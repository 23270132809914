import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ViewChildren, QueryList, Input, ViewChild, OnInit, ContentChild, Output, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ModalService } from '../../services';

@Component({
  selector: 'base-modal',
  templateUrl: './base-modal.html',
  styleUrls: ['./base-modal.scss'],
  animations: [
    trigger('state', [
      state('void, hidden', style({ top: '-100%' })),
      state('visible', style({ top: '0' })),
      transition('* => visible', animate('400ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
      transition('* => hidden', animate('400ms cubic-bezier(0.4, 0.0, 1, 1)'))
    ]),
    trigger('opacity', [
      state('void, hidden', style({ 'background-color': '#00000000' })),
      state('visible', style({ 'background-color': '#000a1dc5' })),
      transition('* => visible', animate('400ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
      transition('* => hidden', animate('400ms cubic-bezier(0.4, 0.0, 1, 1)'))
    ])
  ]
})
export class BaseModal implements OnInit {
  visibility: 'visible' | 'hidden' = 'visible';
  otherVisibility: 'visible' | 'hidden' = 'visible';
  @Input() customClass: string;
  constructor(private modalService: ModalService) {}
  ngOnInit(): void {
    this.modalService.modalRef.afterClosed().subscribe((data) => {
      setTimeout(() => {
        this.visibility = 'hidden';
      });
      this.otherVisibility = 'hidden';
      console.log(data);
    });
  }
}
