import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import { countries } from '../../helpers';
import { PhoneNumberValidator } from '../../validators';
@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnChanges {
  phoneUtil: any;
  phoneCode: string;
  @Input() svg?: boolean;
  @Input() regionCode: string;
  @Input() phoneNumberCode: boolean;
  @Input() isRequired = false;
  @Input() flagOnly = false;
  @Input() rOnly = false;
  @Input() select = true;
  @Input() isDisabled = false;
  @Input() showFlagArrow = true;
  @Input() controlName = 'phoneNumber';
  @Input() partialPhoneNumber: string;
  @ViewChildren('searchRef') filterInput: QueryList<ElementRef>;
  @Output() code = new EventEmitter<string>();
  @Output() regionChange = new EventEmitter<string>();
  countries = countries;
  @Input() form: FormGroup;
  constructor() {}

  ngOnInit() {
    this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    if (changes['partialPhoneNumber']) {
      this.changeRegionCode();
    }
    if (changes['regionCode']) {
      this.changeRegionCode(true);
    }
  }
  changeRegion(event: any) {
    if (event.value) {
      this.regionChange.emit(event.value);
    }
  }
  filter(e: any, search: string) {
    e.filter(search);
  }
  search(a: string, b: { viewValue: string }) {
    return b.viewValue.toLowerCase().startsWith(a.toLowerCase()) ? b : null;
  }
  changeRegionCode(changeCode?: boolean) {
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);
    this.form.patchValue({
      phoneNumber: this.partialPhoneNumber
    });
    const PNF = libphonenumber.PhoneNumberFormat;
    if (changeCode) {
      const validators = [PhoneNumberValidator(this.regionCode)];
      if (this.isRequired) {
        validators.push(Validators.required);
      }
      this.code.emit(this.phoneCode);
      if (!this.flagOnly) {
        this.form.get(this.controlName).setValidators(validators);
        this.form.get(this.controlName).updateValueAndValidity({ emitEvent: true });
      }
      this.form.updateValueAndValidity();
    }
    if (this.form.get(this.controlName).value) {
      this.form.get(this.controlName).markAsTouched();
    }
  }
  onOpen() {
    this.filterInput.changes.subscribe((res) => {
      if (this.filterInput && this.filterInput.first && this.filterInput.first.nativeElement) {
        this.filterInput.first.nativeElement.focus();
      }
    });
  }
  onClose() {
    this.form.get(this.controlName).markAsTouched();
  }
}
