import { RegistrationRoutingModule } from './registration-routing.module';
import { NgModule } from '@angular/core';
// import { environment } from 'environments/environment';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  SocialLoginModule,
  FacebookLoginProvider,
  GoogleLoginProvider
  // LoginOpt,
} from 'angularx-social-login';
import { SharedModule } from '@avenews/shared-lib';
import { RegistrationComponent } from './registration/registration.component';
import { UiLibraryModule } from '@avenews/ui-library';
import { FeatherModule } from 'angular-feather';
import { CheckCircle, ChevronLeft, Settings } from 'angular-feather/icons';

const icons = {
  CheckCircle,
  Settings,
  ChevronLeft
};

// const fbLoginOptions: LoginOpt = {
//   auth_type: 'rerequest',
//   scope: 'email',
//   return_scopes: true,
// };
// const config = new AuthServiceConfig([
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(
//       environment.oauthClientIds.facebook,
//       fbLoginOptions
//     ),
//   },
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.oauthClientIds.google),
//   },
// ]);

// export function provideConfig() {
//   return config;
// }

@NgModule({
  declarations: [RegistrationComponent, TermsOfUseComponent, PrivacyPolicyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RegistrationRoutingModule,
    RouterModule,
    FormsModule,
    SharedModule,
    UiLibraryModule,
    SocialLoginModule,
    NgSelectModule,
    FeatherModule.pick(icons)
  ],
  providers: [
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: provideConfig,
    // },
  ]
})
export class RegistrationModule {}
