import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { Sort } from '@angular/material/sort';
import { ApplicationStatus } from '@avenews/agt-sdk';

@Component({
  selector: 'frontend-distributors-list',
  templateUrl: './distributors-list.component.html',
  styleUrls: ['./distributors-list.component.scss']
})
export class DistributorsListComponent extends ListNoSdkComponent<any> {
  distributor: any;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Distributors',
      route: 'distributors'
    }
  ];
  accounts: any[] = [];
  distributors: any[] = [];
  currentDistributor: any;
  currentTopupUser: any;
  statuses = [
    {
      realName: 'New',
      status: 'new',
      color: 'blue'
    },
    {
      realName: 'Approved',
      status: 'approved',
      color: 'green'
    },
    {
      realName: 'Declined',
      status: 'declined',
      color: 'red'
    },
    {
      realName: 'On Hold',
      status: 'onHold',
      color: 'orange'
    }
  ];

  constructor(injector: Injector, private alertService: AlertService, private router: Router, private route: ActivatedRoute) {
    super(injector, {
      name: 'getAllDistributors',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/user/distributors`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'distributors';
  }

  ngOnInit(): void {
    super.init();
    this.postPagination();
    this.paginated.subscribe((val: string) => {
      this.postPagination();
    });
    this.listConfig = {
      emptyState: {
        title: 'No Distributors yet',
        img: 'assets/images/empty-states/organization.png',
        button: undefined
      },
      actions: [],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['ID', 'BUYER NAME', 'ACTIONS'],
      mainTitle: 'Distributors',
      columns: [
        {
          name: '#',
          dtoName: 'numericId',
          type: 'number',
          selected: true,
          sticky: true
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'policyRole.organization.businessName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'REGISTRATION NUMBER',
          dtoName: 'policyRole.organization.registrationNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'BUSINESS TILL NUMBER',
          dtoName: 'policyRole.organization.tillNumber',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'DISCOUNT (%)',
          dtoName: 'agreement.discount',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'FIRST NAME',
          dtoName: 'personalInformation.firstName',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'LAST NAME',
          dtoName: 'personalInformation.lastName',
          type: 'text',
          selected: true
        },
        {
          name: 'EMAIL ADDRESS',
          dtoName: 'personalInformation.email',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'PHONE',
          dtoName: 'personalInformation.phoneNumber',
          pipe: 'phoneCode',
          type: 'text',
          selected: true
        },
        {
          name: 'NATIONAL ID',
          dtoName: 'distributor.nationalId',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'distributor.status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          dtoName: undefined,
          selected: true
        }
      ],

      filters: [
        {
          name: '#',
          dtoName: 'numericId',
          type: 'number'
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'policyRole.organization.businessName',
          type: 'text'
        },
        {
          name: 'REGISTRATION NUMBER',
          dtoName: 'policyRole.organization.registrationNumber',
          type: 'text'
        },
        {
          name: 'BUSINESS TILL NUMBER',
          dtoName: 'policyRole.organization.tillNumber',
          type: 'text'
        },
        {
          name: 'DISCOUNT (%)',
          dtoName: 'agreement.discount',
          type: 'text'
        },
        {
          name: 'FIRST NAME',
          dtoName: 'personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'LAST NAME',
          dtoName: 'personalInformation.lastName',
          type: 'text'
        },
        {
          name: 'EMAIL ADDRESS',
          dtoName: 'personalInformation.email',
          type: 'text'
        },
        {
          name: 'PHONE',
          dtoName: 'personalInformation.phoneNumber',
          type: 'text'
        },
        {
          name: 'NATIONAL ID',
          dtoName: 'distributor.nationalId',
          type: 'text'
        },
        {
          name: 'STATUS',
          dtoName: 'distributor.status',
          type: 'radio',
          radio: [
            {
              label: 'New',
              value: 'new'
            },
            {
              label: 'Approved',
              value: 'approved'
            },
            {
              label: 'Declined',
              value: 'declined'
            },
            {
              label: 'On Hold',
              value: 'onHold'
            }
          ]
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }

  addDistributor() {
    $('#addOrganizationWizard').css({ display: 'flex' });
  }

  primaryActions(val?: string) {
    switch (val) {
      case 'new':
        this.addDistributor();
        break;

      default:
        this.addDistributor();
    }
  }
  viewOrder(distri: any) {
    this.router.navigate(['orders', distri._id]);
  }

  changeStatus(entity: any) {
    this.distributor = entity;
    $('#changeDistributorStatusWizard').css({ display: 'flex' });
  }

  actions(e: { action: any; entity?: any }) {
    switch (e.action.action) {
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
      case 'view':
        this.viewDistributor(e.entity);
        break;
    }
  }

  viewDistributor(user: any) {
    this.router.navigate(['distributor', user._id]);
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      if (sort.active === 'cashoutLimited' || sort.active === 'status' || sort.active === 'highRisk' || sort.active === 'owner') {
        this.list = this.list.sort((a, b) => {
          if (sort.active === 'cashoutLimited') {
            let as = -1;
            let bs = -1;
            if (a?.financing && a?.financing?.availableCashoutRate !== 0) {
              as = 1;
            }
            if (b?.financing && b?.financing?.availableCashoutRate !== 0) {
              bs = 1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          } else if (sort.active === 'status') {
            let as = 0;
            let bs = 0;
            if (a?.financing) {
              if (a?.financing?.validated) {
                as = 1;
              }
            } else {
              as = -1;
            }
            if (b?.financing) {
              if (b?.financing?.validated) {
                bs = 1;
              }
            } else {
              bs = -1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          } else if (sort.active === 'highRisk') {
            let as = 0;
            let bs = 0;
            if (a?.financing) {
              if (a?.financing?.highRisk) {
                as = 1;
              }
            } else {
              as = -1;
            }
            if (b?.financing) {
              if (b?.financing?.highRisk) {
                bs = 1;
              }
            } else {
              bs = -1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }

  manualFilter(filter: any[]) {
    const manuals = ['cashoutLimited', 'status', 'highRisk', 'owner'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'owner') {
          const ownerName = this.ownerFullName(item?.owner);
          res = ownerName.toLowerCase().includes(f.value.toLowerCase());
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }

  valueChains(chains: string[], tooltip?: boolean) {
    if (!chains || chains?.length <= 1) {
      return chains?.[0] || '-';
    }
    if (tooltip) {
      return chains?.join(',\n');
    }
    return 'Multiple';
  }

  ownerFullName(owner: any) {
    return owner.personalInformation.firstName + ' ' + owner.personalInformation.lastName;
  }

  async postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [];
      actions.push({ label: 'Change status', type: 'default', action: 'changeStatus' });
      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
}
