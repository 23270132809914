export const packageUnits = [
  { value: 'KG', viewValue: 'KG' },
  { value: 'LB', viewValue: 'LB' },
  { value: 'MT', viewValue: 'MT' }
];

export const containerType = [
  { value: '20 ft.', viewValue: '20 ft.' },
  { value: '40 ft.', viewValue: '40 ft.' }
];

export const packageTypes = [
  { value: 'bag', viewValue: 'Bags' },
  { value: 'box', viewValue: 'Boxes' }
];
