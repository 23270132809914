import {
  TdKYCComponent,
  TDDisbMlComponent,
  TdKycCheckListComponent,
  DocumentPasswordComponent,
  TdActivityLogComponent,
  TdBureauComponent,
  TdDecisionTreeComponent,
  TdDocumentsComponent,
  TdNotesComponent,
  TDSignoffComponent
} from './tools';
import { OverlayModule } from '@angular/cdk/overlay';
import { TDDocumentsResolver } from './resolvers/td-documents.resolver';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { FinanceModule } from './../finance/finance.module';
import { MLScoreDataResolver } from './resolvers/ml-score-data.resolver';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnswersResolver } from './resolvers/answers.resolver';
import { CategoriesResolver } from './resolvers/categories.resolver';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiLibraryModule } from '@avenews/ui-library';
import { SharedModule } from '@avenews/shared-lib';
import { AdminSharedModule } from './../shared/admin-shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe, DecimalPipe, DatePipe } from '@angular/common';
import { TradeDeskRoutingModule } from './trade-desk-routing.module';
import { TradeDeskBuilderComponent } from './trade-desk-builder/trade-desk-builder.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { LoanDeskComponent } from './loans/loan-desk.component';
import { TradeDeskAgentComponent } from './trade-desk-agent/trade-desk-agent.component';
import { TradeDeskService } from './services/trade-desk.service';
import { GeneralVariablesResolver } from './resolvers/general-variables.resolver';
import { AvenewsUsersResolver } from './resolvers/avenews-users.resolver';
import { ActivityLogResolver } from './resolvers/activitylog.resolver';
import { MentionModule } from 'angular-mentions';
import { BureauResolver } from './resolvers/bureau.resolver';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgChartsModule } from 'ng2-charts';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KycResolver } from './resolvers/kyc.resolver';
import { KycChecklistResolver } from './resolvers/kyc-checklist.resolver';
import { ExpiryDateModal } from './tools/expiry-date-modal/expiry-date-modal';
import { LoanFilesComponent } from './loan-files/loan-files.component';
import { ExpireSoonComponent } from './loan-files/expire-soon/expire-soon.component';
import { ExpiredFilesComponent } from './loan-files/expired-files/expired-files.component';
import { ArchivedFilesComponent } from './loan-files/archived-files/archived-files.component';
import { ExpireSoonResolver } from './resolvers/expire-soon.resolver';
import { ExpiredFilesResolver } from './resolvers/expired-files.resolver';
import { ArchivedFilesResolver } from './resolvers/archived-files.resolver';
import { LoanFilesService } from './services/loan-files.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TradeDeskBuilderComponent,
    LoanDeskComponent,
    TradeDeskAgentComponent,
    TdDocumentsComponent,
    DocumentPasswordComponent,
    TdNotesComponent,
    TDSignoffComponent,
    TdActivityLogComponent,
    TdDecisionTreeComponent,
    TdBureauComponent,
    TDDisbMlComponent,
    ExpiryDateModal,
    TdKYCComponent,
    TdKycCheckListComponent,
    LoanFilesComponent,
    ExpireSoonComponent,
    ExpiredFilesComponent,
    ArchivedFilesComponent
  ],
  imports: [
    CommonModule,
    TextFieldModule,
    MentionModule,
    MatExpansionModule,
    TradeDeskRoutingModule,
    MatTreeModule,
    ReactiveFormsModule,
    ClipboardModule,
    CdkTreeModule,
    MatIconModule,
    FinanceModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTabsModule,
    MatCheckboxModule,
    NgChartsModule,
    OverlayModule,
    FormsModule,
    MatTooltipModule,
    UiLibraryModule,
    AdminSharedModule,
    SharedModule
  ],
  providers: [
    CategoriesResolver,
    ActivityLogResolver,
    TDDocumentsResolver,
    MLScoreDataResolver,
    GeneralVariablesResolver,
    KycResolver,
    TitleCasePipe,
    TradeDeskService,
    AnswersResolver,
    LoanFilesService,
    DecimalPipe,
    DatePipe,
    BureauResolver,
    ExpiredFilesResolver,
    ExpireSoonResolver,
    ArchivedFilesResolver,
    AvenewsUsersResolver,
    KycChecklistResolver
  ],
  exports: [TdBureauComponent]
})
export class TradeDeskModule {}
