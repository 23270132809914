import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@avenews/shell';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { AgentsResolver } from './resolvers/agents.resolver';
import { UserResolver } from '../shared/resolvers/user.resolver';
import { AgentViewComponent } from './agent-view/agent-view.component';
import { AgentCommissionsResolver } from './resolvers/comissions-by-agent.resolver';
import { IncomingAgentCommissionsResolver } from './resolvers/incoming-comissions-by-agent.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'agents',
      component: AgentsListComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        agents: AgentsResolver
      }
    },
    {
      path: 'agents/:id',
      component: AgentViewComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        agent: UserResolver,
        commissions: IncomingAgentCommissionsResolver,
        allCommissions: AgentCommissionsResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentsRoutingModule {}
