<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" moduleName="Organization view"></av-breadcrumbs-template>
<div class="user-view-wrapper">
  <frontend-base-side-view [topTitle]="topTitle" [action]="false" [sideView]="sideView" [entity]="org">
    <ng-template #fieldValue let-item="item" let-entity="entity" let-p="template">
      <ng-container [ngTemplateOutlet]="p" *ngIf="item?.name !== 'Owner' && item?.name !== 'Partner'"></ng-container>
      <ng-container *ngIf="item?.name === 'Owner'">
        <div>{{ entity?.owner?.personalInformation?.firstName }} {{ entity?.owner?.personalInformation?.lastName }}</div></ng-container
      >
      <ng-container *ngIf="item?.name === 'Partner'">
        <div>
          {{ entity?.partner ? (entity?.partner | titlecase) : 'No' }}
        </div></ng-container
      >
    </ng-template>
  </frontend-base-side-view>

  <mat-tab-group [disableRipple]="true" id="user-view-tabs">
    <mat-tab color="#152C5B" label="Deals">
      <frontend-deals [organization]="org"></frontend-deals>
    </mat-tab>
    <mat-tab color="#152C5B" label="Periods">
      <frontend-periods [repayments]="repayments" (periods)="getPeriods($event)"></frontend-periods>
    </mat-tab>
    <mat-tab color="#152C5B" label="Repayments">
      <frontend-repayments [periods]="periods"></frontend-repayments>
    </mat-tab>
  </mat-tab-group>
</div>
