import { Component, Injector, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Agt4Period, Action, PeriodStatus, PeriodType, Agt4Repayment, Agt4Deal } from '@avenews/agt-sdk';
import { ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-repayments',
  templateUrl: './repayments.component.html',
  styleUrls: ['./repayments.component.scss']
})
export class RepaymentsComponent extends ListNoSdkComponent<Agt4Repayment> implements OnInit {
  repayment: Agt4Repayment;
  isEdit: boolean;
  @Input() periods: Agt4Period[];
  isPeriodOpen: boolean;
  deals: Agt4Deal[];
  constructor(injector: Injector, private route: ActivatedRoute, private router: Router) {
    super(injector, {
      noPagination: true,
      name: 'getPeriods',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/repayments`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'repayments';
  }

  ngOnInit(): void {
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    super.init();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No Repayments found',
        img: 'assets/images/empty-states/deal-empty.png'
      },
      actions: [],
      bulkAddButton: undefined,
      fixedColumns: ['ID', 'PARTNER NAME', 'ACTIONS'],
      mainTitle: '',
      addButton: undefined,
      columns: [
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'PERIOD NAME',
          dtoName: 'name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'USER NAME',
          dtoName: 'owner.personalInformation.firstName',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'AMOUNT REPAID',
          dtoName: 'reportedAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'REPAYMENT DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'METHOD',
          dtoName: 'type',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },

        {
          name: 'START DATE',
          dtoName: 'startDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'END DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REPAYMENT DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REQUESTED AMOUNT',
          dtoName: 'requestedAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'REPAYMENT AMOUNT',
          dtoName: 'repaymentAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'AMOUNT REPAID',
          dtoName: 'numericId',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ] as any[]
    };
  }

  ngAfterViewInit(): void {}

  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  actions(e: { action: Action; entity?: any }) {
    switch (
      e.action.action
      // case 'edit':
      //   this.editPeriod(e.entity);
      //   break;
      // case 'changeStatus':
      //   this.changeStatus(e.entity);
      //   break;
      // case 'repay':
      //   this.setRepayment(e.entity);
      //   break;
    ) {
    }
  }
  // setRepayment(period: Agt4Period) {}
  // changeStatus(deal: Agt4Period) {}

  // editPeriod(period: Agt4Period) {}
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
      // if (sort.active === 'fullName') {
      //   this.list = this.list.sort((a, b) => {
      //     if (sort.active === 'fullName') {
      //       return sort?.direction === 'asc'
      //         ? a.contacts?.[0]?.firstName?.localeCompare(b.contacts?.[0]?.firstName)
      //         : b.contacts?.[0]?.firstName?.localeCompare(a.contacts?.[0]?.firstName);
      //     }
      //     return 1;
      //   });
      // } else {
      //   this.sortData(sort);
      // }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {});
      return res;
    });
    this.totalCount = this.list.length;
  }

  postPagination() {}
  periodName(repayment: Agt4Repayment) {
    if (this.periods && repayment?.period?._id) {
      return this.periods.find((period) => period._id === repayment.period?._id)?.['name'];
    }
  }
}
