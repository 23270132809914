import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDownNum'
})
export class RoundDownNumePipe implements PipeTransform {
  transform(number: number): string {
    const roundedNumber = Math.floor(number * 100) / 100;
    return roundedNumber.toFixed(2);
  }
}
