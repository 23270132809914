<div class="modal-box" id="linkUserToDealAccount">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-md">
    <div *ngIf="isNotOrganization" class="modal-box-heading">
      <div>
        <h2>Link user account</h2>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <p>Link an account from which the user can buy from</p>
    </div>
    <div *ngIf="!isNotOrganization" class="modal-box-heading">
      <div>
        <h2>Select user account</h2>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
    </div>
    <div class="modal-box-content scrollable">
      <div *ngIf="listStatus === 'empty-state'" class="empty-state">
        <div class="empty">
          <img src="assets/images/empty-states/agribusiness-empty-state.svg" alt="empty-state" />
          <p class="empty-title">No users found</p>
        </div>
      </div>
      <ng-container *ngIf="listStatus !== 'empty-state'">
        <h3 class="choose-contact">Select a user account from the list below</h3>
        <p>*These users are not part of any organization</p>
        <div class="actions-section clearfix">
          <div class="searchbar d-flex">
            <input (ngModelChange)="search($event)" [(ngModel)]="term" placeholder="Search" class="search-bar m-0" type="search" />
            <span class="featherer">
              <i-feather name="search"></i-feather>
            </span>
          </div>
        </div>
        <div *ngIf="listStatus === 'empty-search'">
          <div class="empty-search">
            <p class="empty-title">No results found</p>
            <p class="empty-subtitle">Try adjusting your search to find what you’re looking for.</p>
          </div>
        </div>
        <ng-container *ngIf="listStatus === 'normal'">
          <div class="stepper-wrapper">
            <div class="stepper desktop-stepper">
              <div class="tab-content">
                <div [formGroup]="chooseUserForm" class="table-wrapper">
                  <ng-container *ngIf="recommendedUsers?.length">
                    <p>Recommended users</p>
                    <table matSort class="table main-table table-borderless ftd-small">
                      <thead class="thead-generator">
                        <tr id="table-head-generator">
                          <th></th>
                          <th class="name table-h" mat-sort-header="id">ID</th>
                          <th class="name min-w table-h" mat-sort-header="contact">FIRST NAME</th>
                          <th class="min-w table-h text-left">LAST NAME</th>
                          <th class="min-w table-h" mat-sort-header="country">EMAIL</th>
                          <th class="min-w table-h" mat-sort-header="country">PHONE NUMBER</th>
                          <th class="min-w table-h" mat-sort-header="country">BUSINESS NAME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr (click)="radio.click()" *ngFor="let user of users; let i = index">
                          <td class="text-left">
                            <input
                              formControlName="user"
                              type="radio"
                              class="form_radio"
                              id="doc-row{{ user?._id }}"
                              name="user"
                              [value]="user"
                              [checked]="true"
                            />
                            <label #radio for="doc-row{{ user?._id }}" class="form_radio_label">
                              <span (click)="radio.click()" class="form_radio_btn"></span
                            ></label>
                          </td>
                          <td>#{{ user?.numericId }}</td>
                          <td class="name">
                            <p>
                              {{ user?.personalInformation?.firstName || '-' }}
                            </p>
                          </td>
                          <td class="text-left">
                            <p>{{ user?.personalInformation?.lastName || '-' }}</p>
                          </td>
                          <td>
                            <p>
                              {{ user?.personalInformation?.email || '-' }}
                            </p>
                          </td>
                          <td>{{ user?.personalInformation?.phoneNumber || '-' }}</td>
                          <td>{{ user?.companyInformation?.companyName || '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
                <div [formGroup]="chooseUserForm" class="table-wrapper">
                  <table matSort class="table main-table table-borderless ftd-small">
                    <thead class="thead-generator">
                      <tr id="table-head-generator">
                        <th></th>
                        <th class="name table-h" mat-sort-header="id">ID</th>
                        <th class="name min-w table-h" mat-sort-header="contact">FIRST NAME</th>
                        <th class="min-w table-h text-left">LAST NAME</th>
                        <th class="min-w table-h" mat-sort-header="country">EMAIL</th>
                        <th class="min-w table-h" mat-sort-header="country">PHONE NUMBER</th>
                        <th *ngIf="isNotOrganization" class="min-w table-h" mat-sort-header="country">BUSINESS NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        (click)="radio.click()"
                        *ngFor="
                          let user of list
                            | paginate
                              : {
                                  itemsPerPage: itemsPerPage,
                                  currentPage: currentPage,
                                  totalItems: totalCount,
                                  id: 'choose-item-list'
                                };
                          let i = index
                        "
                      >
                        <td class="text-left">
                          <input
                            formControlName="user"
                            type="radio"
                            class="form_radio"
                            id="doc-row{{ user?._id }}"
                            name="user"
                            [value]="user"
                            [checked]="true"
                          />
                          <label #radio for="doc-row{{ user?._id }}" class="form_radio_label">
                            <span (click)="radio.click()" class="form_radio_btn"></span
                          ></label>
                        </td>
                        <td>#{{ user?.numericId }}</td>
                        <td class="name">
                          <p>
                            {{ user?.personalInformation?.firstName || '-' }}
                          </p>
                        </td>
                        <td class="text-left">
                          <p>{{ user?.personalInformation?.lastName || '-' }}</p>
                        </td>
                        <td>
                          <p>
                            {{ user?.personalInformation?.email || '-' }}
                          </p>
                        </td>
                        <td>{{ user?.personalInformation?.phoneNumber || '-' }}</td>
                        <td *ngIf="isNotOrganization">{{ user?.companyInformation?.companyName || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="justify-content-between d-flex">
            <pagination-controls
              id="choose-item-list"
              (pageChange)="pageChanged($event)"
              *ngIf="listStatus === 'normal'"
            ></pagination-controls>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="modal-box-actions">
      <button (click)="continue()" class="bttn-primary pull-right">Save</button>
    </div>
  </div>
</div>
