import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult, Agt4Period } from '@avenews/agt-sdk';

@Injectable()
export class PeriodsResolver implements Resolve<PaginatedResult<Agt4Period>> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: import('@angular/router').ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return (
      this.http.get(`${environment.new_api_url}/api/period/user/${id}/1231/1/_id/desc`) as Observable<PaginatedResult<Agt4Period>>
    ).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
