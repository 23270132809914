import { catchError } from 'rxjs/operators';
import { AlertService } from '@avenews/alerts';
import { Router, ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Component, Injector, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { Action, UploadFileDTO } from '@avenews/agt-sdk';
import { defaultValues, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { EMPTY } from 'rxjs';
import { AuthenticationService } from '@avenews/front-auth';

@Component({
  selector: 'frontend-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends ListNoSdkComponent<any> implements OnInit, OnChanges {
  @Input() application: any;
  @Input() edit: boolean;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<any>;
  @ViewChild('fileUploader', { static: false }) fileInput: any;
  @Input() products: any;
  @Input() loan: any;
  acceptedMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  constructor(
    private alerts: AlertService,
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/loan-application`,
      noPagination: true
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'application';
    this.exportId = 'main-farmer-export';
  }
  uploadedFile(e: any) {
    const file = e.target.files[0];
    if (file && this.validateFile(file)) {
      this.spinner.showSpinner();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const uploadFile: UploadFileDTO = {
          file: reader.result.toString().split(',')[1],
          fileName: file.name,
          mime: reader.result.toString().split(',')[0].split(':')[1].split(';')[0]
        };
        this.http
          .put(`${environment.new_api_url}/api/loan-application/${this.loan?._id}/products-list`, {
            ...uploadFile
          })
          .pipe(
            catchError((err) => {
              this.spinner.hideSpinner();
              this.alerts.showAlert(err?.error?.message, 'danger');
              return EMPTY.pipe();
            })
          )
          .subscribe((data: any) => {
            if (data.key) {
              this.http
                .post('https://7zquybghwdoykbu7p3rvbwzr2i0kpzoh.lambda-url.us-east-1.on.aws', {
                  loanId: this.loan?._id,
                  filename: data?.key.slice(21)
                })
                .pipe(
                  catchError((err) => {
                    this.spinner.hideSpinner();
                    this.alerts.showAlert(err?.error?.message, 'danger');
                    return EMPTY.pipe();
                  })
                )
                .subscribe(() => {
                  this.alerts.showAlert('Products list uploaded successfully', 'success');
                  this.spinner.hideSpinner();
                  this.router.navigateByUrl(this.router.url);
                });
            }
          });
      };
    }
  }
  validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 15e6;
  }
  ngOnInit(): void {
    // super.init();
    this.route.data.subscribe((data) => {
      this.application = data['application'];
      const products = [];

      if (this.application?.dealId) {
        products.push(...this.application?.products?.slice());
      }
      this.list = products?.slice();
      this.list.forEach((item) => {
        if (item?.buyers?.length) {
          item['buyerNames'] = item?.buyers?.replace(/,(.)/g, '\n');
          item['amountOfBuyerstooltip'] = item['buyerNames'];
          item['buyerstooltip'] = item['buyerNames'];
        }
        let newType = item?.productType.replace(/_/g, ' ');
        newType = newType?.slice(0, 1)?.toUpperCase() + newType?.slice(1);
        item['myType'] = newType.replace(/\s(.)/g, (x) => {
          return x?.toUpperCase();
        });
        item['packageBuyingPrices'] = item?.packageBuyingPrices || item?.buyingPrice || item?.packageBuyingPrice;
        item['packageSellingPrices'] = item?.packageSellingPrices || item?.sellingPrice || item?.packageSellingPrice;
        item['totalBuyingPrice'] = item?.totalBuyingPrice ? item?.totalBuyingPrice : item?.packageBuyingPrice * item?.packagesToBuy;
        item['periodRoi'] = item?.periodRoi ? item?.periodRoi * 100 : '0';
        item['roiIndicator'] = item?.roiIndicator ? 'True' : 'False';
        item['storeName'] = item?.storeName || item?.buyFrom || '-';
        item['buyToSellDays'] = item?.buyToSellDays || item?.daysToSell || '-';
      });
      this.totalCount = this.list?.length;
      this.originalTotalCount = this.totalCount;
      this.currentPage = 1;
      this.itemsPerPage = defaultValues.items_per_page;
      this.options.pageSize = defaultValues.items_per_page;
      this.unsortedList = this.list?.slice();
      this.originalTotalCount = this.totalCount;
      this.recordsPerPageForm.get('recordsPerPage')!.setValue(defaultValues.items_per_page);
    });
    const user = this.authService.credentials.user as any;

    let addButton: any = {
      emptyStateLabel: 'New',
      isDropdown: true,
      label: 'New',
      dropdown: [
        {
          emittedValue: 'uploadProductList',
          label: 'Upload product list'
        },
        { emittedValue: 'addProduct', label: 'Add products' }
      ]
    };
    if (!user?.roles?.includes('addProduct')) {
      console.log('here');
      addButton = {
        emptyStateLabel: 'Upload product list',
        emittedValue: 'uploadProductList'
      };
    }
    console.log(addButton);
    this.listConfig = {
      emptyState: {
        title: 'No products here',
        img: 'assets/images/empty-states/no-farmers.png',

        button: {
          label: 'Upload product list',
          action: 'uploadProductList'
        }
      },
      actions: [
        // { label: 'View', type: 'default', action: 'view' } will uncomment when we have  view product
      ],
      bulkAddButton: undefined,

      addButton,
      fixedColumns: ['NAME', 'ACTION'],
      mainTitle: 'Products',

      columns: [
        {
          name: 'PRODUCT TYPE',
          dtoName: 'myType',
          pipe: 'text',
          type: 'text',
          selected: true
        },
        {
          name: 'PRODUCT NAME',
          dtoName: 'name',
          pipe: 'text',
          type: 'text',
          selected: true
        },
        {
          name: 'APPROVED',
          dtoName: 'roiIndicator',
          pipe: 'text',
          type: 'text',
          selected: true
        },
        {
          name: 'PACKAGE BUYING PRICES',
          dtoName: 'packageBuyingPrices',
          pipe: 'number',
          type: 'number',
          sideText: 'KES',
          selected: true
        },
        {
          name: 'PACKAGE SELLING PRICES',
          dtoName: 'packageSellingPrices',
          pipe: 'number',
          type: 'number',
          sideText: 'KES',
          selected: true
        },
        {
          name: 'PACKAGE TYPE',
          dtoName: 'packageType',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'MEASUREMENT UNIT',
          dtoName: 'measurementUnit',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'UNITS IN PACKAGE',
          dtoName: 'unitsInPackage',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'PACKAGES TO BUY',
          dtoName: 'packagesToBuy',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'TOTAL BUYING PRICE',
          dtoName: 'totalBuyingPrice',
          pipe: 'number',
          sideText: 'KES',
          type: 'number',
          selected: true
        },
        {
          name: 'BUY TO SELL DAYS',
          dtoName: 'buyToSellDays',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'BUY TO PAYMENT DAYS',
          dtoName: 'buyToPaymentDays',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'AMOUNT OF BUYERS',
          dtoName: 'amountOfBuyers',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'DAYS TO BUY',
          dtoName: 'daysToBuy',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'DAYS TO PAID FULL',
          dtoName: 'daysToPaidFull',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'DAYS TO SELL',
          dtoName: 'daysToSell',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'PROCESSING PERIOD',
          dtoName: 'processingPeriod',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'MARGIN PERCENTAGE',
          dtoName: 'marginPercentage',
          pipe: 'number',
          sideText: '%',
          type: 'number',
          selected: true
        },
        {
          name: 'ANIMAL AMOUNT',
          dtoName: 'animalsAmount',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'WEEKS RATIO',
          dtoName: 'weeksRatio',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'PERIOD ROI',
          dtoName: 'periodRoi',
          pipe: 'number',
          alternative: '0',
          sideText: '%',
          type: 'number',
          selected: true
        },
        {
          name: 'REVENUE',
          dtoName: 'revenue',
          pipe: 'number',
          sideText: 'KES',
          type: 'number',
          selected: true
        },
        {
          name: 'REVISED LOAN',
          dtoName: 'revisedLoan',
          sideText: 'KES',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'ROI',
          dtoName: 'roi',
          sideText: '%',
          pipe: 'number',
          type: 'number',
          selected: true
        },

        {
          name: 'ANNUALIZED ROI',
          dtoName: 'annualizedRoi',
          sideText: '%',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'BUYERS',
          dtoName: 'buyers',
          type: 'text',
          selected: true
        },
        {
          name: 'LAND SIZE',
          dtoName: 'landSize',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'MANUFACTURER',
          dtoName: 'manufacturer',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          type: 'text',
          selected: true
        },
        {
          name: 'NUMBER OF SELLERS',
          dtoName: 'numOfSellers',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'PAYMENT COMMENTS',
          dtoName: 'paymentComments',
          type: 'text',
          selected: true
        },
        {
          name: 'PAYMENT TERMS',
          dtoName: 'paymentTerms',

          type: 'text',
          selected: true
        },
        {
          name: 'SELLERS TYPE',
          dtoName: 'sellersType',
          type: 'text',
          selected: true
        },
        {
          name: 'STORE NAME',
          dtoName: 'storeName',
          type: 'text',
          selected: true
        },
        {
          name: 'TYPE',
          dtoName: 'type',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'PACKAGE BUYING PRICES',
          dtoName: 'priceToBuyPerPackage',
          type: 'number'
        },
        {
          name: 'PACKAGE SELLING PRICES',
          dtoName: 'priceToSellPerPackage',
          type: 'number'
        },
        {
          name: 'PACKAGE TYPE',
          dtoName: 'type',
          type: 'text'
        },
        {
          name: 'MEASUREMENT UNIT',
          dtoName: 'measurementUnit',
          type: 'text'
        },
        {
          name: 'UNITS IN PACKAGE',
          dtoName: 'unitsInPackage',
          type: 'text'
        },

        {
          name: 'PACKAGES TO BUY',
          dtoName: 'packageContent',
          type: 'number'
        },
        {
          name: 'TOTAL BUYING PRICE',
          dtoName: 'landSize',
          type: 'number'
        },

        {
          name: 'BUY TO SELL DAYS',
          dtoName: 'daysToSell',
          type: 'number'
        }
      ]
    };

    if (this.edit) {
      this.listConfig.actions = [{ label: 'Edit', type: 'default', action: 'edit' }];
    }
  }
  primaryActions(val?: string) {
    switch (val) {
      case 'uploadProductList':
        this.fileInput?.nativeElement.click();
        break;
      case 'addProduct':
        this.addProduct();
        break;
    }
  }
  deleteContact() {}
  exportContact() {}
  editProduct(prod: any) {}
  viewProduct(prod: any) {}

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'edit':
        this.editProduct(e.entity);
        break;
    }
  }
  uploadProductList() {}
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  manualFilter(filter: any[]) {
    const manuals = ['priceToBuyPerPackage', 'priceToSellPerPackage'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item: any) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'priceToBuyPerPackage') {
          let price = item?.priceToBuyPerPackage * item?.packageContent;
          if (isNaN(price)) {
            price = 0;
          }
          switch (f.option) {
            case 'lower':
              res = res && price < f.value;
              break;
            case 'greater':
              res = res && price > f.value;
              break;
            case 'equal':
              res = price === f.value;
              break;
          }
        }
        if (f.column === 'priceToSellPerPackage') {
          let price = item?.priceToSellPerPackage * item?.packageContent;
          if (isNaN(price)) {
            price = 0;
          }
          switch (f.option) {
            case 'lower':
              res = res && price < f.value;
              break;
            case 'greater':
              res = res && price > f.value;
              break;
            case 'equal':
              res = price === f.value;
              break;
          }
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
    }
  }
  buyerNames(item: { buyers: string }) {
    if (item?.buyers?.length) return item?.buyers?.replace(/,(.)/g, '\n');
    return '-';
  }
  fixType(type: string) {
    let newType = type.replace(/_/g, ' ');
    newType = newType.slice(0, 1).toUpperCase() + newType.slice(1);
    return newType.replace(/\s(.)/g, (x) => {
      return x.toUpperCase();
    });
  }
  addProduct() {
    this.http
      .get(`${environment.new_api_url}/api/auth/token/${this?.loan.createdBy?._id}`, { responseType: 'text' })
      .pipe(
        catchError((err) => {
          console.error(err);
          return EMPTY.pipe();
        })
      )
      .subscribe((response) => {
        const baseUrl = 'app.avenews.io';
        let url = '';
        if (environment.new_api_url.includes('qa')) {
          url = 'http://qa.' + baseUrl;
        } else if (environment.new_api_url.includes('staging')) {
          url = 'http://staging.' + baseUrl;
        }
        if (environment.production) {
          url = 'http://' + baseUrl;
        }
        window.open(`${url}/admin/product-list/${this.loan._id}?token=${response}`, '_blank');
      });
  }
}
