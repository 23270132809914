<ng-template #appended><ng-content select="[append]"></ng-content></ng-template>
<ng-template #appendNext><ng-content select="[appendNext]"></ng-content></ng-template>

<div
  *ngIf="inputName === 'default' || inputName === 'password' || inputName === 'fake-pass'"
  class="form-group col-md-{{ size }} default-input-group {{ group | showFieldStyle : controlName }}"
  [formGroup]="group"
>
  <mat-form-field [class.borderd]="borderd" appearance="standard" [hideRequiredMarker]="true">
    <mat-label
      [class.bigger]="bigger"
      [matTooltip]="labelName"
      #tooltip="matTooltip"
      [matTooltipDisabled]="!wrap"
      [ngClass]="{ 'wrap-label': wrap, grey: group.get(controlName).invalid && group.get(controlName).touched }"
      *ngIf="labelName"
      >{{ labelName }} <span *ngIf="strictMode">{{ group | showAsterisk : controlName }}</span>
      <span *ngIf="sideText" class="side-text">({{ sideText }})</span></mat-label
    >
    <ng-content select="[underLabel]"></ng-content>
    <div class="xmd:flex grid grid-cols-1">
      <div class="form-append grow" [ngClass]="{ 'appended-form': appendIt }">
        <input
          (paste)="pasted($event)"
          *ngIf="inputType === 'password'"
          class="form-control"
          [readOnly]="rOnly"
          value="{{ value }}"
          placeholder="{{ placeholderName || '' }}"
          type="password"
          matInput
          formControlName="{{ controlName }}"
          id="{{ controlName + uniqueId }}"
          [step]="stepNumber"
        />
        <input
          (paste)="pasted($event)"
          *ngIf="inputType === 'fake-pass'"
          class="form-control fake-pass"
          [readOnly]="rOnly"
          value="{{ value }}"
          placeholder="{{ placeholderName || '' }}"
          type="text"
          matInput
          formControlName="{{ controlName }}"
          id="{{ controlName + uniqueId }}"
          [step]="stepNumber"
        />
        <input
          *ngIf="
            inputType !== 'number' &&
            inputType !== 'fake' &&
            inputType !== 'password' &&
            inputType !== 'textarea' &&
            inputType !== 'fake-pass'
          "
          class="form-control"
          (keydown)="onInput($event)"
          [readOnly]="rOnly"
          (paste)="pasted($event)"
          placeholder="{{ placeholderName || '' }}"
          type="text"
          [class.new]="newDesign"
          value="{{ value }}"
          matInput
          formControlName="{{ controlName }}"
          id="{{ controlName + uniqueId }}"
          [step]="stepNumber"
        />
        <textarea
          rows="4"
          (paste)="pasted($event)"
          *ngIf="inputType === 'fake'"
          class="form-control"
          value="{{ value }}"
          [readOnly]="rOnly"
          placeholder="{{ placeholderName || '' }}"
          id="{{ controlName + uniqueId }}"
        ></textarea>
        <textarea
          formControlName="{{ controlName }}"
          rows="4"
          *ngIf="inputType === 'textarea'"
          class="form-control"
          value="{{ value }}"
          (paste)="pasted($event)"
          [readOnly]="rOnly"
          placeholder="{{ placeholderName || '' }}"
          id="{{ controlName + uniqueId }}"
        ></textarea>
        <input
          *ngIf="inputType === 'number'"
          class="form-control"
          [readOnly]="rOnly"
          placeholder="{{ placeholderName || '' }}"
          type="number"
          matInput
          (paste)="pasted($event)"
          value="{{ value }}"
          formControlName="{{ controlName }}"
          id="{{ controlName + uniqueId }}"
          [step]="stepNumber"
          [class.disabled]="isDisabled"
        />
        <!-- <div *ngIf="append" [ngClass]="{ appended: appendIt, aficion: appendIt }">
          <ng-container *ngTemplateOutlet="appended"></ng-container>
        </div> -->
      </div>
      <ng-container *ngTemplateOutlet="appendNext"></ng-container>
    </div>

    <div *ngIf="append" matSuffix class="flex items-center justify-center" [ngClass]="{ appended: appendIt, aficion: appendIt }">
      <ng-container *ngTemplateOutlet="appended"></ng-container>
    </div>
  </mat-form-field>

  <ng-container *ngFor="let error of errorMessages?.[controlName]">
    <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
      <p>
        {{ error.message }}
      </p>
    </div>
  </ng-container>
  <div *ngIf="underInput" class="under-input">{{ underInput }}</div>
</div>

<div *ngIf="inputName === 'radio'" class="form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }}" [formGroup]="group">
  <label *ngIf="labelName" class="radio-label mb-3" for="{{ controlName }}"
    >{{ labelName }} <span *ngIf="strictMode">{{ group | showAsterisk : controlName }}</span>
    <span *ngIf="sideText" class="side-text">({{ sideText }})</span>
  </label>
  <div [ngClass]="{ 'read-only': rOnly }" [class.column]="isColumn" class="radio-container">
    <ng-container *ngFor="let radio of radios; let i = index">
      <div class="radio">
        <label class="cursor-pointer" [class.darker]="darker" for="{{ controlName + i + uniqueId }}" *ngIf="radio?.labelFirst">{{
          radio?.label
        }}</label>
        <input
          class="radio"
          [value]="radio?.value"
          (paste)="pasted($event)"
          [readonly]="rOnly"
          type="radio"
          formControlName="{{ controlName }}"
          id="{{ controlName + i + uniqueId }}"
        />
        <label class="cursor-pointer" [class.darker]="darker" for="{{ controlName + i + uniqueId }}" *ngIf="!radio?.labelFirst">{{
          radio?.label
        }}</label>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let error of errorMessages?.[controlName]">
    <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
      <p>
        {{ error.message }}
      </p>
    </div>
  </ng-container>
</div>
<div *ngIf="inputName === 'checkbox'" class="form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }}" [formGroup]="group">
  <label class="checkbox-label mb-3">{{ labelName }}</label>
  <div [class.column]="isColumn" class="d-flex checkbox-container">
    <ng-container *ngFor="let checkbox of checkboxes; let i = index">
      <div (click)="checker.click()" [ngClass]="{ 'read-only': rOnly }" class="checkboxes-wrapper">
        <label for="{{ (checkbox?.controlName || controlName) + uniqueId }}" *ngIf="checkbox?.labelFirst">{{ checkbox?.label }}</label>
        <input
          #checker
          class="form_radio"
          [value]="checkbox?.value"
          [readonly]="rOnly"
          type="checkbox"
          formControlName="{{ checkbox?.controlName || controlName }}"
          id="{{ (checkbox?.controlName || controlName) + uniqueId }}"
        />
        <!-- <labels
          for="{{ checkbox?.controlName||controlName + uniqueId }}"
          *ngIf="!checkbox?.labelFirst"
          >{{ checkbox?.label }}</labels
        > -->
        <label
          *ngIf="!checkbox?.labelFirst"
          for="{{ (checkbox?.controlName || controlName) + uniqueId }}"
          class="form_radio_label d-inline-flex"
        >
          <span class="form_checkbox_btn"></span>
        </label>
        <p class="d-inline-flex click">
          {{ checkbox?.label }}
        </p>
      </div>
      <!-- <div class="help-block" *ngIf="isFieldInvalid(controlName)">
        <p class="required" *ngIf="group?.get(controlName)?.errors!['required']">This field is required</p>
      </div> -->
    </ng-container>
  </div>
</div>
<div
  *ngIf="inputName === 'new-checkbox'"
  class="form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }}"
  [formGroup]="group"
>
  <label>{{ labelName }}</label>

  <div [class.column]="isColumn" class="d-flex checkbox-container" id="checkbox-container-{{ uniqueId }}">
    <ng-container *ngFor="let checkbox of checkboxes; let i = index">
      <div (click)="!rOnly && checker.click()" formArrayName="{{ controlName }}" class="checkboxes-wrapper">
        <label *ngIf="checkbox?.labelFirst">{{ checkbox?.label }}</label>
        <input
          #checker
          (change)="onCheckboxChange($event, i)"
          class="form_radio"
          [value]="checkbox?.value"
          [formControl]="$any(group?.get(controlName))?.at(i)"
          type="checkbox"
        />
        <!-- <label
          for="{{ controlName + uniqueId }}"
          *ngIf="!checkbox?.labelFirst"
          >{{ checkbox?.label }}</label
        > -->
        <label *ngIf="!checkbox?.labelFirst" class="form_radio_label d-inline-flex">
          <span #span class="form_checkbox_btn"></span>
        </label>
        <p class="d-inline-flex click">
          {{ checkbox?.label }}
        </p>
      </div>
      <!-- <div class="help-block" *ngIf="isFieldInvalid(controlName)">
        <p class="required" *ngIf="group?.get(controlName)?.errors!['required']">This field is required</p>
      </div> -->
    </ng-container>
    <ng-container *ngFor="let error of errorMessages?.[controlName]">
      <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
        <p>
          {{ error.message }}
        </p>
      </div>
    </ng-container>
  </div>
</div>

<!-- Phoneee -->
<div *ngIf="inputName === 'phoneInput'" class="phone-group-input" [formGroup]="group">
  <div [class.background]="isBackground" class="input-group form-group">
    <!-- <label class="label phone-label"
        >{{ labelName }} <span *ngIf="strictMode"> {{ group | showAsterisk : controlName }}</span></label
      > -->
    <div class="xmd:flex grid grid-cols-1">
      <div class="phone-number grow input-group-prepend">
        <app-phone-input
          [svg]="flagSvg"
          [isDisabled]="disablePhone"
          [rOnly]="rOnlyPhone"
          (regionChange)="changeRegion($event)"
          (code)="phoneCode = $event"
          [partialPhoneNumber]="partialPhoneNumber"
          [regionCode]="regionCode"
          [form]="group"
          [phoneNumberCode]="flagOnly"
          [flagOnly]="flagOnly"
          [controlName]="controlName"
          [isRequired]="true"
        ></app-phone-input>

        <mat-form-field [hideRequiredMarker]="true" class="phone" [class.borderd]="borderd" appearance="fill">
          <mat-label
            [class.bigger]="bigger"
            [matTooltip]="labelName"
            #tooltip="matTooltip"
            [matTooltipDisabled]="!wrap"
            [ngClass]="{ 'wrap-label': wrap }"
            *ngIf="labelName"
            >{{ labelName }}
          </mat-label>
          <input
            (paste)="pasted($event)"
            (input)="preventNonNumeric($event)"
            [class.error-border]="isFieldInvalid(controlName)"
            (autofocus)="group.get(controlName).markAsTouched()"
            type="text"
            class="form-control phone"
            id="{{ controlName + uniqueId }}"
            [formControlName]="controlName"
            matInput
          />
        </mat-form-field>
      </div>
      <ng-container *ngTemplateOutlet="appendNext"></ng-container>
    </div>
  </div>
  <ng-container *ngFor="let error of errorMessages?.[controlName]">
    <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
      <p>
        {{ error.message }}
      </p>
    </div>
  </ng-container>
</div>

<div
  *ngIf="inputName === 'select'"
  class="select-template form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }}"
  [formGroup]="group"
>
  <label
    [matTooltip]="labelName"
    #tooltip="matTooltip"
    [matTooltipDisabled]="!wrap"
    [ngClass]="{ 'wrap-label': wrap }"
    for="{{ controlName + uniqueId }}"
    >{{ labelName }} <span *ngIf="strictMode">{{ group | showAsterisk : controlName }}</span>
    <span *ngIf="sideText" class="side-text">({{ sideText }}).</span></label
  >
  <ng-select
    id="{{ controlName + uniqueId }}"
    #select
    (click)="onOpen(select)"
    [virtualScroll]="true"
    (close)="onClose()"
    [clearable]="false"
    [formControlName]="controlName"
    [items]="options"
    [multiple]="false"
    [ngClass]="{ disabled: rOnly || isDisabled }"
    [searchable]="false"
    [searchFn]="search"
    [placeholder]="!select?.selectedItems?.length ? placeholderName || '' : ''"
    autocomplete="nothing"
    bindLabel="{{ bindLabel }}"
    bindValue="{{ bindValue }}"
  >
    <ng-template ng-label-tmp let-item="item">
      <div class="start">
        {{ item[bindValue!] != null ? item[bindLabel!] : placeholderName || '' }}
      </div>
    </ng-template>
    <ng-template let-index="index" let-search="searchTerm" ng-option-tmp let-item="item">
      <span>
        {{ item[bindLabel!] }}
      </span>
    </ng-template>
    <ng-template *ngIf="!noSearch" ng-header-tmp>
      <input
        class="search-input"
        #searchInput
        autocomplete="walaishi"
        placeholder="{{ searchPlaceholder || 'Type country here...' }}"
        type="search"
        (input)="filter(select, $event.target)"
      />
    </ng-template>
  </ng-select>
  <ng-container *ngFor="let error of errorMessages?.[controlName]">
    <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
      <p>
        {{ error.message }}
      </p>
    </div>
  </ng-container>
</div>
<div *ngIf="inputName === 'date'" class="form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }}" [formGroup]="group">
  <label *ngIf="labelName" for="{{ controlName + uniqueId }}"
    >{{ labelName }} <span *ngIf="strictMode">{{ group | showAsterisk : controlName }}</span>
    <span *ngIf="sideText" class="side-text">({{ sideText }})</span></label
  >
  <div class="form-append date">
    <input
      autocomplete="walaishiiiiii"
      aria-autocomplete="walaishiiiiii"
      matInput
      [matDatepicker]="picker"
      [min]="min"
      (blur)="group?.get(controlName)?.markAsUntouched()"
      #dateRef
      class="form-control"
      [readonly]="rOnly"
      [disabled]="isDisabled"
      placeholder="{{ placeholderName }}"
      formControlName="{{ controlName }}"
      id="{{ controlName + uniqueId }}"
      [step]="stepNumber"
      (click)="picker.open()"
    />

    <mat-datepicker #picker></mat-datepicker>
    <div (click)="picker.open()" class="appended date-append">
      <i-feather name="calendar"></i-feather>
    </div>
  </div>
  <ng-container *ngFor="let error of errorMessages?.[controlName]">
    <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
      <p *ngIf="!error.messageKey">
        {{ error.message }}
      </p>
      <p *ngIf="error.messageKey">
        {{ group.get(controlName)?.errors?.[error.messageKey] }}
      </p>
    </div>
  </ng-container>
</div>

<div *ngIf="inputName === 'random'" class="form-group col-md-{{ size }}">
  <label
    [matTooltip]="labelName"
    #tooltip="matTooltip"
    [matTooltipDisabled]="!wrap"
    [ngClass]="{ 'wrap-label': wrap }"
    *ngIf="labelName"
    for="{{ controlName + uniqueId }}"
    >{{ labelName }} <span *ngIf="sideText" class="side-text">({{ sideText }})</span></label
  >
  <!-- <ng-content select="[underLabel]"></ng-content> -->

  <div class="form-append">
    <input
      *ngIf="inputType === 'password'"
      class="form-control"
      [readOnly]="rOnly"
      value="{{ value }}"
      placeholder="{{ placeholderName }}"
      type="password"
      id="{{ controlName + uniqueId }}"
      [step]="stepNumber"
    />
    <input
      *ngIf="inputType === 'text'"
      class="form-control"
      [readOnly]="rOnly"
      placeholder="{{ placeholderName }}"
      type="text"
      value="{{ value }}"
      id="{{ controlName + uniqueId }}"
    />
    <textarea
      rows="4"
      *ngIf="inputType === 'fake'"
      class="form-control"
      value="{{ value }}"
      [readOnly]="rOnly"
      placeholder="{{ placeholderName }}"
      id="{{ controlName + uniqueId }}"
    ></textarea>
    <textarea
      rows="4"
      *ngIf="inputType === 'textarea'"
      class="form-control"
      value="{{ value }}"
      [readOnly]="rOnly"
      placeholder="{{ placeholderName }}"
      id="{{ controlName + uniqueId }}"
    ></textarea>
    <input
      *ngIf="inputType === 'number'"
      class="form-control"
      [readOnly]="rOnly"
      placeholder="{{ placeholderName }}"
      type="number"
      value="{{ value }}"
      id="{{ controlName + uniqueId }}"
    />
    <div *ngIf="append" class="appended">
      <ng-container *ngTemplateOutlet="appended"></ng-container>
    </div>
  </div>
</div>
<!-- radioItems -->
<div
  *ngIf="inputName === 'radioItems'"
  class="form-group col-md-{{ size }} {{ group | showFieldStyle : controlName }} radio-items"
  [formGroup]="group"
>
  <label class="radio-label mb-3" for="{{ controlName }}"
    >{{ labelName }} <span *ngIf="strictMode">{{ group | showAsterisk : controlName }}</span>
    <span *ngIf="sideText" class="side-text">({{ sideText }})</span>
  </label>
  <div [ngClass]="{ 'read-only': rOnly }" [class.column]="isColumn" class="d-flex radio-container">
    <ng-container *ngFor="let radio of radios; let i = index">
      <div class="radio">
        <!-- <label [class.darker]="darker" for="{{ controlName + i + uniqueId }}" *ngIf="radio?.labelFirst">{{ radio?.label }}</label>
        <input
          class="radio"
          [value]="radio?.value"
          (paste)="pasted($event)"
          [readonly]="rOnly"
          type="radio"
          formControlName="{{ controlName }}"
          id="{{ controlName + i + uniqueId }}"
        />
        <label [class.darker]="darker" for="{{ controlName + i + uniqueId }}" *ngIf="!radio?.labelFirst">{{ radio?.label }}</label> -->

        <label for="{{ controlName + i + uniqueId }}" class="radio-button">
          <input
            type="radio"
            class="radio"
            [value]="radio?.value"
            (paste)="pasted($event)"
            [readonly]="rOnly"
            type="radio"
            formControlName="{{ controlName }}"
            id="{{ controlName + i + uniqueId }}"
          />
          <div class="radio-circle"></div>
          <span [class.darker]="darker" *ngIf="!radio?.labelFirst" class="radio-label">{{ radio?.label }}</span>
        </label>
      </div>
    </ng-container>
    <ng-container *ngFor="let error of errorMessages?.[controlName]">
      <div class="error-messages" *ngIf="group.get(controlName).hasError(error.type) && group.get(controlName).touched">
        <p>
          {{ error.message }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
