<div class="flex" [attr.position]="position">
  <div [class.open]="isOpen" [class.r-zero]="isOpen" [class.close]="!isOpen" class="select-wrapper" (click)="handleOptions($event)">
    <span *ngIf="multiselect && value"> {{ counter }} Selected </span>
    <span *ngIf="value && !multiselect">{{ value[0] }}</span>
    <span *ngIf="!value"> Select option</span>

    <div *ngIf="isOpen" class="options-wrapper">
      <div *ngIf="search" class="search-wrapper">
        <i-feather class="search-icon" name="search"></i-feather>
        <input [formControl]="myControl" type="search" class="search" placeholder="search" (blur)="onBlur()" (focus)="onFocus()" #input />
      </div>
      <div class="option-wrapper">
        <av-option
          (onValueChanged)="OnSelectValue($event)"
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          [pick]="selectedValues.includes(option)"
        >
          {{ option }}</av-option
        >
      </div>
    </div>
  </div>
</div>
