<div class="flex-wrapper">
  <div class="container">
    <div class="panel">
      <div class="top-head">
        <img class="avenews-logo" src="{{ loginConfig?.logo }}" alt="Avenwes-GT" />
      </div>
      <div class="expired-link">
        <img src="{{loginConfig?.icon}}" alt="expired">
        <p class="title-expire">Link expired</p>
        <p class="subtitle-expired">It seems that this link has expired or used. To receive a new link to reset your password, just click below</p>
        <button class="expired-button" (click)="resendLink()">Resend link</button>
      </div>
    </div>
  </div>

</div>
