<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Trade desk builder'"></av-breadcrumbs-template>
<div id="trade-desk-container">
  <div class="desk-top">
    <div>
      <h2>Trade desk</h2>
    </div>
    <div class="actions">
      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="!editMode">
          <i-feather (click)="edit()" class="edit" name="edit-2"></i-feather>
        </ng-container>
        <ng-container *ngIf="editMode">
          <div (click)="saveCategories()" class="boxer">
            <i-feather name="check"></i-feather>
          </div>
          <div (click)="cancelEdit()" class="boxer">
            <i-feather class="exer" name="x"></i-feather>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <div class="loader">
          <img src="../../../assets/loading.gif" alt="" />
        </div>
      </ng-container>
    </div>
  </div>
  <div class="desk-container">
    <div [formGroup]="categoryForm" class="categories">
      <div class="category">
        <div class="category-header">
          <div>
            <textarea
              [ngClass]="{ 'ng-touched': scoreControl.invalid, 'ng-invalid': scoreControl.invalid }"
              readonly
              placeholder="Category name"
              rows="1"
              [value]="'ML Model'"
              class="category-name"
            ></textarea>
          </div>
          <div [ngClass]="{ 'has-error': scoreControl?.invalid && scoreControl?.touched }" data-percentage="%" class="score-holder">
            <input
              [ngClass]="{ 'has-error': scoreControl?.invalid && scoreControl?.touched }"
              [readonly]="!editMode"
              (keydown)="restrict($event)"
              [max]="100"
              min="0"
              placeholder="%"
              #input
              type="number"
              [formControl]="scoreControl"
            />
          </div>
        </div>
      </div>
      <div
        (click)="chooseCategory(i)"
        [hidden]="category?.value?.deleted"
        [ngClass]="{ active: category?.value?._id === chosenCategory?.['_id']&&!editMode,inactive: !category?.value?.active }"
        [formGroup]="$any(category)"
        *ngFor="let category of categoryFormArray?.controls; let i = index; trackBy: trackByFn"
        class="category"
      >
        <div class="category-header">
          <div>
            <textarea
              [readonly]="!editMode"
              placeholder="Category name"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="3"
              formControlName="name"
              [value]="category?.value?.name"
              class="category-name"
            ></textarea>
          </div>
          <div
            [ngClass]="{ 'has-error': categoryForm?.hasError('weights') && category?.value?.active }"
            [attr.data-percentage]="category?.value?.score ? '%' : ''"
            class="score-holder"
          >
            <input
              [ngClass]="{ 'help-block': categoryForm?.hasError('weights') && category?.value?.active }"
              [readonly]="!editMode"
              (keydown)="restrict($event)"
              [max]="50"
              placeholder="%"
              #input
              type="number"
              formControlName="score"
            />
          </div>
        </div>
        <div #footer class="category-footer-wrapper">
          <div class="category-footer">
            <div class="left">
              <av-slide-toggle formControlName="active"></av-slide-toggle>
            </div>
            <!-- <div *ngIf="isEditing && activeEdit[i]" class="right">
                <i-feather (click)="edit(i)" class="edit" name="check"></i-feather>
                <i-feather (click)="remove(i)" class="trash" name="x"></i-feather>
              </div> -->
            <div class="right">
              <!-- <i-feather (click)="edit(i)" class="edit" name="edit-2"></i-feather> -->
              <i-feather (click)="remove(category)" class="trash" name="trash-2"></i-feather>
            </div>
          </div>
        </div>
      </div>
      <div class="category">
        <div class="category-header">
          <div>
            <textarea
              readonly
              placeholder="Category name"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="3"
              [value]="'Finance application score'"
              class="category-name"
            ></textarea>
          </div>
        </div>
        <div class="height-auto category-footer-wrapper">
          <div [formGroup]="threshForm" class="category-footer flex-column">
            <div class="category-header">
              <div>
                <textarea
                  readonly
                  placeholder="Category name"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="3"
                  [value]="'Approval threshold ≤'"
                  class="category-name"
                ></textarea>
              </div>
              <div
                [ngClass]="{ 'has-error': threshForm?.get('approval')?.errors }"
                [attr.data-percentage]="threshForm?.value?.approval ? '%' : ''"
                class="score-holder"
              >
                <input
                  [ngClass]="{ 'help-block': threshForm?.get('approval')?.errors }"
                  [readonly]="!editMode"
                  (keydown)="restrict($event)"
                  [max]="100"
                  [min]="0"
                  #input
                  type="number"
                  formControlName="approval"
                />
              </div>
            </div>
            <div class="category-header">
              <div>
                <textarea
                  readonly
                  placeholder="Category name"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="3"
                  [value]="'Decline threshold ≥'"
                  class="category-name"
                ></textarea>
              </div>
              <div
                [ngClass]="{ 'has-error': threshForm?.get('decline')?.errors }"
                [attr.data-percentage]="threshForm?.value?.approval ? '%' : ''"
                class="score-holder"
              >
                <input
                  [ngClass]="{ 'help-block': threshForm?.get('decline')?.errors }"
                  [readonly]="!editMode"
                  (keydown)="restrict($event)"
                  [max]="100"
                  [min]="0"
                  #input
                  type="number"
                  formControlName="decline"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editMode">
        <div (click)="addEmptyCategory()" class="add-category">
          <span>
            <i-feather class="edit" name="plus"></i-feather>
            Add category
          </span>
        </div>
      </div>
      <p *ngIf="editMode">Scores total - {{ calcTotalScore() + mlScore }}</p>
      <div *ngIf="categoryForm?.hasError('weights')">
        <p class="help-block">Total scores are greater than 100%</p>
      </div>
      <div *ngIf="threshForm?.invalid && editMode">
        <p class="help-block">Invalid thresholds</p>
      </div>
    </div>
    <div class="questions">
      <!-- <p class="text-right">{{ calcTotalQuestionScore }} score</p> -->
      <div class="questions-panel">
        <div class="add-question">
          <button (click)="addQuestion()" class="bttn-primary">Add question</button>
        </div>
        <div *ngFor="let question of chosenCategory?.questions" class="question-section">
          <div class="question-header">
            <div>
              <p class="question-name">{{ question?.label }}</p>
            </div>
            <div class="points">
              <p *ngIf="question?.scorable">Included in calculations</p>
              <i-feather #icon (click)="expand(content, icon)" name="chevron-down"></i-feather>
            </div>
          </div>
          <div #content class="question-content">
            <div class="content">
              <app-base-form-template
                *ngIf="question?.type === 'textarea' || question?.type === 'number'"
                [size]="+question?.size || 6"
                labelName="{{ question?.label }}"
                [group]="stamForm"
                value="User's answer"
                [rOnly]="true"
                [inputType]="question?.type"
                controlName="stam"
                inputName="default"
              >
              </app-base-form-template>

              <app-base-form-template
                [rOnly]="true"
                (openSelect)="expandMore(content, $event)"
                *ngIf="question?.type === 'radio' || question?.type === 'checkbox' || question?.type === 'select'"
                [size]="+question?.size || 6"
                labelName="{{ question?.label }}"
                [group]="stamForm"
                [inputType]="question?.type"
                [inputName]="question?.type"
                [radios]="question?.multipleChoices"
                [checkboxes]="question?.multipleChoices"
                [options]="question?.multipleChoices"
                bindLabel="label"
                bindValue="label"
                [isColumn]="true"
                controlName="stam"
              ></app-base-form-template>
              <!-- <app-base-form-template
                [isColumn]="true"
                *ngIf="question?.type === 'radio'"
                [size]="+question?.size || 6"
                labelName="{{ question?.label }}"
                [group]="stamForm"
                [rOnly]="true"
                inputType="radio"
                inputName="radio"
                [radios]="question?.multipleChoices"
              >
              </app-base-form-template> -->
            </div>
          </div>
          <div class="divider"></div>
          <div class="question-footer">
            <div class="left">
              <av-slide-toggle [(ngModel)]="question.active" (ngModelChange)="activeQuestion(question)"></av-slide-toggle>
            </div>
            <div class="right">
              <i-feather (click)="editQuestion(question)" class="edit" name="edit-2"></i-feather>
              <i-feather (click)="deleteQuestion(question)" class="trash" name="trash-2"></i-feather>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons"></div>
    </div>
  </div>
</div>
<av-create-question (closed)="clearQuestionData()" [category]="chosenCategory" [question]="question" [isEdit]="isEdit"></av-create-question>
