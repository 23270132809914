import { Component } from '@angular/core';

@Component({
  selector: 'app-root',

  /* just an example of use */

  template: `
    <av-tabs>
      <av-tab tabTitle="Goods Received Note">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
        ever since the 1500s, when an unknown printer took a galley of.
      </av-tab>
      <av-tab tabTitle="Application"> Tab 2 content </av-tab>
      <av-tab tabTitle="Documents"> Tab 3 content </av-tab>
      <av-tab tabTitle="Members">
        Tab 4 LONGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG CONTENTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT
      </av-tab>
    </av-tabs>
  `
})
export class TabGroupComponent {
  title = 'tabs-example';
}
