import { Router } from '@angular/router';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SpinnerToggleService, TwoFaService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import * as $ from 'jquery';
import { PaymentService } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { Transaction } from '@avenews/base-types';
@Component({
  selector: 'app-topup-component',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent extends BaseValidationComponent implements OnInit, OnChanges {
  @Input() accounts: any;
  @Input() user: any;
  beneficiaryAccountId: string;
  beneficiaryId: string;
  topupForm: FormGroup;
  balanceType = [];
  errorMessages = {
    amount: [
      { type: 'required', message: 'This field is required' },
      { type: 'min', message: 'Invalid number' }
    ]
  };
  constructor(
    fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private twoFaService: TwoFaService
  ) {
    super();
    this.topupForm = fb.group({
      amount: [undefined, [Validators.required, Validators.min(1)]],
      balanceType: [undefined, Validators.required],
      notes: [undefined]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this?.accounts?.length) {
      const balanceType = [];
      this.accounts.forEach((data) => {
        if (data?.service === PaymentService.AVENEWS_INCOME) {
          balanceType.push({
            viewValue: 'Income Balance',
            value: data
          });
        }
      });
      this.balanceType = balanceType;
    }
  }
  ngOnInit(): void {}
  close() {
    $('#addTopupWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  async onTopup() {
    this.validateForm(this.topupForm);

    if (this.topupForm.valid) {
      if (await this.twoFaService.open()) {
        this.beneficiaryAccountId = this.topupForm.value.balanceType._id;
        this.beneficiaryId = this.topupForm.value.balanceType.beneficiaryId._id;
        this.spinner.showSpinner();
        this.http
          .post(`${environment.new_api_url}/api/transaction/balance/top-up`, {
            amount: this.topupForm.get('amount').value,
            beneficiaryAccountId: this.beneficiaryAccountId,
            notes: this.topupForm.get('notes').value
          })
          .pipe(
            catchError((err) => {
              this.alertService.showAlert(err?.error?.message, 'danger');
              this.spinner.hideSpinner();
              $('#addTopupWizard').fadeOut();
              this.deleteData();
              this.router.navigateByUrl(this.router.url);
              return EMPTY.pipe();
            })
          )
          .subscribe((data: Transaction) => {
            this.alertService.showAlert(`Account has been topped up with ${data.amount} KES successfuly`, 'success');
            $('#addTopupWizard').fadeOut();
            this.deleteData();
            this.router.navigateByUrl(this.router.url);
            this.spinner.hideSpinner();
          });
      }
    }
  }
  deleteData() {
    this.topupForm.reset();
    this.topupForm.patchValue({
      amount: undefined,
      balanceType: undefined,
      notes: undefined
    });
  }
}
