import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  transform(status: string): string {
    if (!status) {
      return 'status status-gray';
    } else if (status === 'ACCEPTED' || status === 'approved') {
      // can add any status that should success with ||
      return 'status status-green';
    } else if (status.toLowerCase() === 'paid' || status.toLowerCase() === 'pre-approved') {
      return 'status status-blue';
    } else if (status.toLowerCase() === 'active') {
      // can add any status that should success with ||
      return 'status status-primary';
    } else if (status.toLowerCase() === 'inactive') {
      // can add any status that should success with ||
      return 'status status-inactive';
    } else if (status.toLowerCase() === 'planning') {
      // can add any status that should success with ||
      return 'status status-purple';
    } else if (status.toLowerCase() === 'partially-paid' || status.toLowerCase() === 'partially paid' || status === 'partially_paid') {
      return 'status status-purple';
    } else if (
      status.toLowerCase() === 'awaiting' ||
      status.toLowerCase() === 'awaiting payment' ||
      status === 'PENDING APPROVAL' ||
      status === 'pending' ||
      status === 'processing'
    ) {
      return 'status status-orange';
    } else if (status?.toLowerCase?.() === 'declined' || status === 'rejected') {
      return 'status status-red';
    } else if (status === 'cancelled') {
      return 'status status-gray';
    } else if (status === 'draft') {
      return 'status status-draft';
    } else if (status === 'po-received' || status === 'sale-supplied') {
      return 'status status-green';
    } else if (status === 'po-partially-received' || status === 'sale-partially-supplied') {
      return 'status status-orange';
    } else if (status === 'completed' || status === 'settled') {
      return 'status status-green';
    }
    return 'status status-gray';
  }
}
