import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: any, searchTerm: any, filters?: boolean) {
    if (searchTerm === undefined) {
      return items;
    }
    if (!filters) {
      return items.filter((item: any) => {
        let array = JSON.stringify(item);
        if (typeof item === 'object') {
          array = JSON.stringify(Object.values(item));
        }
        return array.toLowerCase().trim().includes(searchTerm.toLowerCase().trim());
      });
    } else {
      return items.filter((data: any) => {
        return data.name.toLowerCase().includes(searchTerm);
      });
    }
  }
}
