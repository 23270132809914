<base-modal>
  <base-modal-header header="Document information"></base-modal-header>
  <base-modal-body (keyup.enter)="submit()">
    <h2>{{document?.fileName}}</h2>
    <div *ngIf="showPass">
      <app-base-form-template
        [errorMessages]="errorMessages"
        labelName="Password"
        inputType="text"
        inputName="default"
        controlName="password"
        [group]="form"
        [size]="12"
      >
      </app-base-form-template>
    </div>
    <div *ngIf="showExpiryDate">
      <app-base-form-template
        labelName="Expiration Date"
        inputType="date"
        inputName="date"
        controlName="expiryDate"
        [group]="form"
        [size]="12"
      >
      </app-base-form-template>
      <p *ngIf="expiryNotDateMandatory" class="text-xs text-gray-500 leading-4 tracking-normal mt-2 mb-0 font-normal">
        If there is no expiration date, please leave it blank and click on "Submit".<br />
      </p>
    </div>
  </base-modal-body>
  <base-modal-footer>
    <button (click)="close()" class="bttn btn-actions">Close</button>
    <button (click)="submit()" class="bttn btn-primary !text-white">Submit</button>
  </base-modal-footer>
</base-modal>
