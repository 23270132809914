<div class="modal-box" id="addQuestionModal">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>{{ isEdit ? 'Edit' : 'Add' }} Question</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="content-header">Category - {{ category?.name }}</p>
      <div (keyup.enter)="submit()" [formGroup]="questionForm">
        <app-base-form-template
          [group]="questionForm"
          labelName="Question score"
          inputType="checkbox"
          inputName="checkbox"
          [checkboxes]="scoreOptions"
          controlName="scorable"
          [size]="6"
        >
        </app-base-form-template>

        <app-base-form-template
          labelName="Question"
          inputType="textarea"
          inputName="default"
          controlName="label"
          [group]="questionForm"
          [size]="12"
        >
        </app-base-form-template>
      </div>
      <div class="border-top">
        <p class="content-header">Answer</p>
        <div class="form-pair">
          <app-base-form-template
            labelName="Answer type"
            inputType="select"
            inputName="select"
            bindLabel="viewValue"
            bindValue="value"
            controlName="type"
            [group]="questionForm"
            [options]="answerTypes"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            *ngIf="questionForm?.get('type')?.value === 'select'"
            labelName="Dropdown Preview"
            inputType="select"
            inputName="select"
            bindLabel="label"
            bindValue="label"
            controlName="fake"
            [group]="fakeForm"
            [options]="multipleChoicesArray?.value"
            [size]="6"
          ></app-base-form-template>
        </div>
      </div>
      <div
        *ngIf="
          questionForm?.get('type')?.value === 'radio' ||
          questionForm?.get('type')?.value === 'checkbox' ||
          questionForm?.get('type')?.value === 'select'
        "
        class="multiple-choices"
      >
        <div [formGroup]="$any(choice)" class="choices-faker" *ngFor="let choice of multipleChoicesArray.controls; let i = index">
          <div class="choices-wrapper">
            <div class="fake-{{ questionForm?.get('type')?.value }}"></div>
            <div>
              <textarea
                placeholder="Type label here..."
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                formControlName="label"
              ></textarea>
            </div>
          </div>
          <div class="answer-score">
            <app-base-form-template
              labelName="Score"
              inputType="number"
              inputName="default"
              controlName="score"
              placeholderName="Score"
              [group]="$any(choice)"
              [size]="12"
            ></app-base-form-template>
          </div>
          <div class="trasher">
            <i-feather (click)="remove(i)" class="click" name="trash-2"></i-feather>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div (click)="addGroup()" class="add-another">+ Add another</div>
          <div (click)="reset()" class="remove-all">Reset</div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary" (click)="submit()">{{ isEdit ? 'Update' : 'Add' }}</button>
      </div>
    </div>
  </div>
</div>
