<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Mobile transactions'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-users-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (sendFilters)="filter($event)"
  (bulkActions)="actionsBulk($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'BENEFICIARY' &&
        col?.name !== 'HIGH RISK' &&
        col?.name !== 'STATUS' &&
        col?.name !== 'INTERNAL' &&
        col?.name !== 'TYPE'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'INTERNAL'">
      <td>
        {{ item?.internal ? 'Yes' : 'No' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'TYPE'">
      <td>
        {{ getType(item) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY'">
      <td>
        {{ item?.beneficiary?.beneficiary | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'HIGH RISK'">
      <td>
        {{ item?.transactionId?.includes('HR') ? 'High risk' : 'Low risk' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="{{ !item?.status ? '' : (item?.status | status) }}">
          {{ item?.status || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
