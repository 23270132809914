<div class="modal-box" id="activation">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Activation</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="top-up-header">
        {{ user?.personalInformation?.firstName }} {{ user?.personalInformation?.lastName }} account is {{ activation }}
      </p>
      <button (click)="activateOrDisable()" class="active-bttn">{{ activationEp }} account</button>
    </div>
  </div>
</div>
