import { Component, Injector, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { actionMapper } from '../services/agents.helper';
import { AgentsService } from '../services/agents.service';

@Component({
  selector: 'frontend-agent-commissions',
  templateUrl: './agent-commissions.component.html',
  styleUrls: ['./agent-commissions.component.scss']
})
export class AgentCommissionsComponent extends ListNoSdkComponent<any> {
  statuses = [
    {
      realName: 'Approved',
      status: 'approved',
      color: 'green'
    },
    {
      realName: 'Declined',
      status: 'declined',
      color: 'red'
    }
  ];

  constructor(
    injector: Injector,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private agentsService: AgentsService
  ) {
    super(injector, {
      name: 'getAllAgents',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/commissions/agent/:agentId`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'commissions';
    this.stickyFilters = this.agentsService.getIncomingDatesFilter();
  }

  ngOnInit(): void {
    super.apiUrl=this.apiUrl.replace(':agentId', this.route.snapshot.params['id']);

    super.init();

    this.postPagination();
    this.paginated.subscribe((val: string) => {
      this.postPagination();
    });
    this.listConfig = {
      emptyState: {
        title: 'No commissions for this agent yet',
        img: 'assets/images/empty-states/organization.png',
        button: undefined
      },
      actions: [],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['ID', 'ACTIONS'],
      mainTitle: 'Incoming Commissions - from 1st-15th or 15th to end of month',
      columns: [
        {
          name: 'REASON',
          dtoName: 'reason',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'LEAD BUSINESS NAME',
          dtoName: 'loanId.lead.businessName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'DATE',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'VALUE',
          dtoName: 'amount',
          type: 'number',
          pipe: 'decimal',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text',
          selected: true
        },
        {
          name: 'LEAD PHONE NUMBER',
          dtoName: 'loanId.lead.phoneNumber',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTION',
          dtoName: 'action',
          pipe: 'titleCase',
          type: 'text',
          selected: false
        },
        {
          name: 'ACTIONS',
          dtoName: undefined,
          selected: true
        }
      ],

      filters: [
        {
          name: 'REASON',
          dtoName: 'actionName',
          type: 'text'
        },
        {
          name: 'LEAD BUSINESS NAME',
          dtoName: 'loanId.lead.businessName',
          type: 'text'
        },
        {
          name: 'DATE',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'VALUE',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        },
        {
          name: 'LEAD PHONE NUMBER',
          dtoName: 'loanId.lead.phoneNumber',
          type: 'text'
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }

  primaryActions(val?: string) {
    switch (val) {
      case 'new':
        break;

      default:
    }
  }

  actions(e: { action: any; entity?: any }) {
    switch (e.action.action) {
      case 'changeStatus':
        break;
      case 'view':
        this.viewAgent(e.entity);
        break;
    }
  }

  viewAgent(user: any) {
    this.router.navigate(['agents', user._id]);
  }

  valueChains(chains: string[], tooltip?: boolean) {
    if (!chains || chains?.length <= 1) {
      return chains?.[0] || '-';
    }
    if (tooltip) {
      return chains?.join(',\n');
    }
    return 'Multiple';
  }

  ownerFullName(owner: any) {
    return owner.personalInformation.firstName + ' ' + owner.personalInformation.lastName;
  }

  async postPagination() {
    const list = [];
    for (const item of this.list) {
      item['reason'] = actionMapper[item?.action];
      item['isBlocked'] = item.blocked ? 'Blocked' : 'Not Blocked';
      list.push(item);
    }
    this.list = list.slice();
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
}
