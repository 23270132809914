import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(text: string, length: number = 20, dots: string = '...'): string {
    if (text) {
      if (text.length > length) {
        const truncated: string = text.substring(0, length).trim() + dots;
        return truncated;
      }
    }
    return text;
  }
}
