import { HeaderMenu } from './header.types';
import { RouteInfo } from './sidebar.types';

export interface ShellConfig {
  shared: {
    env: any;
  };
  sidebar: RouteInfo[];
  header: HeaderMenu[];
}
