import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class CategoriesResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    const loanId = route.params['id'];
    return this.http.get(this.environment.new_api_url + `/api/trade-desk/category${loanId ? '/loan/' + loanId : ''}`);
  }
}
