import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Column } from '../../helpers/types/list.types';
declare const $: any;
@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit, OnChanges {
  @Input() exportId?: string;
  @Input() cols?: Column[];
  @Input() totalCount?: number;
  @Output() exportEmit = new EventEmitter();
  exportForm: FormGroup;
  checkAllForm: FormGroup;
  columnsCheckbox: any[] = [];

  constructor(private fb: FormBuilder) {
    this.exportForm = this.fb.group({});
    this.checkAllForm = this.fb.group({
      yes: [true]
    });
  }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cols'] && this.cols) {
      this.columnsCheckbox = [];
      this.exportForm = this.fb.group({});
      this.cols.forEach((data) => {
        if (data.dtoName) {
          this.columnsCheckbox.push({
            label: data.name,
            value: true,
            controlName: this.fixString(data.name)
          });
          this.exportForm.addControl(
            this.fixString(data.name),
            new FormControl({
              value: true,
              disabled: data.sticky
            })
          );
        }
      });
      this.exportForm.valueChanges.subscribe((data) => {
        const allTrue = Object.values(data).every((vals: any) => {
          return vals;
        });
        if (allTrue) {
          this.checkAllForm.get('yes')!.setValue(true, { emitEvent: false });
        } else {
          this.checkAllForm.get('yes')!.setValue(false, { emitEvent: false });
        }
      });
      this.checkAllForm.get('yes')!.valueChanges.subscribe((data) => {
        this.checkAll(Boolean(data));
      });
    }
  }
  checkAll(flag: boolean) {
    const stickies = this.cols!.map((data) => {
      return data.sticky && this.fixString(data.name);
    });

    Object.keys(this.exportForm.controls).forEach((data) => {
      if (!stickies.includes(data)) {
        this.exportForm.get(data)!.setValue(flag, { emitEvent: false });
      }
    });
  }
  fixString(str: string) {
    return str.replace(/ /g, '');
  }
  submitExport() {
    $('#' + this.exportId + 'success').fadeOut();
  }
  export() {
    this.exportEmit.emit(this.exportForm.getRawValue());
    // $('#exportWizard').fadeOut('fast');
    // $('#successModal').css({ display: 'flex' });
  }
  cancel() {
    $('#' + this.exportId).fadeOut('fast');
  }
  closeExportModal() {
    $('#' + this.exportId).fadeOut('fast');
  }
}
