<div class="modal-box" id="addUserWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>New User</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="top-up-header">Create User</p>
      <div [formGroup]="userForm">
        <div class="form-pair hr">
          <app-base-form-template
            labelName="User type"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="role"
            placeholderName="Choose type"
            [noSearch]="false"
            [options]="roles"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <app-base-form-template
            labelName="First Name"
            inputType="text"
            inputName="default"
            controlName="firstName"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Last Name"
            inputType="text"
            inputName="default"
            controlName="lastName"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <div class="form-group">
            <label>Phone Number *</label>
            <div [formGroup]="userForm" class="input-group">
              <div class="phone-number input-group-prepend">
                <app-phone-input
                  [isDisabled]="true"
                  (regionChange)="changeRegion($event)"
                  (code)="phoneCode = $event"
                  [isRequired]="true"
                  [partialPhoneNumber]="partialPhoneNumber"
                  [regionCode]="regionCode"
                  [form]="userForm"
                  [controlName]="'phoneNumber'"
                ></app-phone-input>
              </div>
              <input autocomplete="walaishiiiiii" type="tel" class="form-control" id="phone" formControlName="phoneNumber" />
            </div>
            <ng-container *ngFor="let error of errorMessages?.['phoneNumber']">
              <div class="error-messages" *ngIf="userForm.get('phoneNumber').hasError(error.type) && userForm.get('phoneNumber').touched">
                <p>
                  {{ error.message }}
                </p>
              </div>
            </ng-container>

            <span hidden translate>Phone Number</span>
          </div>
          <app-base-form-template
            labelName="Country"
            bindLabel="viewValue"
            bindValue="viewValue"
            inputType="select"
            inputName="select"
            controlName="country"
            placeholderName="Choose Country"
            [options]="countries"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <app-base-form-template
          labelName="Company Name"
          inputType="text"
          inputName="default"
          controlName="companyName"
          [group]="userForm"
          [size]="6"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair border-top">
        <app-base-form-template
          labelName="Username"
          inputType="text"
          inputName="default"
          [errorMessages]="errorMessages"
          controlName="username"
          [group]="userForm"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          [errorMessages]="errorMessages"
          labelName="Password"
          inputType="text"
          inputName="default"
          controlName="password"
          [group]="userForm"
          [size]="6"
        >
        </app-base-form-template>
      </div>
      <div class="d-flex">
        <div [formGroup]="userForm" class="form-group col-md-6">
          <label for="checkbox">Create Wallet?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox" formControlName="createWallet" />
            <label class="form-check-label" for="checkbox"> Create Wallet </label>
          </div>
        </div>
        <app-base-form-template
          [errorMessages]="errorMessages"
          labelName="Requested amount"
          inputType="number"
          inputName="default"
          controlName="creditRequested"
          [group]="userForm"
          [size]="6"
        >
          <p class="under-label" underLabel>(From application)</p>
          <span #append append>KES</span>
        </app-base-form-template>
      </div>
      <div class="topup-modal-footer">
        <button class="btn btn-primary" (click)="createUser()">Create</button>
      </div>
    </div>
  </div>
</div>
