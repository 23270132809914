import { map } from 'rxjs/operators';
import { catchError, EMPTY, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class TDDocumentsResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    const loanId = route.params['id'];
    return this.http.get(this.environment.new_api_url + `/api/trade-desk/ml-score-data/loan/${loanId}`).pipe(
      map((data: any) => {
        return data.files;
      }),
      catchError((err) => {
        return of([]);
      })
    );
  }
}
