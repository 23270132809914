export const defaultValues = {
  profile_picture: 'https://s3.us-east-2.amazonaws.com/agt-files-test/defaults/profile-pic.jpg',
  agri_picture: 'https://s3.us-east-2.amazonaws.com/agt-files-test/defaults/agricultural.png',
  processed_picture: 'https://s3.us-east-2.amazonaws.com/agt-files-test/defaults/processed.png',
  items_per_page: 10,
  items_per_page_1: 7,
  items_per_page_2: 12
};
export const counties = [
  {
    viewValue: 'Mombasa',
    value: 'Mombasa',
    label: 'Mombasa'
  },
  {
    viewValue: 'Kwale',
    value: 'Kwale',
    label: 'Kwale'
  },
  {
    viewValue: 'Kilifi',
    value: 'Kilifi',
    label: 'Kilifi'
  },
  {
    viewValue: 'Tana River',
    value: 'Tana River',
    label: 'Tana River'
  },
  {
    viewValue: 'Lamu',
    value: 'Lamu',
    label: 'Lamu'
  },
  {
    viewValue: 'Taita Taveta',
    value: 'Taita Taveta',
    label: 'Taita Taveta'
  },
  {
    viewValue: 'Garissa',
    value: 'Garissa',
    label: 'Garissa'
  },
  {
    viewValue: 'Wajir',
    value: 'Wajir',
    label: 'Wajir'
  },
  {
    viewValue: 'Mandera',
    value: 'Mandera',
    label: 'Mandera'
  },
  {
    viewValue: 'Marsabit',
    value: 'Marsabit',
    label: 'Marsabit'
  },
  {
    viewValue: 'Isiolo',
    value: 'Isiolo',
    label: 'Isiolo'
  },
  {
    viewValue: 'Meru',
    value: 'Meru',
    label: 'Meru'
  },
  {
    viewValue: 'Tharaka Nithi',
    value: 'Tharaka Nithi',
    label: 'Tharaka Nithi'
  },
  {
    viewValue: 'Embu',
    value: 'Embu',
    label: 'Embu'
  },
  {
    viewValue: 'Kitui',
    value: 'Kitui',
    label: 'Kitui'
  },
  {
    viewValue: 'Machakos',
    value: 'Machakos',
    label: 'Machakos'
  },
  {
    viewValue: 'Makueni',
    value: 'Makueni',
    label: 'Makueni'
  },
  {
    viewValue: 'Nyandarua',
    value: 'Nyandarua',
    label: 'Nyandarua'
  },
  {
    viewValue: 'Nyeri',
    value: 'Nyeri',
    label: 'Nyeri'
  },
  {
    viewValue: 'Kirinyaga',
    value: 'Kirinyaga',
    label: 'Kirinyaga'
  },
  {
    viewValue: "Murang'a",
    value: "Murang'a",
    label: "Murang'a"
  },
  {
    viewValue: 'Kiambu',
    value: 'Kiambu',
    label: 'Kiambu'
  },
  {
    viewValue: 'Turkana',
    value: 'Turkana',
    label: 'Turkana'
  },
  {
    viewValue: 'West Pokot',
    value: 'West Pokot',
    label: 'West Pokot'
  },
  {
    viewValue: 'Samburu',
    value: 'Samburu',
    label: 'Samburu'
  },
  {
    viewValue: 'Trans Nzoia',
    value: 'Trans Nzoia',
    label: 'Trans Nzoia'
  },
  {
    viewValue: 'Uasin Gishu',
    value: 'Uasin Gishu',
    label: 'Uasin Gishu'
  },
  {
    viewValue: 'Elgeyo/Marakwet',
    value: 'Elgeyo/Marakwet',
    label: 'Elgeyo/Marakwet'
  },
  {
    viewValue: 'Nandi',
    value: 'Nandi',
    label: 'Nandi'
  },
  {
    viewValue: 'Baringo',
    value: 'Baringo',
    label: 'Baringo'
  },
  {
    viewValue: 'Laikipia',
    value: 'Laikipia',
    label: 'Laikipia'
  },
  {
    viewValue: 'Nakuru',
    value: 'Nakuru',
    label: 'Nakuru'
  },
  {
    viewValue: 'Narok',
    value: 'Narok',
    label: 'Narok'
  },
  {
    viewValue: 'Kajiado',
    value: 'Kajiado',
    label: 'Kajiado'
  },
  {
    viewValue: 'Kericho',
    value: 'Kericho',
    label: 'Kericho'
  },
  {
    viewValue: 'Bomet',
    value: 'Bomet',
    label: 'Bomet'
  },
  {
    viewValue: 'Kakamega',
    value: 'Kakamega',
    label: 'Kakamega'
  },
  {
    viewValue: 'Vihiga',
    value: 'Vihiga',
    label: 'Vihiga'
  },
  {
    viewValue: "Bung'oma",
    value: "Bung'oma",
    label: "Bung'oma"
  },
  {
    viewValue: 'Busia',
    value: 'Busia',
    label: 'Busia'
  },
  {
    viewValue: 'Siaya',
    value: 'Siaya',
    label: 'Siaya'
  },
  {
    viewValue: 'Kisumu',
    value: 'Kisumu',
    label: 'Kisumu'
  },
  {
    viewValue: 'Homa Bay',
    value: 'Homa Bay',
    label: 'Homa Bay'
  },
  {
    viewValue: 'Migori',
    value: 'Migori',
    label: 'Migori'
  },
  {
    viewValue: 'Kisii.',
    value: 'Kisii.',
    label: 'Kisii.'
  },
  {
    viewValue: 'Nyamira',
    value: 'Nyamira',
    label: 'Nyamira'
  },
  {
    viewValue: 'Nairobi City',
    value: 'Nairobi City',
    label: 'Nairobi City'
  }
];
