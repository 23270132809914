import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgentsRoutingModule } from './agents.routing.module';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { SharedModule } from '@avenews/shared-lib';
import { UiLibraryModule } from '@avenews/ui-library';
import { MentionModule } from 'angular-mentions';
import { NgChartsModule } from 'ng2-charts';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { AgentsResolver } from './resolvers/agents.resolver';
import { AgentViewComponent } from './agent-view/agent-view.component';
import { AgentCommissionsComponent } from './agent-commissions/agent-commissions.component';
import { AgentCommissionsResolver } from './resolvers/comissions-by-agent.resolver';
import { AgentsService } from './services/agents.service';
import { IncomingAgentCommissionsResolver } from './resolvers/incoming-comissions-by-agent.resolver';
import { AllAgentCommissionsComponent } from './all-agent-commissions/all-agent-commissions.component';

@NgModule({
  declarations: [AgentsListComponent, AgentViewComponent, AgentCommissionsComponent,AllAgentCommissionsComponent],
  imports: [
    CommonModule,
    AgentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatTabsModule,
    MatTooltipModule,
    UiLibraryModule,
    AdminSharedModule,
    SharedModule
  ],
  providers: [AgentsResolver, AgentCommissionsResolver, AgentsService, IncomingAgentCommissionsResolver]
})
export class AgentsModule {}
