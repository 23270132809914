import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Agt4Transaction, FilterOptions, Transaction } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { BreadCrumbs, FilesService, ListNoSdkComponent, ModalService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { EMPTY, catchError, of } from 'rxjs';
import { DocumentsListModal } from '../../shared/components/documents-list-modal/documents-list-modal';

@Component({
  selector: 'distributors-transactions',
  templateUrl: 'distributors-transactions.component.html',
  styleUrls: ['./distributors-transactions.component.scss']
})
export class DistributorsTransComponent extends ListNoSdkComponent<any> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  transaction: any;
  relatedTransactions = [];
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(
    injector: Injector,
    private httpService: HttpClient,
    private alertService: AlertService,
    protected router: Router,
    private filesService: FilesService,
    private modalService: ModalService
  ) {
    super(injector, {
      name: 'bnplTransaction',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction/bnpl`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'bnplTransaction';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    super.init();
    this.postPagination();
    this.relatedTransactions = [];
    this.paginated.subscribe((data) => {
      this.postPagination();
    });

    this.listConfig = {
      emptyState: {
        title: 'No transactions here',
        img: 'assets/images/empty-states/trade-empty-state.svg'
      },
      actions: [],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'All transactions',
      columns: [
        {
          name: 'BUSINESS NAME',
          dtoName: 'creditor.organization.businessName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'CREDITOR',
          dtoName: 'creditor.creditor.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ISSUE DATE',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },
        {
          name: 'DISCOUNT',
          dtoName: 'discount',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'DOCUMENTS',
          dtoName: 'docs',
          type: 'string',
          selected: true
        },
        {
          name: 'BENEFICIARY BUSINESS NAME',
          dtoName: 'beneficiary.organization.businessName',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'BENEFICIARY NAME',
          dtoName: 'beneficiary.beneficiary.personalInformation',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'BENEFICIARY PHONE NUMBER',
          dtoName: 'beneficiary.beneficiary.personalInformation.phoneNumber',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'nothing' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficiary.firstName',
          type: 'text'
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'transactionID',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'HIGH RISK',
          dtoName: 'transactionId',
          type: 'radio',
          radio: [
            {
              label: 'High risk',
              value: 'HR',
              filterOption: FilterOptions.CONTAINS
            }
          ]
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        },
        {
          name: 'SEND TO',
          dtoName: 'accountTypes.to',
          type: 'text'
        }
      ]
    };
  }
  async postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [];

      actions.push({ label: 'Download Documents', type: 'default', action: 'download' } as any),
        actions.push({ label: 'Status', type: 'default', action: 'status' });
      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }
  openStatus(transaction: Transaction) {
    this.transaction = transaction;
    $('#changeBnplStatusWizard').css({
      display: 'flex'
    });
  }

  actions(e: { action: any; entity?: any }) {
    switch (e.action.action) {
      case 'status':
        this.openStatus(e.entity);
        break;
      case 'external' as string:
        this.alertService
          .fire({
            title: 'Are you sure?',
            subtitle: 'You are about to send this transaction to the External Queue',
            iconName: 'warning'
          })
          .then((data) => {
            if (data.confirmed) {
              this.spinner.showSpinner();

              this.http
                .put(`${environment?.new_api_url}/api/transaction/bnpl/internal/${e?.entity?._id}/external`, {})
                .pipe(
                  catchError((err) => {
                    this.spinner.hideSpinner();
                    this.alertService.showAlert(err.error.message, 'danger');
                    return EMPTY.pipe();
                  })
                )
                .subscribe(() => {
                  this.alertService.showAlert('Transaction moved to external queue successfully', 'success');
                  this.relatedTransactions.find((x) => x?.relatedId === e?.entity?._id).internal = false;
                  this.router.navigateByUrl(this.router.url);
                });
            }
          });
        break;
      case 'download':
        this.spinner.showSpinner();
        this.http.get(`${environment.new_api_url}/api/transaction/${e.entity._id}/documents`).subscribe(async (data: any) => {
          if (!data?.urls?.length) {
            this.spinner.hideSpinner();
            this.alertService.showAlert('No documents found', 'warning');
          } else {
            this.spinner.hideSpinner();

            const urls = data?.urls;
            const aTags = [];
            for (let i = 0; i < urls?.length; i++) {
              const a = {
                href: urls[i],
                target: '_blank',
                document: e?.entity?.documents[i]
              };
              aTags.push(a);
            }
            this.modalService.open(DocumentsListModal, {
              documents: aTags
            });
          }
        });
        break;
      case 'view':
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  getDocs(item: Agt4Transaction) {
    if (item.documents?.length >= 1) {
      return item.documents?.map((x) => x.fileName)?.join('\n');
    }

    return '-';
  }

  transactionType(transactionId: any) {
    switch (true) {
      case transactionId.includes('CASHBACK'):
        return 'Cashback';
      case transactionId.includes('TOPUP'):
        return 'Topup';
      case transactionId.includes('KES') || transactionId.includes('BXC'):
        return 'Mobile';
      case transactionId.includes('RTGS') || transactionId.includes('EFT'):
        return 'Bank';
      default:
        return 'Unknown';
    }
  }
  statusGrabber(status: any) {
    switch (status) {
      case 'new':
        return 'new';
      case 'approved':
        return 'approved';
      case 'declined':
        return 'declined';
      case 'settled':
        return 'settled';
      case 'onHold':
        return 'on Hold';
      case 'accepted-by-client':
        return 'Client Approved';
      default:
        return 'new';
    }
  }
  async getRelatedTransaction(id: string) {
    /**
     * curl --location ' https://api.new.kenya.avenews.io/api/account/beneficiary/632aea359f77b007cdbfff49' \
     */
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.new_api_url + '/api/transaction/' + id + '/cashout')
        .pipe(
          catchError((err) => {
            console.log('error returning accounts');
            console.log(err);

            return of(false);
          })
        )
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }
  async getAllUrls(data: any[]): Promise<string[]> {
    const urls = [];
    for (let i = 0; i < data?.length; i++) {
      let url = await this.filesService.getFileUrl({
        bucket: data[i].bucket,
        s3Path: data[i].key
      });
      urls.push(url?.url);
    }
    return urls;
  }
}
