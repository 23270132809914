<div [ngClass]="customClass" class="offer-card">
  <p *ngIf="title" class="info-title">{{ title }}</p>
  <h3>{{ subtitle }}</h3>
  <div *ngFor="let item of list" class="offer-item" [ngClass]="item?.customClass" >
    <ng-container *ngIf="!item?.divider; else divider">
      <div class="key">{{ item?.key }}</div>
      <div class="between"></div>
      <div class="value">{{ item?.value }}</div>
    </ng-container>
    <ng-template #divider>
      <div class="divider"></div>
    </ng-template>
  </div>
</div>
