import { Component, Injector, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, ListNoSdkComponent, TimeRemainingPipe } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { LoanFilesService } from '../../services/loan-files.service';
import { FilterOptions } from '@avenews/agt-sdk';

@Component({
  selector: 'frontend-expire-soon',
  templateUrl: './expire-soon.component.html',
  styleUrls: ['./expire-soon.component.scss'],
  providers: [TimeRemainingPipe]
})
export class ExpireSoonComponent extends ListNoSdkComponent<any> implements OnInit {
  isEdit: boolean;
  constructor(injector: Injector, private fileService: LoanFilesService, private timeRemainingPipe: TimeRemainingPipe) {
    super(injector, {
      name: 'expire-soon',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/file`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'files';
    this.options.sortOrder = 'asc';
    this.options.sortBy = 'expiresAt';
  }

  ngOnInit(): void {
    this.stickyFilters = [
      {
        value: 'approved',
        column: 'status',
        option: FilterOptions.EQUAL
      }
    ];
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    this.easyFilters = [
      {
        filterName: '1 Month',
        value: '1month',
        name: 'Expires in the coming',
        active: false
      },
      {
        filterName: '6 Months',
        value: '6month',
        name: 'Expires in the coming',
        active: false
      },
      {
        filterName: '1 Year',
        value: '1year',
        name: 'Expires in the coming',
        active: false
      }
    ];
    super.init();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No expires soon Files found',
        img: 'assets/images/empty-states/trade-empty-state.svg',
        button: undefined
      },
      actions: [
        { label: 'View', type: 'default', action: 'view' },
        { label: 'Move to archive', type: 'default', action: 'changeStatus' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['FILE NAME', 'ACTIONS'],
      mainTitle: '',

      columns: [
        {
          name: 'FILE NAME',
          dtoName: 'originalName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'FILE Type',
          dtoName: 'fileType',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'loanId.businessDetails.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'UPLOADED BY',
          dtoName: 'uploadedBy.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'Expires AT',
          dtoName: 'expiresAt',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'Expires In',
          dtoName: 'expiresIn',
          type: 'text',
          selected: true
        },

        {
          name: 'UPLOADED DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'FILE NAME',
          dtoName: 'originalName',
          type: 'text'
        },
        {
          name: 'FILE Type',
          dtoName: 'fileType',
          type: 'text'
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'loanId.businessDetails.name',
          type: 'text'
        },
        {
          name: 'UPLOADED BY',
          dtoName: 'uploadedBy',
          type: 'text'
        },
        {
          name: 'Expires AT',
          dtoName: 'expiresAt',
          type: 'date'
        },
        {
          name: 'UPLOADED',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'status',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }

  ngAfterViewInit(): void {}

  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'view':
        this.fileService.openFile(e.entity);
        break;
      case 'changeStatus':
        this.fileService.moveToArchive(e.entity);
        break;
    }
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {});
      return res;
    });
    this.totalCount = this.list.length;
  }
  statusGrabber(status: string) {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Declined';
      default:
        return 'Declined';
    }
  }

  postPagination() {
    const list = [];
    for (let item of this.list) {
      try {
        item['expiresIn'] = this.timeRemainingPipe.transform(new Date(item.expiresAt));
      } catch {
        item['expiresIn'] = '-';
      }
      const actions = [
        { label: 'View', type: 'default', action: 'view' },
        { label: 'Move to archive', type: 'default', action: 'changeStatus' }
      ];

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  easyFilter(e: any) {
    if (e.action === 'remove') {
      this.removeEasyFilters();

      this.easyFilters.forEach((data, j) => {
        this.easyFilters[j].active = false;
      });
      this.paginateV2(1);
      return;
    }
    const i = this.easyFilters.findIndex((data) => data.value === e.value);
    if (i !== -1 && this.easyFilters[i].active !== true) {
      this.easyFilters.forEach((data, j) => {
        this.easyFilters[j].active = false;
      });
      this.removeEasyFilters();
      this.easyFilters[i].active = true;
      const mapper = {
        '1month': new Date(new Date().setMonth(new Date().getMonth() + 1)),
        '6month': new Date(new Date().setMonth(new Date().getMonth() + 6)),
        '1year': new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      };
      const filter = {
        column: 'expiresAt',
        option: 'lower',
        value: mapper[e.value]
      };
      this.easyFilterOptions.push(filter);
      this.paginateV2(1);
    }
  }
  removeEasyFilters() {
    this.easyFilterOptions = this.easyFilterOptions?.filter((data) => {
      return data?.column !== 'expiresAt' && data?.option !== 'lower';
    });
    this.filterOptions = this.filterOptions?.filter((data) => {
      return data?.column !== 'expiresAt' && data?.option !== 'lower';
    });
  }
}
