import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'circle-initials',
  templateUrl: './circle-initials.component.html',
  styleUrls: ['./circle-initials.component.scss']
})
export class CircleInitialsComponent {
  @Input() textColor: string;
  @Input() circleColor: string;
  @Input() circleSize: string;
  @Input() initials: string;
  @Input() fontSize: string;
  @Output() logoutUser = new EventEmitter();

  logout() {
    this.logoutUser.emit();
  }
}
