<div class="modal-box" id="limitationWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Chashout limitation</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="top-up-header">
        {{ user?.personalInformation?.firstName }} {{ user?.personalInformation?.lastName }}'s account is {{ limitation }}
      </p>
      <button (click)="limitations()" class="active-bttn">{{ limitationEp }}</button>
    </div>
  </div>
</div>
