import { Router } from '@angular/router';
import { Component, Injector, OnInit } from '@angular/core';
import { BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { Sort } from '@angular/material/sort';
import { Action, Agt4Order, Status } from '@avenews/agt-sdk';

declare const $: any;
@Component({
  selector: 'frontend-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent extends ListNoSdkComponent<Agt4Order> implements OnInit {
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  order: Agt4Order;
  constructor(injector: Injector, private router: Router) {
    super(injector, {
      noPagination: true,
      name: 'getAll',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/order`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'orders';
  }

  ngOnInit(): void {
    this.paginated.subscribe((data) => {
      this.postPagination();
    });

    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No Orders yet',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Link user account', type: 'default', action: 'LinkUserAcc' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['ID', 'BUYER NAME', 'ACTIONS'],
      mainTitle: 'Orders',

      columns: [
        {
          name: 'ID',
          dtoName: 'numericId',
          pipe: 'number',
          type: 'number',
          selected: true,
          sticky: true
        },
        {
          name: 'BUYER NAME',
          dtoName: 'customer.adminDetails.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'PHONE NUMBER',
          dtoName: 'customer.adminDetails.phoneNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'total',
          type: 'number',
          pipe: 'number ',
          selected: true
        },
        {
          name: 'ORDER ROI',
          dtoName: 'roi',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'ORDER MARGIN',
          dtoName: 'margin',
          type: 'text',
          selected: true
        },
        {
          name: 'SUPPLIER NAME',
          dtoName: 'customer.businessDetails.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'ID',
          dtoName: 'numericId',
          type: 'number'
        },
        {
          name: 'BUYER NAME',
          dtoName: 'customer.adminDetails.name',
          type: 'text'
        },
        {
          name: 'PHONE NUMBER',
          dtoName: 'customer.adminDetails.phoneNumber',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'total',
          type: 'number'
        },
        {
          name: 'ORDER ROI',
          dtoName: 'roi',
          type: 'text'
        },

        {
          name: 'ORDER MARGIN',
          dtoName: 'margin',
          type: 'text'
        },
        {
          name: 'SUPPLIER NAME',
          dtoName: 'createdBy.personalInformation.firstName',

          type: 'text'
        },

        {
          name: 'status',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  changeStatus(entity: any) {
    this.order = entity;
    $('#changeOrderStatusWizard').css({ display: 'flex' });
  }

  viewOrder(agri: any) {
    this.router.navigate(['orders', agri._id]);
  }

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'view':
        this.viewOrder(e.entity);
        break;
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
    }
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'fullName') {
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
  openModal(entity: any) {
    this.order = entity;
    $('#changeOrderStatusWizard').css({ display: 'flex' });
  }

  statusGrabber(status: any) {
    switch (status) {
      case 'new':
        return 'New';
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Denied';
      case 'settled':
        return 'Settled';
      case 'processing':
        return 'In progress';
      default:
        return 'New';
    }
  }

  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [{ label: 'Change status', type: 'default', action: 'changeStatus' }];

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
}
