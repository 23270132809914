import { Sort } from '@angular/material/sort';
import { environment } from '@env/environment';
import { Action, Agt4Account, FilterOptions, PaymentService, User } from '@avenews/agt-sdk';
import { ListNoSdkComponent } from '@avenews/shared-lib';
import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { BreadCrumbs } from '@avenews/shared-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import * as $ from 'jquery';
import { catchError, EMPTY } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'frontend-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends ListNoSdkComponent<User & { accounts: any[] }> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  user: any;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<User & { accounts: any[] }>;
  groupDesc: string;
  isEdit: boolean = undefined;
  currentTopupUser: User;
  environment = environment;
  tempFilters = [];
  @Input() roleId: any;
  accounts: Agt4Account[];
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(
    injector: Injector,
    private location: Location,
    private route: ActivatedRoute,
    private alertService: AlertService,
    protected router: Router
  ) {
    super(injector, {
      noPagination: false,
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/user/accounts`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'users';
    this.exportId = 'main-farmer-export';
  }

  ngOnInit(): void {
    this.paginated.subscribe((data) => {
      this.postPagination();
    });
    super.init();
    this.route.queryParams.subscribe((data) => {});
    this.route.data.subscribe(({ role }) => {
      this.roleId = role?._id;
    });
    // this.stickyFilters = [
    //   {
    //     value: 'admin',
    //     column: 'roles',
    //     option: FilterOptions.NOT_EQUAL
    //   }
    // ];
    this.listConfig = {
      emptyState: {
        title: 'No Users wow weird',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        // { label: 'Topup', type: 'default', action: 'topup' },
        // { label: 'Topdown', type: 'default', action: 'topdown' } as any,
        // { label: 'Active/Inactive', type: 'default', action: 'activation' },
        // { label: 'Cashout Limitation', type: 'default', action: 'limitation' },
        // { label: 'Cashback', type: 'default', action: 'cashback' }
        // { label: 'Edit', type: 'default', action: 'edit' }
        // { label: 'Delete', type: 'delete', action: 'delete' },
      ],
      bulkAddButton: undefined,
      addButton: {
        emptyStateLabel: 'New',
        isDropdown: true,
        label: 'New',
        dropdown: [
          {
            emittedValue: 'user',
            label: 'Add a user'
          }
        ]
      },
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Clients List',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'FIRST NAME',
          dtoName: 'personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'LAST NAME',
          dtoName: 'personalInformation.lastName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'PHONE',
          dtoName: 'personalInformation.phoneNumber',
          type: 'text',
          pipe: 'phoneCode',
          selected: true
        },
        {
          name: 'EMAIL',
          dtoName: 'username',
          type: 'text',
          selected: true
        },
        {
          name: 'COUNTRY',
          dtoName: 'companyInformation.country',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'Blocked',
          dtoName: 'isBlocked',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },

        {
          name: 'ID',
          dtoName: '_id',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        // {
        //   name: 'CASHOUT',
        //   dtoName: 'cashout',
        //   pipe: 'titleCase',
        //   type: 'text',
        //   selected: true
        // },
        // {
        //   name: 'ACTIVE',
        //   dtoName: 'accounts',
        //   pipe: 'titleCase',
        //   type: 'text',
        //   selected: true
        // },
        // {
        //   name: 'FINANCING ACCOUNT ID',
        //   dtoName: 'sadge',
        //   pipe: 'titleCase',
        //   type: 'text',
        //   selected: true
        // },
        {
          name: 'ROLE',
          dtoName: 'roles',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'COMPANY NAME',
          dtoName: 'companyInformation.companyName',
          pipe: 'titleCase',
          type: 'text',
          selected: false
        },
        {
          name: 'DATE CREATED',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: false
        },
        {
          name: 'LAST LOGIN',
          dtoName: 'lastLogin',
          pipe: 'date',
          type: 'date',
          selected: false
        },
        {
          name: 'LOGIN ATTEMPTS',
          dtoName: 'loginAttempts',
          pipe: 'number',
          type: 'number',
          selected: false
        },
        {
          name: 'LOGGED IN NOW',
          dtoName: 'loggedIn',
          pipe: 'number',
          type: 'number',
          selected: false
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'Topup' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'FIRST NAME',
          dtoName: 'personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'Last NAME',
          dtoName: 'personalInformation.lastName',
          type: 'text'
        },
        {
          name: 'PHONE',
          dtoName: 'personalInformation.phoneNumber',
          type: 'text'
        },
        {
          name: 'EMAIL',
          dtoName: 'username',
          type: 'text'
        },
        {
          name: 'COUNTRY',
          dtoName: 'companyInformation.country',
          type: 'text'
        },
        {
          name: 'BLOCKED',
          dtoName: 'blocked',
          type: 'radio',
          radio: [
            {
              label: 'Blocked',
              value: true
            },
            {
              label: 'Not Blocked',
              value: false
            }
          ]
        },
        {
          name: 'ID',
          dtoName: '_id',
          type: 'text'
        },
        {
          name: 'COMPANY NAME',
          dtoName: 'companyInformation.companyName',
          type: 'text'
        },
        // {
        //   name: 'CASHOUT',
        //   dtoName: 'cashout',
        //   type: 'radio',
        //   radio: [
        //     {
        //       label: 'Limited',
        //       value: true
        //     },
        //     {
        //       label: 'Not limited',
        //       value: false
        //     }
        //   ]
        // },
        // {
        //   name: 'ACTIVE',
        //   dtoName: 'active',
        //   type: 'radio',
        //   radio: [
        //     {
        //       label: 'Active',
        //       value: true
        //     },
        //     {
        //       label: 'Inactive',
        //       value: false
        //     }
        //   ]
        // },
        {
          name: 'DATE CREATED',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'LAST LOGIN',
          dtoName: 'lastLogin',
          type: 'date'
        },
        {
          name: 'LOGIN ATTEMPTS',
          dtoName: 'loginAttempts',
          type: 'number'
        },
        {
          name: 'LOGGED IN NOW',
          dtoName: 'loggedIn',
          type: 'radio',
          radio: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        }
      ]

      // bulkActionLabel: 'bulk actions'
    };
    const currentUser = JSON.parse(localStorage.getItem('credentials'))?.user;
    if (currentUser?.policyRole?.name === 'avenews-admin' || currentUser?.policyRole?.name === 'avenews-financial') {
      this.listConfig.addButton.dropdown.push({
        emittedValue: 'avenews-user',
        label: 'Add avenews user'
      });
    }
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }
  // viewContact(contact: Contact) {
  //   this.router.navigate(['contact', contact._id]);
  // }
  openTopup(user: User) {
    this.spinner.showSpinner();
    this.sdk
      .getAccountsByBeneficiary(user._id)
      .then((data) => {
        if (
          !data.length ||
          !data.filter((x) => x.service === PaymentService.AVENEWS_FINANCING || x.service === PaymentService.AVENEWS_INCOME).length
        ) {
          // this.alertService.showAlert('User doesnt have a wallet', 'danger');

          this.spinner.hideSpinner();
          return;
        }
        this.currentTopupUser = user;
        this.accounts = data.map((acc) => {
          try {
            acc.details = JSON.parse(acc.details);
            return acc;
          } catch {
            return acc;
          }
        });

        this.spinner.hideSpinner();
        $('#addTopupWizard').css('display', 'flex');
      })
      .catch((err) => {
        this.alertService.showAlert(err.message, 'danger');
        this.spinner.hideSpinner();
      });
  }
  openTopdown(user: User) {
    this.spinner.showSpinner();
    this.sdk
      .getAccountsByBeneficiary(user._id)
      .then((data) => {
        if (
          !data.length ||
          !data.filter((x) => x.service === PaymentService.AVENEWS_FINANCING || x.service === PaymentService.AVENEWS_INCOME).length
        ) {
          // this.alertService.showAlert('User doesnt have a wallet', 'danger');

          this.spinner.hideSpinner();
          return;
        }
        this.currentTopupUser = user;
        this.accounts = data.map((acc) => {
          try {
            acc.details = JSON.parse(acc.details);
            return acc;
          } catch {
            return acc;
          }
        });

        this.spinner.hideSpinner();
        $('#addTopdownWizard').css('display', 'flex');
      })
      .catch((err) => {
        this.alertService.showAlert(err.message, 'danger');
        this.spinner.hideSpinner();
      });
  }

  openActivation(user: User) {
    this.spinner.showSpinner();
    this.sdk
      .getAccountsByBeneficiary(user._id)
      .then((data) => {
        if (!data.length || !data.filter((x) => x.service === PaymentService.AVENEWS_FINANCING).length) {
          this.alertService.showAlert('User doesnt have a wallet', 'danger');
          this.spinner.hideSpinner();
          return;
        }
        this.currentTopupUser = user;
        this.accounts = data.filter((account) => account.service === PaymentService.AVENEWS_FINANCING);
        this.spinner.hideSpinner();
        $('#activation').css('display', 'flex');
      })
      .catch((err) => {
        this.alertService.showAlert(err.message, 'danger');
        this.spinner.hideSpinner();
      });
  }
  openCashoutLimitation(user: User) {
    this.spinner.showSpinner();
    this.user = user;
    this.accounts = this.user.accounts.filter((data) => {
      if (data.service == PaymentService.AVENEWS_FINANCING) {
        $('#limitationWizard').css('display', 'flex');
      }
      return data.service === PaymentService.AVENEWS_FINANCING;
    });
    this.spinner.hideSpinner();
  }
  viewUser(user: User) {
    this.router.navigate(['user', user._id]);
  }
  unblock(user: any) {
    this.alertService
      .fire({
        cancelButton: true,
        title: 'Are you sure you want to unblock this user',
        subtitle: 'click Unblock to unblock the user',
        iconName: 'warning',
        confirmButtonText: 'Unblock'
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();

          this.http
            .put(`${environment.new_api_url}/api/user/unblock/${user?._id}`, {})
            .pipe(
              catchError((err) => {
                this.spinner.hideSpinner();
                this.alertService.showAlert(err?.error?.message, 'danger');
                return EMPTY.pipe();
              })
            )
            .subscribe(() => {
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(
                `${user?.personalInformation?.firstName} ${user?.personalInformation?.lastName} has been unblocked`,
                'success'
              );
              this.spinner.hideSpinner();
            });
        }
      });
  }
  actions(e: { action: Action; entity?: User }) {
    switch (e.action.action) {
      case 'unblock':
        this.unblock(e.entity);
        break;
      case 'topup':
        this.openTopup(e.entity);
        break;
      case 'topdown':
        this.openTopdown(e.entity);
        break;
      case 'activation':
        this.openActivation(e.entity);
        break;
      case 'activate':
        this.handleActivation(e.entity);
        break;
      case 'deactivate':
        this.handleActivation(e.entity);
        break;
      case 'createWallet':
        this.newWallet(e.entity);
        break;
      case 'cashback':
        this.cashout(e.entity);
        break;
      case 'limitation':
        this.openCashoutLimitation(e.entity);
        break;
      case 'riskLevel':
        this.changeRisk(e.entity);
        break;
      case 'delete':
        break;
      case 'view':
        break;
      case 'export':
        break;
    }
  }
  primaryActions(val?: string) {
    switch (val) {
      case 'user':
        this.addUser();
        break;
      case 'avenews-user':
        this.addAvenewsUser();
        break;
      default:
        this.addUser();
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  bulkUpload() {
    this.alertService.showAlert('Coming soon', 'warning');
  }
  addUser() {
    $('#addUserWizard').css({ display: 'flex' });
  }
  addAvenewsUser() {
    $('#addAgentWizard').css({ display: 'flex' });
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  cashout(user: User) {
    this.currentTopupUser = user;
    $('#cashbackModal').css({ display: 'flex' });
  }
  changeRisk(user: any) {
    const account = user.accounts.find((data: any) => data.service === PaymentService.AVENEWS_FINANCING);
    this.alertService
      .fire({
        title: 'Are you sure?',
        subtitle: 'You are about to mark this account as ' + (!account.highRisk ? 'high' : 'low') + ' risk.',
        iconName: 'warning'
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();
          this.http
            .put(`${this.environment.new_api_url}/api/account/avenews/${account._id}/high-risk`, {
              highRisk: !account.highRisk
            })
            .pipe(
              catchError((err: any) => {
                this.spinner.hideSpinner();
                this.router.navigateByUrl(this.router.url);
                this.alertService.showAlert('Something went wrong. Please try again', 'danger');
                return EMPTY;
              })
            )
            .subscribe((data) => {
              this.spinner.hideSpinner();
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(`User marked as ${!account.highRisk ? 'high' : 'low'} risk`, 'success');
            });
        }
      });
  }
  postPagination() {
    const list = [];
    for (let item of this.list) {
      item['isBlocked'] = item.blocked ? 'Blocked' : 'Not Blocked';
      const actions = [];
      if (item?.blocked) {
        actions.push({ label: 'Unblock', type: 'default', action: 'unblock' });
      }
      // if (!item.accounts.length) {
      // actions.push({ label: 'Create Wallet', type: 'default', action: 'createWallet' });
      // } else {
      // actions.push({ label: 'Topup', type: 'default', action: 'topup' });
      // actions.push({ label: 'Topdown', type: 'default', action: 'topdown' });
      // actions.push({ label: 'Cashout Limitation', type: 'default', action: 'limitation' });
      // actions.push({ label: 'Cashback', type: 'default', action: 'cashback' });
      // if (item.accounts[0]?.highRisk) {
      //   // actions.push({ label: 'Mark as low risk', type: 'default', action: 'riskLevel' });
      // } else {
      //   // actions.push({ label: 'Mark as high risk', type: 'default', action: 'riskLevel' });
      // }
      // if (item.accounts[0]?.service === PaymentService.AVENEWS_FINANCING) {
      //   if (item.accounts[0]?.validated) {
      //     // actions.push({ label: 'Deactivate', type: 'default', action: 'deactivate' });
      //   } else {
      //     // actions.push({ label: 'Activate', type: 'default', action: 'activate' });
      //   }
      // }
      // }
      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
  private newWallet(user: User) {
    const catcher = (err) => {
      this.spinner.hideSpinner();
      this.alertService.showAlert(err.error.message, 'danger');
      return EMPTY.pipe();
    };
    this.alertService
      .fire({
        cancelButton: true,
        title: 'User doesnt have a wallet',
        subtitle: 'create wallet for this user? \nthis will create a new income and financing balance with 0 balance',
        iconName: 'warning',
        confirmButtonText: 'Create Wallet'
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();

          this.http
            .post(`${environment.new_api_url}/api/account/avenews`, {
              beneficiaryId: user._id
            })
            .pipe(catchError(catcher))
            .subscribe(() => {
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(
                `Wallet for ${user.personalInformation.firstName} ${user.personalInformation?.lastName} created successfully`,
                'success'
              );
              this.spinner.hideSpinner();
            });
        }
      });
  }
  private handleActivation(user: any) {
    const catcher = (err) => {
      this.spinner.hideSpinner();
      this.alertService.showAlert(err.error.message, 'danger');
      return EMPTY.pipe();
    };
    const isValidated = user?.accounts?.[0]?.validated;
    const { title, subtitle, confirmButtonText } = {
      title: !isValidated ? 'Activate account?:' : 'Deactivate account?:',
      subtitle: !isValidated
        ? 'Activating this account will unlock the account to be able to do transactions in the app'
        : 'Deactivating this account will lock the account from doing transactions in the app',
      confirmButtonText: !isValidated ? 'Activate' : 'Deactivate'
    };
    this.alertService
      .fire({
        cancelButton: true,
        title,
        subtitle,
        iconName: 'warning',
        confirmButtonText
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();
          this.http
            .put(`${environment.new_api_url}/api/account/avenews/${user?.accounts[0]?._id}`, {
              validated: !isValidated
            })
            .pipe(catchError(catcher))
            .subscribe(() => {
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(
                `Finance Balance for ${user.personalInformation.firstName} ${user.personalInformation?.lastName} ${confirmButtonText}d successfully`,
                'success'
              );
              this.spinner.hideSpinner();
            });
        }
      });
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      // this.list = this.unsortedList.slice();

      if (sort.active === 'cashout' || sort.active === 'accounts') {
        this.list = this.list.sort((a, b) => {
          if (sort.active === 'cashout') {
            let as = -1;
            let bs = -1;
            if (a.accounts[0]?.service === PaymentService.AVENEWS_FINANCING && a.accounts[0]?.availableCashoutRate !== 0) {
              as = 1;
            }
            if (b.accounts[0]?.service === PaymentService.AVENEWS_FINANCING && b.accounts[0]?.availableCashoutRate !== 0) {
              bs = 1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          } else if (sort.active === 'accounts') {
            let as = 0;
            let bs = 0;
            if (a.accounts[0]?.service === PaymentService.AVENEWS_FINANCING) {
              if (a.accounts[0]?.validated) {
                as = 1;
              }
            } else {
              as = -1;
            }
            if (b.accounts[0]?.service === PaymentService.AVENEWS_FINANCING) {
              if (b.accounts[0]?.validated) {
                bs = 1;
              }
            } else {
              bs = -1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    this.tempFilters = filter;
    const manuals = ['cashout', 'active', 'blocked'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'cashout') {
          if (item.accounts[0]?.service === PaymentService.AVENEWS_FINANCING) {
            if ((item.accounts[0].availableCashoutRate !== 0) === f.value) {
              res = false;
            }
          } else {
            res = false;
          }
        }
        if (f.column === 'active') {
          if (item.accounts[0]?.service === PaymentService.AVENEWS_FINANCING) {
            if ((item.accounts[0].validated !== true) === f.value) {
              res = false;
            }
          } else {
            res = false;
          }
        }
        if (f.column === 'blocked') {
          if (f.value !== item.blocked) {
            res = false;
          }
          if (!f.value && !item.blocked) {
            res = true;
          }
        }
      });
      return res;
    });
  }
  getRole(user: any) {
    if (!user?.roles?.includes('partner')) {
      return 'Agribusiness';
    }
    if (user?.description === 'buyer-partner') {
      return 'Buyer Partner';
    }
    return 'Supplier Partner';
  }
}
