import { ChartsResolver } from './resolvers/charts.resolver';
import { ChartsDashboardComponent } from './charts-dashboard/charts-dashboard.component';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'portfolio-monitor',
      component: ChartsDashboardComponent,
      resolve:{
        // charts: ChartsResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioMonitorRoutingModule {}
