<div
  [class.active]="card?.active"
  [class.invalid]="card?.invalid"
  [class.approved]="card?.approved"
  [class.full]="card?.full"
  [ngStyle]="{ 'background-color': card?.diffType?.backgroundColor, 'border-color': card?.diffType?.borderColor }"
  [class.less-padding]="(card?.isAdd || card?.isEdit || card?.isDelete || card?.scheduled || card?.headerIcon) && !card?.isAddBottom"
  [class.animate]="card?.animated"
  class="card-wrapper"
  (click)="mainActions(card)"
>
  <div *ngIf="card?.scheduled" class="scheduled">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="58" height="58" viewBox="0 0 58 58">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_4104" data-name="Rectangle 4104" width="58" height="58" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_14811" data-name="Group 14811" transform="translate(-16 -217)">
        <g id="Group_14751" data-name="Group 14751" transform="translate(16 217)">
          <g id="Group_14720" data-name="Group 14720" clip-path="url(#clip-path)">
            <path
              id="Path_9608"
              data-name="Path 9608"
              d="M12.806,17.3c4.009,3.986,8.158,8.125,12.263,12.234l4.483-4.48L17.3,12.807Z"
              fill="#58c6d4"
            />
            <path
              id="Path_9609"
              data-name="Path 9609"
              d="M1.348,52.911c-1.631,2.327-1.831,4.1.025,5.089q-.017-2.544-.025-5.089"
              fill="#687788"
            />
            <path id="Path_9610" data-name="Path 9610" d="M58,1.26c-.98-1.723-2.7-1.533-4.934,0Z" fill="#687788" />
            <path
              id="Path_9611"
              data-name="Path 9611"
              d="M1.117,28.976A3.81,3.81,0,0,0,0,31.672v24.8c.025-2.129,1.928-3.755,3.619-5.511l8.634-8.628L27.31,27.29,15.057,15.047Z"
              fill="#58c6d4"
            />
            <path
              id="Path_9612"
              data-name="Path 9612"
              d="M29,1.117A3.818,3.818,0,0,1,31.7,0H56.519C54.388.025,52.761,1.927,51,3.616l-8.635,8.628L27.311,27.29,15.058,15.046Z"
              fill="#58c6d4"
            />
          </g>
        </g>
        <text
          id="Scheduled_"
          data-name="Scheduled"
          transform="translate(37.551 241.005) rotate(-45)"
          fill="#fff"
          font-size="8"
          font-family="Roboto-Medium, Roboto"
          font-weight="500"
        >
          <tspan x="-19.777" y="0">Scheduled</tspan>
        </text>
      </g>
    </svg>
  </div>
  <div [ngClass]="{ 'top-1.5': card?.scheduled }" class="card-header relative">
    <button (click)="listAction(card)" *ngIf="card?.isAdd && !card?.isAddBottom" class="add-button">
      <i-feather name="plus"></i-feather>
    </button>
    <div *ngIf="card?.isDelete || card?.isEdit || card?.headerIcon" (click)="listAction(card)" class="icon-wrapper">
      <ng-container *ngIf="card?.isDelete">
        <i-feather name="x"></i-feather>
      </ng-container>
      <ng-container *ngIf="card?.isEdit">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.869" height="12.869" viewBox="0 0 12.869 12.869">
          <g id="Group_14672" data-name="Group 14672" transform="translate(0 0)">
            <path
              id="Path_9542"
              data-name="Path 9542"
              d="M126.528,170.042l-.006-.006-3.243-3.245s-3.953,3.953-5.857,5.9a2.376,2.376,0,0,0-.524.9c-.313.956-.571,1.93-.859,2.9a.613.613,0,0,0,.141.68.607.607,0,0,0,.654.116c.919-.276,1.844-.538,2.768-.8a2.827,2.827,0,0,0,1.248-.753C122.665,173.9,126.528,170.042,126.528,170.042Z"
              transform="translate(-115.996 -164.457)"
              fill="#152c5b"
            />
            <path
              id="Path_9543"
              data-name="Path 9543"
              d="M292.72,117.577l-1.109-1.109a1.517,1.517,0,0,0-2.144,0l-1.257,1.256,3.254,3.255,1.257-1.257A1.517,1.517,0,0,0,292.72,117.577Z"
              transform="translate(-280.295 -116.024)"
              fill="#152c5b"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="card?.headerIcon">
        <i-feather name="{{ card?.headerIcon }}"> </i-feather>
      </ng-container>
    </div>
  </div>
  <div
    [class.no-padding]="card?.subtitle"
    [class.invalid-icon]="card?.invalid"
    [class.upper]="(card?.isAdd || card?.isEdit || card?.isDelete || card?.headerIcon) && !card?.isAddBottom"
    class="card-content"
  >
    <img *ngIf="!card?.iconBackgroundColor &&  !card?.animated"  src="{{ card?.imgSrc }}" alt="{{ card?.imgAlt }}" />
    <img *ngIf="!card?.iconBackgroundColor &&  card?.animated"  src="{{ card?.animatedimgSrc }}" alt="{{ card?.imgAlt }}" />
    <div *ngIf="card?.iconBackgroundColor" class="top-content-icon" [ngStyle]="{ 'background-color': card?.iconBackgroundColor }">
      <img src="{{ card?.imgSrc }}" alt="{{ card?.imgAlt }}" />
    </div>
    <p *ngIf="card?.subtitle" class="subtitle">{{ card?.subtitle }}</p>
    <p [ngClass]="{ 'mb-4': card?.scheduled }" *ngIf="card?.title" class="title">{{ card.title }}</p>
    <i-feather (click)="listAction(card)" class="alert-circle" *ngIf="card?.invalid" name="alert-circle"></i-feather>
    <i-feather (click)="listAction(card)" *ngIf="card?.nameIcon" name="{{ card?.nameIcon }}"></i-feather>
    <button *ngIf="card?.isAdd && card?.isAddBottom" (click)="listAction(card)" class="add-button bottom-button">
      <i-feather name="plus"></i-feather>
    </button>
  </div>
  <div *ngIf="card?.scheduled" class="card-footer">
    <ng-container *ngIf="card?.scheduled">
      <svg xmlns="http://www.w3.org/2000/svg" width="14.001" height="14.001" viewBox="0 0 14.001 14.001">
        <path
          id="calendar_1_"
          data-name="calendar (1)"
          d="M12.25,1.75h-.583V.583A.584.584,0,0,0,11.084,0H10.5a.584.584,0,0,0-.583.583V1.75H4.083V.583A.584.584,0,0,0,3.5,0H2.917a.584.584,0,0,0-.583.583V1.75H1.75A1.752,1.752,0,0,0,0,3.5v8.75A1.752,1.752,0,0,0,1.75,14h10.5A1.752,1.752,0,0,0,14,12.25V3.5A1.752,1.752,0,0,0,12.25,1.75Zm.583,10.5a.584.584,0,0,1-.583.583H1.75a.584.584,0,0,1-.583-.583V5.857H12.834Zm0,0"
          fill="#fff"
        />
      </svg>
      <p>{{ card?.scheduleDate | date : 'dd/MM/yyyy HH:mm' }}</p>
    </ng-container>
  </div>
</div>

<!-- How to user in HTML Add this Code card type Card and pass actions according to the actions if it (isAdd || isEdit || isDelete || isIconHader)
 <av-card-template [card]="card" (action)="actions($event)"></av-card-template>-->
<!-- Add this code to the Ts -->
<!-- card: Card = {
  title: 'Card Title', // required
   subtitle : 'Card Subtitle', // optional
  isAdd: true, // optional  if you put the isAdd and isAddBottom both true then the add button will be shown at the bottom of the card
  isEdit: false, // optional
  isDelete: false, // optional
  isAddBottom: true, // optional
  scheduled: false, // optional
  headerIcon: '', // i-feather
  invalid: false, // optional
  active: true, // optional
  approved: false // optional
  imgSrc: '', // optional
  imgAlt: '', // optional
  iconBackgroundColor: '', // optional
  nameIcon: '', // i-feather name
  scheduleDate: new Date(), // optional
  diffType: {
    backgroundColor: '',
    borderColor: ''
  } // optional

}; -->
