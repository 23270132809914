import { RegistrationModule } from './registration/registration.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { LoginModule } from './login/login.module';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthenticationGuard } from './services/authentication/authentication.guard';
import { UiLibraryModule } from '@avenews/ui-library';
export const authenticationRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, RouterModule, RegistrationModule, LoginModule, UiLibraryModule],
  providers: [AuthenticationService, AuthenticationGuard],
  exports: [LoginModule, RegistrationModule]
})
export class AuthenticationModule {
  public static forRoot(authConfig: any): ModuleWithProviders<LoginModule | RegistrationModule>[] {
    return [
      {
        ngModule: LoginModule,
        providers: [
          {
            provide: 'loginConfig',
            useValue: authConfig.login
          }
        ]
      },
      {
        ngModule: RegistrationModule,
        providers: [
          {
            provide: 'registrationConfig',
            useValue: authConfig.registartion
          }
        ]
      }
    ];
  }
}
