<base-modal>
  <base-modal-header header="Withdraw balance"></base-modal-header>
  <base-modal-body>
    <p class="withdraw-header">
      Client full name -
      {{
        options.organization?.owner?.personalInformation?.firstName + '-' + options.organization?.owner?.personalInformation?.lastName
          | titlecase
      }}
    </p>
    <p class="withdraw-amount">
      Current balance: <span>{{ balance | roundDownNum }}KES</span>
    </p>
    <form [formGroup]="withdrawForm">
      <app-base-form-template
        labelName="Amount repaid"
        bindLabel="viewValue"
        bindValue="value"
        inputType="number"
        inputName="default"
        controlName="amountWithdrawn"
        [group]="withdrawForm"
        [errorMessages]="errorMessages"
        [size]="6"
      >
        <span #append append>KES</span>
      </app-base-form-template>
    </form>
    <p class="notice">
      Note! You are taking funds from a client account. Make sure you have entered the correct amount and that this is a necessary action.
    </p>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="submit()">Withdraw</button>
    </div>
  </base-modal-body>
</base-modal>
