<div class="user-view-wrapper">
  <frontend-base-side-view
    [topTitle]="topTitle"
    [action]="false"
    [addDetails]="true"
    additionalDetails="Application details"
    [sideViewAddDetails]="sideViewAddDetails"
    [sideView]="sideView"
    [entity]="agribusiness"
  >
    <ng-template #fieldValue let-item="item" let-entity="entity" let-p="template">
      <ng-container
        [ngTemplateOutlet]="p"
        *ngIf="
          item?.name !== 'partner first name' &&
          item?.name !== 'partner last name' &&
          item?.name !== 'STATUS' &&
          item?.name !== 'partner type'
        "
      >
      </ng-container>

      <ng-container *ngIf="item?.name === 'partner first name'">
        <td>
          {{ agriFirstName(entity) }}
        </td></ng-container
      >
      <ng-container *ngIf="item?.name === 'partner last name'">
        <td>
          {{ agriLastName(entity) }}
        </td></ng-container
      >

      <ng-container *ngIf="item?.name === 'partner type'">
        <td>
          {{ agriType(entity) }}
        </td></ng-container
      >
      <ng-container *ngIf="item?.name === 'STATUS'">
        <div class="loan-status status-{{ entity?.status }}">
          <p>
            {{ statusGrabber(entity?.status) }}
          </p>
        </div></ng-container
      >
    </ng-template>
  </frontend-base-side-view>

  <mat-tab-group [disableRipple]="true" id="application-view-tabs">
    <mat-tab color="#152C5B" label="Performance history">
      <frontend-performance-history [agribusiness]="agribusiness"></frontend-performance-history>
    </mat-tab>
    <mat-tab color="#152C5B" label="Documents">
      <frontend-agri-documents (openModal)="openModal($event)"></frontend-agri-documents>
    </mat-tab>
  </mat-tab-group>

  <av-delete-modal
    *ngIf="showModale"
    id="{{ item.id }}"
    loanId="{{ agribusiness._id }}"
    fileName="{{ item.fileName }}"
    (answer)="remover($event)"
    path="agribusiness"
    url="agribusinesses"
  ></av-delete-modal>
</div>
