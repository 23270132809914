import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
declare const $: any;
@Component({
  selector: 'frontend-cashout-limitation',
  templateUrl: './cashout-limitation.component.html',
  styleUrls: ['./cashout-limitation.component.scss']
})
export class CashoutLimitationComponent implements OnInit, OnChanges {
  @Input() accounts: any;
  @Input() user: any;
  limitation: string;
  limitationEp: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: SpinnerToggleService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}
  ngOnChanges() {
    this.limitation = this.accounts?.[0]?.availableCashoutRate === 0 ? 'Limited' : 'Not Limited';
    this.limitationEp = this.accounts?.[0]?.availableCashoutRate === 0 ? 'Remove limitation' : 'Limit';
  }
  close() {
    $('#limitationWizard').fadeOut();
  }
  limitations() {
    if (this.accounts?.[0]?.availableCashoutRate !== 0) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/account/avenews/${this.accounts?.[0]?._id}/cashout/limit`, {
          availableCashoutRate: 0
        })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Account has been limited successfuly`, 'success');
          $('#limitationWizard').fadeOut();
          this.router.navigateByUrl(this.router.url);
        });
    } else {
      this.spinner.showSpinner();
      this.http

        .put(`${environment.new_api_url}/api/account/avenews/${this.accounts?.[0]?._id}/cashout/limit`, {
          availableCashoutRate: 1
        })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Removed limitation successfuly`, 'success');
          $('#limitationWizard').fadeOut();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
}
