import { Component, Injector, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { LoanFilesService } from '../../services/loan-files.service';
import { FilterOptions } from '@avenews/agt-sdk';

@Component({
  selector: 'frontend-archived-files',
  templateUrl: './archived-files.component.html',
  styleUrls: ['./archived-files.component.scss']
})
export class ArchivedFilesComponent extends ListNoSdkComponent<any> implements OnInit {
  isEdit: boolean;
  constructor(injector: Injector, private fileService: LoanFilesService) {
    super(injector, {
      name: 'archived-files',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/file`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'archivedFiles';
    this.options.sortOrder = 'asc';
    this.options.sortBy = 'expiresAt';
  }

  ngOnInit(): void {
    this.stickyFilters = [
      {
        value: 'approved',
        column: 'status',
        option: FilterOptions.EQUAL
      }
    ];
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    super.init();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No archived Files found',
        img: 'assets/images/empty-states/trade-empty-state.svg',
        button: undefined
      },
      actions: [
        { label: 'View', type: 'default', action: 'view' },
        { label: 'Unarchive', type: 'default', action: 'changeStatus' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['FILE NAME', 'ACTIONS'],
      mainTitle: '',

      columns: [
        {
          name: 'FILE NAME',
          dtoName: 'originalName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },

        {
          name: 'FILE Type',
          dtoName: 'fileType',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'UPLOADED BY',
          dtoName: 'uploadedBy.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'Expires AT',
          dtoName: 'expiresAt',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'UPLOADED',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'FILE NAME',
          dtoName: 'originalName',
          type: 'text'
        },
        {
          name: 'FILE Type',
          dtoName: 'fileType',
          type: 'text'
        },
        {
          name: 'UPLOADED BY',
          dtoName: 'uploadedBy',
          type: 'text'
        },
        {
          name: 'Expires AT',
          dtoName: 'expiresAt',
          type: 'date'
        },
        {
          name: 'UPLOADED',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'status',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }

  ngAfterViewInit(): void {}

  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'view':
        this.fileService.openFile(e.entity);
        break;
      case 'changeStatus':
        this.fileService.Unarchive(e.entity);
        break;
    }
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {});
      return res;
    });
    this.totalCount = this.list.length;
  }
  statusGrabber(status: string) {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Declined';
      default:
        return 'Declined';
    }
  }

  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [
        { label: 'View', type: 'default', action: 'view' },
        { label: 'Unarchive', type: 'default', action: 'changeStatus' }
      ];

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
}
