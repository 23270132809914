import { Component, Input } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

export const slideLeft = trigger('slideLeft', [
  state('-2', style({ transform: 'translateX(-100%)' })),
  state('2', style({ transform: 'translateX(100%)' })),

  transition('* => 1', [animate('500ms ease-in', style({ transform: 'translateX(0%)' }))]),
  transition('* => -1', [animate('500ms ease-in', style({ transform: 'translateX(0%)' }))]),

  transition('1 => 2', [animate('500ms ease-in')]),
  transition('1 => -2', [animate('500ms ease-in')]),
  transition('-1 => -2', [animate('500ms ease-in')]),
  transition('-1 => 2', [animate('500ms ease-in')])
]);
export const slideDM = trigger('slideDM', [
  transition(':enter', [style({ transform: 'translate3d(0%, 0, 0)' }), animate('500ms ease-in', style({ transform: 'translateX(100%)' }))]),
  transition(':leave', [animate('22s ease-in')])
]);
export const slideRight = trigger('slideRight', [
  transition(':enter', []),
  transition(':leave', [animate('500ms ease-in', style({ transform: 'translateX(-100%)' }))])
]);

@Component({
  selector: 'av-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [
    slideLeft,
    slideDM,
    trigger('routerAnimation', [
      // One time initial load. Move page from left -100% to 0%
      transition('-1 => *', [
        query(':enter', [
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(-100%)'
          }),
          animate(
            '500ms ease',
            style({
              opacity: 1,
              transform: 'translateX(0%)'
            })
          )
        ])
      ]),

      // Previous, slide left to right to show left page
      transition(':decrement', [
        // set new page X location to be -100%
        query(
          ':enter',
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(-100%)'
          })
        ),

        group([
          // slide existing page from 0% to 100% to the right
          query(
            ':leave',
            animate(
              '500ms ease',
              style({
                position: 'fixed',
                width: '100%',
                transform: 'translateX(100%)'
              })
            )
          ),
          // slide new page from -100% to 0% to the right
          query(
            ':enter',
            animate(
              '500ms ease',
              style({
                opacity: 1,
                transform: 'translateX(0%)'
              })
            )
          )
        ])
      ]),

      // Next, slide right to left to show right page
      transition(':increment', [
        // set new page X location to be 100%
        query(
          ':enter',
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(100%)'
          })
        ),

        group([
          // slide existing page from 0% to -100% to the left
          query(
            ':leave',
            animate(
              '500ms ease',
              style({
                position: 'fixed',
                width: '100%',
                transform: 'translateX(-100%)'
              })
            )
          ),
          // slide new page from 100% to 0% to the left
          query(
            ':enter',
            animate(
              '500ms ease',
              style({
                opacity: 1,
                transform: 'translateX(0%)'
              })
            )
          )
        ])
      ])
    ])
  ]
})
export class TabComponent {
  @Input('tabTitle') title: string;
  counter = -1;
  @Input() active = false;

  yoyo() {}
  get getState() {
    document.querySelectorAll('av-tab').forEach((element?: any, index?: any) => {
      if (element.children[0].children[0].getAttribute('hidden') === null) {
        return index;
      }
    });
    return -1;
  }
}
