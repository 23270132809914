import { of, catchError, tap, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class KycResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    const orgId = route.parent.data?.['loan']?.createdBy?.policyRole?.organization?._id;

    return this.http.get(`https://kkpw6h3tt3.execute-api.us-east-1.amazonaws.com/prod/kyc-v2/${orgId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(undefined);
      })
    );
  }
}
