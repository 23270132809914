import { PendingTransactionListComponent } from './pending-transaction-list/pending-transaction-list.component';
import { MobileTransactionListComponent } from './mobile-transaction-list/mobile-transaction-list.component';
import { AllTransactionListResolver } from './resolvers/all-transactions.resolver';
import { AdminSharedModule } from './../shared/admin-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayRoutingModule } from './pay-routing.module';
import { BeneficiaryNamePipe, SharedModule } from '@avenews/shared-lib';
import { InternalTransactionListComponent } from './internal-transaction-list/intrenal-transaction-list.component';
import { TopupListComponent } from './topup-list/topup-list.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { BankTransactionListComponent } from './bank-transaction-list/bank-transaction-list.component';
import { UserTopupsListComponent } from './user-topups-list/user-topups-list.component';
import { UsersRepaymentsListComponent } from './users-repayments-list/users-repayments-list.component';
import { AllPaymentsListResolver } from './resolvers/all-repayments.resolver';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    InternalTransactionListComponent,
    BankTransactionListComponent,
    MobileTransactionListComponent,
    TopupListComponent,
    TransactionListComponent,
    UserTopupsListComponent,
    UsersRepaymentsListComponent,
    PendingTransactionListComponent
  ],
  imports: [CommonModule, AdminSharedModule, MatTooltipModule, SharedModule, PayRoutingModule],
  providers: [AllTransactionListResolver, BeneficiaryNamePipe, AllPaymentsListResolver]
})
export class PayModule {}
