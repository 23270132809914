import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Transaction, FilterOptions, Status, Action, Agt4Transaction } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs, BeneficiaryNamePipe } from '@avenews/shared-lib';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-mobile-transaction-list',
  templateUrl: './mobile-transaction-list.component.html',
  styleUrls: ['./mobile-transaction-list.component.scss']
})
export class MobileTransactionListComponent extends ListNoSdkComponent<Agt4Transaction> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Agt4Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private benName: BeneficiaryNamePipe, private route: ActivatedRoute, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction/custom/mobile`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'mobileTransactions';
    this.exportId = 'main-farmer-export';
    this.queryMode = 'or';
  }
  ngOnInit(): void {
    super.init();

    this.listConfig = {
      emptyState: {
        title: 'No internal transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        // { label: 'Status', type: 'default', action: 'status' }
        // { label: 'Edit', type: 'default', action: 'edit' }
        // { label: 'Delete', type: 'delete', action: 'delete' },
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Mobile transactions',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficairy.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'INTERNAL',
          dtoName: 'internal',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'TYPE',
          dtoName: 'topup',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'highRisk',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },

        {
          name: 'ACTIONS',
          actions: [{ label: 'nothing' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.name',
          type: 'text',
          dtoNames: [
            'beneficiaryusers.personalInformation.firstName',
            'beneficiaryusers.personalInformation.lastName',
            'beneficiarycontacts.firstName',
            'beneficiarycontacts.lastName'
          ]
        },
        {
          name: 'INTERNAL',
          dtoName: 'internal',
          type: 'radio',
          radio: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text'
        },
        {
          name: 'HIGH RISK',
          dtoName: 'highRisk',
          type: 'radio',
          radio: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        },
        {
          name: 'SEND TO',
          dtoName: 'accountTypes.to',
          type: 'text'
        }
      ]

      // bulkActionLabel: 'bulk actions'
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }

  openStatus(transaction: Transaction) {}
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'status':
        this.openStatus(e.entity);
        break;
      case 'add':
        break;
      case 'delete':
        break;
      case 'view':
        break;
      case 'export':
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  getType(item: Agt4Transaction) {
    if (item.transactionId.includes('CASHBACK')) {
      return 'Cashback';
    }
    if (item.transactionId.includes('TOPUP')) {
      return 'Topup';
    }
    return 'Mobile';
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      // this.list = this.unsortedList.slice();

      if (sort.active === 'name' || sort.active === 'highRisk') {
        this.list = this.list.sort((a: any, b: any) => {
          if (sort.active === 'name') {
            let as = -1;
            let bs = -1;
            if (
              this.benName.transform(a.beneficiary.beneficiary) &&
              this.benName.transform(a.beneficiary.beneficiary) !== '-' &&
              this.benName.transform(b.beneficiary.beneficiary) &&
              this.benName.transform(b.beneficiary.beneficiary) !== '-'
            ) {
              if (
                this.benName.transform(a.beneficiary.beneficiary).toLowerCase() >
                this.benName.transform(b.beneficiary.beneficiary).toLowerCase()
              ) {
                as = 1;
              }
              if (
                this.benName.transform(a.beneficiary.beneficiary).toLowerCase() <
                this.benName.transform(b.beneficiary.beneficiary).toLowerCase()
              ) {
                bs = 1;
              }

              return sort.direction === 'desc' ? as - bs : bs - as;
            }
            return -1;
          }
          if (sort.active === 'highRisk') {
            let as = -1;
            let bs = -1;
            if (a?.transactionId?.includes('HR')) {
              as = 1;
            }
            if (b?.transactionId?.includes('HR')) {
              bs = 1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['name', 'highRisk'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item: any) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'name') {
          if (this.benName.transform(item.beneficiary.beneficiary) && this.benName.transform(item.beneficiary.beneficiary) !== '-') {
            if (this.benName.transform(item.beneficiary.beneficiary).toLowerCase().indexOf(f.value.toLowerCase()) === -1) {
              res = false;
            }
          } else {
            res = false;
          }
        }
        if (f.column === 'highRisk') {
          if (item?.transactionId?.includes('HR')) {
            if (f.value === false) {
              res = false;
            }
          } else {
            if (f.value === true) {
              res = false;
            }
          }
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
}
