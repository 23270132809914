import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Transaction } from '@avenews/base-types';
import { Action, BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import * as $ from 'jquery';
import { catchError, EMPTY } from 'rxjs';
import { AlertService } from '@avenews/alerts';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'frontend-pending-transaction-list',
  templateUrl: './pending-transaction-list.component.html',
  styleUrls: ['./pending-transaction-list.component.scss']
})
export class PendingTransactionListComponent extends ListNoSdkComponent<Transaction> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  transaction: Transaction;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alerts: AlertService, private route: ActivatedRoute, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction/custom/pending`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'transactions';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    this.paginated.subscribe((data) => {
      this.postPagination();
    });
    super.init();
    this.unsortedList = this.list.slice();

    this.listConfig = {
      emptyState: {
        title: 'No pending transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        { label: 'Status', type: 'default', action: 'status' }
        // { label: 'Edit', type: 'default', action: 'edit' },
        // { label: 'Delete', type: 'delete', action: 'delete' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['BENEFICIARY', 'ACTIONS'],
      mainTitle: 'Pending transactions',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'dateCreated',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: true,
          sticky: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },
        {
          name: 'PAYEE',
          dtoName: 'payee',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'Beneficiary',
          dtoName: 'beneficiaryName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'BENEFICIARY BANK NAME',
          dtoName: 'bankDetails.bankName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NAME',
          dtoName: 'bankDetails.accountName',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY BRANCH CODE',
          dtoName: 'bankDetails.branchCode',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NUMBER',
          dtoName: 'bankDetails.accountNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC ID',
          dtoName: 'beneficiary._id',
          type: 'number',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'highRiskText',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text',
          selected: false
        },

        {
          name: 'CREDITOR ACC ID',
          dtoName: 'creditor._id',
          type: 'number',
          selected: true
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          type: 'text',
          selected: false
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'Status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'dateCreated',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: true,
          sticky: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },
        {
          name: 'PAYEE',
          dtoName: 'payee',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'Beneficiary',
          dtoName: 'beneficiaryName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'BENEFICIARY BANK NAME',
          dtoName: 'bankDetails.bankName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NAME',
          dtoName: 'bankDetails.accountName',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY BRANCH CODE',
          dtoName: 'bankDetails.branchCode',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NUMBER',
          dtoName: 'bankDetails.accountNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC ID',
          dtoName: 'beneficiary._id',
          type: 'number',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'isHighRisk',
          type: 'radio',
          radio: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ],
          selected: true
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text',
          selected: false
        },

        {
          name: 'CREDITOR ACC ID',
          dtoName: 'creditor._id',
          type: 'number',
          selected: true
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          type: 'text',
          selected: false
        }
      ] as any

      // bulkActionLabel: 'bulk actions'
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }

  async openStatus(transaction: Transaction, status: string) {
    const choice = await this.alerts.fire({
      title: `Are you sure?`,
      iconName: 'warning',
      subtitle: `Are you sure you want to ${status.slice(0, -1)} this transaction?`,
      confirmButtonText: 'Yes'
    });
    if (choice.confirmed) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/transaction/internal/status/${transaction?._id}`, {
          status,
          response: `manually ${status} due to absa check status failure`
        })
        .pipe(
          catchError((err) => {
            this.alerts.showAlert(`${err.error.message}`, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((res: any) => {
          this.alerts.showAlert(`Transaction has been ${status} successfully`, 'success');
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'approve':
        this.openStatus(e.entity, 'approved');
        break;
      case 'decline':
        this.openStatus(e.entity, 'declined');
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [];
      actions.push({ label: 'Approve', type: 'default', action: 'approve' });
      actions.push({ label: 'Decline', type: 'default', action: 'decline' });
      item['actions'] = actions;
      item['payee'] = this.getPayee(item);
      item['beneficiaryName'] = this.getBeneficiaryName(item);
      item['bankDetails'] = this.getBeneficiaryBankDetails(item);
      item['isHighRisk'] =
        item.transactionId?.includes('KES-HR') ||
        item.transactionId?.includes('BXC-HR') ||
        item.transactionId?.includes('0HR') ||
        item.transactionId?.includes('-HR');
      item['highRiskText'] = item?.['isHighRisk'] ? 'Yes' : 'No';
      list.push(item);
    }
    this.list = list.slice();
  }

  getPayee(item: any) {
    return item?.creditor?.creditor?.personalInformation?.firstName + ' ' + item?.creditor?.creditor?.personalInformation?.lastName;
  }
  getBeneficiaryName(item: any) {
    if (item.beneficiary?.beneficiary?.personalInformation) {
      return (
        item?.beneficiary?.beneficiary?.personalInformation?.firstName + ' ' + item?.beneficiary?.beneficiary?.personalInformation?.lastName
      );
    }
    return item?.beneficiary?.beneficiary?.firstName + ' ' + item?.beneficiary?.beneficiary?.lastName;
  }
  getBeneficiaryBankDetails(item: any) {
    let details;
    try {
      details = JSON.parse(item?.beneficiary?.details);
    } catch (err) {}
    return {
      bankName: details?.bankName,
      bankCode: details?.bankCode,
      accountNumber: details?.accountNumber,
      accountName: details?.accountName,
      branchCode: details?.branchCode
    };
  }

  transactionType(transactionId: any) {
    switch (true) {
      case transactionId.includes('CASHBACK'):
        return 'Cashback';
      case transactionId.includes('TOPUP'):
        return 'Topup';
      case transactionId.includes('KES') || transactionId.includes('BXC'):
        return 'Mobile';
      case transactionId.includes('RTGS') || transactionId.includes('EFT'):
        return 'Bank';
      default:
        return 'Unknown';
    }
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      if (false) {
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['isHighRisk'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'isHighRisk') {
          res = f.value === item?.['isHighRisk'];
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
}
