import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult } from '@avenews/agt-sdk';

@Injectable()
export class OrgsResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    const filter = route.data['filter'];
    let orgId = route.params['orgId'];
    let url = `${environment.new_api_url}/api/organization/accounts/10/1/_id/desc`;
    if (filter) {
      url += `${filter.replace('{orgId}', orgId)}`;
    }
    return (this.http.get(url) as Observable<PaginatedResult<any>>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
