export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype?: string;
  neededPermission?: string[];
  dropdown?: RouteInfo[];
  open?: boolean;
  mainPath?: string;
  active?: boolean;
}
