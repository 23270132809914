import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { OrdersRoutingModule } from './orders-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrdersComponent } from './orders/orders.component';
import { OrdersResolver } from './resolvers/orders.resolver';
import { OrderViewComponent } from './order-view/order-view.component';
import { MainDataComponent } from './main-data/main-data.component';
import { QuestionsComponent } from './questions/questions.component';
import { OrderResolver } from './resolvers/order.resolver';

@NgModule({
  declarations: [OrdersComponent, OrderViewComponent, MainDataComponent, QuestionsComponent],
  imports: [
    MatTooltipModule,
    MatMenuModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AdminSharedModule,
    MatTabsModule,
    SharedModule,
    OrdersRoutingModule
  ],
  exports: [],
  providers: [OrdersResolver, OrderResolver]
})
export class OrdersModule {}
