<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Pending transactions'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-users-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (bulkActions)="actionsBulk($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container *ngIf="col?.name !== 'STATUS'" [ngTemplateOutlet]="td"></ng-container>

    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="{{ !item?.status ? '' : (item?.status | status) }}">
          {{ item?.status || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<app-transaction-status-component [transaction]="transaction"></app-transaction-status-component>
