import { Pipe, PipeTransform } from '@angular/core';
export interface StatusOptions {
  status: string;
  color: string;
  realName: string;
  default?: boolean;
}
@Pipe({
  name: 'statuses'
})
export class StatusesPipe implements PipeTransform {
  constructor() {}
  transform(status: string, statuses: StatusOptions[], className?: boolean) {
    const statusOptions = statuses.find((data) => data.status === status);
    if (className) {
      return statusOptions?.color || statuses.find((data) => data.default)?.color;
    }
    return statusOptions?.realName || statuses.find((data) => data?.default)?.realName || '';
  }
}
