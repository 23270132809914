import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Transaction, FilterOptions, Status, Action, Agt4Transaction } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent extends ListNoSdkComponent<Transaction> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alertService: AlertService, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'allTransactions';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No internal transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        { label: 'Download Documents', type: 'default', action: 'download' } as any
        // { label: 'Edit', type: 'default', action: 'edit' }
        // { label: 'Delete', type: 'delete', action: 'delete' },
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'All transactions',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficiary.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'transactionId',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'INTERNAL',
          dtoName: 'internal',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'TOPUP',
          dtoName: 'topup',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },

        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          type: 'text',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'DOCUMENTS',
          dtoName: 'documents',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },

        {
          name: 'ACTIONS',
          actions: [{ label: 'nothing' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficiary.firstName',
          type: 'text'
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'transactionID',
          type: 'text'
        },
        {
          name: 'INTERNAL',
          dtoName: 'internal',
          type: 'radio',
          radio: [
            {
              label: 'Yes',
              value: true
            }
          ]
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'HIGH RISK',
          dtoName: 'transactionId',
          type: 'radio',
          radio: [
            {
              label: 'High risk',
              value: 'HR',
              filterOption: FilterOptions.CONTAINS
            }
          ]
        },

        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        },
        {
          name: 'SEND TO',
          dtoName: 'accountTypes.to',
          type: 'text'
        }
      ]

      // bulkActionLabel: 'bulk actions'
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }

  openStatus(transaction: Transaction) {}
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'status':
        this.openStatus(e.entity);
        break;
      case 'download':
        this.http.get(`${environment.new_api_url}/api/transaction/${e.entity._id}/documents`).subscribe((data: { urls: string[] }) => {
          if (!data?.urls?.length) {
            this.alertService.showAlert('No documents found', 'warning');
          } else {
            for (let i = 0; i < data?.urls?.length; i++) {
              const a = document.createElement('a');
              a.href = data.urls[i];
              a.target = '_blank';
              a.click();
            }
          }
        });
        break;
      case 'delete':
        break;
      case 'view':
        break;
      case 'export':
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  getDocs(item: Agt4Transaction) {
    if (item.documents?.length > 1) {
      return 'Multiple';
    } else if (item?.documents?.length === 1) {
      return item.documents[0].fileName;
    }

    return '-';
  }
  transactionType(transactionId: any) {
    switch (true) {
      case transactionId.includes('CASHBACK'):
        return 'Cashback';
      case transactionId.includes('TOPUP'):
        return 'Topup';
      case transactionId.includes('KES') || transactionId.includes('BXC'):
        return 'Mobile';
      case transactionId.includes('RTGS') || transactionId.includes('EFT'):
        return 'Bank';
      default:
        return 'Unknown';
    }
  }
}
