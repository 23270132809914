import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@avenews/agt-sdk';
import { SideView } from '../../shared/components/base-side-view/base-side-view.component';

@Component({
  selector: 'frontend-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.scss']
})
export class ApplicationViewComponent implements OnInit {
  topTitle: string;
  application: any;
  response = false;
  item: any;
  showModale = false;
  showDecModal = false;
  sideView: SideView[] = [
    {
      name: 'first Name',
      dtoName: 'firstName'
    },
    {
      name: 'last Name',
      dtoName: 'lastName'
    },

    {
      name: 'phone Number',
      dtoName: 'phoneNumber'
    },
    {
      name: 'email',
      dtoName: 'email'
    },
    {
      name: 'business Name',
      dtoName: 'businessName'
    }
  ];
  sideViewAddDetails: SideView[] = [
    {
      name: 'REQUESTED AMOUNT',
      dtoName: 'loanAmount',
      pipe: 'number',
      type: 'number'
    },
    {
      name: 'APPLICATION DATE',
      dtoName: 'createdAt',
      pipe: 'date',
      type: 'date'
    },

    {
      name: 'STATUS',
      dtoName: 'status'
    }
  ];
  user: User;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.data.subscribe(({ application }) => {
      this.application = application;
      this.topTitle = this.application.applicantFirstName + ' ' + this.application.applicantLastName;
    });
  }
  openModal(item: any) {
    this.item = item;
    this.showModale = true;
  }

  openDecModal(item: any) {
    this.item = item;
    this.showDecModal = true;
  }

  remover(ans: boolean) {
    this.showModale = false;
  }

  addDeclineReason(reason: string) {
    this.showDecModal = false;
  }

  statusGrabber(status: string) {
    switch (status) {
      case 'new':
        return 'New application';
      case 'data_analysis':
        return 'Data analysis';
      case 'risk_assessment':
        return 'Risk assessment';
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Declined';
      default:
        return 'New application';
    }
  }
  get totalPrice(): number {
    return this.application?.products?.reduce((a, b) => {
      return a + b.totalBuyingPrice;
    }, 0);
  }
}
