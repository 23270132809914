import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Status, Agt4Agribusiness, ProformaStatus, Proforma } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { AuthenticationService } from '@avenews/front-auth';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'frontend-change-invoice-status',
  templateUrl: './change-invoice-status.component.html',
  styleUrls: ['./change-invoice-status.component.scss']
})
export class ChangeInvoiceStatusComponent extends BaseValidationComponent implements OnInit {
  statusForm: FormGroup;
  @Input() invoice: Proforma;
  environment = environment;
  errorMessages = {
    repaymentAmount: [{ type: 'amount', message: '' }]
  };
  statusSelect = [
    // {
    //   value: InvoiceStatus.PROCESSING,
    //   viewValue: 'Processing'
    // },
    // {
    //   value: InvoiceStatus.PAID,
    //   viewValue: 'Paid'
    // },
    // {
    //   value: InvoiceStatus.SETTLED,
    //   viewValue: 'Settled'
    // },
    // {
    //   value: InvoiceStatus.CANCELLED,
    //   viewValue: 'Cancelled'
    // }
  ];
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private authService: AuthenticationService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.statusForm = fb.group({
      status: [undefined, Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  close() {
    $('#changeInvoiceStatusWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  changeStatus() {
    this.validateForm(this.statusForm);
    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${this.environment.new_api_url}/api/invoice/${this.invoice?._id}/status`, {
          status: this.statusForm.value.status
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            $('#changeInvoiceStatusWizard').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.alertService.showAlert(`Status changed successfuly`, 'success');
          $('#changeInvoiceStatusWizard').fadeOut();
          this.router.navigateByUrl(this.router.url);
          this.deleteData();
          this.spinner.hideSpinner();
        });
    }
  }
  deleteData() {
    this.statusForm.reset();
  }
}
