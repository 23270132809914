import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploadFileDTO } from '@avenews/agt-sdk';
import { FilesService, ModalService, SpinnerToggleService } from '@avenews/shared-lib';

@Component({
  selector: 'av-expiry-date-modal',
  templateUrl: './expiry-date-modal.html',
  styleUrls: ['./expiry-date-modal.scss']
})
export class ExpiryDateModal implements OnInit {
  form: FormGroup;
  options: any;
  @Input() showPass: boolean;
  @Input() showExpiryDate: boolean;
  @Input() document: UploadFileDTO;
  @Input() expiryNotDateMandatory: boolean;
  errorMessages = {
    password: [
      { type: 'required', message: 'Password is required' },
      {
        type: 'wrongPass',
        message: 'Wrong password'
      }
    ]
  };
  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private filesService: FilesService,
    private spinner: SpinnerToggleService
  ) {
    this.form = this.fb.group({
      password: [undefined],
      expiryDate: [undefined]
    });
  }
  async ngOnInit() {
    this.showPass = this.options.showPass;
    this.showExpiryDate = this.options.showExpiryDate;
    this.document = this.options.document;
    this.expiryNotDateMandatory = this.options.expiryDateNotMandatory;
    if (this.showExpiryDate && !this.expiryNotDateMandatory) {
      this.form.get('expiryDate').setValidators([Validators.required]);
    }
    if (this.showPass) {
      this.form.get('password').setValidators([Validators.required]);
      const nullPass = await this.filesService.checkPDFPassword(this.document.file, '');
      console.log(nullPass);
      if (nullPass) {
        this.form.get('password').clearValidators();
        this.form.get('password').updateValueAndValidity();
        this.showPass = false;
      }
    }
  }
  close(
    data: any = {
      confirmed: false
    }
  ) {
    this.modalService.close(data);
  }
  async submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      let passValid = true;
      if (this.showPass) {
        this.spinner.showSpinner();
        passValid = await this.filesService.checkPDFPassword(this.document.file, this.form.value.password);
        this.spinner.hideSpinner();
      }
      if (passValid) {
        this.close({ ...this.form.value, confirmed: true });
        return;
      }
      this.form.get('password').setErrors({ wrongPass: true });
    }
  }
}
