import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

let nextUniqueId = 0;

@Component({
  selector: 'av-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  private isChecked = false;
  @Input() label: string;

  private uniqueId = `av-radio-${++nextUniqueId}`;

  @Input() id: string = this.uniqueId;

  @Input() disabled = false;

  @Input() required = false;

  @Input() value: string;

  @Input() name: string | null = null;
  @Input()
  get checked(): boolean {
    return this.isChecked;
  }
  set checked(value: boolean) {
    if (value !== this.checked) {
      this.isChecked = value;
      this.changeDetectorRef.markForCheck();
    }
  }
  public onChange: any = () => {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}
  toggle(): void {
    if (!this.disabled) {
      this.isChecked = !this.isChecked;
      this.onChange(this.isChecked);
    }
  }
}
