import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';

export function NotEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let valid = true;
    try {
      if (control instanceof FormArray) {
        valid = control.value.some((item: any) => !!item);
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
    }
    return valid ? null : { required: true };
  };
}
