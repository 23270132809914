import { User } from '@avenews/agt-sdk';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DisbMlModal } from '../../../shared/components/disb-ml-modal/disb-ml-modal';
import { ModalService } from '@avenews/shared-lib';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as $ from 'jquery';
@Component({
  selector: 'td-disb-ml',
  templateUrl: './td-disb-ml.component.html',
  styleUrls: ['./td-disb-ml.component.scss'],
  animations: [
    trigger('rotate', [
      state('true', style({ transform: 'rotate(180deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('true => false', animate('300ms ease-out')),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class TDDisbMlComponent implements OnInit {
  @Input() loan: any;
  @Input() user: User;
  @Input() mlScoreData: any;
  @Input() originalOffer: any;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  biro: any;
  kyc: any;
  panelOpenState = false;
  disbursementMl = {
    '1': 0.537933056384516,
    '2': 0.31968899386558935,
    '3': 0.3297896787712775,
    '4': 0.5395832246560752,
    '5': 0.5395832246560752,
    '6': 0.5947962721772495,
    '7': 0.5947962721772495,
    '8': 0.7423789705621423,
    '9': 0.7423789705621423,
    '10': 0.7423789705621423
  };
  constructor(private modalService: ModalService, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.data.subscribe(({ biro, kyc }) => {
      this.biro = biro;
      this.kyc = kyc?.data?.documents?.[0]?.scan;
    });
    // if (this.mlScoreData.disbursementMl) {
    //   this.mlScoreData['disbursementMl'] = this.disbursementMl;
    //   const ml = [];
    //   Object.values(this.mlScoreData.disbursementMl).forEach((data) => {
    //     ml.push(data);
    //   });
    //   this.mlScoreData['disbursementMl'] = ml;
    // }
  }
  openMlModal() {
    if (this.mlScoreData.score) {
      const ref = this.modalService.open(DisbMlModal, {
        loan: this.loan,
        user: this.user,
        mlScoreData: this.mlScoreData,
        biro: this.biro,
        kyc: this.kyc,
        originalOffer: this.originalOffer
      });
      ref.afterClosedPromise().then((postClose) => {
        if (postClose?.confirmed) {
          this.panel.open();
          this.pulse();
          const data = postClose?.['data'];
          const mlValues = postClose['mlValues'];
          this.mlScoreData['disbursementMl'] = {
            mlValues: mlValues || this.mlScoreData?.['disbursementMl']?.mlValues,
            prediction: data
          };
        }
      });
    }
  }
  pulse() {
    const panel = document.getElementById('panel');
    // make it become #37c1ce then white again with transiton using jquery
    $(panel).css('background', '#37c1ce');
    setTimeout(() => {
      $(panel).css('background-color', '#37c1ce20');
    }, 500);
  }
}
