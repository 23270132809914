import { Component, Injector, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Agt4Agribusiness } from '@avenews/agt-sdk';
import { environment } from '@env/environment';
import { Agt4Account, User } from '@avenews/agt-sdk';
import { ListNoSdkComponent } from '@avenews/shared-lib';
import { Observable } from 'rxjs';
import { catchError, EMPTY } from 'rxjs';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'av-link-user-modal',
  templateUrl: './link-user-modal.component.html',
  styleUrls: ['./link-user-modal.component.scss']
})
export class LinkUserModalComponent extends ListNoSdkComponent<any> implements OnInit, OnChanges {
  chooseUserForm: FormGroup;
  environment = environment;
  @Input() agri: Agt4Agribusiness;
  users: Observable<User[]>;
  users$;
  term;
  filterArray;
  currUser: any;
  accounts: Agt4Account[];
  recommendedUsers = [];

  constructor(injector: Injector, protected router: Router, private alertService: AlertService) {
    super(injector, {
      noPagination: true,
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/user/accounts`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'users';
    this.exportId = 'main-farmer-export';
    this.chooseUserForm = this.fb.group({ user: [undefined, Validators.required] });
  }

  ngOnInit(): void {
    super.init();
    this.paginated.subscribe(() => {
      this.patchUser();
    });
  }
  override ngOnChanges(changes: SimpleChanges): void {
    if (changes['agri'] && this.agri) {
      this.checkPhones();
    }
  }
  checkPhones() {
    this.recommendedUsers = [];
    this.list.forEach((data) => {
      if (data.personalInformation?.phoneNumber === this.agri.adminDetails?.phoneNumber) {
        this.recommendedUsers.push(data);
      }
    });
  }

  close() {
    $('#linkUserAccount').fadeOut('', () => {
      this.deleteData();
    });
  }

  continue() {
    if (this.chooseUserForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/agribusiness/${this.agri?._id}/owner`, {
          userId: this.chooseUserForm.get('user').value._id
        })
        .pipe(
          catchError((err) => {
            console.error(err);
            this.alertService.showAlert('Failed to link user account', 'danger');
            this.spinner.hideSpinner();
            $('#linkUserAccount').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.alertService.showAlert(`User account linked successfuly`, 'success');
          $('#linkUserAccount').fadeOut();
          this.router.navigateByUrl(this.router.url);
          this.deleteData();
          this.spinner.hideSpinner();
        });
    }
  }
  patchUser() {
    if (this.currUser) {
      const user = this.list.find((s) => s._id === this.currUser._id); // this fixes the selected not showing in the edit bug
      this.chooseUserForm.patchValue({
        user
      });
    }
  }
  deleteData() {
    this.chooseUserForm.reset();
    this.chooseUserForm.patchValue({
      user: undefined
    });
  }
}
