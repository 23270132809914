<ng-template
  [cdkConnectedOverlayPanelClass]="['document-password-modal', customClass]"
  cdkConnectedOverlayBackdropClass="sheet-backdrop"
  cdkConnectedOverlayHasBackdrop
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
>
  <div (keyup.enter)="continue()" class="modal-box" id="documentPasswordModal">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-content scrollable">
        <div (click)="close(true)" class="flex justify-end cursor-pointer">
          <i-feather name="x"></i-feather>
        </div>
        <p class="sm-modal-title">PDF Password Required</p>
        <p class="sent flex">
          <i-feather class="text-avenews" name="lock"></i-feather>Please enter the password to unlock the uploaded PDF.
        </p>
        <form class="formerr" [formGroup]="passForm">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Password"
            inputType="text"
            inputName="default"
            controlName="password"
            [group]="passForm"
            [size]="12"
          >
          </app-base-form-template>
        </form>
      </div>
      <div class="modal-box-actions">
        <button (click)="continue()" class="!h-12 w-full sm:w-32 bttn-primary pull-right">Continue</button>
      </div>
    </div>
  </div>
</ng-template>
