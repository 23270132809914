import { Directive, HostListener, Input } from '@angular/core';
import { BackAlertOptions } from '../helpers';

@Directive({
  selector: '[backAlert]'
})
export class BackAlertDirective {
  @Input() canGoBack = false;
  @Input() backAlertOptions: BackAlertOptions;

  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: any) {
    console.log(this.canGoBack);
    if (!this.canGoBack) {
      e.preventDefault();
      console.log(e);
      e.returnValue = false;
    }
  }
  constructor() {}

  // private backAlert(opts?: BackAlertOptions): Promise<boolean> {
  //   return new Promise((res) => {
  //     if (this.canGoBack === true) {
  //       console.log('can go back', this.canGoBack);
  //       res(true);
  //       return;
  //     }
  //     let data: {
  //       backAlertOptions: BackAlertOptions;
  //     };
  //     if (opts) {
  //       data = {
  //         backAlertOptions: opts
  //       };
  //     }
  //     const ref = this.modalService.open(BackAlertModal, {
  //       data
  //     });
  //     ref.afterClosedPromise().then((postClose) => {
  //       res(postClose?.confirmed);
  //     });
  //   });
  // }
}
