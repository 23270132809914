<div class="flex-wrapper">
  <div class="container">
    <div class="panel">
      <div class="top-head">
        <img class="avenews-logo" src="{{ loginConfig?.logo }}" alt="Avenwes-GT" />
      </div>
      <div class="a-panel-body" *ngIf="!passwordChanged">
        <form [formGroup]="passwordForm">
          <p class="bold-p">Reset password</p>
          <p class="enter-pass">Enter new password for your Avenews account.</p>
          <app-base-form-template
            labelName="New password"
            [inputType]="showPass ? 'text' : 'password'"
            inputName="default"
            [size]="12"
            controlName="newPassword"
            [group]="passwordForm"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Verify new password"
            [inputType]="showPass ? 'text' : 'password'"
            inputName="default"
            [size]="12"
            controlName="confirmPassword"
            [group]="passwordForm"
          >
          </app-base-form-template>
          <div class="pass-wrap">
            <av-checkbox (click)="showPassword()"></av-checkbox>
            <p class="show-pass">Show password</p>
          </div>
          <div class="help-block mt" *ngIf="passwordsNotMatch()">
            <p>Passwords don't match!</p>
          </div>

          <av-button size="stretched" type="primary" label="Update Password" (click)="submit()"> </av-button>
        </form>
      </div>
      <div class="pass-changed" *ngIf="passwordChanged">
        <div class="reset-pass">
          <div class="sa">
            <div class="icon"></div>
            <div class="check"></div>
          </div>
        </div>
        <div class="mb-0 reset-p">
          <p class="title-center">Password Updated!</p>
          <p class="text-center">Your password has been updated successfully.</p>
        </div>
        <div class="bttn-stretched">
          <av-button type="primary" [size]="'stretched'" routerLink="/login" label="Sign in"></av-button>
        </div>
      </div>
    </div>
  </div>
</div>
