import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Transaction, FilterOptions, Status, Action } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-topup-list',
  templateUrl: './topup-list.component.html',
  styleUrls: ['./topup-list.component.scss']
})
export class TopupListComponent extends ListNoSdkComponent<Transaction> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alertService: AlertService, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction/custom/topup`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'topups';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No internal transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        // { label: 'Status', type: 'default', action: 'status' }
        // { label: 'Edit', type: 'default', action: 'edit' }
        // { label: 'Delete', type: 'delete', action: 'delete' },
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Topups',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'ISSUER',
          dtoName: 'beneficiary.name',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'RECEIVER',
          dtoName: 'creditor.name',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },
        {
          name: 'CASHBACK',
          dtoName: 'cashback',
          type: 'text',
          pipe: 'text',
          selected: true
        },
        {
          name: 'Date Created',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },

        {
          name: 'ACTIONS',
          actions: [{ label: 'Status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'ISSUER',
          dtoName: 'creditor.creditor.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'RECEIVER',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'Date Created',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text'
        }
      ]

      // bulkActionLabel: 'bulk actions'
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }

  openStatus(transaction: Transaction) {}
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'status':
        this.openStatus(e.entity);
        break;
      case 'add':
        break;
      case 'delete':
        break;
      case 'view':
        break;
      case 'export':
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
}
