import { of, catchError, tap, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class BureauResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    const ml = route.parent.data?.['scoreData'];
    if (ml && ml?.clientID) {
      return this.http.post(`https://zibindddbcf2kk56bvtolszpna0cvart.lambda-url.us-east-1.on.aws`, { clientID: ml?.clientID }).pipe(
        catchError((err) => {
          console.error(err);
          return of(undefined);
        })
      );
    } else {
      return of(undefined);
    }
  }
}
