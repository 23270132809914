import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Transaction, FilterOptions, Status, Action, Agt4Repayment } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import * as $ from 'jquery';

@Component({
  selector: 'frontend-users-repayments-list',
  templateUrl: './users-repayments-list.component.html',
  styleUrls: ['./users-repayments-list.component.scss']
})
export class UsersRepaymentsListComponent extends ListNoSdkComponent<Agt4Repayment> implements OnInit {
  isOpen = true;
  repayment: any;
  contactGroupTotal = 0;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Agt4Repayment>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alertService: AlertService, protected router: Router) {
    super(injector, {
      name: 'getAllRepayments',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/repayment`,
      noPagination: true
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'repayments';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    super.init();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No repayments',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Users repayments',
      columns: [
        {
          name: 'USER NAME',
          dtoName: 'owner.personalInformation.firstName',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'PERIOD NAME',
          dtoName: 'period.numericId',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'amount',
          pipe: 'amount',
          selected: true
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'type',
          type: 'text',
          pipe: 'text',
          selected: true
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'FILES',
          dtoName: 'documents',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'User Name',
          dtoName: 'owner.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'Deal Name',
          dtoName: 'deal.name',
          type: 'text'
        },
        {
          name: 'Date Created',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        },
        {
          name: 'Period Name',
          dtoName: 'period.numericId',
          type: 'number'
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'type',
          type: 'text'
        }
      ]
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  approveTopup(e: any) {
    this.repayment = e;
    $('#approveUserRepaymentWizard').css('display', 'flex');
  }
  declineTopup(e: any) {
    this.repayment = e;
    $('#declineUserRepaymentWizard').css('display', 'flex');
  }
  openStatus() {}
  actions(e: { action: Action; entity?: Agt4Repayment }) {
    switch (e.action.action) {
      case 'approve':
        this.approveTopup(e.entity);
        break;
      case 'decline':
        this.declineTopup(e.entity);
        break;
      case 'download':
        this.download(e.entity);
        break;
    }
  }
  download(item: Agt4Repayment) {
    if (!item.documents?.length) {
      this.alertService.showAlert('No documents found', 'warning');
      return;
    } else {
      this.http.get(`${environment.new_api_url}/api/repayment/${item._id}/documents`).subscribe((files: any) => {
        files.urls.forEach((data) => {
          window.open(data, '_blank');
        });
      });
    }
  }
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  getFileNames(files: any, tooltip?: boolean): string {
    if (!files?.length) return '-';
    if (!tooltip) {
      if (files?.length > 1) {
        return 'Multiple';
      } else return files?.[0]?.fileName;
    } else {
      return files?.map((file: any) => file.fileName).join('\n');
    }
  }
  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [];
      if (item.status === Status.PROCESSING) {
        actions.push({ label: 'Approve', type: 'default', action: 'approve' });
        actions.push({ label: 'Decline', type: 'default', action: 'decline' });
      }
      actions.push({ label: 'Download documents', type: 'default', action: 'download' });
      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
}
