<input
  [id]="id"
  [required]="required"
  [checked]="checked"
  [attr.value]="value"
  [disabled]="disabled"
  [attr.name]="name"
  (click)="toggle()"
  type="checkbox"
/>
<label [attr.for]="id"> {{ label }}</label>
