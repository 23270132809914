import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Question, BaseFormTemplateComponent, SpinnerToggleService, MultipleChoices } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';
import { businessTypes, educationLevels, financingTypes, gender, yesNo } from '../../helpers';

@Component({
  selector: 'av-loan-details',
  templateUrl: './add-loan-details.component.html',
  styleUrls: ['./add-loan-details.component.scss']
})
export class LoanDetailsComponent extends BaseFormTemplateComponent implements OnChanges {
  @Input() loan: any;
  @Input() loanDetails: any;
  @Output() closed = new EventEmitter();
  environment = environment;
  detailsForm: FormGroup;
  businessTypes = businessTypes;
  yesNo = yesNo;
  financingTypes = financingTypes;

  gender = gender;
  educationLevels = educationLevels;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.detailsForm = this.fb.group({
      clientID: [undefined, Validators.required],
      has_bank: [undefined, Validators.required],
      has_mpesa: [undefined, Validators.required],
      has_trade_docs: [undefined, Validators.required],
      has_handwritten_trade_docs: [undefined, Validators.required],
      loan_amount: [undefined, Validators.required],
      gender: [undefined, Validators.required],
      financing_type: [undefined, Validators.required],
      businessType: this.fb.array([], Validators.required),
      business_type_aggregator: [undefined],
      business_type_inputs_provider: [undefined],
      business_type_trader: [undefined],
      business_type_processor: [undefined],
      business_type_cooperative: [undefined],
      business_type_other: [undefined],
      has_facility: [undefined, Validators.required],
      seniority_in_business: [undefined, Validators.required],
      education_level: [undefined, Validators.required],
      loan_count: [0, Validators.required],
      loan_total: [0, Validators.required]
    });
    this.businessTypes.forEach((data) => {
      const businessTypesArray = this.detailsForm.get('businessType') as FormArray;
      businessTypesArray.push(this.fb.control(undefined));
    });
    this.formInput = this.detailsForm;
  }
  close(bool?: boolean) {
    $('#loanDetailsModal').fadeOut('fast', () => {
      this.deleteData();
      this.closed.emit(bool);
    });
  }
  deleteData() {
    this.detailsForm.reset();
    this.detailsForm.markAsUntouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['loanDetails'] && changes?.['loanDetails'].currentValue) {
      this.detailsForm.patchValue(changes?.['loanDetails'].currentValue);
      const bTypes = this.detailsForm.get('businessType') as FormArray;
      bTypes.controls.forEach((control, index) => {
        control.setValue(this.loanDetails['business_type_' + this.businessTypes[index].value] || null);
      });
    }
  }
  submit() {
    const formValue = this.detailsForm.value;
    const dto = {
      clientID: formValue.clientID,
      version: 2,
      has_bank: formValue.has_bank,
      has_mpesa: formValue.has_mpesa,
      has_trade_docs: formValue.has_trade_docs,
      has_handwritten_trade_docs: formValue.has_handwritten_trade_docs,
      loan_amount: formValue.loan_amount,
      gender: formValue.gender,
      financing_type: formValue.financing_type,
      business_type_aggregator: formValue.businessType.includes('aggregator'),
      business_type_inputs_provider: formValue.businessType.includes('inputs_provider'),
      business_type_trader: formValue.businessType.includes('trader'),
      business_type_processor: formValue.businessType.includes('processor'),
      business_type_cooperative: formValue.businessType.includes('cooperative'),
      business_type_other: formValue.businessType.includes('other'),
      has_facility: formValue.has_facility,
      seniority_in_business: formValue.seniority_in_business,
      education_level: formValue.education_level,
      loan_count: 0,
      loan_total: 0
    };

    this.validateForm(this.detailsForm);
    if (this.detailsForm.valid) {
      this.spinner.showSpinner();
      let postOrPut = 'post';
      // if (this.loanDetails) {
      //   postOrPut = 'put';
      // }
      this.http[postOrPut](`${environment.new_api_url}/api/trade-desk/ml-score-data/loan/${this.loan?._id}`, dto)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message || 'Please check the national id', 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((res) => {
          this.spinner.hideSpinner();
          this.close(true);
          // this.http.post('https://pdprediction-ecs.avenews.io/predict_pd', dto).subscribe((data: any) => {
          //   this.http
          //     .put(`${environment.new_api_url}/api/trade-desk/ml-score-data/loan/${this.loan?._id}/score/${data.probability * 50}`, dto)
          //     .subscribe(() => {
          //       this.close(true);
          //     });
          // });
        });
      // this.http[putOrPost](this.environment.new_api_url + subUrl, dto)
      //   .pipe(
      //     catchError((err) => {
      //       this.spinner.hideSpinner();
      //       this.alertService.showAlert(err.error.message, 'danger');
      //       return EMPTY.pipe();
      //     })
      //   )
      //   .subscribe(() => {
      //     this.alertService.showAlert('Question saved successfully');
      //     this.spinner.hideSpinner();
      //     this.router.navigateByUrl(this.router.url);
      //     this.close();
      //   });
    }
  }

  reset() {}
  autofill() {
    const mapper = {
      clientID: 'personalDetails.nationalId',
      has_facility: 'businessDetails.storageFacility.exists',
      seniority_in_business: 'personalDetails.yearsOfExperience',
      education_level: 'personalDetails.levelOfEducation.level'
    };
    Object.keys(mapper).forEach((key) => {
      const formKey = mapper[key];
      const value = this.getValue(formKey, this.loan);
      this.detailsForm.get(key).setValue(value);
    });
  }
  getValue(key: string, obj: any) {
    let value = obj;
    const mapper = {
      universityStudent: 'university student',
      universityGraduate: 'university graduate'
    };
    key.split('.').forEach((k) => {
      value = value?.[k];
    });
    if (mapper[value]) {
      value = mapper[value];
    }
    return value;
  }
}
