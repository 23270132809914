import { User } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { catchError, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from '@env/environment';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'av-loan-offer',
  templateUrl: './loan-offer.component.html',
  styleUrls: ['./loan-offer.component.scss']
})
export class LoanOfferComponent implements OnInit, OnChanges {
  @Input() offer: any;
  @Input() loan: any;
  @Input() originalOffer: any;
  @Input() mlScoreId: string;
  @Input() user: User;
  form: FormGroup;
  offerForm: FormGroup;
  cuteForm = new FormGroup({});
  environment = environment;
  checked: boolean;
  spina: boolean;
  reasonRes: (value: string | PromiseLike<any>) => void;
  reasonSubmitted = new Promise<any>((resolve) => {
    this.reasonRes = resolve;
  });
  constructor(
    private fb: FormBuilder,
    private alerts: AlertService,
    private router: Router,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe
  ) {
    this.form = this.fb.group({
      originalPaymentTerms: [''],
      paymentTerms: [''],
      notes: ['']
    });
    this.offerForm = this.fb.group({
      userAmount: [0, Validators.required],
      userPaymentTerms: [0, Validators.required],
      minOffer: [0, Validators.required],
      optOffer: [0, Validators.required],
      minTotalBuying: [0, Validators.required],
      optTotalBuying: [0, Validators.required],
      minFee: [0, Validators.required],
      optFee: [0, Validators.required],
      paymentTerms: [0, Validators.required],
      optPricingCheck: [true],
      minPricingCheck: [true],
      notes: ['', Validators.required]
    });
    this.cutifyForm();
  }
  ngOnInit(): void {
    this.resetPromise();
  }

  cutifyForm() {
    Object.keys(this.offerForm.controls).forEach((key) => {
      try {
        const cuteValue = this.decimalPipe.transform(this.offerForm.controls[key].value, '1.0-2');
        this.cuteForm.addControl(key, this.fb.control(cuteValue));
        this.cuteForm.get(key).setValue(cuteValue);
      } catch {}
    });
  }

  deleteData() {
    this.offerForm.reset();
    this.offerForm.patchValue({
      optPricingCheck: true,
      minPricingCheck: true
    });
    this.checked = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.loan) {
      this.form.patchValue({ originalPaymentTerms: ((this.loan?.dealId?.periodWeeks || 0) * 7)?.toFixed(2) });
    }
    this.getOffer();
  }
  getOffer() {
    if (this.offer) {
      const totalBuying = this.loan?.dealId?.totalBuying || 0;
      const minFee = this.offer.minOffer - totalBuying;
      const optFee = this.offer.optOffer - totalBuying;
      if (this.offer?.paymentTerms) {
        this.form.patchValue({ paymentTerms: this.offer.paymentTerms, notes: this.offer.notes });
      }
      const paymentTerms = this.offer?.paymentTerms || (this.loan?.dealId?.periodWeeks || 0) * 7;
      this.offerForm.patchValue({
        minOffer: this.offer?.minOffer,
        optOffer: this.offer?.optOffer,
        minFee: this.offer?.minFee || minFee,
        optFee: this.offer?.optFee || optFee,
        minTotalBuying: this.offer?.minTotalBuying || totalBuying,
        optTotalBuying: this.offer?.optTotalBuying || totalBuying,
        paymentTerms,
        userAmount: this.offer?.minTotalBuying || totalBuying,
        minPricingCheck: this.offer?.minPricingCheck === false ? false : true,
        optPricingCheck: this.offer?.optPricingCheck === false ? false : true
        // notes: this.offer?.notes
      });
      this.cutifyForm();
    }
  }
  close() {
    $('#editOfferModal').fadeOut('', () => {
      this.deleteData();
    });
  }
  openOffer() {
    $('#editOfferModal').css({ display: 'flex' });
    this.getOffer();
  }
  save() {
    this.offerForm.markAllAsTouched();
    if (this.checked && this.offerForm.valid) {
      this.spinner.showSpinner();
      const dto = {
        minOffer: this.offerForm.value.minOffer,
        optOffer: this.offerForm.value.optOffer,
        minTotalBuying: this.offerForm.value.minTotalBuying,
        minFee: this.offerForm.value.minFee,
        optTotalBuying: this.offerForm.value.optTotalBuying,
        optFee: this.offerForm.value.optFee,
        paymentTerms: this.offerForm.value.paymentTerms,
        notes: this.offerForm.value.notes,
        minPricingCheck: this.offerForm.value.minPricingCheck,
        optPricingCheck: this.offerForm.value.optPricingCheck
      };
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/ml-score-data/${this.mlScoreId}/offer-calculator`, dto)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alerts.showAlert(err?.error?.message, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((res: any) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert('Offer updated successfuly', 'success');
          this.router.navigateByUrl(this.router.url);
          this.close();
        });
    }
  }
  toggleReasonModal() {
    const display = $('#reasonModal').css('display') === 'flex' ? 'none' : 'flex';
    $('#reasonModal').css({ display });
  }
  async updateOfferStatus(status: string) {
    const choice = await this.alerts.fire({
      title: `Are you sure?`,
      iconName: 'warning',
      subtitle: `Are you sure you want to ${status.slice(0, -1)} this offer?`,
      confirmButtonText: 'Yes'
    });
    if (choice.confirmed) {
      let declineReason = { definedReason: '', reason: '' };
      if (status === 'declined') {
        this.toggleReasonModal();
        declineReason = await this.reasonSubmitted;
        this.resetPromise();
        if (!declineReason?.definedReason) {
          return;
        }
        this.toggleReasonModal();
      }
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/ml-score-data/${this.mlScoreId}/offer-calculator/status`, {
          status,
          declineReason: declineReason?.reason || undefined,
          definedReason: declineReason?.definedReason || undefined
        })
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alerts.showAlert(err?.error?.message, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((res: any) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert('Offer status updated successfuly', 'success');
          this.router.navigateByUrl(this.router.url);
          this.close();
        });
    }
  }

  calculateOffer() {
    if (!this.spina) {
      this.spina = true;
      const url = `https://jpxryfz4f1.execute-api.us-east-1.amazonaws.com/${
        environment.production ? 'prod' : 'staging'
      }/live-offer-calculator`;
      this.http
        .post(url, {
          loanId: this.loan._id,
          period: this.offerForm.value.paymentTerms,
          amount: this.offerForm.value.userAmount
        })
        .pipe(
          catchError((err) => {
            this.spina = false;
            this.alerts.showAlert(err?.error?.message, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.spina = false;
          this.checked = true;
          this.offerForm.patchValue({
            minOffer: data['minOffer'],
            optOffer: data['optOffer'],
            minFee: data['minFee'],
            optFee: data['optFee'],
            minTotalBuying: data['financedAmount'],
            optTotalBuying: data['financedAmount'],
            optPricingCheck: data['optPricingCheck'],
            minPricingCheck: data['minPricingCheck']
          });
          this.cutifyForm();
        });
    }
  }

  resolveReason(value: any) {
    this.reasonRes(value);
  }
  private resetPromise() {
    this.reasonRes = null;
    this.reasonSubmitted = new Promise<any>((resolve) => {
      this.reasonRes = resolve;
    });
  }
}
