<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-produts-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (addButton)="primaryActions($event)"
  (sendFilters)="manualFilter($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
>
</agt-nosdk-list>
<input #fileUploader hidden [accept]="accept" (change)="uploadedFile($event)" type="file" />
