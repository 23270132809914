import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { UsersResolver } from '../shared/resolvers/users.resolver';
import { RolesResolver } from './resolvers/roles.resolver';

@NgModule({
  declarations: [UsersComponent],
  imports: [CommonModule, MatTabsModule, UsersRoutingModule, AdminSharedModule, SharedModule],
  exports: [UsersComponent],
  providers: [UsersResolver, RolesResolver]
})
export class UsersModule {}
