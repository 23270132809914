<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Organizations'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-orders-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (addButton)="primaryActions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'VALUE CHAIN' &&
        col?.name !== 'OWNER' &&
        col?.name !== 'STATUS' &&
        col?.name !== 'CASH OUT LIMITATION' &&
        col?.name !== 'OWNER EMAIL' &&
        col?.name !== 'SASA' &&
        col?.name !== 'HIGH RISK'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'CASH OUT LIMITATION'">
      <td>
        <ng-container *ngIf="item?.accounts?.length">
          {{ item?.accounts?.[0]?.availableCashoutRate ?? 1 ? 'Not Limited' : 'Limited' }}
        </ng-container>
        <!-- <ng-container *ngIf="!item?.accounts?.length">
          <div class="loader-gif">
            <img src="../../../assets/loading.gif" alt="" />
          </div>
        </ng-container> -->
        </td
    ></ng-container>
    <ng-container *ngIf="col?.name === 'VALUE CHAIN'">
      <td #tooltip="matTooltip" [matTooltip]="item?.valueChainTooltip" matTooltipClass="invoice-tooltip">
        {{ item?.valueChains }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'OWNER'">
      <td>
        {{ item?.owner?.personalInformation | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'OWNER EMAIL'">
      <td #tooltip="matTooltip" matTooltipClass="invoice-tooltip" [matTooltip]="item?.emails">
        {{ item?.owner?.username }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'SASA'">
      <td>
        <p class="{{ (item?.sasa === 'Has Access' ? 'approved' : 'gray') | status }}">
          {{ item?.sasa }}
        </p>
      </td></ng-container
    >

    <ng-container *ngIf="col?.name === 'HIGH RISK'">
      <td>
        <ng-container *ngIf="item?.accounts?.length">
          {{ item?.financing?.highRisk ? 'High Risk' : 'Low Risk' }}
        </ng-container>
        <!-- <ng-container *ngIf="!item?.accounts?.length">
          <div class="loader-gif">
            <img src="../../../assets/loading.gif" alt="" />
          </div>
        </ng-container> -->
        </td
    ></ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <!-- <ng-container *ngIf="!item?.accounts?.length">
        <div class="loader-gif">
          <img src="../../../assets/loading.gif" alt="" />
        </div>
      </ng-container> -->
      <ng-container *ngIf="item?.accounts?.length">
        <td class="status-complete">
          <p class="{{ (!item?.financing?.validated ? '' : item?.financing?.validated ? 'approved' : 'gray') | status }}">
            {{ (item?.financing?.validated ? 'Active' : 'Inactive') || '-' | titlecase }}
          </p>
        </td>
      </ng-container>
    </ng-container>
  </ng-template>
</agt-nosdk-list>
<frontend-create-organization></frontend-create-organization>
<app-topup-component [accounts]="accounts" [user]="currentTopupUser"></app-topup-component>
<frontend-cashout-limitation [accounts]="accounts" [user]="currentTopupUser"></frontend-cashout-limitation>
<frontend-cashback-popup [user]="currentTopupUser"></frontend-cashback-popup>
