<div class="numbered-list">
  <div *ngFor="let item of list" class="list-container">
    <div class="number">{{ item?.number }}</div>
    <div class="line"></div>
    <div class="text-container">
      <div class="title">{{ item?.title }}</div>
      <div class="sub-text">{{ item?.subText }}</div>
      <div class="text">{{ item?.text }}</div>
    </div>
  </div>
</div>
