<div class="modal-box" id="addTopupWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Top up</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="top-up-header">Top up for {{ user?.personalInformation?.firstName }} {{ user?.personalInformation?.lastName }}</p>
      <p *ngIf="topupForm?.get('balanceType')?.value" class="top-up-header">
        Balance: {{ topupForm?.get('balanceType')?.value?.details?.balance | number: '1.2-2' }}
      </p>
      <p *ngIf="topupForm?.get('balanceType')?.value" class="top-up-header">
        Balance After topup:
        {{ topupForm?.get('balanceType')?.value?.details?.balance + topupForm?.get('amount')?.value || 0 | number: '1.2-2' }}
      </p>
      <form [formGroup]="topupForm">
        <app-base-form-template
          labelName="Balance"
          bindLabel="viewValue"
          bindValue="value"
          inputType="select"
          inputName="select"
          controlName="balanceType"
          placeholderName="Choose balance"
          [options]="balanceType"
          [group]="topupForm"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
        [errorMessages]="errorMessages"
          labelName="Amount"
          inputType="number"
          inputName="default"
          controlName="amount"
          [group]="topupForm"
          [size]="6"
        >
        </app-base-form-template>
        <!-- <div class="amount-currency"><p>KES</p></div> -->
        <app-base-form-template
          labelName="Notes"
          inputType="textarea"
          inputName="default"
          controlName="notes"
          [group]="topupForm"
          [size]="12"
        >
        </app-base-form-template>
      </form>
    </div>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="onTopup()">Top up</button>
    </div>
  </div>
</div>
