export const inputProductTypes = [
  { value: 'Seeds', type: 'consumable' },
  { value: 'Fertilizer', type: 'consumable' },
  { value: 'Insecticide', type: 'consumable' },
  { value: 'Soil', type: 'consumable' },
  { value: 'Pesticide', type: 'consumable' },
  { value: 'Animal Feed', type: 'consumable' },
  { value: 'Insect Trap', type: 'capital' },
  { value: 'Irrigation', type: 'capital' },
  { value: 'Machinery', type: 'capital' },
  { value: 'Other', type: 'capital' }
];
