import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'frontend-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss']
})
export class ReasonModalComponent implements OnInit {
  @Input() status: string;
  @Input() buttonText: string;
  @Input() title: string = 'Decline document';
  @Output() submitted = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  environment = environment;
  reasonControl = new FormControl(undefined);
  reasonForm = new FormGroup({
    reason: this.reasonControl,
    definedReason: new FormControl(undefined, [Validators.required])
  });
  reasonOptions = [
    { viewValue: "Sales cycle is longer than Avenews's standard", value: "Sales cycle is longer than Avenews's standard" },
    { viewValue: "Refused Avenews's period", value: "Refused Avenews's period" },
    { viewValue: 'Seeking startup capital', value: 'Seeking startup capital' },
    { viewValue: 'Farmer', value: 'Farmer' },
    { viewValue: 'Not an agri-SME', value: 'Not an agri-SME' },
    { viewValue: 'Failed KYC', value: 'Failed KYC' },
    { viewValue: 'Offer expired', value: 'Offer expired' },
    { viewValue: 'Irrelevant product', value: 'Irrelevant product' },
    { viewValue: 'Informal business', value: 'Informal business' },
    { viewValue: 'Asking for <200k', value: 'Asking for <200k' },
    { viewValue: 'Re-applied too soon after declined application', value: 'Re-applied too soon after declined application' },
    { viewValue: 'ROI too low', value: 'ROI too low' },
    { viewValue: 'Outstanding debt', value: 'Outstanding debt' },
    { viewValue: "Volumes don't match request", value: "Volumes don't match request" },
    { viewValue: 'Found financing elsewhere', value: 'Found financing elsewhere' },
    { viewValue: 'Refusing essential element of application process', value: 'Refusing essential element of application process' },
    { viewValue: "Refused Avenews's financing amount", value: "Refused Avenews's financing amount" },
    { viewValue: 'Opportunity expired', value: 'Opportunity expired' },
    { viewValue: 'Seeking funds for capex', value: 'Seeking funds for capex' },
    { viewValue: 'Churned without stating reason', value: 'Churned without stating reason' },
    { viewValue: 'Off season', value: 'Off season' },
    { viewValue: 'Associated with red-listed client', value: 'Associated with red-listed client' },
    { viewValue: 'Wrong Number', value: 'Wrong Number' }
  ];
  constructor() {}

  ngOnInit(): void {}
  onHandleDecline() {
    this.reasonControl.markAsTouched();
    if (this.reasonForm.valid) {
      this.submitted.emit(this.reasonForm.value);
      this.reasonForm.reset();
      return;
    }
  }
  close() {
    $(`#reasonModal`).fadeOut();
    this.closed.emit();
  }
}
