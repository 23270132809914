import { DashboardCounterResolver } from './dashboard-counter.resolver';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@avenews/shell';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      data: {
        title: 'Dashboard'
      },
      component: DashboardComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        // counters: DashboardCounterResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
