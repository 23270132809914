import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  HostBinding,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'av-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit, OnChanges {
  private $selected = false;

  @Input() value: any;

  @Input() name: string;

  @Input() id: string;

  @Input() pick: boolean;

  @Input() multiselect = false;

  @Output() selectionChange = new EventEmitter();

  @Output() onValueChanged = new EventEmitter();

  @Output() onSelectedValue = new EventEmitter<boolean>();

  @ViewChild('option') option: ElementRef;

  constructor(private $changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}
  @HostBinding('class.selected')
  get selected(): boolean {
    return this.$selected;
  }
  ngOnChanges() {
    this.$selected = this.pick;
  }
  select(): void {
    const elm = this.option.nativeElement;
    if (!this.$selected) {
      this.$selected = true;
      elm.classList.add('selected');
      this.$changeDetectorRef.markForCheck();
    } else {
      this.$selected = false;
      elm.classList.remove('selected');
    }
    this.onSelectedValue.emit(this.selected);
    this.onValueChanged.emit([this.value, this.selected]);
  }
}
