<base-modal>
  <base-modal-header [header]="header"></base-modal-header>
  <base-modal-body>
    <p class="withdraw-header">{{documents?.length}} Documents:</p>
    <div *ngFor="let item of documents" class="flex items-center justify-start">
      <i-feather class="text-avenews" name="file"></i-feather>
      <a target="_blank" [href]="item?.href">{{item?.document?.fileName}}</a>
    </div>
  </base-modal-body>
</base-modal>
