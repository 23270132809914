<div class="modal-box" id="declineTopupWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-content">
      <img src="../../../../assets/images/svgs/Group 2369.svg" />
      <p class="modal-title">
        Are you sure you want to decline top up of {{ topup?.amount | number: '1.0-2' }} by
        {{ topup?.beneficiary?.beneficiary | beneficiaryName | titlecase }}
      </p>
      <p class="sub-title">You cannot undo this action</p>
    </div>

    <div class="bttn-wrapper">
      <button (click)="close()" class="close">Close</button>
      <button (click)="decline()" class="decline">Decline</button>
    </div>
  </div>
</div>
