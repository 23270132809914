import { AlertService } from './services/alert.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent, AlertModalService } from './alert-modal/alert-modal.service';
import { AlertBannerComponent, AlertBannerService } from './alert-banner/alert-banner.component';
import { FeatherModule } from 'angular-feather';
import { X, Check } from 'angular-feather/icons';
import { SanitizePipe } from './services/sanitize.pipe';
import { ShowFieldStylePipe } from './services/show-field-style.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snack-bar/snack-barcomponent';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatSnackBarModule, FeatherModule.pick({ X, Check })],
  declarations: [AlertModalComponent, AlertBannerComponent, SnackBarComponent, SanitizePipe, ShowFieldStylePipe],
  exports: [AlertModalComponent, SnackBarComponent, SanitizePipe],
  providers: [AlertModalService, AlertBannerService, SanitizePipe, ShowFieldStylePipe, AlertService]
})
export class AvenewsAlertsModule {}
