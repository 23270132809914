<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="agent?.personalInformation?.firstName"></av-breadcrumbs-template>

<div id="trade-desk-container">
  <div class="desk-top">
    <div>
      <h2>Agent - {{ agent?.personalInformation?.firstName }}</h2>
    </div>
  </div>
  <div class="desk-container">
    <div class="categories">
      <div class="identity-card">
        <div class="header">
          <div class="initials">
            {{ agent?.personalInformation?.firstName | uppercase | slice : 0 : 1
            }}{{ agent?.personalInformation?.lastName | uppercase | slice : 0 : 1 }}
          </div>
          <div class="full-name">{{ agent?.personalInformation?.firstName }} {{ agent?.personalInformation?.lastName }}</div>
        </div>
        <div class="d-flex flex-column mt-3">
          <div class="info-section" *ngFor="let item of infoTorender">
            <div class="icon">
              <i-feather [name]="item.icon"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">{{ item.title }}:</p>
              <p class="soft-p">{{ item.dto || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group id="agent-view-tabs" (selectedTabChange)="tabChanged($event)" #tabGroup>
      <mat-tab label="Incoming Commisions">
        <div class="mt-4">
          <frontend-agent-commissions></frontend-agent-commissions>
        </div>
      </mat-tab>
      <mat-tab label="All Commisions">
        <div class="mt-4">
          <frontend-all-agent-commissions class="mt-4"></frontend-all-agent-commissions>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
