<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'deal-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (addButton)="primaryActions($event)"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'DEAL NAME' &&
        col?.name !== 'MAX AMOUNT' &&
        col?.name !== 'PERIOD DURATION' &&
        col?.name !== 'REPAYMENT TYPE' &&
        col?.name !== 'STATUS' &&
        col?.name !== 'DEAL TYPE'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>

    <ng-container *ngIf="col?.name === 'DEAL NAME'">
      <td>{{ item?.name }}</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'MAX AMOUNT'">
      <td>{{ item?.maximumFinancingAmount || 0 | number : '1.0-2' }} KES</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'DEAL TYPE'">
      <td>{{ item?.type | dealName | titlecase }}</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'PERIOD DURATION'">
      <td>{{ item?.maxDuration || 0 | number : '1.0-2' }} Days</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'REPAYMENT TYPE'">
      <td *ngIf="item?.repaymentInfo?.repaymentMode === 'multi'">{{ 'Multi (Inputs partners)' }}</td>
      <td *ngIf="item?.repaymentInfo?.repaymentMode === 'single'">{{ 'Single' }}</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="status status-{{ item?.status }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<app-create-deal [users]="users" [isEdit]="isEdit" [deal]="deal" fadeSidebar modalId="addDealWizard"> </app-create-deal>
<frontend-new-period [(isOpen)]="isPeriodOpen" [deal]="deal" [isEdit]="isEdit" fadeSidebar modalId="addPeriodWizard"></frontend-new-period>
<app-change-deal-status [(isOpen)]="isStatusOpen" [deal]="deal"></app-change-deal-status>
