<div class="nk-block-head">
    <div class="nk-block-head-content">
        <h5 class="nk-block-title title"> {{title}}</h5>
        <p>{{subTitle}}</p>
    </div>
</div>
<div class="card card-border">
    <ul class="data-list is-compact">
        <li class="data-item" *ngFor="let key of keys">
            <div class="data-col">
                <div class="data-label">{{key}}</div>
                <div *ngIf="statusField.includes(key)">
                    <div class="badge badge-dim badge-sm badge-outline-{{statusGrabber(dataKyc[key])}}">{{dataKyc[key]}}
                    </div>
                </div>
                <div *ngIf="!statusField.includes(key)">
                    <div class="data-value">{{dataKyc[key]}}</div>
                </div>
                <div *ngIf="picFieald.includes(key)">
                    <img style='display:block; width:100px;height:100px;' id='base64image'
                        src='data:image/jpeg;base64,{{dataKyc[key]}}' />
                </div>
            </div>
        </li>
    </ul>
</div>