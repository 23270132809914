import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ShellComponent } from './shell/shell.component';
import { FeatherModule } from 'angular-feather';
import { ChevronDown, ChevronRight, LifeBuoy } from 'angular-feather/icons';
import { CurrentUserResolver, SharedModule } from '@avenews/shared-lib';
import { ShellConfig } from '../lib/types/shell.types';
import { AuthenticationGuard, AuthenticationService } from '@avenews/front-auth';
const icons = {
  LifeBuoy,
  ChevronDown,
  ChevronRight
};
@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, RouterModule, MatMenuModule, SharedModule, BrowserModule, FeatherModule.pick(icons)],
  declarations: [SidebarComponent, HeaderComponent, ShellComponent],
  exports: [SidebarComponent, HeaderComponent, ShellComponent],
  providers: [AuthenticationService, AuthenticationGuard, CurrentUserResolver]
})
export class ShellModule {
  public static forRoot(authConfig: ShellConfig): ModuleWithProviders<ShellModule> {
    return {
      ngModule: ShellModule,

      providers: [
        {
          provide: 'env',
          useValue: authConfig.shared.env
        },
        {
          provide: 'headerConfig',
          useValue: authConfig.header
        },
        {
          provide: 'sidebarConfig',
          useValue: authConfig.sidebar
        }
      ]
    };
  }
}
