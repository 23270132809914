import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'av-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  searchTerm: string;
  counter = 0;
  selectedValues: any = [];
  wasInside: boolean;
  @Input() search = false;

  @Input() name: string;

  @Input() value: any;

  @Input() id: string;

  @Input() disabled: boolean;

  @Input() isOpen = false;

  @Input() position: 'start' | 'center' | 'end';

  @Input() options: string[];
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
    this.myControl.updateValueAndValidity({ emitEvent: true });
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
    }
    this.wasInside = false;
  }
  @Input() multiselect = false;

  @ViewChild('input') input: ElementRef;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase().trim();
    return this.options.filter((option) => option.toLowerCase().trim().includes(filterValue));
  }

  onFocus() {
    const elm = this.input.nativeElement.parentElement;
    elm.classList.add('input-focus');
  }

  onBlur() {
    const elm = this.input.nativeElement.parentElement;
    elm.classList.remove('input-focus');
  }

  handleOptions(e: any) {
    this.isOpen = !this.isOpen;
    if (
      (((this.search && this.multiselect) || this.multiselect) && this.helper(e.composedPath(), document.querySelector('.option-wrapper'))) ||
      this.helper(e.composedPath(), document.querySelector('.search-wrapper'))
    ) {
      this.isOpen = true;
    }
  }

  helper(item: any, multiselect: any) {
    for (let i = 0; i < item.length; i++) {
      if (item[i] == multiselect) {
        return true;
      }
    }
    return false;
  }

  OnSelectValue($event: any) {
    this.value = $event;
    if (this.value[1] === false && this.selectedValues.includes(this.value[0])) {
      this.selectedValues.splice(this.selectedValues.indexOf(this.value[0]), 1);
    } else {
      this.selectedValues.push(this.value[0]);
    }
    if ($event[1] == false) {
      this.counter--;
      if (this.counter === 0) {
        this.value = !this.value;
      }
    }
    if ($event[1] == true) {
      this.counter++;
    }
  }
}
