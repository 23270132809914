import { Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  newNotification = new Subject<void>();
  $newNotification = this.newNotification.asObservable();
  constructor(@Inject('env') private environment: any) {}
  async getToken() {
    const messaging = getMessaging();
    return getToken(messaging, { vapidKey: this.environment.firebase.vapidKey });
  }
  async requestPermission() {
    try {
      const currentToken = await this.getToken();
      if (currentToken) {
        console.debug(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  }
  listen() {
    navigator.serviceWorker?.addEventListener?.('message', (message) => {
      if (message.data?.notification) {
        this.newNotification.next();
        const notificationData = message.data;
        if (notificationData.sound || notificationData?.data?.sound) {
          const audio = new Audio('../../../assets/sounds/naruto_sms.mp3');
          audio.volume = 0.5;
          audio.play();
        }
      }
    });
    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log(payload);
    // });
  }
  initNotifications() {
    this.requestPermission();
    this.listen();
  }
  //background messaging
}
