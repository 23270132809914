<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Organizations'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-orders-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (addButton)="primaryActions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container *ngIf="col?.name !== 'OWNER'" [ngTemplateOutlet]="td"></ng-container>
    <ng-container *ngIf="col?.name === 'OWNER'">
      <td>
        {{ item?.personalInformation | beneficiaryName }}
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
