import { Injectable } from '@angular/core';
import { FilterOptions } from '@avenews/agt-sdk';

@Injectable()
export class AgentsService {
  constructor() {}

  getIncomingDatesFilter(): {
    value: string;
    column: string;
    option: FilterOptions;
  }[] {
    // Get the current date
    const now = new Date();

    // Get the current day
    const currentDay = now.getDate();

    // Determine the first day of the period
    let firstDayOfPeriod, lastDayOfPeriod;

    if (currentDay >= 15) {
      firstDayOfPeriod = new Date(now.getFullYear(), now.getMonth(), 15);
      lastDayOfPeriod = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    } else {
      firstDayOfPeriod = new Date(now.getFullYear(), now.getMonth(), 1);
      lastDayOfPeriod = new Date(now.getFullYear(), now.getMonth(), 14);
    }

    const filterStartDate = {
      value: firstDayOfPeriod.toISOString(), // Convert the date object to an ISO string
      column: 'dateCreated',
      option: FilterOptions.GREATER_THAN
    };

    const filterEndDate = {
      value: lastDayOfPeriod.toISOString(), // Convert the date object to an ISO string
      column: 'dateCreated',
      option: FilterOptions.LOWER_THAN
    };
    // Define the filter object
    return [filterStartDate, filterEndDate];
  }
}
