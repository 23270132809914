import { catchError, EMPTY, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SpinnerToggleService } from './../../services/spinner-toggle.service';
import { Router } from '@angular/router';
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  Injectable,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@avenews/alerts';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
let nextUniqueId = 0;

@Component({
  selector: 'av-two-fa-modal',
  templateUrl: './two-fa-modal.component.html',
  styleUrls: ['./two-fa-modal.component.scss']
})
export class TwoFaModalComponent implements OnInit {
  private uniqueId = `factor-modal-${++nextUniqueId}`;
  verifyForm: FormGroup;
  isSentCode: boolean;
  phoneNumber: string;
  currentUser: any;
  @Input() isOpen: boolean;
  @Input() customClass = this.uniqueId;
  @Output() dismiss = new EventEmitter<boolean>();
  @Output() codeValue = new EventEmitter();
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() authenticate = new EventEmitter();
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: any;
  errorMessages = {
    code: [
      { type: 'required', message: 'Code is required' },
      { type: 'pattern', message: 'Code must be a number' },
      { type: 'minlength', message: 'Code must be 6 digits' },
      { type: 'maxlength', message: 'Code must be 6 digits' }
    ]
  };

  constructor(
    //get env frorm forroot
    @Inject('env') private environment: any,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private alerts: AlertService
  ) {
    this.verifyForm = this.fb.group({
      code: [undefined, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('credentials'))?.user;
    this.phoneNumber = this.currentUser?.personalInformation?.phoneNumber;
    // this.http
    //   .post(`${this.environment.new_api_url}/api/notification/2fa`, {})
    //   .pipe(
    //     catchError((err) => {
    //       this.alerts.showAlert(err?.error?.message, 'danger');
    //       console.log(err);
    //       return EMPTY.pipe();
    //     })
    //   )
    //   .subscribe((data) => {
    //     console.log(data);
    //   });
  }

  onCancel() {
    $('#2faModal').fadeOut('fast');
    this.isSentCode = false;
    this.verifyForm.reset();
    this.codeValue.emit(false);
    this.close();
  }
  close() {
    this.isOpen = false;
    this.isOpenModal.emit(this.isOpen);
  }
  verify() {
    this.verifyForm.markAllAsTouched();
    if (this.verifyForm.valid) {
      this.spinner.showSpinner();
      this.http
        .post(`${this.environment.new_api_url}/api/notification/2fa/validate/${this.verifyForm.value.code}`, {})
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alerts.showAlert(err?.error?.message, 'danger');
            console.log(err);
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.codeValue.emit(true);
          this.close();
        });
    }
  }

  resendCode() {
    setTimeout(() => {
      this.isSentCode = true;
    }, 800);
  }
}
@Injectable()
export class TwoFaService {
  constructor(
    private alerts: AlertService,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private http: HttpClient,
    @Inject('env') private environment
  ) {}
  async open() {
    this.sendCode();
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(TwoFaModalComponent).create(this.injector);
    componentRef.instance.isOpen = true;
    this.appRef.attachView(componentRef.hostView);
    return firstValueFrom(componentRef.instance.codeValue.asObservable());
  }
  sendCode() {
    this.http
      .post(`${this.environment.new_api_url}/api/notification/2fa`, {})
      .pipe(
        catchError((err) => {
          this.alerts.showAlert(err?.error?.message, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe(() => {});
  }
}
