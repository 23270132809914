export const currencies = [
  { value: 'USD', viewValue: 'USD' },
  { value: 'EUR', viewValue: 'EUR' },
  { value: 'KES', viewValue: 'KES' },
  { value: 'UGX', viewValue: 'UGX' },
  { value: 'ZMW', viewValue: 'ZMW' },
  { value: 'ZAR', viewValue: 'ZAR' },
  { value: 'AED', viewValue: 'AED' },
  { value: 'AFN', viewValue: 'AFN' },
  { value: 'ALL', viewValue: 'ALL' },
  { value: 'AMD', viewValue: 'AMD' },
  { value: 'ANG', viewValue: 'ANG' },
  { value: 'AOA', viewValue: 'AOA' },
  { value: 'ARS', viewValue: 'ARS' },
  { value: 'AUD', viewValue: 'AUD' },
  { value: 'AWG', viewValue: 'AWG' },
  { value: 'AZN', viewValue: 'AZN' },
  { value: 'BAM', viewValue: 'BAM' },
  { value: 'BBD', viewValue: 'BBD' },
  { value: 'BDT', viewValue: 'BDT' },
  { value: 'BGN', viewValue: 'BGN' },
  { value: 'BHD', viewValue: 'BHD' },
  { value: 'BIF', viewValue: 'BIF' },
  { value: 'BMD', viewValue: 'BMD' },
  { value: 'BND', viewValue: 'BND' },
  { value: 'BOB', viewValue: 'BOB' },
  { value: 'BOV', viewValue: 'BOV' },
  { value: 'BRL', viewValue: 'BRL' },
  { value: 'BSD', viewValue: 'BSD' },
  { value: 'BTN', viewValue: 'BTN' },
  { value: 'BWP', viewValue: 'BWP' },
  { value: 'BYN', viewValue: 'BYN' },
  { value: 'BZD', viewValue: 'BZD' },
  { value: 'CAD', viewValue: 'CAD' },
  { value: 'CDF', viewValue: 'CDF' },
  { value: 'CHE', viewValue: 'CHE' },
  { value: 'CHF', viewValue: 'CHF' },
  { value: 'CHW', viewValue: 'CHW' },
  { value: 'CLF', viewValue: 'CLF' },
  { value: 'CLP', viewValue: 'CLP' },
  { value: 'CNY', viewValue: 'CNY' },
  { value: 'COP', viewValue: 'COP' },
  { value: 'COU', viewValue: 'COU' },
  { value: 'CRC', viewValue: 'CRC' },
  { value: 'CUC', viewValue: 'CUC' },
  { value: 'CUP', viewValue: 'CUP' },
  { value: 'CVE', viewValue: 'CVE' },
  { value: 'CZK', viewValue: 'CZK' },
  { value: 'DJF', viewValue: 'DJF' },
  { value: 'DKK', viewValue: 'DKK' },
  { value: 'DOP', viewValue: 'DOP' },
  { value: 'DZD', viewValue: 'DZD' },
  { value: 'EGP', viewValue: 'EGP' },
  { value: 'ERN', viewValue: 'ERN' },
  { value: 'ETB', viewValue: 'ETB' },
  { value: 'FJD', viewValue: 'FJD' },
  { value: 'FKP', viewValue: 'FKP' },
  { value: 'GBP', viewValue: 'GBP' },
  { value: 'GEL', viewValue: 'GEL' },
  { value: 'GHS', viewValue: 'GHS' },
  { value: 'GIP', viewValue: 'GIP' },
  { value: 'GMD', viewValue: 'GMD' },
  { value: 'GNF', viewValue: 'GNF' },
  { value: 'GTQ', viewValue: 'GTQ' },
  { value: 'GYD', viewValue: 'GYD' },
  { value: 'HKD', viewValue: 'HKD' },
  { value: 'HNL', viewValue: 'HNL' },
  { value: 'HRK', viewValue: 'HRK' },
  { value: 'HTG', viewValue: 'HTG' },
  { value: 'HUF', viewValue: 'HUF' },
  { value: 'IDR', viewValue: 'IDR' },
  { value: 'ILS', viewValue: 'ILS' },
  { value: 'INR', viewValue: 'INR' },
  { value: 'IQD', viewValue: 'IQD' },
  { value: 'IRR', viewValue: 'IRR' },
  { value: 'ISK', viewValue: 'ISK' },
  { value: 'JMD', viewValue: 'JMD' },
  { value: 'JOD', viewValue: 'JOD' },
  { value: 'JPY', viewValue: 'JPY' },
  { value: 'KGS', viewValue: 'KGS' },
  { value: 'KHR', viewValue: 'KHR' },
  { value: 'KMF', viewValue: 'KMF' },
  { value: 'KPW', viewValue: 'KPW' },
  { value: 'KRW', viewValue: 'KRW' },
  { value: 'KWD', viewValue: 'KWD' },
  { value: 'KYD', viewValue: 'KYD' },
  { value: 'KZT', viewValue: 'KZT' },
  { value: 'LAK', viewValue: 'LAK' },
  { value: 'LBP', viewValue: 'LBP' },
  { value: 'LKR', viewValue: 'LKR' },
  { value: 'LRD', viewValue: 'LRD' },
  { value: 'LSL', viewValue: 'LSL' },
  { value: 'LYD', viewValue: 'LYD' },
  { value: 'MAD', viewValue: 'MAD' },
  { value: 'MDL', viewValue: 'MDL' },
  { value: 'MGA', viewValue: 'MGA' },
  { value: 'MKD', viewValue: 'MKD' },
  { value: 'MMK', viewValue: 'MMK' },
  { value: 'MNT', viewValue: 'MNT' },
  { value: 'MOP', viewValue: 'MOP' },
  { value: 'MRU', viewValue: 'MRU' },
  { value: 'MUR', viewValue: 'MUR' },
  { value: 'MVR', viewValue: 'MVR' },
  { value: 'MWK', viewValue: 'MWK' },
  { value: 'MXN', viewValue: 'MXN' },
  { value: 'MXV', viewValue: 'MXV' },
  { value: 'MYR', viewValue: 'MYR' },
  { value: 'MZN', viewValue: 'MZN' },
  { value: 'NAD', viewValue: 'NAD' },
  { value: 'NGN', viewValue: 'NGN' },
  { value: 'NIO', viewValue: 'NIO' },
  { value: 'NOK', viewValue: 'NOK' },
  { value: 'NPR', viewValue: 'NPR' },
  { value: 'NZD', viewValue: 'NZD' },
  { value: 'OMR', viewValue: 'OMR' },
  { value: 'PAB', viewValue: 'PAB' },
  { value: 'PEN', viewValue: 'PEN' },
  { value: 'PGK', viewValue: 'PGK' },
  { value: 'PHP', viewValue: 'PHP' },
  { value: 'PKR', viewValue: 'PKR' },
  { value: 'PLN', viewValue: 'PLN' },
  { value: 'PYG', viewValue: 'PYG' },
  { value: 'QAR', viewValue: 'QAR' },
  { value: 'RON', viewValue: 'RON' },
  { value: 'RSD', viewValue: 'RSD' },
  { value: 'RUB', viewValue: 'RUB' },
  { value: 'RWF', viewValue: 'RWF' },
  { value: 'SAR', viewValue: 'SAR' },
  { value: 'SBD', viewValue: 'SBD' },
  { value: 'SCR', viewValue: 'SCR' },
  { value: 'SDG', viewValue: 'SDG' },
  { value: 'SEK', viewValue: 'SEK' },
  { value: 'SGD', viewValue: 'SGD' },
  { value: 'SHP', viewValue: 'SHP' },
  { value: 'SLL', viewValue: 'SLL' },
  { value: 'SOS', viewValue: 'SOS' },
  { value: 'SRD', viewValue: 'SRD' },
  { value: 'SSP', viewValue: 'SSP' },
  { value: 'STN', viewValue: 'STN' },
  { value: 'SVC', viewValue: 'SVC' },
  { value: 'SYP', viewValue: 'SYP' },
  { value: 'SZL', viewValue: 'SZL' },
  { value: 'THB', viewValue: 'THB' },
  { value: 'TJS', viewValue: 'TJS' },
  { value: 'TMT', viewValue: 'TMT' },
  { value: 'TND', viewValue: 'TND' },
  { value: 'TOP', viewValue: 'TOP' },
  { value: 'TRY', viewValue: 'TRY' },
  { value: 'TTD', viewValue: 'TTD' },
  { value: 'TWD', viewValue: 'TWD' },
  { value: 'TZS', viewValue: 'TZS' },
  { value: 'UAH', viewValue: 'UAH' },
  { value: 'USN', viewValue: 'USN' },
  { value: 'UYI', viewValue: 'UYI' },
  { value: 'UYU', viewValue: 'UYU' },
  { value: 'UYW', viewValue: 'UYW' },
  { value: 'UZS', viewValue: 'UZS' },
  { value: 'VES', viewValue: 'VES' },
  { value: 'VND', viewValue: 'VND' },
  { value: 'VUV', viewValue: 'VUV' },
  { value: 'WST', viewValue: 'WST' },
  { value: 'XAF', viewValue: 'XAF' },
  { value: 'XAG', viewValue: 'XAG' },
  { value: 'XAU', viewValue: 'XAU' },
  { value: 'XBA', viewValue: 'XBA' },
  { value: 'XBB', viewValue: 'XBB' },
  { value: 'XBC', viewValue: 'XBC' },
  { value: 'XBD', viewValue: 'XBD' },
  { value: 'XCD', viewValue: 'XCD' },
  { value: 'XDR', viewValue: 'XDR' },
  { value: 'XOF', viewValue: 'XOF' },
  { value: 'XPD', viewValue: 'XPD' },
  { value: 'XPF', viewValue: 'XPF' },
  { value: 'XPT', viewValue: 'XPT' },
  { value: 'XSU', viewValue: 'XSU' },
  { value: 'XTS', viewValue: 'XTS' },
  { value: 'XUA', viewValue: 'XUA' },
  { value: 'XXX', viewValue: 'XXX' },
  { value: 'YER', viewValue: 'YER' },
  { value: 'ZWL', viewValue: 'ZWL' }
];
