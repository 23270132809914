import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface Card {
  title: string;
  subtitle?: string;
  isAdd?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
  headerIcon?: string; // if no delete or add or edit icons needed
  imgSrc?: string;
  imgAlt?: string;
  iconBackgroundColor?: string;
  isAddBottom?: boolean;
  nameIcon?: string; // i-feather name
  scheduled?: boolean; // comes with the footer and with the top icon too
  scheduleDate?: Date;
  invalid?: boolean;
  active?: boolean;
  full?: boolean;
  approved?: boolean;
  diffType?: {
    backgroundColor?: string;
    borderColor?: string;
  };
  animated?: boolean;
  animatedimgSrc?: string;
}

@Component({
  selector: 'av-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss']
})
export class CardTemplateComponent implements OnInit {
  @Input() card: Card;
  @Output() action = new EventEmitter();
  @Output() cardAction = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.card.animated) {
      setTimeout(() => {
        this.card.animated = false;
      }, 5000);
    }
  }
  listAction(card: Card) {
    this.action.emit(card);
  }
  mainActions(card: Card) {
    this.cardAction.emit(card);
  }
}
