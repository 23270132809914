import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { ExpiredTokenResetComponent } from './expired-token-reset/expired-token-reset.component';
import { ExpiredTokenResolver } from './resolvers/expired-token.resolver';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    resolve: {
      expiredToken: ExpiredTokenResolver
    }
  },
  {
    path: 'expired-token',
    component: ExpiredTokenResetComponent
  },
  { path: 'validation/:token', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule {}
