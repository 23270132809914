import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'frontend-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.scss']
})
export class DeclineModalComponent implements OnInit {
  @Input() applicationName: string;
  @Input() td: boolean;
  @Input() loanId: string;
  @Input() loan: any;
  @Input() fileName: string;
  @Input() status: string;
  @Input() id: string;
  @Input() path: string;
  @Input() url: string;
  @Input() title: string = 'Decline document';
  @Input() emitValue: boolean;
  @Input() document: any;
  @Output() done = new EventEmitter();
  @Output() submitted = new EventEmitter<string>();
  environment = environment;
  declineReasonForm: FormGroup;

  documentOptionsMap = {
    bankStatement: 'Bank Statement',
    MPesaStatment: 'M-PESA statement',
    cr12: 'CR12 (company ownership document)',
    coi: 'Official certificate of incorporation',
    kra: 'KRA PIN certificate',
    tradeDocs: 'Trade documents',
    general: 'General'
  };

  constructor(private http: HttpClient, private spinner: SpinnerToggleService, private router: Router, public fb: FormBuilder) {
    this.declineReasonForm = this.fb.group({ declineRes: [undefined, Validators.required] });
  }

  ngOnInit(): void {}
  onHandleDecline() {
    if (this.emitValue) {
      this.submitted.emit(this.declineReasonForm.get('declineRes').value);
      this.declineReasonForm.get('declineRes').reset();
      return;
    }
    if (!this.td) {
      this.http
        .put(`${this.environment.new_api_url}/api/${this.path}/file/${this.id}/status`, {
          status: this.status,
          description: this.declineReasonForm.value.declineRes
        })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.router.navigateByUrl(this.router.url);
        });
    } else {
      this.handleTd();
    }
    this.done.emit(false);
  }
  handleTd() {
    this.http
      .put(`${this.environment.new_api_url}/api/file/${this.id}/status`, {
        status: 'declined',
        description: this.declineReasonForm.value.declineRes
      })
      .pipe(
        catchError((err: any) => {
          this.spinner.hideSpinner();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.sendDocumentNotification(this.document);
        this.spinner.hideSpinner();
        this.router.navigateByUrl(this.router.url);
      });
  }
  close() {
    this.done.emit(false);
  }
  sendDocumentNotification(document: any) {
    const id = this.loan?.createdBy?._id;
    this.http
      .post(`${this.environment.new_api_url}/api/notification/android`, {
        identity: [id],
        title: 'Loan Document Status Update',
        body: `We're sorry to inform you that your uploaded loan document - ${
          this.documentOptionsMap[document?.fileType || document?.type]
        } - has been declined. Please review the requirements and resubmit.`,
        silent: false,
        actions: [
          {
            action: 'explore',
            title: 'Go to the site',
            url: 'https://example.com'
          }
        ],
        data: {
          destination: 'loan'
        },
        miniApp: 'loans'
      })
      .subscribe((data) => {});
  }
}
