import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerToggleService, TwoFaService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { AlertService } from '@avenews/alerts';
import { BaseValidationComponent } from '../base-validation/base-validation.component';

@Component({
  selector: 'frontend-cashback-popup',
  templateUrl: './cashback-popup.component.html',
  styleUrls: ['./cashback-popup.component.scss']
})
export class CashBackPopupComponent extends BaseValidationComponent implements OnInit {
  cashbackForm: FormGroup;
  @Input() user: any;

  constructor(
    fb: FormBuilder,
    private twoFaService: TwoFaService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private alertService: AlertService
  ) {
    super();
    this.cashbackForm = fb.group({
      amount: [undefined, Validators.required],
      balanceType: [undefined]
    });
  }
  ngOnInit(): void {}
  async createCashback() {
    this.validateForm(this.cashbackForm);
    if (this.cashbackForm.valid) {
      if (await this.twoFaService.open()) {
        this.spinner.showSpinner();
        this.http
          .post(`${environment.new_api_url}/api/transaction/balance/top-up`, {
            amount: this.cashbackForm.get('amount').value,
            type: 'cashback',
            beneficiaryAccountId: this.getUserAccountId()
          })
          .pipe(
            catchError((err) => {
              this.alertService.showAlert(err?.error?.message, 'danger');
              this.spinner.hideSpinner();
              $('#cashbackModal').fadeOut();
              this.deleteData();
              return EMPTY.pipe();
            })
          )
          .subscribe((data) => {
            this.alertService.showAlert(`Cashback send Successful`, 'success');
            $('#cashbackModal').fadeOut();
            this.deleteData();
            this.spinner.hideSpinner();
          });
      }
    }
  }
  getUserAccountId() {
    return this.user.accounts.find((acc: any) => acc.service === 'avenews-income')._id;
  }
  close() {
    $('#cashbackModal').fadeOut('', () => {
      this.deleteData();
    });
  }
  deleteData() {
    this.cashbackForm.reset();
  }
}
