import { catchError, of, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '@avenews/agt-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { HeaderMenu } from '../types';
import { AuthenticationService } from '@avenews/front-auth';
import { NotificationService } from '@avenews/shared-lib';

@Component({
  selector: 'av-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  dashboardNav: boolean;
  currentUser: any;
  private notificationSubscription: Subscription;
  notifications = [];
  notificationState = 'fetching';
  unread = 0;
  @ViewChild('notificationMenu', { static: true }) notificationMenu: any;
  @ViewChild('notificationMenuTrigger', { static: true }) menuTrigger: any;
  @ViewChild('bell', { static: true }) bell: any;
  @HostListener('window:click', ['$event'])
  onClick(event: any) {
    if (
      !event?.composedPath?.()?.includes?.(this.bell?.nativeElement) &&
      !event?.composedPath?.()?.includes?.(this.notificationMenu?._elementRef?.nativeElement) &&
      this.menuTrigger.menuOpen
    ) {
      this.menuTrigger.closeMenu();
    }
  }
  constructor(
    private authService: AuthenticationService,
    @Inject('headerConfig') public headerMenu: HeaderMenu[],
    @Inject('env') public environment: any,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('/dashboard')) {
      this.dashboardNav = true;
    }
    this.route.data.subscribe(({ currentUser }) => {
      this.currentUser = currentUser;
    });
    this.getNotifications();
    this.notificationSubscription = this.notificationService.$newNotification.subscribe(() => {
      this.getNotifications();
      console.log('getting new notification');
    });
  }
  getNotifications() {
    this.notificationState = 'fetching';
    this.http.get(`${this.environment.new_api_url}/api/notification`).subscribe((data: any[]) => {
      this.notifications = data;
      this.notificationState = 'normal';
      if (!this.notifications.length) {
        this.notificationState = 'empty';
      }
      this.calcUnread();
    });
  }
  calcUnread() {
    this.unread = this.notifications.filter((notification) => !notification.read).length;
  }
  logout(item: HeaderMenu) {
    if (item?.logout) {
      this.authService
        .logout()
        .pipe(
          catchError(() => {
            return of();
          })
        )
        .subscribe(() => {
          this.router.navigate(['/login']);
        });
    }
  }
  openNotification(notification: any, aux?: boolean) {
    const url = window.origin + '/' + notification?.message.link;
    if (aux) {
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(notification?.message?.data?.link);
    }
  }
  get name(): string {
    return (
      this?.authService?.credentials?.user?.personalInformation?.firstName +
      ' ' +
      this?.authService?.credentials?.user?.personalInformation?.lastName
    );
  }
  get lastLogin(): Date {
    return new Date(this?.currentUser?.lastLogin);
  }
  removeNotification(e: MouseEvent, notification?: any) {
    if (notification) {
      e.stopPropagation();
      notification['removing'] = true;
      if (!notification.read) {
        this.unread -= 1;
        if (this.unread === 0) {
          this.notificationState = 'empty';
        }
      }
      this.http.delete(`${this.environment.new_api_url}/api/notification/${notification._id}`).subscribe((data: any) => {});
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
      }, 500);
    } else {
      this.http.delete(`${this.environment.new_api_url}/api/notification`).subscribe((data: any) => {});
      for (let i = 0; i < this.notifications.length; i++) {
        // stagger the removal of notifications
        setTimeout(() => {
          this.notifications[i]['removing'] = true;
          setTimeout(() => {
            this.notifications[i]['hidden'] = true;
          }, 300);
        }, i * 20);
      }
      setTimeout(() => {
        this.notifications = [];
        this.unread = 0;
        this.notificationState = 'empty';
      }, this.notifications.length * 20 + 300);
    }
  }
  toggleRead(e: MouseEvent, notification?: any) {
    e.stopPropagation();
    if (notification) {
      notification.read = !notification.read;
      this.http
        .put(`${this.environment.new_api_url}/api/notification/read/toggle`, { ids: [notification?._id], read: notification.read })
        .subscribe((data: any) => {});
    } else {
      if (this.notifications.find((notification) => !notification.read)) {
        const ids = [];
        this.notifications.forEach((data) => {
          if (!data.read) {
            ids.push(data._id);
          }
        });
        this.notifications.forEach((notification, i) => {
          this.notifications[i].read = true;
        });
        this.http.put(`${this.environment.new_api_url}/api/notification/read/toggle`, { ids, read: false }).subscribe((data: any) => {});
        this.unread = 0;
      }
    }
    this.calcUnread();
  }
}
