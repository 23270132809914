<div
  class="circle"
  [ngStyle]="{ width: circleSize, height: circleSize, 'background-color': circleColor }"
  mat-button
  [matMenuTriggerFor]="menu"
>
  <p class="circle-inner" [ngStyle]="{ color: textColor, 'font-size': fontSize }">{{ initials | uppercase }}</p>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logout()" class="logout-btn">
    <i-feather name="upload"></i-feather>
    Sign out
  </button>
</mat-menu>
