import { ActivatedRoute } from '@angular/router';
import { zip, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ApplicationStatus } from '../../../shared/helpers';
enum Status {
  NEW = 'new',
  DATA_ANALYSIS = 'data_analysis',
  RISK_ASSESSMENT = 'risk_assessment',
  APPROVED = 'approved',
  DECLINED = 'declined',
  PROCESSING = 'processing',
  WAITING_FOR_CLIENT = 'waitingForClient',
  AWAITING_APPROVAL = 'awaitingApproval',
  TENTATIVELY_APPROVED = 'tentativelyApproved',
  INTERNALLY_DECLINED = 'internallyDeclined',
  INTERNALLY_APPROVED = 'internallyApproved',
  CLIENT_DECLINED = 'clientDeclined',
  CLIENT_APPROVED = 'clientApproved'
}
@Component({
  selector: 'td-activity-log',
  templateUrl: './td-activity-log.component.html',
  styleUrls: ['./td-activity-log.component.scss']
})
export class TdActivityLogComponent implements OnInit, OnChanges {
  @Input() loan: any;
  @Input() user: any;
  @Input() users: any;
  @Input() mlScoreData: any;
  loading = true;
  statusMapper = {
    [ApplicationStatus.NEW]: 'New',
    [ApplicationStatus.PROCESSING]: 'In progress',
    [ApplicationStatus.WAITING_FOR_CLIENT]: 'Waiting for client',
    [ApplicationStatus.AWAITING_APPROVAL]: 'Awaiting offer approval',
    [ApplicationStatus.TENTATIVELY_APPROVED]: 'Tentatively approved',
    [ApplicationStatus.INTERNALLY_DECLINED]: 'Offer declined intrenally',
    [ApplicationStatus.INTERNALLY_APPROVED]: 'Offer approved intrenally',
    [ApplicationStatus.CLIENT_DECLINED]: 'Offer declined by client',
    [ApplicationStatus.CLIENT_APPROVED]: 'Offer approved by client',
    [ApplicationStatus.ON_HOLD]: 'On hold',
    [ApplicationStatus.DUPLICATED]: 'Duplicated',
    [ApplicationStatus.REFERRED_BACK]: 'Referred back'
  };

  environment = environment;
  tempLogs = [];
  activityLog = [];
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {}
  getLoanActivityLog() {
    return this.http
      .get(
        this.environment.new_api_url +
          `/api/activity-log/1000/1/_id/asc?filters=[{"column":"entityId","option":"equal","value":"${this.loan?._id}"}]`
      )
      .pipe(
        catchError((err) => {
          return of(undefined);
        })
      );
  }
  getMlScoreDataActivityLog() {
    return this.http
      .get(
        this.environment.new_api_url +
          `/api/activity-log/1000/1/_id/asc?filters=[{"column":"entityId","option":"equal","value":"${this.mlScoreData?._id}"}]`
      )
      .pipe(
        catchError((err) => {
          return of(undefined);
        })
      );
  }
  ngOnChanges(): void {
    if (!this.activityLog.length) {
      this.loading = true;
    }
    this.handleLog();
  }
  handleLog() {
    this.activityLog = [];
    this.loading = true;
    zip(this.getLoanActivityLog(), this.getMlScoreDataActivityLog()).subscribe((data: any[]) => {
      if (data[0]?.entities) {
        this.activityLog.push(...data[0].entities);
      }
      if (data[1]?.entities) {
        this.activityLog.push(...data[1].entities);
      }
      // keep same background for same user
      for (let i = 0; i < this.activityLog?.length; i++) {
        const item = this.activityLog[i];
        const index = this.tempLogs.findIndex((tempItem) => tempItem?.createdBy?._id === item?.createdBy?._id);
        if (index > -1) {
          item['background'] = this.tempLogs[index]?.['background'];
        }
      }
      const activityUsers = this.activityLog.map((item) => {
        if (item?.createdBy) {
          return item.createdBy;
        }
      });
      const updateUserBackground = (id: string, background: string) => {
        //get all the items with the same id in createdBy._id
        const items = this.activityLog.filter((item) => item.createdBy?._id === id);
        items.forEach((item) => {
          if (!item['background']) {
            item['background'] = background;
          }
        });
      };
      for (let i = 0; i < this.activityLog.length; i++) {
        const item = this.activityLog[i];
        item['message'] = this.getMessage(item);
      }
      for (let i = 0; i < activityUsers.length; i++) {
        const background = this.randomDarkColorRGBA();
        updateUserBackground(activityUsers[i]?._id, background);
      }

      this.activityLog.sort((a, b) => {
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
      });
      this.loading = false;
      this.tempLogs = this.activityLog?.slice();
    });
  }
  randomDarkColorRGBA() {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    let a = Math.random() * (1 - 0.5) + 0.5;
    return `rgba(${r},${g},${b},${a})`;
  }
  getMessage(item: any): string {
    switch (item.action) {
      case 'create':
        return `added ML details.`;
      case 'update':
        return `updated ML details.`;
      case 'uploadInsight':
        return `uploaded an mpesa insight.`;
      case 'addBankStatements':
        return `added bank statements.`;
      case 'uploadFileValidator':
        return `uploaded a ${item?.value?.fileType} file.`;
      case 'upload':
        return `uploaded a ${item?.value?.fileType} file named "${item?.value?.originalName}"`;
      case 'getOfferCalculator':
        return `generated a new offer.`;
      case 'editOfferCalculator':
        return `updated an offer.`;
      case 'updateOfferCalculatorStatus':
        return `${item?.value?.offerCalculator?.status === 'approved' ? 'approved' : 'declined'} the offer.`;
      case 'editPersonalDetails':
        return `updated personal details.`;
      case 'editBusinessDetails':
        return `updated business details.`;
      case 'editAddress':
        return `updated address.`;
      case 'uploadBankInsight':
        return `uploaded a bank statement.`;
      case 'submit':
        return `submitted the loan.`;
      case 'assignLoanApplication':
        return `assigned ${this.getAssigneeById(item?.value?.assignee)} to the loan.`;
      case 'unassignLoanApplication':
        return `Unassigned ${this.getAssigneeById(item?.prevValue?.assignee)} from the loan.`;
      case 'updateStatus':
        let message = `updated loan status to ${this.statusMapper[item?.value?.loan?.status || item?.value?.status]}`;
        if (item?.value?.declineReason) {
          message =
            message + ` with the following reason:\n${item?.value?.declineReason?.definedReason || item?.value?.declineReason?.reason}`;
        }
        message = message + `.`;
        return message;
      case 'uploadProductsList':
        return `uploaded a products list.`;
      case 'addTradeHistory':
        return 'added trade history.';
      case 'saveDecisionTree':
        return `Saved a decision Tree.`;
      case 'addDecisionTreeCalculation':
        return `Calculated a decision tree with the following outcome:\n ${item?.value?.outcome}`;
    }
    return '';
  }
  getAssigneeById(id: string): string {
    let name = '';
    this.users?.forEach((user) => {
      if (user._id === id) {
        name = user.personalInformation?.firstName + ' ' + user.personalInformation?.lastName;
      }
    });
    return name;
  }
}
