<div>
  <div>
    <div class="docs-section" *ngFor="let doc of documents; let i = index">
      <div class="header">
        <h1>{{ doc.title }}</h1>
        <input name="file" id="file-{{ i }}" class="input-file" (change)="uploadDocs($event, doc.docType)" #file type="file" />
        <label for="file-{{ i }}">Upload</label>
      </div>
      <div class="uploaded-documents">
        <div class="state emptySate" *ngIf="!doc.data?.length; else fullSate">
          <img src="../../../assets/images/svgs/Group 77.svg" />
          <h1>No documents added yet</h1>
        </div>
        <ng-template #fullSate>
          <div class="doc-card clearfix {{ checkStatus(document?.status) }}" *ngFor="let document of doc.data">
            <div class="heading">
              <div class="ttooltip tools">
                <img *ngIf="document.status === 'declined'" src="../../../assets/images/svgs/info.svg" />
                <span class="ttooltiptext">{{ document.description }}</span>
              </div>

              <div>
                <img class="dropbtn" [matMenuTriggerFor]="menu" src="../../../assets/images/svgs/Group 5260.svg" />
                <mat-menu #menu="matMenu" class="menu-content">
                  <button (click)="changeStatus(document._id, 'approved')" mat-menu-item class="menu-item">
                    <span>Approve</span>
                  </button>
                  <button (click)="handStatus(document._id, 'declined', document.fileName)" mat-menu-item class="menu-item">
                    <span>Decline</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="view">
              <div class="file-image-wrapper">
                <img src="../../../assets/images/svgs/file-1.svg" />
              </div>
              <p class="t-bold">{{ document?.fileName }}</p>
            </div>
            <div class="doc-footer">
              <p class="t-light-gray">{{ document.dateCreated | date : 'dd/MM/yyyy' }}</p>
              <div class="action-btns">
                <img src="../../../assets/images/svgs/trash.svg" (click)="removeDoc(document._id, document.fileName)" />
                <a (click)="openFile(document?._id)" target="_blank"> <img src="../../../assets/images/svgs/download.svg" /></a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
