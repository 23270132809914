import { ListOptions } from '@avenews/agt-sdk';

export function getFiltersAndQueryMode(options: ListOptions): { filter: string; queryMode: string } {
  const filter = options.filters ? `filters=${JSON.stringify(options.filters)}` : '';

  let queryMode = '';

  if (options.queryMode) {
    queryMode = filter ? `&queryMode=${options.queryMode}` : `queryMode=${options.queryMode}`;
  }

  return { filter, queryMode };
}
