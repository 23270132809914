import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'base-modal-footer',
  templateUrl: './base-modal-footer.html',
  styleUrls: ['./base-modal-footer.scss']
})
export class BaseModalFooter implements OnInit {
  @Input() customClass: string;
  constructor() {}
  ngOnInit(): void {}
}
