import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fullname' })
export class FullnamePipe implements PipeTransform {
  transform(user: any): any {
    let res = '';
    if (user) {
      if (!user.personalInformation || (!user.personalInformation.firstName && !user.personalInformation.lastName)) {
        res = '-';
      } else if (!user.personalInformation.firstName) {
        res = user.personalInformation.lastName;
      } else if (!user.personalInformation.lastName) {
        res = user.personalInformation.firstName;
      } else res = user.personalInformation.firstName + ' ' + user.personalInformation.lastName;
    }
    return res;
  }
}
