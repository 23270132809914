import { defaultPagination } from './../../core/constants/index';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { PaginatedResult } from '@avenews/agt-sdk';
import { Transaction } from '@avenews/base-types';
import { getFiltersAndQueryMode } from '@avenews/shared-lib';

@Injectable()
export class AllTransactionListResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: any) {
    // get the second parameter in the url, eg. /transactions/topup (get 'topup')
    const type = route.url[1].path;
    if (type !== 'all') {
      return this.getTransactions(type);
    }
    const filters = route.data['filters'];
    let options = defaultPagination.options;
    if (route.data['options']) {
      options = route.data['options'];
    }
    if (route.data['sortBy']) {
      options = { ...options, sortBy: route.data['sortBy'] };
    }
    const overridePageSize = route.data['pageSize'];
    let { filter, queryMode } = getFiltersAndQueryMode({
      pagination: options,
      filters: filters?.length ? filters : undefined,
      queryMode: route.data['queryMode'] || 'and'
    });
    const { pageSize, pageNumber, sortBy, sortOrder } = options;
    return (
      this.http.get(
        `${environment.new_api_url}/api/transaction/${
          overridePageSize || pageSize
        }/${pageNumber}/${sortBy}/${sortOrder}?${filter}${queryMode}`
      ) as Observable<PaginatedResult<Transaction>>
    ).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }

  getTransactions(type: string) {
    return (
      this.http.get(`${environment.new_api_url}/api/transaction/custom/${type}/10/1/_id/desc`) as Observable<PaginatedResult<Transaction>>
    ).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
