export interface Statement {
  name: string;
  overdraft: number;
  bet: number;
  loan: number;
}
export interface Lpo {
  year: number;
  month: string;
  amount: number;
}
export interface DocumentNeeded {
  document: DocumentType;
  submitted?: boolean;
  status: DocumentStatus;
}
export enum DocumentStatus {
  APPROVE = 'approved',
  DECLINE = 'declined'
}
export interface NecessaryFile {
  file: string;
  fileName: string;
  mime: string;
  type: DocumentType;
  status?: DocumentStatus;
  description?: string;
}
export interface LoanDecisionDTO {
  agricheck?: 'yes' | 'no';
  mpesaStatement?: Statement[];
  bankStatement?: Statement[];
  lpoVolume?: Lpo[];
  beurreCheck?: NecessaryFile;
  documents?: DocumentNeeded[];
}
enum DocumentType {
  ID = 'id',
  PROOF_OF_ADDRESS = 'proofOfAddress',
  PERSONAL_FINANCES = 'personalFinances',
  BANK_STATEMENT = 'bankStatement',
  ENTITY_REGISTRATION = 'entityRegistration',
  LPO = 'lpo',
  BUSINESS_TRANSACTION = 'businessTransactions',
  PAYMENT_RECEIPT = 'paymentReceipt',
  FINANCIAL_STATEMENT = 'financialStatement',
  LOAN_SUMMARY = 'loanSummary',
  BUSINESS_FINANCES = 'businessFinances'
}
