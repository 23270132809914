import { ActivatedRoute } from '@angular/router';
import { User } from '@avenews/agt-sdk';
import { TradeDeskService } from './../../services/trade-desk.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MentionConfig } from 'angular-mentions';
import { Location } from '@angular/common';

@Component({
  selector: 'td-notes',
  templateUrl: './td-notes.component.html',
  styleUrls: ['./td-notes.component.scss']
})
export class TdNotesComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() loan: any;
  @Input() users: User[];
  @ViewChild('noteInput', { static: true }) noteInput: any;
  mentions = [];
  noteId = '';
  dontScroll = false;
  mentionConfig: MentionConfig = {
    items: [],
    triggerChar: '@',
    labelKey: 'name',
    allowSpace: true,
    mentionSelect: (item) => {
      const savedItem = '@' + item.name;
      this.mentions.push({ ...item, at: savedItem });
      // remove duplicate items from mentions
      this.mentions = this.mentions.filter((item, index, self) => {
        return index === self.findIndex((t) => t._id === item._id);
      });
      return savedItem + ' ';
    }
  };
  note = new FormControl('', Validators.required);
  notes = [];
  user: User;
  constructor(private tdService: TradeDeskService, private location: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.user = data?.['user'];
    });
    this.route.queryParams.subscribe((data) => {
      setTimeout(() => {
        if (!data?.['noteId']) {
          this.dontScroll = true;
        }
        this.noteId = data?.['noteId'];
      });
    });
  }
  ngOnChanges(): void {
    if (this.users) {
      this.mentionConfig.items = [];
      this.users.forEach((user) => {
        if (!(user as any)?.fake) {
          console.log(user);
          this.mentionConfig.items.push({
            _id: user._id,
            name: user.personalInformation?.firstName + ' ' + user.personalInformation?.lastName
          });
        }
      });
      if (this.loan) {
        const notes = [];
        this.loan?.generalNotes?.forEach((note) => {
          notes.push({
            ...note,
            user: this.users.find((user) => user._id === note.createdBy) || this.users[0]
          });
        });

        this.notes = notes.reverse();
      }
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!window.location.href.includes('noteId')) {
        this.dontScroll = true;
      }
      if (!this.dontScroll) {
        document.querySelector(`.highlight.note-thing`)?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        this.dontScroll = true;
        setTimeout(() => {
          this.location.go(this.location.path().split('?')[0]);
        }, 2000);
      }
    });
  }
  addNote() {
    if (this.note.value) {
      const actuallyMentioned = [];
      this.mentions.forEach((mention) => {
        const reg = new RegExp(mention.at + '\\s', 'g');
        if (this.note.value.match(reg)) {
          actuallyMentioned.push(mention._id);
        }
      });
      //remove current user from mentioned users
      const index = actuallyMentioned.indexOf(this.user._id);
      if (index > -1) {
        actuallyMentioned.splice(index, 1);
      }
      this.tdService.addNote(this.note, this.loan?._id, actuallyMentioned);
    }
  }
  deleteNote(id: string) {
    this.tdService.deleteNote(id).then((data) => {
      this.notes = this.notes.filter((data) => {
        return data._id !== id;
      });
    });
  }
  keyDown(e: KeyboardEvent) {
    if (e.key === 'Backspace') {
      const input = this.noteInput.nativeElement;
      const inputVal = input.value;
      // go back to the last @ relative to the caret position
      const lastAt = inputVal.lastIndexOf('@', input.selectionStart);
      // if there is an @ in the input
      if (lastAt !== -1) {
        // get the last word in the input
        const lastWord = inputVal.substring(lastAt, input.selectionStart);
        // if the last word is in the mentions array, remove it from the input
        if (this.mentions.find((mention) => mention.at === lastWord)) {
          e.preventDefault();
          const first = inputVal.substring(0, lastAt);
          const second = inputVal.substring(input.selectionStart);
          input.value = first + second;
          input.selectionStart = first.length;
          input.selectionEnd = first.length;
        }
      }
    }
  }
}
