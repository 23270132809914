// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=staging` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  api_url: 'https://qa.api.agt4.avenews.io',
  new_api_url: 'https://qa.api.new.agt4.avenews.io',
  data_url: 'https://qa.api.data.avenews.io',
  version: '2.0.0-dev',
  firebase: {
    apiKey: 'AIzaSyC-UEsuCzop6hy8-wXmIdB6M9DOixQmmO0',
    authDomain: 'avenews-pay.firebaseapp.com',
    projectId: 'avenews-pay',
    storageBucket: 'avenews-pay.appspot.com',
    messagingSenderId: '650322505391',
    appId: '1:650322505391:web:40e544a083c88adbc3ae5a',
    measurementId: 'G-PF2KMT8QHQ',
    vapidKey: 'BLlidIc433tQiOQ3PnhuVjRgLV2DaoOr4NkQsqhlT5JOiiiuoM3D1vEKiBfKfgYxMcsbN2p_Y33--f9P8NECiXc'
  },
  variables: {
    regionCode: 'KE',
    country: 'Kenya',
    capital: 'Nairobi',
    weatherCode: '224758',
    currency: 'KES',
    absa: 'Absa Bank Kenya PLC',
    payment: {
      clientId: 'AvenewsGT',
      clientSecret: 'fc7d0d34-f947-44f4-b635-af23b661c31b',
      scope: 'openid',
      issuer: 'https://absaauth-uat.absabank.co.ke/auth/realms/OBUATKE',
      responseType: 'token'
    },
    showPayment: true,
    showFinance: false
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'af-SA'],
  oauthClientIds: {
    facebook: '2144166195710655',
    google: '47077783387-0ehqh0fsh38lmvh43ppie7p1n1kqg4cm.apps.googleusercontent.com'
  },
  intercom: {
    app_id: 'zjpiv02o',
    tours: {
      orders: {
        generator: {
          suppliersTour: 95289,
          productsTour: 95403,
          orderTour: 95615,
          reviewTour: 96663
        }
      }
    }
  }
};
