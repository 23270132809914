import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Column } from '../../helpers/types/list.types';

@Pipe({
  name: 'column'
})
export class ColumnPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private decimalPipe: DecimalPipe, private titleCase: TitleCasePipe) {}
  transform(item: any, col: Column): any {
    const val = this.getValue(item, col);
    if (val && val !== '-' && col.pipe) {
      switch (col.pipe) {
        case 'phoneCode':
          if (item.countryPhoneCode) {
            if (!val.includes('+')) {
              return (item.countryPhoneCode.toString().includes('+') ? '' : '+') + item.countryPhoneCode.toString() + val;
            }
            return val;
          } else if (item[col?.countryPhoneCode!] && item[col?.countryPhoneCode!].countryPhoneCode) {
            if (!val.includes('+')) {
              return (
                (item[col?.countryPhoneCode!].countryPhoneCode.toString().includes('+') ? '' : '+') +
                item[col?.countryPhoneCode!].countryPhoneCode +
                val
              );
            }
          }
          break;
        case 'titleCase':
          return this.titleCase.transform(val);
        case 'date':
          return this.datePipe.transform(val, 'dd/MM/yyyy');
        case 'decimal':
          return this.decimalPipe.transform(val, '1.2-2');
        case 'number':
          return this.decimalPipe.transform(val, '1.0-2');
      }
    }
    return val;
  }
  getValue(item: any, col: Column) {
    const dtoName = col?.dtoName;
    if (dtoName.includes('.')) {
      const splitted = dtoName.split('.');
      let res: any = item;
      for (let i = 0; i < splitted.length; i++) {
        if (res && res[splitted[i]]) {
          res = res[splitted[i]];
        } else {
          return item?.alternative || '-';
        }
      }
      return (res != null && res) || '-';
    }
    if (item[dtoName] != null) {
      return item[dtoName];
    }
    return col?.alternative || '-';
  }
}
