import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
export interface BreadCrumbs {
  name: string;
  route: any;
}
@Component({
  selector: 'av-breadcrumbs-template',
  templateUrl: './base-breadcrumbs-template.component.html',
  styleUrls: ['./base-breadcrumbs-template.component.scss'],
})
export class BaseBreadcrumbsTemplateComponent implements OnInit {
  @Input() moduleName: string;
  @Input() breadCrumbs: BreadCrumbs[];
  constructor(private router: Router) {}

  ngOnInit() {}
  back(route: any) {
    this.router.navigate([route]);
  }
}
