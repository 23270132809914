<div class="header-wrapper">
  <div [class.heighter]="dashboardNav" class="header">
    <!-- <div *ngIf="dashboardNav" class="darshboard-top-info">
      <img src="assets/shared/topnav/new-welcome.svg" alt="welcome" class="welcome" />
      <div>
        <p class="date">{{ lastLogin | date: 'EE, MMM d, y' }}</p>
        <p class="full-name">
          Welcome {{ currentUser.personalInformation.firstName | titlecase }} {{ currentUser.personalInformation.lastName | titlecase }}!
        </p>
        <p class="sub-header">Ready to get things done? Good. Let’s get started!</p>
      </div>
    </div> -->
    <div class="user-info">
      <div class="notification-section">
        <div #bell [matMenuTriggerFor]="notificationMenu" #notificationMenuTrigger="matMenuTrigger" class="notification-bell">
          <div *ngIf="unread" class="unread-counter">{{ unread > 9 ? '9+' : unread }}</div>
          <i-feather name="bell"></i-feather>
        </div>
      </div>
      <mat-menu
        backdropClass="header-backdrop"
        class="notification-menu"
        yPosition="below"
        xPosition="before"
        #notificationMenu="matMenu"
        [hasBackdrop]="true"
      >
        <div class="notification-wrapper" (click)="$event.stopPropagation()">
          <div *ngIf="notificationState === 'normal'" class="notifications-header">
            <div (click)="toggleRead($event)" class="start">Mark all as read</div>
            <div (click)="removeNotification($event)" class="end"><i-feather name="x-circle"></i-feather>Clear all</div>
          </div>
          <div class="notifications-content">
            <ng-container *ngIf="notificationState === 'fetching'">
              <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="notificationState === 'normal'">
              <div class="list-container">
                <div
                  [class.hidden]="notification?.hidden"
                  (click)="openNotification(notification)"
                  (auxclick)="openNotification(notification, true)"
                  [ngClass]="{ active: !notification?.read, removing: notification?.['removing'] }"
                  *ngFor="let notification of notifications"
                  class="notification-item"
                >
                  <div class="inner-notification-header">
                    <div class="notification-title">
                      {{ notification?.message?.title }}
                    </div>
                    <div class="close-notification">
                      <i-feather (click)="removeNotification($event, notification)" name="x"></i-feather>
                    </div>
                  </div>
                  <div class="notification-body">“{{ notification?.message?.body }}”</div>
                  <div class="notification-footer">
                    <div class="notification-date">
                      {{ notification?.dateCreated | notificationDate }}
                    </div>
                    <div (click)="toggleRead($event, notification)" *ngIf="!notification?.read" class="mark-as-read"></div>
                    <div (click)="toggleRead($event, notification)" *ngIf="notification?.read" class="mark-as-unread">Mark as unread</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="notificationState === 'empty'">
              <div class="empty-state">
                <div class="notification-center">
                  <div class="bell-holder"><i-feather name="bell"></i-feather></div>
                  <div class="big-text">No notifications</div>
                  <div class="sub-text">When you get notifications, they’ll show up here.</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
      <div [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" class="click info-wrapper">
        <div class="img-wrapper">
          <img src="{{ currentUser?.personalInformation?.profilePicture }}" alt="" />
        </div>
        <div class="dropdown-wrapper">
          <div>{{ name }}</div>
        </div>
        <div class="arrow">
          <i-feather name="chevron-down"></i-feather>
        </div>
        <mat-menu backdropClass="header-backdrop" class="header-menu" yPosition="below" #menu="matMenu" [hasBackdrop]="true">
          <div class="menu-wrapper">
            <div
              (click)="logout(item)"
              class="header-menu-item"
              [attr.tooltip]="item?.tooltip"
              [routerLink]="item?.route"
              *ngFor="let item of headerMenu; let i = index"
              mat-menu-item
            >
              <div class="icon-wrapper">
                <i-feather name="{{ item?.icon }}"></i-feather>
              </div>
              <div>
                {{ item?.title }}
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
