<div class="top-header">
  <div>
    <img class="avenews-logo" src="{{ registrationConfig?.logo }}" alt="Avenwes-GT" />
  </div>
  <div class="flex-text-btn">
    <p class="gray">Already a member?</p>
    <av-button routerLink="/login" label="Sign in" type="secondary" size="small"></av-button>
  </div>
</div>
<div *ngIf="!isConfirmed" class="container">
  <div class="info-wrapper">
    <p class="info">
      {{ registrationConfig?.title }} <span *ngIf="registrationConfig?.withBlue">{{ registrationConfig?.blueTitle }}</span>
    </p>
    <div *ngFor="let info of registrationConfig?.infoCard" class="info-card">
      <p class="title">{{ info?.label }}</p>
      <p>{{ info?.infoContent }}</p>
    </div>
    <p class="member">Already a member? <a class="a" routerLink="/login">Sign in</a></p>
  </div>
  <div class="sign-up-wrapper">
    <form *ngIf="socialNetworkRegister" id="complement">
      <p class="welcome-social">Welcome {{ socialUser?.name }}</p>
      <p class="social">We have few more info that we need from you.</p>
      <app-base-form-template
        labelName="Country"
        bindLabel="viewValue"
        bindValue="viewValue"
        inputType="select"
        inputName="select"
        controlName="countrySocialUser"
        placeholderName="Choose country"
        [options]="countries"
        [size]="6"
        [group]="SocialUserForm"
      >
      </app-base-form-template>

      <div class="bttns-wrapper">
        <a class="back" (click)="regularForm()"> <i-feather name="chevron-left"></i-feather> Go Back</a>
        <av-button type="primary" label="Create Account with {{ network | titlecase }}" (click)="signInWithGoogle()"> </av-button>
      </div>
    </form>
    <ng-container *ngIf="!socialNetworkRegister">
      <p class="section-title">Sign Up</p>
      <form [formGroup]="registrationForm">
        <div class="full-name">
          <app-base-form-template
            labelName="First Name"
            inputType="text"
            inputName="default"
            [size]="12"
            controlName="firstName"
            [group]="registrationForm"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Last Name"
            inputType="text"
            inputName="default"
            [size]="12"
            controlName="lastName"
            [group]="registrationForm"
          >
          </app-base-form-template>
        </div>
        <app-base-form-template
          labelName="Email"
          inputType="text"
          inputName="default"
          [size]="12"
          controlName="email"
          [group]="registrationForm"
        >
        </app-base-form-template>
        <app-base-form-template
          labelName="Password"
          [inputType]="showPass ? 'text' : 'password'"
          inputName="default"
          [size]="12"
          controlName="password"
          [group]="registrationForm"
        >
        </app-base-form-template>
        <app-base-form-template
          labelName="Confirm Password"
          [inputType]="showPass ? 'text' : 'password'"
          inputName="default"
          [size]="12"
          controlName="confirmPassword"
          [group]="registrationForm"
        >
        </app-base-form-template>

        <div class="pass-wrap">
          <av-checkbox (click)="showPassword()"></av-checkbox>
          <p class="show-pass">Show password</p>
        </div>
        <div class="help-block" *ngIf="passwordsNotMatch()">
          <p>Passwords doesn't match</p>
        </div>
      </form>

      <p class="policy">
        By signing up, you confirm that you’ve read and accepted our <a class="a">Terms</a> of use and <a class="a">Privacy Policy</a>.
      </p>
      <div class="login-bttns">
        <av-button
          [type]="isLoading ? 'icon' : 'primary'"
          [rotateIcon]="isLoading"
          [icon]="isLoading ? 'settings' : ''"
          iconType="primary"
          size="stretched"
          label="Sign up"
          (click)="onGeneralSubmit()"
        >
        </av-button>

        <ng-container *ngIf="registrationConfig?.socialLogin">
          <p class="text-center">OR</p>
          <av-button (click)="preSignUp('google')" type="secondary" size="stretched" label="Sign in with Google">
            <img class="google-logo" src="assets/images/regestration-img/google logo.svg" alt="Google" />
          </av-button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="isConfirmed" id="confirmation">
  <div class="confirmation-card">
    <img src="assets/images/regestration-img/account-verification.svg" />
    <h1 class="mb-4 check">Check your email</h1>

    <p class="success-content" *ngIf="unVerifiedEmail; else elseBlock">
      We’ve sent a verification email to
      <strong>{{ unVerifiedEmail }}.</strong>
      <br />
      Click the link inside to get started.
    </p>
    <ng-template #elseBlock>
      <p class="success-content">
        We’ve sent a verification email to
        <strong>{{ user?.personalInformation.phoneNumber }}.</strong>
        <br />
        Click the link inside to get started.
      </p>
    </ng-template>

    <p class="success-content font2">Can’t find the email?</p>
    <av-button label="Re-send Email" size="default" (click)="resendConfirm()" type="primary"></av-button>
  </div>
</div>

<!-- uncomment when the modal ready -->
<!-- <div class="modal-box" id="registerSuccessModal">
  <div class="modal-box-content">
    <div class="sa">
      <div class="icon"></div>
      <div class="check2"></div>
    </div>
    <div>
      <p class="alert-content">Verification email has been sent!</p>
    </div>
    <div class="modal-delete-footer">
      <button class="bttn-success mt-5 okButton" (click)="closeSuccsses()">Ok</button>
    </div>
  </div>
</div> -->
