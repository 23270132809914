<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Order view'"></av-breadcrumbs-template>

<div class="user-view-wrapper">
  <frontend-base-side-view
    [topTitle]="topTitle"
    [action]="false"
    [addDetails]="true"
    additionalDetails="Additional details"
    [sideViewAddDetails]="sideViewAddDetails"
    [sideView]="sideView"
    [basicDetails]="'Order details'"
    [entity]="order"
  >
    <ng-template #fieldValue let-item="item" let-entity="entity" let-p="template">
      <ng-container [ngTemplateOutlet]="p" *ngIf="item?.name !== 'STATUS' && item?.name !== 'FINANCE % CAP'"> </ng-container>

      <ng-container *ngIf="item?.name === 'FINANCE % CAP'">
        <td *ngIf="entity?.financedCapRate">
          {{ entity?.financedCapRate * entity?.total | number: '1.0-2' }} ({{ entity?.financedCapRate * 100 }}%)
        </td>
        <td *ngIf="!entity?.financedCapRate">-</td>
      </ng-container>

      <ng-container *ngIf="item?.name === 'STATUS'">
        <div class="loan-status status-{{ entity?.status }}">
          <p>
            {{ statusGrabber(entity?.status) }}
          </p>
        </div></ng-container
      >
    </ng-template>
  </frontend-base-side-view>

  <mat-tab-group [disableRipple]="true" id="order-view-tabs">
    <mat-tab color="#152C5B" label="Main data">
      <frontend-main-data [order]="order"></frontend-main-data>
    </mat-tab>
    <mat-tab color="#152C5B" label="Questions">
      <frontend-questions [order]="order"></frontend-questions>
    </mat-tab>
  </mat-tab-group>
</div>
