import { ValidatorFn, AbstractControl } from '@angular/forms';

export function MinControlValidator(minName: string, opposite?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validAmount = true;
    const min = control?.parent?.get(minName)?.value;
    if (control.value) {
      !opposite && min && (validAmount = control.value < min);
      opposite && min && (validAmount = control.value > min);
    }
    return validAmount || control.value == null ? null : { minAmount: `Minimum must be ${opposite ? 'bigger' : 'smaller'} than ${min}` };
  };
}
