import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Item {
  status: string;
  fileName: string;
  editMode: boolean;
}
@Component({
  selector: 'block-item',
  templateUrl: './block-item.component.html',
  styleUrls: ['./block-item.component.scss']
})
export class BlockItem {
  @Input() item: Item;
  @Output() moveTo = new EventEmitter();

  ngOnInit() {}

  moveToDetails() {
    this.moveTo.emit(this.item);
  }
}
