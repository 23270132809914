<div class="text-slate-900 rounded-lg bg-white p-4 mt-2">
  <ng-container *ngIf="!loading">
    <mat-accordion *ngIf="bureaus?.length">
      <mat-expansion-panel class="mb-4" *ngFor="let biro of bureaus" #panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-bold text-xl"> Bureau - {{ biro.nationalId }}</mat-panel-title>
          <mat-panel-description> Click to {{ panel.expanded ? 'collapse' : 'expand' }} </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="p-4 rounded-lg mx-auto shadow-lg bg-gray-700 text-white w-auto self-center dashboard flex flex-col">
          <h3 class="flex items-center text-left">
            <i-feather class="text-slate-200 mr-2 w-4 h-4" name="pie-chart"></i-feather> Creditinfo Predictor (CIP)
          </h3>
          <div class="flex flex-row justify-around">
            <div class="flex flex-col items-center">
              <div class="font-medium">{{biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.Score||'X'}}</div>
              <div>Score</div>
            </div>
            <div class="flex flex-col items-center">
              <div class="font-medium  {{ biro['gradeColor'] }}">
                {{biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.Grade||'X'}}
              </div>
              <div>Score Grade</div>
            </div>
            <div class="flex flex-col items-center">
              <div class="h-8">
                <i-feather
                  class="{{biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.Trend|trendIcon:true}}"
                  name="{{biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.Trend|trendIcon}}"
                ></i-feather>
              </div>
              <div>Trend</div>
            </div>
          </div>
        </div>
        <div class="border-b border-s mt-5 overflow-auto whitespace-nowrap tabler">
          <h3 class="!font-bold text-slate-900 text-xl">Open Contracts - Debtor</h3>
          <table class="min-w-full">
            <thead class="border-b bg-gray-700">
              <tr>
                <th scope="col" class="font-medium rounded-tl-xl text-left text-white px-6 py-4">Subscriber</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Type of Contract</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Opened</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Updated</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Total</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Balance</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Past Due Amount</th>
                <th scope="col" class="font-medium rounded-tr-xl text-center text-white px-6 py-4">Past Due Days</th>
              </tr>
            </thead>
            <ng-container *ngIf="biro?.openDebtorContracts?.length; else noOpen">
              <tbody>
                <tr class="border-b" *ngFor="let contract of biro?.openDebtorContracts; let i = index">
                  <td class="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">{{ contract?.Subscriber }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.AccountProductType }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">
                    {{ contract?.DateAccountOpened | date: 'dd/MM/yyyy' }}
                  </td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.lastUpdate | date: 'dd/MM/yyyy' }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.total }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.balance }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.PastDueAmount || '0.00 KES' }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.DaysInArrears }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-gray-900 font-medium px-6 text-center py-4 whitespace-nowrap">
                    {{ openDebtorSums?.total | number: '1.2-2' }} {{ openDebtorSums?.currency }}
                  </td>
                  <td class="text-gray-900 font-medium px-6 text-center py-4 whitespace-nowrap">
                    {{ openDebtorSums?.balance | number: '1.2-2' }} {{ openDebtorSums?.currency }}
                  </td>
                  <td class="text-gray-900 font-medium px-6 text-center py-4 whitespace-nowrap">
                    {{ openDebtorSums?.pastDueAmount | number: '1.2-2' }} {{ openDebtorSums?.currency }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </ng-container>
            <ng-template #noOpen>
              <div class="flex justify-center font-medium text-slate-900 text-2xl mt-4 mb-4">
                There is no open debtor contracts for this bureau
              </div>
            </ng-template>
          </table>
        </div>
        <div class="border-b border-s mt-5 overflow-auto whitespace-nowrap tabler">
          <h3 class="!font-bold text-slate-900 text-xl">Closed Contracts - Debtor</h3>
          <table class="min-w-full shadow">
            <thead class="border-b bg-gray-700">
              <tr>
                <th scope="col" class="font-medium rounded-tl-xl bg-gray-700 sticky top-0 text-left text-white px-6 py-4">Subscriber</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Type of Contract</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Closed</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Status</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Total</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Balance</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4">Past Due Amount</th>
                <th scope="col" class="font-medium bg-gray-700 sticky top-0 text-center text-white px-6 py-4 rounded-tr-xl">
                  Past Due Days
                </th>
              </tr>
            </thead>
            <ng-container *ngIf="biro?.closedDebtorContracts?.length; else noClosed">
              <tbody>
                <tr class="border-b" *ngFor="let contract of biro?.closedDebtorContracts">
                  <td class="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">{{ contract?.Subscriber }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.AccountProductType }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">
                    {{ !contract?.RealEndDate ? '-' : (contract?.RealEndDate | date: 'dd/MM/yyyy') }}
                  </td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.PhaseOfContract }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.total || '-' }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.balance || '-' }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ contract?.PastDueAmount || '-' }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">
                    {{ contract?.DaysInArrears === '0' ? '-' : contract?.DaysInArrears }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-gray-900 px-6 text-center font-medium py-4 whitespace-nowrap">
                    {{ closedDebtorSums?.total | number: '1.2-2' }} {{ closedDebtorSums?.currency }}
                  </td>
                  <td class="text-gray-900 px-6 text-center font-medium py-4 whitespace-nowrap">
                    {{ closedDebtorSums?.balance | number: '1.2-2' }} {{ closedDebtorSums?.currency }}
                  </td>
                  <td class="text-gray-900 px-6 text-center font-medium py-4 whitespace-nowrap">
                    {{ closedDebtorSums?.pastDueAmount | number: '1.2-2' }} {{ closedDebtorSums?.currency }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </ng-container>
            <ng-template #noClosed>
              <div class="flex justify-center font-medium text-slate-900 text-2xl mt-4 mb-4">
                There is no closed debtor contracts for this bureau
              </div>
            </ng-template>
          </table>
        </div>
        <div class="border-b border-s mt-5 overflow-auto whitespace-nowrap tabler">
          <h3 class="!font-bold text-slate-900 text-xl">Top Reason Codes</h3>
          <table class="min-w-full">
            <thead class="border-b bg-gray-700">
              <tr>
                <th scope="col" class="font-medium rounded-tl-xl text-left text-white px-6 py-4">Reason Code</th>
                <th scope="col" class="font-medium text-center text-white px-6 py-4">Description</th>
              </tr>
            </thead>
            <ng-container
              *ngIf="biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.ReasonsList?.Reason?.length; else noOpen"
            >
              <tbody>
                <tr
                  class="border-b"
                  *ngFor="let reason of biro?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.ReasonsList?.Reason; let i = index"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-left font-medium text-gray-900">{{ reason?.Code }}</td>
                  <td class="text-gray-900 px-6 text-center py-4 whitespace-nowrap">{{ reason?.Description }}</td>
                </tr>
              </tbody>
            </ng-container>
            <ng-template #noOpen>
              <div class="flex justify-center font-medium text-slate-900 text-2xl mt-4 mb-4"></div>
            </ng-template>
          </table>
        </div>
        <div class="charter mt-4">
          <canvas
            baseChart
            [options]="chartOptions"
            class="chart"
            [data]="{ datasets: $any(biro)?.scoring?.datasets, labels: $any(biro)?.scoring?.labels }"
            type="line"
          ></canvas>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="w-full mt-4">
      <app-base-form-template
        [errorMessages]="errorMessages"
        [size]="6"
        labelName="National ID"
        [group]="bureauForm"
        inputType="text"
        inputName="default"
        controlName="nationalId"
      >
      </app-base-form-template>
      <button (click)="addBureau()" class="bttn btn-actions">Add bureau</button>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <img width="150px" height="100px" src="../../../assets/loading.gif" alt="" />
  </ng-container>
</div>
