import { LoanApplication, User } from '@avenews/agt-sdk';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@avenews/alerts';
import { EMPTY, catchError } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'disb-ml-modal',
  templateUrl: './disb-ml-modal.html',
  styleUrls: ['./disb-ml-modal.scss']
})
export class DisbMlModal implements OnInit {
  @Input() loan: any;
  @Input() mlScoreData: any;
  @Input() originalOffer: any;
  monthOptions = [
    {
      viewValue: 'January',
      value: 1
    },
    {
      viewValue: 'February',
      value: 2
    },
    {
      viewValue: 'March',
      value: 3
    },
    {
      viewValue: 'April',
      value: 4
    },
    {
      viewValue: 'May',
      value: 5
    },
    {
      viewValue: 'June',
      value: 6
    },
    {
      viewValue: 'July',
      value: 7
    },
    {
      viewValue: 'August',
      value: 8
    },
    {
      viewValue: 'September',
      value: 9
    },
    {
      viewValue: 'October',
      value: 10
    },
    {
      viewValue: 'November',
      value: 11
    },
    {
      viewValue: 'December',
      value: 12
    }
  ];
  modalService: ModalService;
  biro: any;
  options: any;
  kyc: any;
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private spinner: SpinnerToggleService,
    private alertService: AlertService
  ) {}
  ngOnInit(): void {
    this.loan = this.options?.loan;
    this.mlScoreData = this.options?.mlScoreData;
    this.biro = this.options?.biro;
    this.kyc = this.options?.kyc;
    this.originalOffer = this.options?.originalOffer;
    console.log(this.options);
    let dateOfBirth = this.biro?.bureauCheck?.GetCustomReportResult?.Individual?.General?.DateOfBirth;
    if (!dateOfBirth) {
      dateOfBirth = this.kyc?.front?.dateOfBirthFormatted;
    }
    if (dateOfBirth) {
      dateOfBirth = new Date(dateOfBirth);
    }
    this.form = this.fb.group({
      disbursmentAmount: [0, [Validators.required, Validators.min(1)]],
      repaymentAmount: [0, [Validators.required, Validators.min(1)]],
      age: [dateOfBirth ? this.getAge(dateOfBirth) : 0, [Validators.required, Validators.min(1)]],
      paymentTerm: [0, [Validators.required, Validators.min(1)]],
      disbursmentMonth: [0, [Validators.required, Validators.min(1)]],
      repaymentMonth: [0, [Validators.required, Validators.min(1)]]
    });
    this.patchValues();
  }

  close() {
    this.modalService.close();
  }
  getAge(dateOfBirth: Date) {
    const diff = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  patchValues() {
    let disbAmount = 0;
    let repaymentAmount = 0;
    let paymentTerms = 0;
    if (!this.originalOffer) {
      disbAmount = this.loan.dealId?.totalBuying;
      repaymentAmount = this.loan.dealId?.optOffer;
    } else if (this.originalOffer) {
      disbAmount = this.originalOffer?.optTotalBuying || this.loan.dealId?.totalBuying;
      repaymentAmount = this.originalOffer?.optOffer || this.loan.dealId?.optOffer;
    }
    paymentTerms = this.originalOffer?.paymentTerms || this.loan.dealId?.periodWeeks * 7;
    paymentTerms = isNaN(paymentTerms) ? 0 : paymentTerms;
    let repaymentMonth = 0;
    // repayment month should be today + the amount of days in paymentTerms
    if (paymentTerms) {
      let repaymentDate = new Date();
      repaymentDate.setDate(new Date().getDate() + paymentTerms);
      repaymentMonth = repaymentDate.getMonth() + 1;
    }
    this.form.patchValue({
      disbursmentAmount: this.mlScoreData?.disbursementMl?.mlValues?.disbValue
        ? this.mlScoreData?.disbursementMl?.mlValues?.disbValue
        : disbAmount,
      repaymentAmount: this.mlScoreData?.disbursementMl?.mlValues?.expectedRepaym
        ? this.mlScoreData?.disbursementMl?.mlValues?.expectedRepaym
        : repaymentAmount,
      paymentTerm:
        this.mlScoreData?.disbursementMl?.mlValues?.weeksPeriod * 7
          ? this.mlScoreData?.disbursementMl?.mlValues?.weeksPeriod * 7
          : paymentTerms,
      disbursmentMonth: this.mlScoreData?.disbursementMl?.mlValues?.disMonth
        ? this.mlScoreData?.disbursementMl?.mlValues?.disMonth
        : new Date().getMonth() + 1,
      repaymentMonth: this.mlScoreData?.disbursementMl?.mlValues?.repMonth
        ? this.mlScoreData?.disbursementMl?.mlValues?.repMonth
        : repaymentMonth
    });
  }
  submit() {
    const formValue = this.form.value;
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    const businessType = [];
    if (this.mlScoreData.business_type_aggregator) {
      businessType.push('aggregator');
    }
    if (this.mlScoreData.business_type_cooperative) {
      businessType.push('cooperative');
    }
    if (this.mlScoreData.business_type_inputs_provider) {
      businessType.push('inputsProvider');
    }
    if (this.mlScoreData.business_type_other) {
      businessType.push('other');
    }
    if (this.mlScoreData.business_type_processor) {
      businessType.push('processor');
    }
    if (this.mlScoreData.business_type_trader) {
      businessType.push('trader');
    }
    const mlDTO = {
      disbNumber: 1,
      produce: this.mlScoreData.financing_type === 'buying produce' || this.mlScoreData.financing_type === 'buying combo',
      maxDisbValue: formValue.disbursmentAmount,
      disbValue: formValue.disbursmentAmount,
      expectedRepaym: formValue.repaymentAmount,
      year: new Date().getFullYear(),
      disMonth: formValue.disbursmentMonth,
      repMonth: formValue.repaymentMonth,
      roleInBusiness: this.loan?.businessDetails?.role || 'other',
      age: formValue.age,
      businessRegistrationType: this.loan?.businessDetails?.type,
      businessType,
      hasStorage: this.loan.businessDetails?.storageFacility?.exists,
      storageSize: this.loan?.businessDetails?.storageFacility?.exists ? this.loan?.businessDetails?.storageFacility?.size : null,
      pdScore: this.mlScoreData.score * 100,
      hasBank: this.mlScoreData.has_bank,
      hasMpesa: this.mlScoreData.has_mpesa,
      hasTradeDocs: this.mlScoreData.has_trade_docs,
      hasHandwrittenTradeDocs: this.mlScoreData.has_handwritten_trade_docs,
      seniorityInBusiness: this.mlScoreData.seniority_in_business,
      educationLevel: this.mlScoreData.education_level,
      weeksPeriod: Math.ceil(formValue.paymentTerm / 7),
      male: this.mlScoreData.gender === 'male',
      mlScoreDataId: this.mlScoreData._id
    };
    console.log(mlDTO);
    this.spinner.showSpinner();
    const env = environment?.new_api_url?.includes('//api.new.kenya') ? 'prod' : 'qa';
    this.http
      .post(`https://disb-pd.avenews.io/predict-disb?env=${env}`, mlDTO)
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(err?.error?.message || 'Something went wrong', 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.alertService.showAlert(`Successfully calculated disbursment probabilities`, 'success');
        this.spinner.hideSpinner();
        this.modalService.close({ data, confirmed: true, mlValues: mlDTO });
      });
  }
}
