<div class="offer-wrapper">
  <div class="offer-container">
    <ng-container *ngIf="user?.policyRole?.name !== 'avenews-agent'">
      <ng-container *ngIf="originalOffer">
        <div class="w-100 initial-offer">
          <div class="w-100 heading d-flex justify-content-between">
            <div>
              <h3>Original loan offer</h3>
            </div>
            <div *ngIf="!offer?.minTotalBuying && user?.policyRole?.name !== 'avenews-agent'" (click)="openOffer()" class="click pen">
              <i-feather name="edit-2"></i-feather>
            </div>
          </div>
          <div class="offer-cards d-flex w-100">
            <div [ngClass]="{ declined: originalOffer?.minPricingCheck === false }" class="offer-card">
              <p *ngIf="originalOffer?.minPricingCheck === false" class="status status-red">Declined</p>
              <h3>Minimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ originalOffer?.minOffer - loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ originalOffer?.minOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
            <div [ngClass]="{ declined: originalOffer?.optPricingCheck === false }" class="offer-card">
              <p *ngIf="originalOffer?.optPricingCheck === false" class="status status-red">Declined</p>
              <h3>Optimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ originalOffer?.optOffer - loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ originalOffer?.optOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="6"
              labelName="Payment terms"
              sideText="In days"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="text"
              controlName="originalPaymentTerms"
            ></app-base-form-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="offer?.minTotalBuying">
        <div class="w-100 initial-offer">
          <div class="w-100 heading d-flex justify-content-between">
            <div>
              <h3>Loan offer</h3>
            </div>
            <div (click)="openOffer()" class="click pen">
              <i-feather name="edit-2"></i-feather>
            </div>
          </div>
          <div class="offer-cards d-flex w-100">
            <div [ngClass]="{ declined: offer?.minPricingCheck === false }" class="offer-card">
              <p *ngIf="offer?.minPricingCheck === false" class="status status-red">Declined</p>
              <h3>Minimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ offer?.minTotalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ offer?.minFee | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ offer?.minOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
            <div [ngClass]="{ declined: offer?.optPricingCheck === false }" class="offer-card">
              <p *ngIf="offer?.optPricingCheck === false" class="status status-red">Declined</p>
              <h3>Optimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ offer?.optTotalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ offer?.optFee | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ offer?.optOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="6"
              labelName="Payment terms"
              sideText="In days"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="text"
              controlName="paymentTerms"
            ></app-base-form-template>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="12"
              labelName="Offer notes"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="textarea"
              controlName="notes"
            ></app-base-form-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="user?.policyRole?.name === 'avenews-agent'">
      <ng-container *ngIf="offer?.minTotalBuying">
        <div class="w-100 initial-offer">
          <div class="w-100 heading d-flex justify-content-between">
            <div>
              <h3>Loan offer</h3>
            </div>
            <div (click)="openOffer()" class="click pen">
              <i-feather name="edit-2"></i-feather>
            </div>
          </div>
          <div class="offer-cards d-flex w-100">
            <div [ngClass]="{ declined: offer?.minPricingCheck === false }" class="offer-card">
              <p *ngIf="offer?.minPricingCheck === false" class="status status-red">Declined</p>
              <h3>Minimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ offer?.minTotalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ offer?.minFee | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ offer?.minOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
            <div [ngClass]="{ declined: offer?.optPricingCheck === false }" class="offer-card">
              <p *ngIf="offer?.optPricingCheck === false" class="status status-red">Declined</p>
              <h3>Optimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ offer?.optTotalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ offer?.optFee | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ offer?.optOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="6"
              labelName="Payment terms"
              sideText="In days"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="text"
              controlName="paymentTerms"
            ></app-base-form-template>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="12"
              labelName="Offer notes"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="textarea"
              controlName="notes"
            ></app-base-form-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="originalOffer && !offer?.minTotalBuying">
        <div class="w-100 initial-offer">
          <div class="w-100 heading d-flex justify-content-between">
            <div>
              <h3>Loan offer</h3>
            </div>
            <div (click)="openOffer()" class="click pen">
              <i-feather name="edit-2"></i-feather>
            </div>
          </div>
          <div class="offer-cards d-flex w-100">
            <div [ngClass]="{ declined: originalOffer?.minPricingCheck === false }" class="offer-card">
              <p *ngIf="originalOffer?.minPricingCheck === false" class="status status-red">Declined</p>
              <h3>Minimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ originalOffer?.minOffer - loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ originalOffer?.minOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
            <div [ngClass]="{ declined: originalOffer?.optPricingCheck === false }" class="offer-card">
              <p *ngIf="originalOffer?.optPricingCheck === false" class="status status-red">Declined</p>
              <h3>Optimal Offer</h3>
              <div class="offer-item">
                <div class="key">Disbursement amount</div>
                <div class="between"></div>
                <div class="value">{{ loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="offer-item">
                <div class="key">Fee</div>
                <div class="between"></div>
                <div class="value">{{ originalOffer?.optOffer - loan?.dealId?.totalBuying | number: '1.0-2' }}KES</div>
              </div>
              <div class="divider"></div>
              <div class="d-flex justify-content-between av-color">
                <div>Repayment amount</div>
                <div>{{ originalOffer?.optOffer | number: '1.0-2' }}KES</div>
              </div>
            </div>
          </div>
          <div class="section">
            <app-base-form-template
              [size]="6"
              labelName="Payment terms"
              sideText="In days"
              [group]="form"
              [rOnly]="true"
              inputName="default"
              inputType="text"
              controlName="originalPaymentTerms"
            ></app-base-form-template>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <h2 *ngIf="!offer">No offer yet</h2>
  </div>
  <div *ngIf="user?.policyRole?.name !== 'avenews-agent' && (originalOffer || offer?.minTotalBuying)" class="offer-buttons">
    <div>
      <button class="bttn btn-actions" (click)="updateOfferStatus('declined')">Decline</button>
    </div>
    <div>
      <button class="bttn-success" (click)="updateOfferStatus('approved')">Approve</button>
    </div>
  </div>
</div>
<div class="contents" fadeSidebar modalId="editOfferModal">
  <div class="modal-box" id="editOfferModal">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-heading">
        <p>Edit offer</p>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <div [formGroup]="offerForm" class="modal-box-content">
        <h3>Offer calculator</h3>
        <div class="form-pair offers-wrapper d-flex w-100">
          <app-base-form-template
            labelName="Disbursement amount"
            inputType="number"
            inputName="default"
            controlName="userAmount"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            [size]="6"
            labelName="Payment terms"
            sideText="In days"
            [group]="offerForm"
            inputName="default"
            inputType="number"
            controlName="paymentTerms"
          ></app-base-form-template>
          <div class="d-flex w-100 justify-content-end">
            <button (click)="calculateOffer()" class="btn-actions bttn">Calculate offer</button>
          </div>
        </div>
        <div [ngClass]="{ 'animate-spina-wrapper': spina }" class="spina-wrapper">
          <div *ngIf="spina" [ngClass]="{ 'animate-spina': spina }" class="spina">
            <img src="../../../../assets/loading.gif" />
          </div>
          <div class="offers-wrapper">
            <div [ngClass]="{ locked: !offerForm.value.minPricingCheck }" class="lock">
              <h3 *ngIf="!offerForm.value.minPricingCheck">
                <span class="no-good"> Declined </span>
              </h3>
            </div>
            <h3>Minimal offer</h3>
            <app-base-form-template
              [rOnly]="true"
              labelName="Disbursement amount"
              inputType="text"
              inputName="default"
              controlName="minTotalBuying"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <app-base-form-template
              [rOnly]="true"
              labelName="Fee"
              inputType="text"
              inputName="default"
              controlName="minFee"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <app-base-form-template
              [rOnly]="true"
              labelName="Repayment amount"
              inputType="text"
              inputName="default"
              controlName="minOffer"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
          </div>
          <div class="offers-wrapper">
            <div [ngClass]="{ locked: !offerForm.value.optPricingCheck }" class="lock">
              <h3 *ngIf="!offerForm.value.optPricingCheck">
                <span class="no-good"> Declined </span>
              </h3>
            </div>
            <h3>Optimal offer</h3>
            <app-base-form-template
              [rOnly]="true"
              labelName="Disbursement amount"
              inputType="text"
              inputName="default"
              controlName="optTotalBuying"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <app-base-form-template
              [rOnly]="true"
              labelName="Fee"
              inputType="text"
              inputName="default"
              controlName="optFee"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <app-base-form-template
              [rOnly]="true"
              labelName="Repayment amount"
              inputType="text"
              inputName="default"
              controlName="optOffer"
              [group]="cuteForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
          </div>
        </div>
        <!-- <div class="offers-wrapper">
          <app-base-form-template
            [size]="6"
            labelName="Payment terms"
            sideText="In days"
            [group]="offerForm"
            inputName="default"
            inputType="number"
            controlName="paymentTerms"
          ></app-base-form-template>
        </div> -->
        <div class="offers-wrapper">
          <app-base-form-template
            [size]="12"
            labelName="Notes"
            [group]="offerForm"
            inputName="default"
            inputType="textarea"
            controlName="notes"
          ></app-base-form-template>
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="offerForm.value.minPricingCheck || offerForm.value.optPricingCheck"
          #tooltip="matTooltip"
          matTooltip="{{ !checked ? 'Please calculate a new offer before saving a new one' : 'Generate new offer' }}"
          class="bttn-primary"
          (click)="save()"
        >
          Save
        </button>
        <button
          *ngIf="!offerForm.value.minPricingCheck && !offerForm.value.optPricingCheck"
          #tooltip="matTooltip"
          matTooltip="Unable to save offer, both offers are declined, please try different Disbursment amount or Payment terms"
          class="bttn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<frontend-reason-modal
  fadeSidebar
  modalId="reasonModal"
  title="Decline reaosn"
  (closed)="reasonRes(null)"
  (submitted)="resolveReason($event)"
></frontend-reason-modal>

<!-- <div class="contents" fadeSidebar modalId="editOfferModal">
  <div class="modal-box" id="editOfferModal">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-heading">
        <p>Edit offer</p>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <div [formGroup]="offerForm" class="modal-box-content">
        <div class="offers-wrapper">
          <app-base-form-template
            labelName="Disbursement amount"
            inputType="number"
            inputName="default"
            controlName="minTotalBuying"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            labelName="Fee"
            inputType="number"
            inputName="default"
            controlName="minFee"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            labelName="Repayment amount"
            inputType="number"
            inputName="default"
            controlName="minOffer"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
        </div>
        <div class="offers-wrapper">
          <app-base-form-template
            labelName="Disbursement amount"
            inputType="number"
            inputName="default"
            controlName="optTotalBuying"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            labelName="Fee"
            inputType="number"
            inputName="default"
            controlName="optFee"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            labelName="Repayment amount"
            inputType="number"
            inputName="default"
            controlName="optOffer"
            [group]="offerForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
        </div>
        <div class="offers-wrapper">
          <app-base-form-template
            [size]="6"
            labelName="Payment terms"
            sideText="In days"
            [group]="offerForm"
            inputName="default"
            inputType="number"
            controlName="paymentTerms"
          ></app-base-form-template>
        </div>
        <div class="offers-wrapper">
          <app-base-form-template
            [size]="12"
            labelName="Notes"
            [group]="offerForm"
            inputName="default"
            inputType="textarea"
            controlName="notes"
          ></app-base-form-template>
        </div>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div> -->
