<ng-container>
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      hideToggle
      #panel
      class="cursor-default category !p-0 !h-auto"
      id="panel"
    >
      <mat-expansion-panel-header class="pp !mr-0 cursor-default">
        <mat-panel-title class="!mr-0">
          <div class="w-full flex items-center justify-between">
            <div class="big-text">Sign off</div>
            <div class="flex items-center icon-holder">
              <div class="mr-3 cursor-pointer">
                <i-feather [@rotate]="panelOpenState.toString()" name="chevron-down"></i-feather>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="disbs flex justify-between" *ngFor="let signoff of signoffs; let i = index">
        <div class="text-primary font-medium">{{ signoff?.name }}</div>
        <div>
          <i-feather
            class="check"
            #tooltip="matTooltip"
            matTooltip="{{ signoff?.['name'] }} was signed off at {{ signoff?.data?.['date']|date:'dd/MM/YYYY' }}  By {{ signoff?.data?.['firstName'] }}  {{ signoff?.data?.['lastName'] }}"
            matTooltipClass="base-tooltip"
            *ngIf="signoff?.data?.['date']"
            name="check"
          ></i-feather>
          <button
            class="bg-avenews text-white rounded-lg p-1 mt-1"
            (click)="signOff(signoff?.action)"
            #tooltip="matTooltip"
            [matTooltip]="'Click this button when this task is done'"
            matTooltipClass="base-tooltip"
            *ngIf="!signoff?.data?.['date']"
          >
            Update
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
