import { first, Observable } from 'rxjs';
import { CdkConnectedOverlay, ConnectionPositionPair, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { Component, Input, Output, EventEmitter, ViewChild, HostListener, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';
let nextUniqueId = 0;

@Component({
  selector: 'av-select-overlay',
  templateUrl: './avenews-select.component.html',
  styleUrls: ['./avenews-select.component.scss']
})
export class AvenewsSelectComponent implements OnInit {
  private uniqueId = `av-select-overlay-${++nextUniqueId}`;
  escPressed$: Observable<KeyboardEvent>;
  tempItems = [];
  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 165,
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: null
    }
  ];
  searchTerm: string;
  positionStrategy: FlexibleConnectedPositionStrategy;
  subscriber: any;
  @Input() isOpen: boolean;
  @Input() avatar: boolean;
  @Input() selected: any;
  @Input() element: any;
  @Input() viewValue: string[] | string;
  @Input() allowSearch: boolean;
  @Input() searchFields: string[];
  @Input() items: any[] = [];
  @Input() maxHeight = 400;
  @Input() customClass = this.uniqueId;
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() selectedChange = new EventEmitter<any>();

  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: CdkConnectedOverlay;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.height = window.innerHeight;
  }
  height = window.innerHeight;
  constructor() {}

  ngOnInit(): void {
    this.cdkConnectedOverlay.positionChange.pipe(first()).subscribe((posChange) => {});
    this.tempItems = this.items?.slice?.();
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.['items']) {
      this.parseViewValue();
    }
  }
  parseViewValue() {
    this.items.forEach((data) => {
      if (this.viewValue instanceof Array) {
        data['viewValue'] = this.viewValue.map((field) => this.parseItem(data, field)).join(' ');
      } else {
        data['viewValue'] = this.parseItem(data, this.viewValue);
      }
    });
  }
  close() {
    this.subscriber.unsubscribe();
    this.isOpen = false;
    this.isOpenModal.emit(this.isOpen);
  }

  open() {
    setTimeout(() => {
      const parent = this.element?.elementRef?.nativeElement?.previousSibling;
      const overlay = this.cdkConnectedOverlay.overlayRef.overlayElement;
      // make left and top position of overlay same as parent and force it
      const left = parent?.offsetLeft;
      const top = parent?.offsetTop + parent?.offsetHeight;
      overlay.style.width = parent?.offsetWidth + 'px';
      // overlay.style.left = left + 'px';
      // overlay.style.top = top + 'px';

      this.subscriber = this.cdkConnectedOverlay.overlayOutsideClick.subscribe(() => {
        this.close();
      });
      $('.searcher').trigger('focus');
    });
  }
  onSearch(val?: any) {
    if (this.allowSearch && this.searchTerm) {
      if (!this.searchTerm) {
        this.items = this.tempItems?.slice?.();
      }
      this.searchFields = ['personalInformation.firstName', 'personalInformation.lastName'];
      this.items.forEach((data: any) => {
        data['filter'] = this.searchFields.map((field) => this.parseItem(data, field)?.toLowerCase?.()).join(' ');
      });
      this.items = this.tempItems.filter((data: any) => data['filter'].includes(this.searchTerm.toLowerCase()));
    } else {
      this.items = this.tempItems?.slice?.();
    }
  }
  parseItem(item: any, field: string) {
    return field.split('.').reduce((o, i) => o[i], item);
  }
  selectItem(item: any) {
    this.selectedChange.emit(item);
    this.close();
  }
}
