import { ModalService } from '../services';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BackAlertModal } from '../components/back-alert/back-alert.component';

@Injectable({
  providedIn: 'root'
})
/**
 * This guard is used to prevent the user from going back to the previous page,
 * to customize it you can add an object called (backAlertOptions) to your component
 */
export class BackGuard implements CanDeactivate<any> {
  constructor(private modalService: ModalService) {}
  async canDeactivate(component: any): Promise<boolean> {
    console.log(component);
    return false;
    if (!component.canGoBack) {
      return this.backAlert(component);
    } else {
      return true;
    }
  }

  private backAlert(component: any): Promise<boolean> {
    return new Promise((res) => {
      let data;
      if (component.backAlertOptions) {
        data = {
          backAlertOptions: component.backAlertOptions
        };
      }
      const ref = this.modalService.open(BackAlertModal, {
        data
      });
      ref.afterClosedPromise().then((postClose) => {
        console.log(postClose);
        console.log(postClose?.confirmed);
        res(postClose?.confirmed);
      });
    });
  }
}
