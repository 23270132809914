<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="' All transactions'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-users-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (sendFilters)="filter($event)"
  (bulkActions)="actionsBulk($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'BENEFICIARY NAME' &&
        col?.name !== 'DOCUMENTS' &&
        col?.name !== 'STATUS' &&
        col?.name !== 'DISCOUNT' &&
        col?.name !== 'CREDITOR'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>

    <ng-container *ngIf="col?.name === 'CREDITOR'">
      <td>
        {{ item?.creditor?.creditor | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY NAME'">
      <td>
        {{ item?.beneficiary?.beneficiary | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'DISCOUNT'">
      <td>
        {{ item?.discount || 0 }}
      </td></ng-container
    >

    <ng-container *ngIf="col?.name === 'DOCUMENTS'">
      <td [matTooltip]="getDocs(item)" matTooltipClass="base-tooltip" #statusTooltip="matTooltip">
        {{ (item?.documents?.[0]?.fileName) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="status status-{{ statusGrabber(item?.status) }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<frontend-bnpl-status [transaction]="transaction"></frontend-bnpl-status>
