import { Agt4Deal, User, Agt4Period } from '@avenews/agt-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injector, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, ListNoSdkComponent, ModalService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import * as $ from 'jquery';
import { WithdrawModal } from '../../../shared/components/withdraw-modal/withdraw-modal.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'frontend-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
  animations: [
    trigger('rotate', [
      state('true', style({ transform: 'rotate(180deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('true => false', animate('300ms ease-out')),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class DealsComponent extends ListNoSdkComponent<Agt4Deal> implements OnInit, AfterViewInit {
  deal: Agt4Deal;
  isEdit: boolean;
  users: User;
  period: Agt4Period;
  isStatusOpen: boolean;
  isPeriodOpen: boolean;
  isWithdrawOpen: boolean;
  @Input() organization: any;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  constructor(injector: Injector, private router: Router, private route: ActivatedRoute, private modalService: ModalService) {
    super(injector, {
      noPagination: true,
      name: 'getDeals',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/deals`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'deals';
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ users }) => {
      // this.users = users;
    });
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    super.init();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No deals found',
        img: 'assets/images/empty-states/deal-empty.png',
        button: {
          label: 'New',
          action: 'add'
        }
      },
      actions: [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Edit', type: 'default', action: 'edit' },
        { label: 'New period', type: 'default', action: 'add' }
      ],
      bulkAddButton: undefined,
      addButton: {
        emptyStateLabel: 'New',
        isDropdown: false,
        label: 'New',
        emittedValue: 'add'
      },
      fixedColumns: ['ID', 'PARTNER NAME', 'ACTIONS'],
      mainTitle: '',

      columns: [
        {
          name: 'DEAL NAME',
          dtoName: 'numericId',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'MAX AMOUNT',
          dtoName: 'maxAmount',
          pipe: 'number',
          type: 'number',
          selected: true,
          sticky: true
        },
        {
          name: 'START DATE',
          dtoName: 'startDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'END DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'PERIOD DURATION',
          dtoName: 'periodDuration',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'DEAL TYPE',
          dtoName: 'type',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'PERIOD TYPE',
          dtoName: 'periodType',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'REPAYMENT TYPE',
          dtoName: 'repaymentInfo.repaymentMode',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'DEAL NAME',
          dtoName: 'dealName',
          type: 'text'
        },
        {
          name: 'MAX AMOUNT',
          dtoName: 'maxAmount',
          type: 'number'
        },
        {
          name: 'START DATE',
          dtoName: 'startDate',
          type: 'date'
        },
        {
          name: 'END DATE',
          dtoName: 'endDate',
          type: 'date'
        },
        {
          name: 'PERIOD DURATION',
          dtoName: 'periodDuration',
          type: 'number'
        },

        {
          name: 'DEAL TYPE',
          dtoName: 'dealType',
          type: 'text'
        },
        {
          name: 'PERIOD TYPE',
          dtoName: 'periodType',
          type: 'text'
        },
        {
          name: 'REPAYMENT TYPE',
          dtoName: 'repaymentInfo.repaymentMode',
          type: 'text'
        },

        {
          name: 'status',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }

  ngAfterViewInit(): void {}

  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'edit':
        this.editDeal(e.entity);
        break;
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
      case 'withdrawB':
        this.withdrawB(e.entity);
        break;
      case 'add':
        this.addPeriod(e.entity);
        break;
    }
  }
  changeStatus(deal: Agt4Deal) {
    this.deal = deal;
    // $('#changeDealStatusWizard').css({
    //   display: 'flex'
    // });
    this.isStatusOpen = true;
  }
  editDeal(entity: any) {
    this.deal = entity;
    this.isEdit = true;
    $('#addDealWizard').css({
      display: 'flex'
    });
  }
  addDeal() {
    this.isEdit = false;
    this.deal = undefined;
    $('#addDealWizard').css({
      display: 'flex'
    });
  }
  addPeriod(deal: Agt4Deal) {
    this.isEdit = false;
    this.deal = deal;
    this.isPeriodOpen = true;
  }
  withdrawB(deal: Agt4Deal) {
    this.deal = deal;
    const ref = this.modalService.open(WithdrawModal, {
      deal: this.deal,
      organization: this.organization
    });
    ref.afterClosedPromise().then((postClose) => {
      if (postClose?.confirmed) {
        this.panel.open();
        this.pulse();
      }
    });
  }
  pulse() {
    const panel = document.getElementById('panel');
    $(panel).css('background', '#37c1ce');
    setTimeout(() => {
      $(panel).css('background-color', '#37c1ce20');
    }, 500);
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
      // if (sort.active === 'fullName') {
      //   this.list = this.list.sort((a, b) => {
      //     if (sort.active === 'fullName') {
      //       return sort?.direction === 'asc'
      //         ? a.contacts?.[0]?.firstName?.localeCompare(b.contacts?.[0]?.firstName)
      //         : b.contacts?.[0]?.firstName?.localeCompare(a.contacts?.[0]?.firstName);
      //     }
      //     return 1;
      //   });
      // } else {
      //   this.sortData(sort);
      // }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {});
      return res;
    });
    this.totalCount = this.list.length;
  }
  statusGrabber(status: string) {
    switch (status) {
      case 'paused':
        return 'Paused';
      case 'active':
        return 'Active';
      case 'completed':
        return 'Completed';
      case 'planned':
        return 'Planned';
      case 'cancelled':
        return 'cancelled';
      case 'defaulted':
        return 'Defaulted';
      default:
        return 'Planned';
    }
  }
  primaryActions(val?: string) {
    switch (val) {
      case 'add':
        this.addDeal();
        break;
    }
  }
  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Withdraw from balance', type: 'default', action: 'withdrawB' }
      ];
      if (item.status === 'planned') {
        actions.push({ label: 'Edit', type: 'default', action: 'edit' });
      }
      if (item?.status === 'active') {
        actions.push({ label: 'New period', type: 'default', action: 'add' });
      }

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
}
