import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'general-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() docName = 'sakjbxjksba';
  details: any;
  content: any;

  @Output() closeModal = new EventEmitter();

  ngOnInit() {
    if (this.docName === 'MPesaStatment') {
      this.content = {
        title: 'What is an M-Pesa statement?',
        sub: 'Mpesa statement is a report that shows the transactions you have made via Mpesa for a certain period of time.',
        steps: [
          'Dial *334#',
          'Select My Account.',
          'Select Request Statement.',
          'Select the Period of request.',
          'Enter the recipient’s email address.',
          'Repeat the recipient’s email address.',
          'Enter your M-PESA PIN.',
          'You will receive a notification SMS with the code you will use to access the statement.'
        ],
        showBtns: false
      };
    }
    if (this.docName === 'bankStatement') {
      this.content = {
        title: 'What is a Bank statement?',
        sub: 'A bank statement is a document that shows all the activity that has occurred in a bank account over a specific period of time. It includes deposits, withdrawals, fees, interest, and other account activity.',
        digitalSteps: [
          'Call your bank’s customer service number.',
          'Share with them your account number and any additional information they need.',
          'Ask for a Digital (Soft) copy of your bank statement for the past 12 months.',
          'Tell them how you want to receive it - like in an email.',
          'Upload the file here in the app.'
        ],
        physicalSteps: [
          'Log in to your online banking account or contact your bank’s customer service to request a hard copy bank statement.',
          'Provide the necessary account information, such as the account number, period for which the statement is required, and mailing address.',
          'Confirm the fees, if any, associated with the request and arrange for payment, if required. wait for the statement to be mailed to your address on file.',
          'Wait for the statement to be mailed to your address on file.',
          'When you receive the file, scan it to a digital copy.',
          'Share the scanned file with us through the app.'
        ],
        showBtns: true
      };
    }
  }
  close() {
    this.closeModal.emit();
  }
}
