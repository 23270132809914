<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Internal transactions'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-users-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (sendFilters)="filter($event)"
  (bulkActions)="actionsBulk($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'BENEFICIARY' &&
        col?.name !== 'BENEFICIARY BANK NAME' &&
        col?.name !== 'BENEFICIARY BRANCH CODE' &&
        col?.name !== 'BENEFICIARY ACC NUMBER' &&
        col?.name !== 'BENEFICIARY ACCOUNT NAME' &&
        col?.name !== 'TRANSACTION TYPE' &&
        col?.name !== 'HIGH RISK' &&
        col?.name !== 'CREDITOR NAME' &&
        col?.name !== 'INTERNAL' &&
        col?.name !== 'STATUS'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'INTERNAL'">
      <td>
        {{ item?.internal ? 'Yes' : 'No' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY ACCOUNT NAME'">
      <td>
        {{ item?.beneficiary?.beneficiary | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY'">
      <td>
        {{ item?.beneficiary?.beneficiary | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY BANK NAME'">
      <td>
        {{ (item?.beneficiary | beneficiaryDetails)?.bankName || '-' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY BRANCH CODE'">
      <td>
        {{ (item?.beneficiary | beneficiaryDetails)?.branchCode || '-' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'BENEFICIARY ACC NUMBER'">
      <td>
        {{ (item?.beneficiary | beneficiaryDetails)?.accountNumber || '-' }}
      </td></ng-container
    >

    <ng-container *ngIf="col?.name === 'HIGH RISK'">
      <td>
        {{ item?.transactionId?.includes('HR') ? 'High risk' : 'Low risk' }}
      </td></ng-container
    >

    <ng-container *ngIf="col?.name === 'TRANSACTION TYPE'">
      <td>
        {{ transactionType(item?.transactionId) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'CREDITOR NAME'">
      <td>
        {{ item?.creditor?.creditor | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="{{ !item?.status ? '' : (item?.status | status) }}">
          {{ item?.status || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<app-transaction-status-component [transaction]="transaction"></app-transaction-status-component>
