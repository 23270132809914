import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { BaseFormTemplateComponent, SpinnerToggleService } from '@avenews/shared-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY } from 'rxjs';
import { Status } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'frontend-user-approve-repayment',
  templateUrl: './user-approve-repayment.component.html',
  styleUrls: ['./user-approve-repayment.component.scss']
})
export class UserApproveRepaymentComponent extends BaseFormTemplateComponent implements OnInit, OnChanges {
  @Input() repayment: any;
  repaymentForm: FormGroup;
  environment = environment;
  constructor(
    private spinner: SpinnerToggleService,
    private alertService: AlertService,
    private http: HttpClient,
    private router: Router,
    fb: FormBuilder
  ) {
    super();
    this.repaymentForm = fb.group({
      amount: [undefined, [Validators.required, Validators.min(1), Validators.max(this.repayment?.reportedAmount)]]
    });

    this.errorMessages = {
      amount: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'max', message: 'Can’t be bigger than the Max amount' }
      ]
    };
    this.formInput = this.repaymentForm;
  }

  override ngOnInit() {}
  ngOnChanges() {
    this.repaymentForm.patchValue({
      amount: this.repayment.reportedAmount
    });
  }
  close() {
    $('#approveUserRepaymentWizard').fadeOut();
  }
  submit() {
    this.validateForm(this.repaymentForm);

    if (this.repaymentForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(
          `${this.environment.new_api_url}/api/deal/${this.repayment.deal._id}/period/${this.repayment.period._id}/repayment/${this.repayment._id}/status`,
          {
            status: Status.APPROVED,
            actualAmount: this.repaymentForm.value.amount
          }
        )
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            this.router.navigate(['/transactions/users-repayments']);
            $('#approveUserRepaymentWizard').fadeOut();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: any) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert('Repayment approved successfuly', 'success');
          $('#approveUserRepaymentWizard').fadeOut();
          this.router.navigate(['/transactions/users-repayments']);
          this.spinner.hideSpinner();
        });
    }
    $('#approveUserRepaymentWizard').fadeOut();
  }
}
