import { Component, OnInit } from '@angular/core';
import { BackAlertOptions } from '../../helpers';
import { ModalService } from '../../services';

@Component({
  selector: 'back-alert',
  templateUrl: './back-alert.component.html',
  styleUrls: ['./back-alert.component.scss']
})
export class BackAlertModal implements OnInit {
  modalService: ModalService;
  alertOptions: BackAlertOptions = {
    title: 'Are you sure?',
    subtitle: 'Leaving this page means that you have to do this process from the beginning',
    buttons: [
      {
        type: 'confirm',
        text: 'Confirm'
      },
      {
        type: 'cancel',
        text: 'Cancel'
      }
    ]
  };

  constructor() {}
  ngOnInit(): void {}

  handleButtons(type: string) {
    const button = this.alertOptions.buttons.find((x) => x.type === type);
    if (button?.callback) {
      button.callback();
      return;
    }
    this.modalService.close({ confirmed: type === 'confirm' });
  }

  close() {
    this.modalService.close();
  }
}
