import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { RouteInfo } from '../types';

@Component({
  selector: 'av-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      state('void, closed', style({ height: '0' })),
      state('open', style({ height: '{{len}}' + 'px' }), {
        params: {
          len: 0
        }
      }),
      transition('* <=> *', animate('400ms cubic-bezier(0.0, 0.0, 0.2, 1)'))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  collapsed: boolean;
  index: number;
  currentMenu: any;
  showText = true;

  timedOutCloser: any;
  // @HostListener('document:mouseover', ['$event'])
  // public onClick(e: any) {
  //   console.log(e);
  //   if (this.collapsed) {
  //     const dropdowners = document.querySelectorAll('.dropdowner');
  //     let shouldntClose =
  //       e?.target?.parentElement?.className?.includes?.('menu-wrapper') ||
  //       e?.target?.parentElement?.className?.includes?.('mat') ||
  //       e?.target?.className?.includes?.('mat') ||
  //       e?.target?.className?.includes?.('menu-wrapper') ||
  //       e?.target?.id?.includes?.('overlay');
  //     if (dropdowners) {
  //       for (let i = 0; i < dropdowners?.length; i++) {
  //         const data = dropdowners[i];
  //         if (e?.composedPath().includes(data)) {
  //           return;
  //         }
  //       }
  //     }
  //     if (!shouldntClose) {
  //       this.currentMenu?.closeMenu();
  //     }
  //   }
  // }
  constructor(private router: Router, @Inject('sidebarConfig') public menuItems: RouteInfo[], @Inject('env') private env: any) {}

  ngOnInit(): void {
    const dropdowns = this.menuItems.filter((data) => {
      return data.dropdown;
    });
    if (dropdowns?.length) {
      dropdowns.forEach((data) => {
        if (this.router.url.includes(data.path)) {
          data.open = true;
        }
      });
    }
  }
  hideSidebar() {
    const collapser = document.querySelector('.sidebar');

    if (!collapser.classList.value.includes('collapsed-sidebar')) {
      collapser.classList.add('collapsed-sidebar');
    }
  }
  collapse() {
    const mainPanel = document.querySelector('.main-panel');
    let timeout = 0;
    this.menuItems.forEach((data) => {
      if (data?.open) {
        data.open = false;
        timeout = 400;
      }
    });

    setTimeout(() => {
      this.collapsed = !this.collapsed;
      let addRemove = this.collapsed ? 'add' : 'remove';

      mainPanel.classList[addRemove]('collapsed-panel');
      if (this.collapsed) {
        // this.farmersDropdown = false;
        this.showText = !this.showText;
      } else {
        mainPanel.classList.remove('collapsed-panel');
        setTimeout(() => {
          this.showText = !this.showText;
        }, 501);
      }
    }, timeout);
  }
  userHasAccess(a: RouteInfo): boolean {
    let user;
    try {
      user = JSON.parse(localStorage.getItem('credentials')).user;
    } catch (err) {}
    return a?.neededPermission?.includes(user?.policyRole?.name);
  }
  removeStyle(a) {
    return true;
  }
  closeFromTop(menuTrigger: any, element?: HTMLElement) {
    element.addEventListener('mouseleave', function (e) {
      const right = this.offsetLeft + this.offsetHeight;
      if (e.pageX <= right) {
        return menuTrigger.closeMenu();
      }
    });
  }
  collapseDropdownNavigate(menuTrigger: any, menuitem: RouteInfo) {
    if (menuTrigger) {
      this.menuItems.forEach((data) => {
        if (data.dropdown && 'active' in data) {
          data.active = menuitem.path?.includes?.(data.mainPath);
          if (data.active) {
            menuTrigger.closeMenu();
          }
        }
      });
    }
  }
  dropdownNavigate(menuitem: RouteInfo) {
    if (menuitem.dropdown) {
      this.menuItems.forEach((data) => {
        if (data.dropdown && 'active' in data) {
          data.active = menuitem.path?.includes?.(data.mainPath);
          document.querySelector(`.${data.icontype}-tag`).classList.add('dropdown-active');
        }
      });
    }
  }
  openMenu(menuTrigger: any) {
    if (this.collapsed) {
      setTimeout(() => {
        return menuTrigger.openMenu();
      }, 1);
    }
  }
  closeMenu(menuTrigger: any) {
    if (menuTrigger) {
      setTimeout(() => {
        return menuTrigger.closeMenu();
      }, 1);
    }
  }
  isActive(route: string): boolean {
    const url = this.router.url.split('/');
    return url?.[1].includes(route);
  }
  mouseEnter(trigger: any) {
    if (this.collapsed) {
      setTimeout(() => {
        if (!trigger?.menuOpen) {
          this.currentMenu = trigger;
          trigger.openMenu();
        }
      }, 1);
    }
  }

  mouseLeave(trigger: any, e?: any) {
    // if (this.collapsed) {
    //   if (
    //     !e?.relatedTarget?.className.includes('mat-menu-content') &&
    //     !e?.relatedTarget?.id.includes('cdk-overlay-') &&
    //     !e?.relatedTarget?.className.includes('menu-title') &&
    //     !e?.relatedTarget?.className.includes('mat') &&
    //     !e?.parentElement?.className?.includes('mat')
    //   ) {
    //     trigger.closeMenu();
    //   }
    // }
  }
}
