import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import * as $ from 'jquery';
import { PaymentService, Agt4Agribusiness } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { Transaction } from '@avenews/base-types';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'av-agri-topup',
  templateUrl: './agri-topup.component.html',
  styleUrls: ['./agri-topup.component.scss'],
  providers: [DatePipe]
})
export class AgriTopupComponent implements OnInit {
  @Input() agri: Agt4Agribusiness;
  beneficiaryAccountId: string;
  beneficiaryId: string;
  topupForm: FormGroup;
  balanceType = [];
  topupTypes = [
    {
      viewValue: 'Regular',
      value: 'regular'
    },
    {
      viewValue: 'Pending',
      value: 'pending'
    }
  ];
  constructor(
    fb: FormBuilder,
    private datePipe: DatePipe,
    private alertService: AlertService,
    public spinner: SpinnerToggleService,
    private http: HttpClient
  ) {
    this.topupForm = fb.group({
      amount: [undefined, Validators.required],
      balanceType: [undefined, Validators.required],
      type: [undefined, Validators.required]
    });
    this.topupForm.get('type').valueChanges.subscribe((data) => {
      if (data === 'pending') {
        this.balanceType = [{ viewValue: 'Income Balance', value: PaymentService.AVENEWS_INCOME }];
        this.topupForm.get('balanceType').setValue(PaymentService.AVENEWS_INCOME);
        this.topupForm.get('balanceType').disable();
      } else {
        this.balanceType = [
          { viewValue: 'Income Balance', value: PaymentService.AVENEWS_INCOME },
          { viewValue: 'Finance Balance', value: PaymentService.AVENEWS_FINANCING }
        ];
        this.topupForm.get('balanceType').enable();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const balanceType = [];
    balanceType.push({
      viewValue: 'Income Balance',
      value: PaymentService.AVENEWS_INCOME
    });
    this.balanceType = balanceType;
  }

  ngOnInit(): void {}
  close() {
    $('#agriTopupWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  onTopup() {
    // this.validateForm(this.topupForm);

    if (this.topupForm.valid) {
      this.beneficiaryId = this.agri?._id;
      this.spinner.showSpinner();
      this.http
        .post(`${environment.new_api_url}/api/transaction/balance/top-up/agribusiness`, {
          amount: this.topupForm.get('amount').value,
          account: this.topupForm.get('balanceType').value,
          type: this.topupForm.get('type').value,
          beneficiaryId: this.agri?._id
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            $('#agriTopupWizard').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: Transaction) => {
          this.alertService.showAlert(`Topup Successful`, 'success');
          $('#agriTopupWizard').fadeOut();
          this.deleteData();
          this.spinner.hideSpinner();
        });
    }
  }
  deleteData() {
    this.topupForm.reset();
    this.topupForm.patchValue({
      amount: undefined,
      balanceType: undefined,
      type: undefined
    });
  }
}
