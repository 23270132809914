import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { LegendPosition } from '@swimlane/ngx-charts';
@Component({
  selector: 'admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  counters: any;
  environment = environment;
  tradeCardEmptyText = {
    lines: ['GRN’s? Purchase order? Trade?', 'Whatever you call it, we can capture it.', 'Yes, even the ones you issued in the past.']
  };
  transactionsCardEmptyText = {
    lines: ['no transactions yet', 'no transactions yet', 'no transactions yet']
  };
  usersCardEmptyText = {
    lines: ['there seem to be no users', 'something went wrong', 'weird']
  };
  legendPosition = LegendPosition.Below;
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true
  };
  public pieChartLabels: any[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: any = [];
  view: any = [500, 150];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    // this.route.data.subscribe(({ counters }) => {
    //   this.counters = counters;
    //   Object.keys(this.counters).forEach((key) => {
    //     if (key !== 'users' && key !== 'transactions') {
    //       const name =
    //         key
    //           .split('Transactions')
    //           .map((word) => {
    //             return word.charAt(0).toUpperCase() + word.slice(1);
    //           })
    //           .join('') + ' Transactions';

    //       this.pieChartData.push({
    //         name,
    //         value: this.counters[key] || 0
    //       });
    //     }
    //   });
    //   this.pieChartData.push({
    //     name: 'Bank Transactions',
    //     value: this.counters?.transactions - this.counters?.mobileTransactions - this.counters?.topupTransactions || 0
    //   });
    // });
  }
}
