import { UsersResolver } from './../shared/resolvers/users.resolver';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserResolver } from '../shared/resolvers/user.resolver';
import { DistributorsListComponent } from './distributors-list/distributors-list.component';
import { DistributorViewComponent } from './distributor-view/distributor-view.component';
import { DistributorsTransComponent } from './distributors-transactions/distributors-transactions.component';
import { AllTransactionListResolver } from '../pay/resolvers/all-transactions.resolver';
import { DistributorsResolver } from './resolvers/distributors.resolver';
import { BnplTransactionsResolver } from './resolvers/bnpl-transactions.resolver';
import { DistributorResolver } from './resolvers/distributor.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'distributors/all',
      component: DistributorsListComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        distributors: DistributorsResolver
      }
    },
    {
      path: 'distributor/:id',
      component: DistributorViewComponent,
      resolve: {
        distributor: DistributorResolver,
        distributors: DistributorsResolver
      },
      data: {
        title: 'Organization View',
        filter: '?filters=[{%22value%22:{orgId},%22column%22:%22numericId%22,%22option%22:%22equal%22}]'
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'distributors/transactions/all',
      runGuardsAndResolvers: 'always',
      component: DistributorsTransComponent,
      resolve: {
        bnplTransaction: BnplTransactionsResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DistributorRoutingModule {}
