import { Action, Proforma } from '@avenews/agt-sdk';
import { BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { Component, Injector, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import * as $ from 'jquery';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'frontend-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent extends ListNoSdkComponent<Proforma> implements OnInit {
  invoice: Proforma;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector) {
    super(injector, {
      noPagination: true,
      name: 'getAllAgribusiness',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/invoice`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'invoices';
  }
  ngOnInit(): void {
    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No invoices found',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [{ label: 'Change status', type: 'default', action: 'changeStatus' }],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['ID', 'PARTNER NAME', 'ACTIONS'],
      mainTitle: 'Clients List',

      columns: [
        {
          name: 'ID',
          dtoName: 'numericId',
          pipe: 'number',
          type: 'number',
          selected: true,
          sticky: true
        },
        {
          name: 'PARTNER NAME',
          dtoName: 'createdBy',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'INVOICE REF #',
          dtoName: 'reference',
          type: 'text',
          selected: true
        },
        {
          name: 'FILES',
          dtoName: 'files',
          type: 'text',
          selected: true
        },
        {
          name: 'ISSUE DATE',
          dtoName: 'issueDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'DUE DATE',
          dtoName: 'repaymentDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'AGRIBUSINESS',
          dtoName: 'supplier',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'CREATED DATE',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: false
        },
        {
          name: 'PAYMENT TERMS',
          dtoName: 'paymentTerms',
          type: 'number',
          selected: false
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',

          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'ID',
          dtoName: 'numericId',
          type: 'number'
        },
        {
          name: 'PARTNER NAME',
          dtoName: 'createdBy',
          type: 'text'
        },
        {
          name: 'INVOICE REF #',
          dtoName: 'reference',
          type: 'text'
        },
        {
          name: 'FILES',
          dtoName: 'files',
          type: 'text'
        },
        {
          name: 'ISSUE DATE',
          dtoName: 'issueDate',
          type: 'date'
        },
        {
          name: 'DUE DATE',
          dtoName: 'repaymentDate',
          type: 'date'
        },
        {
          name: 'AGRIBUSINESS',
          dtoName: 'suppliers',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'CREATED DATE',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'PAYMENT TERMS',
          dtoName: 'paymentTerms',
          type: 'number'
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
    }
  }
  changeStatus(entity: any) {
    this.invoice = entity;
    $('#changeInvoiceStatusWizard').css({ display: 'flex' });
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      // this.list = this.unsortedList.slice();

      if (sort.active === 'createdBy') {
        this.list = this.list.sort((a, b) => {
          if (sort.active === 'createdBy') {
            return sort?.direction === 'asc'
              ? this.getPartnerName(a).localeCompare(this.getPartnerName(b))
              : this.getPartnerName(b).localeCompare(this.getPartnerName(a));
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['createdBy'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'createdBy') {
          if (!this.getPartnerName(item).toLowerCase()?.includes(f.value)) {
            res = false;
          }
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
  statusGrabber(status: string) {
    switch (status) {
      case 'paid':
        return 'Paid';
      case 'settled':
        return 'Settled';
      case 'processing':
        return 'Processing';
      case 'cancelled':
        return 'Cancelled';
      default:
        return '-';
    }
  }
  getPartnerName(proforma: Proforma): string {
    if (!proforma.createdBy?.personalInformation?.firstName || !proforma.createdBy?.personalInformation?.lastName) {
      return undefined;
    }
    return proforma.createdBy?.personalInformation?.firstName + ' ' + proforma.createdBy?.personalInformation?.lastName;
  }
  getSuppliersTooltip(proforma: Proforma) {
    return proforma.suppliers
      .map((data) => {
        return data.supplier?.adminDetails?.name;
      })
      .join('\n');
  }
  getFileNames(files: any, tooltip?: boolean): string {
    if (!files?.length) return '-';
    if (!tooltip) {
      if (files?.length > 1) {
        return 'Multiple';
      } else return files?.[0]?.fileName;
    } else {
      return files?.map((file: any) => file.fileName).join('\n');
    }
  }
}
