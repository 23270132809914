<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'files-list-expired'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container *ngIf="col?.name !== 'UPLOADED BY' && col?.name !== 'STATUS'" [ngTemplateOutlet]="td"></ng-container>

    <ng-container *ngIf="col?.name === 'UPLOADED BY'">
      <td class="">
        {{ item?.uploadedBy?.personalInformation?.firstName || '-' }} {{ item?.uploadedBy?.personalInformation?.lastName || '-' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="status status-{{ item?.status }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
