<div *ngIf="!chart" class="dashboard-card-wrapper">
  <div class="header">
    <div class="flex-title">
      <av-svg [iconType]="iconType"></av-svg>
      <p>{{ title }}</p>
    </div>
  </div>
  <hr class="hr" />
  <div class="card-content">
    <div class="is-empty" *ngIf="!number">
      <ng-container *ngFor="let line of emptyState?.lines">
        {{ line }}
        <br />
      </ng-container>
    </div>
    <div class="info-card" *ngIf="number">
      <p class="counter">{{ number | number: '1.0-2' }}</p>
      <p class="added">{{ subtitle + ' added' }}</p>
    </div>
  </div>
</div>
<div *ngIf="chart" class="dashboard-card-wrapper">
  <div class="header">
    <div class="flex-title">
      <av-svg [iconType]="iconType"></av-svg>
      <p>{{ title }}</p>
    </div>
  </div>
  <hr class="hr" />
  <div class="card-content">
    <ng-content></ng-content>
  </div>
</div>
