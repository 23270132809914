import { catchError, EMPTY, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SdkService } from '@avenews/shared-lib';

@Injectable()
export class AccountsByBeneficiaryResolver implements Resolve<any> {
  constructor(private sdkService: SdkService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return from(this.sdkService.getAccountsByBeneficiary(id)).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
