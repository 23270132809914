import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { Agt4Order } from '@avenews/agt-sdk';
import { Component, Input, OnInit } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-main-data',
  templateUrl: './main-data.component.html',
  styleUrls: ['./main-data.component.scss']
})
export class MainDataComponent implements OnInit {
  @Input() order: Agt4Order;
  isSaved = false;
  environment = environment;
  prods: FormGroup;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    this.prods = this.fb.group({
      selling: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.order.products.forEach((prod) => {
      (this.prods.get('selling') as FormArray).push(this.fb.control(prod.resellingUnitPrice));
    });
  }

  onSave() {
    if (this.prods.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${this.environment.new_api_url}/api/order/${this.order?._id}/products`, {
          products: this.prods.value.selling.map((selling, index) => {
            return {
              orderProductId: this.order.products[index]._id,
              resellingUnitPrice: selling || 0
            };
          })
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.isSaved = false;
          this.alertService.showAlert(`Data saved successfully`, 'success');
          this.router.navigateByUrl(this.router.url);
          this.spinner.hideSpinner();
        });
    }
  }
  onEdit() {
    this.isSaved = true;
  }
}
