<ng-template
  [cdkConnectedOverlayPanelClass]="['sheet-pane', customClass]"
  cdkConnectedOverlayBackdropClass="sheet-backdrop"
  cdkConnectedOverlayHasBackdrop
  (attach)="open()"
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
>
  <div (cdkDragEnded)="dragEnd()" (cdkDragMoved)="drag($event)" cdkDragLockAxis="y" cdkDrag #sheetModal id="sheet-modal">
    <div class="sheet-container">
      <div cdkDragHandle class="sheet-header">
        <div cdkDragHandle draggable="true" class="sheet-handle"></div>
        <div (click)="animateClose()" class="sheet-close">Close</div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
