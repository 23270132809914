import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class LoanResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot) {
    // const id = route.params['id'];
    // return (this.http.get(`${environment.new_api_url}/api/loan-application/admin/${id}`) as Observable<any>).pipe(
    //   catchError((err) => {
    //     console.error(err);
    //     // this.router.navigate(['/login']);
    //     return EMPTY.pipe();
    //   })
    // );
    // this is really ugly but it is what it is..
    return of([]);
  }
}
