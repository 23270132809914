import { User } from '@avenews/agt-sdk';
import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { BaseFormTemplateComponent } from 'libs/shared/src/lib/components/base-form-template/base-form-template.component';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '@avenews/alerts';
import { SpinnerToggleService, countries } from '@avenews/shared-lib';
import * as $ from 'jquery';
import * as libphonenumber from 'google-libphonenumber';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent extends BaseFormTemplateComponent implements OnInit {
  beneficiaryAccountId: string;
  beneficiaryId: string;
  userForm: FormGroup;
  countries = countries;
  roles = [
    {
      viewValue: 'Agribusiness',
      value: 'agribusiness'
    },
    {
      viewValue: 'Supplier partner',
      value: 'partner'
    },
    {
      viewValue: 'Buyer partner',
      value: 'buyer-partner'
    }
  ];

  constructor(
    fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private alertService: AlertService,
    public spinner: SpinnerToggleService
  ) {
    super();
    this.userForm = fb.group({
      role: [undefined, [Validators.required]],
      username: [undefined, [Validators.required, Validators.email]],
      password: [undefined, [Validators.required, Validators.minLength(8)]],
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      countryPhoneCode: ['+254', Validators.required],
      phoneNumber: [undefined, Validators.required],
      country: ['Kenya', Validators.required],
      companyName: [undefined],
      createWallet: [false],
      creditRequested: [undefined]
    });
    this.formInput = this.userForm;
    this.errorMessages = {
      username: [
        { type: 'required', message: 'Username is required' },
        { type: 'email', message: 'Username must be a valid email address' }
      ],
      password: [
        { type: 'required', message: 'Password is required' },
        { type: 'minlength', message: 'Password must be at least 8 characters long' }
      ],
      phoneNumber: [
        {
          type: 'required',
          message: 'Phone number is required'
        },
        {
          type: 'wrongNumber',
          message: 'Phone number is not valid'
        }
      ]
    };
  }
  ngOnChanges(changes: SimpleChanges): void {}
  override ngOnInit(): void {}
  close() {
    $('#addUserWizard').fadeOut('slow', () => {
      this.deleteData();
    });
  }
  createUser() {
    this.validateForm(this.userForm);
    const afterSuccess = () => {
      this.router.navigateByUrl(this.router.url);
      this.close();
      this.deleteData();
    };
    const catcher = (err) => {
      this.spinner.hideSpinner();
      this.alertService.showAlert(err.error.message, 'danger');
      this.close();
      return EMPTY.pipe();
    };
    if (this.userForm.valid) {
      this.spinner.showSpinner();

      const user = { ...this.userForm.value, phoneNumber: `+${this.phoneCode}${this.userForm.value.phoneNumber}` };
      if (user.role === 'buyer-partner') {
        user['description'] = 'buyer-partner';
        user.role = 'partner';
      }
      delete user.createWallet;
      delete user.countryPhoneCode;
      delete user.creditRequested;
      this.http
        .post(`${environment.api_url}/api/user/register/mobile`, {
          ...user
        })
        .pipe(catchError(catcher))
        .subscribe((data: User) => {
          this.alertService.showAlert('User created successfully', 'success');
          if (this.userForm.get('createWallet').value) {
            const creditRequested = this.userForm.get('creditRequested').value;
            const dto = {
              beneficiaryId: data._id
            };
            if (creditRequested) {
              dto['creditRequested'] = creditRequested;
            }
            this.http
              .post(`${environment.new_api_url}/api/account/avenews`, {
                ...dto
              })
              .pipe(catchError(catcher))
              .subscribe(() => {
                this.alertService.showAlert(
                  `Wallet for ${data.personalInformation.firstName} ${data.personalInformation?.lastName} created successfully`,
                  'success'
                );
                afterSuccess();
              });
          } else {
            afterSuccess();
          }
        });
    }
  }
  deleteData() {
    this.userForm.reset();
    this.userForm.markAsUntouched();
    this.userForm.get('country').setValue('Kenya');
    this.userForm.get('countryPhoneCode').setValue('+254');
  }
}
