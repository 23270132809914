<div (click)="onHandleDelete(false)">
  <div id="myModal" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <img src="../../../../assets/images/svgs/Group 2369.svg" />
        <h1>Are you sure you want to delete {{ fileName }}</h1>
        <p>You cannot undo this action</p>
      </div>
      <div class="modal-body">
        <div class="btn-cancel" (click)="onHandleDelete(false)">Close</div>
        <div class="btn-delete" (click)="onHandleDelete(true)">Delete</div>
      </div>
    </div>
  </div>
</div>
