<div class="o-hidden {{ listConfig?.customClass }}">
  <div *ngIf="listStatus === 'empty-state' && maintitleEmptyState" class="title">
    <p class="main-title empty">{{ listConfig?.mainTitle }}</p>
  </div>
  <div *ngIf="listStatus === 'empty-state'" class="empty-div">
    <ng-container *ngIf="emptyStateTemplateRef" [ngTemplateOutlet]="emptyStateTemplateRef"> </ng-container>
    <div *ngIf="!emptyStateTemplateRef" class="center-div">
      <img [src]="listConfig?.emptyState?.img" />
      <p class="empty-title">{{ listConfig?.emptyState?.title }}</p>
      <p class="empty-sub-title">{{ listConfig?.emptyState?.subTitle }}</p>
      <div [hidden]="!listConfig?.emptyState?.button" class="bulk-dropdowns">
        <button
          *ngIf="listConfig?.addButton?.isDropdown"
          mat-icon-button
          [matMenuTriggerFor]="actionsMenu"
          type="button"
          class="bttn-primary bttn"
        >
          {{ listConfig?.addButton?.label }}
        </button>
        <button
          (click)="addButton.emit(listConfig?.emptyState?.button?.action)"
          *ngIf="!listConfig?.addButton?.isDropdown"
          mat-icon-button
          type="button"
          class="bttn-primary bttn"
        >
          {{ listConfig?.emptyState?.button?.label }}
        </button>

        <mat-menu #actionsMenu="matMenu">
          <button
            (click)="addButton.emit(action?.emittedValue)"
            class="menu-item"
            *ngFor="let action of listConfig?.addButton?.dropdown"
            mat-menu-item
          >
            <span class="action-{{ action?.type }}">{{ action?.label }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div *ngIf="listStatus !== 'empty-state'">
    <div class="header-list-wrapper">
      <div class="title">
        <p class="main-title">{{ listConfig?.mainTitle }}</p>
      </div>
      <div *ngIf="listConfig?.addButton?.label" class="add-btn">
        <div class="buttons-wrapper">
          <div *ngIf="listConfig?.bulkAddButton" class="bulk-dropdowns">
            <button
              *ngIf="listConfig?.bulkAddButton?.isDropdown"
              mat-icon-button
              [matMenuTriggerFor]="actionsMenu"
              type="button"
              class="btn-actions bttn"
            >
              {{ listConfig?.bulkAddButton?.label | titlecase }}
            </button>
            <button (click)="bulkAddButton.emit()" mat-icon-button type="button" class="btn-actions bttn">
              {{ listConfig?.bulkAddButton?.label | titlecase }}
            </button>

            <mat-menu #actionsMenu="matMenu">
              <button
                (click)="bulkAddButton.emit(action?.emittedValue)"
                class="menu-item"
                *ngFor="let action of listConfig?.bulkAddButton?.dropdown"
                mat-menu-item
              >
                <span class="action-{{ action?.type }}">{{ action?.label }}</span>
              </button>
            </mat-menu>
          </div>
          <div class="bulk-dropdowns">
            <button
              *ngIf="listConfig?.addButton?.isDropdown"
              mat-icon-button
              [matMenuTriggerFor]="actionsMenu"
              type="button"
              class="bttn-primary bttn"
            >
              {{ listConfig?.addButton?.label }}
            </button>
            <button
              (click)="addButton.emit(listConfig?.addButton?.emittedValue)"
              *ngIf="!listConfig?.addButton?.isDropdown"
              mat-icon-button
              type="button"
              class="bttn-primary bttn"
            >
              {{ listConfig?.addButton?.label }}
            </button>

            <mat-menu #actionsMenu="matMenu">
              <button
                (click)="addButton.emit(action?.emittedValue)"
                class="menu-item"
                *ngFor="let action of listConfig?.addButton?.dropdown"
                mat-menu-item
              >
                <span class="action-{{ action?.type }}">{{ action?.label }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="action-wrapper">
      <div class="filter-wrapper">
        <app-filters-template  #filtersTemplate (easyFilterAction)="easyFilterAction.emit($event)" (filter)="filterValues($event)" [easyFilters]="easyFilters" [listConfig]="listConfig"></app-filters-template>
      </div>

      <div>
        <div mat-icon-button [matMenuTriggerFor]="customiseMenu" class="customise-wrapper">
          <img class="svg-filter" src="assets/images/empty-states/filter.svg" alt="filter" />
          <p class="cus-view">Customize view</p>
        </div>
        <mat-menu class="customize" #customiseMenu="matMenu">
          <div class="custom-dropdown" (click)="$event.stopPropagation()">
            <div class="custom-header-wrapper">
              <p>Columns</p>
              <div class="search-wrapper">
                <input [(ngModel)]="searchTerm" type="search" class="search" placeholder="search..." #input />
                <i-feather class="search-icon" name="search"></i-feather>
              </div>
            </div>
            <div class="custom-col-wrapper">
              <div
                [formGroup]="customiseForm"
                class="columns menu-item"
                *ngFor="let col of listConfig?.columns | filter: searchTerm:true"
                mat-menu-item
              >
                <input
                  type="checkbox"
                  class="form_radio"
                  id="check-column{{ fixString(col?.name) }}"
                  formControlName="{{ fixString(col?.name) }}"
                />
                <label #radio for="check-column{{ fixString(col?.name) }}" class="form_radio_label">
                  <span class="form_checkbox_btn"></span
                ></label>
                <span (click)="radio.click()">{{ col?.name | titlecase }}</span>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <ng-content select="[header]"></ng-content>

    <div class="template-list-wrapper">
      <table matSort (matSortChange)="sortEmit($event)">
        <thead id="t-head">
          <tr>
            <ng-container *ngFor="let col of listConfig?.columns | colFilter: colFilter; let i = index">
              <th
                *ngIf="col?.dtoName && !col?.noSort"
                [ngClass]="i === listConfig?.columns?.length! - 1 ? 'last-action last-th' : ''"
                [mat-sort-header]="col?.sortName || col?.dtoName"
              >
                {{ col?.name }}
              </th>
              <th *ngIf="col?.dtoName && col?.noSort" [ngClass]="i === listConfig?.columns?.length! - 1 ? 'last-action last-th' : ''">
                {{ col?.name }}
              </th>
              <th *ngIf="!col?.dtoName" [ngClass]="i === listConfig?.columns?.length! - 1 ? 'last-action last-th' : ''">
                {{ col?.name }}
              </th>
            </ng-container>
          </tr>
        </thead>

        <tbody *ngIf="listStatus !== 'empty-search'">
          <tr
            [ngClass]="item['customClass']"
            (auxclick)="listAction({ action: 'view-tab' }, item)"
            (click)="listAction({ action: 'view' }, item)"
            *ngFor="
              let item of list
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: currentPage,
                      totalItems: totalCount,
                      id: listId
                    }
            "
          >
            <ng-container *ngFor="let col of listConfig?.columns | colFilter: colFilter">
              <ng-container
                [ngTemplateOutlet]="colTemplateRef || tdTemplate"
                [ngTemplateOutletContext]="{
                  col: col,
                  template: tdTemplate,
                  item: item
                }"
              ></ng-container>
              <ng-template #tdTemplate>
                <td
                  #tooltip="matTooltip"
                  matTooltip="{{ item?.[col?.dtoName+'tooltip'] || (item | column: col)+(col?.sideText||'') }}"
        matTooltipClass="base-tooltip"
        *ngIf="col?.dtoName"
                >
                  {{ item | column: col }}<ng-container *ngIf="item[col?.dtoName]">{{ col?.sideText || '' }}</ng-container>
                </td>
              </ng-template>

              <ng-container *ngIf="!col?.dtoName">
                <td class="last-action last-td">
                  <button
                    (click)="$event.stopPropagation()"
                    mat-icon-button
                    [matMenuTriggerFor]="actionMenu"
                    aria-label="Example icon-button with a menu"
                    class="dropdown-action"
                  >
                    <i-feather name="more-vertical"></i-feather>
                  </button>

                  <mat-menu #actionMenu="matMenu">
                    <button
                      (click)="listAction(action, item)"
                      class="menu-item"
                      *ngFor="let action of item?.['actions'] || listConfig?.actions"
                      mat-menu-item
                    >
                      <span class="action-{{ action?.type }}">{{ action?.label }}</span>
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
      </table>

      <div *ngIf="listStatus === 'empty-search'" class="empty-search">
        <img src="assets/images/empty-states/empty-search.svg" alt="empty-search" />
        <p class="no-result">No results found</p>
        <p>Try different key words or remove search filters</p>
      </div>
    </div>

    <div *ngIf="totalCount" class="pagination-wrapper">
      <pagination-controls id="{{ listId }}" (pageChange)="changedPage($event)"></pagination-controls>
      <div class="choose-records">
        <p>Results per page</p>
        <div [formGroup]="recordsPerPageForm">
          <select (change)="changeRecordsPerPage()" formControlName="recordsPerPage" class="form-control">
            <option *ngFor="let record of recordsOptions" [value]="record">{{ record }}</option>
          </select>
        </div>
        <p>out of {{ totalCount }}</p>
      </div>
    </div>
  </div>
</div>
<app-export-modal
  [totalCount]="totalCount"
  [cols]="listConfig?.columns"
  [exportId]="exportId"
  (exportEmit)="export($event)"
></app-export-modal>
