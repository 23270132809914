import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AGTError, ErrorCodes, OAuthContext, SocialNetworkName, User, Credentials } from '@avenews/agt-sdk';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { LoginConfig } from '../../auth-config';
import { PhoneNumberValidator, NotificationService, SdkService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import * as $ from 'jquery';
import Smartlook from 'smartlook-client';

@Component({
  selector: 'av-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  network: string;
  showPass = false;
  userValidated = false;
  showUsername = true;
  error: string;
  phoneUtil: any;
  partialPhoneNumber: string;
  credentials: Credentials;
  blockedEmail = '';
  regionCode: string;
  phoneCode: string;
  validationError = false;
  user: any;
  isLoading = false;
  savedUsername: string;
  formInput: FormGroup;
  showError = true;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private sdkService: SdkService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private router: Router,
    @Inject('loginConfig') public loginConfig: LoginConfig
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      phoneNumber: [undefined]
    });
    this.formInput = this.loginForm;
    this.loginForm.valueChanges.subscribe((data) => {
      Object.keys(this.loginForm.controls).forEach((data) => {
        this.loginForm.get(data)?.markAsUntouched();
        this.loginForm.get(data).setErrors(null);
        this.showError = null;
      });
    });
  }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.params!['token']) {
      this.userValidated = true;
      const token = this.activeRoute.snapshot.params['token'];
      this.sdkService.activateAccount(token).then((user) => {
        // this.intercomLogin(user, false);
      });
    }
  }

  showPassword() {
    if (this.showPass === false) {
      this.showPass = true;
    } else {
      this.showPass = false;
    }
  }
  showPhoneOrEmail() {
    this.error = '';
    this.showUsername = !this.showUsername;
    if (!this.showUsername) {
      this.loginForm.get('phoneNumber')?.setValidators([Validators.required, PhoneNumberValidator(this.regionCode)]);
      this.loginForm.get('username')?.clearValidators();
      this.loginForm.updateValueAndValidity();
    } else {
      this.loginForm.get('username')?.setValidators([Validators.required, Validators.email]);
      this.loginForm.get('phoneNumber')?.clearValidators();
      this.loginForm.updateValueAndValidity();
    }
  }
  changeRegion(code: string) {
    this.regionCode = code;
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);
  }
  signIn() {
    this.onSubmit(this.loginForm);
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.validationError = false;
      this.error = '';
      let creds;
      if (this.showUsername) {
        creds = {
          username: this.loginForm.get('username')?.value,
          password: this.loginForm.get('password')?.value
        };
      } else {
        creds = {
          username: '+' + this.phoneCode + this.loginForm.get('phoneNumber')?.value,
          password: this.loginForm.get('password')?.value
        };
      }
      this.authService
        .login(creds)
        .then(async (credentials) => {
          const loginSuccess = () => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
            console.debug(`${credentials.user.username} successfully logged in`);

            // this.intercomLogin(credentials.user, true);

            this.activeRoute.queryParams.subscribe((params) => this.router.navigate([params?.['redirect'] || '/'], { replaceUrl: true }));
          };
          if (this.loginConfig?.isAdmin) {
            if (!credentials.user.policyRole?.name?.includes('avenews')) {
              this.validationError = true;
              this.loginForm.get('username')?.setErrors({ notAdmin: true });
              this.isLoading = false;

              return;
            } else {
              if (this.loginConfig?.environment?.production) {
                const smartlookUserDetails = {
                  name: credentials.user?.personalInformation.firstName + ' ' + credentials.user?.personalInformation.lastName,
                  email: credentials.user?.username,
                  role: credentials.user?.policyRole?.name
                };
                Smartlook.identify(credentials.user._id, {
                  ...smartlookUserDetails
                });
              }
              try {
                const address = await this.notificationService.getToken();
                this.http
                  .post(
                    `${this.loginConfig.environment.new_api_url}/api/notification/android/binding`,
                    { address },
                    {
                      headers: {
                        Authorization: `Bearer ${credentials.token}`
                      }
                    }
                  )
                  .subscribe(() => {
                    console.debug('binding success', { address });
                  });
              } catch {
                console.error('binding error');
              }

              this.credentials = credentials;
              this.loginForm.markAsPristine();
              this.isLoading = false;
              const user = credentials.user;
              const date = new Date();
              date.setMonth(date.getMonth() - 6);
              const lastPassDate = user?.['recentPasswordChangeDate'];
              if (!lastPassDate || new Date(lastPassDate) < date) {
                localStorage.clear();
                $('#reset-modal').css({ display: 'flex' });
              } else {
                this.loginForm.markAsPristine();
                this.isLoading = false;
                console.debug(`${credentials.user.username} successfully logged in`);
                if (this.credentials?.user?.policyRole?.name?.includes('agent')) {
                  this.router.navigate(['/trade-desk']);
                } else {
                  // this.router.navigate(['/']);
                  this.activeRoute.queryParams.subscribe((params) =>
                    this.router.navigate([params?.['redirect'] || '/'], { replaceUrl: true })
                  );
                }
              }
            }
          } else {
            loginSuccess();
          }
        })
        .catch((error: AGTError) => {
          this.showError = true;
          this.isLoading = false;
          if (error.errorCode === ErrorCodes.INVALID_CREDENTIALS) {
            this.validationError = true;
            this.loginForm.get('username')?.setErrors({ wrongPass: true });
            this.loginForm.get('password')?.setErrors({ wrongPass: true });
            this.savedUsername = this.loginForm.get('username')?.value;
          } else if (error.errorCode === ErrorCodes.BLOCKED_USER) {
            this.loginForm.get('username')?.setErrors({ blocked: true });
            this.loginForm.get('password')?.setErrors({ blocked: true });
            this.blockedEmail = this.loginForm.value.username;
            // $('#forgotModalWizard').css({ display: 'flex' });
          }
          console.debug(`Login error: ${error}`);
          this.error = error.message;
        });
    }
  }
  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }

  // Method to sign in with social networks.
  signInWithGoogle(network: SocialNetworkName): void {
    this.network = network;
    let platform = '';
    this.isLoading = true;
    if (network === 'facebook') {
      platform = FacebookLoginProvider.PROVIDER_ID;
    }
    if (network === 'google') {
      platform = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(platform).then(
      (response) => {
        const context: OAuthContext = {
          email: response.email,
          personal_network_id: response.id,
          access_token: response.authToken
        };
        this.error = '';
        this.authService
          .oAuthLogin(context, network)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            (credentials) => {
              console.debug(`${credentials.user.username} successfully logged in`);
              // this.intercomLogin(credentials.user, true);
              this.activeRoute.queryParams.subscribe((params) =>
                this.router.navigate([params?.['redirect'] || '/'], {
                  replaceUrl: true
                })
              );
              this.user = response;
            },
            (error) => {
              if (error.errorCode === 'SOCIAL_ACCOUNT_NOT_FOUND') {
                localStorage.setItem('networkToRegister', network);
                this.router.navigate(['registration'], {
                  state: { network: network }
                });
              } else {
                this.alertService.showAlert('Please, sign up before signing in', 'danger');
              }
            }
          );
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  resetPassword() {
    $('#forgotModalWizard').css({ display: 'none' });
    $('#resetModalWizard').css({ display: 'flex' });
  }
}
