import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'av-rating',
  templateUrl: './avenews-rating.component.html',
  styleUrls: ['./avenews-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvenewsRatingComponent),
      multi: true
    }
  ]
})
export class AvenewsRatingComponent implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  stars: number[] = [1, 2, 3, 4, 5];
  rating: number = 0;
  hoverRating: number = 0;
  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit() {
    if (this.required) {
      this.onChange(1);
    }
  }

  setRating(rating: number) {
    if (!this.disabled) {
      this.rating = rating;
      this.onChange(this.rating);
      this.onTouched();
    }
  }

  setHoverRating(rating: number) {
    if (!this.disabled) {
      this.hoverRating = rating;
    }
  }

  writeValue(value: number) {
    this.rating = value || 0;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
