import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plusPhone'
})
export class PlusPhonePipe implements PipeTransform {
  transform(phoneNumber: string, countryPhoneCode: string): string {
    let phoneRes = '';
    if (phoneNumber) {
      if (countryPhoneCode) {
        if (phoneNumber.includes('+')) {
          phoneRes += phoneNumber;
        } else if (countryPhoneCode.toString().includes('+')) {
          phoneRes += countryPhoneCode + phoneNumber;
        } else {
          phoneRes += '+' + countryPhoneCode + phoneNumber;
        }
      } else {
        phoneRes += phoneNumber;
      }
    }
    return phoneRes || '-';
  }
}
