import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'notificationDate' })
export class NotificationDatePipe implements PipeTransform {
  transform(date: Date): any {
    // if date is today return the string Today
    // if date is yesterday return the string Yesterday
    // else return the date in the format dd/mm/yyyy
    date = new Date(date);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      return 'Today';
    }
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }
    //if 7days range return day differnece as number
    if (date.getTime() > yesterday.getTime() - 7 * 24 * 60 * 60 * 1000) {
      return moment(date).fromNow();
    }
    return moment(date).format('DD/MM/YYYY');
  }
}
