<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Organizations'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-orders-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (addButton)="primaryActions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'OWNER' && col?.name !== 'DISCOUNT (%)' && col?.name !== 'STATUS'"
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'OWNER'">
      <td>
        {{ item?.personalInformation | beneficiaryName }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'DISCOUNT (%)'">
      <td>{{ item?.agreement?.discount || 0 }}%</td></ng-container
    >

    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="status status-{{ item?.distributor?.status | statuses: statuses:true }}">
          {{ item?.distributor?.status | statuses: statuses }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<change-distributor-status [distributor]="distributor"></change-distributor-status>
