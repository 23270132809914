import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, EMPTY, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '@avenews/agt-sdk';
import { SdkService } from '../services/sdk.service';

@Injectable()
export class CurrentUserResolver implements Resolve<User> {
  constructor(private sdkService: SdkService, private router: Router) {}
  // get data from resolver
  resolve(route: import('@angular/router').ActivatedRouteSnapshot) {
    const routeData = route.data;
    return from(this.sdkService.getCurrentUser()).pipe(
      catchError((err) => {
        console.error(err);
        const loginUrl = routeData['loginUrl'] || '/login';
        this.router.navigate([`/${loginUrl}`]);
        return EMPTY.pipe();
      })
    );
  }
}
