import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoicesResolver } from './resolvers/invoices.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'invoice',
      component: InvoiceListComponent,
      runGuardsAndResolvers: 'always',
      // I will uncomment when there a real data
      resolve: { invoices: InvoicesResolver },
      data: { pageSize: 10 }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceRoutingModule {}
