<div class="modal-box" id="resetModalWizard">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-content">
        <div class="container-1 tab-content p-3">
          <div class="center"><img src="assets/images/empty-states/account-verification.svg" /></div>
  
          <div class="dialog">
            <div class="title top">
              We've sent an email to
              <span class="title-email"> {{ userEmail }}</span>
              <!-- {{ user.personalInformation.email }} -->
              <!-- <b class="email">email@gmail.com </b> -->
              with a link to reset your password
            </div>
            <div class="align-items-left">
              <p class="next">What's next?</p>
              <p>1. Open your email and check your inbox</p>
            </div>
            <p>
              2. <b class="search"> Search for an email from Avenews</b> with the title "Reset Password request". this email can also be in
              "Spam" or other folders.
            </p>
            <p>3. click the link in the center of the email. and follow the instructions from there</p>
          </div>
          <div class="modal-delete-footer">
            <button class="bttn-delete mt-5" (click)="close()">Ok, got it!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  