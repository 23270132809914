<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="font-bold text-slate-800">KYC Checklist </mat-panel-title>
      <!-- <mat-panel-description> KYC Checklist</mat-panel-description> -->
    </mat-expansion-panel-header>
    <ng-container *ngIf="!loading; else loader">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node class="select-none cursor-pointer" *matTreeNodeDef="let node" matTreeNodePadding>
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <mat-checkbox
            (click)="$event.preventDefault(); todoLeafItemSelectionToggle(node)"
            #check
            id="{{ node?.name }}"
            class="checklist-leaf-node pr-3"
            [checked]="node?.completed"
            (change)="todoLeafItemSelectionToggle(node)"
            >{{ node.item }}</mat-checkbox
          >
          <label class="cursor-pointer" (click)="todoLeafItemSelectionToggle(node)" for="{{ node?.name }}">
            {{ node.name }}
          </label>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-checkbox
            (click)="$event.preventDefault(); todoItemSelectionToggle(node)"
            #check
            class="pr-2"
            [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)"
            >{{ node.item }}</mat-checkbox
          >
          <label class="cursor-pointer" (click)="todoItemSelectionToggle(node)" for="{{ node?.name }}">
            {{ node.name }}
          </label>
        </mat-tree-node>
      </mat-tree>
      <div class="flex justify-end mt-3">
        <button class="!text-white bttn btn-primary" (click)="checkAll()">Submit</button>
      </div>
    </ng-container>
    <ng-template #loader>
      <div class="w-full flex justify-center">
        <img width="150px" height="100px" src="../../../../assets/loading.gif" alt="" />
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
