import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entityType'
})
export class EntityTypePipe implements PipeTransform {
  transform(grn: any) {
    try {
      if ((grn.supplier as any).businessDetails) {
        return 'Agribusiness';
      } else {
        return 'Farmer';
      }
    } catch {
      return '';
    }
  }
}
