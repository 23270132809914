<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Clients'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-users-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (addButton)="primaryActions($event)"
  (sendFilters)="filter($event)"
  (bulkActions)="actionsBulk($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'NAME' &&
        col?.name !== 'FINANCING ACCOUNT ID' &&
        col?.name !== 'ROLE' &&
        col?.name !== 'CASHOUT' &&
        col?.name !== 'ACTIVE' &&
        col?.name !== 'LOGGED IN NOW'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'LOGGED IN NOW'">
      <td>
        {{ item?.loggedIn ? 'Yes' : 'No' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'CASHOUT'">
      <td>
        {{ item?.accounts[0]?.availableCashoutRate ?? 1 ? 'Not Limited' : 'Limited' }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'ROLE'">
      <td>
        {{ getRole(item) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'ACTIVE'">
      <td *ngIf="item?.accounts?.[0]?.service==='avenews-financing'" class="status-complete">
        <p class="{{ (!item?.accounts[0]?.validated ? '' : item?.accounts[0]?.validated ? 'approved' : 'gray') | status }}">
          {{ (item?.accounts[0]?.validated ? 'Active' : 'Inactive') || '-' | titlecase }}
        </p>
      </td>
      <td *ngIf="item?.accounts?.[0]?.service!=='avenews-financing'">-</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'FINANCING ACCOUNT ID'">
      <td *ngIf="item?.accounts?.[0]?.service==='avenews-financing'" class="status-complete">
        <p>
          {{ item?.accounts[0]?._id }}
        </p>
      </td>
      <td *ngIf="item?.accounts?.[0]?.service!=='avenews-financing'">-</td>
    </ng-container>
  </ng-template>
</agt-nosdk-list>
<app-topup-component [accounts]="accounts" [user]="currentTopupUser"></app-topup-component>
<app-topdown-component [accounts]="accounts" [user]="currentTopupUser"></app-topdown-component>
<frontend-activate [accounts]="accounts" [user]="currentTopupUser"></frontend-activate>
<app-create-user></app-create-user>
<app-create-agent [roleId]="roleId"></app-create-agent>
<frontend-cashout-limitation [accounts]="accounts" [user]="user"></frontend-cashout-limitation>
<frontend-cashback-popup [user]="currentTopupUser"></frontend-cashback-popup>
