import { OrgsResolver } from './../shared/resolvers/orgs.resolver';
import { UsersResolver } from './../shared/resolvers/users.resolver';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UserResolver } from '../shared/resolvers/user.resolver';
import { DealsResolver } from './user-view/resolvers/deals.resolver';
import { PeriodsResolver } from './user-view/resolvers/periods.resolver';
import { RepaymentsResolver } from './user-view/resolvers/repayments.resolver';
import { OrgByIdResolver } from '../shared/resolvers/org-by-id.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'organizations',
      component: OrganizationsListComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        organizations: OrgsResolver
        // users: UsersResolver,
      },
      data: {
        isExistOrg: true,
        title: 'Organizations'
      }
    },
    {
      path: 'organization/:id/:orgId',
      component: UserViewComponent,
      resolve: {
        user: UserResolver,
        org:OrgByIdResolver,
        // org: OrgsResolver,
        users: UsersResolver,
        deals: DealsResolver,
        periods: PeriodsResolver,
        repayments: RepaymentsResolver
      },
      runGuardsAndResolvers: 'always',
      data: {
        title: 'Organization View',
        // filter: '?filters=[{%22value%22:{orgId},%22column%22:%22numericId%22,%22option%22:%22equal%22}]'
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationsRoutingModule {}
