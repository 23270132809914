<div class="modal-box" id="reasonModal">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>{{ title }}</p>
      <img src="../../../../assets/images/svgs/close.svg" (click)="close()" />
    </div>
    <div class="modal-box-content">
      <!-- <span>Reason *</span> -->
      <!-- <div class="reason-body"> -->
      <!-- <div class="input-container">
          <textarea [formControl]="reasonControl" rows="6" cols="45"></textarea>
        </div> -->
      <app-base-form-template
        labelName="Reason"
        [group]="reasonForm"
        inputType="select"
        inputName="select"
        bindLabel="viewValue"
        bindValue="value"
        [options]="reasonOptions"
        controlName="definedReason"
        placeholderName="Type reason here..."
        [size]="12"
      ></app-base-form-template>
      <app-base-form-template
        labelName="Note"
        [group]="reasonForm"
        inputType="textarea"
        inputName="default"
        controlName="reason"
        placeholderName="Type reason here..."
      ></app-base-form-template>
      <!-- </div> -->
    </div>
    <div class="modal-footer">
      <button class="bttn-primary" (click)="onHandleDecline()">{{ buttonText || 'Submit' }}</button>
    </div>
  </div>
</div>
