import { Router } from '@angular/router';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { EMPTY } from 'rxjs';
import { AlertService } from '@avenews/alerts';
enum TraderType {
  LPO = 'lpo',
  TRADER = 'trader'
}

enum DistributionNetwork {
  NARROW = 'narrow',
  WIDE = 'wide'
}

enum ClientBase {
  ESTABLISHED = 'established',
  SPECULATIVE = 'speculative'
}

interface CalculateDecisionDTO {
  type: string;
  monthlyLiquidity?: number;
  tdScore?: number;
  mlScore?: number;
  valueAdd?: string;
  averageTransactions?: number;
  completedTransactions?: number;
  assetOwnership?: string;
  traderType?: TraderType;
  clientBase?: ClientBase;
  distributionNetwork?: DistributionNetwork;
  [customField: string]: unknown;
}

@Component({
  selector: 'td-decision-tree',
  templateUrl: './td-decision-tree.component.html',
  styleUrls: ['./td-decision-tree.component.scss']
})
export class TdDecisionTreeComponent implements OnInit, OnChanges {
  @Input() loan: any;
  @Input() user: any;
  @Input() users: any;
  @Input() mlScoreData: any;
  @Input() mlScore: number;
  @Input() tdScore: number;
  types = [
    //keys of typer
    {
      viewValue: 'Processor',
      value: 'Processor'
    },
    {
      viewValue: 'Trader',
      value: 'Trader'
    },
    {
      viewValue: 'Fresh Produce',
      value: 'Fresh Produce'
    },
    {
      viewValue: 'Inputs',
      value: 'Inputs'
    },
    {
      viewValue: 'Animal Trader',
      value: 'Animal Trader'
    },
    {
      viewValue: 'Livestock Processor',
      value: 'Livestock Processor'
    },
    {
      viewValue: 'Product Trader',
      value: 'Product Trader'
    },
    {
      viewValue: 'Crop Exporter',
      value: 'Crop Exporter'
    },
    {
      viewValue: 'Animal Byproduct Exporter',
      value: 'Animal Byproduct Exporter'
    }
  ];
  loading = true;
  environment = environment;
  form: FormGroup;
  chosenType = [];
  decisionTree = [];
  mapper = [
    {
      label: 'Type',
      value: 'type'
    },
    { label: 'Maximum finance amount', value: 'outcome' },
    {
      label: 'Monthly Liquidity',
      value: 'monthlyLiquidity'
    },
    {
      label: 'ML Score',
      value: 'mlScore'
    },
    {
      label: 'TD Score',
      value: 'tdScore'
    },
    {
      label: 'Value Add',
      value: 'valueAdd'
    },
    {
      label: 'Distribution Network',
      value: 'distributionNetwork'
    },
    {
      label: 'Value of completed transactions in last 6 months (maximum value)',
      value: 'averageTransactions'
    },
    {
      label: 'Number of completed transactions in last 6 months (based on bank statements)',
      value: 'completedTransactions'
    },
    {
      label: 'Does the Company own assets for logistics needs',
      value: 'assetOwnership'
    },
    {
      label: 'Is the Company an LPO based trader or a regular trader',
      value: 'traderType'
    },
    {
      label: 'Is the Company an established or speculative client',
      value: 'clientBase'
    }
  ];
  valueAdd = [
    {
      label: 'Value Add',
      controlName: 'valueAdd',
      inputType: 'select',
      inputName: 'select',
      size: 6,
      options: [
        { label: 'Low', value: 'low', viewValue: 'Low' },
        { label: 'High', value: 'high', viewValue: 'High' }
      ]
    }
  ];
  network = [
    {
      label: 'Distribution  Network',
      controlName: 'distributionNetwork',
      inputType: 'select',
      inputName: 'select',
      size: 6,
      options: [
        { label: 'Narrow', value: DistributionNetwork.NARROW, viewValue: 'Narrow' },
        { label: 'Wide', value: DistributionNetwork.WIDE, viewValue: 'Wide' }
      ]
    }
  ];

  typers = {
    Processor: this.valueAdd.slice(),
    Trader: [
      {
        label: 'Value of completed transactions in last 6 months (maximum value)',
        controlName: 'averageTransactions',
        inputType: 'number',
        inputName: 'default',
        append: 'KES',
        size: 6
      },
      {
        label: 'Number of completed transactions in last 6 months (based on bank statements)',
        controlName: 'completedTransactions',
        inputType: 'number',
        inputName: 'default',
        size: 6
      },
      {
        label: 'Does the Company own assets for logistics needs',
        controlName: 'assetOwnership',
        inputType: 'select',
        inputName: 'select',
        size: 6,
        options: [
          { label: 'Yes', value: true, viewValue: 'Yes' },
          { label: 'No', value: false, viewValue: 'No' }
        ]
      },
      {
        label: 'Is the Company an LPO based trader or a regular trader',
        controlName: 'traderType',
        inputType: 'select',
        inputName: 'select',
        size: 6,
        options: [
          { label: 'LPO Trader', value: TraderType.LPO, viewValue: 'LPO Trader' },
          { label: 'Regular Trader', value: TraderType.TRADER, viewValue: 'Regular Trader' }
        ]
      }
    ],
    'Fresh Produce': this.valueAdd.slice(),
    Inputs: this.network.slice(),
    'Animal Trader': [
      {
        label: 'Client Base',
        controlName: 'clientBase',
        inputType: 'select',
        inputName: 'select',
        size: 6,
        options: [
          { label: 'Established', value: ClientBase.ESTABLISHED, viewValue: 'Established' },
          { label: 'Speculative', value: ClientBase.SPECULATIVE, viewValue: 'Speculative' }
        ]
      }
    ],
    'Livestock Processor': this.valueAdd.slice(),
    'Product Trader': this.network.slice(),
    'Crop Exporter': this.valueAdd.slice(),
    'Animal Byproduct Exporter': this.valueAdd.slice()
  };
  uniqueDocuments = {
    Processor: ['Food Handling and Safety License', 'KEBS (Kenya Bureau of Standards)'],
    Trader: [
      'LPO for the current deal being funded',
      'Evidence of last three LPOs secured and delivered',
      'Independent Validation of LPOs with the Buying Entity'
    ],
    'Fresh Produce': ['Previous Trade Documents', 'Shorter Payment Terms given short shelf life. Max 21 days period'],
    Inputs: ['Split repayments structure preferred.'],
    'Animal Trader': ['LPOs'],
    'Livestock Processor': ['Food Handling and Safety License', 'KEBS (Kenya Bureau of Standards)'],
    'Product Trader': [],
    'Crop Exporter': [
      'Terms of sale and payment with Buyer',
      'Export Trade Documents i.e. shipping (bill of lading) or air documentation',
      'Structure with mini-disbursements',
      'Seasonal Businesses - liquidity calculated per season and not month on month.',
      'Incoterms'
    ],
    'Animal Byproduct Exporter': [
      'Terms of sale and payment with Buyer',
      'Export Trade Documents i.e. shipping (bill of lading) or air documentation',
      'Structure with mini-disbursements',
      'Incoterms'
    ]
  };
  constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private alerts: AlertService
  ) {
    this.form = this.fb.group({
      type: [undefined, Validators.required],
      monthlyLiquidity: [undefined, Validators.required],
      tdScore: [undefined],
      mlScore: [undefined],
      valueAdd: [undefined],
      averageTransactions: [undefined],
      completedTransactions: [undefined],
      assetOwnership: [undefined],
      traderType: [undefined],
      clientBase: [undefined],
      distributionNetwork: [undefined]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setupView();
    if (changes?.['tdScore'] || changes?.['mlScore']) {
      this.patch();
    }
  }
  ngOnInit(): void {
    this.patch();
    this.form.get('type').valueChanges.subscribe((value) => {
      this.handleTypeChange(value);
    });
  }
  setupView() {
    if (this.mlScoreData?.decision?.decisionTree) {
      console.log(this.mlScoreData?.decision?.decisionTree);
      this.decisionTree = [];
      const decisionTree = this.mlScoreData.decision.decisionTree;
      Object.keys(decisionTree).forEach((key) => {
        const mappedKey = this.mapKey(key);
        const value = this.mapValue(decisionTree[key]);
        const extra = this.mapExtra(key);
        let show = true;
        if (key === 'tdScore' || key === 'mlScore') show = this.user?.policyRole?.name === 'avenews-admin';
        if (decisionTree[key] != null) this.decisionTree.push({ key: mappedKey, value, extra, show });
      });
    }
  }
  mapValue(val: any) {
    const mapper = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
      { label: 'LPO Trader', value: TraderType.LPO },
      { label: 'Regular Trader', value: TraderType.TRADER },
      { label: 'Established', value: ClientBase.ESTABLISHED },
      { label: 'Speculative', value: ClientBase.SPECULATIVE }
    ];
    return mapper.find((data) => data.value === val)?.label || val;
  }
  mapExtra(key: string) {
    const mapper = [
      { label: 'tdScore', extra: '%' },
      { label: 'mlScore', extra: '%' },
      { label: 'averageTransactions', extra: 'KES' },
      { label: 'monthlyLiquidity', extra: 'KES' },
      { label: 'outcome', extra: 'KES' }
    ];
    return mapper.find((data) => data.label === key)?.extra || '';
  }
  mapKey(key: string) {
    return this.mapper.find((data) => data.value === key)?.label;
  }
  handleTypeChange(value: string) {
    this.chosenType = this.typers[value] || [];
    const controls = [
      'valueAdd',
      'averageTransactions',
      'completedTransactions',
      'assetOwnership',
      'traderType',
      'clientBase',
      'distributionNetwork'
    ];
    controls.forEach((control) => {
      this.form.get(control).reset();
      this.form.get(control).clearValidators();
    });
    this.chosenType.forEach((data) => {
      this.form.get(data.controlName).setValidators(Validators.required);
    });
  }

  close() {
    $('#addDecisionModal').fadeOut('', () => {
      this.deleteData();
    });
  }
  openModal() {
    $('#addDecisionModal').css({ display: 'flex' });
    this.patch();
  }
  deleteData() {
    this.form.reset();
  }
  patch() {
    if (this.mlScoreData?.decision?.decisionTree) {
      this.handleTypeChange(this.mlScoreData.decision.decisionTree.type);
      this.form.patchValue(this.mlScoreData.decision.decisionTree);
    }
    this.form.patchValue({
      tdScore: +(this.tdScore || 0).toFixed(2),
      mlScore: +(this.mlScore || 0).toFixed(2)
    });
  }
  calculate() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.spinner.showSpinner();
    const values = this.form.getRawValue();
    // remove undefined from values and save in dto
    const dto = {};
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        dto[key] = values[key];
      }
    });
    this.http
      .post(`${environment.new_api_url}/api/trade-desk/decision-tree/calculate/loan/${this.loan?._id}`, dto)
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err?.error?.message, 'danger');
          console.log(err);
          return EMPTY.pipe();
        })
      )
      .subscribe((data: any) => {
        this.spinner.hideSpinner();
        this.alerts.showAlert('Decision Tree Calculated Successfully', 'success');
        this.close();
        this.router.navigateByUrl(this.router.url);
      });
  }

  save() {
    const typeControl = this.form.get('type');
    typeControl.markAsTouched();
    if (!typeControl.valid) {
      return;
    }
    this.spinner.showSpinner();
    const values = this.form.getRawValue();
    // remove undefined from values and save in dto
    const dto = {};
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        dto[key] = values[key];
      }
    });
    this.http
      .post(`${environment.new_api_url}/api/trade-desk/decision-tree/save/loan/${this.loan?._id}`, dto)
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err?.error?.message, 'danger');
          console.log(err);
          return EMPTY.pipe();
        })
      )
      .subscribe((data: any) => {
        this.spinner.hideSpinner();
        this.alerts.showAlert('Decision Tree Saved Successfully', 'success');
        this.close();
        this.router.navigateByUrl(this.router.url);
      });
  }
}
