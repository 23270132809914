<div class="main-filter-wrapper">
  <div [formGroup]="selectForm" class="filters-wrapper">
    <div *ngFor="let item of colValues; let i = index" class="selected-filter">
      <button
        id="{{ fixString(item?.name!) }}chips"
        mat-icon-button
        [matMenuTriggerFor]="chips"
        #chipsBtn
        class="chips-button"
        #menuTrigger
      >
        {{ item.name | titlecase }}:
        <span class="pl-1" *ngIf="selectForm.get(fixString(item.name))!.value !== 'between'">{{
          (filteringForm.get(fixString(item.name))!.get('label')?.value | truncate : 20) || ''
        }}</span>
        <span class="pl-1" *ngIf="selectForm.get(fixString(item.name))!.value === 'between'"
          >{{ (filteringForm.get(fixString(item.name))!.get('startLabel')?.value | truncate : 20) || '' }}
          -
          {{ filteringForm.get(fixString(item.name))!.get('endLabel')?.value | truncate : 20 }}</span
        >
        <i-feather name="x" (click)="remove(item)"></i-feather>
      </button>

      <mat-menu (close)="closedChip($event)" class="filter-drop" #chips="matMenu">
        <div (keyup.enter)="filterValue(item, chipsBtn)" [formGroup]="filteringForm" *ngIf="item.type === 'text'" class="chips-wrapper">
          <div class="chips-dropdown">
            <div [formGroupName]="fixString(item?.name!)" (click)="$event.stopPropagation()">
              <p>{{ item?.name | titlecase }}</p>

              <input formControlName="value" type="text" class="form-control" id="text" />
            </div>
            <div class="chips-buttons">
              <button (click)="remove(item)" class="cancel-button">Cancel</button>
              <button (click)="filterValue(item, chipsBtn)" class="apply-button">Apply</button>
            </div>
          </div>
        </div>
        <div *ngIf="item.type === 'number'" class="filter-dropdown">
          <div (keyup.enter)="filterValue(item, chipsBtn)" (click)="$event.stopPropagation()" class="chips-dropdown">
            <ng-select
              (change)="changeType(item, $event)"
              [formControlName]="fixString(item?.name!)"
              [clearable]="false"
              class="filter-select"
              [searchable]="false"
            >
              <ng-option value="equal">Equals</ng-option>
              <ng-option value="lower">Smaller than</ng-option>
              <ng-option value="greater">Bigger than</ng-option>
              <ng-option value="between">Between</ng-option>
            </ng-select>
            <div
              [formGroup]="filteringForm"
              *ngIf="
                selectForm.get(fixString(item?.name!))!.value === 'equal' ||
                selectForm.get(fixString(item?.name!))!.value === 'lower' ||
                selectForm.get(fixString(item?.name!))!.value === 'greater'
              "
            >
              <p class="col-name">{{ item.name | titlecase }}</p>
              <ng-container [formGroupName]="fixString(item?.name!)">
                <input formControlName="value" type="number" class="form-control" id="number" />
              </ng-container>
            </div>
            <div [formGroup]="filteringForm" *ngIf="selectForm.get(fixString(item?.name!))?.value === 'between'">
              <div [formGroupName]="fixString(item?.name!)">
                <p class="number-between">From</p>
                <input formControlName="startValue" type="number" class="form-control" id="number" />
              </div>
              <div [formGroupName]="fixString(item?.name!)">
                <p class="number-between">To</p>
                <input formControlName="endValue" type="number" class="form-control" id="number" />
              </div>
            </div>
            <div class="chips-buttons">
              <button (click)="remove(item)" class="cancel-button">Cancel</button>
              <button (click)="filterValue(item, chipsBtn)" class="apply-button">Apply</button>
            </div>
          </div>
        </div>
        <div *ngIf="item.type === 'date'" class="filter-dropdown">
          <!-- type date -->
          <div (keyup.enter)="filterValue(item, chipsBtn)" (click)="$event.stopPropagation()" class="chips-dropdown">
            <ng-select
              (change)="changeType(item, $event)"
              [formControlName]="fixString(item?.name!)"
              [clearable]="false"
              class="filter-select"
              [searchable]="false"
            >
              <ng-option value="equal">Equals</ng-option>
              <ng-option value="lower">Before</ng-option>
              <ng-option value="greater">After</ng-option>
              <ng-option value="between">Between</ng-option>
            </ng-select>
            <div [formGroup]="filteringForm" *ngIf="selectForm.get(fixString(item?.name!))?.value !== 'between'">
              <p class="col-name">{{ item.name | titlecase }}</p>
              <div [formGroupName]="fixString(item?.name!)" class="date">
                <input class="form-control" placeholder="DD/MM/YYYY" name="date" formControlName="value" type="date" />
              </div>
            </div>
            <div [formGroup]="filteringForm" *ngIf="selectForm.get(fixString(item?.name!))?.value === 'between'">
              <div [formGroupName]="fixString(item?.name!)">
                <p class="number-between">From</p>
                <!-- need to fix this -->
                <div class="date">
                  <input class="form-control" placeholder="DD/MM/YYYY" name="date" formControlName="startValue" type="date" />
                </div>
              </div>
              <div [formGroup]="filteringForm">
                <p class="number-between">To</p>
                <!-- need to fix this -->
                <div [formGroupName]="fixString(item?.name!)" class="date">
                  <input class="form-control" placeholder="DD/MM/YYYY" name="date" formControlName="endValue" type="date" />
                </div>
              </div>
            </div>
            <div class="chips-buttons">
              <button (click)="remove(item)" class="cancel-button">Cancel</button>
              <button (click)="filterValue(item, chipsBtn)" class="apply-button">Apply</button>
            </div>
          </div>
        </div>
        <div *ngIf="item.type === 'radio'" class="chips-wrapper">
          <div (keyup.enter)="filterValue(item, chipsBtn)" class="chips-dropdown radio-dropdown">
            <div (click)="$event.stopPropagation()">
              <div class="search-radio">
                <div class="search-wrapper">
                  <input type="search" class="search" placeholder="search..." #input />
                  <i-feather class="search-icon" name="search"></i-feather>
                </div>
              </div>
              <div [formGroup]="filteringForm" class="radio radio-button-wrapper">
                <div
                  [formGroupName]="fixString(item?.name!)"
                  *ngFor="let radio of item?.radio; let j = index"
                  [ngClass]="{ 'first-radio': j === 0 }"
                  class="radio radio-label-wrapper"
                >
                  <input type="radio" class="radio" [value]="radio?.value" formControlName="value" name="value" [id]="radio.value" />
                  <label [for]="radio.value">{{ radio?.label }}</label>
                </div>
              </div>
            </div>
            <div class="chips-buttons radio-buttons">
              <button (click)="remove(item)" class="cancel-button">Cancel</button>
              <button (click)="filterValue(item, chipsBtn)" class="apply-button">Apply</button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

    <!-- add-filter -->

    <button mat-icon-button [matMenuTriggerFor]="filter" aria-label="Example icon-button with a menu" class="filter-button">
      Add filter <i-feather name="filter"></i-feather>
    </button>
    <div *ngIf="colValues.length" class="clear-all">
      <button (click)="clearAll()" class="clear-filters">Clear filters</button>
    </div>
  </div>

  <!-- filters-cols -->
  <mat-menu class="filter-drop" #filter="matMenu">
    <div class="filter-dropdown">
      <div class="filter-header-wrapper">
        <div (click)="$event.stopPropagation()" class="search-wrapper">
          <input [(ngModel)]="searchTerm" type="search" class="search" placeholder="search..." #input />
          <i-feather class="search-icon" name="search"></i-feather>
        </div>
      </div>
      <div class="filter-details">
        <ng-container *ngFor="let item of listConfig?.filters | filter : searchTerm : true">
          <button *ngIf="item?.dtoName && !item?.noFilter" class="menu-item" mat-menu-item>
            <span class="filter-name" (click)="addFilter(item)">{{ item?.name | titlecase }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-menu>
  <!-- clear-filter -->
</div>
<div class="flex mt-3">

  <div *ngFor="let item of easyFilters; let i = index" [ngClass]="{ 'active-filter': item?.active }" class="easy-filters selected-filter">
    <button (click)="easyFilterAction.emit(item)" #chipsBtn class="chips-button">
    {{ item.name | titlecase }}:
    <span class="pl-1" *ngIf="item?.option !== 'between'">{{ (item?.filterName | truncate: 20) || '' }}</span>
    <!-- <span class="pl-1" *ngIf="selectForm.get(fixString(item.name))!.value === 'between'"
      >{{ (filteringForm.get(fixString(item.name))!.get('startLabel')?.value | truncate: 20) || '' }}
      -
      {{ filteringForm.get(fixString(item.name))!.get('endLabel')?.value | truncate: 20 }}</span
      > -->
      <i-feather *ngIf="item?.active" name="x" (click)="$event.stopImmediatePropagation();removeEasyFilter(item)"></i-feather>
  </button>
</div>
    </div>