import { Component, Input, OnInit } from '@angular/core';
import { InfoView } from '../../helpers';

@Component({
  selector: 'av-info-view',
  templateUrl: './info-view.component.html',
  styleUrls: ['./info-view.component.scss']
})
export class InfoViewComponent implements OnInit {
  @Input() title: string;
  @Input() list: InfoView[];
  @Input() customClass: string;
  @Input() subtitle: string;
  constructor() {}

  ngOnInit(): void {}
}
