import { Component, Injector, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { environment } from '@env/environment';
import { QueryMode, User } from '@avenews/agt-sdk';
import { ListNoSdkComponent } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'link-user-to-deal-modal',
  templateUrl: './link-user-to-deal-modal.component.html',
  styleUrls: ['./link-user-to-deal-modal.component.scss']
})
export class LinkUserToDealModalComponent extends ListNoSdkComponent<any> implements OnInit, OnChanges {
  chooseUserForm: FormGroup;
  @Input() users: User[];
  @Output() getUser = new EventEmitter();
  @Input() isNotOrganization = true;
  @Output() next = new EventEmitter();
  recommendedUsers = [];
  users$: any;
  currUser: any;
  term: any;
  constructor(injector: Injector, private route: ActivatedRoute, protected router: Router, private alertService: AlertService) {
    super(injector, {
      noPagination: false,
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/user`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'users';
    this.chooseUserForm = this.fb.group({ user: [undefined, Validators.required] });
  }
  ngOnInit(): void {
    super.init();
    this.paginated.subscribe(() => {
      this.patchUser();
    });
  }
  close() {
    $('#linkUserToDealAccount').fadeOut('', () => {
      this.deleteData();
    });
  }
  deleteData() {
    this.chooseUserForm.reset();
    this.chooseUserForm.patchValue({
      user: undefined
    });
  }
  patchUser() {
    if (this.currUser) {
      const user = this.list.find((s) => s._id === this.currUser._id); // this fixes the selected not showing in the edit bug
      this.chooseUserForm.patchValue({
        user
      });
    }
  }
  continue() {
    if (this.isNotOrganization) {
      if (this.chooseUserForm.value) {
        this.next.emit(this.chooseUserForm.value);
        this.close();
      }
    } else if (!this.isNotOrganization) {
      this.getUser.emit(this.chooseUserForm.value);
      this.close();
    }
  }
  override search(input: string) {
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.list = this.unsortedList.slice();
      this.totalCount = this.list.length;
      if (input) {
        this.clearFilters();
        const keys = [
          'personalInformation.firstName',
          'personalInformation.lastName',
          'personalInformation.phoneNumber',
          'personalInformation.email'
        ];
        const opts = [];
        keys.forEach((key) => {
          const opt = {};
          opt['column'] = key;
          opt['value'] = input;
          opt['option'] = 'contains';
          opts.push(opt);
        });
        this.queryMode = QueryMode.OR;
        this.filterOptions = opts;
        this.options.pageNumber = 1;
        this.pageChanged(1);
        this.totalCount = this.list.length;
      } else {
        this.queryMode = QueryMode.AND;
        this.options.pageNumber = 1;
        this.filterOptions = [];
        this.pageChanged(1);
      }
    }, 600);
  }
}
