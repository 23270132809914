import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SdkService } from '@avenews/shared-lib';
declare const $: any;

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {
  closeModal = false;
  @Output() confirm = new EventEmitter();
  @Input() userEmail: string;
  resetLinkStatus: number;

  constructor(private sdkService: SdkService) {}

  ngOnInit() {}

  cancel() {
    $('#forgotModalWizard').fadeOut();
  }
  recoverPassword() {
    this.sdkService.sendResetPasswordLink({ email: this.userEmail }).then(() => {});
    this.confirm.emit();
  }
}
