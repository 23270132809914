import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { BaseFormTemplateComponent, SpinnerToggleService } from '@avenews/shared-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'frontend-user-approve-topup',
  templateUrl: './user-approve-topup.component.html',
  styleUrls: ['./user-approve-topup.component.scss']
})
export class UserApproveTopupComponent extends BaseFormTemplateComponent implements OnInit {
  @Input() topup: any;
  alertService: any;
  repaymentForm: FormGroup;
  amount = 100000;

  constructor(private spinner: SpinnerToggleService, private http: HttpClient, private router: Router, fb: FormBuilder) {
    super();
    this.repaymentForm = fb.group({
      amount: [undefined, [Validators.required, Validators.min(1), Validators.max(this.amount)]]
    });
    this.repaymentForm.patchValue({
      amount: this.amount
    });
    this.errorMessages = {
      amount: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'max', message: 'Can’t be bigger than the Max amount' }
      ]
    };
    this.formInput = this.repaymentForm;
  }

  override ngOnInit() {}
  close() {
    $('#approveUserTopupWizard').fadeOut();
  }
  submit() {
    this.validateForm(this.repaymentForm);
    if (this.repaymentForm.valid) {
      this.spinner.showSpinner();
      //   this.http
      //     .put(`${environment.new_api_url}/api/loan-application/status/${this.topup._id}`, {
      //       status: 'approved'
      //     })
      //     .pipe(
      //       catchError((err) => {
      //         this.alertService.showAlert(err?.error?.message, 'danger');
      //         this.spinner.hideSpinner();
      //         $('#approveUserTopupWizard').fadeOut();
      //         return EMPTY.pipe();
      //       })
      //     )
      //     .subscribe((data) => {
      //       this.alertService.showAlert(`Top up approved successfuly`, 'success');
      //       $('#changeAppStatusWizard').fadeOut();
      //       this.router.navigateByUrl(this.router.url);
      //       this.spinner.hideSpinner();
      //     });
      // }
    }
  }
}
