<div class="questions-main-wrapper">
  <div class="questions-title">
    <p class="section-title">Questions and answers</p>
  </div>
  <div [formGroup]="prods" class="questions">
    <div formArrayName="question" class="question-wrapper" *ngFor="let question of order.questions; let i = index">
      <div class="question-title">
        <p class="question-title">{{ i + 1 }}. {{ question?.question }}</p>
        <div class="desc-in-weeks">
          <textarea [readOnly]="!isSaved" class="question-content" formControlName="{{ i }}"></textarea>
          <div [formGroup]="prods" *ngIf="i === 1 && question.question.includes('cycle')" class="form-append">
            <input [readOnly]="!isSaved" formControlName="moneyCycle" class="supplier-input form-group col-md-12" type="number" />
            <div class="appended">In weeks</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="edit-btn-wrapper">
  <button *ngIf="isSaved" (click)="onSave()" class="save-btn">Save</button>
  <button *ngIf="!isSaved" (click)="onEdit()" class="edit-btn">Edit</button>
</div>
