import { PortfolioMonitorModule } from './portfolio-monitor/portfolio-monitor.module';
import { TradeDeskModule } from './trade-desk/trade-desk.module';
import { AdminSharedModule } from './shared/admin-shared.module';
import { AdminHttpInterceptor } from './interceptors/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AvenewsAlertsModule } from '@avenews/alerts';
import { LoginModule } from '@avenews/front-auth';
import { SharedModule } from '@avenews/shared-lib';
import { ShellModule } from '@avenews/shell';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { authConfig, env, shellConfig } from './core/constants';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppRoutingModule } from './app.routing.module';
import { PayModule } from './pay/pay.module';
import { UsersModule } from './users/users.module';
import { AgribusinessesModule } from './agribusinesses/agribusinesses.module';
import { InvoiceModule } from './invoice/invoice.module';
import { FinanceModule } from './finance/finance.module';
import { OrdersModule } from './orders/orders.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import { DistributorModule } from './distributor/distributor.module';
import { AgentsModule } from './agents/agents.module';

initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    DashboardModule,
    ShellModule.forRoot(shellConfig),
    AvenewsAlertsModule,
    SharedModule.forRoot({ env }),
    LoginModule.forRoot(authConfig.login),
    InvoiceModule,
    UsersModule,
    TradeDeskModule,
    AgribusinessesModule,
    OrdersModule,
    OrganizationsModule,
    DistributorModule,
    AdminSharedModule,
    NgbModule,
    PayModule,
    PortfolioMonitorModule,
    FinanceModule,
    AgentsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
    // CustomFormsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AdminHttpInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {}
