import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as libphonenumber from 'google-libphonenumber';
import { SocialAuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { SocialNetworkName, SocialNetworkRegistrationDTO, User, AGTError } from '@avenews/agt-sdk';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { countries, SdkService } from '@avenews/shared-lib';
import { RegistrationConfig } from '../../auth-config';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  formInput: FormGroup;
  showPass = false;
  SocialUserForm: FormGroup;
  user: any;
  socialNetworkRegister = false;
  countrySocialUser: string;
  businessNameSocialUser: string;
  countries = countries;
  error: string;
  phoneUtil: any;
  isConfirmed = false;
  unVerifiedEmail: string;
  regionCode: string;
  phoneCode: string;
  partialPhoneNumber: string;
  emailPhoneError = false;
  socialUser: SocialUser;
  canRegister = false;
  accessToken: string;
  isLoading = false;
  network: SocialNetworkName;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private alertService: AlertService,
    // public intercom: Intercom,
    @Inject('registrationConfig') public registrationConfig: RegistrationConfig,
    private sdkService: SdkService
  ) {
    this.SocialUserForm = this.formBuilder.group({
      countrySocialUser: [undefined, Validators.required]
    });
  }

  ngOnInit() {
    this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    this.regionCode = this.registrationConfig.environment.variables.regionCode;
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);
    this.registrationForm = this.formBuilder.group(
      {
        firstName: [undefined, Validators.required],
        lastName: [undefined, Validators.required],
        email: [undefined, Validators.required],
        phoneNumber: [undefined],
        country: [undefined],
        password: [undefined, [Validators.required, Validators.minLength(8)]],
        confirmPassword: [undefined, Validators.required],
        companyName: [undefined]
      },
      { validator: [this.equalPasswordsValidator, this.emailAndPhoneValidator] }
    );
    this.route.paramMap.subscribe((data) => {
      this.unVerifiedEmail = data.get('email');
      if (this.unVerifiedEmail) {
      }
    });
    this.formInput = this.registrationForm;
  }

  showPassword() {
    if (this.showPass === false) {
      this.showPass = true;
    } else {
      this.showPass = false;
    }
  }

  equalPasswordsValidator(group: FormGroup) {
    if (group.value.confirmPassword !== group.value.password) {
      return { passwordsNotMatch: true };
    }
    return null;
  }

  emailAndPhoneValidator(group: FormGroup) {
    if (!group.value.phoneNumber && !group.value.email) {
      return { emailAndPhoneError: true };
    } else {
      return null;
    }
  }

  phoneValidator(phoneNumber: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.phoneUtil.isValidNumber(phoneNumber)) {
        return { phoneError: true };
      }
      return null;
    };
  }

  showEmailPhoneError() {
    return this.userf?.['email'].touched && this.userf?.['phoneNumber'].touched && this.registrationForm.hasError('emailAndPhoneError');
  }

  generateLink(code: any, country: any) {
    return `<img src='../../assets/img/flags/${code}.png' height='19' height='27'><span>\xa0\xa0${country}</span>`;
  }

  phoneChanges() {
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);
    this.registrationForm.patchValue({
      phoneNumber: this.partialPhoneNumber
    });
  }

  passwordsNotMatch() {
    return (
      this.registrationForm.get('confirmPassword').touched &&
      this.registrationForm.get('password').value !== this.registrationForm.get('confirmPassword').value
    );
  }

  onGeneralSubmit() {
    this.onSubmit(this.registrationForm);
    if (this.registrationForm.valid) {
      this.isLoading = true;
      const dto: any = {
        firstName: this.userf?.['firstName'].value,
        lastName: this.userf?.['lastName'].value,
        username: this.userf?.['email'].value,
        role: 'agribusiness',
        country: this.userf?.['country'].value,
        countryPhoneCode: '+' + this.phoneCode,
        password: this.userf?.['password'].value,
        phoneNumber:
          this.phoneCode && this.userf?.['phoneNumber'].value
            ? '+' + this.phoneCode + this.userf?.['phoneNumber'].value
            : this.userf?.['phoneNumber'].value,
        companyName: this.userf?.['companyName'].value
      };

      if (!this.canRegister) {
        this.sdkService
          .register(dto)
          .then((user: User) => {
            this.canRegister = true;
            this.isConfirmed = true;
            if (user._id) {
              this.user = user;
              this.unVerifiedEmail = this.user.personalInformation.email;
              this.intercomLogin(user, false);
            } else {
              return;
            }
          })
          .catch((err: AGTError) => {
            this.alertService.showAlert(err.message, 'danger');
            this.isLoading = false;
          });
      } else {
      }
    }
  }
  get userf() {
    return this.registrationForm.controls;
  }

  regularForm() {
    this.socialNetworkRegister = false;
  }

  //  uncomment after adding intercom
  intercomLogin(user: User, isOAuth: boolean) {
    // this.intercom.update({
    //   app_id: environment.intercom.app_id,
    //   name: user.personalInformation.firstName + ' ' + user.personalInformation.lastName,
    //   email: user.personalInformation.email,
    //   phone: user.personalInformation.phoneNumber,
    //   user_id: user._id,
    //   role: user.roles[0],
    //   registered: true,
    //   validated: isOAuth,
    //   logged_in: isOAuth,
    //   widget: {
    //     activator: '#intercom'
    //   }
    // });
  }

  filter(e: any, search: string) {
    e.filter(search);
  }
  search(a: string, b: { viewValue: string }) {
    return b.viewValue.toLowerCase().startsWith(a.toLowerCase()) ? b : null;
  }

  resendConfirm() {
    this.sdkService.resendValidation({ username: this.unVerifiedEmail }).then(() => {});
  }

  isFieldInvalid(field: string, errorType?: string) {
    if (field === 'password' && errorType === 'minlength') {
      return this.formInput.get(field).hasError('minlength') && this.formInput.get(field).touched;
    } else if (field === 'password' && errorType === 'required') {
      return this.formInput.get(field).hasError('required') && this.formInput.get(field).touched;
    } else {
      return this.formInput.get(field).invalid && this.formInput.get(field).touched;
    }
  }

  showFieldStyle(field: string) {
    return {
      'has-error': this.isFieldInvalid(field)
    };
  }

  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }

  // Method to sign in with facebook.
  preSignUp(network: SocialNetworkName): void {
    this.network = network;
    let platform: string;
    if (network === 'facebook') {
      platform = FacebookLoginProvider.PROVIDER_ID;
    }
    if (network === 'google') {
      platform = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(platform).then((response: SocialUser) => {
      if (!response.email) {
        this.alertService.showAlert('Please, provide your email address', 'danger');
      } else {
        this.accessToken = response.authToken;
        this.socialUser = response;
        this.socialNetworkRegister = true;
      }
    });
  }

  signInWithGoogle() {
    this.isLoading = true;
    const socialuserInfo: SocialNetworkRegistrationDTO = {
      access_token: this.accessToken,
      country: this.countrySocialUser,
      businessName: this.businessNameSocialUser
    };

    this.authService
      .oAuthRegistration(socialuserInfo, this.network)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (credentials) => {
          this.route.queryParams.subscribe((params) => this.router.navigate([params?.['redirect'] || '/'], { replaceUrl: true }));
          this.intercomLogin(credentials.user, true);
        },
        (err: AGTError) => {
          this.isLoading = false;
          this.alertService.showAlert(err.message, 'danger');
        }
      );
  }
}
