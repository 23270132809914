<div>
  <div>
    <div class="docs-section" *ngFor="let doc of documents; let i = index">
      <div class="header">
        <h1>{{ doc.title }}</h1>
      </div>
      <div class="uploaded-documents">
        <div class="state emptySate" *ngIf="!doc.data?.length; else fullSate">
          <img src="../../../../assets/images/svgs/Group 77.svg" />
          <h1>No documents added yet</h1>
        </div>
        <ng-template #fullSate>
          <div class="doc-card clearfix {{ document?.cssStatus }}" *ngFor="let document of doc.data">
            <div class="heading">
              <div
                *ngIf="(document?.valid === false && document?.status === 'awaiting') || document.status === 'declined'"
                class="ttooltip tools"
              >
                <img src="../../../../assets/images/svgs/info.svg" />
                <span class="ttooltiptext">{{ document.description || 'Invalid format' }}</span>
              </div>

              <div
                class="ttooltip tools"
                *ngIf="(document.valid === true && document.status === 'awaiting') || document.status === 'approved'"
              >
                <i-feather name="check" class="approved"></i-feather>
                <span class="ttooltiptext">{{ 'Approved' }}</span>
              </div>
              <div
                class="ttooltip tools"
                *ngIf="
                  (document.status === 'awaiting' && document.valid === undefined) || (!document?.status && document.valid === undefined)
                "
              >
                <i-feather class="awaiting" name="clock"></i-feather>
                <span class="ttooltiptext">{{ 'Awaiting approval' }}</span>
              </div>
              <div class="pass ttooltip tools" *ngIf="document?.password">
                <i-feather name="key"></i-feather>
                <span class="ttooltiptext">{{ document?.password }}</span>
              </div>
              <div>
                <img class="dropbtn" [matMenuTriggerFor]="menu" src="../../../../assets/images/svgs/Group 5260.svg" />
                <mat-menu #menu="matMenu" class="menu-content">
                  <button (click)="openchangeStatusModal(document)" mat-menu-item class="menu-item">
                    <span>Change document status</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="view">
              <div class="file-image-wrapper">
                <img src="../../../assets/images/svgs/file-1.svg" />
              </div>
              <p class="t-bold">{{ document?.fileName }}</p>
              <p *ngIf="document?.uploadedBy?.personalInformation">
                {{ document?.uploadedBy?.personalInformation?.firstName }}
                {{ document?.uploadedBy?.personalInformation?.lastName }}
              </p>
            </div>
            <div class="doc-footer">
              <p class="t-light-gray">{{ document.dateCreated | date : 'dd/MM/yyyy' }}</p>
              <div class="action-btns">
                <a (click)="openFile(document)" target="_blank"> <img src="../../../../assets/images/svgs/download.svg" /></a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
