import { ChartsResolver } from './resolvers/charts.resolver';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';

import { PortfolioMonitorRoutingModule } from './portfolio-monitor.routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '@avenews/shared-lib';
import { UiLibraryModule } from '@avenews/ui-library';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { ChartsDashboardComponent } from './charts-dashboard/charts-dashboard.component';

@NgModule({
  declarations: [ChartsDashboardComponent],
  imports: [
    CommonModule,
    CommonModule,
    NgChartsModule,
    ReactiveFormsModule,
    FormsModule,
    UiLibraryModule,
    AdminSharedModule,
    SharedModule,
    PortfolioMonitorRoutingModule
  ],
  providers: [ChartsResolver]
})
export class PortfolioMonitorModule {}
