export interface BackAlertButtons {
  type: 'cancel' | 'confirm';
  text: string;
  customClass?: string;
  callback?: () => any;
}

export interface BackAlertOptions {
  title: string;
  subtitle?: string;
  buttons: BackAlertButtons[];
  customClass?: string;
}
