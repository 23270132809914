import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { InvoiceRoutingModule } from './invoice.routing.module';
import { InvoicesResolver } from './resolvers/invoices.resolver';

@NgModule({
  declarations: [InvoiceListComponent],
  imports: [CommonModule, MatTooltipModule, SharedModule, AdminSharedModule, InvoiceRoutingModule],
  providers: [InvoicesResolver]
})
export class InvoiceModule {}
