import { Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Agt4Period } from '@avenews/agt-sdk';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'repayments-modal',
  templateUrl: './repayment-modal.component.html',
  styleUrls: ['./repayment-modal.component.scss']
})
export class RepaymentModal extends BaseValidationComponent implements OnInit, OnChanges {
  @Input() period: any;
  beneficiaryAccountId: string;
  beneficiaryId: string;
  repaymentForm: FormGroup;
  environment = environment;

  options = [
    {
      viewValue: 'Mobile',
      value: 'mobile'
    },
    {
      viewValue: 'Bank',
      value: 'bank'
    },
    {
      viewValue: 'Income balance',
      value: 'income balance'
    }
  ];
  errorMessages = {
    amountRepaid: [
      { type: 'required', message: 'This field is required' },
      { type: 'min', message: 'Invalid number' },
      { type: 'max', message: 'Can’t be bigger than the remaining amount' }
    ]
  };
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.repaymentForm = fb.group({
      amountRepaid: [undefined, [Validators.required, Validators.min(0)]],
      repaymentDate: [undefined, Validators.required],
      paymentMethod: [undefined, Validators.required]
    });
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.period) {
      const repaidControl = this.repaymentForm.get('amountRepaid');
      repaidControl.clearValidators();
      repaidControl.setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(Number(this.roundDownTwoDecimals(this.period?.repaymentOption?.repaymentAmount)))
      ]);
    }
  }

  roundDownTwoDecimals(num: number): string {
    const roundedNumber = Math.floor(num * 100) / 100;
    return roundedNumber.toFixed(2);
  }

  close() {
    $('#repaymentModal').fadeOut('', () => {
      this.deleteData();
    });
  }

  deleteData() {
    this.repaymentForm.reset();
  }
  submit() {
    this.validateForm(this.repaymentForm);
    if (this.repaymentForm.valid) {
      this.spinner.showSpinner();
      this.http
        .post(`${this.environment?.new_api_url}/api/deal/${this.period?.deal?._id}/period/${this.period?._id}/repayment/set`, {
          amount: this.repaymentForm.get('amountRepaid').value,
          repaymentDate: this.repaymentForm.get('repaymentDate').value,
          type: this.repaymentForm.get('paymentMethod').value
        })

        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err?.error?.message, 'danger');
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.close();
          this.router.navigateByUrl(this.router.url);
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Repayment was set successfully`, 'success');
        });
    }
  }
}
