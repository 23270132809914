import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseAgribusiness, Agt4Order, Status } from '@avenews/agt-sdk';
import { SideView } from '../../shared/components/base-side-view/base-side-view.component';
import { BreadCrumbs } from '@avenews/shared-lib';

@Component({
  selector: 'frontend-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    },
    {
      name: 'Orders',
      route: 'orders'
    }
  ];
  topTitle: any;
  response = false;
  item: any;
  showModale = false;
  showDecModal = false;
  application: any;
  order: any;
  sideView: SideView[] = [
    {
      name: 'ISSUE DATE',
      dtoName: 'dateCreated',
      pipe: 'date'
    },
    {
      name: 'AMOUNT',
      dtoName: 'total',
      pipe: 'number'
    },

    {
      name: 'FINANCE % CAP',
      dtoName: 'financedCapRate',
      pipe: 'number'
    },
    {
      name: 'ORDER ROI',
      dtoName: 'roi',
      pipe: 'number'
    },
    {
      name: 'ORDER MARGIN',
      dtoName: 'margin',
      pipe: 'number'
    },

    {
      name: 'STATUS',
      dtoName: 'status'
    }
  ];
  sideViewAddDetails: SideView[] = [
    {
      name: 'BUYER NAME',
      dtoName: 'customer.adminDetails.name'
    },
    {
      name: 'PHONE NUMBER',
      dtoName: 'customer.adminDetails.phoneNumber'
    },

    {
      name: 'EMAIL',
      dtoName: 'customer.adminDetails.email'
    },
    {
      name: 'SUPPLIER NAME',
      dtoName: 'customer.adminDetails.name'
    }
  ];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ order }) => {
      this.order = order;
      this.topTitle = 'order #' + this.order?.numericId;
    });
  }

  statusGrabber(status: any) {
    switch (status) {
      case 'new':
        return 'New';
      case Status.APPROVED:
        return 'Approved';
      case Status.DECLINED:
        return 'Denied';
      case Status.SETTLED:
        return 'Settled';
      case Status.PROCESSING:
        return 'In progress';
      case Status.PREAPPROVED:
        return 'Pre approved';
      default:
        return 'New';
    }
  }

  agriType(agri: any) {
    return agri?.paymentTerms ? 'Buyer' : 'Supplier';
  }
  agriFirstName(agri): string {
    if (agri?.contacts?.length) {
      return agri.contacts[0].firstName;
    }
    return '-';
  }
  agriLastName(agri): string {
    if (agri?.contacts?.length) {
      return agri.contacts[0].lastName;
    }
    return '-';
  }
}
