import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beneficiaryDetails'
})
export class BeneficiaryDetailsPipe implements PipeTransform {
  constructor() {}
  transform(item: any) {
    try {
      let details = item;
      if (item.details) {
        details = JSON.parse(item.details);
      }
      return details;
    } catch (err) {}
    return item || '-';
  }
}
