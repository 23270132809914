import { Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { SpinnerToggleService } from '@avenews/shared-lib';

@Injectable()
export class LoanFilesService {
  constructor(
    private http: HttpClient,
    private spinner: SpinnerToggleService,
    private alertService: AlertService,
    private router: Router
  ) {}

  openFile(file: any) {
    this.spinner.showSpinner();
    this.http
      .post(`https://gc0an3fw5j.execute-api.us-east-1.amazonaws.com/prod`, {
        bucket: file?.bucket,
        file: file?.s3Path,
        region_name: file?.bucket === 'agt-new-files-local-dev-staging' ? 'us-east-1' : 'us-east-2'
      })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Something went wrong`, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        window.open(data['url']);
      });
  }
  moveToArchive(file: any) {
    this.spinner.showSpinner();
    this.http
      .put(`${environment.new_api_url}/api/file/${file?._id}/archive?archived=true`, {})
      .pipe(
        catchError((err) => {
          this.alertService.showAlert(err?.error?.message, 'danger');
          this.spinner.hideSpinner();
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.alertService.showAlert(`Moved to archive successfuly`, 'success');
        this.router.navigateByUrl(this.router.url);
        this.spinner.hideSpinner();
      });
  }
  Unarchive(file: any) {
    this.spinner.showSpinner();
    this.http
      .put(`${environment.new_api_url}/api/file/${file?._id}/archive?archived=false`, {})
      .pipe(
        catchError((err) => {
          this.alertService.showAlert(err?.error?.message, 'danger');
          this.spinner.hideSpinner();
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.alertService.showAlert(`Unarchive successfuly`, 'success');
        this.router.navigateByUrl(this.router.url);
        this.spinner.hideSpinner();
      });
  }
}
