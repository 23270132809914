<div class="main-data-wrapper">
  <div class="section-wrapper">
    <p class="section-title">Order summary</p>
    <div class="top-form">
      <div>
        <label>Order margin</label>
        <input value="{{ order?.margin | number: '1.0-2' }}" type="text" readonly />
      </div>
      <div>
        <label>Order ROI</label>
        <input value="{{ order?.roi | number: '1.0-2' }}" type="text" readonly />
      </div>
    </div>
  </div>
  <div>
    <p class="section-title">Products</p>
    <table class="table-blue">
      <thead>
        <tr class="blue-tr">
          <th class="desc">PRODUCT</th>
          <th class="desc">MEASURMENT UNIT</th>
          <th class="desc">QUANTITY</th>
          <th class="desc">BUYING PRICE</th>
          <th class="desc">SELLING PRICE</th>
          <th class="desc">MARGIN</th>
          <th class="desc">ROI</th>
        </tr>
      </thead>
      <tbody [formGroup]="prods">
        <tr formArrayName="selling" class="table-body-tr" *ngFor="let prod of order.products; let i = index">
          <td>
            <div class="form">
              <input class="input" value="{{ prod?.product?.name }}" type="text" readonly />
            </div>
          </td>
          <td>
            <input class="input" value="{{ prod?.product?.measuramentUnit }}" type="text" readonly />
          </td>
          <td>
            <input class="input" value="{{ prod?.quantity }}" type="text" readonly />
          </td>
          <td>
            <div class="form-append">
              <input readonly class="supplier-input form-group col-md-12" type="number" value="{{ prod?.actualUnitPrice }}" />
              <div class="appended">KES</div>
            </div>
          </td>
          <td>
            <div class="form-append">
              <input [readOnly]="!isSaved" formControlName="{{ i }}" class="supplier-input form-group col-md-12" type="number" />
              <div class="appended">KES</div>
            </div>
          </td>
          <td>
            <input class="input" value="{{ prod?.margin | number: '1.0-2' }}" type="text" readonly />
          </td>
          <td>
            <input class="input" value="{{ prod?.roi | number: '1.0-2' }}" type="text" readonly />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="total">
      <div class="total-wrapper">
        <p>
          Total <span class="the-total">{{ order?.total | number: '1.0-2' }}<span class="kes">KES</span></span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="edit-btn-wrapper">
  <button *ngIf="isSaved" (click)="onSave()" class="save-btn">Save</button>
  <button *ngIf="!isSaved" (click)="onEdit()" class="edit-btn">Edit</button>
</div>
