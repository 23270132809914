<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-commission-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (addButton)="primaryActions($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
  (sendFilters)="filter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container *ngIf="col?.name !== 'STATUS'" [ngTemplateOutlet]="td"></ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td
        class="status-complete"
        matTooltip="If the status is declined, the commission will not be counted."
        matTooltipClass="base-tooltip"
        #statusTooltip="matTooltip"
      >
        <p class="status status-{{ item?.status | statuses: statuses:true }}">
          {{ item?.status | statuses: statuses }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
