import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
let nextUniqueId = 0;

@Component({
  selector: 'av-doc-password',
  templateUrl: './document-password.component.html',
  styleUrls: ['./document-password.component.scss']
})
export class DocumentPasswordComponent {
  private uniqueId = `factor-modal-${++nextUniqueId}`;
  passForm: FormGroup;
  @Input() isOpen: boolean;
  @Input() customClass = this.uniqueId;
  @Output() passwordValue = new EventEmitter<string>();
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: any;
  constructor(private fb: FormBuilder) {
    this.passForm = this.fb.group({
      password: [undefined]
    });
  }
  close() {
    this.isOpen = false;
    this.isOpenModal.emit(this.isOpen);
  }
  continue() {
    this.passwordValue.emit(this.passForm.value.password);
    this.close();
  }
}
