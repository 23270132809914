<ng-template
  [cdkConnectedOverlayPanelClass]="['document-password-modal', customClass]"
  cdkConnectedOverlayBackdropClass="sheet-backdrop"
  cdkConnectedOverlayHasBackdrop
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
>
  <div (keyup.enter)="continue()" class="modal-box" id="documentPasswordModal">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-content scrollable">
        <p class="sm-modal-title">Does the file have a password?</p>
        <p class="sent">If the file has a password please write it down, if not keep it blank and click on "Continue".</p>
        <form [formGroup]="passForm">
          <app-base-form-template
            labelName="Password"
            inputType="text"
            inputName="default"
            controlName="password"
            [group]="passForm"
            [size]="12"
          >
          </app-base-form-template>
        </form>
      </div>
      <div class="modal-box-actions">
        <button (click)="continue()" class="bttn-primary pull-right">Continue</button>
      </div>
    </div>
  </div>
</ng-template>
