import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiInputs'
})
export class MultiInputsPipe implements PipeTransform {
  transform(inputs: any[], type: string, tooltip?: boolean) {
    if (tooltip) {
      return this.tooltip(inputs, type);
    }
    return this.column(inputs, type);
  }
  tooltip(inputs: any[], type: string) {
    let res = '';

    inputs.forEach((data) => {
      if (data.input.type === type) {
        res += `${data.input.name} - ${data.quantityRequested} packages\n`;
      }
    });
    return res;
  }
  column(inputs: any[], type: string) {
    let length = 0;
    let index = -1;
    inputs.forEach((data, i) => {
      if (data.input) {
        if (data.input.type === type) {
          length++;
          index = i;
        }
      }
    });
    if (length) {
      if (length === 1) {
        if (inputs[index].input.type === type) {
          return inputs[index].input.name + ' - ' + inputs[index].quantityRequested + ' packages';
        } else {
          return '-';
        }
      } else {
        return 'Multiple';
      }
    } else {
      return '-';
    }
  }
}
