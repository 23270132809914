import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;
@Component({
  selector: 'av-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SlideToggleComponent,
      multi: true
    }
  ]
})
export class SlideToggleComponent implements ControlValueAccessor {
  value = false;
  public id = `av-slide-toggle-${++nextUniqueId}`;
  constructor() {}
  onChange: any = () => {};
  onTouch: any = () => {};

  // This will will write the value to the view if the the value changes occur on the model programmatically
  writeValue(value: any) {
    this.value = value || false;
  }
  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // When the element is touched, this method will get called
  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }
  setValue(value: boolean, checkbox: HTMLInputElement) {
    this.value = value;
    checkbox.checked = value;
    this.onChange(value);
    this.onTouch();
  }
}
