import { Component, Inject, OnInit } from '@angular/core';
import { LoginConfig } from '../../auth-config';
import { SdkService, SpinnerToggleService } from '@avenews/shared-lib';
import { ResetPasswordDTO } from '@avenews/agt-sdk';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'av-expired-token-reset',
  templateUrl: './expired-token-reset.component.html',
  styleUrls: ['./expired-token-reset.component.scss']
})
export class ExpiredTokenResetComponent implements OnInit {
  resetToken: string;
  username: string;
  constructor(
    @Inject('loginConfig') public loginConfig: LoginConfig,
    private spinner: SpinnerToggleService,
    private route: ActivatedRoute,
    private sdkService: SdkService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.username = params['username'];
    });
  }

  resendLink() {
    this.spinner.showSpinner();
    const resetPasswordDto: ResetPasswordDTO = { email: this.username };
    this.sdkService
      .sendResetPasswordLink(resetPasswordDto)
      .then((data) => {
        console.log(data);
        this.spinner.hideSpinner();
        this.router.navigate(['/']);
      })
      .catch((err) => {
        this.spinner.hideSpinner();
      });
  }
}
