import { map, tap } from 'rxjs/operators';
import { catchError, Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PaginatedResult } from '@avenews/agt-sdk';
import { HttpClient } from '@angular/common/http';
import { LoginConfig } from '../../auth-config';

@Injectable()
export class ExpiredTokenResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router,
    @Inject('loginConfig') public loginConfig: LoginConfig
    ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const environment = this.loginConfig.environment
    const resetToken = route.params['token'];
    return (this.http.get(`${environment.new_api_url}/api/user/reset-password/token/${resetToken}/expiration`) as Observable<any>).pipe(
      catchError((err) => {
        console.error(err);
        return of(undefined);
      }),
      tap((data) => {
        if (data.expired) {
          this.router.navigate(['/expired-token'], {
            queryParams: {
              username: data.username
            }
          });
        }
      })
    );
  }
}
