<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Agribusinesses'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-agribusinesses-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'FULL NAME' && col?.name !== 'STATUS' && col?.name !== 'PARTNER TYPE' && col?.name !== 'PAYMENT TERMS'"
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'FULL NAME'">
      <td>
        {{ agriName(item) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'PARTNER TYPE'">
      <td>
        {{ agriType(item) }}
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'PAYMENT TERMS'">
      <td *ngIf="item?.paymentTerms">{{ item?.paymentTerms | number : '1.0-2' }} Days</td>
      <td *ngIf="!item?.paymentTerms">-</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="{{ !item?.status ? '' : (item?.status | status) }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<frontend-change-status [agri]="agri"></frontend-change-status>
<av-link-user-modal [agri]="agri"></av-link-user-modal>
<av-agri-topup [agri]="agri"></av-agri-topup>
