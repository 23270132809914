import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { Agt4Agribusiness } from '@avenews/agt-sdk';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AgribusinessByIdResolver implements Resolve<Agt4Agribusiness> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Agt4Agribusiness> {
    const id = route.params['id'];
    return (this.http.get(`${environment.new_api_url}/api/agribusiness/${id}`) as Observable<Agt4Agribusiness>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
