import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { EMPTY, catchError } from 'rxjs';

@Component({
  selector: 'frontend-agent-view',
  templateUrl: './agent-view.component.html',
  styleUrls: ['./agent-view.component.scss']
})
export class AgentViewComponent implements OnInit {
  agent: any;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    },
    {
      name: 'Agents',
      route: 'agents'
    }
  ];
  selectedIndex = 0;
  infoTorender: any[];

  @ViewChild('tabGroup', {
    static: true
  })
  tabGroup: MatTabGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  ngOnInit(): void {
    this.route.data.subscribe(({ agent }) => {
      this.agent = agent;
      this.infoTorender = [
        {
          icon: 'briefcase',
          title: 'Business name',
          dto: this.agent?.policyRole?.organization?.businessName
        },
        {
          icon: 'user',
          title: 'First Name',
          dto: this.agent?.personalInformation?.firstName
        },
        {
          icon: 'user',
          title: 'Last Name',
          dto: this.agent?.personalInformation?.firstName
        },
        {
          icon: 'phone-call',
          title: 'Phone',
          dto: this.agent?.personalInformation?.phoneNumber
        },
        {
          icon: 'at-sign',
          title: 'Email',
          dto: this.agent?.personalInformation?.email
        }
      ];
    });
  }

  tabChanged(e: any) {
    this.selectedIndex = e.index;
  }
}
