import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  // Fallback when no prior route is matched
  // { path: 'not-found', component: NotFoundComponent },
  // { path: 'unauthorized', component: UnauthorizedPageComponent },
  // { path: 'home', component: LandingComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
