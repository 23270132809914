export class ListOptions {
  name!: string;
  service?: string;
  adminName?: string;
  financeSdk?: boolean;
  noPagination?: boolean;
  exportMethod!: string;
  actionMethod?: any;
  apiUrl?: string;
}
export class RadioConfig {
  label!: string;
  value!: string;
}
export type ActionType =
  | 'add'
  | 'bulkPayment'
  | 'edit'
  | 'cashback'
  | 'view'
  | 'addInput'
  | 'addCrop'
  | 'purchase'
  | 'export'
  | 'delete'
  | 'topup'
  | 'activation'
  | 'changeStatus'
  | 'LinkUserAcc'
  | 'updateTopup'
  | 'limitation'
  | 'status'
  | 'approve'
  | 'decline'
  | 'withdrawB';

export interface Action {
  label: string;
  type?: string;
  emittedValue?: string;
  action?: ActionType;
}
export interface Column {
  name: string;
  dtoName: string;
  filterName?: string;
  sortName?: string;
  alternative?: any;
  type?: string;
  actions?: Action[];
  pipe?: string;
  countryPhoneCode?: string;
  selected?: boolean;
  radio?: RadioConfig[];
  sticky?: boolean;
  noFilter?: boolean;
  noSort?: boolean;
  sideText?: string;
}
interface Filter {
  name: string;
  type: string;
  dtoName?: string;
  radio?: any[];
  dtoNames?: string[];
}

export interface ListConfig {
  customClass?: string;
  actions: Action[];
  columns: Column[];
  filters: Filter[];
  fixedColumns: string[];
  mainTitle: string;
  actionButtonLabel?: string;
  bulkActions?: Action[];
  bulkActionLabel?: string;
  emptyState: {
    title: string;
    subTitle?: string;
    button?: {
      label: string;
      action: any;
    };
    img: string;
  };
  addButton: {
    emptyStateLabel?: string;
    label: string;
    isDropdown?: boolean;
    dropdown?: Action[];
    emittedValue?: string;
  };
  bulkAddButton?: {
    label: string;
    isDropdown?: boolean;
    dropdown?: Action[];
    emittedValue?: string;
  };
  innateState?: {
    canSearch: boolean;
  };
}
export const defaultFarmerCols = [
  {
    name: 'NAME',
    dtoName: 'firstName',
    pipe: 'titleCase',
    type: 'text',
    selected: true,
    sticky: true
  },
  {
    name: 'PHONE',
    dtoName: 'phoneNumber',
    type: 'text',
    pipe: 'phoneCode',
    selected: true
  },
  {
    name: 'EMAIL',
    dtoName: 'email',
    type: 'text',
    selected: true
  },
  {
    name: 'COUNTRY',
    dtoName: 'country',
    pipe: 'titleCase',
    type: 'text',
    selected: true
  },
  {
    name: 'GROUP',
    dtoName: 'groups.0.name',
    pipe: 'titleCase',
    type: 'text',
    selected: true
  },
  { name: 'NATIONAL ID', dtoName: 'nationalId', type: 'text' },
  { name: 'BIRTHDATE', dtoName: 'dateOfBirth', pipe: 'date', type: 'date' },
  {
    name: 'GENDER',
    dtoName: 'gender',
    pipe: 'titleCase',
    type: 'radio',
    radio: [
      {
        label: 'Male',
        value: 'male'
      },
      {
        label: 'Female',
        value: 'female'
      }
    ]
  }
];
export const zambiaFarmerCols: any = [
  { name: 'PROVINCE', dtoName: 'province', pipe: 'titleCase', type: 'text' },
  { name: 'DISTRICT', dtoName: 'district', pipe: 'titleCase', type: 'text' },
  {
    name: 'VILLAGE',
    dtoName: 'village',
    pipe: 'titleCase',
    type: 'text',
    selected: true
  },
  {
    name: 'ACTIONS',
    actions: [{ label: 'View' }],
    selected: true,
    sticky: true
  }
];
export const ugandaFarmerCols: any = [
  { name: 'REGION', dtoName: 'region', pipe: 'titleCase', type: 'text' },
  { name: 'DISTRICT', dtoName: 'district', pipe: 'titleCase', type: 'text' },
  { name: 'SUBCOUNTY', dtoName: 'subCounty', pipe: 'titleCase', type: 'text' },
  { name: 'PARISH', dtoName: 'parish', pipe: 'titleCase', type: 'text' },
  {
    name: 'VILLAGE',
    dtoName: 'village',
    pipe: 'titleCase',
    type: 'text',
    selected: true
  },
  {
    name: 'ACTIONS',
    actions: [{ label: 'View' }],
    selected: true,
    sticky: true
  }
];
export const kenyaFarmerCols: any = [
  { name: 'SUBCOUNTY', dtoName: 'subCounty', pipe: 'titleCase', type: 'text' },
  { name: 'COUNTY', dtoName: 'county', pipe: 'titleCase', type: 'text' },
  { name: 'WARD', dtoName: 'ward', pipe: 'titleCase', type: 'text' },
  {
    name: 'VILLAGE',
    dtoName: 'village',
    pipe: 'titleCase',
    type: 'text',
    selected: true
  },
  {
    name: 'ACTIONS',
    actions: [{ label: 'View' }],
    selected: true,
    sticky: true
  }
];
