import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User, Agt4Period, Organization, Agt4Repayment } from '@avenews/agt-sdk';
import { BreadCrumbs } from '@avenews/shared-lib';
import { SideView } from '../../shared/components/base-side-view/base-side-view.component';

@Component({
  selector: 'frontend-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  topTitle: string;
  periods: Agt4Period[];
  repayments: Agt4Repayment[];
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    },
    {
      name: 'Organizations',
      route: 'organizations'
    }
  ];
  sideView: SideView[] = [
    {
      name: 'Business Name',
      dtoName: 'businessName'
    },
    {
      name: 'Registration Number',
      dtoName: 'registrationNumber'
    },
    {
      name: 'Owner',
      dtoName: 'owner'
    },
    {
      name: 'Type',
      dtoName: 'type'
    },
    {
      name: 'Business Address',
      dtoName: 'businessAddress'
    },
    {
      name: 'Partner',
      dtoName: 'partner'
    }
  ];
  user: User;
  org: Organization;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ user, org, repayments: { entities } }) => {
      this.repayments = entities;
      this.user = user;
      this.org = org;
      this.topTitle = this.org.businessName;
    });
  }

  getPeriods(periods: Agt4Period[]) {
    this.periods = periods;
  }
}
