import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { ModalService } from '../../../services';

@Component({
  selector: 'base-modal-header',
  templateUrl: './base-modal-header.html',
  styleUrls: ['./base-modal-header.scss']
})
export class BaseModalHeader implements OnInit {
  @Input() customClass: string;
  @Input() manualClose: boolean;
  @Input() header: string;
  @Input() bordered = true;
  @Output() closed = new EventEmitter();
  constructor(private modalService: ModalService) {}
  ngOnInit(): void {}
  close() {
    if (this.manualClose) {
      this.closed.emit();
      return;
    }
    this.modalService.close();
  }
}
