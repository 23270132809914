import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'frontend-charts-dashboard',
  templateUrl: './charts-dashboard.component.html',
  styleUrls: ['./charts-dashboard.component.scss']
})
export class ChartsDashboardComponent implements OnInit {
  datas = [];
  options: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  };
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // this.route.data.subscribe(({ charts }) => {
    //   const titleParser = {
    //     commidty: 'commodity',
    //     'company and managment': 'company and management'
    //   };
    //   this.datas = Object.keys(charts).map((data) => {
    //     if (charts[data]?.label) {
    //       return {
    //         data: {
    //           labels: charts[data]?.label,
    //           datasets: [
    //             {
    //               data: charts[data]?.count
    //             }
    //           ]
    //         },
    //         title: titleParser[data] || data,
    //         type: 'pie',
    //         options: this.options
    //       };
    //     } else {
    //       const opts: ChartConfiguration<'line'>['data'] = {
    //         labels: charts[data]?.score,
    //         datasets: [
    //           {
    //             data: charts[data]?.score,
    //             label: 'Total score'
    //           }
    //         ]
    //       };
    //       const optis: ChartOptions<'line'> = {};
    //       const labels = ['0-10', '10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90', '90-100'];
    //       // rangeData equals to an array of labels.length elements, each element is the number of times the data is in range of the label
    //       const rangeData = labels.map((label) => {
    //         const range = label.split('-');
    //         return charts[data]?.score.filter((score) => score >= range[0] && score <= range[1]).length;
    //       });
    //       return {
    //         data: {
    //           labels,
    //           datasets: [
    //             {
    //               label: 'Total score',
    //               data: rangeData,
    //               yAxisID: 'y-axis-1'
    //             }
    //           ]
    //         },
    //         title: titleParser[data] || data,
    //         type: 'line',
    //         options: {
    //           ...optis
    //         }
    //       };
    //     }
    //   });
    // });
  }
}
