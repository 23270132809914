import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneEmail' })
export class PhoneEmailPipe implements PipeTransform {
  transform(phone: string, email: string, code: string) {
    let res = '';
    let emailRes = '';
    let phoneRes = '';
    if (email) {
      emailRes += email;
    }
    if (phone) {
      if (code) {
        if (phone.includes('+')) {
          phoneRes += phone;
        } else if (code.toString().includes('+')) {
          phoneRes += code + phone;
        } else {
          phoneRes += '+' + code + phone;
        }
      } else {
        phoneRes += phone;
      }
    }
    if (emailRes) {
      res += emailRes;
    }
    if (res) {
      if (phoneRes) {
        res += ' / ' + phoneRes;
      }
    } else {
      res += phoneRes;
    }
    return res || '-';
  }
}
