import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export function MinDateValidator(minDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validDate = false;
    try {
      if (control.value) {
        const date = new Date(control.value);
        const min = control?.parent?.get(minDate).value;
        validDate = date?.getTime() >= new Date(min)?.getTime();
      } else {
        validDate = true;
      }
    } catch (err) {
      console.error(err);
    }
    return validDate || control.value == null ? null : { minDate: `Date must be after ${moment(new Date()).format('DD/MM/YYYY')}` };
  };
}
