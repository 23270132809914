<div class="modal-box" id="addDealWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>{{ isEdit ? 'Edit deal' : 'New deal' }}</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="content-header">
        {{ user?.personalInformation?.firstName + ' ' + user?.personalInformation?.lastName | titlecase }} - Deal
      </p>
      <div (keyup.enter)="submit()" [formGroup]="dealForm">
        <div class="form-pair">
          <app-base-form-template
            labelName="Deal name"
            inputType="text"
            inputName="default"
            controlName="name"
            [group]="dealForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Status"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="status"
            [options]="statuses"
            [noSearch]="false"
            [group]="dealForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <app-base-form-template
            labelName="Deal type"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="type"
            [placeholderName]="'Choose type'"
            [options]="dealType"
            [noSearch]="true"
            [group]="dealForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Approved Goods"
            inputType="text"
            inputName="default"
            controlName="approvedGoods"
            [group]="dealForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Start date"
            inputType="date"
            inputName="date"
            controlName="startDate"
            [group]="dealForm"
            [size]="6"
            placeholderName="DD/MM/YYYY"
          >
          </app-base-form-template>
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="End date"
            inputType="date"
            inputName="date"
            controlName="endDate"
            [group]="dealForm"
            [size]="6"
            placeholderName="DD/MM/YYYY"
          >
          </app-base-form-template>
        </div>
        <hr />

        <p class="sections-title">Disbursement</p>
        <div class="form-pair">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Min financing amount"
            inputType="number"
            inputName="default"
            controlName="minimumFinancingAmount"
            [group]="dealForm"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Max financing amount"
            inputType="number"
            inputName="default"
            controlName="maximumFinancingAmount"
            [group]="dealForm"
            [size]="6"
          >
            <span append #append>KES</span>
          </app-base-form-template>
        </div>
        <hr />
        <p class="sections-title">Period</p>
        <div class="form-pair">
          <app-base-form-template
            labelName="Period type"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="periodType"
            [placeholderName]="'Choose type'"
            [options]="periodType"
            [noSearch]="true"
            [group]="dealForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Max duration"
            bindLabel="viewValue"
            bindValue="value"
            inputType="number"
            inputName="default"
            controlName="maxDuration"
            sideText="In days"
            [group]="dealForm"
            [min]="0"
            [size]="6"
          >
          </app-base-form-template>
        </div>

        <hr />
        <p class="sections-title">Repayments</p>

        <div>
          <div class="form-pair">
            <app-base-form-template
              *ngIf="
                (dealForm?.get('periodType').value !== 'recurring' && dealForm?.get('type').value === 'general' && !isEdit) ||
                  (dealForm?.get('periodType').value !== 'recurring' && deal && deal.type === 'general');
                else other
              "
              labelName="Repayment type"
              inputType="text"
              inputName="default"
              controlName="repaymentType"
              [group]="dealForm"
              [size]="6"
            >
            </app-base-form-template>
            <ng-template #other>
              <app-base-form-template
                labelName="Repayment type"
                bindLabel="viewValue"
                bindValue="value"
                inputType="select"
                inputName="select"
                controlName="repaymentType"
                [options]="repaymentType"
                [noSearch]="true"
                [group]="dealForm"
                [size]="6"
              >
              </app-base-form-template>
            </ng-template>
            <ng-container
              *ngIf="
                (dealForm?.get('repaymentType').value === 'single' && !isEdit) ||
                dealForm?.get('type')?.value === 'general' ||
                (deal && deal.repaymentInfo.repaymentMode === 'single') ||
                deal?.type === 'general' ||
                dealForm.get('repaymentType').value === 'multiFixed'
              "
            >
              <app-base-form-template
                [errorMessages]="errorMessages"
                labelName="Max repayment amount"
                inputType="number"
                inputName="default"
                controlName="maxRepaymentAmount"
                [group]="dealForm"
                [size]="6"
              >
                <span append #append>KES</span>
              </app-base-form-template>
            </ng-container>
          </div>

          <div
            *ngIf="
              (dealForm.get('repaymentType').value === 'single' && !isEdit) ||
              (dealForm?.get('type').value === 'general' && !isEdit) ||
              (deal && deal.repaymentInfo.repaymentMode === 'single') ||
              deal?.type === 'general'
            "
            class="form-pair"
          >
            <app-base-form-template
              labelName="Markup multiplier"
              inputType="number"
              inputName="default"
              controlName="feeMultiplier"
              [group]="dealForm"
              [size]="6"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Markup amount"
              inputType="number"
              inputName="default"
              controlName="feeAmount"
              [group]="dealForm"
              [size]="6"
            >
              <span append #append>KES</span>
            </app-base-form-template>
          </div>
          <ng-container *ngIf="dealForm.get('repaymentType').value === 'multiFixed' && dealForm?.get('periodType').value === 'recurring'">
            <div *ngIf="multiRepamentsArray?.length" class="multi-repayments">
              <div *ngFor="let item of multiRepamentsArray.controls; let prodIndex = index" class="row-form">
                <div class="num flex">#{{ prodIndex + 1 }}</div>
                <app-base-form-template
                  labelName="Days from start"
                  inputType="number"
                  inputName="default"
                  controlName="duration"
                  [strictMode]="false"
                  [group]="$any(item)"
                  [size]="4"
                  [errorMessages]="errorMessages"
                >
                  <span append #append>KES</span>
                </app-base-form-template>
                <app-base-form-template
                  labelName="Amount to repay"
                  inputType="number"
                  inputName="default"
                  controlName="amount"
                  [strictMode]="false"
                  [group]="$any(item)"
                  [size]="4"
                >
                  <span append #append>KES</span>
                </app-base-form-template>
                <app-base-form-template
                  labelName="In percentages"
                  inputType="number"
                  inputName="default"
                  controlName="percentage"
                  [strictMode]="false"
                  [group]="$any(item)"
                  [size]="3"
                  [isDisabled]="true"
                  [rOnly]="true"
                >
                  <span append #append class="percentage">%</span>
                </app-base-form-template>
                <div class="flex" [ngClass]="{ lighten: prodIndex === 0 || prodIndex === 1 }">
                  <img src="../../../assets/images/svgs/red-trash.svg" (click)="removePayment(prodIndex)" />
                </div>
              </div>
              <p *ngIf="errorDays" class="error">Days from start of last repayment should match the Max duration</p>
              <p *ngIf="!ascending" class="error">Repayments durations should be in an ascending order</p>
              <p *ngIf="equalDays" class="error">Repayments can’t have the same duration</p>
              <p *ngIf="maxAmountError" class="error">Repayments should be equal to Max repayment amount</p>
              <button class="add-btn" (click)="addPayment()">Add payment +</button>
            </div>
          </ng-container>

          <div
            *ngIf="(dealForm.get('repaymentType').value === 'multi' && !isEdit) || (deal && deal.repaymentInfo.repaymentMode === 'multi')"
            class="form-pair"
          >
            <div class="repayments">
              <app-base-form-template
                labelName="Max installments allowed"
                bindLabel="viewValue"
                bindValue="value"
                inputType="select"
                inputName="select"
                controlName="maxInstallmentsAllowed"
                [options]="maxRepyments"
                [noSearch]="true"
                [group]="dealForm"
                [size]="12"
              >
              </app-base-form-template>
              <p class="small-text">Repayments will happen every 2 weeks</p>
            </div>

            <app-base-form-template
              labelName="Rate per week"
              inputType="number"
              inputName="default"
              controlName="ratePerWeek"
              [group]="dealForm"
              [size]="6"
            >
              <span append #append>KES</span>
            </app-base-form-template>
          </div>
        </div>

        <div *ngIf="dedicatedTypeMode">
          <hr />
          <p class="sections-title">Supplier</p>
          <div class="form-pair supplier-sec">
            <div (click)="openModal()" class="supplier-section" [ngClass]="!linkedUserAccount ? 'full-size' : 'half-size'">
              <label>Supplier</label>
              <input
                type="select"
                placeholder="{{
                  !linkedUserAccount ? 'Select supplier' : (linkedUserAccount.personalInformation | beneficiaryName | titlecase)
                }}"
              />
              <span class="ng-arrow-wrapper"><span class="ng-arrow"></span></span>
            </div>
            <div *ngIf="linkedUserAccount" class="company-name">
              {{ linkedUserAccount?.companyInformation.companyName | titlecase }}
            </div>
          </div>
          <p class="error-linkacc" *ngIf="!isAccountLinked && !linkedUserAccount">This filed is required</p>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" *ngIf="!isEdit" (click)="submit()">Create</button>
        <button class="btn btn-primary" *ngIf="isEdit" (click)="Edit()">Save</button>
      </div>
    </div>
  </div>
</div>

<link-user-to-deal-modal (next)="next($event)" [users]="users"></link-user-to-deal-modal>
