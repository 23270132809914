import { map } from 'rxjs/operators';
import { catchError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { PaginatedResult } from '@avenews/agt-sdk';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class RolesResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve() {
    return (this.http.get(`${environment.new_api_url}/api/policy-role/mine/50/1/_id/desc`) as Observable<PaginatedResult<any>>).pipe(
      map((data) => {
        return data.entities.find((role) => role.name === 'avenews-agent');
      }),
      catchError((err) => {
        console.error(err);
        return of(undefined);
      })
    );
  }
}
