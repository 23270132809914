import { FormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideControl'
})
export class HideControlPipe implements PipeTransform {
  constructor() {}
  transform(control: any, group: FormGroup) {
    /**
     *     condition: {
        controlName: 'businessType',
        value: 'other',
        option: 'contains'
      } 
      condition looks like this
     */
    if (control?.condition) {
      const { controlName, value, option } = control.condition;
      const currentControl = group.get(controlName);
      if (currentControl) {
        const currentValue = currentControl.value;
        if (option === 'contains') {
          return !currentValue.includes(value);
        }
        if (option === 'equals') {
          return currentValue !== value;
        }
      }
    }

    return false;
  }
}
