import { PaginatedResult, User } from '@avenews/agt-sdk';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { SdkService } from '@avenews/shared-lib';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { from, catchError, EMPTY, zip, Observable } from 'rxjs';
import { Transaction } from '@avenews/base-types';

@Injectable()
export class DashboardCounterResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient, private router: Router) {}

  resolve() {
    const counter = {};
    return zip(
      this.http.get(`${environment.new_api_url}/api/transaction/1/1/_id/desc`) as Observable<PaginatedResult<Transaction>>,
      this.http.get(`${environment.new_api_url}/api/user/1/1/_id/desc`) as Observable<PaginatedResult<User>>,
      this.http.get(
        `${environment.new_api_url}/api/transaction/1/1/_id/desc?filters=[{%22value%22:%22true%22,%22column%22:%22internal%22,%22option%22:%22equal%22},{%22value%22:%22declined%22,%22column%22:%22status%22,%22option%22:%22notEqual%22},{%22value%22:%22approved%22,%22column%22:%22status%22,%22option%22:%22notEqual%22}]`
      ) as Observable<PaginatedResult<Transaction>>,
      this.http.get(
        `${environment.new_api_url}/api/transaction/1/1/_id/desc?filters=[{%22value%22:%22TOPUP%22,%22column%22:%22transactionId%22,%22option%22:%22contains%22}]`
      ) as Observable<PaginatedResult<Transaction>>,
      this.http.get(
        `${environment.new_api_url}/api/transaction/1/1/_id/desc?filters=[{%22value%22:%22KES%22,%22column%22:%22transactionId%22,%22option%22:%22contains%22}]`
      ) as Observable<PaginatedResult<Transaction>>
    ).pipe(
      map((data) => {
        counter['transactions'] = data[0].totalCount;
        counter['users'] = data[1].totalCount;
        counter['internalTransactions'] = data[2].totalCount;
        counter['topupTransactions'] = data[3].totalCount;
        counter['mobileTransactions'] = data[4].totalCount;
        return counter;
      }),
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
