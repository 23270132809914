<div class="text-slate-900 rounded-lg bg-white p-4 mt-2">
  <div *ngIf="!mlScoreData?.decision?.decisionTree; else innateState" class="empty">
    <div>
      <img src="../../../assets/images/empty-states/empty-search.svg" alt="" />
    </div>
    <div class="no-note">No details added yet</div>
    <button
      class="bttn btn-primary"
      (click)="openModal()"
      #tooltip="matTooltip"
      matTooltip="Add details about the applicant to calculate the maximum loan amount"
    >
      Add details
    </button>
  </div>
  <ng-template #innateState>
    <!-- <div>
      <button
        class="bttn btn-primary"
        (click)="openModal()"
        #tooltip="matTooltip"
        matTooltip="Add details about the applicant to calculate the maximum loan amount"
      >
        Add details
      </button>
    </div> -->
    <div class="offer-container">
      <div class="w-100 heading d-flex justify-between">
        <div>
          <h3>Decision Tree</h3>
        </div>
        <div (click)="openModal()" class="click pen">
          <i-feather name="edit-2"></i-feather>
        </div>
      </div>
      <div class="decision-cards d-flex w-100">
        <div [ngClass]="{ declined: loan?.decisionTreeStatus?.status === 'decline' }" class="decision-card">
          <p *ngIf="loan?.decisionTreeStatus?.status === 'decline'" class="status status-red">Declined is recommended</p>
          <ng-container *ngFor="let item of decisionTree">
            <div *ngIf="item?.show" [ngClass]="{ bold: item?.key === 'Maximum finance amount' }" class="offer-item">
              <div class="key">{{ item?.key }}</div>
              <div class="between"></div>
              <div class="value">{{ item?.value }} {{ item?.extra }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="documents-container mt-5 border-t border-gray-300">
      <div class="mt-4 w-100 heading d-flex justify-between">
        <div>
          <h3>Needed documents</h3>
        </div>
      </div>
      <div>
        <h4 class="text-blue-500 text-lg mb-3">Ask for the following documents to complete information</h4>
        <div
          *ngFor="let document of uniqueDocuments[mlScoreData?.decision?.decisionTree?.type]; let i = index"
          class="unique-documents mb-3"
        >
          <p class="flex items-center justify-start mb-2"><i-feather name="file-text"></i-feather> {{ document }}</p>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="contents" fadeSidebar modalId="addDecisionModal">
    <div class="modal-box" id="addDecisionModal">
      <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
        <div class="modal-box-heading flex justify-between">
          <p class="text-4xl font-bold">Calculate maximum loan amount</p>
          <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
        </div>
        <div [formGroup]="form" class="modal-box-content">
          <h3 class="text-blue-500">Decision tree</h3>
          <div class="form-pair flex-wrap d-flex w-100 mb-4 border-b border-gray-300">
            <app-base-form-template
              labelName="Type"
              inputType="select"
              inputName="select"
              controlName="type"
              bindLabel="viewValue"
              bindValue="value"
              [group]="form"
              [size]="6"
              [options]="types"
            >
            </app-base-form-template>
            <app-base-form-template
              [size]="6"
              labelName="Monthly Liquidity"
              [group]="form"
              inputName="default"
              inputType="number"
              controlName="monthlyLiquidity"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <app-base-form-template
              *ngIf="user?.policyRole?.name !== 'avenews-agent'"
              [size]="6"
              [rOnly]="true"
              labelName="TD Score"
              [group]="form"
              inputName="default"
              inputType="number"
              controlName="tdScore"
            >
              <span #append append>%</span>
            </app-base-form-template>
            <app-base-form-template
              *ngIf="user?.policyRole?.name !== 'avenews-agent'"
              [size]="6"
              [rOnly]="true"
              labelName="ML Score"
              [group]="form"
              inputName="default"
              inputType="number"
              controlName="mlScore"
            >
              <span #append append>%</span>
            </app-base-form-template>
            <app-base-form-template
              [wrap]="true"
              *ngFor="let control of chosenType"
              [labelName]="control?.label"
              [inputType]="control?.inputType"
              [inputName]="control?.inputName"
              [controlName]="control?.controlName"
              bindLabel="viewValue"
              bindValue="value"
              [group]="form"
              [size]="control?.size"
              [options]="control?.options"
            >
              <span *ngIf="control?.append" #append append>{{ control?.append }}</span>
            </app-base-form-template>
          </div>
          <div class="border-b border-gray-300" *ngIf="form?.get('type')?.value && uniqueDocuments[form?.get('type')?.value]?.length">
            <h4 class="text-blue-500 text-lg mb-3">Ask for the following documents to complete information</h4>
            <div *ngFor="let document of uniqueDocuments[form?.get('type')?.value]; let i = index" class="unique-documents mb-3">
              <p class="flex items-center justify-start mb-2"><i-feather name="file-text"></i-feather> {{ document }}</p>
            </div>
          </div>
          <div [ngClass]="loan?.decisionTreeStatus?.status" class="application-status mb-4 mt-4" *ngIf="form?.get('type')?.value">
            <div class="status-text">{{ loan?.decisionTreeStatus?.name }}</div>
          </div>
          <div class="modal-footer flex justify-between">
            <button class="bttn btn-actions bg-white border border-solid border-gray-200" (click)="save()">Save</button>
            <button class="bttn-primary" (click)="calculate()">Calculate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
