<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'New applications'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-approved-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (sendFilters)="filter($event)"
  (changeP)="pageChanged($event)"
  (sort)="sortData($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container *ngIf="col?.name !== 'APPLICANT NAME' && col?.name !== 'REQUESTED AMOUNT'" [ngTemplateOutlet]="td"></ng-container>
    <ng-container *ngIf="col?.name === 'APPLICANT NAME'">
      <td>{{ item?.applicantFirstName }} {{ item?.applicantLastName }}</td></ng-container
    >
    <ng-container *ngIf="col?.name === 'REQUESTED AMOUNT'">
      <td>{{ totalAmount(item) | number: '1.0-2' }} KES</td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<frontend-change-application-status [application]="application"></frontend-change-application-status>
