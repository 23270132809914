<div class="user-view-wrapper">
  <frontend-base-side-view
    [topTitle]="topTitle"
    [action]="false"
    [addDetails]="true"
    additionalDetails="Application details"
    [sideViewAddDetails]="sideViewAddDetails"
    [sideView]="sideView"
    [entity]="application"
  >
    <ng-template #fieldValue let-item="item" let-entity="entity" let-p="template">
      <ng-container
        [ngTemplateOutlet]="p"
        *ngIf="
          item?.name !== 'STATUS' &&
          item?.name !== 'first Name' &&
          item?.name !== 'last Name' &&
          item?.name !== 'phone Number' &&
          item?.name !== 'REQUESTED AMOUNT'
        "
      >
      </ng-container>
      <ng-container *ngIf="item?.name === 'STATUS'">
        <div class="loan-status status-{{ entity?.status }}">
          <p>
            {{ statusGrabber(entity?.status) }}
          </p>
        </div></ng-container
      >
      <ng-container *ngIf="item?.name === 'first Name'">
        <div>
          {{ entity?.applicantFirstName }}
        </div></ng-container
      >
      <ng-container *ngIf="item?.name === 'last Name'">
        <div>
          {{ entity?.applicantLastName }}
        </div></ng-container
      >
      <ng-container *ngIf="item?.name === 'REQUESTED AMOUNT'">
        <div>
          {{ totalPrice | number: '1.0-2' }}
        </div></ng-container
      >
    </ng-template>
  </frontend-base-side-view>

  <mat-tab-group [disableRipple]="true" id="application-view-tabs">
    <mat-tab color="#152C5B" label="Main data">
      <frontend-main-data></frontend-main-data>
    </mat-tab>
    <mat-tab color="#152C5B" label="Risk"></mat-tab>
    <mat-tab color="#152C5B" label="Products">
      <frontend-products [products]="application.products"></frontend-products>
    </mat-tab>
    <mat-tab color="#152C5B" label="Documents">
      <frontend-documents (openDecModal)="openDecModal($event)" (openModal)="openModal($event)"></frontend-documents>
    </mat-tab>
  </mat-tab-group>

  <av-delete-modal
    *ngIf="showModale"
    id="{{ item.id }}"
    path="loan-application"
    url="finance"
    loanId="{{ application._id }}"
    fileName="{{ item.fileName }}"
    (answer)="remover($event)"
  ></av-delete-modal>

  <frontend-decline-modal
    *ngIf="showDecModal"
    loanId="{{ application._id }}"
    url="finance"
    path="loan-application"
    applicationName="{{ this.application.applicantFirstName + ' ' + this.application.applicantLastName }}"
    fileName="{{ item.fileName }}"
    id="{{ item.id }}"
    status="{{ item.status }}"
    (done)="addDeclineReason($event)"
  >
  </frontend-decline-modal>
</div>
