<div id="declineModalTD">
  <div id="myModal" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h1>{{ title }}</h1>
        <img src="../../../../assets/images/svgs/close.svg" (click)="close()" />
      </div>
      <div class="modal-body">
        <p *ngIf="fileName">
          <span>{{ fileName }}</span>
        </p>
        <span>Reason *</span>
        <form [formGroup]="declineReasonForm">
          <div class="input-container">
            <textarea formControlName="declineRes" id="w3review" name="w3review" rows="6" cols="45"></textarea>
          </div>
          <button (click)="onHandleDecline()">Decline</button>
        </form>
      </div>
    </div>
  </div>
</div>
