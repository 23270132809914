import { SpinnerToggleService } from '@avenews/shared-lib';
import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseValidationComponent } from './../../shared/components/base-validation/base-validation.component';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { months } from '../dates';
import { AlertService } from '@avenews/alerts';
import { LoanDecisionDTO, DocumentNeeded, DocumentStatus, Statement, Lpo } from './dto';

@Component({
  selector: 'frontend-main-data',
  templateUrl: './main-data.component.html',
  styleUrls: ['./main-data.component.scss']
})
export class MainDataComponent extends BaseValidationComponent implements OnInit {
  agricheckRadio = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  acceptedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  prettyAcceptedTypes = ['pdf', 'jpg', 'jpeg', 'png', '.doc', 'xlsx', 'xls'];
  environment = environment;
  application: any;
  mainData: any;
  years: any[] = [];
  docHelper = {
    proofOfAddress: 'proofOfAddress',
    personalFinances: 'personalFinances',
    businessFinances: 'businessFinances',
    businessTransactions: 'businessTransactions',
    entityRegistration: 'entityRegistration'
  };
  months = months;
  applicationForm: FormGroup;
  errorMessages = {
    isAgricheck: [{ type: '', message: '' }]
  };
  neededDocument = [
    {
      label: '1. Personal finances',
      value: true,
      controlName: 'personalFinances',
      id: 'personalFinances'
    },
    {
      label: '2. Business finances',
      value: true,
      controlName: 'businessFinances',
      id: 'businessFinances'
    },
    {
      label: '3. Business transactions',
      value: true,
      controlName: 'businessTransactions',
      id: 'businessTransactions'
    },
    {
      label: '4. Proof of address',
      value: true,
      controlName: 'proofOfAddress',
      id: 'clientSubmitted'
    },
    {
      label: '5. Entity registration',
      value: true,
      controlName: 'entityRegistration',
      id: 'entityRegistration'
    }
  ];

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private router: Router,
    private http: HttpClient,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {
    super();
    this.applicationForm = this.fb.group({
      dealMargin: [undefined],
      dealROI: [undefined],
      agriCheck: [undefined],
      mpesaStatement: this.fb.array([this.statementGroup]),
      bankStatement: this.fb.array([this.statementGroup]),
      lpoVolume: this.fb.array([this.lpoGroup]),
      beurreCheck: [undefined],
      proofOfAddress: this.fb.array([this.fb.control(undefined), this.fb.control(undefined)]),
      personalFinances: this.fb.array([this.fb.control(undefined), this.fb.control(undefined)]),
      businessFinances: this.fb.array([this.fb.control(undefined), this.fb.control(undefined)]),
      businessTransactions: this.fb.array([this.fb.control(undefined), this.fb.control(undefined)]),
      entityRegistration: this.fb.array([this.fb.control(undefined), this.fb.control(undefined)])
    });
    this.formInput = this.applicationForm;
  }

  ngOnInit(): void {
    const todayYear = new Date().getFullYear();
    for (var i = todayYear; i >= todayYear - 50; i--) {
      this.years.push({ viewValue: i, value: i });
    }
    this.route.data.subscribe((data) => {
      this.application = data['application'];
      this.applicationForm.patchValue({
        dealMargin: this.application.dealMargin,
        dealROI: this.application.dealROI
      });
      this.mainData = data['application'];
      if (this.mainData) {
        this.applicationForm.patchValue({
          agriCheck: this.mainData?.agricheck,
          beurreCheck: this.mainData.beurreCheck
        });
        this.handleArrays();
        this.handleDocuments();
      }
    });
  }

  addMpesa() {
    this.getMpesaArray().push(this.statementGroup);
  }
  addBank() {
    this.getBankArray().push(this.statementGroup);
  }
  addLpo() {
    this.lpoArray.push(this.lpoGroup);
  }
  getMpesaArray() {
    return this.applicationForm.get('mpesaStatement') as FormArray;
  }
  getBankArray() {
    return this.applicationForm.get('bankStatement') as FormArray;
  }
  calcAvg(formArray: FormArray, controlName: string) {
    let total = 0;
    formArray.controls.forEach((control) => {
      if (control.get(controlName).value && !isNaN(control.get(controlName).value)) {
        total += +control.get(controlName).value;
      }
    });
    return formArray.length ? total / formArray.length : 0;
  }
  calcAction(action: 'avg' | 'max' | 'min') {
    if (this.lpoArray.length >= 1) {
      switch (action) {
        case 'avg':
          return this.calcAvg(this.lpoArray, 'amount');
        case 'max':
          return this.lpoArray.controls
            .reduce((acc, curr) => {
              return acc.get('amount').value > curr.get('amount').value ? acc : curr;
            })
            .get('amount').value;
        case 'min':
          return this.lpoArray.controls
            .reduce((acc, curr) => {
              return acc.get('amount').value < curr.get('amount').value ? acc : curr;
            })
            .get('amount').value;
      }
    }
    return 0;
  }
  deleteRow(formArray: FormArray, i: number) {
    formArray.removeAt(i);
    // if needed in the future
    // if (formArray.length > 1) {
    //   formArray.removeAt(i);
    // } else {
    //   formArray.controls[0].reset();
    // }
  }
  uploadDoc(event: any) {
    let file: File = event.target.files[0];
    if (!this.validateFile(file)) {
      this.alertService.showAlert(`Maximum file size is 15MB and it should be ${this.prettyAcceptedTypes.join(', ')}`, 'danger');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File = reader.result as string;
      const doc = {
        file: base64File,
        fileName: file.name,
        mime: file.type,
        type: 'loanSummary'
      };
      this.applicationForm.get('beurreCheck').setValue(doc);
    };
  }
  openFile(data: any) {
    if (data.mime.includes('vnd') || data.mime?.includes('msword')) {
      // download the file because its not openable in browser
      const a = document.createElement('a');
      a.href = data.file;
      a.download = data.fileName;
      a.click();
      return;
    }
    var w = window.open('about:blank');
    w.document.title = data.fileName;
    setTimeout(function () {
      //FireFox seems to require a setTimeout for this to work.
      const iframe = w.document.body.appendChild(w.document.createElement('iframe'));
      iframe.src = data.file;
      iframe.width = '100%';
      iframe.height = '100%';
    }, 0);
  }
  save() {
    this.validateForm(this.applicationForm);
    if (this.applicationForm.valid) {
      this.spinner.showSpinner();
      let docs: any[] = [];
      Object.keys(this.docHelper).forEach((key) => {
        const tuple = this.applicationForm.get(key).value;
        docs.push({
          documentType: this.docHelper[key],
          approved: tuple?.[1] ,
          submitted: Boolean(tuple?.[0])
        });
      });
      const dto: any = {
        agricheck: this.applicationForm.get('agriCheck').value,
        bankStatment: this.getBankArray().value,
        mpesaStatment: this.getMpesaArray().value,
        lpoVolume: this.lpoArray.value,
        beurreCheck: this.applicationForm.get('beurreCheck').value,
        documentsNeeded: docs,
        dealROI: this.applicationForm.get('dealROI').value,
        dealMargin: this.applicationForm.get('dealMargin').value
      };
      this.http
        .put(`${this.environment.new_api_url}/api/loan-application/admin/${this.application?._id}`, dto)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.alertService.showAlert('Application updated successfully', 'success');
          this.router.navigateByUrl(this.router.url).then(() => {
            document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
          });
        });
    } else {
      document.querySelector('.ng-invalid').scrollIntoView({ behavior: 'smooth' });
    }
  }
  get statementGroup(): FormGroup {
    return this.fb.group({
      name: [undefined, Validators.required],
      overdraft: [undefined, Validators.required],
      bet: [undefined, Validators.required],
      loan: [undefined, Validators.required]
    });
  }

  get lpoArray() {
    return this.applicationForm.get('lpoVolume') as FormArray;
  }
  private get lpoGroup(): FormGroup {
    return this.fb.group({
      year: [undefined],
      month: [undefined],
      amount: [0]
    });
  }
  private validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 15e6;
  }
  private handleDocuments() {
    this.mainData.documentsNeeded?.forEach((data) => {
      const i = Object.values(this.docHelper).indexOf(data.documentType);
      if (i !== -1) {
        this.applicationForm.get(Object.keys(this.docHelper)[i]).setValue([data?.submitted, data?.approved ? true : false]);
      }
    });
  }
  private handleArrays() {
    this.getMpesaArray().clear();
    this.mainData.mpesaStatment?.forEach((statement) => {
      this.addStatement(statement, this.getMpesaArray());
    });
    if (this.getMpesaArray().length === 0) {
      this.addMpesa();
    }
    this.getBankArray().clear();
    this.mainData.bankStatment?.forEach((statement) => {
      this.addStatement(statement, this.getBankArray());
    });
    if (this.getBankArray().length === 0) {
      this.addBank();
    }
    this.lpoArray.clear();
    this.mainData.lpoVolume.forEach((lpo) => {
      this.addVolume(lpo);
    });
    if (this.lpoArray.length === 0) {
      this.addLpo();
    }
  }
  private addVolume(lpo: Lpo) {
    this.lpoArray.push(
      this.fb.group({
        year: [lpo.year],
        month: [lpo.month],
        amount: [lpo.amount]
      })
    );
  }
  private addStatement(statement: Statement, formArray: FormArray) {
    formArray.push(
      this.fb.group({
        name: [statement.name, Validators.required],
        overdraft: [statement.overdraft, Validators.required],
        bet: [statement.bet, Validators.required],
        loan: [statement.loan, Validators.required]
      })
    );
  }
}
