<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'deal-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'STATUS' && col?.name !== 'AMOUNT REPAID' && col?.name !== 'START DATE'"
      [ngTemplateOutlet]="td"
    ></ng-container>

    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="status status-{{ item?.status }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'START DATE'">
      <td>
        {{ (startDate(item) | date : 'dd/MM/yyyy') || '-' }}
      </td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'AMOUNT REPAID'">
      <td>
        {{
          item?.requestedAmount
            ? (item?.requestedAmount * item?.feeMultiplier - item?.repaymentAmount || 0 | number : '1.0-2') + 'KES'
            : '0 KES'
        }}
      </td>
    </ng-container>
  </ng-template>
</agt-nosdk-list>
<frontend-new-period
  [(isOpen)]="isPeriodOpen"
  [period]="period"
  [isEdit]="isEdit"
  fadeSidebar
  modalId="addPeriodWizard"
></frontend-new-period>
<repayments-modal fadeSidebar modalId="repaymentModal" [period]="period"></repayments-modal>
<app-change-period-status [(isOpen)]="isStatusOpen" [period]="period"></app-change-period-status>
