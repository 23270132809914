import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { SdkService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { PaginatedResult, User } from '@avenews/agt-sdk';

@Injectable()
export class UsersResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: import('@angular/router').ActivatedRouteSnapshot) {
    const isExistOrg = route.data['isExistOrg'];
    let url = `/api/user/10/1/_id/desc?filters=[{%22value%22:%22admin%22,%22column%22:%22roles%22,%22option%22:%22notEqual%22},{%22value%22:%22admin%22,%22column%22:%22roles%22,%22option%22:%22notEqual%22}]`;
    if (isExistOrg) {
      url = `/api/user/10/1/_id/desc?linkedToOrg=false`;
    }
    return (this.http.get(`${environment.new_api_url}${url}`) as Observable<PaginatedResult<User>>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
