import { AlertService } from '@avenews/alerts';
import { Component, Injector, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Account, PaymentService, User } from '@avenews/agt-sdk';
import { Action, BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { catchError, EMPTY, firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'frontend-origanizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss']
})
export class OrganizationsListComponent extends ListNoSdkComponent<any> implements OnInit {
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  accounts: any[] = [];
  users: any[] = [];
  currentOrg: any;
  currentUser: any;
  currentTopupUser: any;

  constructor(injector: Injector, private alertService: AlertService, private router: Router, private route: ActivatedRoute) {
    super(injector, {
      noPagination: false,
      name: 'getAll',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/organization/accounts`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'organizations';
  }

  ngOnInit(): void {
    // this.http.get(`${environment.new_api_url}/api/user/12310/1/_id/desc?linkedToOrg=true`).subscribe((data: any) => {
    //   this.users = data?.entities;
    //   this.list.forEach((org: any) => {
    //     org['emails'] = this.emails(org);
    //   });
    // });
    super.init();
    this.postPagination();
    this.paginated.subscribe((val: string) => {
      this.postPagination();
    });
    this.listConfig = {
      emptyState: {
        title: 'No Organizations yet',
        img: 'assets/images/empty-states/organization.png',
        button: {
          label: 'New',
          action: 'add'
        }
      },
      actions: [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Link user account', type: 'default', action: 'LinkUserAcc' }
      ],
      bulkAddButton: undefined,
      addButton: {
        emptyStateLabel: 'New',
        isDropdown: false,
        label: 'New',
        emittedValue: 'add'
      },
      fixedColumns: ['ID', 'BUYER NAME', 'ACTIONS'],
      mainTitle: 'Organizations',
      columns: [
        {
          name: '#',
          dtoName: 'numericId',
          type: 'number',
          selected: true,
          sticky: true
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'REGISTRATION NUMBER',
          dtoName: 'registrationNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'PARTNER',
          dtoName: 'partner',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'TYPE',
          dtoName: 'type',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BUSINESS ADDRESS',
          dtoName: 'businessAddress',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'SASA',
          dtoName: 'sasa',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'VALUE CHAIN',
          dtoName: 'valueChain',
          type: 'text',
          selected: true
        },
        {
          name: 'OWNER',
          dtoName: 'owner',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'OWNER EMAIL',
          dtoName: 'owners',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'CASH OUT LIMITATION',
          dtoName: 'cashoutLimited',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'highRisk',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          dtoName: undefined,
          selected: true
        }
      ],

      filters: [
        {
          name: '#',
          dtoName: 'numericId',
          type: 'number'
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessName',
          type: 'text'
        },
        {
          name: 'REGISTRATION NUMBER',
          dtoName: 'registrationNumber',
          type: 'text'
        },
        {
          name: 'PARTNER',
          dtoName: 'partner',
          type: 'text'
        },
        {
          name: 'TYPE',
          dtoName: 'type',
          type: 'text'
        },
        {
          name: 'BUSINESS ADDRESS',
          dtoName: 'businessAddress',
          type: 'text'
        },

        {
          name: 'VALUE CHAIN',
          dtoName: 'valueChain',
          type: 'text'
        },
        {
          name: 'OWNER',
          dtoName: 'owner',
          type: 'text'
        },
        {
          name: 'CASH OUT LIMITATION',
          dtoName: 'cashoutLimited',
          type: 'radio',
          radio: [
            { label: 'Limited', value: true },
            { label: 'Not Limited', value: false }
          ]
        },
        {
          name: 'HIGH RISK',
          dtoName: 'highRisk',
          type: 'radio',
          radio: [
            { label: 'High Risk', value: true },
            { label: 'Low Risk', value: false }
          ]
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'radio',
          radio: [
            { label: 'Active', value: true },
            { label: 'Inactive', value: false }
          ]
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  changeStatus(entity: any) {}
  addOrganization() {
    $('#addOrganizationWizard').css({ display: 'flex' });
  }
  primaryActions(val?: string) {
    switch (val) {
      case 'new':
        this.addOrganization();
        break;

      default:
        this.addOrganization();
    }
  }
  viewOrder(agri: any) {
    this.router.navigate(['orders', agri._id]);
  }

  actions(e: { action: any; entity?: any }) {
    const user = {
      ...e.entity.orgUser,
      personalInformation: {
        ...e.entity.orgUser.personalInformation,
        firstName: e.entity.businessName,
        lastName: ''
      },
      accounts: e.entity.accounts,
      financing: e.entity?.financing
    };
    switch (e.action.action) {
      case 'topup':
        this.openTopup(user);
        break;
      case 'view':
        this.viewOrg(e.entity);
        break;
      case 'activate':
        this.handleActivation(user);
        break;
      case 'grant':
        this.sasaAccess(e?.entity, e?.action?.action);
        break;
      case 'revoke':
        this.sasaAccess(e?.entity, e?.action?.action);
        break;
      case 'cashback':
        this.cashout(user);
        break;
      case 'limitation':
        this.openCashoutLimitation(user);
        break;
      case 'riskLevel':
        this.changeRisk(user);
        break;
    }
  }
  viewOrg(org: any) {
    this.router.navigate(['organization', org?.orgUser?._id, org._id]);
  }
  cashout(user: User) {
    this.currentTopupUser = user;
    $('#cashbackModal').css({ display: 'flex' });
  }
  openCashoutLimitation(user: any) {
    this.currentTopupUser = user;
    this.accounts = [user?.financing];
    $('#limitationWizard').css('display', 'flex');
  }
  changeRisk(user: any) {
    const account = user?.financing;
    this.alertService
      .fire({
        title: 'Are you sure?',
        subtitle: 'You are about to mark this account as ' + (!account.highRisk ? 'high' : 'low') + ' risk.',
        iconName: 'warning'
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();
          this.http
            .put(`${environment.new_api_url}/api/account/avenews/${account._id}/high-risk`, {
              highRisk: !account.highRisk
            })
            .pipe(
              catchError((err: any) => {
                this.spinner.hideSpinner();
                this.router.navigateByUrl(this.router.url);
                this.alertService.showAlert('Something went wrong. Please try again', 'danger');
                return EMPTY;
              })
            )
            .subscribe((data) => {
              this.spinner.hideSpinner();
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(`Organization marked as ${!account.highRisk ? 'high' : 'low'} risk`, 'success');
            });
        }
      });
  }
  sasaAccess(item: any, action: 'revoke' | 'grant') {
    console.log(item);
    this.alertService
      .fire({
        title: 'Are you sure?',
        subtitle: `You are about to ${action} access to SASA from ${item?.businessName}`,
        iconName: 'warning'
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();
          this.http
            .put(
              `${environment.new_api_url}/api/policy-role/organization/${item?._id}/feature/${action === 'grant' ? 'assign' : 'revoke'}`,
              {
                features: ['sasa']
              }
            )
            .pipe(
              catchError((err: any) => {
                this.spinner.hideSpinner();
                this.router.navigateByUrl(this.router.url);
                this.alertService.showAlert('Something went wrong. Please try again', 'danger');
                return EMPTY;
              })
            )
            .subscribe((data) => {
              this.spinner.hideSpinner();
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(`Sasa access has been ${action === 'grant' ? 'granted' : 'revoked'}`, 'success');
            });
        }
      });
  }
  async openTopup(user: any) {
    this.currentTopupUser = user;
    let funds;
    this.spinner.showSpinner();
    try {
      funds = await firstValueFrom(this.http.get(`${environment?.new_api_url}/api/balance/user/${user?._id}`));
    } catch (err) {}
    this.spinner.hideSpinner();
    this.accounts = user?.accounts.map((acc) => {
      try {
        acc.details = JSON.parse(acc.details);
        if (funds) {
          if (acc?.service === PaymentService.AVENEWS_INCOME) {
            acc.details.balance = funds?.income?.total;
          }
        }
        return acc;
      } catch {
        return acc;
      }
    });

    this.spinner.hideSpinner();
    $('#addTopupWizard').css('display', 'flex');
  }
  private handleActivation(user: any) {
    const catcher = (err) => {
      this.spinner.hideSpinner();
      this.alertService.showAlert(err.error.message, 'danger');
      return EMPTY.pipe();
    };
    const isValidated = user?.accounts?.[0]?.validated;
    const { title, subtitle, confirmButtonText } = {
      title: !isValidated ? 'Activate account?:' : 'Deactivate account?:',
      subtitle: !isValidated
        ? 'Activating this account will unlock the account to be able to do transactions in the app'
        : 'Deactivating this account will lock the account from doing transactions in the app',
      confirmButtonText: !isValidated ? 'Activate' : 'Deactivate'
    };
    this.alertService
      .fire({
        cancelButton: true,
        title,
        subtitle,
        iconName: 'warning',
        confirmButtonText
      })
      .then((data) => {
        if (data.confirmed) {
          this.spinner.showSpinner();
          this.http
            .put(`${environment.new_api_url}/api/account/avenews/${user?.accounts[0]?._id}`, {
              validated: !isValidated
            })
            .pipe(catchError(catcher))
            .subscribe(() => {
              this.router.navigateByUrl(this.router.url);
              this.alertService.showAlert(
                `Finance Balance for ${user.personalInformation.firstName} ${user.personalInformation?.lastName} ${confirmButtonText}d successfully`,
                'success'
              );
              this.spinner.hideSpinner();
            });
        }
      });
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      if (sort.active === 'cashoutLimited' || sort.active === 'status' || sort.active === 'highRisk' || sort.active === 'owner') {
        this.list = this.list.sort((a, b) => {
          if (sort.active === 'cashoutLimited') {
            let as = -1;
            let bs = -1;
            if (a?.financing && a?.financing?.availableCashoutRate !== 0) {
              as = 1;
            }
            if (b?.financing && b?.financing?.availableCashoutRate !== 0) {
              bs = 1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          } else if (sort.active === 'status') {
            let as = 0;
            let bs = 0;
            if (a?.financing) {
              if (a?.financing?.validated) {
                as = 1;
              }
            } else {
              as = -1;
            }
            if (b?.financing) {
              if (b?.financing?.validated) {
                bs = 1;
              }
            } else {
              bs = -1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          } else if (sort.active === 'highRisk') {
            let as = 0;
            let bs = 0;
            if (a?.financing) {
              if (a?.financing?.highRisk) {
                as = 1;
              }
            } else {
              as = -1;
            }
            if (b?.financing) {
              if (b?.financing?.highRisk) {
                bs = 1;
              }
            } else {
              bs = -1;
            }
            return sort.direction === 'desc' ? as - bs : bs - as;
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['cashoutLimited', 'status', 'highRisk', 'owner'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'cashoutLimited') {
          if (this.getIncomeFinance(item, 'finance')) {
            if ((this.getIncomeFinance(item, 'finance')?.availableCashoutRate !== 0) === f.value) {
              res = false;
            }
          } else {
            res = false;
          }
        }
        if (f.column === 'status') {
          res = this.getIncomeFinance(item, 'finance').validated == f.value;
        }
        if (f.column === 'highRisk') {
          res = this.getIncomeFinance(item, 'finance').highRisk == f.value;
        }
        if (f.column === 'owner') {
          const ownerName = this.ownerFullName(item?.owner);
          res = ownerName.toLowerCase().includes(f.value.toLowerCase());
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
  openModal(entity: any) {}
  valueChains(chains: string[], tooltip?: boolean) {
    if (!chains || chains?.length <= 1) {
      return chains?.[0] || '-';
    }
    if (tooltip) {
      return chains?.join(',\n');
    }
    return 'Multiple';
  }
  ownerFullName(owner: any) {
    return owner.personalInformation.firstName + ' ' + owner.personalInformation.lastName;
  }
  statusGrabber(status: any) {
    switch (status) {
      case 'new':
        return 'New';
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Denied';
      case 'settled':
        return 'Settled';
      case 'processing':
        return 'In progress';
      default:
        return 'New';
    }
  }

  async postPagination() {
    let list = [];
    for (let item of this.list) {
      const actions = [];
      item['sasa'] = this.hasSasaFeature(item) ? 'Has Access' : 'No Access';
      console.log(item?.sasa);
      if (item?.sasa === 'Has Access') {
        actions.push({ label: 'Revoke SASA access', type: 'default', action: 'revoke' });
      } else {
        actions.push({ label: 'Grant SASA access', type: 'default', action: 'grant' });
      }
      item['actions'] = actions;
      item['valueChains'] = this.valueChains(item.valueChains);
      item['valueChainTooltip'] = this.valueChains(item.valueChain, true);
      item['emails'] = this.emails(item);
      list.push(item);
    }
    this.list = list.slice();
    list = [];
    for (let item of this.list) {
      // const accounts = await this.getAccountsByBeneficiary(item?.orgUser?._id);
      const accounts = item['accounts'];
      item['accounts'] = accounts;
      const actions = [];
      if (!item?.accounts?.length) {
        actions.push({ label: 'Create Wallet', type: 'default', action: 'createWallet' });
      } else {
        actions.push({ label: 'Topup', type: 'default', action: 'topup' });
        // actions.push({ label: 'Topdown', type: 'default', action: 'topdown' });
        actions.push({ label: 'Cashout Limitation', type: 'default', action: 'limitation' });
        actions.push({ label: 'Cashback', type: 'default', action: 'cashback' });
        if (this.getIncomeFinance(item, 'financing')?.highRisk) {
          actions.push({ label: 'Mark as low risk', type: 'default', action: 'riskLevel' });
        } else {
          actions.push({ label: 'Mark as high risk', type: 'default', action: 'riskLevel' });
        }
        if (this.getIncomeFinance(item, 'financing')?.service) {
          if (this.getIncomeFinance(item, 'financing')?.validated) {
            actions.push({ label: 'Deactivate', type: 'default', action: 'activate' });
          } else {
            actions.push({ label: 'Activate', type: 'default', action: 'activate' });
          }
        }
      }
      item['sasa'] = this.hasSasaFeature(item) ? 'Has Access' : 'No Access';
      console.log(item?.sasa);
      if (item?.sasa === 'Has Access') {
        actions.push({ label: 'Revoke SASA access', type: 'default', action: 'revoke' });
      } else {
        actions.push({ label: 'Grant SASA access', type: 'default', action: 'grant' });
      }
      item['actions'] = actions;
      item['valueChains'] = this.valueChains(item.valueChains);
      item['valueChainTooltip'] = this.valueChains(item.valueChain, true);
      item['financing'] = this.getIncomeFinance(item, 'finance');
      item['income'] = this.getIncomeFinance(item, 'income');
      item['emails'] = this.emails(item);
      list.push(item);
    }
    this.list = list.slice();
  }
  getIncomeFinance(org: any, type: string) {
    const acc = org.accounts as Account[];
    const income = acc.find((data) => data.service === PaymentService.AVENEWS_INCOME);
    const finance = acc.find((data) => data.service === PaymentService.AVENEWS_FINANCING);
    if (type === 'income') {
      return income;
    }
    return finance;
  }
  emails(org: any): string {
    let res = '';
    this.users?.forEach?.((data) => {
      if (org._id === (data?.policyRole?.[0] || data?.policyRole)?.organization) {
        res += data.username + ` (${data?.policyRole?.[0]?.name}) \n`;
      }
    });
    return res;
  }
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }

  async getAccountsByBeneficiary(id: string) {
    /**
     * curl --location ' https://api.new.kenya.avenews.io/api/account/beneficiary/632aea359f77b007cdbfff49' \
     */
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.new_api_url + '/api/account/beneficiary/' + id)
        .pipe(
          catchError((err) => {
            console.log('error returning accounts');
            console.log(err);
            return EMPTY.pipe();
          })
        )
        .subscribe((data: any) => {
          console.log(data);
          resolve(data);
        });
    });
  }
  hasSasaFeature(item?: any): boolean {
    return item?.owner?.policyRole?.features?.includes('sasa');
  }
}
