<div id="trade-desk-container">
  <div class="desk-top">
    <div>
      <h2>Distributor - application</h2>
    </div>
  </div>
  <div class="desk-container">
    <div class="categories">
      <div class="identity-card">
        <div class="header">
          <div class="initials">
            {{ distributor?.user?.personalInformation?.firstName | uppercase | slice : 0 : 1
            }}{{ distributor?.user?.personalInformation?.lastName | uppercase | slice : 0 : 1 }}
          </div>
          <div class="full-name">
            {{ distributor?.user?.personalInformation?.firstName }} {{ distributor?.user?.personalInformation?.lastName }}
          </div>
        </div>
        <div class="d-flex flex-column mt-3">
          <div class="info-section" *ngFor="let item of infoTorender">
            <div class="icon">
              <i-feather [name]="item.icon"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">{{ item.title }}:</p>
              <p class="soft-p">{{ item.dto || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group (selectedTabChange)="tabChanged($event)" #tabGroup>
      <mat-tab label="Documents">
        <td-documents [distributor]="distributor"></td-documents>
      </mat-tab>
      <mat-tab label="KYC">
        <td-kyc [distributor]="distributor"></td-kyc>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
