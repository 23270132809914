import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult, User } from '@avenews/agt-sdk';

@Injectable()
export class AvenewsUsersResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: import('@angular/router').ActivatedRouteSnapshot) {
    const url = `/api/user/12310/1/_id/desc?filters=[{%22value%22:%22avenews%22,%22column%22:%22policyRole.name%22,%22option%22:%22contains%22}]`;
    const org = (JSON.parse(localStorage.getItem('credentials')).user as User)?.policyRole?.organization?._id;
    return (this.http.get(`${environment.new_api_url}${url}`) as Observable<PaginatedResult<User>>).pipe(
      map((data) => {
        return data.entities
      }),
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
