import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export interface Slides {
  title?: string;
  description?: string;
  imgSrc?: string;
  imgAlt?: string;
}
@Component({
  selector: 'av-feature-announcement',
  templateUrl: './feature-announcement.component.html',
  styleUrls: ['./feature-announcement.component.scss']
})
export class FeatureAnnouncementComponent implements OnInit {
  @Input() slides: Slides[];
  swip: any;
  @Input() noDropFilter = false;
  constructor(protected router: Router) {}

  ngOnInit(): void {
    this.swip = new Swiper('.swiper', {
      slidesPerView: 1,
      spaceBetween: 30,
      grabCursor: true,
      speed: 500,
      pagination: {
        el: '.slider__pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.slider__button-next',
        prevEl: '.slider__button-prev'
      },
      modules: [Navigation, Pagination]
    });
  }
  swipeNext() {
    if (this.swip.activeIndex !== this.slides.length - 1) {
      this.swip.slideNext();
    } else if (this.swip.activeIndex === this.slides.length - 1) {
      this.router.navigateByUrl('loan/schedule-call-onboarding');
      this.noDropFilter = true;
    }
  }
}
