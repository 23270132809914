import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'av-numbered-steps',
  templateUrl: './numbered-steps.component.html',
  styleUrls: ['./numbered-steps.component.scss']
})
export class NumberedStepsComponent implements OnInit {
  @Input() list: { number: number; title: string; text: string; subText: string }[];
  @Input() sub?: string;
  @Input() chekcout?: boolean;
  constructor() {}

  ngOnInit(): void {}
}
