<div class="modal-box" id="declineUserRepaymentWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-content">
      <img src="../../../../assets/images/svgs/Group 2369.svg" />
      <p class="modal-title">
        Are you sure you want to decline top up of {{ repayment?.reportedAmount | number: '1.0-2' }} by
        {{ repayment?.owner?.personalInformation | beneficiaryName | titlecase }}
      </p>
      <p class="sub-title">You cannot undo this action</p>
    </div>
    <div class="bttn-wrapper">
      <button (click)="decline(false)" class="close">Close</button>
      <button (click)="decline(true)" class="decline">Decline</button>
    </div>
  </div>
</div>
