<div class="notes-section">
  <div class="add-note">
    <textarea
      #noteInput
      (keydown)="keyDown($event)"
      [mentionConfig]="mentionConfig"
      placeholder="Add a note..."
      rows="5"
      [formControl]="note"
      class="note"
    ></textarea>
    <div class="d-flex justify-content-end">
      <button (click)="addNote()" class="bttn-primary">Add Note</button>
    </div>
  </div>
  <div *ngIf="!notes?.length" class="empty">
    <div>
      <img src="../../../assets/images/empty-states/chat-empty.svg" alt="" />
    </div>
    <div class="no-note">No note added yet</div>
  </div>
  <ng-container *ngIf="notes?.length">
    <div [ngClass]="{ highlight: noteId === note?._id }" class="note-thing" *ngFor="let note of notes">
      <div class="note-top">
        <div class="note-identity">
          <div class="note-avatar">
            {{ note?.user?.personalInformation?.firstName[0] + note?.user?.personalInformation?.lastName[0] | uppercase }}
          </div>
          <div>{{ note?.user?.personalInformation?.firstName + ' ' + note?.user?.personalInformation?.lastName | titlecase }}</div>
        </div>
        <div class="note-top-end">
          <div class="note-date">{{ note?.dateCreated | date: 'dd/MM/yyyy - HH:mm' }}</div>
          <!-- <div [matMenuTriggerFor]="menu" class="note-actions click">
            <i-feather name="more-vertical"></i-feather>
          </div> -->
          <!-- <mat-menu class="notes-menu" #menu="matMenu">
            <button (click)="deleteNote(note?._id)" mat-menu-item>Delete</button>
          </mat-menu> -->
        </div>
      </div>
      <div class="note-content">{{ note?.note }}</div>
    </div>
  </ng-container>
</div>
