<div class="modal-box" id="changeAgriStatusWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Change Status</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <form [formGroup]="statusForm">
        <app-base-form-template
          labelName="Status"
          bindLabel="viewValue"
          bindValue="value"
          inputType="select"
          inputName="select"
          controlName="status"
          placeholderName="Change status"
          [options]="statusSelect"
          [group]="statusForm"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          [errorMessages]="errorMessages"
          labelName="Amount"
          inputType="number"
          inputName="default"
          controlName="amount"
          [group]="statusForm"
          [size]="6"
          [rOnly]="statusForm.get('status').value === 'declined'"
          [isDisabled]="statusForm.get('status').value === 'declined'"
        >
          <span #append append>KES</span>
        </app-base-form-template>
      </form>
    </div>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="changeStatus()">Update Status</button>
    </div>
  </div>
</div>
