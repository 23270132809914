import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { EMPTY, catchError } from 'rxjs';

@Component({
  selector: 'frontend-distributors-view',
  templateUrl: './distributor-view.component.html',
  styleUrls: ['./distributor-view.component.scss']
})
export class DistributorViewComponent implements OnInit, AfterViewInit {
  distributor: any;
  selectedIndex = 0;
  infoTorender: any[];
  discount: any;

  documents: any;
  @ViewChild('tabGroup', {
    static: true
  })
  tabGroup: MatTabGroup;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}
  ngOnInit(): void {
    this.route.data.subscribe(({ distributor }) => {
      this.distributor = distributor;
      this.http
        .get(`${environment.new_api_url}/api/agreement/organization/${distributor?.organization._id}`)
        .pipe(
          catchError((err: any) => {
            return EMPTY;
          })
        )
        .subscribe((data) => {
          if (data && data['discount']) {
            this.discount = data['discount'];
          } else {
            this.discount = 0;
          }
          this.infoTorender = [
            {
              icon: 'credit-card',
              title: 'Business name',
              dto: this.distributor.organization.businessName
            },
            {
              icon: 'briefcase',
              title: 'Business Registration Number',
              dto: this.distributor?.organization?.registrationNumber
            },
            {
              icon: 'briefcase',
              title: 'Business Till Number',
              dto: this.distributor?.organization?.tillNumber
            },
            {
              icon: 'briefcase',
              title: 'Discount',
              dto: this.discount + '%'
            },
            {
              icon: 'briefcase',
              title: 'First Name',
              dto: this.distributor?.user?.personalInformation?.firstName
            },
            {
              icon: 'briefcase',
              title: 'Last Name',
              dto: this.distributor?.user?.personalInformation?.firstName
            },
            {
              icon: 'briefcase',
              title: 'Status',
              dto: this.distributor?.status
            },
            {
              icon: 'phone-call',
              title: 'Phone',
              dto: this.distributor?.user?.personalInformation?.phoneNumber
            },
            {
              icon: 'at-sign',
              title: 'Email',
              dto: this.distributor?.user?.personalInformation?.email
            }
          ];
        });
    });
  }

  tabChanged(e: any) {
    this.selectedIndex = e.index;
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((data) => {
      const noteId = data?.['noteId'];
      if (noteId) {
        const notesTab = this.tabGroup._tabs.find((data) => {
          return data.textLabel === 'Notes';
        });
        this.tabGroup.selectedIndex = notesTab?.position;
      }
    });
  }
}
