import { ChangePasswordModal } from './../shared/components/change-password/change-password.component';
import { UsersResolver } from './../shared/resolvers/users.resolver';
import { UsersComponent } from './users.component';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesResolver } from './resolvers/roles.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'users',
      pathMatch: 'full',
      component: UsersComponent,
      resolve: { users: UsersResolver, role: RolesResolver },
      runGuardsAndResolvers: 'always',
      data: { pageSize: 10, title: 'Users' }
    },
    {
      path: 'settings/change-password',
      component: ChangePasswordModal
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}
