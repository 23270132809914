import { Router } from '@angular/router';
import { Agt4Order } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'frontend-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  isSaved: boolean = false;
  @Input() order: Agt4Order;
  environment = environment;
  inWeeks: boolean = false;
  prods: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router
  ) {
    this.prods = this.fb.group({
      moneyCycle: [undefined, Validators.required],
      question: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.order.questions.forEach((que) => {
      (this.prods.get('question') as FormArray).push(this.fb.control(que.answer));
    });
    this.order.moneyCycle && this.prods.get('moneyCycle').setValue(this.order.moneyCycle);
  }
  onSave() {
    this.spinner.showSpinner();
    this.http
      .put(`${this.environment.new_api_url}/api/order/${this.order?._id}/questions`, {
        questions: this.prods.value.question.map((answer, index) => {
          return {
            questionId: this.order.questions[index]._id,
            answer: answer
          };
        }),
        moneyCycle: this.prods.value.moneyCycle
      })
      .pipe(
        catchError((err) => {
          this.alertService.showAlert(err?.error?.message, 'danger');
          this.spinner.hideSpinner();
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.isSaved = false;
        this.alertService.showAlert(`Data saved successfully`, 'success');
        this.router.navigateByUrl(this.router.url);
        this.spinner.hideSpinner();
      });
  }
  onEdit() {
    this.isSaved = true;
  }
}
