import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'issuedFor'
})
export class IssuedForPipe implements PipeTransform {
  transform(grn: any) {
    try {
      if ((grn.supplier as any).businessDetails) {
        return (grn.supplier as any).businessDetails.name;
      } else {
        const supplier = grn.supplier as any;
        return supplier.firstName
          ? this.upperCaseFirstLetter(supplier.firstName) + ' ' + this.upperCaseFirstLetter(supplier.lastName)
          : supplier.fullName;
      }
    } catch {
      return undefined;
    }
  }
  upperCaseFirstLetter(name: string) {
    if (name && name[0]) {
      return name[0].toUpperCase() + name.slice(1);
    }
    return name;
  }
}
