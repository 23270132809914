import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beneficiaryName'
})
export class BeneficiaryNamePipe implements PipeTransform {
  constructor() {}
  transform(item: any) {
    let res = '';
    try {
      let details = item;
      if (item.details) {
        details = JSON.parse(item.details);
      }
      if (details.fullName) {
        res += details.fullName;
      } else if (details.firstName) {
        res += details.firstName + ' ' + details.lastName;
      } else if (details.agribusinessName) {
        res += details.agribusinessName;
      } else if (details.name) {
        res += details.name;
      } else if (details.businessDetails) {
        res += details.businessDetails.name;
      } else if (details?.personalInformation) {
        res += details.personalInformation.firstName + ' ' + details.personalInformation.lastName;
      }
    } catch (err) {}
    return res || '-';
  }
}
