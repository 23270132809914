import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsersResolver } from '../shared/resolvers/users.resolver';
import { DistributorsListComponent } from './distributors-list/distributors-list.component';
import { DistributorRoutingModule } from './distributor-routing.module';
import { DistributorViewComponent } from './distributor-view/distributor-view.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MentionModule } from 'angular-mentions';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { UiLibraryModule } from '@avenews/ui-library';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgChartsModule } from 'ng2-charts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { FinanceModule } from '../finance/finance.module';
import { MatIconModule } from '@angular/material/icon';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TdDocumentsComponent, TdKYCComponent } from './tools';
import { DistributorsTransComponent } from './distributors-transactions/distributors-transactions.component';
import { AllTransactionListResolver } from '../pay/resolvers/all-transactions.resolver';
import { DistributorsResolver } from './resolvers/distributors.resolver';
import { BnplTransactionsResolver } from './resolvers/bnpl-transactions.resolver';
import { DistributorResolver } from './resolvers/distributor.resolver';

@NgModule({
  declarations: [DistributorsListComponent, DistributorViewComponent, TdKYCComponent, TdDocumentsComponent, DistributorsTransComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatTabsModule,
    MatTooltipModule,
    UiLibraryModule,
    AdminSharedModule,
    SharedModule,
    DistributorRoutingModule
  ],
  exports: [],
  providers: [UsersResolver, AllTransactionListResolver, DistributorResolver, DistributorsResolver, BnplTransactionsResolver]
})
export class DistributorModule {}
