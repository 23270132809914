import { ListNoSdkComponent } from '@avenews/shared-lib';
import { Component, Injector, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Action, Agt4Period, PeriodStatus, PeriodType } from '@avenews/agt-sdk';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Sort } from '@angular/material/sort';
@Component({
  selector: 'frontend-periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent extends ListNoSdkComponent<Agt4Period> implements OnInit {
  period: Agt4Period;
  isEdit: boolean;
  isPeriodOpen: boolean;
  isStatusOpen: boolean;
  @Input() repayments: any;
  @Output() periods = new EventEmitter();
  constructor(injector: Injector, private router: Router) {
    super(injector, {
      noPagination: true,
      name: 'getPeriods',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/periods`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'periods';
  }

  ngOnInit(): void {
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    super.init();
    this.calcEndDate();
    this.postPagination();
    this.listConfig = {
      emptyState: {
        title: 'No Periods found',
        img: 'assets/images/empty-states/deal-empty.png'
      },
      actions: [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Edit', type: 'default', action: 'edit' },
        { label: 'New period', type: 'default', action: 'add' }
      ],
      bulkAddButton: undefined,
      fixedColumns: ['ID', 'PARTNER NAME', 'ACTIONS'],
      mainTitle: '',
      addButton: undefined,
      columns: [
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'PERIOD NAME',
          dtoName: 'name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'START DATE',
          dtoName: 'startDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'END DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REPAYMENT DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REQUESTED AMOUNT',
          dtoName: 'requestedAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'REPAYMENT AMOUNT',
          dtoName: 'repaymentOption.repaymentAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },

        {
          name: 'START DATE',
          dtoName: 'startDate',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'END DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REPAYMENT DATE',
          dtoName: 'endDate',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'REQUESTED AMOUNT',
          dtoName: 'requestedAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'REPAYMENT AMOUNT',
          dtoName: 'repaymentAmount',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'AMOUNT REPAID',
          dtoName: 'numericId',
          sideText: 'KES',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ] as any[]
    };
  }

  ngAfterViewInit(): void {}

  calcEndDate() {
    this.repayments.forEach((repayment) => {
      if (repayment.status !== 'approved') {
        return;
      }
      const {
        period: { _id: periodId },
        dateCreated: endDate
      } = repayment;
      const period = this.list.find((p: Agt4Period) => p._id === periodId);
      if (period) {
        period.endDate = endDate;
      }
    });
  }

  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'edit':
        this.editPeriod(e.entity);
        break;
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
      case 'repay':
        this.setRepayment(e.entity);
        break;
    }
  }

  setRepayment(period: Agt4Period) {
    this.period = period;
    $('#repaymentModal').css({ display: 'flex' });
  }

  changeStatus(period: Agt4Period) {
    this.period = period;
    this.isStatusOpen = true;
  }

  editPeriod(period: Agt4Period) {
    this.isEdit = true;
    this.isPeriodOpen = true;
    this.period = period;
  }

  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      this.sortData(sort);
      // if (sort.active === 'fullName') {
      //   this.list = this.list.sort((a, b) => {
      //     if (sort.active === 'fullName') {
      //       return sort?.direction === 'asc'
      //         ? a.contacts?.[0]?.firstName?.localeCompare(b.contacts?.[0]?.firstName)
      //         : b.contacts?.[0]?.firstName?.localeCompare(a.contacts?.[0]?.firstName);
      //     }
      //     return 1;
      //   });
      // } else {
      //   this.sortData(sort);
      // }
    }
  }

  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {});
      return res;
    });
    this.totalCount = this.list.length;
  }

  statusGrabber(status: string) {
    switch (status) {
      case PeriodStatus.NEW:
        return 'New';
      case PeriodStatus.ACTIVE:
        return 'Active';
      case PeriodStatus.OVERDUE:
        return 'Overdue';
      case PeriodStatus.PARTIALLY_PAID:
        return 'Partially Paid';
      case PeriodStatus.SETTLED:
        return 'Settled';
      case PeriodStatus.CANCELLED:
        return 'Cancelled';
      default:
        return 'New';
    }
  }

  postPagination() {
    const deals = {};
    this.list.sort((a, b) => {
      // sort by numericId
      if (a.numericId < b.numericId) {
        return -1;
      }
      if (a.numericId > b.numericId) {
        return 1;
      }
      return 0;
    });

    this.list.forEach((data) => {
      // deals.push(data.deal._id);
      if (deals[data.deal._id]?.length) {
        deals[data.deal._id].push(data);
      } else {
        deals[data.deal._id] = [data];
      }
    });
    Object.values(deals).forEach((deal: any[]) => {
      deal.forEach((data, i) => {
        data['name'] = `Period ${i + 1}`;
      });
    });
    this.periods.emit(this.list);
    //remove duplicates from dealIds
    this.list.sort((a, b) => {
      if (a.numericId > b.numericId) {
        return -1;
      }
      if (a.numericId < b.numericId) {
        return 1;
      }
      return 0;
    });
    const list = [];
    for (let item of this.list) {
      const actions = [{ label: 'Change status', type: 'default', action: 'changeStatus' }];
      if (item.status === 'new' && item?.deal?.periodType === PeriodType.RECURRING) {
        actions.push({ label: 'Edit', type: 'default', action: 'edit' });
      }
      if (item?.status !== 'settled' && item.status !== 'cancelled') {
        actions.push({ label: 'Set repayment', type: 'default', action: 'repay' });
      }

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }

  startDate(period: Agt4Period) {
    if (period?.status !== 'new') {
      if (period?.['startDate']) {
        return period['startDate'];
      }
      if (period?.deal?.periodType === PeriodType.CREDIT_LINE) return period['dateCreated'];
      const startDate = new Date(period?.deal?.endDate || new Date());
      startDate.setDate(startDate.getDate() - period.duration);
      return startDate;
    }
  }
}
