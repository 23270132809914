import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'frontend-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  currId: string;
  documents: any[];
  @Output() openModal = new EventEmitter();
  @Output() openDecModal = new EventEmitter();

  acceptedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  prettyAcceptedTypes = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'xls', 'xlsx'];
  environment = environment;
  subscription: Subscription;
  deleteMode = false;
  currDocId: string;
  item: object;
  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private spinner: SpinnerToggleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe(async (params) => {
      this.currId = params['id'];
    });
    this.route.data.subscribe(({ documents }) => {
      this.documents = [
        { title: 'Personal finances', docType: 'personalFinances', data: documents?.filter((doc: any) => doc.documentType === 'personalFinances') },
        { title: 'Business finances', docType: 'businessFinances', data: documents?.filter((doc: any) => doc.documentType === 'businessFinances') },
        {
          title: 'Business transactions',
          docType: 'businessTransactions',
          data: documents?.filter((doc: any) => doc.documentType === 'businessTransactions')
        },
        {
          title: 'Financial statement',
          docType: 'financialStatement',
          data: documents?.filter((doc: any) => doc.documentType === 'financialStatement')
        },
        { title: 'Proof of address', docType: 'proofOfAddress', data: documents?.filter((doc: any) => doc.documentType === 'proofOfAddress') },
        {
          title: 'Entity registration',
          docType: 'entityRegistration',
          data: documents?.filter((doc: any) => doc.documentType === 'entityRegistration')
        }
      ];
    });
  }
  validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 15e6;
  }

  uploadDocs(event: any, docType: string) {
    let file: File = event.target.files[0];
    if (!this.validateFile(file)) {
      this.alertService.showAlert(`Maximum file size is 15MB and it should be ${this.prettyAcceptedTypes.join(', ')}`, 'danger');
      return;
    }
    this.spinner.showSpinner();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File = (reader.result as string).split(',')[1];
      const doc = {
        file: base64File,
        fileName: file.name,
        mime: file.type,
        documentType: docType,
      };
      this.http
        .post(`${this.environment.new_api_url}/api/loan-application/file/${this.currId}`, 
          [{...doc}]
        )
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(`Document was not added`, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.router.navigate(['/finance/', this.currId]);
          this.alertService.showAlert(`Document was added successfully`, 'success');
        });
    };
  }

  checkStatus(type: string) {
    if (type === 'approved') return 'doc-status-approved';
    if (type === 'declined') return 'doc-status-declined';
    else return null;
  }
  changeStatus(id: string, status: string) {
    this.spinner.showSpinner();
    this.http
      .put(`${this.environment.new_api_url}/api/loan-application/file/${id}/status`, {
        status: status
      })
      .pipe(
        catchError((err: any) => {
          this.spinner.hideSpinner();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        this.router.navigate(['/finance/', this.currId]);
      });
  }

  handStatus(id: string, status: string, fileName: string) {
    this.item = {
      id: id,
      status: status,
      fileName: fileName
    };
    this.openDecModal.emit(this.item);
  }

  removeDoc(id: string, fileName: string) {
    this.item = {
      id: id,
      fileName: fileName
    };
    this.openModal.emit(this.item);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  openFile(id: string) {
    this.spinner.showSpinner();
    this.http.get(`${this.environment.new_api_url}/api/loan-application/file/${id}`).subscribe((data) => {
      this.spinner.hideSpinner();
      window.open(data['url']);
    });
  }
}
