import { UploadFileDTO } from '@avenews/agt-sdk';

export const base64ToArrayBuffer = (base64: string) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const isEncrypted = (files: any): Promise<boolean> => {
  return new Promise((res) => {
    let isEncrypeted = false;
    files.text().then((x: any) => {
      isEncrypeted = x.includes('Encrypt');
      isEncrypeted = isEncrypeted || x.substring(x.lastIndexOf('<<'), x.lastIndexOf('>>')).includes('/Encrypt');
      res(isEncrypeted);
    });
  });
};

export const hasPassword = async (doc: UploadFileDTO) => {
  let file = doc.file.slice();
  file = btoa(atob(file));

  let fileHasPassword = false;
  let files = new Blob([base64ToArrayBuffer(file)], { type: 'application/pdf' });
  fileHasPassword = await isEncrypted(files);
  return fileHasPassword;
};
