<div class="modal-box" id="changeOrderStatusWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Change status</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="title">
        {{ order?.customer?.adminDetails?.name }} - order amount: <span>{{ order?.total | number: '1.0-2' }}KES</span>
      </p>
      <form [formGroup]="statusForm">
        <div>
          <app-base-form-template
            labelName="Status"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="status"
            placeholderName="Change status"
            [options]="statusSelect"
            [group]="statusForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>

        <div *ngIf="statusForm.get('status').value === 'approved'" class="approved-form">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Finance % cap "
            inputType="number"
            inputName="default"
            controlName="financedCap"
            [max]="100"
            [min]="0"
            [group]="statusForm"
            [size]="6"
          >
            <span #append append>
              <i-feather name="percent"> </i-feather>
            </span>
          </app-base-form-template>
          <app-base-form-template
            labelName="Amount to finance"
            inputType="text"
            inputName="default"
            controlName="amount"
            value="{{ (statusForm.get('financedCap').value / 100) * order?.total | number: '1.0-2' }}"
            [group]="statusForm"
            [rOnly]="true"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
        </div>
      </form>
    </div>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="changeStatus()">Save</button>
    </div>
  </div>
</div>
