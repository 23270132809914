import { Agt4Order, Status } from '@avenews/agt-sdk';
import { BaseValidationComponent } from './../base-validation/base-validation.component';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@avenews/alerts';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'frontend-change-order-status',
  templateUrl: './change-order-status.component.html',
  styleUrls: ['./change-order-status.component.scss']
})
export class ChangeOrderStatusComponent extends BaseValidationComponent implements OnInit {
  statusForm: FormGroup;
  environment = environment;
  approvedForm: FormGroup;
  @Input() order: Agt4Order;
  statusSelect = [
    {
      value: Status.PROCESSING,
      viewValue: 'In progress'
    },
    {
      value: Status.APPROVED,
      viewValue: 'Approved'
    },
    {
      value: Status.DECLINED,
      viewValue: 'Denied'
    }
  ];

  errorMessages = {
    financedCap: [
      { type: 'required', message: 'This field is required' },
      { type: 'min', message: 'Invalid number' },
      { type: 'max', message: 'Invalid number' }
    ]
  };

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.statusForm = this.fb.group({
      status: [undefined, Validators.required]
    });
    this.statusForm.valueChanges.subscribe((data) => {
      if (data.status === 'approved') {
        this.statusForm.addControl(
          'financedCap',
          new FormControl(undefined, [Validators.required, Validators.max(100), Validators.min(1)]),
          { emitEvent: false }
        );
        this.statusForm.addControl('amount', new FormControl(undefined), { emitEvent: false });
      } else {
        this.statusForm.removeControl('financedCap', { emitEvent: false });
        this.statusForm.removeControl('amount', { emitEvent: false });
      }
    });
  }

  ngOnInit(): void {}

  changeStatus() {
    this.validateForm(this.statusForm);
    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${this.environment.new_api_url}/api/order/${this.order?._id}/status`, {
          status: this.statusForm.value.status,
          financedCap: this.statusForm.value.financedCap
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            $('#changeOrderStatusWizard').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.alertService.showAlert(`Status changed successfuly`, 'success');
          $('#changeOrderStatusWizard').fadeOut();
          this.router.navigateByUrl(this.router.url);
          this.deleteData();
          this.spinner.hideSpinner();
        });
    }
  }
  close() {
    $('#changeOrderStatusWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  deleteData() {
    this.statusForm.reset();
  }
}
