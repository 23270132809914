import { CurrentUserResolver } from '@avenews/shared-lib';
import { MLScoreDataResolver } from './resolvers/ml-score-data.resolver';
import { ApplicationResolver } from './../finance/resolvers/application.resolver';
import { TradeDeskAgentComponent } from './trade-desk-agent/trade-desk-agent.component';
import { FinanceListResolver } from './../finance/resolvers/finance-list.resolver';
import { CategoriesResolver } from './resolvers/categories.resolver';
import { TradeDeskBuilderComponent } from './trade-desk-builder/trade-desk-builder.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@avenews/shell';
import { LoanDeskComponent } from './loans/loan-desk.component';
import { FilterOptions } from '@avenews/agt-sdk';
import { GeneralVariablesResolver } from './resolvers/general-variables.resolver';
import { TDDocumentsResolver } from './resolvers/td-documents.resolver';
import { AvenewsUsersResolver } from './resolvers/avenews-users.resolver';
import { ActivityLogResolver } from './resolvers/activitylog.resolver';
import { BureauResolver } from './resolvers/bureau.resolver';
import { KycResolver } from './resolvers/kyc.resolver';
import { KycChecklistResolver } from './resolvers/kyc-checklist.resolver';
import { LoanFilesComponent } from './loan-files/loan-files.component';
import { ExpireSoonResolver } from './resolvers/expire-soon.resolver';
import { ExpiredFilesResolver } from './resolvers/expired-files.resolver';
import { ArchivedFilesResolver } from './resolvers/archived-files.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'trade-desk',
      component: LoanDeskComponent,
      runGuardsAndResolvers: 'always',
      data: {
        pageSize: 10,
        title: 'Trade Desk'
      },

      resolve: { finance: FinanceListResolver }
    },

    {
      path: 'trade-desk/builder',
      component: TradeDeskBuilderComponent,
      runGuardsAndResolvers: 'always',
      data: {
        title: 'Trade Desk'
      },
      resolve: {
        categories: CategoriesResolver,
        generalVariables: GeneralVariablesResolver
      }
    },
    {
      path: 'trade-desk/loan/:id',
      data: {
        title: 'TD Loan View'
      },
      resolve: { application: ApplicationResolver, loan: ApplicationResolver, scoreData: MLScoreDataResolver },
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: '',
          component: TradeDeskAgentComponent,
          runGuardsAndResolvers: 'always',
          data: {
            isExistOrg: true,
            filters: [
              {
                value: 'approved',
                column: 'status',
                option: FilterOptions.NOT_EQUAL
              }
            ] as any
          },
          resolve: {
            categories: CategoriesResolver,
            generalVariables: GeneralVariablesResolver,
            biro: BureauResolver,
            user: CurrentUserResolver,
            users: AvenewsUsersResolver,
            documents: TDDocumentsResolver,
            logs: ActivityLogResolver,
            kyc: KycResolver,
            // kycCheckList: KycChecklistResolver
          }
        }
      ]
    },
    {
      path: 'loan-files',
      component: LoanFilesComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        files: ExpireSoonResolver,
        expiredFiles: ExpiredFilesResolver,
        archivedFiles: ArchivedFilesResolver
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TradeDeskRoutingModule {}
