import { catchError, EMPTY, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'av-kyc-view',
  templateUrl: './kyc-view.component.html',
  styleUrls: ['./kyc-view.component.scss']
})
export class KycViewComponent implements OnInit {
  kycResponse: any;
  showSpinner: boolean;
  // loanId:any;
  loanId = '6346c99d147be3d3a79a71e5';
  kyc: any;
  header = [
    { title: 'Onboarding Status Details', subTitle: 'Submission date, approve date, status etc.' },
    { title: 'Ahrvo Comply Risk Analysis', subTitle: 'Applicant risk calculations' },
    { title: 'Applicant Information', subTitle: 'Basic info, like name, phone, address, country etc.' },
    { title: 'Uploaded Documents', subTitle: 'Below are the users uploaded documents.' }
  ];
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.showSpinner = true;
      this.loanId = params['id'];
      this.http
        .get(`https://4rwz4abkda.execute-api.us-east-1.amazonaws.com/prod/verification?loanId=${this.loanId}`)
        .pipe(
          catchError((err) => {
            return of(undefined);
          })
        )
        .subscribe((res) => {
          this.kycResponse = res;
          this.showSpinner = false;
          if (res) {
            this.kyc = {
              statusDetails: {
                'Submitted By': null,
                'Submitted At': this.kycResponse.submitted_at,
                'Onboarding Status': this.kycResponse.kyc_status,
                'Last Checked At': this.kycResponse.last_checked_on,
                'User Status': this.kycResponse.user_status
              },
              ahrvoComplyRiskAnalysis: {
                'User Country Score': this.kycResponse.user_country_score,
                'User Risk Score': this.kycResponse.risk_score,
                'Risk Diligence Score': this.kycResponse.risk_diligence_score,
                'Face Match': this.kycResponse.faces_match,
                'Liveness Check': this.kycResponse.liveness_check,
                'Gender Check': this.kycResponse.gender_check,
                'Age Check': this.kycResponse.age_check,
                'Email Verified': this.kycResponse.email_verified,
                'Phone Verified': this.kycResponse.phone_verified,
                'Area Code Match': this.kycResponse.area_code_match,
                'Nationality per ID': this.kycResponse.nationality_id,
                'MRZ Type': this.kycResponse.mrz_type,
                'MRZ Match': this.kycResponse.mrz_match,
                'AML and PEP': this.kycResponse.aml_and_pep_check,
                'Last IP Address Check': this.kycResponse.last_ip_address_check
              },
              applicationInformation: {
                'Fisrt Name': this.kycResponse.first_name,
                'Last Name': this.kycResponse.last_name,
                'Email Address': this.kycResponse.email,
                'Phone Number': this.kycResponse.phone,
                'Date of Birth': this.kycResponse.dob,
                Gender: 'Male',
                'Full Address': this.kycResponse.address,
                'Country of Residence': this.kycResponse.country,
                City: null,
                Zipcode: null
              },
              UploadedDocuments: {
                'Document Type': this.kycResponse.doc_type,
                'ID Issuance Date': this.kycResponse.id_issuance_date,
                'ID Expire Date': this.kycResponse.id_expiry_date,
                'Front Side': null,
                'Back Side': null,
                'Face Image From Liveness': null
              }
            };
          }
        });
    });
  }
  close(bool?: boolean) {
    $('#kycViewModal').fadeOut('fast', () => {});
  }
}
