import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    try {
      if (control.value) {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value.toString(), regionCode);
        validNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode);
        if(control.value.includes('+')) {
          validNumber = false;
        }
      } else {
        validNumber = true;
      }
    } catch (e) {}
    if (!validNumber) {
      return { wrongNumber: { value: control.value, code: regionCode } };
    } else {
      return null as any;
    }
    // return validNumber ? null : { wrongNumber: { value: control.value, code: regionCode } };
  };
}
