import { Router } from '@angular/router';
import { Component, Injector, OnInit } from '@angular/core';
import { BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { Action, FilterOptions, Agt4Agribusiness } from '@avenews/agt-sdk';
import { Sort } from '@angular/material/sort';

declare const $: any;
@Component({
  selector: 'frontend-agribusinesses',
  templateUrl: './agribusinesses.component.html',
  styleUrls: ['./agribusinesses.component.scss']
})
export class AgribusinessesComponent extends ListNoSdkComponent<Agt4Agribusiness> implements OnInit {
  code: string;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  agri: Agt4Agribusiness;
  constructor(injector: Injector, private router: Router) {
    super(injector, {
      noPagination: true,
      name: 'getAllAgribusiness',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/agribusiness`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'agribusinesses';
  }

  ngOnInit(): void {
    this.paginated.subscribe(() => {
      this.postPagination();
    });
    this.stickyFilters = [
      {
        value: 'admin',
        column: 'roles',
        option: FilterOptions.NOT_EQUAL
      }
    ];
    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No agribusinesses wow weird',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        // { label: 'Top up', type: 'default', action: 'updateTopup' }
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Link user account', type: 'default', action: 'LinkUserAcc' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['ID', 'PARTNER NAME', 'ACTIONS'],
      mainTitle: 'Clients List',

      columns: [
        {
          name: 'ID',
          dtoName: '_id',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'PARTNER NAME',
          dtoName: 'adminDetails.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessDetails.name',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'FULL NAME',
          dtoName: 'fullName',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'PARTNER TYPE',
          dtoName: 'partnerType',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'PHONE NUMBER',
          dtoName: 'adminDetails.phoneNumber',
          type: 'text',
          selected: true
        },
        {
          name: 'EMAIL',
          dtoName: 'adminDetails.email',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'LINKED USER',
          dtoName: 'owner',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ROLE',
          dtoName: 'adminDetails.role',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'creditAmount',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'PAYMENT TERMS',
          dtoName: 'paymentTerms',
          pipe: 'number',
          type: 'number',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],

      filters: [
        {
          name: 'ID',
          dtoName: '_id',
          type: 'text'
        },
        {
          name: 'PARTNER NAME',
          dtoName: 'adminDetails.name',
          type: 'text'
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessDetails.name',
          type: 'text'
        },
        {
          name: 'FULL NAME',
          dtoName: 'fullName',
          type: 'text'
        },
        {
          name: 'PARTNER TYPE',
          dtoName: 'partnerType',
          type: 'text'
        },

        {
          name: 'PHONE NUMBER',
          dtoName: 'adminDetails.phoneNumber',
          type: 'text'
        },
        {
          name: 'EMAIL',
          dtoName: 'adminDetails.email',
          type: 'text'
        },
        {
          name: 'LINKED USER',
          dtoName: 'owner',
          type: 'text'
        },
        {
          name: 'ROLE',
          dtoName: 'adminDetails.role',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'creditAmount',
          type: 'text'
        },
        {
          name: 'PAYMENT TERMS',
          dtoName: 'paymentTerms',
          type: 'text'
        },
        {
          name: 'status',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  changeStatus(entity: any) {
    this.agri = entity;
    $('#changeAgriStatusWizard').css({ display: 'flex' });
  }

  LinkUserAcc(entity: any) {
    this.agri = entity;
    $('#linkUserAccount').css({ display: 'flex' });
  }

  updateTopup(entity: any) {
    this.agri = entity;
    $('#agriTopupWizard').css({ display: 'flex' });
  }
  viewAgribusiness(agri: any) {
    this.router.navigate(['agribusinesses', agri._id]);
  }

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'view':
        this.viewAgribusiness(e.entity);
        break;
      case 'changeStatus':
        this.changeStatus(e.entity);
        break;
      case 'topup':
        this.updateTopup(e.entity);
        break;
      case 'LinkUserAcc':
        this.LinkUserAcc(e.entity);
        break;
    }
  }

  agriName(agri): string {
    if (agri?.contacts?.length) {
      return agri.contacts[0].firstName + ' ' + agri.contacts[0].lastName;
    }
    return '-';
  }
  manualSort(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortData(sort);
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      // this.list = this.unsortedList.slice();

      if (sort.active === 'fullName') {
        this.list = this.list.sort((a, b) => {
          if (sort.active === 'fullName') {
            return sort?.direction === 'asc'
              ? a.contacts?.[0]?.firstName?.localeCompare(b.contacts?.[0]?.firstName)
              : b.contacts?.[0]?.firstName?.localeCompare(a.contacts?.[0]?.firstName);
          }
          return 1;
        });
      } else {
        this.sortData(sort);
      }
    }
  }
  manualFilter(filter: any[]) {
    const manuals = ['fullName'];
    const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
    const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
    this.noPagFilter(autoFilters);
    this.list = this.list.filter((item) => {
      let res = true;
      manualFilters.forEach((f) => {
        if (f.column === 'fullName') {
          if (item?.contacts?.[0]?.firstName && item?.contacts?.[0]?.lastName) {
          }
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
  statusGrabber(status: string) {
    switch (status) {
      case 'draft':
        return 'New';
      case 'approved':
        return 'Approved';
      case 'processing':
        return 'In Progress';
      case 'declined':
        return 'Declined';
      default:
        return '-';
    }
  }
  agriType(agri: Agt4Agribusiness) {
    return agri?.paymentTerms ? 'Buyer' : 'Supplier';
  }
  postPagination() {
    // i will use this later to change the actions menu based on the item
    const list = [];
    for (let item of this.list) {
      const actions = [
        { label: 'Change status', type: 'default', action: 'changeStatus' },
        { label: 'Link user account', type: 'default', action: 'LinkUserAcc' }
      ];
      if (item.paymentTerms && item?.['owner']) {
        actions.push({ label: 'Top up', type: 'default', action: 'topup' });
      }

      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }
  openModal() {
    $('#2faModal').css({ display: 'flex' });
  }
}
