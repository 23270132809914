import { catchError, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { BaseFormTemplateComponent } from 'libs/shared/src/lib/components/base-form-template/base-form-template.component';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '@avenews/alerts';
import { SpinnerToggleService, countries } from '@avenews/shared-lib';
import * as $ from 'jquery';

@Component({
  selector: 'app-create-agent',
  templateUrl: './add-avenews-agent.component.html',
  styleUrls: ['./add-avenews-agent.component.scss']
})
export class AvenewsAgentComponent extends BaseFormTemplateComponent implements OnInit {
  beneficiaryAccountId: string;
  beneficiaryId: string;
  userForm: FormGroup;
  countries = countries;
  @Input() roleId: string;
  constructor(fb: FormBuilder, private http: HttpClient, private alertService: AlertService, public spinner: SpinnerToggleService) {
    super();
    this.userForm = fb.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      phoneNumber: [undefined, Validators.required],
      email: [undefined, [Validators.required, Validators.email, Validators.pattern(/@avenews-gt\./i)]]
    });
    this.formInput = this.userForm;
    this.errorMessages = {
      email: [
        { type: 'required', message: 'Email is required' },
        { type: 'email', message: 'Email must be a valid email address' },
        { type: 'pattern', message: 'Must a valid Avenews email address "@avenews-gt"' }
      ]
    };
  }

  close() {
    $('#addAgentWizard').fadeOut('slow', () => {
      this.deleteData();
    });
  }
  createUser() {
    this.validateForm(this.userForm);
    if (!this.roleId) {
      this.alertService.showAlert('Could not find role, contact a developer', 'danger');
      return;
    }
    const catcher = (err: any) => {
      this.spinner.hideSpinner();
      this.alertService.showAlert(err.error.message, 'danger');
      this.close();
      return EMPTY.pipe();
    };
    if (this.userForm.valid) {
      this.spinner.showSpinner();
      const user = {
        roleId: this.roleId,
        ...this.userForm.value
      };
      this.http
        .post(`${environment.new_api_url}/api/user/organization`, { ...user })
        .pipe(catchError(catcher))
        .subscribe(() => {
          this.spinner.hideSpinner();
          this.close();
          this.alertService.showAlert('Agent created successfully, An email with the password was sent', 'success');
        });
    }
  }
  deleteData() {
    this.userForm.reset();
    this.userForm.markAsUntouched();
  }
}
