import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ChartsResolver implements Resolve<any> {
  constructor(private httpClient: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.httpClient.get('https://m5f9fhh671.execute-api.us-east-1.amazonaws.com/prod/portfolio-monitor').pipe(
      catchError(() => {
        return EMPTY.pipe();
      })
    );
  }
}
