import { Router } from '@angular/router';
import { AuthenticationService } from '@avenews/front-auth';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import * as $ from 'jquery';
import { AlertService } from '@avenews/alerts';
import { Transaction, Status } from '@avenews/base-types';
@Component({
  selector: 'app-transaction-status-component',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss']
})
export class TransactionStatusComponent extends BaseValidationComponent implements OnInit, OnChanges {
  @Input() transaction: Transaction;
  statusForm: FormGroup;
  statusSelect = [
    {
      value: Status.APPROVED,
      viewValue: 'Approved'
    },
    {
      value: Status.DECLINED,
      viewValue: 'Declined'
    }
  ];
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private authService: AuthenticationService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.statusForm = fb.group({
      status: [undefined, Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  close() {
    $('#changeStatusWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  changeStatus() {
    this.validateForm(this.statusForm);

    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/transaction/internal/status/${this.transaction?._id}`, {
          status: this.statusForm.value.status,
          response: `Transaction made from the admin panel by ${this.authService?.credentials.user?.personalInformation?.firstName} ${this.authService?.credentials.user?.personalInformation?.lastName}`
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            $('#changeStatusWizard').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: Transaction) => {
          this.alertService.showAlert(`Status Changed to  ${this.statusForm.value.status} successfuly`, 'success');
          $('#changeStatusWizard').fadeOut();
          this.deleteData();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  deleteData() {
    this.statusForm.reset();
    this.statusForm.patchValue({});
  }
}
