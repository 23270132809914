<ng-template
  [cdkConnectedOverlayPanelClass]="['factor-modal', customClass]"
  cdkConnectedOverlayBackdropClass="sheet-backdrop"
  cdkConnectedOverlayHasBackdrop
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
>
  <div (keyup.enter)="verify()" class="modal-box" id="2faModal">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-content scrollable">
        <p class="sm-modal-title">Verification code</p>
        <p class="sent">
          A verification code has been sent to <span>{{ phoneNumber }}</span
          >. Please enter the code below to continue.
        </p>
        <form [formGroup]="verifyForm">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Verification code"
            inputType="text"
            inputName="default"
            controlName="code"
            [group]="verifyForm"
            [size]="12"
          >
          </app-base-form-template>
        </form>
        <p class="resend">Didn`t receive the code? <a (click)="resendCode()">Resend code</a></p>

        <div @fade *ngIf="isSentCode">
          <p class="sent"><i-feather name="check"></i-feather> Code is sent</p>
        </div>
      </div>
      <div class="modal-box-actions">
        <button (click)="onCancel()" class="bttn-cancel">Cancel</button>
        <button (click)="verify()" class="bttn-primary pull-right">Verify</button>
      </div>
    </div>
  </div>
</ng-template>
