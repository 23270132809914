<div class="nk-block">
  <ng-container *ngIf="!showSpinner">
    <ng-container *ngIf="kyc">
      <div class="row gy-5">
        <div class="col-lg-5">
          <av-kyc-card [dataKyc]="kyc.statusDetails" [title]="header[0].title" [subTitle]="header[0].subTitle"></av-kyc-card>
          <av-kyc-card [dataKyc]="kyc.ahrvoComplyRiskAnalysis" [title]="header[1].title" [subTitle]="header[1].subTitle"></av-kyc-card>
        </div>
        <div class="col-lg-7">
          <av-kyc-card [dataKyc]="kyc.applicationInformation" [title]="header[2].title" [subTitle]="header[2].subTitle"></av-kyc-card>
          <av-kyc-card [dataKyc]="kyc.UploadedDocuments" [title]="header[3].title" [subTitle]="header[3].subTitle"></av-kyc-card>
        </div>
      </div>
    </ng-container>
    <h2 *ngIf="!kyc">No kyc yet</h2>
  </ng-container>
  <ng-container *ngIf="showSpinner">
    <div class="activity-container d-flex justify-content-center">
      <img width="200" src="../../../assets/loading.gif" alt="" />
    </div>
  </ng-container>
</div>
