import { ListConfig, RadioConfig } from '../../helpers/types/list.types';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
declare const $: any;
interface ColValues {
  name: string;
  type: string;
  dtoName?: string;
  radio: RadioConfig[];
}
@Component({
  selector: 'app-filters-template',
  templateUrl: './filters-template.component.html',
  styleUrls: ['./filters-template.component.scss']
})
export class FiltersTemplateComponent implements OnInit, OnChanges, AfterViewInit {
  selectable = true;
  removable = true;
  colValue: any;
  value?: string;
  fruitCtrl = new FormControl();
  searchTerm!: '';
  fromValue: any;
  toValue: any;
  oldLength = 0;
  selectForm!: FormGroup;
  filteringForm!: FormGroup;
  filterTriggerForm!: FormGroup;
  colValues: ColValues[] = [];
  @Input() listConfig!: ListConfig;
  @Input() easyFilters!: any;
  @Output() filter = new EventEmitter();
  @Output() colValuesEmitter = new EventEmitter();
  @Output() easyFilterAction = new EventEmitter();
  @ViewChildren('chipsBtn', { read: MatMenuTrigger })
  triggerBtn!: QueryList<MatMenuTrigger>;
  @ViewChild(MatMenuTrigger) trigger: any;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.filteringForm = this.fb.group({});
    this.filterTriggerForm = this.fb.group({});
    this.filterTriggerForm.valueChanges.subscribe((data) => {});
  }
  ngOnChanges(changes: any): void {
    this.selectForm = this.fb.group({});
    if (changes['listConfig'] && this.listConfig) {
      this.listConfig.filters.forEach((data: any) => {
        if (!data.noFilter) {
          this.selectForm.addControl(this.fixString(data.name), new FormControl('equal'));
        }
      });
    }
  }
  get betweenGroup(): FormGroup {
    return this.fb.group({
      startValue: [undefined, Validators.required],
      endValue: [undefined, Validators.required],
      actualStartValue: [undefined],
      actualEndValue: [undefined],
      startLabel: [undefined],
      endLabel: [undefined],
      item: [undefined]
    });
  }
  get equalsGroup(): FormGroup {
    return this.fb.group({
      value: [undefined, Validators.required],
      actualValue: [undefined],
      label: [undefined],
      item: [undefined]
    });
  }
  remove(item: any): void {
    const index = this.colValues.indexOf(item);
    this.selectForm.get(this.fixString(item.name))!.setValue('equal');
    this.filteringForm.removeControl(this.fixString(item.name));

    if (index >= 0) {
      this.colValues.splice(index, 1);
    }
    this.triggerFilter();
  }
  ngAfterViewInit() {
    this.triggerBtn.changes.subscribe((list: QueryList<MatMenuTrigger>) => {
      const menus = list.toArray();
      if (menus[menus.length - 1]) {
        if (menus.length > this.oldLength) {
          setTimeout(() => {
            menus[menus.length - 1].openMenu();
          }, 1);
        }
      }
      this.oldLength = menus.length;
    });
  }

  addFilter(e: any) {
    if (!this.filteringForm.get(this.fixString(e.name))) {
      this.filteringForm.addControl(this.fixString(e.name), this.equalsGroup);
      this.colValues.push(e);
    } else {
      $('#' + this.fixString(e.name) + 'chips').trigger('click');
    }
    // if (!this.selectForm.get(controlName)) {
    //   this.selectForm.addControl(controlName, new FormControl(undefined));
    //   this.colValue = e;
    //   if (e) {
    //     this.colValues.push(this.colValue);
    //   }
    // } else {
    //   $('#' + e.dtoName + 'chips').trigger('click');
    // }
  }

  filterValue(item: any, chips: any) {
    const controlName = this.fixString(item.name);
    const form = this.filteringForm;
    const control = form.get(controlName);
    let startValue;
    this.value = undefined;
    if (item && this.selectForm.get(controlName)!.value === 'between') {
      const fromValue = control?.get('startValue')!.value;
      const toValue = control?.get('endValue')!.value;
      control?.get('actualStartValue')!.setValue(fromValue);
      control?.get('actualEndValue')!.setValue(toValue);
      startValue = fromValue;
      if (item.type === 'number') {
        control?.get('startLabel')!.setValue(fromValue);
        control?.get('endLabel')!.setValue(toValue);
      } else if (item.type === 'date') {
        control?.get('startLabel')!.setValue(this.dateString(fromValue));
        control?.get('endLabel')!.setValue(this.dateString(toValue));
      }
      control?.get('item')!.setValue(item);
    } else {
      if (item.type !== 'radio' && item.type !== 'date') {
        this.value = control?.get('value')!.value;
        form.get(controlName)!.get('actualValue')!.setValue(control?.get('value')!.value);
        control?.get('item')!.setValue(item);
        control?.get('label')!.setValue(this.value);
      } else if (item.type === 'radio') {
        this.value = control?.get('value')!.value;
        form.get(controlName)!.get('actualValue')!.setValue(control?.get('value')!.value);
        control?.get('item')!.setValue(item);
        const label = item.radio.find((data: any) => {
          return data.value === this.value;
        }).label;
        control?.get('label')!.setValue(label);
      } else if (item.type === 'date') {
        this.value = control?.get('value')!.value;
        form.get(controlName)!.get('actualValue')!.setValue(control?.get('value')!.value);
        control?.get('item')!.setValue(item);
        control?.get('label')!.setValue(this.dateString(this.value));
      }
    }
    // if(0) is false but 0!=null is true thats why i use 2 equals
    if (this.value != null || startValue != null) {
      this.triggerFilter();
    } else {
      this.remove(item);
    }
    chips.click();
  }
  dateString(date: any) {
    const formattedDate = new Date(date);
    const formattedString = `${formattedDate.getDate()}/${formattedDate.getMonth() + 1}/${formattedDate.getFullYear()}`;
    return formattedString;
  }
  changeType(item: any, e: any) {
    if (e === 'between') {
      this.filteringForm.removeControl(this.fixString(item.name));
      this.filteringForm.addControl(this.fixString(item.name), this.betweenGroup);
    } else {
      this.filteringForm.removeControl(this.fixString(item.name));
      this.filteringForm.addControl(this.fixString(item.name), this.equalsGroup);
    }
  }
  triggerFilter() {
    const form = this.filteringForm;
    const filter: any = [];
    Object.keys(form.controls).forEach((key) => {
      const fg = form.get(key);
      const fgVal = fg?.value;
      fgVal?.item?.radio?.forEach?.((data: any) => {
        if (data.value === fgVal.actualValue) {
          if (data?.filterOption) {
            this.selectForm.get(key).setValue(data?.filterOption);
          }
        }
      });
      if (fg?.value.actualValue != null || fg?.value.actualStartValue != null) {
        const item = form.get(key)!.get('item')!.value;
        if (this.selectForm.get(key)!.value === 'between') {
          filter.push({
            column: form.get(key)!.get('item')!.value.filterName || form.get(key)!.get('item')!.value.dtoName,
            option: 'greater',
            value:
              item.type !== 'date' ? form.get(key)!.get('actualStartValue')!.value : new Date(form.get(key)!.get('actualStartValue')!.value)
          });
          filter.push({
            column: form.get(key)!.get('item')!.value.filterName || form?.get(key)!.get('item')!.value.dtoName,
            option: 'lower',
            value:
              item.type !== 'date' ? form.get(key)!.get('actualEndValue')!.value : new Date(form.get(key)!.get('actualEndValue')!.value)
          });
        } else {
          if (this.selectForm.get(key)!.value === 'equal' && item.type == 'date') {
            const value = new Date(form.get(key)!.get('actualValue')!.value);

            const end = new Date(value.setHours(23, 59, 59, 0));
            const start = new Date(value.setHours(0, 0, 0, 0));

            filter.push({
              column: form.get(key)!.get('item')!.value.filterName || form.get(key)!.get('item')!.value.dtoName,
              option: 'greater',
              value: start.toISOString()
            });
            filter.push({
              column: form.get(key)!.get('item')!.value.filterName || form?.get(key)!.get('item')!.value.dtoName,
              option: 'lower',
              value: end.toISOString()
            });
          } else {
            let option = this.selectForm.get(key)!.value;
            if (form.get(key)!.get('item')!.value.type === 'text') {
              option = 'contains';
            }
            if (form.get(key)!.get('item')!.value.dtoNames) {
              const dtoNames = form.get(key)!.get('item')!.value.dtoNames;
              console.log(dtoNames);
              dtoNames.forEach((dtoName: any) => {
                filter.push({
                  column: dtoName,
                  option: option,
                  value:
                    item.type !== 'date' ? form.get(key)!.get('actualValue')!.value : new Date(form.get(key)!.get('actualValue')!.value)
                });
              });
            } else {
              filter.push({
                column: form.get(key)!.get('item')!.value.filterName || form.get(key)!.get('item')!.value.dtoName,
                option: option,
                value: item.type !== 'date' ? form.get(key)!.get('actualValue')!.value : new Date(form.get(key)!.get('actualValue')!.value)
              });
            }
          }
        }
      }
    });
    this.filter.emit(filter);
  }

  public clearAll() {
    this.colValues = [];
    this.resetSelectForm();
    this.filteringForm = this.fb.group({});
    this.triggerFilter();
  }
  resetSelectForm() {
    Object.keys(this.selectForm.controls).forEach((data) => {
      this.selectForm.get(data)!.setValue('equal');
    });
  }
  fixString(str: string) {
    return str.replace(/ /g, '');
  }
  closedChip(e: any) {}
  removeEasyFilter(item: any) {
    this.easyFilterAction.emit({ ...item, action: 'remove' });
  }
}
