import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'av-spinner',
  templateUrl: './inline-spinner.component.html',
  styleUrls: ['./inline-spinner.component.scss']
})
export class InlineSpinnerComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
