import { of, catchError, tap, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class KycChecklistResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    const loanId = route.params['id'];
    const baseUrl = 'https://ir6sfiae54.execute-api.us-east-1.amazonaws.com';
    let url = '';
    if (this.environment.new_api_url.includes('qa')) {
      url = `${baseUrl}/qa`;
    } else if (this.environment.new_api_url.includes('staging')) {
      url = `${baseUrl}/staging`;
    } else if (this.environment.production) {
      url = `${baseUrl}/prod`;
    }
    return this.http.get(`${url}/${loanId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(undefined);
      })
    );
  }
}
