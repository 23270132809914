<div class="modal-box" id="forgotModalWizard">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-content">
        <div class="container-1 tab-content p-3">
          <div class="dialog">
            <div class="center">
              <div class="sa">
                <div class="icon"></div>
              </div>
            </div>
            <div class="title">Oops… Looks like you forgot your password</div>
            <p class="statement">
              It seems you’re trying to login, but using the wrong password. No worries, you can set a new password with a few short steps.
            </p>
  
            
          </div>
          <div class="modal-delete-footer">
            <button class="bttn-cancel mt-5 cancel-btn" (click)="cancel()">Cancel</button>
            <button class="bttn-delete mt-5 reset-btn" (click)="recoverPassword()">Reset my password</button>
            
          </div>
          <div class="n-help">
            <p>Need help?</p>
            <a class="contact-us">Click here to conatct us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  