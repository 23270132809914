import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Agt4Agribusiness, PaginatedResult, Agt4Deal } from '@avenews/agt-sdk';

@Injectable()
export class DealsResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: import('@angular/router').ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return (this.http.get(`${environment.new_api_url}/api/deal/user/${id}/1231/1/_id/desc`) as Observable<PaginatedResult<Agt4Deal>>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
