import { ValidatorFn, AbstractControl } from '@angular/forms';

export function MaxDateValidator(minYear?: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validDate = false;
    if (!minYear) {
      minYear = 0;
    }
    try {
      const date = new Date();
      const year = date.getFullYear();
      if (typeof control.value === 'number') {
        validDate = control.value <= year - minYear;
      } else {
        const split = control.value.split('/');
        const validator = new Date(split);
        validDate = validator <= date;
      }
    } catch (e) {}
    return validDate || control.value == null
      ? null
      : { futureDate: { value: control.value } };
  };
}
