import { Component, Input, OnInit } from '@angular/core';
import { svgs } from './svgs';

@Component({
  selector: 'av-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  svgs = svgs;
  @Input() iconType: string;
  constructor() {}

  ngOnInit(): void {}
}
