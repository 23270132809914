<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Trade desk application'"></av-breadcrumbs-template>
<div class="status-holder">
  <div class="d-flex justify-content-end">
    <span [matMenuTriggerFor]="menu" class="touch click status status-{{ loan?.status | statuses : statuses : true }}">{{
      loan?.status | statuses : statuses
    }}</span>
  </div>
  <mat-menu #menu="matMenu">
    <button *ngFor="let status of statuses" mat-menu-item>
      <span (click)="changeStatus(status)" class="status status-{{ status?.color }}">{{ status?.status | statuses : statuses }}</span>
    </button>
  </mat-menu>
</div>
<div id="trade-desk-container">
  <div class="desk-top">
    <div>
      <h2>Trade desk - application</h2>
    </div>
  </div>
  <div class="desk-container">
    <div class="categories">
      <div class="asignee-card">
        <div class="asignee">Assignee:</div>
        <div (click)="openAssigneeSelect(trigger)" class="asignee-section">
          <div *ngIf="assignee?._id" class="asignee-avatar">
            {{
              (assignee?.personalInformation?.firstName | slice : 0 : 1) + (assignee?.personalInformation?.lastName | slice : 0 : 1)
                | uppercase
            }}
          </div>
          <div class="asignee-full-name">
            {{
              !assignee?._id
                ? 'Unassigned'
                : (assignee?.personalInformation?.firstName + ' ' + assignee?.personalInformation?.lastName | titlecase)
            }}
          </div>
          <div class="asignee-end">
            <i-feather name="chevron-down"></i-feather>
          </div>
        </div>
      </div>
      <av-select-overlay
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        [element]="selectContainer"
        [(isOpen)]="selectOpen"
        [avatar]="true"
        [items]="users"
        [allowSearch]="true"
        [viewValue]="['personalInformation.firstName', 'personalInformation.lastName']"
        [searchFields]="['personalInformation.firstName', 'personalInformation.lastName']"
        [selected]="assignee"
        (selectedChange)="assignTo($event)"
      ></av-select-overlay>
      <div class="identity-card">
        <div class="header">
          <div class="initials">
            {{ loan?.personalDetails?.firstName | uppercase | slice : 0 : 1
            }}{{ loan?.personalDetails?.lastName | uppercase | slice : 0 : 1 }}
          </div>
          <div class="full-name">{{ loan?.personalDetails?.firstName }} {{ loan?.personalDetails?.lastName }}</div>
        </div>
        <div class="d-flex flex-column mt-3">
          <div class="info-section">
            <div class="icon">
              <i-feather name="credit-card"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">National ID:</p>
              <p class="soft-p">{{ loan?.personalDetails?.nationalId }}</p>
            </div>
          </div>
          <div class="info-section">
            <div class="icon">
              <i-feather name="briefcase"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">Business name:</p>
              <p class="soft-p">{{ loan?.businessDetails?.name }}</p>
            </div>
          </div>
          <div class="info-section">
            <div class="icon">
              <i-feather name="phone-call"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">Phone:</p>
              <p class="soft-p">{{ loan?.personalDetails?.primaryPhoneNumber }}</p>
            </div>
          </div>
          <div class="info-section">
            <div class="icon">
              <i-feather name="at-sign"></i-feather>
            </div>
            <div class="info">
              <p class="bold-p">Email:</p>
              <p class="soft-p">{{ loan?.personalDetails?.email }}</p>
            </div>
          </div>
          <div *ngIf="loan?.source === 'INPUTS_NETWORK'" class="info-section">
            <div class="info flex justify-center w-full">
              <p class="soft-p status status-purple">Inputs Network</p>
            </div>
          </div>
        </div>
      </div>
      <td-signoff [user]="currentUser" [originalOffer]="originalOffer" [loan]="loan" [mlScoreData]="mlScoreData"></td-signoff>

      <div (click)="openLoanDetails()" class="category click">
        <div #tooltip="matTooltip" matTooltip="please fill in the loan details by clicking here" class="category-header">
          <div class="vertical-header">
            <p>ML Model</p>
          </div>
          <div class="score-holder end-score ml-cat">
            <ng-container *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'; else elseAgent">
              <p *ngIf="actualMlScore != null; else spinner" class="user-score">
                <span>{{ actualMlScore || 0 | number : '1.0-2' }}/</span>
                {{ mlScore }}%
              </p>
            </ng-container>
            <ng-template #elseAgent>
              <p>{{ actualMlScore && 1 }}/{{ 1 }}</p>
            </ng-template>
            <ng-template #spinner>
              <img #tooltip="matTooltip" matTooltip="Calculating" class="spinna" src="../../../assets/loading.gif" alt="" />
            </ng-template>
          </div>
        </div>
      </div>
      <td-disb-ml [user]="currentUser" [originalOffer]="originalOffer" [loan]="loan" [mlScoreData]="mlScoreData"></td-disb-ml>
      <div
        (click)="chooseCategory(i)"
        [ngClass]="{ active: category?._id === chosenCategory?._id }"
        *ngFor="let category of categories; let i = index"
        class="category click"
      >
        <ng-container >
          <div class="category-header">
            <div class="vertical-header">
              <div>
                <p>{{ category?.name }}</p>
              </div>
              <div *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'" class="q-score">
                <p>{{ answeredQuestions(category) }}/{{ activeLength(category?.questions)?.length }} Q</p>
              </div>
            </div>
            <div [ngClass]="{ pt3: currentUser?.policyRole?.name === 'avenews-agent' }" class="end-score">
              <ng-container *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'; else noScore">
                <p class="user-score">
                  <span>{{ calcAnswerPercentage(category) | number : '1.0-2' }}/</span>
                  {{ category?.score }}%
                </p>
              </ng-container>
              <ng-template #noScore>
                <p class="user-score">{{ answeredQuestions(category) }}/{{ activeLength(category?.questions)?.length }} Q</p>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'" class="category progress">
        <div class="category-header">
          <div class="vertical-header">
            <p>Finance application score</p>
          </div>
          <div class="total-score">
            <circle-progress [progress]="financeScore">{{ financeScore | number : '1.0-2' }}%</circle-progress>
          </div>
        </div>
      </div>
      <div *ngIf="financeScore" [ngClass]="loan?.customStatus?.status" class="application-status">
        <div class="status-text">{{ loan?.customStatus?.name }}</div>
      </div>
      <div *ngIf="mlScoreData?.decision?.decisionTree" [ngClass]="loan?.decisionTreeStatus?.status" class="application-status">
        <div class="status-text">Decision tree status: {{ loan?.decisionTreeStatus?.name }}</div>
      </div>
    </div>
    <mat-tab-group (selectedTabChange)="tabChanged($event)" #tabGroup>
      <mat-tab label="Questions">
        <div *ngIf="selectedIndex === 0" class="questions">
          <div class="questions-panel">
            <p class="text-right">
              <!-- Score <span class="bold-p">{{ calcAnswersByCat }}/</span>{{ calcTotalQuestionScore }} -->
            </p>
            <div [hidden]="!question?.active" *ngFor="let question of chosenCategory?.questions; let i = index" class="question-section">
              <ng-container>
                <div (click)="expand(content, icon)" class="question-header">
                  <div class="d-flex align-items-center">
                    <div class="checkmark-container">
                      <div [ngClass]="{ completed: arrayNotEmpty(question?.answer) }" class="checkmark-circle">
                        <i-feather name="check"></i-feather>
                      </div>
                    </div>
                    <p class="question-name">{{ question?.label }}</p>
                  </div>
                  <div class="points">
                    <!-- <p>Score {{ question?.userScore }}/{{ question?.score | number: '1.0-2' }}</p> -->
                    <i-feather #icon (click)="expand(content, icon)" name="chevron-down"></i-feather>
                  </div>
                </div>
                <div #content class="question-content">
                  <div
                    class="content"
                    [ngClass]="{
                      'align-items-baseline': question?.type !== 'textarea'
                    }"
                  >
                    <app-base-form-template
                      *ngIf="question?.type === 'textarea' || question?.type === 'number'"
                      [rOnly]="!editMode"
                      [size]="+question?.size || 6"
                      labelName="{{ question?.label }}"
                      [group]="$any(this.questionsForm.get(question?._id))"
                      inputType="{{ question?.type }}"
                      inputName="default"
                      controlName="answer"
                    >
                    </app-base-form-template>
                    <app-base-form-template
                      *ngIf="question?.type === 'radio' || question?.type === 'checkbox' || question?.type === 'select'"
                      [rOnly]="!editMode"
                      [size]="+question?.size || 6"
                      labelName="{{ question?.label }}"
                      [group]="$any(this.questionsForm.get(question?._id))"
                      [inputType]="question?.type"
                      [inputName]="question?.type === 'checkbox' ? 'new-checkbox' : question?.type"
                      [radios]="question?.multipleChoices"
                      [checkboxes]="question?.multipleChoices"
                      [options]="question?.multipleChoices"
                      bindLabel="viewValue"
                      bindValue="value"
                      (openSelect)="expandMore(content, $event)"
                      [isColumn]="true"
                      controlName="answer"
                    ></app-base-form-template>
                    <app-base-form-template
                      [rOnly]="!editMode"
                      [size]="+question?.size || 6"
                      labelName="Agent notes"
                      [group]="$any(this.questionsForm.get(question?._id))"
                      inputType="textarea"
                      controlName="notes"
                      inputName="default"
                    >
                    </app-base-form-template>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="buttons">
            <button (click)="edit()" [ngClass]="{ 'btn-actions': !editMode, 'btn-primary': editMode }" class="bttn">
              {{ editMode ? 'Save' : 'Edit' }}
            </button>
            <button
              *ngIf="loan?.customStatus?.status === 'decline'"
              #tooltip="matTooltip"
              matTooltip="Can't generate offer because this application is declined"
              [class.disabled]="loan?.customStatus?.status === 'decline'"
              (click)="generateOffer()"
              class="btn-success bttn"
            >
              Generate Offer
            </button>
            <button
              *ngIf="loan?.customStatus?.status !== 'decline'"
              [class.disabled]="loan?.customStatus?.status === 'decline'"
              (click)="generateOffer()"
              class="btn-success bttn"
            >
              Generate Offer
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Decision support">
        <div *ngIf="selectedIndex === 1" class="decision-support">
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Crop calendar</p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.crop">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button *ngIf="!miniSpinners?.crop" (click)="tdService.checkCrop(cropForm, loan?._id)" class="bttn btn-actions">
                  Check
                </button>
              </div>
            </div>
            <div class="help-content">
              <div class="gray-back d-flex">
                <app-base-form-template
                  [size]="3"
                  labelName="Crop"
                  [group]="cropForm"
                  inputName="select"
                  inputType="select"
                  [options]="cropOptions"
                  [noSearch]="false"
                  bindLabel="viewValue"
                  bindValue="value"
                  controlName="cropType"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Planting"
                  [group]="cropForm"
                  inputName="default"
                  inputType="text"
                  controlName="planting"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Harvesting"
                  [group]="cropForm"
                  inputName="default"
                  inputType="text"
                  controlName="harvesting"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Peak season"
                  [group]="cropForm"
                  inputName="default"
                  inputType="text"
                  controlName="peak"
                ></app-base-form-template>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Crop price checker</p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.cropPrices">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button
                  *ngIf="!miniSpinners?.cropPrices"
                  (click)="tdService.checkCropPrices(cropPriceForm, loan?._id)"
                  class="bttn btn-actions"
                >
                  Check
                </button>
              </div>
            </div>
            <div class="help-content">
              <div class="d-flex flex-wrap">
                <app-base-form-template
                  [size]="6"
                  labelName="Crop"
                  [group]="cropPriceForm"
                  inputName="select"
                  [noSearch]="false"
                  inputType="select"
                  [options]="cropPriceOptions"
                  bindLabel="viewValue"
                  bindValue="value"
                  controlName="cropName"
                ></app-base-form-template>
                <div class="col-md-9"></div>
                <div class="col-md-12">
                  <h3>Whole sale</h3>
                </div>
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Min"
                  [group]="wholeSale"
                  inputName="default"
                  inputType="text"
                  controlName="min"
                >
                  <span #append append>KES</span></app-base-form-template
                >
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Max"
                  [group]="wholeSale"
                  inputName="default"
                  inputType="text"
                  controlName="max"
                  ><span #append append>KES</span></app-base-form-template
                >
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Average"
                  [group]="wholeSale"
                  inputName="default"
                  inputType="text"
                  controlName="avg"
                  ><span #append append>KES</span></app-base-form-template
                >
                <div class="col-md-12">
                  <h3>Retail</h3>
                </div>
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Min"
                  [group]="retail"
                  inputName="default"
                  inputType="text"
                  controlName="min"
                >
                  <span #append append>KES</span></app-base-form-template
                >
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Max"
                  [group]="retail"
                  inputName="default"
                  inputType="text"
                  controlName="max"
                  ><span #append append>KES</span></app-base-form-template
                >
                <app-base-form-template
                  [size]="3"
                  [rOnly]="true"
                  labelName="Average"
                  [group]="retail"
                  inputName="default"
                  inputType="text"
                  controlName="avg"
                  ><span #append append>KES</span></app-base-form-template
                >
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Social media</p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.email">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button
                  *ngIf="!miniSpinners?.email"
                  (click)="tdService.checkSocialMedia(emailSMF, 'email', loan?._id)"
                  class="bttn btn-actions"
                >
                  Check
                </button>
              </div>
            </div>
            <div class="help-content">
              <div class="d-flex">
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  [size]="3"
                  labelName="Email"
                  [group]="emailSMF"
                  inputName="default"
                  inputType="text"
                  controlName="username"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Twitter"
                  [group]="emailSMF"
                  inputName="default"
                  inputType="text"
                  controlName="twitter"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="LinkedIn"
                  [group]="emailSMF"
                  inputName="default"
                  inputType="text"
                  controlName="linkedin"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Facebook"
                  [group]="emailSMF"
                  inputName="default"
                  inputType="text"
                  controlName="facebook"
                ></app-base-form-template>
              </div>
            </div>
            <div class="help-header">
              <div class="help-left">
                <p></p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.phone">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button
                  *ngIf="!miniSpinners?.phone"
                  (click)="tdService.checkSocialMedia(phoneSMF, 'phone', loan?._id)"
                  class="bttn btn-actions"
                >
                  Check
                </button>
              </div>
            </div>
            <div class="help-content">
              <div class="d-flex">
                <app-base-form-template
                  [size]="3"
                  labelName="Phone number"
                  [group]="phoneSMF"
                  inputName="default"
                  inputType="text"
                  controlName="username"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Twitter"
                  [group]="phoneSMF"
                  inputName="default"
                  inputType="text"
                  controlName="twitter"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="LinkedIn"
                  [group]="phoneSMF"
                  inputName="default"
                  inputType="text"
                  controlName="linkedin"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Facebook"
                  [group]="phoneSMF"
                  inputName="default"
                  inputType="text"
                  controlName="facebook"
                ></app-base-form-template>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Business Validation</p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.business">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button
                  *ngIf="!miniSpinners?.business"
                  (click)="tdService.checkBusiness(businessForm, 'business', loan?._id)"
                  class="bttn btn-actions"
                >
                  Check
                </button>
              </div>
            </div>
            <div class="help-content">
              <div class="d-flex">
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  [size]="3"
                  labelName="Business number"
                  [group]="businessForm"
                  inputName="default"
                  inputType="text"
                  controlName="number"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Business Name"
                  [group]="businessForm"
                  inputName="default"
                  inputType="text"
                  controlName="name"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Status"
                  [group]="businessForm"
                  inputName="default"
                  inputType="text"
                  controlName="status"
                ></app-base-form-template>
              </div>
            </div>
          </div>

          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>M-Pesa insights</p>
              </div>
              <div class="help-right"></div>
            </div>

            <div *ngFor="let insightForm of $any(insightsArray?.controls); let i = index" class="help-content">
              <p *ngIf="!insightForm.get('personalInfo')?.value?.statementPeriod" class="insight-p">Insights {{ i + 1 }}</p>
              <p *ngIf="insightForm.get('personalInfo')?.value?.statementPeriod" class="insight-p">
                {{ insightForm.get('personalInfo')?.value?.statementPeriod }}
              </p>
              <div class="d-flex">
                <div
                  [ngClass]="{ 'has-error': insightForm?.get('file')?.touched && insightForm?.get('file')?.invalid }"
                  class="form-group file-group col-md-5"
                >
                  <label #file for="mpesa-file-{{ i }}">File</label>
                  <div class="fake-file">
                    <div (click)="file.click()" class="fake-button">Choose file</div>
                    <div #tooltip="matTooltip" matTooltip="{{ insightForm?.get('fileName')?.value }}" class="name">
                      {{ insightForm?.get('fileName')?.value }}
                    </div>
                  </div>
                  <input (change)="uploadDoc($event, i)" accept="application/pdf" hidden id="mpesa-file-{{ i }}" type="file" />
                  <div *ngIf="insightForm?.get('fileName')?.hasError('invalidFile')" class="help-block">File not supported</div>
                </div>
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  [size]="5"
                  labelName="Password"
                  [group]="insightForm"
                  inputName="default"
                  inputType="text"
                  controlName="password"
                ></app-base-form-template>
                <div class="col-md-2 d-flex align-items-center mini-spinner">
                  <button
                    *ngIf="!miniSpinners?.insights[i]"
                    (click)="tdService.checkInsights(insightForm, loan, i)"
                    class="bttn btn-actions"
                  >
                    Check
                  </button>
                  <img *ngIf="miniSpinners?.insights[i]" src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
              </div>
              <div *ngIf="insightForm?.get('periods')?.value === 'invalid'">File not supported</div>

              <ng-container *ngIf="insightForm.get('periods')?.value !== 'invalid'">
                <mat-accordion>
                  <mat-expansion-panel class="border-slate-300 rounded-lg shadow-sm">
                    <mat-expansion-panel-header>
                      <mat-panel-title
                        *ngIf="insightForm.get('personalInfo')?.value?.organisationName; else personal"
                        class="font-bold text-slate-800"
                        >Business Mpesa {{ i + 1 }}</mat-panel-title
                      >
                      <ng-template #personal>
                        <mat-panel-title class="font-bold text-slate-800">Personal Mpesa {{ i + 1 }}</mat-panel-title>
                      </ng-template>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="insightForm.get('personalInfo')?.value">
                      <h3 class="mt-3 mb-3">Personal Information</h3>
                      <div class="d-flex">
                        <app-base-form-template
                          *ngIf="insightForm.get('personalInfo')?.value?.customerName"
                          [ngClass]="{
                            'red-input': insightForm.get('extra').value?.statementStatus?.name === false,
                            'green-input': insightForm.get('extra').value?.statementStatus?.name === true
                          }"
                          [value]="insightForm.get('personalInfo')?.value?.customerName"
                          [size]="3"
                          labelName="Name"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala"
                        >
                        </app-base-form-template>
                        <app-base-form-template
                          *ngIf="insightForm.get('personalInfo')?.value?.organisationName"
                          [ngClass]="{
                            'red-input': insightForm.get('extra').value?.statementStatus?.name === false,
                            'green-input': insightForm.get('extra').value?.statementStatus?.name === true
                          }"
                          [value]="insightForm.get('personalInfo')?.value?.organisationName"
                          [size]="3"
                          labelName="Business name"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala"
                        >
                        </app-base-form-template>
                        <app-base-form-template
                          *ngIf="insightForm.get('personalInfo')?.value?.mobileNumber"
                          [ngClass]="{
                            'red-input': insightForm.get('extra').value?.statementStatus?.phoneNumber === false,
                            'green-input': insightForm.get('extra').value?.statementStatus?.phoneNumber === true
                          }"
                          [size]="3"
                          [value]="insightForm.get('personalInfo')?.value?.mobileNumber"
                          labelName="Phone number"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala1"
                        >
                        </app-base-form-template>
                        <app-base-form-template
                          *ngIf="insightForm.get('personalInfo')?.value?.statementPeriod"
                          [ngClass]="{
                            'red-input': insightForm.get('extra').value?.statementStatus?.date === false,
                            'green-input': insightForm.get('extra').value?.statementStatus?.date === true
                          }"
                          [size]="3"
                          [value]="insightForm.get('personalInfo')?.value?.statementPeriod"
                          labelName="Statement Period"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala1"
                        >
                        </app-base-form-template>
                        <app-base-form-template
                          *ngIf="insightForm.get('personalInfo')?.value?.emailAddress"
                          [ngClass]="{
                            'red-input': insightForm.get('extra').value?.statementStatus?.email === false,
                            'green-input': insightForm.get('extra').value?.statementStatus?.email === true
                          }"
                          [size]="3"
                          [value]="insightForm.get('personalInfo')?.value?.emailAddress"
                          labelName="Customer Email"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala2"
                        >
                        </app-base-form-template>
                      </div>
                    </ng-container>
                    <h3 class="mt-3 mb-3">Month By Month Insights</h3>
                    <div class="gray-back insight" *ngFor="let period of insightForm.get('periods')?.value; let j = index">
                      <div class="d-flex">
                        <app-base-form-template
                          [value]="period?.date"
                          [size]="3"
                          labelName="Date"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="date"
                        >
                        </app-base-form-template>
                        <app-base-form-template
                          [size]="3"
                          [value]="period?.income"
                          labelName="Income"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="income"
                        >
                          <span #append append>KES</span>
                        </app-base-form-template>
                        <app-base-form-template
                          [size]="3"
                          [value]="period?.withdrawn"
                          labelName="Expenses"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="expenses"
                        >
                          <span #append append>KES</span>
                        </app-base-form-template>
                      </div>
                      <div class="d-flex">
                        <app-base-form-template
                          [errorMessages]="errorMessages"
                          [size]="3"
                          [rOnly]="true"
                          labelName="Loan"
                          [value]="period?.loanRatio"
                          inputName="random"
                          inputType="text"
                          controlName="loan"
                        >
                          <span #append append>%</span>
                        </app-base-form-template>
                        <app-base-form-template
                          [size]="3"
                          labelName="Bet"
                          [value]="period?.betRatio"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="bet"
                        >
                          <span #append append>%</span>
                        </app-base-form-template>
                        <app-base-form-template
                          [size]="3"
                          labelName="Overdraft"
                          [value]="period?.overdraftRatio"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="overdraft"
                        >
                          <span #append append>%</span>
                        </app-base-form-template>
                        <app-base-form-template
                          [size]="3"
                          labelName="Net income"
                          [value]="period?.netIncome"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="netIncome"
                        >
                          <span #append append>KES</span>
                        </app-base-form-template>
                      </div>
                    </div>
                    <ng-container *ngIf="insightForm.get('personalInfo')?.value">
                      <h3 class="mt-3 mb-3">Periodic Totals</h3>
                      <div class="d-flex">
                        <app-base-form-template
                          [value]="insightForm.get('periodicTotals')?.value?.totalWithdrawn || 0 | number : '1.0-2'"
                          [size]="3"
                          labelName="Total Withdrawn"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.totalSalary || 0 | number : '1.0-2'"
                          labelName="Total Salary"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala1"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.totalIncome || 0 | number : '1.0-2'"
                          labelName="Total Income"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala2"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.totalNetIncome || 0 | number : '1.0-2'"
                          labelName="Total Net Income"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala2"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                      </div>
                      <h3 class="mt-3 mb-3">Periodic Averages</h3>

                      <div class="d-flex">
                        <app-base-form-template
                          [value]="insightForm.get('periodicTotals')?.value?.avgWithdrawn || 0 | number : '1.0-2'"
                          [size]="3"
                          labelName="Average Withdrawn"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.avgSalary || 0 | number : '1.0-2'"
                          labelName="Average Salary"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala1"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.avgIncome || 0 | number : '1.0-2'"
                          labelName="Average Income"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala2"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                        <app-base-form-template
                          [size]="3"
                          [value]="insightForm.get('periodicTotals')?.value?.avgNetIncome || 0 | number : '1.0-2'"
                          labelName="Average Net Income"
                          [rOnly]="true"
                          inputName="random"
                          inputType="text"
                          controlName="nala2"
                        >
                          <span #append append>KES</span></app-base-form-template
                        >
                      </div>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>

              <div
                (click)="addAnotherInsight()"
                *ngIf="i === insightsArray.length - 1 && insightsArray.at(i).get('periods').value?.length"
                class="another-insight mt-4"
              >
                <i-feather name="plus"></i-feather>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Bank statement insights</p>
              </div>
              <div class="help-right"></div>
            </div>

            <div *ngFor="let insightForm of $any(bankInsightsArray?.controls); let i = index" class="help-content">
              <p class="insight-p">Statement {{ i + 1 }}</p>
              <div class="d-flex">
                <div
                  [ngClass]="{ 'has-error': insightForm?.get('file')?.touched && insightForm?.get('file')?.invalid }"
                  class="form-group file-group col-md-4"
                >
                  <label #file for="bank-insight-{{ i }}">File</label>
                  <div class="fake-file">
                    <div (click)="file.click()" class="fake-button">Choose file</div>
                    <div #tooltip="matTooltip" matTooltip="{{ insightForm?.get('fileName')?.value }}" class="name">
                      {{ insightForm?.get('fileName')?.value }}
                    </div>
                  </div>
                  <input
                    (change)="uploadDoc($event, i, insightForm)"
                    accept="application/pdf"
                    hidden
                    id="bank-insight-{{ i }}"
                    type="file"
                  />
                  <div *ngIf="insightForm?.get('fileName')?.hasError('invalidFile')" class="help-block">File not supported</div>
                </div>
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  [size]="3"
                  labelName="Password"
                  [group]="insightForm"
                  inputName="default"
                  inputType="text"
                  controlName="password"
                ></app-base-form-template>
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  [size]="3"
                  labelName="Bank name"
                  [options]="bankOptions"
                  bindLabel="viewValue"
                  bindValue="value"
                  [group]="insightForm"
                  inputName="select"
                  inputType="select"
                  controlName="bankName"
                ></app-base-form-template>
                <div class="col-md-2 d-flex align-items-center mini-spinner">
                  <button
                    *ngIf="!miniSpinners?.bankInsights[i]"
                    (click)="tdService.checkBankInsight(insightForm, loan?._id, i)"
                    class="bttn btn-actions"
                  >
                    Check
                  </button>
                  <img *ngIf="miniSpinners?.bankInsights[i]" src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
              </div>
              <ng-container *ngIf="insightForm.get('data')?.value?.months?.length">
                <div class="gray-back insight" *ngFor="let period of insightForm.get('data')?.value?.months; let j = index">
                  <div class="d-flex">
                    <app-base-form-template
                      [value]="period?.date"
                      [size]="4"
                      labelName="Date"
                      [rOnly]="true"
                      inputName="random"
                      inputType="text"
                      controlName="date"
                    >
                    </app-base-form-template>
                    <app-base-form-template
                      [size]="4"
                      [value]="period?.['paidIn']"
                      labelName="Paid-in"
                      [rOnly]="true"
                      inputName="random"
                      inputType="text"
                      controlName="income"
                    >
                      <span #append append>KES</span>
                    </app-base-form-template>
                    <app-base-form-template
                      [size]="4"
                      [value]="period?.withdrawn"
                      labelName="Withdrawn"
                      [rOnly]="true"
                      inputName="random"
                      inputType="text"
                      controlName="expenses"
                    >
                      <span #append append>KES</span>
                    </app-base-form-template>
                  </div>
                </div>
                <div *ngIf="insightForm.get('data')?.value?.total" class="d-flex flex-col flex-wrap">
                  <h3 class="font-merri text-xl !font-semibold mb-3 mt-3">Totals</h3>
                  <div class="d-flex flex-wrap">
                    <app-base-form-template
                      [errorMessages]="errorMessages"
                      [size]="6"
                      [rOnly]="true"
                      labelName="Total Paid-in"
                      [value]="insightForm?.value?.data?.total?.totalPaidIn"
                      inputName="random"
                      inputType="text"
                      controlName="totalPaidIn"
                    >
                      <span #append append>KES</span>
                    </app-base-form-template>
                    <app-base-form-template
                      [size]="6"
                      labelName="Total Withdrawn"
                      [value]="insightForm?.value?.data?.total?.totalWithdrawn"
                      [rOnly]="true"
                      inputName="random"
                      inputType="text"
                      controlName="bet"
                    >
                      <span #append append>KES</span>
                    </app-base-form-template>
                  </div>
                  <div class="d-flex flex-col flex-wrap">
                    <h3 class="font-merri text-xl !font-semibold mb-3 mt-3">Averages</h3>
                    <div class="flex">
                      <app-base-form-template
                        [size]="6"
                        labelName="Average Paid-in"
                        [value]="insightForm?.value?.data?.total?.avgPaidIn"
                        [rOnly]="true"
                        inputName="random"
                        inputType="text"
                        controlName="overdraft"
                      >
                        <span #append append>KES</span>
                      </app-base-form-template>
                      <app-base-form-template
                        [size]="6"
                        labelName="Average Withdrawn"
                        [value]="insightForm?.value?.data?.total?.avgWithdrawn"
                        [rOnly]="true"
                        inputName="random"
                        inputType="text"
                        controlName="netIncome"
                      >
                        <span #append append>KES</span>
                      </app-base-form-template>
                    </div>
                  </div>
                  <div class="d-flex flex-col flex-wrap">
                    <h3 class="font-merri text-xl !font-semibold mb-3 mt-3">File</h3>
                    <div class="flex">
                      <app-base-form-template
                        [size]="6"
                        labelName="File valid?"
                        [value]="insightForm?.value?.fileValid"
                        [rOnly]="true"
                        inputName="random"
                        inputType="text"
                        controlName="overdraft"
                      >
                      </app-base-form-template>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                (click)="addAnotherBankInsight()"
                *ngIf="i === bankInsightsArray.length - 1 && bankInsightsArray.at(i).get('data').value?.months?.length"
                class="another-insight"
              >
                <i-feather name="plus"></i-feather>
              </div>
            </div>
          </div>

          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Bank statement</p>
              </div>
              <div class="help-right">
                <div class="mini-spinner" *ngIf="miniSpinners?.bankStatement">
                  <img src="../../../assets/loading.gif" width="40" height="40" alt="" />
                </div>
                <button
                  *ngIf="!miniSpinners?.bankStatement"
                  (click)="tdService.checkBankStatements(bankFormArray, loan?._id)"
                  class="bttn btn-actions"
                >
                  Check
                </button>
              </div>
            </div>

            <div class="help-content">
              <div [formGroup]="$any(bankForm)" *ngFor="let bankForm of bankFormArray.controls; let i = index" class="d-flex">
                <app-base-form-template
                  [size]="4"
                  labelName="Income"
                  [group]="$any(bankForm)"
                  inputName="default"
                  inputType="number"
                  controlName="income"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="4"
                  labelName="Expense"
                  [group]="$any(bankForm)"
                  inputName="default"
                  inputType="number"
                  controlName="expense"
                >
                </app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Month"
                  [group]="$any(bankForm)"
                  [options]="months"
                  bindLabel="viewValue"
                  bindValue="value"
                  inputName="select"
                  inputType="select"
                  controlName="month"
                >
                </app-base-form-template>
                <div class="adder">
                  <!-- <div *ngIf="i === bankFormArray.length - 1 && i < 3">
                    <i-feather (click)="addBankStatement()" class="plus" name="plus"></i-feather>
                  </div> -->
                  <div>
                    <i-feather (click)="removeBankStatement(i)" class="trash" name="trash-2"></i-feather>
                  </div>
                </div>
              </div>
              <div (click)="addBankStatement()" class="another-insight">
                <i-feather name="plus"></i-feather>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Application business information</p>
              </div>
            </div>

            <div class="help-content">
              <div class="d-flex wrap">
                <app-base-form-template
                  [size]="3"
                  labelName="Deal margin"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="dealMargin"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Deal ROI"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="dealROI"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Period weeks"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="periodWeeks"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Annualized ROI"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="annualROI"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Total buying"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="totalBuying"
                ></app-base-form-template>
                <app-base-form-template
                  [size]="3"
                  labelName="Total selling"
                  [group]="extraForm"
                  [rOnly]="true"
                  inputName="default"
                  inputType="text"
                  controlName="totalSelling"
                ></app-base-form-template>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>KYC</p>
              </div>
              <div class="help-right">
                <!-- <button (click)="viewKyc()" class="bttn btn-actions">View KYC</button> -->
              </div>
            </div>

            <div class="help-content">
              <div class="d-flex wrap">
                <input readonly class="kyc-control form-control" [value]="kycLink" type="text" />
                <button (click)="copyToClipboard(kycLink)" class="bttn btn-actions">Copy to clipboard</button>
              </div>
            </div>
          </div>
          <div class="help-section">
            <div class="help-header">
              <div class="help-left">
                <p>Mono</p>
              </div>
            </div>

            <div class="help-content">
              <div class="d-flex wrap">
                <input
                  readonly
                  class="kyc-control form-control"
                  [value]="'https://app.avenews.io/bank-statement?loanId=' + loan?._id"
                  type="text"
                />
                <button (click)="copyToClipboard('https://app.avenews.io/bank-statement?loanId=' + loan?._id)" class="bttn btn-actions">
                  Copy to clipboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Products">
        <frontend-products [loan]="loan" [edit]="true" *ngIf="selectedIndex === 2"></frontend-products>
      </mat-tab>
      <mat-tab label="Offer">
        <av-loan-offer
          [user]="currentUser"
          [mlScoreId]="mlScoreData?._id"
          *ngIf="selectedIndex === 3"
          [originalOffer]="originalOffer"
          [loan]="loan"
          [offer]="offer"
        ></av-loan-offer>
      </mat-tab>
      <mat-tab label="Documents">
        <td-documents *ngIf="selectedIndex === 4" [loan]="loan"></td-documents>
      </mat-tab>
      <mat-tab label="Notes">
        <td-notes [users]="users" [loan]="loan"></td-notes>
      </mat-tab>
      <mat-tab label="KYC">
        <td-kyc [currentUser]="currentUser" [loan]="loan" [mlScoreData]="mlScoreData"></td-kyc>
      </mat-tab>
      <mat-tab *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'" label="Activity log">
        <td-activity-log [users]="users" [mlScoreData]="mlScoreData" [loan]="loan" [user]="currentUser"></td-activity-log>
      </mat-tab>
      <mat-tab *ngIf="currentUser?.policyRole?.name !== 'avenews-agent'" label="Bureau">
        <td-bureau [mlScoreData]="mlScoreData" [loan]="loan" [user]="currentUser"></td-bureau>
      </mat-tab>
      <mat-tab label="Decision Tree">
        <td-decision-tree
          [mlScore]="actualMlScore"
          [tdScore]="financeScore"
          [mlScoreData]="mlScoreData"
          [loan]="loan"
          [user]="currentUser"
        ></td-decision-tree>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<av-loan-details [loanDetails]="loanDetails" (closed)="closed($event)" [loan]="loan"></av-loan-details>
<frontend-reason-modal title="Decline reaosn" (closed)="reasonRes(null)" (submitted)="resolveReason($event)"></frontend-reason-modal>
