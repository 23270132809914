import { Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';

@Component({
  selector: 'documents-list-modal',
  templateUrl: './documents-list-modal.html',
  styleUrls: ['./documents-list-modal.scss']
})
export class DocumentsListModal implements OnInit {
  options: any;
  modalService: ModalService;
  documents: any[];
  header = 'Transaction Documents';
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.options?.documents?.length) {
      this.documents = this.options.documents;
      console.log(this.documents);
    }
    this.header = this.options?.header || this.header;
  }

  close() {
    this.modalService.close();
  }
}
