import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { EMPTY, catchError } from 'rxjs';

@Injectable()
export class FilesService {
  constructor(private http: HttpClient) {
    GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.js';
  }

  checkPDFPassword(file: any, password: string): Promise<boolean> {
    const fileData = this.base64ToUint(file);
    return new Promise((res) => {
      pdfjsLib
        .getDocument({ data: fileData, password })
        .promise.then((pdf) => {
          pdf
            .getMetadata()
            .then((metadata) => {
              //   console.log(metadata);
              //   if (metadata?.info?.['Encrypted'] || metadata?.metadata?.['encrypted']) {
              //     console.log('invalid pass');
              //   } else {
              //     console.log('valid pass');
              //   }
              res(true);
            })
            .catch((err) => {
              res(false);
            });
        })
        .catch((err) => {
          if (err.message === 'Incorrect Password') {
            console.log('invalid file pass');
            res(false);
          }
          res(false);
        });
    });
  }

  base64ToUint(base64File) {
    const decodedData = atob(base64File);
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }
    return uint8Array;
  }
  async getFileUrl(document: { bucket: string; s3Path: string }): Promise<{ url: string }> {
    return new Promise((res, rej) => {
      this.http
        .post(`https://gc0an3fw5j.execute-api.us-east-1.amazonaws.com/prod`, {
          bucket: document?.bucket,
          file: document?.s3Path,
          region_name: document?.bucket === 'agt-new-files-local-dev-staging' ? 'us-east-1' : 'us-east-2'
        })
        .pipe(
          catchError((err) => {
            rej(err);
            return EMPTY.pipe();
          })
        )
        .subscribe((data: any) => {
          res(data);
        });
    });
  }
}
