<nav class="navbar navbar-primary navbar-transparent navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <a class="navbar-brand" href="https://www.avenews-gt.com"
        ><img src="../../../assets/img/logo-20cm-grey.png" width="168" height="34"
      /></a>
    </div>
    <div class="pull-right pt-3">
      <button class="bttn-transparent" routerLink="/login">Sign in</button>
      <a class="bttn-transparent" href="https://www.avenews-gt.com/apply">Apply Now</a>
    </div>
  </div>
</nav>

<div class="container content-container">
  <p>
    <strong
      >Avenews-GT Terms of Use <br />
      Last Revised: August 20, 2019</strong
    >
  </p>
  <p>
    Avenews-GT Ltd. and its affiliates (<strong>“Avenews-GT ", "we", "our", "Company"</strong>) welcomes you (the
    <strong>"User(s)", or "you"</strong>) to our website platform available at
    <a routerLink="/login" target="_blank">https://agt-platform.com/login</a>
    (the <strong>“Site”</strong>) and our mobile application (the "App", the Site and the App shall be referred to collectively as the
    <strong>"Platform"</strong>). Each of the Platform's Users may use them in accordance with the terms and conditions hereunder.
  </p>

  <ol>
    <li>
      <strong>Acceptance of the Terms</strong>
      <p>
        By entering, connecting to, accessing or using the Platform, and/or by installing and/or downloading the App on your mobile device,
        you acknowledge that you have read and understood the following terms of use, including the terms of our Privacy Policy available
        at:
        <a routerLink="/privacy-policy" target="_blank">https://agt-platform.com/privacy-policy</a>
        (the <strong>"Privacy Policy"</strong>) (collectively, the <strong>"Terms"</strong> ) and you agree to be bound by them and to
        comply with all applicable laws and regulations regarding your use of the Platform and you acknowledge that these Terms constitute a
        binding and enforceable legal contract between Avenews-GT and you.
        <strong
          >IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ENTER TO, CONNECT TO, ACCESS OR USE THE PLATFORM IN ANY MANNER, DO NOT INSTALL
          THE APP AND/OR PROMPTLY UNINSTALL THE APP FROM YOUR MOBILE DEVICE.</strong
        >
      </p>
      <p>
        You hereby acknowledge that the Terms govern your use of the Platform. Additionally, Avenews-GT maintains a website serving mainly
        as an informative marketing tool, available at:
        <a href="https://www.avenews-gt.com/" target="_blank">https://www.avenews-gt.com/</a>. This website is governed by its applicable
        the Terms of Use and Privacy Policy available at
        <a href="https://www.avenews-gt.com/terms-of-use" target="_blank">https://www.avenews-gt.com/terms-of-use</a>, and
        <a href="https://www.avenews-gt.com/privacy-policy" target="_blank">https://www.avenews-gt.com/privacy-policy</a>.
      </p>
      <p>
        Our Platform is available only to individuals who (a) are at least sixteen (16) years old; and (b) possess the legal capacity to
        enter into these Terms (on behalf of themselves and their organization) and to form a binding agreement under any applicable law.
        You hereby represent that you possess the legal authority to enter into these Terms on your organization's behalf and to form a
        binding agreement under any applicable law, to use the Platform in accordance with these Terms, and to fully perform your
        obligations hereunder. For the avoidance of doubt, any act or omission performed by you in connection with the Platform shall
        obligate your organization.
      </p>
    </li>
    <li>
      <strong>The Platform</strong>
      <p>
        Avenews-GT provides you with access to a platform for the commercial trade of agricultural produce. The Platform allows you to
        conduct trade processes within the Platform (whether as a buyer, seller or agri-business), connect with your Client/Contact (as
        defined below) and efficiently manage your orders and invoices via automatic means. By creating an Account (as defined and further
        detailed below) and registering to the Platform as a User, you may refer your customers, suppliers, or service provider (as
        applicable, each a "<strong>Client/Contact</strong>") to the platform, inviting them to join our services, create an Account and use
        the Platform.
      </p>
      <p>
        The Platform may also contain the Company's contact information, videos, photos, text, files, logos, button icons, images, data
        compilations, links, other specialized content, technical data, documentation, know-how, specifications materials, designs, data,
        the "look and feel" of the Platform, algorithms, source and object code, interface, GUI, interactive features related graphics,
        illustrations, drawings, animations, and other features obtained from or through the Platform (collectively, the
        “<strong>Content</strong>”).
      </p>
      <p>
        The Platform may include the sending of push-notifications, messages, emails, alerts via various means of communication. You can
        deactivate the push-notifications at any time by changing the notification settings on your mobile phone, device or tablet.
      </p>
      <p>The Platform and the Content shall be referred to collectively as the "<strong>Service</strong>".</p>
    </li>
    <li>
      <strong>Registration and User Account(s)</strong>
      <p>
        In order to have access to the Platform and use the Service, you must download the App from the necessary mobile application
        marketplace or access the Platform via the Site, as well as register and create an account (the "Account"). In order to create an
        Account you acknowledge and agree that you will be required to provide the Company with certain information, which may consist of
        personally identifiable information (including, inter alia, name, email, country and telephone number). You may also provide us with
        the following information: identification number (ID), job title, company's registered name and number, address, a profile photo,
        additional details including information relating to your business. Additionally, the Company will have access to the information
        you upload to the Platform, such as any of the following: information related to orders, clients/contacts, ecosystems (group of
        clients/contacts), invoices, and products, client/contact contact details, bank account details and trade related documents
        (collectively the "User Generated Content"). You expressly acknowledge and agree that in order for Avenews-GT to grant you with
        access to the Platform and provide the Service, Avenews-GT may have to access and/or use the information you provided to Avenews-GT.
        A comprehensive explanation regarding the information that we collect from our Users appears in our Privacy Policy.
      </p>
      <p>
        You may not have more than one (1) active Account, creating multiple Accounts with overlapping uses or in order to evade a
        suspension of your Account (as further detailed below) is not allowed, unless otherwise authorized in writing by the Company. You
        may use your Account solely for your own business or commercial use and not on behalf of or for the benefit of any third party.
      </p>
      <p>
        Your Account is password protected. In order to protect the security of your information available on your Account (including
        business related information), to the greatest extent possible, you must safeguard and not disclose your Account username and
        password and you must supervise the use of such Account.
      </p>
      <p>
        You must provide accurate and complete information when creating an Account and you agree to: (i) not misrepresent your identity or
        your Account information; and (ii) keep your Account information up to date and accurate.
      </p>
      <p>
        You may not assign or transfer your rights or delegate your duties under the Account without the prior written consent of
        Avenews-GT. You must notify us immediately of any unauthorized use of your Account or any other breach of security. We cannot and
        will not be liable for any loss or damage arising from any failure of yours to comply with the Terms, i.e., if someone else accesses
        your Account through the registration information he/she has obtained from you or through a violation by you of these Terms, or for
        any unauthorized use of your Account or any other breach of security.
      </p>
      <p>
        You may change your password within the Platform, however, if you wish to either change your username, or cancel and remove your
        Account, please send us an e-mail of your request to:<a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a>. Your Account
        will terminate within reasonable time following your request, and from that date of termination you will no longer be able to access
        your Account. Note that you will be able to edit or remove, at any time, any information contained in your personal Account, in such
        event please contact us via email.
      </p>
      <p>
        CANCELLING OR TERMINATING YOUR ACCOUNT MAY CAUSE THE LOSS OF CERTAIN INFORMATION YOU PROVIDED US AND/OR THE CAPACITY OF YOUR
        ACCOUNT. WE DO NOT ACCEPT ANY LIABILITY FOR SUCH LOSS.
      </p>
      <p>
        Use of the Platform is entirely free of charge. However, Avenews-GT reserves the right to charge fees for such use in the future. In
        addition, you hereby acknowledge and agree that you may be charged for internet, maintenance of network connection and data usage
        charges made through use of the Platform, according with the applicable rates charged by your respective third party internet and
        data usage service provider as may be from time to time. Please note that the Service will not be available to you if your mobile
        phone, device or tablet are not connected to the internet for whatever reason.
      </p>
      <div class="squared-div">
        <i>
          ALL RIGHTS IN AND TO THE CONTENT AVAILABLE ON THE PLATFORM ARE RESERVED TO AVENEWS-GT OR ITS LICENSORS. TO THE EXTENT LEGALLY
          PERMISSIBLE, THE PLATFORM AND THE CONTENT AVAILABLE THEREIN ARE PROVIDED ON AN "AS IS" BASIS. AVENEWS-GT WILL NOT BE LIABLE FOR
          ANY DAMAGES OR LOSS, INCURRED BY YOU OR ANY OTHER PERSON AS A RESULT OF OR IN CONNECTION WITH YOUR USE OF THE PLATFORM AND/OR THE
          CONTENT AVAILABLE THEREIN AND/OR ANY DECISION MADE OR ACTION TAKEN OR NOT TAKEN IN RELIANCE ON THE USE OF THE SERVICE, INCLUDING,
          WITHOUT LIMITATION THE FINACIAL SERVICE (AS DEFINED BELOW). WE DO NOT GUARANTEE THAT ANY OF THE USERS' NEEDS WILL BE FULFILLED.
          YOUR USE OF THE PLATFORM AND/OR THE CONTENT IS ENTIRELY AT YOUR OWN RISK.</i
        >
      </div>
    </li>
    <li>
      <strong>Use Restrictions</strong>
      <p>
        There are certain conducts which are strictly prohibited when using the Platform. Please read the following restrictions carefully.
        Failure to comply with any of the provisions set forth herein may result (at Avenews-GT's sole discretion) in the termination of
        your use of the Service and may also expose you to civil and/or criminal liability.
      </p>
      <p>
        Unless otherwise explicitly permitted under these Terms or in writing by Avenews-GT, you may not (and you may not permit any third
        party to): (a) use the Platform and/or the Content for any illegal, immoral, unlawful and/or unauthorized purposes, including,
        without limitation, by providing inaccurate pricing and manipulating the pricing of offered produce and products; (b) use the
        Platform and/or Content for the benefit of or on behalf of any third party without Avenews-GT's express prior written consent; (c)
        remove or disassociate, from the Platform and/or the Content any restrictions and signs indicating proprietary rights of Avenews-GT
        or its licensors, including but not limited to any proprietary notices contained in such materials (such as ©,™, or ®), and you
        represent and warrant that you will abide by all applicable laws in this respect; (d) interfere with or violate Users' rights to
        privacy and other rights, or harvest or collect personally identifiable information about Users without their express consent,
        whether manually or with the use of any robot, spider, crawler, any search or retrieval application, or use other manual or
        automatic device, process or method to access the Platform and retrieve, index and/or data-mine information; (e) interfere with or
        disrupt the operation of the Platform or the servers or networks that host the Platform, or disobey any laws, regulations,
        requirements, procedures, or policies of such servers or networks; (f) falsely state or otherwise misrepresent your affiliation with
        any person or entity, or express or imply that the Company endorses you, your site, your business or any statement you make, or
        present false or inaccurate information about the Service; (g) take any action that imposes, or may impose, an unreasonable or
        disproportionately large load on our Platform infrastructure, as determined by us; (h) bypass any measures we may use to prevent or
        restrict access to the Platform; (i) copy, modify, alter, adapt, replicate, make available, translate, port, reverse engineer,
        decompile, or disassemble any portion of the Content made available by Avenews-GT on or through the Platform, or publicly display,
        reproduce, create derivative works from, perform, distribute, or otherwise use such Content; (j) copy, distribute, display, execute
        publicly, make available to the public, reduce to human readable form, decompile, disassemble, adapt, sublicense, make any
        commercial use, sell, rent, transfer, lend, process, compile, reverse engineer, combine with other software, translate, modify or
        create derivative works of any material that is subject to Avenews-GT's proprietary rights, including Avenews-GT's Intellectual
        Property (as such term is defined below), in any way or by any means, unless expressly permitted in the Terms and/or under any
        applicable laws which expressly permits such actions; (k) make any use of the Content on any other site or networked computer
        environment for any purpose without Avenews-GT’s prior written consent; (l) create a browser or border environment around Avenews-GT
        Content (no frames or inline linking is allowed); (m) sublicense, sell, rent, transfer, lend, or exploit for any commercial purposes
        any use of or access to the Platform and/or Content; (n) frame or mirror any part of the Platform without Avenews-GT's prior express
        written authorization; (o) create a database by systematically downloading and storing all or any of the Content from the Platform;
        (p) transmit or otherwise make available in connection with the Platform any virus, worm, Trojan Horse, time bomb, web bug, spyware,
        or any other computer code, file, or program that may or is intended to damage or hijack the operation of any hardware, software, or
        telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component; (q) use the
        Service for any purpose for which the Platform and/or the Service is not intended and/or (r) infringe and/or violate any of the
        Terms.
      </p>
    </li>
    <li>
      <strong>User Generated Content</strong>
      <p>
        The Platform includes functionalities which enable Users, including Your Clients/Contacts to share and upload, add, create, post,
        publish or make available User Generated Content the following terms apply to your User Generated Content:
      </p>
      <ol style="padding-left: 2.5rem">
        <li>
          You represent and warrant that (1) you are the rightful owner of the User Generated Content or that you have (and will continue to
          have) all the necessary licenses, rights, consents, and permissions from the licensors of such User Generated Content and (2) that
          such User Generated Content does not infringe any third party's intellectual property rights, privacy rights or publicity rights.
        </li>
        <li>
          THE USER GENERATED CONTENT THAT YOU UPLOAD TO THE PLATFORM WILL BE PUBLICLY AVAILABLE TO YOUR CLIENT/CONTACT; AND, THEREFORE, IS
          DEEMED
          <u>NON-CONFIDENTIAL</u> AND YOU HEREBY <u>WAIVE</u> ANY PRIVACY RIGHTS AND PUBLICITY RIGHTS (AS APPLICABLE) WITH RESPECT THERETO.
        </li>
        <li>
          You understand and agree that you are solely responsible for your User Generated Content and the consequences of posting or
          publishing such User Generated Content. Avenews-GT will not bear any liability for any loss, damage, cost or expense that you may
          suffer or incur as a result of or in connection with uploading any User Generated Content.
        </li>
        <li>
          You agree that you will not post or upload any User Generated Content containing content which is unlawful for you to possess,
          post or upload in any country in which you are resident, or content which would be unlawful for Avenews-GT to use or possess in
          connection with the Platform (including but not limited to any content which is defamatory, libelous, pornographic, indecent,
          harassing, threatening, abusive or fraudulent)
        </li>
        <li>
          Although Avenews-GT has no obligation to screen, edit or monitor any of the User Generated Content, Avenews-GT explicitly reserves
          the right, at its sole discretion, to remove, edit, or disable the access of, any User Generated Content available on the Platform
          at any time without giving any prior notice and for any reason,
          <strong>and you are solely responsible for creating backup copies of your User Generated Content at your sole expense</strong>.
        </li>
        <li>
          Avenews-GT takes no responsibility and assumes no liability for any User Generated Content uploaded, posted, published or made
          available by you or any third party in the Platform, or for any loss or damage thereto, nor is Avenews-GT liable for any mistakes,
          defamation, libel, falsehoods, obscenities or pornography you or any other third party may encounter.
        </li>
        <li>
          Avenews-GT may create limits on the use of the Platform, including limitation on size and storage space available for Users to
          upload User Generated Content.
        </li>
        <li>
          As a viewer of the User Generated Content, you agree that if you find that any User Generated Content available on the Platform is
          offensive, harmful, inaccurate, misleading or was posted in violation of the Terms, please stop viewing such User Generated
          Content and promptly contact us at
          <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a>.
        </li>
      </ol>
    </li>
    <li>
      <strong> License to User Generated Content </strong>
      <p>
        As long as your User Generated Content is subject to any applicable copyright law, it shall remain at all times, and to the extent
        permitted by law, your sole and exclusive property. We do not claim ownership of your User Generated Content. However, we need
        certain licenses to your User Generated Content for business purposes and in order to enable different functions in the Platform.
      </p>
      <p>
        When you upload, post, publish or make available any User Generated Content on the Platform, you grant us a perpetual,
        non-exclusive, royalty-free, and worldwide license to publicly display, communicate, distribute, host, publicly perform, publish,
        reproduce, make modifications or derivative works (solely for the purpose of better showcasing your User Generated Content), store
        and use such User Generated Content, in connection with the Platform, whether through the Internet, mobile devices or otherwise, in
        any media formats and through any media channels known today and developed in the future for the purpose of operating, marketing,
        promoting and improving Avenews-GT's services and for any other legitimate business purposes. You hereby waive any moral rights,
        attribution rights and publicity rights (if any) with respect to our use of the User Generated Content in accordance with these
        Terms.
      </p>
      <p>
        When you upload, post, publish or make available any User Generated Content, you grant your Client/Contact a non-exclusive,
        non-commercial and royalty-free license to access and view your User Generated Content on our Platform. Please note that the Company
        cannot monitor or control what other Users do with your User Generated Content.
      </p>
      <p>
        AVENEWS-GT TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY USER GENERATED CONTENT UPLOADED, POSTED, PUBLISHED AND/OR MADE
        AVAILABLE BY YOU OR ANY THIRD PARTY IN THE PLATFORM, FOR ANY LOSS OR DAMAGE THERETO OR FOR ANY LOSS OR DAMAGE IT MAY CAUSE THIRD
        PARTIES, NOR IS AVENEWS-GT LIABLE FOR ANY MISTAKES, DEFAMATION, LIBEL, FALSEHOODS, OBSCENITIES OR PORNOGRAPHY YOU OR ANY OTHER THIRD
        PARTY MAY ENCOUNTER WITH RESPECT TO THE USER GENERATED CONTENT.
      </p>
      <p>
        Please note that when you delete User Generated Content, such removed content may persist in backup copies for a reasonable period
        of time (although it will not be available to your Client/Contact and other Users).
      </p>
    </li>
    <li>
      <strong>Misconduct </strong>
      <p>
        We care for the safety of Your Customers and our Users. If you believe a User acted inappropriately, including but not limited to
        offensive, violent or sexually inappropriate behavior, please report immediately such User to us at the following e-mail address:
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a>.
      </p>
      <p>
        Famous respects the intellectual property rights of others. If you believe that your work has been copied in a way that constitutes
        copyright infringement, please contact as the email listed above.
      </p>
    </li>
    <li>
      <strong>Your Client/Contact</strong>
      <p>
        Upon creating an Account you may choose to send your Clients/Contacts an invitation to join the Platform. The invitation will be
        sent via the Platform and received as an email or SMS message, containing a link, inviting your Client to create an Account. It is
        hereby clarified that in order to send an invite you will be required to provide the Company with the Client/Contact's name, mobile
        telephone number and email address (the "<strong>Data</strong>"). The Client/Contact may accept or decline to your invite; such
        response will be sent automatically to the Company. The Company will activate a User Account on the behalf of the Client/Contact
        which has accepted your referral. The Client/Contact access and use of the Platform shall be subject to the Client/Contact approval
        of the Terms, as may be updated for time to time at our sole discretion. An activated Client/Contact may interact solely with the
        User who has referred the Client/Contact to the Platform. If you are registered to the Platform as a Client/Contact and wish to
        interact with additional Users as a buyer, seller or agri-business, please email as at: office@avenews-gt.com. Avenews-GT will
        examine your request, and determine, at its sole discretion, whether to accept your request and register you as a User.
      </p>
      <p>
        We will have access to the Data and other information regarding provided by the Client/Contact and may store such Data and
        information or a portion thereof in order to provide the Service, all as set forth in the Privacy Policy. You hereby represent and
        warrant (on behalf of you and your organization) that you have received any required consents from your Client/Contact in order to
        provide us with the Data and enable us to use the Data in order to offer the Service to such Client/Contact. You shall remain solely
        responsible and liable for the lawful collection and transfer to Avenews-GT of such Data, and hereby expressly release Avenews-GT
        from any and all liability arising from Avenews-GT's use of such Data as permitted herein.
      </p>
    </li>
    <li>
      <strong>The Financial Service</strong>
      <p>
        If you have a registered bank account at one of the banks as listed in Annex 1(each a "<strong>Bank</strong>"), you will be entitled
        to use the financial services (the "<strong>Financial Service</strong>"). The Financial Service will enable you to share with the
        Bank information relating to your use of the Platform including information you shared with us while opening the account, all
        information relating to your activity within the Platform, as well as you User Generated content (the "<strong
          >Activity Information</strong
        >"), and as such provide the Bank with accurate information relating to your current business activities. Your Activity Information
        may be used by the Bank, at its sole discretion, including, <i>inter alia</i>, in order to a provide you with certain banking
        services and discounts.
      </p>
      <p>
        In order to join the Financial Service, please email us to:
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a> or file a request via the applicable feature in the Platform, and
        we will send you a consent form which is required in order to join the Financial Service (the "<strong>Consent Form</strong>"),
        granting us a permission to provide the Bank with access to your Activity Information. Upon receiving your Consent Form we will
        examine your request and approve it providing that it includes all necessary information and consents. You may cancel your Consent
        Form at any point you, at your sole discretion by sending us an email. We will make an effort to respond to your request and cease
        proving the Bank with access to your Activity Information within a reasonable timeframe.
      </p>
      <p>
        For the avoidance of doubt, it is hereby clarified that: (i) we will not provide the Bank, its representatives or any other third
        party any of your Activity Information unless you have provided us with a signed Consent Form approved by the Company (ii) the
        Financial Service is provided on an AS-IS basis, and we do not guarantee that the use of the Financial Service will improve the
        services you will receive from the Bank, increase you revenues, or meet you expectations; (iii) any and all decisions made by the
        Bank, including decisions made based your Activity Information will be made at the Bank's sole discretion, we will not be involved
        in any such decisions; (v) your use of the Financial Service is at your own risk, Avenews-GT bares no responsibility for any and all
        consequences, acts or omissions of the Bank and/or any third party arising from sharing your Activity Information with the Bank,
        including, without limitation (a) any and all direct or indirect damages, (b) loss of business profits and business interruption,
        (c) offensive use of your Activity Information and any unauthorized use of your Activity Information, including sharing your
        Activity Information with third parties; (d) any breach of your privacy and/or Intellectual Property rights, (e) any breach of third
        party rights, including, without limitation, privacy and/or Intellectual Property rights.
      </p>
    </li>
    <li>
      <strong>Privacy Policy</strong>
      <p>
        We respect your privacy and are committed to protect the information you share with us, including information relating to your
        Client(s)/Contact(s). We believe that you have a right to know our practices regarding the information we collect when you connect
        to, access or use the Platform. Our policy and practices and the type of information collected are described in detail in our
        <strong>Privacy Policy</strong> at
        <a routerLink="/privacy-policy">https://agt-platform.com/privacy-policy</a>
        which is incorporated herein by reference. If you intend to connect to, access or use the Platform you must first read and agree to
        the Privacy Policy.
      </p>
    </li>
    <li>
      <strong>License and Intellectual Property Rights</strong>
      <p>
        Subject to the terms hereof, Avenews-GT hereby grants to you, and you accept, a personal, nonexclusive, non-commercial,
        non-transferable, non-sublicensable and fully revocable limited license (i) to download and use the App on your authorized mobile
        phone, device or tablet that you own or control, solely for the limited purpose of using the App for your internal non-commercial
        use, and for no other purpose, strictly in accordance with the Terms, the applicable Usage Rules (defined below) and applicable law;
        and (ii) to use the Platform and the Content provided therein in accordance with the terms contained in this Agreement.
      </p>
      <p>
        The Platform, the Content, the Company's proprietary assets and any and all intellectual property rights pertaining thereto,
        including, but not limited to, inventions, patents and patent applications, trademarks, trade names, service marks, logos,
        copyrightable materials, and trade secrets, whether or not registered or capable of being registered (excluding the User Generated
        Content) (collectively, “<strong>Intellectual Property</strong>”), are owned by and/or licensed to the Company and are protected by
        applicable copyright and other intellectual property laws and international conventions and treaties. All rights not expressly
        granted to you hereunder are reserved by the Company and its licensors. The Terms do not convey to you an interest in or to the
        Company Intellectual Property (defined below) but only a limited revocable right of use in accordance with the Terms. Nothing in the
        Terms constitutes a waiver of the Company’s Intellectual Property under any law.
      </p>
      <p>
        To the extent you provide any feedbacks, comments or suggestions to Avenews-GT regarding the Platform and/or Content
        (“<strong>Feedback</strong>“), Avenews-GT shall have an exclusive, royalty-free, fully paid up, worldwide, perpetual and irrevocable
        license to incorporate the Feedback into any Avenews-GT current or future products, technologies or services and use such Feedback
        for any purpose all without further compensation to you and without your approval. You agree that all such Feedback shall be deemed
        non-confidential.
      </p>
      <p>
        Further, you warrant that your Feedback is not subject to any license terms that would purport to require Avenews-GT to comply with
        any additional obligations with respect to any Avenews-GT current or future products, technologies or services that incorporate any
        Feedback.
      </p>
    </li>
    <li>
      <strong>Trademarks and Trade names</strong>
      <p>
        “Avenews-GT” Avenews-GT™, Avenews-GT’s marks and logos and all other proprietary identifiers used by the Company and/or its third
        party affiliates in connection with the Service (“<strong>Company Trademarks</strong>”) are all trademarks and/or trade names of the
        Company, whether or not registered. All other trademarks, service marks, trade names and logos which may appear on the Platform
        belong to their respective owners (“<strong>Third Party Marks</strong>”). No right, license, or interest to the Company Trademarks
        and the Third Party Marks is granted hereunder, and you agree that no such right, license, or interest shall be asserted by you with
        respect to the Company Trademarks or Third Party Marks and therefore you will avoid using any of those marks, except as permitted
        herein.
      </p>
      <p>
        You (on behalf of your organization) agree that Avenews-GT may identify your organization as a user of the Service and use your
        organization's trademark and/or logo: (i) in sales presentations, promotional/marketing materials, and press releases, and (ii) in
        order to develop a brief customer profile for use by Avenews-GT on Avenews-GT' website for promotional purposes.
      </p>
    </li>
    <li>
      <strong>Interactive Areas</strong>
      <p>
        The Platform offers the User (buy, seller or agri-business) to interact with each other such by requesting or offering or ordering
        produce, filing out and sending order forms, sending invoicing, communicating via the Platform, provide information about the
        produce and services, etc. ("<strong>Interactive Areas</strong>"). You are solely responsible for your use of such Interactive Areas
        and use them at your own risk.
      </p>
      <p>
        As a provider of such Interactive Areas, Avenews-GT is not liable for any statements, representations or User Generated Content
        provided by its Users in such Interactive Areas.
      </p>
      <p>
        Any use of the Interactive Areas or other portions of the Platform in violation of the foregoing restrictions specified in the
        Terms, constitutes violation of the Terms and may result, <i>inter alia</i>, in termination or suspension of your rights to use the
        Interactive Areas and/or the Service.
      </p>
    </li>
    <li>
      <strong>Advertisements, Promotions, Sponsorships and Benefits</strong>
      <p>
        Avenews-GT may accept and display via the Platform advertisements, promotions and sponsorships from third parties and may provide
        the you and/or the Client/Contact with benefits provided via third parties. Please note that the Company makes no warranties or
        representations concerning such advertisements, promotions, sponsorship and benefits whether or not the Company has control over
        them. The Company may be entitled to certain shares of the earnings from such advertisements, promotions and sponsorship. You agree,
        acknowledge and consent that you will not be entitled to any compensation with respect to such monetary amounts. Company assumes no
        responsibility for such third party advertisements, benefits and sponsorships and will not be liable for any damage or loss, cost or
        expense incurred to you or any other person as a result of or in connection with third party advertisements, benefits and
        sponsorships.
      </p>
    </li>
    <li>
      <strong>Linking to Avenews-GT's Platform and Links to Third Party Sites</strong>
      <p>
        Certain links provided herein permit our Users to leave the Platform and enter non-Avenews-GT sites or services. Those linked sites
        and services are provided solely as a convenience to you. These linked sites and services are not under the control of Avenews-GT
        and it is not responsible for the availability of such external sites or services, and does not endorse and is not responsible or
        liable for any content including but not limited to content advertising, products or other information on or available from such
        linked sites and services or any link contained in linked sites or service. In addition, Avenews-GT is not responsible or liable for
        such linked sites and services' privacy practices and/or any other practices. Your access to, use of and reliance upon any such
        sites, services and content and your dealings with such third parties are at your sole risk and expense. Avenews-GT reserves the
        right to terminate any link at any time. You further acknowledge and agree that Avenews-GT shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be caused, by or in connection with use of or reliance on any
        services, content, products or other materials available on or through such linked sites or resource. Most of such linked sites and
        services provide legal documents, including terms of use and privacy policy, governing the use thereof. It is always advisable and
        we encourage you to read such documents carefully before using those sites and services,
        <i>inter alia</i>, in order to know what kind of information about you is being collecting.
      </p>
    </li>
    <li>
      <strong>Usage Rules</strong>
      <p>
        If you will access the Platform via the App, you will be downloading the App from a third party platform, service provider or
        distributor (“<strong>Platform Provider</strong>“) your use of the App may also be governed by usage rules which the Platform
        Provider may have established and which relate to your use of the App (“<strong>Usage Rules</strong>“). Certain Usage Rules are
        described below, but other Usage Rules may apply and it is your responsibility to determine what other Usages Rules are applicable
        to your use of the App. You undertake to comply with all the applicable Platform Provider's Usage Rules and the Usage Rules
        applicable to your use of the App are incorporated herein by reference. In the event of a conflict between the Terms and the terms
        of any applicable Usage Rules, which relates solely to the Platform Provider's representations, warranties, restrictions on use of
        the App, obligations, limitation of liability (to the extent applicable to the Platform Provider) and/or other provisions that
        impose any responsibility on the Platform Provider, the terms of the applicable Platform Provider's Usage Rules shall prevail. You
        represent that you are not prohibited by any applicable laws or Usage Rules from downloading and/or using the App. Any download
        and/or use of the App by anyone prohibited by any applicable laws or Usage Rules from downloading and/or using the App is expressly
        prohibited.
      </p>
      <p>
        <strong><u>Apple Inc.</u></strong>
      </p>
      <p>
        The following applies to you if you downloaded the App from the Apple App Store (“<strong>Licensed Application</strong>“): You
        acknowledge and agree that (i) the license granted herein is limited to a non-transferable license to use the Licensed Application
        on an Apple branded device that you own or control, (ii) these Terms are solely between you and the Company, not Apple Inc.
        (“<strong>Apple</strong>“), and that Apple has no responsibility for the Licensed Application or content thereof, (iii) your use of
        the Licensed Application must comply with Usage Rules established by Apple, as set forth in the App Store Terms of Service effective
        as of the date you enter into these Terms, except that such Licensed Application may be accessed and used by other accounts
        associated with you via Apple's Family Sharing or volume purchasing; and (iv) Apple has no obligation whatsoever to furnish any
        maintenance and support services with respect to the Licensed Application.
      </p>
      <p>
        In the event of any failure of the Licensed Application to conform to any applicable warranty, you may notify Apple, and Apple will
        refund you the purchase price you paid, if any, for the Licensed Application. To the maximum extent permitted by applicable law,
        Apple will have no other warranty obligation whatsoever with respect to the Licensed Application, and any other claims, losses,
        liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be solely governed by these
        Terms and any law applicable to the Company as provider of the App.
      </p>
      <p>
        The Company and you acknowledge that the Company, and not Apple, is responsible for addressing any claims relating to the Licensed
        Application or your possession and/or use thereof, including, but not limited to: (i) product liability claims; (ii) any claim that
        the Licensed Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer
        protection, privacy, or similar legislation.
      </p>
      <p>
        You acknowledge that, in the event of any third party claim that the Licensed Application or your possession and use thereof
        infringes that third party's intellectual property rights, the Company, not Apple, will be solely responsible for the investigation,
        defense, settlement and discharge of any such intellectual property infringement claim. You acknowledge and agree that Apple, and
        Apple's subsidiaries, are third party beneficiaries of these Terms, and that, upon your acceptance of these Terms, Apple will have
        the right (and will be deemed to have accepted the right) to enforce these Terms of Use against you as a third party beneficiary
        thereof.
      </p>
    </li>
    <li>
      <strong>Social Media Features </strong>
      <p>
        The Platform may include social sharing and posting features and other integrated tools (for example the Facebook "Like" and "Share"
        buttons, sharing and posting content via Twitter, YouTube, Facebook, Google+ and e-mail etc.) ("<strong>Social Features</strong>").
      </p>
      <p>
        The Social Features are operated or allow for social integration with certain third party social networks or third party platforms
        (“<strong>Social Network</strong>"). These are created and maintained by third parties who are not affiliated with and/or controlled
        by Avenews-GT. Your use of the Social Features is subject to the applicable third party Social Network terms of use and privacy
        policies. If you do not agree to the practices described in such terms you should disable our Platform's integration with such
        Social Networks, however you may find that you are not able to enjoy all the features available in our Platform. Avenews-GT is not
        responsible and has no liability for your use of such Social Networks.
      </p>
    </li>
    <li>
      <strong>Special provisions relating to Third Party Components </strong>
      <p>
        The Platform may use or include third party software, files and components that are subject to open source and third party license
        terms (“<strong>Third Party Components</strong>“). Your right to use such Third Party Components as part of, or in connection with,
        the Platform is subject to any applicable acknowledgements and license terms accompanying such Third Party Components, contained
        therein or related thereto. If there is a conflict between the licensing terms of such Third Party Components and these Terms, the
        licensing terms of the Third Party Components shall prevail in connection with the related Third Party Components. These Terms do
        not apply to any Third Party Components accompanying or contained in the Platform and Avenews-GT disclaims all liability related
        thereto. You acknowledge that Avenews-GT is not the author, owner or licensor of any Third Party Components, and that Avenews-GT
        makes no warranties or representations, express or implied, as to the quality, capabilities, operations, performance or suitability
        of Third Party Components. Under no circumstances shall the Platform or any portion thereof (except for the Third Party Components
        contained therein) be deemed to be “open source" or “publicly available" software. A list of Third Party Components that their
        licenses require certain notification, is available in the Platform or its documentation and will be updated from time to time.
      </p>
    </li>
    <li>
      <strong>Availability</strong>
      <p>
        The Services' availability and functionality depends on various factors, such as communication networks. Avenews-GT does not warrant
        or guarantee that the Service will operate and/or be available at all times without disruption or interruption, or that it will be
        immune from unauthorized access or error-free.
      </p>
    </li>
    <li>
      <strong>Changes to Platform</strong>
      <p>
        Avenews-GT reserves the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue, temporarily or
        permanently the Platform (or any part thereof, including but not limited to the Content) without notice, at any time. In addition,
        you hereby acknowledge that the Content provided under the Platform may be changed, extended in terms of content and form or removed
        at any time without any notice to you. You agree that Avenews-GT shall not be liable to you or to any third party for any
        modification, suspension, or discontinuance of the Platform or the Content included therein. You hereby agree that the Company is
        not responsible for any errors or malfunctions that may occur in connection with the performing of such changes.
      </p>
      <p>
        Avenews-GT has no obligation to provide upgrades or new releases of the Platform under these Terms. However, if Avenews-GT supplies
        to you any updates, upgrades and any new versions of the Platform (“Updates“) according to its then current policies, it may include
        automatic updating or upgrading of the App with or without any additional notice to you and the Terms will govern any such Updates
        unless these are accompanied by a separate license agreement which will prevail, and all references herein to the Platform shall
        include such Updates.
      </p>
    </li>
    <li>
      <strong>Disclaimer of Warranties</strong>
      <p>
        TO THE FULLEST EXTENT LEGALLY PERMISSIBLE, THE PLATFORM AND THE CONTENT ARE PROVIDED ON AN “<strong>AS IS</strong>”, “<strong
          >WITH ALL FAULTS</strong
        >” AND “<strong>AS AVAILABLE</strong>” BASIS, AND AVENEWS-GT, INCLUDING ITS VENDORS, OFFICERS, SHAREHOLDERS, SUB-CONTRACTORS,
        DIRECTORS, EMPLOYEES, AFFILIATES, SUBSIDIARIES, LICENSORS, AGENTS AND SUPPLIERS (COLLECTIVELY, "<strong
          >AVENEWS-GT'S REPRESENTATIVES</strong
        >"), DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR
        NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND THOSE ARISING FROM A COURSE
        OF DEALING OR USAGE OF TRADE. YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS THAT THIS AGREEMENT CANNOT CHANGE.
      </p>
      <p>
        WE DO NOT WARRANT (I) THAT THE USE AND OPERATION OF THE PLATFORM IS OR WILL BE SECURE, TIMELY, ACCURATE, COMPLETE, UNINTERRUPTED,
        WITHOUT ERRORS, OR FREE OF VIRUSES, DEFECTS, WORMS, OTHER HARMFUL COMPONENTS OR OTHER PROGRAM LIMITATIONS, (II) THAT AVENEWS-GT WILL
        CORRECT ANY ERRORS OR DEFECTS IN THE PLATFORM, (III) THE PLATFORM WILL BE INTEROPERABLE OR COMPATIBLE WITH YOUR DEVICE, OTHER
        SOFTWARE, HARDWARE, OR ANY EQUIPMENT, AND AVENEWS-GT AND AVENEWS-GT'S REPRESENTATIVESARE NOT RESPONSIBLE FOR ANY LOSSES SUFFERED
        RESULTING FROM INTEROPERABILITY OR COMPATIBILITY PROBLEMS, (IV) AND/OR MAKE ANY REPRESENTATION REGARDING THE USE, INABILITY TO USE
        OR OPERATE, OR THE RESULTS OF THE USE OF THE PLATFORM AND/OR CONTENT AVAILABLE THEREON OR THROUGH THE PLATFORM (INCLUDING THAT THE
        RESULTS OF USING THE PLATFORM WILL MEET YOUR REQUIREMENTS). AVENEWS-GT AND AVENEWS-GT'S REPRESENTATIVES DISCLAIM ALL WARRANTIES AND
        CONDITIONS WITH REGARD TO THE USE OF THE PLATFORM, INCLUDING BUT NOT LIMITED TO THE AVAILABILITY, RELIABILITY OR THE QUALITY OF
        PLATFORM, AND ARE NOT AND SHALL NOT BE RESPONSIBLE FOR ANY ERROR, FAULT OR MISTAKE RELATED TO ANY CONTENT AND INFORMATION DISPLAYED
        WITHIN THE PLATFORM.
      </p>
      <p>WE ARE NOT RESPONSIBLE AND HAVE NO LIABILITY FOR ANY ITEM OR SERVICE PROVIDED BY ANY PERSON OR ENTITY OTHER THAN AVENEWS-GT.</p>
      <p>
        WE ARE NOT RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR OTHERS THAT MAY RESULT FROM TECHNICAL PROBLEMS (INCLUDING WITHOUT LIMITATION
        IN CONNECTION WITH THE INTERNET SUCH AS SLOW CONNECTIONS, TRAFFIC CONGESTION, OVERLOAD OF SERVERS, DELAYS OR INTERRUPTIONS) OR ANY
        TELECOMMUNICATIONS OR INTERNET PROVIDERS.
      </p>
      <p>
        <strong>
          YOU AGREE THAT USE OF PLATFORM AND/OR THE CONTENT IS ENTIRELY AT YOUR OWN RISK, AND AVENEWS-GT SHALL HAVE NO LIABILITY RELATING TO
          SUCH USE .
        </strong>
      </p>
      <p>
        INASMUCH AS SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OR LIMITATIONS AS SET FORTH HEREIN, THE FULL EXTENT OF THE ABOVE
        EXCLUSIONS AND LIMITATIONS MAY NOT APPLY.
      </p>
    </li>
    <li>
      <strong>Limitation of Liability</strong>
      <p>
        TO THE MAXIMUM EXTENT LEGALLY PERMISSIBLE, IN NO EVENT SHALL AVENEWS-GT, INCLUDING AVENEWS-GT'S REPRESENTATIVES BE LIABLE FOR ANY
        DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, CONTRACT, NEGLIGENCE, TORT OR STRICT LIABILITY)
        (INCLUDING WITHOUT LIMITATION, LOSS OF GOODWILL, PROFITS OR DATA AND BUSINESS INTERRUPTION) ARISING HEREUNDER, RESULTING FROM OR
        ARISING OUT OF THE PLATFORM AND/OR THE CONTENT, AND/OR YOUR USE OR INABILITY TO PLATFORM AND/OR THE CONTENT AND/OR THE FAILURE OF
        THE PLATFORM TO PERFORM AS DESCRIBED OR EXPECTED, OR FROM THE PERFORMANCE OR FAILURE OF AVENEWS-GT TO PERFORM UNDER THESE TERMS, ANY
        OTHER ACT OR OMISSION OF AVENEWS-GT OR AVENEWS-GT'S REPRESENTATIVES, BY ANY OTHER CAUSE WHATSOEVER; OR BASED UPON BREACH OF
        WARRANTY, GUARANTEE OR CONDITION, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, TORT, OR ANY OTHER LEGAL THEORY, REGARDLESS OF
        WHETHER AVENEWS-GT (OR AVENEWS-GT'S REPRESENTATIVES) HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p>
        IN ANY CASE, WITHOUT LIMITING THE GENERALITY OF THE AFOREGOING AND TO THE MAXIMUM EXTENT LEGALLY PERMISSIBLE, AVENEWS-GT'S AND
        AVENEWS-GT'S REPRESENTATIVES' TOTAL AGGREGATE LIABILITY FOR ALL DAMAGES OR LOSSES WHATSOEVER ARISING HEREUNDER OR IN CONNECTION WITH
        YOUR USE OR INABILITY TO USE THE PLATFORM OR THE CONTENT SHALL BE LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU, IF ANY, TO AVENEWS-GT
        FOR USE OF THE PLATFORM OR $US1.00, WHICHEVER IS GREATER. THE LIMITATION OF LIABILITY PROVISIONS WHICH APPLY TO ANY RESPONSIBILITIES
        OF THE APPLICABLE PLATFORM PROVIDER ARE SET FORTH IN THEIR APPLICABLE USAGE RULES. YOU WILL NOT, AND WAIVE ANY RIGHT TO, SEEK TO
        RECOVER ANY OTHER DAMAGES, INCLUDING CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT OR INCIDENTAL DAMAGES FROM US AND FROM
        AVENEWS-GT'S REPRESENTATIVES.
      </p>
      <p>
        INASMUCH AS SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OR LIMITATIONS AS SET FORTH HEREIN, THE FULL EXTENT OF THE ABOVE
        EXCLUSIONS AND LIMITATIONS MAY NOT APPLY.
      </p>
    </li>
    <li>
      <strong>Indemnification </strong>
      <p>
        You agree to defend, indemnify and hold harmless Avenews-GT and Avenews-GT Representatives from and against any and all claims,
        damages, obligations, losses, liabilities, costs, debts, and expenses (including but not limited to attorney's fees) arising from:
        (i) your use, misuse of, inability to use and/or activities in connection with the Platform and/or Content; (ii) your violation of
        any of these Terms; (iii) damages you may cause to any third party which relates to your use of the Platform and/or the Service,
        your violation of any third party rights, including without limitation any intellectual property rights or privacy right of such
        third party with respect to your use of the Service; and (iv) any damage of any sort, whether direct, indirect, special or
        consequential, you may cause to any third party with relation to the Platform, including, without limitation, with relation to the
        use and transferal of Data and with the use of your Activity Information; (v) the User Generated Content you uploaded through the
        use of the Platform; (vi) any damage of any sort, whether direct, indirect, special or consequential causes as a consequence of (a)
        Avenews-GT use of the Data, and (b) your use of the Financial Service; and/or (vi) your violation of applicable laws in connection
        with your us of the Platform, and referring your Client/Contact to the Platform and Service, including applicable privacy and
        anti-spam laws. It is hereby clarified that this defense and indemnification obligation will survive these Terms.
      </p>
      <p>
        Without derogating from the foregoing, we reserve the right to assume the exclusive defense and control of any matter which is
        subject to indemnification by you, which will not excuse your indemnity obligations hereunder and in which event you will fully
        cooperate with us in asserting any available defense. You agree not to settle any matter subject to an indemnification by you
        without first obtaining our prior express written approval.
      </p>
    </li>
    <li>
      <strong>Amendments to the Terms </strong>
      <p>
        Avenews-GT may, at its sole discretion, change these Terms from time to time, including any other policies incorporated thereto, so
        please re-visit the Platform frequently. In case of any material change, we will make reasonable efforts to post a clear notice on
        the Platform and/or will send you an e-mail (to the extent that you provided us with such e-mail address) regarding such change.
        Such material changes will take effect seven (7) days after such notice was provided on our Platform or sent via e-mail, whichever
        is the earlier. Otherwise, all other Changes to these Terms are effective as of the stated “Last Revised” and your continued use of
        the Platform on or after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes. In the
        event that the Terms should be amended to comply with any legal requirements, the amendments may take effect immediately, or as
        required by the law and without any prior notice.
      </p>
    </li>
    <li>
      <strong
        >Termination or Suspension of your Account, Termination of these Terms and the Termination of the Platform's operation
      </strong>
      <p>
        At any time, Avenews-GT may block your access to the Platform and/or temporarily or permanently limit your access to the Platform,
        at its sole discretion, in addition to any other remedies that may be available to Avenews-GT under any applicable law. Such actions
        by Avenews-GT may be taken, for example, if Avenews-GT deems that you have breached any of these Terms in any manner, or in the
        event that Avenews-GT finds out that your business engages in illegal activity, or violating any local, state, federal or foreign
        law or regulation.
      </p>
      <p>
        If you object to any term hereof, as may be amended from time to time, or become dissatisfied with the Service, you may terminate
        these Terms at any time by logging out of our Platform, uninstalling our App, stopping your use of the Platform and contact us via
        email at
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a>, with a request to delete your account.
      </p>
      <p>
        Additionally, Avenews-GT may at any times, at its sole discretion, cease the operation of the Service or any part thereof,
        temporarily or permanently, delete any information from the Platform or correct, modify, amend, enhance, improve and make any other
        changes thereto or discontinue displaying or providing any information, content or features therein without giving any prior notice.
        You agree and acknowledge that Avenews-GT does not assume any responsibility with respect to, or in connection with the termination
        of the Platform's operation and loss of any data.
      </p>
      <p>
        Upon termination of your Account (including but not limited due to your failure to comply with the Terms) and/or termination of the
        Platform's operation, you are required to stop using the Platform and Content immediately. You hereby acknowledge that upon
        termination of your Account, all information and data provided via the Service will be deleted from our servers and you will not be
        able to recover the deleted information once the Account is terminated (unless otherwise agreed upon in writing by you and
        Avenews-GT).
      </p>
      <p>
        The provisions of these Terms that, by their nature and content, must survive the termination of these Terms in order to achieve the
        fundamental purposes of these Terms shall so survive. Without limiting the generality of the forgoing, the Intellectual Property,
        Disclaimer of Warranties, Limitation of Liability, Indemnification and General sections will survive the termination of the Terms.
      </p>
      <p>
        We note that we can suspend access to your Account if we believe, in our sole discretion, that one (or more) of the following events
        have occurred: (a) there is risk to the security or privacy of your Account; (b) there is a threat to the security or integrity of
        our network or our servers; (c) suspension is needed to protect the rights, property or safety of Avenews-GT, its Users or the
        public; (d) there is a basis for termination of your Account; (e) you have violated these Terms; and/or (f) we are required to by
        law. We may provide you a notice in the event of any such suspension. During such suspension, you will not have the ability to use
        or access your Account. In the event that we will determine, in our sole discretion, that the reason for suspension of access to
        your Account has been resolved, we will restore access to your Account.
      </p>
    </li>
    <li>
      <strong>Minors</strong>
      <p>
        The Platform is intended for Users over the age of sixteen (16). We reserve the right to request proof of age at any stage so that
        we can verify that minors under the age of sixteen (16) are not using the Platform. In the event that it comes to our knowledge that
        a person under the age of sixteen (16) is using the Platform, we will prohibit and block such User from accessing the Platform and
        will make all efforts to promptly delete any Personal Information (as such term is defined in our Privacy Policy) with regard to
        such User.
      </p>
    </li>
    <li>
      <strong>General</strong>
      <p>
        (a) These Terms constitute the entire terms and conditions between you and the Company relating to the subject matter herein and
        supersedes any and all prior or contemporaneous written or oral agreements or understandings between you and the Company, (b) any
        claim relating to the Platform or the Content contained therein will be governed by and interpreted in accordance with the laws of
        the State of Israel without reference to its conflict-of-laws principles and the United Nations Convention Relating to a Uniform Law
        on the International Sale of Goods may not be applied, (c) any dispute arising out of or related to the Platform will be brought in,
        and you hereby consent to exclusive jurisdiction and venue in, the competent courts of the Tel-Aviv District, Israel. You agree to
        waive all defenses of lack of personal jurisdiction and forum non-convenience and agree that process may be served in a manner
        authorized by applicable law or court rule. Notwithstanding the foregoing, Avenews-GT may seek injunctive relief in any court of
        competent jurisdiction, (d) these Terms do not, and shall not be construed to create any relationship, partnership, joint venture,
        employer-employee, agency, or franchisor-franchisee relationship between the parties hereto, (e) no waiver by either party of any
        breach or default hereunder will be deemed to be a waiver of any preceding or subsequent breach or default. Any heading, caption or
        section title contained herein is inserted only as a matter of convenience, and in no way defines or explains any section or
        provision hereof, (f) YOU ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE PLATFORM
        MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED, (g) if
        any provision hereof is adjudged by any court of competent jurisdiction to be unenforceable, that provision shall be limited or
        eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect while most nearly
        adhering to the intent expressed herein, (h) you may not assign or transfer these Terms (including all rights and obligations
        hereunder) without our prior written consent and any attempt to do so in violation of the foregoing shall be void. We may assign or
        transfer these Terms without restriction or notification, (i) no amendment hereof will be binding unless in writing and signed by
        Avenews-GT, (j) in the event of any inconsistency between these Terms and a written agreement executed between you and Avenews-GT
        and signed by both parties, the terms of such written agreement shall prevail; and (k) the parties agree that all correspondence
        relating to these Terms shall be written in the English language.
      </p>
    </li>
    <li>
      <strong>For information, questions or notification of errors, please Contact:</strong>
      <p>
        If you have any questions (or comments) concerning the Terms, you are most welcome to send us an e-mail to
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a> and we will make an effort to reply within a reasonable timeframe.
      </p>
    </li>
  </ol>
</div>
