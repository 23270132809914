import { AlertService } from '@avenews/alerts';
import { InfoView, SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, of, firstValueFrom, EMPTY } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Agt4User } from '@avenews/agt-sdk';

@Component({
  selector: 'td-kyc',
  templateUrl: './td-kyc.component.html',
  styleUrls: ['./td-kyc.component.scss']
})
export class TdKYCComponent implements OnInit {
  @Input() loan: any;
  @Input() distributor: any;
  @Input() mlScoreData: any;
  @Input() currentUser: Agt4User;
  list: InfoView[];
  kyc: any;
  kycStatus: string;
  statuses = [
    {
      realName: 'Manual Review',
      status: 'Manual Review',
      color: 'orange'
    },
    {
      realName: 'Approved',
      status: 'Approved',
      color: 'green'
    },
    {
      realName: 'Declined',
      status: 'Declined',
      color: 'red'
    }
  ];
  rawKyc: any;
  environment = environment;
  isOpen: boolean;
  loading: boolean;
  orgId: string;
  passedImage: string;
  details = [];
  titles = [];
  constructor(private http: HttpClient, private datePipe: DatePipe, private spinner: SpinnerToggleService, private alerts: AlertService) {}
  ngOnInit() {
    const orgId = this.distributor?.organization?._id;
    this.orgId = orgId;
    if (orgId) {
      this.loading = true;
      this.http
        .get(`https://kkpw6h3tt3.execute-api.us-east-1.amazonaws.com/prod/kyc-v2/${orgId}`)
        .pipe(
          catchError((err) => {
            this.loading = false;
            return of(null);
          })
        )
        .subscribe((res: any) => {
          this.loading = false;
          this.rawKyc = res;
          if (this.rawKyc && this.rawKyc?.data) {
            this.list = [];
            this.kyc = this.rawKyc?.data?.documents?.[0]?.scan;
            this.kyc['_id'] = this.rawKyc?._id?.$oid;
            this.kyc['backgroundCheck'] = this.rawKyc?.data?.backgroundCheck;
            this.kyc['face'] = this.rawKyc?.data?.documents?.[0]?.face;
            this.kycStatus = this.kyc?.status;
            if (this.kyc?.backgroundCheck?.match || !this.kyc?.face?.match) {
              this.kycStatus = 'Manual Review';
            } else if (!this.kyc?.status) {
              this.kycStatus = 'Approved';
            }
            try {
              this.kyc.front.dateOfBirthFormatted = this.datePipe.transform(this.kyc.front.dateOfBirthFormatted, 'dd/MM/yyyy');
              this.kyc.back.dateOfBirthFormatted = this.datePipe.transform(this.kyc.back.dateOfBirthFormatted, 'dd/MM/yyyy');
            } catch {}
            this.prepareDetails();
            this.getImages();
          }
        });
    }
  }
  async getImages() {
    const prefixes = ['front', 'back', 'face'];
    const s3Path = 'https://ukyc.s3.amazonaws.com/';
    this.kyc['images'] = {};
    for (let i = 0; i < prefixes?.length; i++) {
      const prefix = prefixes[i];
      let key = `${prefix}-${this.orgId}.jpg`;
      const image = `${s3Path}${key}`;
      const { url } = (await this.getDocumentKey(key)) || { url: '' };
      this.kyc['images'][prefix] = url;
    }
  }
  getDocumentKey(file: string): Promise<{ url: string }> {
    const url = 'https://gc0an3fw5j.execute-api.us-east-1.amazonaws.com/prod/';
    return firstValueFrom(
      this.http.post(url, {
        bucket: 'ukyc',
        file,
        region_name: 'us-east-1'
      }) as any
    );
  }
  prepareDetails() {
    const personalDetails = [];
    this.details = ['dateOfBirthFormatted', 'fullName', 'personalNumber', 'sex', 'city'];
    this.titles = ['Date of Birth', 'Full Name', 'National ID', 'Gender', 'City'];
    this.details.forEach((detail, i) => {
      this.list.push({
        key: this.titles[i],
        value: this.kyc.front[detail] || this.kyc.back[detail] || 'N/A'
      });
    });
    this.list.push(
      ...[
        {
          key: 'Face Match',
          value: this.kyc?.face?.match ? 'Yes' : 'No',
          customClass: !this.kyc?.face?.match ? 'match' : 'no-match'
        },
        {
          key: 'Background Check',
          value: this.kyc?.backgroundCheck?.match ? 'Failed' : 'Passed',
          customClass: this.kyc?.backgroundCheck?.match ? 'match' : 'no-match'
        }
      ]
    );
  }
  openOverlay(image: string) {
    this.passedImage = image;
    this.isOpen = true;
  }
  checkClose(e: MouseEvent, img: any) {
    if (!e.composedPath()?.includes(img)) this.isOpen = false;
  }
  changeStatus(status: string) {
    this.spinner.showSpinner();
    this.http
      .put(`https://kkpw6h3tt3.execute-api.us-east-1.amazonaws.com/prod/kyc-v2/change-status`, { status, id: this.kyc._id })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert('Something went wrong', 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((res: any) => {
        this.kycStatus = status;
        this.spinner.hideSpinner();
        this.alerts.showAlert('Status changed successfully', 'success');
      });
  }
}
