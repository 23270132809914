import { Component, Input } from '@angular/core';

@Component({
  selector: 'steps-tracker',
  templateUrl: 'steps-tracker.component.html',
  styleUrls: ['./steps-tracker.component.scss']
})
export class stepsTrackerComponent {
  currUncompletedStep: any;
  progressHeight: number;
  @Input() steps: any[];

  ngOnInit() {
    this.currUncompletedStep = this.steps?.find((task) => task.done === false);
    if (this.currUncompletedStep) {
      this.progressHeight = 50;
    }
  }
}
