<div class="top-header">
  <div>
    <img class="avenews-logo" src="{{ loginConfig?.logo }}" alt="Avenwes-GT" />
  </div>
  <div *ngIf="!loginConfig?.hideSignUp" class="flex-text-btn">
    <p class="gray">New to {{ loginConfig?.title }}?</p>
    <av-button routerLink="/registration" label="Sign up" type="secondary" size="small"></av-button>
  </div>
</div>

<div class="container">
  <div class="main-wrapper">
    <div class="flex-column align-items-center">
      <img class="small-av-icon" src="{{ loginConfig?.logoIcon }}" alt="avenews" />
      <p class="normal-text">
        Welcome to <strong>{{ loginConfig?.title }}</strong>
      </p>
    </div>
    <div class="login-form-panel">
      <div class="succes-toast" *ngIf="userValidated">
        <i-feather name="check-circle"></i-feather>
        <p>You have successfully validated your email address.</p>
      </div>
      <form (keyup.enter)="signIn()" (submit)="signIn()" [formGroup]="loginForm">
        <app-base-form-template
          *ngIf="showUsername"
          labelName="Username"
          inputType="text"
          inputName="default"
          [size]="12"
          controlName="username"
          [group]="loginForm"
        >
        </app-base-form-template>

        <div *ngIf="!showUsername" class="form-group">
          <label>Phone Number </label>
          <div [formGroup]="loginForm" class="input-group">
            <div class="phone-number input-group-prepend">
              <app-phone-input
                (regionChange)="changeRegion($event)"
                (code)="phoneCode = $event"
                [partialPhoneNumber]="partialPhoneNumber"
                [regionCode]="regionCode"
                [form]="loginForm"
                [controlName]="'phoneNumber'"
              ></app-phone-input>
            </div>
            <input type="tel" class="form-control" id="phone" formControlName="phoneNumber" />
          </div>

          <span hidden translate>Phone Number </span>
        </div>

        <div *ngIf="!loginConfig?.hidePhoneLogin" class="sign-with-phone">
          <p>
            Or
            <a (click)="showPhoneOrEmail()" *ngIf="showUsername" class="a">sign in with phone number</a>
            <a (click)="showPhoneOrEmail()" *ngIf="!showUsername" class="a">sign in with email address</a>
          </p>
        </div>

        <div class="form-group">
          <label> Password</label>
          <input
            [ngClass]="{ 'red-border': loginForm.get('password')?.errors && loginForm.get('password').touched }"
            [type]="showPass ? 'text' : 'password'"
            class="form-control"
            autocomplete="current-password"
            [placeholder]="'Password'"
            formControlName="password"
            required
          />
          <div class="pass-wrap">
            <av-checkbox (click)="showPassword()"></av-checkbox>
            <p class="show-pass">Show password</p>
          </div>

          <a *ngIf="!loginConfig?.hideForgotPassword" class="a" routerLink="/forgot-password">Forgot your password?</a>
        </div>
      </form>
      <div class="login-bttns">
        <av-button
          #login
          (click)="signIn()"
          [type]="isLoading ? 'icon' : 'primary'"
          [rotateIcon]="isLoading"
          [icon]="isLoading ? 'settings' : ''"
          iconType="primary"
          size="stretched"
          label="Sign in"
        >
        </av-button>
        <ng-container *ngIf="showError">
          <div class="error-message" *ngIf="loginForm.get('username')?.hasError('wrongPass')">Invalid username or password</div>
          <div class="error-message" *ngIf="loginForm.get('username')?.hasError('blocked')">This user is blocked</div>
          <div class="error-message" *ngIf="loginForm.get('username')?.hasError('notAdmin')">This user is not an admin nor an agent</div>
        </ng-container>
        <ng-container *ngIf="loginConfig?.socialLogin">
          <p class="text-center or-text">OR</p>
          <av-button (click)="signInWithGoogle('google')" type="secondary" size="stretched" label="Sign in with Google">
            <img class="google-logo" src="../../../assets/images/regestration-img/google logo.svg" alt="Google" />
          </av-button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="bottom-wrapper">
    <p>
      By logging in, you agree to our
      <a class="btn-link" href="assets/shared/pdfs/kenya-privacy-policy.pdf" target="_blank">Terms of Use</a>
      and to receive emails & updates and acknowledge that you read our
      <a class="btn-link" href=".assets/shared/pdfs/kenya-privacy-policy.pdf" target="_blank">Privacy Policy.</a>
    </p>
  </div>
</div>
<app-reset-password-modal [userEmail]="blockedEmail" (confirm)="resetPassword()"></app-reset-password-modal>
<app-email-sent-modal [userEmail]="blockedEmail"></app-email-sent-modal>
<reset-password-modal [environment]="loginConfig?.environment" [credentials]="credentials"></reset-password-modal>
