<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Supplier invoices'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-agribusinesses-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="
        col?.name !== 'PARTNER NAME' &&
        col?.name !== 'ID' &&
        col?.name !== 'AMOUNT' &&
        col?.name !== 'AGRIBUSINESS' &&
        col?.name !== 'STATUS' &&
        col?.name !== 'FILES'
      "
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'AGRIBUSINESS'">
      <td>
        {{ item?.supplier?.adminDetails?.name }}
      </td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'PARTNER NAME'">
      <td>{{ getPartnerName(item) }}</td></ng-container
    >
    <ng-container *ngIf="col?.name === 'ID'">
      <td>#{{ item?.numericId | number: '1.0-2' }}</td></ng-container
    >
    <td *ngIf="col?.name === 'AMOUNT'">{{ item?.amount | number: '1.0-2' }}KES</td>

    <ng-container *ngIf="col?.name === 'STATUS'">
      <td class="status-complete">
        <p class="{{ !item?.status ? '' : (item?.status | status) }}">
          {{ statusGrabber(item?.status) || '-' | titlecase }}
        </p>
      </td></ng-container
    >
    <td
      #tooltip="matTooltip"
      [matTooltip]="getFileNames(item?.documents, true)"
      matTooltipClass="invoice-tooltip"
      *ngIf="col?.name === 'FILES'"
    >
      {{ getFileNames(item?.documents) }}
    </td>
  </ng-template>
</agt-nosdk-list>
<frontend-change-invoice-status [invoice]="invoice"></frontend-change-invoice-status>
