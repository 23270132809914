<div class="modal-box" id="agriTopupWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Top up</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <div class="pl">
        <p class="top-up-header">Top up for {{ agri?.adminDetails?.name }}</p>
        <p class="top-up-subheader">
          Payment terms:
          <span> {{ agri?.paymentTerms || 0 | number: '1.0-2' }} Days</span>
        </p>
      </div>
      <form [formGroup]="topupForm">
        <app-base-form-template
          labelName="Balance"
          bindLabel="viewValue"
          bindValue="value"
          inputType="select"
          inputName="select"
          controlName="balanceType"
          placeholderName="Choose balance type"
          [options]="balanceType"
          [group]="topupForm"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          labelName="Type"
          bindLabel="viewValue"
          bindValue="value"
          inputType="select"
          inputName="select"
          controlName="type"
          placeholderName="Choose top-up type"
          [options]="topupTypes"
          [group]="topupForm"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          labelName="Amount"
          inputType="number"
          inputName="default"
          controlName="amount"
          [group]="topupForm"
          [size]="6"
        >
          <span #append append>KES</span>
        </app-base-form-template>
      </form>
    </div>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="onTopup()">Top up</button>
    </div>
  </div>
</div>
