import { GeneralFinanceListComponent } from './general-finance-list/general-finance-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeneficiaryNamePipe, SharedModule } from '@avenews/shared-lib';
import { FinanceRoutingModule } from './finance-routing.module';
import { FinanceListResolver } from './resolvers/finance-list.resolver';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ApplicationResolver } from './resolvers/application.resolver';
import { DocumentsComponent } from './documents/documents.component';
import { ProductsComponent } from './products/products.component';
import { MainDataComponent } from './main-data/main-data.component';
import { DocumentsResolver } from './resolvers/documents.resolver';
import { LoanResolver } from './resolvers/loan.resolver';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ApplicationViewComponent, DocumentsComponent, ProductsComponent, GeneralFinanceListComponent, MainDataComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    AdminSharedModule,
    SharedModule,
    FinanceRoutingModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports: [ProductsComponent],
  providers: [FinanceListResolver, ApplicationResolver, BeneficiaryNamePipe, DocumentsResolver, LoanResolver]
})
export class FinanceModule {}
