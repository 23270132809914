import { AlertService } from '@avenews/alerts';
import { AuthenticationService } from '@avenews/front-auth';
import { Router } from '@angular/router';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Action, FilterOptions, QueryMode } from '@avenews/agt-sdk';
import { BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { ApplicationStatus } from '../../shared/helpers';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'frontend-finance-list',
  templateUrl: './loan-desk.component.html',
  styleUrls: ['./loan-desk.component.scss']
})
export class LoanDeskComponent extends ListNoSdkComponent<any> implements OnInit {
  tempFilters = [];
  application: any;
  reasonTitle = 'Red flag reason';
  statuses = [
    {
      realName: 'New',
      status: ApplicationStatus.NEW,
      color: 'blue'
    },
    {
      realName: 'In progress',
      status: ApplicationStatus.PROCESSING,
      color: 'orange'
    },
    {
      realName: 'Waiting for client',
      status: ApplicationStatus.WAITING_FOR_CLIENT,
      color: 'gray'
    },
    {
      realName: 'Duplicated',
      status: ApplicationStatus.DUPLICATED,
      color: 'gray'
    },
    {
      realName: 'Churned',
      status: ApplicationStatus.CHURNED,
      color: 'gray'
    },
    {
      realName: 'Awaiting offer approval',
      status: ApplicationStatus.AWAITING_APPROVAL,
      color: 'dark-blue'
    },
    {
      realName: 'Tentatively approved',
      status: ApplicationStatus.TENTATIVELY_APPROVED,
      color: 'dark-blue'
    },
    {
      realName: 'Offer declined intrenally',
      status: ApplicationStatus.INTERNALLY_DECLINED,
      color: 'red'
    },

    {
      realName: 'Offer approved intrenally',
      status: ApplicationStatus.INTERNALLY_APPROVED,
      color: 'green'
    },
    {
      realName: 'Offer declined by client',
      status: ApplicationStatus.CLIENT_DECLINED,
      color: 'red'
    },
    {
      realName: 'Offer approved by client',
      status: ApplicationStatus.CLIENT_APPROVED,
      color: 'green'
    },
    {
      realName: 'On hold',
      status: ApplicationStatus.ON_HOLD,
      color: 'gray'
    },
    {
      realName: 'Referred back',
      status: ApplicationStatus.REFERRED_BACK,
      color: 'purple'
    }
  ];
  currentLoan: any;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<any>;
  constructor(injector: Injector, private authService: AuthenticationService, private router: Router, private alertService: AlertService) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/loan-application`,
      noPagination: false
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'finance';
    this.exportId = 'main-finance-td-export';
  }

  ngOnInit(): void {
    // this.stickyFilters = [
    //   {
    //     value: 'draft',
    //     column: 'status',
    //     option: FilterOptions.NOT_EQUAL
    //   }
    // ];
    this.paginated.subscribe((data) => {
      this.postPagination();
    });
    this.initialized.subscribe(() => {
      // this.manualFilter(this.tempFilters);
    });
    super.init();
    // this.list.forEach((data) => {
    //   data['totalAmount'] = this.totalAmount(data);
    //   data['applicantName'] = this.applicantName(data);
    // });
    this.unsortedList = this.list.slice();
    const seeBuilder = this.authService?.credentials?.user?.policyRole?.policies?.find((data) => {
      return data?.action === 'getAll' && data?.subject != 'Category';
    })
      ? { label: 'Builder' }
      : undefined;
    this.listConfig = {
      emptyState: {
        title: 'No approved here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [{ label: 'View', type: 'default', action: 'view' }],
      bulkAddButton: undefined,
      addButton: seeBuilder,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Loans',

      columns: [
        {
          name: 'ASSIGNEE',
          dtoName: 'assigneeName',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessDetails.name',
          pipe: 'text',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'APPLICANT NAME',
          dtoName: 'applicantName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'SOURCE',
          dtoName: 'source',
          type: 'text',
          selected: true
        },
        {
          name: 'DATE APPLIED',
          dtoName: 'dateCreated',
          pipe: 'date',
          type: 'date',
          selected: true
        },
        {
          name: 'REQUESTED AMOUNT',
          dtoName: 'loanAmount',
          type: 'text',
          selected: true
        },
        {
          name: 'USER REQUESTED AMOUNT',
          dtoName: 'userRequestedAmount',
          type: 'text',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text',
          selected: true
        },
        {
          name: 'EMAIL',
          dtoName: 'personalDetails.email',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'PHONE NUMBER',
          dtoName: 'personalDetails.primaryPhoneNumber',
          type: 'text',
          pipe: 'phoneCode',
          selected: true
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'ASSIGNEE',
          dtoName: 'assignee.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'BUSINESS NAME',
          dtoName: 'businessDetails.name',
          type: 'text'
        },
        {
          name: 'APPLICANT NAME',
          dtoName: 'personalDetails.firstName',
          type: 'text'
        },
        {
          name: 'SOURCE',
          dtoName: 'source',
          type: 'radio',
          radio: [
            { label: 'Qualification', value: 'QUALIFICATION' },
            { label: 'Inputs Network', value: 'INPUTS_NETWORK' },
            { label: 'Application', value: 'APP' }
          ]
        },
        {
          name: 'DATE APPLIED',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'radio',
          radio: this.statuses.map((data) => {
            return { label: data.realName, value: data.status };
          })
        },
        {
          name: 'REQUESTED AMOUNT',
          dtoName: 'loanAmount',
          type: 'number'
        },
        {
          name: 'EMAIL',
          dtoName: 'personalDetails.email',
          type: 'text'
        },
        {
          name: 'PHONE NUMBER',
          dtoName: 'personalDetails.primaryPhoneNumber',
          type: 'text'
        }
      ]
    };
  }
  applicantName(item: any) {
    return `${item?.personalDetails?.firstName || ''} ${item?.personalDetails?.lastName || ''}`;
  }
  deleteContact() {}
  exportContact() {}
  viewApp(application: any) {
    this.router.navigate(['/trade-desk/loan', application._id]);
  }
  newTab(application: any) {
    window.open(window.origin + `/trade-desk/loan/${application._id}`, '_blank');
  }
  openStatus(application: any) {
    this.application = application;
    $('#changeAppStatusWizard').css({ display: 'flex' });
  }

  actions(e: { action: Action; entity?: any }) {
    switch (e.action.action) {
      case 'view':
        this.viewApp(e.entity);
        break;
      case 'view-tab':
        this.newTab(e.entity);
        break;
      case 'redFlag':
        this.currentLoan = e.entity;
        this.openCloseRedFlag();
        break;
    }
  }
  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  async postPagination() {
    for (let data of this.list) {
      const actions = [{ label: 'View', action: 'view' }];

      if (data?.assignee?.firstName) {
        data['assigneeName'] = (data?.assignee?.firstName || '') + ' ' + (data?.assignee?.lastName || '');
      } else {
        data['assigneeName'] = 'Unassigned';
      }

      if (this.totalAmount(data) && data?.source !== 'INPUTS_NETWORK') {
        data['loanAmount'] =
          this.totalAmount(data)
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'KES';
      } else {
        data['loanAmount'] = '-';
      }
      const mapper = [
        { label: 'QUALIFICATION', value: 'Qualification' },
        { label: 'APP', value: 'Application' },
        { label: 'INPUTS_NETWORK', value: 'Inputs Network' }
      ];
      if (data?.source) {
        data['source'] = mapper.find((data2) => data2.label === data.source)?.value;
      } else {
        data['source'] = '-';
      }
      data['applicantName'] = this.applicantName(data);
      if (!this.authService.isAgent) {
        const label = data?.redFlag ? 'Remove Red Flag' : 'Red Flag';
        if (label === 'Remove Red Flag') {
          data['customClass'] = 'red-flag';
        }
        actions.push({
          label,
          action: 'redFlag'
        });
      }
      const qualificationDetails = data?.qualification;
      if (!data?.qualification) {
        data['userRequestedAmount'] = 0;
        continue;
      }
      data['qualification'] = qualificationDetails;
      if (data?.source === 'Inputs Network') {
        data['loanAmount'] = qualificationDetails?.exactAmount
          ? qualificationDetails?.exactAmount?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',') + 'KES'
          : '-';
      }
      data['userRequestedAmount'] = qualificationDetails?.exactAmount
        ? qualificationDetails?.exactAmount?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',') + 'KES'
        : '-';

      console.log(data['loanAmount']);
      data['actions'] = actions;
    }
    // for (let item of this.list) {
    //   const qualificationDetails = await this.getQualificationByLoanOrg(item);
    //   if (!qualificationDetails) {
    //     item['userRequestedAmount'] = 0;
    //     continue;
    //   }
    //   item['qualification'] = qualificationDetails;
    //   if (item?.source === 'Inputs Network') {
    //     item['loanAmount'] = qualificationDetails?.financingDetails?.exactAmount
    //     ? qualificationDetails?.financingDetails?.exactAmount?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',') + 'KES'
    //     : '-';
    //   }
    //   item['userRequestedAmount'] = qualificationDetails?.financingDetails?.exactAmount
    //     ? qualificationDetails?.financingDetails?.exactAmount?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',') + 'KES'
    //     : '-';

    //     console.log(item['loanAmount'])
    // }
  }
  // manualFilter(filter: any[]) {
  //   const manuals = ['applicantName', 'loanAmount'];

  //   const autoFilters = filter.filter((data) => manuals.indexOf(data.column) === -1);
  //   const manualFilters = filter.filter((data) => manuals.indexOf(data.column) !== -1);
  //   this.noPagFilter(autoFilters);
  //   this.list = this.list.filter((item: any) => {
  //     let res = true;
  //     manualFilters.forEach((f) => {
  //       if (f.column === 'applicantName') {
  //         const name = `${item.applicantName}`.toLowerCase();
  //         res = res && Boolean(name.match(new RegExp(f.value, 'gi'))?.length);
  //       }
  //       if (f.column === 'loanAmount') {
  //         res = res && this.totalAmount(item) === f.value;
  //       }
  //     });
  //     return res;
  //   });
  //   this.totalCount = this.list.length;
  // }
  // manualSort(sort: Sort) {
  //   if (!sort.active || sort.direction === '') {
  //     this.sortData(sort);
  //     return;
  //   } else {
  //     this.options.sortOrder = sort.direction;
  //     this.options.sortBy = sort.active;
  //     if (sort.active === 'loanAmount') {
  //       this.list = this.list.sort((a, b) => {
  //         const aAmount = +a.loanAmount?.split('KES')?.[0]?.replace(/,/g, '');
  //         const bAmount = +b.loanAmount?.split('KES')?.[0]?.replace(/,/g, '');
  //         return sort.direction === 'asc' ? aAmount - bAmount : bAmount - aAmount;
  //       });
  //     } else {
  //       this.sortData(sort);
  //     }
  //   }
  // }
  totalAmount(item: any) {
    // return item?.products?.reduce?.((a, b) => {
    //   return a + b?.totalBuyingPrice;
    // }, 0);
    return item?.totalBuyingDeal || 0;
  }
  goToBuilder() {
    this.router.navigate(['/trade-desk/builder']);
  }
  override search(input: string) {
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.list = this.unsortedList.slice();
      this.totalCount = this.list.length;
      if (input) {
        this.clearFilters();
        const keys = [
          'businessDetails.name',
          'assignee.personalInformation.firstName',
          'assignee.personalInformation.lastName',
          'personalDetails.firstName',
          'personalDetails.lastName',
          'personalDetails.primaryPhoneNumber',
          'personalDetails.email'
        ];
        const opts = [];
        keys.forEach((key) => {
          const opt = {};
          opt['column'] = key;
          opt['value'] = input;
          opt['option'] = 'contains';
          opts.push(opt);
        });
        this.queryMode = QueryMode.OR;
        this.filterOptions = opts;
        this.options.pageNumber = 1;
        this.pageChanged(1);
        // this.list = this.list.filter((data) => {
        //   let date = new Date(input);
        //   let dateValid: boolean;
        //   if (date.getTime() !== NaN) {
        //     date = new Date(moment(date).format('DD/MM/YYYY'));
        //     dateValid = new Date(data.dateCreated).toDateString() === date.toDateString();
        //   }
        //   return (
        //     dateValid ||
        //     keys.some((key) => {
        //       return this.getValue(data, key)?.toString().toLowerCase().includes(input.toLowerCase());
        //     })
        //   );
        // });
        this.totalCount = this.list.length;
      } else {
        this.queryMode = QueryMode.AND;
        this.options.pageNumber = 1;
        this.filterOptions = [];
        this.pageChanged(1);
      }
    }, 600);
  }
  openCloseRedFlag(close?: boolean) {
    const display = close ? 'none' : 'flex';
    if (this.currentLoan?.redFlag) {
      this.reasonTitle = 'Remove red flag reason';
    } else {
      this.reasonTitle = 'Red flag reason';
    }
    $('#reasonModal').css({ display });
  }
  redFlag(obj: any) {
    const reason = JSON.stringify(obj);

    this.spinner.showSpinner();
    const orgId = this.currentLoan?.organization;
    const redFlag = !this.currentLoan?.redFlag
    this.http
      .put(`${environment.new_api_url}/api/organization/${orgId}/redFlag`, {
        reason,
        redFlag
      })
      .subscribe((data) => {
        this.router.navigateByUrl(this.router.url);
        this.openCloseRedFlag(true);
        const message = redFlag ? 'Loan application has been red flagged' : 'Loan application has been unflagged';
        this.alertService.showAlert(message, 'success');
      });
  }
  // async getQualificationByLoanOrg(item: any): Promise<any> {
  //   console.log(item);
  //   let env =
  //     environment.new_api_url.includes('qa') || environment.new_api_url.includes('localhost')
  //       ? 'qa'
  //       : environment.new_api_url.includes('staging')
  //       ? 'staging'
  //       : 'prod';
  //   if (environment.production) {
  //     env = 'prod';
  //   }
  //   const apiUrl = `https://xvgw00u5rl.execute-api.us-east-1.amazonaws.com/prod/qualification-org?env=${env}`;
  //   return new Promise((res, rej) => {
  //     const orgId = item.createdBy?.policyRole?.organization?._id;
  //     this.http
  //       .post(apiUrl, {
  //         orgId
  //       })
  //       .pipe(
  //         catchError((err) => {
  //           return of(undefined);
  //         })
  //       )
  //       .subscribe((data) => {
  //         res(data);
  //       });
  //   });
  // }
}
