import { GeneralFinanceListComponent } from './general-finance-list/general-finance-list.component';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilterOptions } from '@avenews/agt-sdk';
import { FinanceListResolver } from './resolvers/finance-list.resolver';
import { ApplicationResolver } from './resolvers/application.resolver';
import { DocumentsResolver } from './resolvers/documents.resolver';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { LoanResolver } from './resolvers/loan.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      runGuardsAndResolvers: 'always',
      path: 'finance/new-applications',
      component: GeneralFinanceListComponent,
      resolve: { finance: FinanceListResolver },
      data: {
        filters: [
          {
            value: 'new',
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'finance/data-analysis',
      component: GeneralFinanceListComponent,
      resolve: { finance: FinanceListResolver },
      data: {
        filters: [
          {
            value: 'data_analysis',
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'finance/risk-assessment',
      component: GeneralFinanceListComponent,
      resolve: { finance: FinanceListResolver },
      data: {
        filters: [
          {
            value: 'risk_assessment',
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'finance/approved',
      component: GeneralFinanceListComponent,
      resolve: { finance: FinanceListResolver },
      data: {
        filters: [
          {
            value: 'approved',
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'finance/declined',
      component: GeneralFinanceListComponent,
      resolve: { finance: FinanceListResolver },
      data: {
        filters: [
          {
            value: 'declined',
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      path: 'finance/:id',
      runGuardsAndResolvers: 'always',
      component: ApplicationViewComponent,
      resolve: { application: ApplicationResolver, mainData: LoanResolver, documents: DocumentsResolver }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule {}
