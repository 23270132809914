import { environment } from '../../../../environments/environment';
import { AlertService } from '@avenews/alerts';
import { CreateDealDTO, DealStatus, EditDealDTO, RepaymentMode, User } from '@avenews/agt-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormTemplateComponent } from 'libs/shared/src/lib/components/base-form-template/base-form-template.component';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { SpinnerToggleService, MinDateValidator, MinControlValidator, TwoFaService } from '@avenews/shared-lib';
import * as $ from 'jquery';
import { catchError, EMPTY } from 'rxjs';

interface RepaymentType {
  viewValue: string;
  value: string;
}
interface LoanTerm {
  duration: number;
  amount: number;
  percentage: string;
}

@Component({
  selector: 'app-create-deal',
  templateUrl: './create-deal.component.html',
  styleUrls: ['./create-deal.component.scss']
})
export class CreateDealComponent extends BaseFormTemplateComponent implements OnChanges {
  beneficiaryAccountId: string;
  beneficiaryId: string;
  user: User;
  alreadySaved: boolean = false;
  multiRepamentGroup: FormGroup;
  linkedUserAccount: any;
  today = new Date();
  dealForm: FormGroup;
  errorDays = false;
  dedicatedTypeMode = false;
  ascending = true;
  dayMax: boolean;
  maxAmountError: boolean;
  equalDays: boolean;
  isAccountLinked = true;
  @Input() isEdit: boolean;
  @Input() deal: any;
  @Input() users: any;

  statuses = [
    {
      viewValue: 'Planned',
      value: 'planned'
    }
  ];
  dealType = [
    {
      viewValue: 'General',
      value: 'general'
    },
    {
      viewValue: 'Dedicated',
      value: 'dedicated'
    },
    {
      viewValue: 'Inputs Network',
      value: 'inputsNetwork'
    }
  ];
  periodType = [
    {
      viewValue: 'Recurring',
      value: 'recurring'
    },
    {
      viewValue: 'Credit line',
      value: 'credit-line'
    }
  ];
  repaymentType: RepaymentType[] = [];
  maxRepyments = [
    {
      viewValue: 2,
      value: 2
    },
    {
      viewValue: 3,
      value: 3
    }
  ];
  environment = environment;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public spinner: SpinnerToggleService,
    private twoFaService: TwoFaService,
    private alertService: AlertService
  ) {
    super();

    this.route.data.subscribe((data) => {
      this.user = data['user'];
    });

    this.dealForm = this.fb.group({
      name: [undefined, [Validators.required]],
      status: [{ value: 'planned', disabled: true }],
      type: ['general', [Validators.required]],
      periodType: [undefined, [Validators.required]],
      minimumFinancingAmount: [undefined, [Validators.required, Validators.min(1)]],
      maximumFinancingAmount: [undefined, [Validators.required, Validators.min(1), MinControlValidator('minimumFinancingAmount', true)]],
      maxRepaymentAmount: [undefined, []],
      approvedGoods: [undefined],
      feeMultiplier: [{ value: 0, disabled: true }],
      feeAmount: [{ value: 0, disabled: true }, []],
      startDate: [undefined, [Validators.required]],
      endDate: [undefined, [Validators.required, MinDateValidator('startDate')]],
      maxDuration: [undefined, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
      repaymentType: ['single', [Validators.required]],
      maxInstallmentsAllowed: [undefined],
      ratePerWeek: [undefined],
      multiRepayments: this.fb.array([])
    });
    this.multiRepamentsArray.valueChanges.subscribe((data) => {
      if (this.dealForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED && this.dealForm.get('maxRepaymentAmount').value) {
        this.calculatePercentage(this.multiRepamentsArray);
      }
    });
    this.dealForm.get('maxRepaymentAmount').valueChanges.subscribe((data) => {
      if (this.dealForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED) {
        this.calculatePercentage(this.multiRepamentsArray);
      }
    });
    this.dealForm.valueChanges.subscribe((data) => {
      if (data.repaymentType === RepaymentMode.MULTI_FIXED && !this.multiRepamentsArray.controls.length) {
        this.multiRepamentsArray.push(this.newRepaymentsGroup, { emitEvent: false });
        this.multiRepamentsArray.push(this.newRepaymentsGroup, { emitEvent: false });
      }
      this.adjustArray(data);
      if (data.type === 'inputsNetwork') {
        this.dealForm.get('repaymentType').setValue('multi', { emitEvent: false });
      }
      if (
        (data.type === 'dedicated' && data.repaymentType === 'multi') ||
        (data.type === 'inputsNetwork' && data.repaymentType === 'multi')
      ) {
        this.dealForm.get('maxInstallmentsAllowed').setValidators([Validators.required]);
        this.dealForm.get('ratePerWeek').setValidators([Validators.required]);
        this.dealForm.get('feeMultiplier').removeValidators([Validators.required]);
        this.dealForm.get('feeMultiplier').markAsUntouched();
        this.dealForm.get('feeMultiplier').setValue(null, { emitEvent: false });
        this.dealForm.get('feeAmount').removeValidators([Validators.required]);
        this.dealForm.get('feeAmount').markAsUntouched();
        this.dealForm.get('feeAmount').setValue(null, { emitEvent: false });
        this.dealForm.get('maxRepaymentAmount').removeValidators([Validators.required]);
        this.dealForm.get('maxRepaymentAmount').markAsUntouched();
        this.dealForm.get('maxRepaymentAmount').setValue(null, { emitEvent: false });
      } else if (data.type === 'general' || data.repaymentType === 'single') {
        this.dealForm.get('maxInstallmentsAllowed').removeValidators([Validators.required]);
        this.dealForm.get('maxInstallmentsAllowed').markAsUntouched();
        this.dealForm.get('maxInstallmentsAllowed').setValue(null, { emitEvent: false });
        this.dealForm.get('ratePerWeek').removeValidators([Validators.required]);
        this.dealForm.get('ratePerWeek').markAsUntouched();
        this.dealForm.get('ratePerWeek').setValue(null, { emitEvent: false });
        this.dealForm.get('feeMultiplier').setValidators([Validators.required]);
        this.dealForm
          .get('feeAmount')
          .setValidators([Validators.required, Validators.min(1), MinControlValidator('maximumFinancingAmount', true)]);
        this.dealForm
          .get('maxRepaymentAmount')
          .setValidators([Validators.required, Validators.min(1), MinControlValidator('maximumFinancingAmount', true)]);
      }
      if (data.type === 'general' && data.periodType !== 'recurring') {
        this.dealForm.get('repaymentType').setValue('single', { emitEvent: false });
      }
      if (
        data.type === 'dedicated' &&
        data.periodType !== 'recurring' &&
        this.dealForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED
      ) {
        this.dealForm.get('repaymentType').setValue('single', { emitEvent: false });
      }
    });
    this.initCalculators();
    this.formInput = this.dealForm;
    this.errorMessages = {
      minimumFinancingAmount: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'max', message: 'Can’t be bigger than the Max amount' }
      ],
      maximumFinancingAmount: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'minAmount', message: 'Can’t be smaller than the Min financing amount' }
      ],
      maxRepaymentAmount: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'minAmount', message: 'Can’t be smaller than the Maximum financing amount' }
      ],
      startDate: [
        { type: 'required', message: 'This field is required' },
        { type: 'minDate', message: 'Invalid amount', messageKey: 'minDate' }
      ],
      endDate: [
        { type: 'required', message: 'This field is required' },
        { type: 'minDate', message: 'Invalid date' }
      ],
      maxDuration: [
        { type: 'pattern', message: 'number is invalid' },
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' }
      ],
      duration: [
        { type: 'required', message: 'This field is required' },
        { type: 'max', message: 'Invalid amount' }
      ]
    };
  }
  override ngOnInit(): void {
    this.dealForm.get('type').valueChanges.subscribe((data) => {
      if (data === 'dedicated') {
        this.dedicatedTypeMode = true;
      } else {
        this.dedicatedTypeMode = false;
      }
    });
    if (this.dedicatedTypeMode && this.linkedUserAccount) {
      this.isAccountLinked = true;
    }
  }
  ngOnChanges() {
    if (this.deal && this.isEdit) {
      this.dealForm.patchValue({
        name: this.deal.name || undefined,
        status: this.deal.status || undefined,
        type: this.deal.type || undefined,
        periodType: this.deal.periodType || undefined,
        minimumFinancingAmount: this.deal.minimumFinancingAmount || 100000,
        maximumFinancingAmount: this.deal.maximumFinancingAmount || undefined,
        approvedGoods: this.deal.approvedGoods || undefined,
        startDate: this.deal.startDate || undefined,
        endDate: this.deal.endDate || undefined,
        maxDuration: this.deal.maxDuration || undefined,
        repaymentType: this?.deal?.repaymentInfo?.repaymentMode || undefined,
        maxRepaymentAmount: this.deal.repaymentInfo?.repaymentAmount || undefined,
        feeMultiplier: this.deal.repaymentInfo?.markupMultiplier || undefined,
        feeAmount: this.deal.repaymentInfo?.markupAmount || undefined,
        maxInstallmentsAllowed: this?.deal?.repaymentInfo?.maxInstallmentsAllowed || undefined,
        ratePerWeek: this?.deal?.repaymentInfo?.ratePerWeek || undefined
      });
      if (this.deal.repaymentInfo.repaymentMode === RepaymentMode.MULTI_FIXED) {
        this.dealForm.patchValue({
          multiRepayments: this.calculateInputs()
        });
      }
    }
    if (!this.isEdit) {
      this.dealForm.get('minimumFinancingAmount')?.setValue(100000);
    }
  }

  calculateInputs() {
    for (let i = 0; i < this.deal.repaymentInfo.details.length; i++) {
      const data = this.deal.repaymentInfo.details[i];
      const form = this.multiRepamentsArray.at(i) as FormGroup;

      form.patchValue({
        duration: data.duration,
        percentage: data.percentage,
        amount: (this.deal.repaymentInfo.repaymentAmount * data.percentage) / 100
      });
    }
  }

  adjustArray(data: any) {
    this.repaymentType = [];
    const isRecurring = this.dealForm.get('periodType').value === 'recurring';
    let arr = [];

    if (data.type === 'general' && isRecurring) {
      arr = [
        { viewValue: 'Single', value: 'single' },
        { viewValue: 'Multi (regular)', value: RepaymentMode.MULTI_FIXED }
      ];
    } else if (data.type === 'dedicated' && isRecurring) {
      arr = [
        { viewValue: 'Single', value: 'single' },
        { viewValue: 'Multi (regular)', value: RepaymentMode.MULTI_FIXED },
        { viewValue: 'Multi (Inputs partners)', value: 'multi' }
      ];
    } else if (data.type === 'dedicated' && !isRecurring) {
      arr = [
        { viewValue: 'Single', value: 'single' },
        { viewValue: 'Multi (Inputs partners)', value: 'multi' }
      ];
    } else if (data.type === 'inputsNetwork') {
      arr = [{ viewValue: 'Multi (Inputs partners)', value: 'multi' }];
    }
    this.repaymentType.push(...arr);
  }

  calculatePercentage(data: any) {
    data.controls.forEach((control: any) => {
      let amount = control.value.amount || 0;
      control.get('percentage').setValue((amount * 100) / this.dealForm.get('maxRepaymentAmount').value, { emitEvent: false });
    });
  }

  checkAmountsSum() {
    const finalAmount = this.dealForm.get('multiRepayments').value.reduce((acc, obj) => acc + obj.amount, 0);
    this.maxAmountError = finalAmount !== this.dealForm.get('maxRepaymentAmount').value;
  }

  openModal() {
    $('#linkUserToDealAccount').css({ display: 'flex' });
  }

  private initCalculators() {
    this.dealForm.get('startDate').valueChanges.subscribe((data) => {
      this.dealForm.get('endDate').updateValueAndValidity();
    });
    this.dealForm.get('minimumFinancingAmount').valueChanges.subscribe(() => {
      this.dealForm.get('maximumFinancingAmount').updateValueAndValidity({ emitEvent: false });
    });
    this.dealForm.get('maximumFinancingAmount').valueChanges.subscribe(() => {
      this.dealForm.get('maxRepaymentAmount').updateValueAndValidity({ emitEvent: false });
    });
    this.dealForm.valueChanges.subscribe((data) => {
      if (data.maximumFinancingAmount && data.maxRepaymentAmount) {
        this.dealForm.get('feeAmount').setValue(data.maxRepaymentAmount - data.maximumFinancingAmount, { emitEvent: false });
        this.dealForm
          .get('feeMultiplier')
          .setValue((data.maxRepaymentAmount / data.maximumFinancingAmount).toFixed(2), { emitEvent: false });
      } else {
        this.dealForm.get('feeAmount').setValue(0, { emitEvent: false });
        this.dealForm.get('feeMultiplier').setValue(0, { emitEvent: false });
      }
    });
  }

  close() {
    $('#addDealWizard').fadeOut('slow', () => {
      this.deleteData();
    });
  }

  deleteData() {
    this.dealForm.reset();
    this.dealForm.patchValue({
      status: 'planned',
      minimumFinancingAmount: 100000,
      type: 'general',
      repaymentType: 'single',
      supplierId: undefined
    });
    this.linkedUserAccount = undefined;
    this.dealForm.markAsUntouched();
  }

  async submit() {
    if (!this.isEdit) {
      if (this.dealForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED) {
        this.checkRepaymentsDays();
        this.checkAmountsSum();
        if (this.dayMax || !this.ascending || this.maxAmountError || this.equalDays || this.errorDays) {
          return;
        }
      }
      if (this.dealForm.get('repaymentType').value !== RepaymentMode.MULTI_FIXED && this.multiRepamentsArr.length) {
        this.multiRepamentsArray.clear();
      }
      this.validateForm(this.dealForm);
      if (this.dedicatedTypeMode && !this.linkedUserAccount) {
        this.isAccountLinked = false;
        return;
      } else {
        this.isAccountLinked = true;
        if (this.dealForm.valid) {
          if (await this.twoFaService.open()) {
            this.spinner.showSpinner();
            const formValue = this.dealForm.value;
            const FilteredMultirepayments = formValue.multiRepayments.map((obj) => {
              const { amount, ...rest } = obj;
              return rest;
            });
            const deal: CreateDealDTO = {
              name: formValue.name,
              type: formValue.type,
              periodType: formValue.periodType,
              startDate: formValue.startDate,
              endDate: formValue.endDate,
              minimumFinancingAmount: formValue.minimumFinancingAmount,
              maximumFinancingAmount: formValue.maximumFinancingAmount,
              repaymentInfo: {
                repaymentMode: formValue?.repaymentType,
                repaymentAmount: formValue?.maxRepaymentAmount,
                markupMultiplier: formValue?.feeMultiplier,
                markupAmount: formValue?.feeAmount,
                maxInstallmentsAllowed: formValue?.maxInstallmentsAllowed,
                ratePerWeek: formValue?.ratePerWeek,
                details: FilteredMultirepayments
              },
              approvedGoods: formValue.approvedGoods,
              maxDuration: formValue.maxDuration,
              ownerId: this?.user?._id,
              supplierId: this.linkedUserAccount?.policyRole?.organization?.orgUser
            };
            if (deal.repaymentInfo.repaymentMode !== RepaymentMode.MULTI_FIXED) {
              delete deal.repaymentInfo.details;
            }
            if ((deal?.type as any) === 'inputsNetwork') {
              delete deal?.supplierId;
            }
            this.http
              .post(`${this.environment.new_api_url}/api/deal`, deal)
              .pipe(
                catchError((err: any) => {
                  this.spinner.hideSpinner();
                  this.alertService.showAlert(err?.error?.message, 'danger');
                  return EMPTY;
                })
              )
              .subscribe((data) => {
                this.router.navigateByUrl(this.router.url);
                this.spinner.hideSpinner();
                this.alertService.showAlert(`Deal was added successfully`, 'success');
              });
            this.close();
          }
        }
      }
    }
  }

  checkRepaymentsDays() {
    this.checkIfDaysAreInOrder();
    this.checkEqualDays();
    this.checkEndDay();
  }

  checkIfDaysAreInOrder() {
    const arr = this.dealForm.get('multiRepayments').value;
    const propName = 'duration';
    this.ascending = arr.every((item, index) => index === 0 || item[propName] >= arr[index - 1][propName]);
  }

  checkEqualDays() {
    const arr = this.dealForm.get('multiRepayments').value;
    const propName = 'duration';
    this.equalDays = new Set(arr.map((item) => item[propName])).size !== arr.length;
  }

  checkEndDay() {
    let lastDay = this.dealForm.get('multiRepayments').value[this.dealForm.get('multiRepayments').value.length - 1];
    lastDay.duration !== this.dealForm.get('maxDuration').value ? (this.errorDays = true) : (this.errorDays = false);
  }

  Edit() {
    if (this.isEdit && this.deal) {
      if (this.dealForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED) {
        this.checkRepaymentsDays();
        this.checkAmountsSum();
        if (this.dayMax || !this.ascending || this.maxAmountError || this.equalDays || this.errorDays) {
          return;
        }
      }
      if (this.dealForm.get('repaymentType').value !== RepaymentMode.MULTI_FIXED && this.multiRepamentsArr.length) {
        this.multiRepamentsArray.clear();
      }
      this.validateForm(this.dealForm);
      if (this.dealForm.valid) {
        const FilteredMultirepayments = this.dealForm.value.multiRepayments.map((obj) => {
          const { amount, ...rest } = obj;
          return rest;
        });
        this.spinner.showSpinner();
        const updateDeal: any = {
          name: this.dealForm.value.name,
          periodType: this.dealForm.value.periodType,
          startDate: this.dealForm.value.startDate,
          endDate: this.dealForm.value.endDate,
          minimumFinancingAmount: this.dealForm.value.minimumFinancingAmount,
          maximumFinancingAmount: this.dealForm.value.maximumFinancingAmount,
          repaymentInfo: {
            repaymentMode: this.dealForm.value?.repaymentType,
            repaymentAmount: this.dealForm.value.maxRepaymentAmount,
            markupMultiplier: this.dealForm.value.feeMultiplier,
            markupAmount: this.dealForm.value.feeAmount,
            maxInstallmentsAllowed: this.dealForm.value.maxInstallmentsAllowed,
            ratePerWeek: this.dealForm.value.ratePerWeek,
            details: FilteredMultirepayments
          },
          approvedGoods: this.dealForm.value.approvedGoods,
          maxDuration: this.dealForm.value.maxDuration
        };
        if (updateDeal.repaymentInfo.repaymentMode !== RepaymentMode.MULTI_FIXED) {
          delete updateDeal.repaymentInfo.details;
        }
        this.http
          .put(`${this.environment.new_api_url}/api/deal/${this.deal._id}`, updateDeal)
          .pipe(
            catchError((err: any) => {
              this.spinner.hideSpinner();
              this.alertService.showAlert(`Deal was not updated`, 'danger');
              return EMPTY;
            })
          )
          .subscribe((data) => {
            this.router.navigateByUrl(this.router.url);
            this.spinner.hideSpinner();
            this.alreadySaved = true;
            this.alertService.showAlert(`Deal was updated successfully`, 'success');
          });
        this.close();
      }
    }
  }
  next({ user }: any) {
    this.linkedUserAccount = user;
  }
  addPayment() {
    this.multiRepamentsArray.push(this.newRepaymentsGroup);
  }
  createGroup(repayment: any) {
    return this.fb.group({
      ...repayment
    });
  }
  get multiRepamentsArr() {
    return this.multiRepamentsArray;
  }
  get multiRepamentsArray() {
    return this.dealForm.get('multiRepayments') as FormArray;
  }
  saveProduct() {
    let prod: any;
    this.multiRepamentGroup.markAllAsTouched();
    if (this.multiRepamentGroup.valid) {
      prod = {
        ...this.multiRepamentGroup.value
      };
      this.addRepaymentToArray(prod);
      this.multiRepamentGroup = this.newRepaymentsGroup;
    }
  }
  get newRepaymentsGroup(): FormGroup {
    return this.fb.group({
      duration: [undefined, Validators.required],
      amount: [undefined, Validators.required],
      percentage: [undefined, Validators.required]
    });
  }
  addRepaymentToArray(repayment: any) {
    this.multiRepamentsArr.push(this.createGroup(repayment));
  }
  removePayment(idx: any) {
    if (!idx || idx === 1) return;
    else this.multiRepamentsArray.removeAt(idx);
  }
}
