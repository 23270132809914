import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactName'
})
export class ContactNamePipe implements PipeTransform {
  transform(contact: any) {
    return contact.firstName
      ? this.upperCaseFirstLetter(contact.firstName) + ' ' + this.upperCaseFirstLetter(contact.lastName)
      : contact.fullName;
  }
  upperCaseFirstLetter(name: string) {
    if (name && name[0]) {
      return name[0].toUpperCase() + name.slice(1);
    }
    return name;
  }
}
