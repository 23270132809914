import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumber'
})
export class AccountNumberPipe implements PipeTransform {
  constructor() {}
  transform(accNum: string | number, isTopBanner?: boolean) {
    if (typeof accNum === 'number') {
      accNum = accNum.toString();
    }
    if (isTopBanner) {
      return accNum.slice(-4);
    }
    return '****' + accNum.slice(-4);
  }
}
