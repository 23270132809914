<div class="modal-box" id="cashbackModal">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Cashback</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p>Cash back for {{ user | fullname | titlecase }}</p>
      <form [formGroup]="cashbackForm">
        <div>
          <app-base-form-template
            labelName="Balance"
            inputType="text"
            controlName="balanceType"
            placeholderName="Income balance"
            [group]="cashbackForm"
            class="input"
            [rOnly]="true"
          >
          </app-base-form-template>
        </div>
        <div>
          <app-base-form-template
            labelName="Amount"
            inputType="number"
            inputName="default"
            class="input"
            controlName="amount"
            [group]="cashbackForm"
            [min]="0"
          >
            <span #append append>KES</span>
          </app-base-form-template>
        </div>
      </form>
    </div>
    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="createCashback()">Create</button>
    </div>
  </div>
</div>
