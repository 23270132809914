import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthenticationService } from './../services/authentication/authentication.service';
import { Inject, Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { RouterModule } from '@angular/router';
import { CheckCircle, Settings } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { SharedModule } from '@avenews/shared-lib';
import { UiLibraryModule } from '@avenews/ui-library';
import { SocialLoginModule, FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { LoginComponent } from './login/login.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { EmailSentModalComponent } from './email-sent-modal/email-sent-modal.component';
import { ResetPasswordModal } from './reset-password/reset-password.component';
import { ExpiredTokenResolver } from './resolvers/expired-token.resolver';
import { ExpiredTokenResetComponent } from './expired-token-reset/expired-token-reset.component';
export function socialConfigFactory(loginConfigService: LoginConfigService) {
  return loginConfigService.getConfigs();
}
const icons = {
  CheckCircle,
  Settings
};
@Injectable({
  providedIn: 'root'
})
export class LoginConfigService {
  constructor(@Inject('loginConfig') private loginConfig: any) {}
  getConfigs() {
    const providers = [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(this.loginConfig.environment.oauthClientIds.facebook)
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(this.loginConfig.environment.oauthClientIds.google)
      }
    ];
    return {
      autoLogin: false,
      providers: providers
    } as SocialAuthServiceConfig;
  }
}
// const config = new AuthServiceConfig([
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(environment.oauthClientIds.facebook)
//   },
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.oauthClientIds.google)
//   }
// ]);
// export function provideConfig() {
//   return config;
// }

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordModal,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordModalComponent,
    EmailSentModalComponent,
    ExpiredTokenResetComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    LoginRoutingModule,
    SocialLoginModule,
    SharedModule,
    RouterModule,
    CommonModule,
    UiLibraryModule,
    FeatherModule.pick(icons)
  ],

  providers: [
    ExpiredTokenResolver,
    // {
    //   provide: 'SocialAuthServiceConfig',

    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(loginConfig.environment.oauthClientIds.google)
    //       },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider(loginConfig.environment.oauthClientIds.facebook)
    //       }
    //     ]
    //   } as SocialAuthServiceConfig
    // },
    { provide: 'SocialAuthServiceConfig', useFactory: socialConfigFactory, deps: [LoginConfigService] },
    AuthenticationService
  ]
})
export class LoginModule {
  public static forRoot(loginConfig: any): ModuleWithProviders<LoginModule> {
    return {
      ngModule: LoginModule,
      providers: [
        {
          provide: 'loginConfig',
          useValue: loginConfig
        }
      ]
    };
  }
}
