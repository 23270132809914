import { TradeDeskService } from './../../services/trade-desk.service';
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { ModalRef, ModalService, SpinnerToggleService, hasPassword } from '@avenews/shared-lib';
import { Router } from '@angular/router';
import { UploadFileDTO } from '@avenews/agt-sdk';
import { ExpiryDateModal } from '../expiry-date-modal/expiry-date-modal';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';

enum FileType {
  CR12 = 'cr12',
  COI = 'coi',
  KRA = 'kra',
  GENERAL = 'general',
  TRADE_DOCS = 'tradeDocs',
  VIDEO_CALL = 'videoCall'
}
interface UploadFileValidatorDTO {
  document: UploadFileDTO;
  fileType: FileType;
  password?: string;
  expiresAt?: Date;
  intendedType?: string;
}

@Component({
  selector: 'td-documents',
  templateUrl: './td-documents.component.html',
  styleUrls: ['./td-documents.component.scss']
})
export class TdDocumentsComponent implements OnInit, OnDestroy {
  currId: string;
  documents: any[];
  currentDoc: any;
  pdfSrc;
  documentOptionsMap = {
    bankStatement: 'Bank Statement',
    MPesaStatment: 'M-PESA statement',
    cr12: 'CR12 (company ownership document)',
    coi: 'Official certificate of incorporation',
    kra: 'KRA PIN certificate',
    tradeDocs: 'Trade documents',
    general: 'General'
  };
  @Input() loan: any;
  @Output() openModal = new EventEmitter();
  acceptedMimeTypes = ['application/pdf'];
  prettyAcceptedTypes = ['pdf'];
  environment = environment;
  subscription: Subscription;
  kycDocs = ['cr12', 'coi', 'kra'];
  approvedSections = {
    cr12: false,
    coi: false,
    kra: false
  };
  deleteMode = false;
  currDocId: string;
  item: any;
  showDecModal: boolean;
  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private spinner: SpinnerToggleService,
    private tdService: TradeDeskService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.js';

    this.subscription = this.route.params.subscribe(async (params) => {
      this.currId = params['id'];
    });
    this.route.data.subscribe(({ documents, loan }) => {
      const docNeeded = [];
      loan?.documentsNeeded?.forEach((data) => {
        docNeeded.push({
          ...data.document,
          type: data?.type,
          password: data?.password,
          status: data?.status,
          _id: data?._id,
          description: data?.description
        });
      });
      const fileValidators = [];
      loan?.filesValidator?.forEach((data) => {
        fileValidators.push(data);
      });
      if (!documents) {
        documents = [];
      }
      documents = [...documents, ...fileValidators];
      const sorter = (a: any, b: any) => {
        if (a?.dateCreated < b?.dateCreated) {
          return 1;
        }
        if (a?.dateCreated > b?.dateCreated) {
          return -1;
        }
        return 0;
      };

      this.documents = [
        {
          title: 'CR12',
          docType: 'cr12',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'cr12')?.sort(sorter)
        },
        {
          title: 'CERTIFICATE OF INCORPORATION / REGISTRATION',
          docType: 'coi',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'coi')?.sort(sorter)
        },
        {
          title: 'KRA pin / Tax Compliance Certificate',
          docType: 'kra',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'kra')?.sort(sorter)
        },
        {
          title: 'Trade Documents',
          docType: 'tradeDocs',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'tradeDocs')?.sort(sorter)
        },
        {
          title: 'General documents',
          docType: 'general',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'general')?.sort(sorter)
        },
        {
          title: 'Video call',
          docType: 'videoCall',
          data: loan?.files?.filter((doc: any) => doc.fileType === 'videoCall')?.sort(sorter)
        },
        {
          title: 'Mpesa statement',
          docType: 'MPesaStatment',
          data: loan?.files
            ?.filter((doc: any) => {
              return doc.fileType === 'MPesaStatment';
            })
            ?.sort(sorter)
        },
        {
          title: 'Bank statement',
          docType: 'bankStatement',
          data: loan?.files
            ?.filter((doc: any) => {
              return doc.fileType === 'bankStatement';
            })
            ?.sort(sorter)
        }
      ];
      this.documents.forEach((document) => {
        // add cssStatus to each document.data based on checkStatus()
        document.data.forEach((doc: any) => {
          if (!doc?.originalName) {
            doc['originalName'] = doc?.fileName;
          }
          if (doc.status === 'declined') {
            doc['cssStatus'] = this.checkStatus('declined');
          } else {
            doc['cssStatus'] = this.checkStatus(doc?.status);
          }
        });
      });
      console.log(this.documents);
      Object.keys(this.approvedSections).forEach((section) => {
        this.approvedSections[section] = this.documents
          .find((doc) => {
            return doc.docType === section;
          })
          ?.data?.some((doc) => {
            return doc?.status === 'approved';
          });
      });
    });
  }
  validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 50e6;
  }

  async uploadDocs(event: any, docType: FileType) {
    let file: File = event.target.files[0];
    const checkTypeFor = [FileType.COI, FileType.CR12, FileType.KRA];
    if (!checkTypeFor.includes(docType)) {
      if (file.size > 50e6) {
        this.alertService.showAlert(`File too big`, 'danger');
        return;
      }
    } else {
      if (!this.validateFile(file)) {
        this.alertService.showAlert(`Maximum file size is 50MB and it should be ${this.prettyAcceptedTypes.join(', ')}`, 'danger');
        return;
      }
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      const base64File = (reader.result as string).split(',')[1];
      const doc: UploadFileValidatorDTO = {
        document: { file: base64File, fileName: file.name, mime: file.type },
        fileType: docType
      };
      let isEncrypted = false;
      if (doc.document.mime === 'application/pdf') {
        isEncrypted = await hasPassword(doc.document);
      }

      let modal: ModalRef;
      if (docType === FileType.GENERAL) {
        modal = this.openExpiryModal({
          showPass: isEncrypted,
          showExpiryDate: true,
          document: doc.document,
          expiryDateNotMandatory: true
        });
      } else if (this.kycDocs.includes(docType)) {
        modal = this.openExpiryModal({
          showPass: isEncrypted,
          showExpiryDate: true,
          document: doc.document
        });
      } else if (isEncrypted) {
        modal = this.openExpiryModal({
          showPass: true,
          showExpiryDate: false,
          document: doc.document
        });
      }
      if (modal) {
        const res = await modal.afterClosedPromise();
        if (!res.confirmed) {
          return;
        }
        doc['expiresAt'] = res['expiryDate'];
        doc['password'] = res['password'];
      }

      this.spinner.showSpinner();
      this.http
        .post(`${this.environment.new_api_url}/api/loan-application/${this.loan?._id}/file`, { ...doc })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(`Document was not added`, 'danger');
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.spinner.hideSpinner();
          this.router.navigateByUrl(this.router.url);
          this.alertService.showAlert(`Document was added successfully`, 'success');
        });
    };
  }
  afterLoad(e?: any) {
    console.log(e);
  }
  checkStatus(type: string) {
    if (type === 'approved') return 'doc-status-approved';
    if (type === 'declined') return 'doc-status-declined';
    if (type === 'awaiting') return 'doc-status-awaiting';
    else return 'doc-status-awaiting';
  }
  changeStatus(document: any, status: string) {
    const id = document._id;
    console.log(document);
    this.spinner.showSpinner();
    this.http
      .put(`${this.environment.new_api_url}/api/file/${id}/status`, {
        status: 'approved'
      })
      .pipe(
        catchError((err: any) => {
          this.spinner.hideSpinner();
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.sendDocumentNotification(document);
        this.spinner.hideSpinner();
        this.router.navigateByUrl(this.router.url);
      });
  }

  // handStatus(id: string, status: string, fileName: string) {
  //   this.item = {
  //     id: id,
  //     status: status,
  //     fileName: fileName
  //   };
  // }

  // removeDoc(id: string, fileName: string) {
  //   this.item = {
  //     id: id,
  //     fileName: fileName
  //   };
  //   this.openModal.emit(this.item);
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  openFile(file: any) {
    this.spinner.showSpinner();
    this.http
      .post(`https://gc0an3fw5j.execute-api.us-east-1.amazonaws.com/prod`, {
        bucket: file?.bucket,
        file: file?.s3Path,
        region_name: file?.bucket === 'agt-new-files-local-dev-staging' ? 'us-east-1' : 'us-east-2'
      })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Something went wrong`, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        window.open(data['url']);
      });
  }
  openDecModal(item: any) {
    this.showDecModal = true;
    this.currentDoc = item;
    setTimeout(() => {
      $(document.querySelector('#declineModalTD')).css({ display: 'flex' });
    });
    this.item = item;
  }
  closeModal() {
    $(document.querySelector('#declineModalTD')).css({ display: 'none' });
    this.showDecModal = false;
  }
  openExpiryModal(opts: { showPass?: boolean; showExpiryDate?: boolean; document: UploadFileDTO; expiryDateNotMandatory?: boolean }) {
    return this.modalService.open(ExpiryDateModal, {
      ...opts
    });
  }
  checkPDFPassword(fileData: Uint8Array, password: string): Promise<boolean> {
    return new Promise((res) => {
      pdfjsLib
        .getDocument({ data: fileData, password })
        .promise.then((pdf) => {
          pdf
            .getMetadata()
            .then((metadata) => {
              console.log(metadata);
              if (metadata?.info?.['Encrypted'] || metadata?.metadata?.['encrypted']) {
                console.log('invalid pass');
              } else {
                console.log('valid pass');
                // this.pdfSrc = URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }));
              }
              res(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (err.message === 'Incorrect Password') {
            res(false);
          }
        });
    });
  }
  sendDocumentNotification(document: any) {
    const id = this.loan?.createdBy?._id;
    this.http
      .post(`${this.environment.new_api_url}/api/notification/android`, {
        identity: [id],
        title: 'Loan Document Status Update',
        body: `Good news! Your uploaded document - ${this.documentOptionsMap[document?.fileType || document?.type]} - has been approved.`,
        silent: false,
        actions: [
          {
            action: 'explore',
            title: 'Go to the site',
            url: 'https://example.com'
          }
        ],
        data: {
          destination: 'loan'
        },
        miniApp: 'loans'
      })
      .subscribe((data) => {
        console.log(data);
      });
  }
}
