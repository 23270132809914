import { AlertService } from '@avenews/alerts';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { User, Agt4Period, RepaymentMode } from '@avenews/agt-sdk';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseFormTemplateComponent } from 'libs/shared/src/lib/components/base-form-template/base-form-template.component';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SpinnerToggleService, MinControlValidator, TwoFaService } from '@avenews/shared-lib';
import * as $ from 'jquery';
import * as libphonenumber from 'google-libphonenumber';
import { EMPTY } from 'rxjs';

interface PeriodData {
  duration: number;
  maxDisbursement: number;
  minDisbursement: number;
  repaymentMode?: string;
}

@Component({
  selector: 'frontend-new-period',
  templateUrl: './new-period.component.html',
  styleUrls: ['./new-period.component.scss']
})
export class NewPeriodComponent extends BaseFormTemplateComponent implements OnChanges {
  @Input() deal: any;
  @Input() isEdit: boolean;
  @Input() period: Agt4Period;
  @Input() isOpen: boolean;
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<boolean>();
  beneficiaryAccountId: string;
  beneficiaryId: string;
  user: User;
  exceedMaxAmount: boolean;
  today = new Date();
  environment = environment;
  periodForm: FormGroup;
  exceedAmount: string;
  repaymentType = [
    { viewValue: 'Single', value: 'single' },
    { viewValue: 'Multi (regular)', value: RepaymentMode.MULTI_FIXED }
  ];
  repayments = [
    {
      duration: 7,
      percentage: 50
    },
    {
      duration: 20,
      percentage: 50
    }
  ];

  constructor(
    private fb: FormBuilder,
    private alerts: AlertService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private twoFaService: TwoFaService,
    public spinner: SpinnerToggleService
  ) {
    super();
    this.route.data.subscribe((data) => {
      this.user = data['user'];
    });

    this.periodForm = this.fb.group({
      duration: [undefined, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]],
      minDisbursement: [100000, [Validators.required, Validators.min(1)]],
      maxDisbursement: [undefined, [Validators.required, Validators.min(1), MinControlValidator('minDisbursement', true)]],
      feeMultiplier: [
        { value: this?.deal?.repaymentInfo?.repaymentAmount / this?.deal?.maximumFinancingAmount, disabled: true },
        Validators.required
      ],
      repaymentType: [undefined],
      maxRepaymentAmount: this.fb.control(undefined)
    });
    this.periodForm.get('minDisbursement').valueChanges.subscribe(() => {
      this.periodForm.get('maxDisbursement').updateValueAndValidity();
    });

    this.periodForm.get('maxDisbursement').valueChanges.subscribe(() => {
      if (
        this.deal &&
        this.deal.repaymentInfo.repaymentMode === RepaymentMode.MULTI_FIXED &&
        this.periodForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED
      ) {
        this.updateRepaymentsAmounts();
      }
    });

    this.periodForm.get('repaymentType')?.valueChanges.subscribe(() => {
      if (
        this.deal &&
        this.deal.repaymentInfo.repaymentMode === RepaymentMode.MULTI_FIXED &&
        this.periodForm.get('repaymentType').value === RepaymentMode.MULTI_FIXED
      ) {
        this.updateRepaymentsArray();
      }
    });
    this.periodForm.get('maxDisbursement').updateValueAndValidity();

    this.formInput = this.periodForm;
    this.errorMessages = {
      minDisbursement: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'max', message: 'Can’t be bigger than the Max amount' }
      ],
      maxDisbursement: [
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'minAmount', message: 'Can’t be smaller than the Min amount' },
        { type: 'max', message: 'Can’t exceed the deal Max financing amount' }
      ],
      duration: [
        { type: 'pattern', message: 'Invalid amount' },
        { type: 'required', message: 'This field is required' },
        { type: 'min', message: 'Invalid amount' },
        { type: 'max', message: 'Invalid amount' }
      ]
    };
  }

  updateRepaymentsArray() {
    this.multipleRepaymentsArray.controls.forEach((repayment: any) => {
      repayment
        .get('amount')
        .setValue((repayment.value.percentage * this.periodForm.get('maxRepaymentAmount').value) / 100, { emitEvent: false });
    });
  }

  updateRepaymentsAmounts() {
    this.multipleRepaymentsArray.controls.forEach((repayment: any) => {
      repayment
        .get('amount')
        .setValue((repayment.value.percentage * this.periodForm.get('maxRepaymentAmount').value) / 100, { emitEvent: false });
    });
  }

  ngOnChanges(): void {
    if (this.deal && this.deal.repaymentInfo.repaymentMode === RepaymentMode.MULTI_FIXED) {
      // this.periodForm.addControl('maxRepaymentAmount', this.fb.control(undefined, [Validators.required]));
      this.periodForm.addControl('multipleRepayments', this.fb.array([]));
      this.periodForm.get('repaymentType').setValidators([Validators.required]);
      this.periodForm.get('maxRepaymentAmount').setValidators([Validators.required]);
    }
    if (this.deal && !this.period) {
      if (!this.exceedAmount) {
        this.periodForm
          .get('maxDisbursement')
          .setValidators([
            Validators.required,
            Validators.min(1),
            MinControlValidator('minDisbursement', true),
            Validators.max(this?.deal?.maximumFinancingAmount)
          ]);
      }
      if (this.deal.repaymentInfo?.repaymentMode === RepaymentMode.MULTI_FIXED) {
        if (this.multipleRepaymentsArray.length) {
          this.multipleRepaymentsArray.clear();
        }
        this.deal.repaymentInfo?.details?.forEach((repayment: any) => {
          this.multipleRepaymentsArray.push(
            this.fb.group({
              daysFromStart: [repayment.duration, Validators.required],
              amount: [(repayment.percentage * this.periodForm.get('maxDisbursement').value) / 100, Validators.required],
              percentage: [repayment.percentage, Validators.required]
            })
          );
        });
        this.periodForm.get('duration').setValidators([Validators.max(this.deal.maxDuration)]);
      }
      if (this.deal.repaymentInfo?.repaymentMode === RepaymentMode.MULTI_FIXED) {
        this.periodForm.patchValue({
          maxRepaymentAmount: this.deal.repaymentInfo.repaymentAmount,
          maxDisbursement: this?.deal?.maximumFinancingAmount,
          feeMultiplier: (this?.deal?.repaymentInfo?.repaymentAmount / this?.deal?.maximumFinancingAmount).toFixed(2),
          duration: this.deal.maxDuration
        });
      }
      if (this.deal.repaymentInfo?.repaymentMode === 'single') {
        if (this.deal.repaymentInfo?.markupMultiplier) {
          this.periodForm.patchValue({
            duration: this.deal.maxDuration,
            feeMultiplier: (this?.deal?.repaymentInfo?.repaymentAmount / this?.deal?.maximumFinancingAmount).toFixed(2)
          });
        } else {
          this.periodForm.patchValue({
            duration: this.deal.maxDuration,
            feeMultiplier: (this?.deal?.repaymentInfo?.repaymentAmount / this?.deal?.maximumFinancingAmount).toFixed(2)
          });
        }
      } else if (this.deal.repaymentInfo?.repaymentMode === 'multi') {
        if (this.deal.repaymentInfo?.maxInstallmentsAllowed === 2) {
          this.periodForm.patchValue({
            duration: this.deal.maxDuration,
            feeMultiplier: this.deal.repaymentInfo?.ratePerWeek * 4
          });
        } else if (this.deal.repaymentInfo?.maxInstallmentsAllowed === 3) {
          this.periodForm.patchValue({
            duration: this.deal.maxDuration,
            feeMultiplier: this.deal.repaymentInfo?.ratePerWeek * 6
          });
        }
      }
    }
    if (this.period) {
      this.periodForm.patchValue({
        duration: this.period.duration,
        minDisbursement: this.period?.minDisbursement,
        maxDisbursement: this.period?.maxDisbursement
      });
    }
  }
  get multipleRepaymentsArray() {
    return this.periodForm.get('multipleRepayments') as FormArray;
  }
  open() {
    $('#addPeriodWizard').css({ display: 'flex' });
  }
  close() {
    $('#addPeriodWizard').fadeOut('fast', () => {
      this.isOpen = false;
      this.isOpenModal.emit(this.isOpen);
      this.deleteData();
    });
  }
  deleteData() {
    this.periodForm.reset();
    this.periodForm.get('minDisbursement').setValue(100000);
    this.periodForm.get('duration').setValue(undefined);
    this.periodForm.get('repaymentType').setValue(undefined);
    this.periodForm.get('repaymentType').clearValidators();
    this.periodForm.get('maxRepaymentAmount').setValue(undefined);
    this.periodForm.get('maxRepaymentAmount').clearValidators();
    for (let i = 0; i < this.multipleRepaymentsArray.length; i++) {
      this.multipleRepaymentsArray.removeAt(0);
    }
    this.periodForm.markAsUntouched();
  }
  async submit() {
    this.validateForm(this.periodForm);
    if (this.periodForm.valid) {
      if (await this.twoFaService.open()) {
        this.spinner.showSpinner();
        const catcher = (err: any) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err.error.message, 'danger');
          this.close();
          return EMPTY.pipe();
        };
        const sub = (data: any) => {
          this.alerts.showAlert(`Period ${this.isEdit ? 'updated' : 'created'} successfully`, 'success');
          this.spinner.hideSpinner();
          this.close();
          this.router.navigateByUrl(this.router.url);
        };
        const data: PeriodData = {
          duration: this.periodForm.get('duration').value,
          maxDisbursement: this.periodForm.get('maxDisbursement').value,
          minDisbursement: this.periodForm.get('minDisbursement').value
        };
        if (this.deal.repaymentInfo?.repaymentMode === RepaymentMode.MULTI_FIXED) {
          data.repaymentMode = this.periodForm.get('repaymentType').value;
        }
        if (this.deal.repaymentInfo?.repaymentMode !== RepaymentMode.MULTI_FIXED) {
          data.repaymentMode = this.deal.repaymentInfo.repaymentMode;
        }
        const putOrPost = this.isEdit ? 'put' : 'post';
        const requestUrl = this.isEdit
          ? `${this.environment?.new_api_url}/api/deal/${this.period?.deal?._id}/period/${this.period?._id}`
          : `${this.environment?.new_api_url}/api/deal/${this.deal?._id}/period`;
        this.http[putOrPost](requestUrl, data)
          .pipe(
            catchError((err: any) => {
              this.spinner.hideSpinner();
              if (err.error.message.includes('INVALID_MAX_DISBURSEMENT')) {
                const str = err.error.message;
                const regex = /max available: (\d+)/;
                const result = str.match(regex)[1];
                this.exceedMaxAmount = true;
                this.exceedAmount = result;
                const hasMaxDisbursementError = this.errorMessages['maxDisbursement'].some((error) => {
                  return error.type === 'invalid';
                });
                if (!hasMaxDisbursementError) {
                  this.errorMessages['maxDisbursement'].push({ type: 'invalid', message: `Can’t exceed ${this.exceedAmount}` });
                }
                this.periodForm.get('maxDisbursement').setErrors({ invalid: true });
              } else this.exceedMaxAmount = false;
              return EMPTY;
            })
          )
          .subscribe(sub);
      }
    }
  }
}
