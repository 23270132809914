<div [formGroup]="applicationForm" class="main-data-wrapper">
  <div class="first-wrapper">
    <div class="main-data-section">
      <p class="section-header first-header">Application summary</p>
      <div class="main-section">
        <div class="info-sec">
          <div class="form col-md-6">
            <label>Deal margin</label>
            <input formControlName="dealMargin" type="number"  />
          </div>
          <div class="form col-md-6">
            <label>Deal ROI</label>
            <input formControlName="dealROI" type="number"  />
          </div>
        </div>
        <div class="form-row">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Agricheck"
            inputType="radio"
            [radios]="agricheckRadio"
            [isColumn]="true"
            inputName="radio"
            controlName="agriCheck"
            [size]="12"
            [group]="applicationForm"
          ></app-base-form-template>
        </div>
      </div>
    </div>

    <div class="main-data-section">
      <p class="section-header">Deal calculation</p>
      <div class="information">
        <div class="form col-md-3">
          <label>Period ROI </label>
          <input [value]="application?.deal?.periodRoi" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>Min period ROI</label>
          <input [value]="application?.deal?.minRoi" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>Optimal period ROI</label>
          <input [value]="application?.deal?.optimalRoi" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>Period weeks</label>
          <input [value]="application?.deal?.periodWeeks" type="number" readonly />
        </div>
      </div>
      <div class="information">
        <div class="form col-md-3">
          <label>Annualized ROI </label>
          <input [value]="application?.deal?.annualizedRoi" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>Total buying</label>
          <div class="form-append">
            <input [value]="application?.deal?.totalBuying" readonly class="supplier-input form-group col-md-12" type="number" />
            <div class="appended">KES</div>
          </div>
        </div>
        <div class="form col-md-3">
          <label>Total selling</label>
          <div class="form-append">
            <input [value]="application?.deal?.totalSelling" readonly class="supplier-input form-group col-md-12" type="number" />
            <div class="appended">KES</div>
          </div>
        </div>
      </div>
      <div class="information divider">
        <div class="form col-md-3">
          <label>Min ROI check </label>
          <input [value]="application?.deal?.minRoiCheck ? 'True' : 'False'" type="text" readonly />
        </div>
        <div class="form col-md-3">
          <label>Optimal ROI check</label>
          <input [value]="application?.deal?.optimalRoiCheck ? 'True' : 'False'" type="text" readonly />
        </div>
      </div>
      <div class="information divider">
        <div class="form col-md-3">
          <label>Min offer </label>
          <div class="form-append">
            <input [value]="application?.deal?.minOffer" readonly class="supplier-input form-group col-md-12" type="text" />
            <div class="appended">KES</div>
          </div>
        </div>
        <div class="form col-md-3">
          <label>Optimal offer</label>
          <div class="form-append">
            <input [value]="application?.deal?.optimalOffer" readonly class="supplier-input form-group col-md-12" type="text" />
            <div class="appended">KES</div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-data-section">
      <p class="section-header">M-Pesa statement</p>
      <div class="information">
        <div class="form hidden col-md-3"></div>
        <div class="form col-md-3">
          <label>AVG Overdraft</label>
          <input [value]="calcAvg(getMpesaArray(), 'overdraft')" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>AVG Bet</label>
          <input [value]="calcAvg(getMpesaArray(), 'bet')" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>AVG Loan</label>
          <input [value]="calcAvg(getMpesaArray(), 'loan')" type="number" readonly />
        </div>
      </div>
      <div class="mobile-statement" formArrayName="mpesaStatement">
        <div
          [class.border-top]="i >= 1"
          [formGroup]="$any(doc)"
          *ngFor="let doc of getMpesaArray().controls; let i = index"
          class="documents-info"
        >
          <div class="form col-md-3">
            <input formControlName="name" class="input" placeholder="Document name" type="text" />
          </div>
          <div class="form col-md-3">
            <input formControlName="overdraft" class="input" id="input" type="number" />
          </div>
          <div class="form col-md-3">
            <input formControlName="bet" class="input" id="input" type="number" />
          </div>
          <div class="form col-md-3">
            <input formControlName="loan" class="input" id="input" type="number" />
          </div>
          <div class="delete">
            <i-feather (click)="deleteRow(getMpesaArray(), i)" name="trash-2"></i-feather>
          </div>
        </div>
        <p class="add-another" (click)="addMpesa()">Add another +</p>
      </div>
    </div>
    <div class="main-data-section">
      <p class="section-header">Bank statement</p>
      <div class="information">
        <div class="form hidden col-md-3"></div>
        <div class="form col-md-3">
          <label>AVG Overdraft</label>
          <input [value]="calcAvg(getBankArray(), 'overdraft')" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>AVG Bet</label>
          <input [value]="calcAvg(getBankArray(), 'bet')" type="number" readonly />
        </div>
        <div class="form col-md-3">
          <label>AVG Loan</label>
          <input [value]="calcAvg(getBankArray(), 'loan')" type="number" readonly />
        </div>
      </div>
      <div class="mobile-statement" formArrayName="bankStatement">
        <div
          [class.border-top]="i >= 1"
          [formGroup]="$any(doc)"
          *ngFor="let doc of getBankArray().controls; let i = index"
          class="documents-info"
        >
          <div class="form col-md-3">
            <input formControlName="name" class="input" placeholder="Document name" type="text" />
          </div>
          <div class="form col-md-3">
            <input formControlName="overdraft" class="input" id="input" type="number" />
          </div>
          <div class="form col-md-3">
            <input formControlName="bet" class="input" id="input" type="number" />
          </div>
          <div class="form col-md-3">
            <input formControlName="loan" class="input" id="input" type="number" />
          </div>
          <div class="delete">
            <i-feather (click)="deleteRow(getBankArray(), i)" name="trash-2"></i-feather>
          </div>
        </div>
        <p class="add-another" (click)="addBank()">Add another +</p>
      </div>
    </div>
    <div class="main-data-section">
      <p class="section-header">LPO volume check</p>
      <div class="average-check">
        <div class="form col-md-4">
          <label>Max</label>
          <input [value]="calcAction('max')" type="text" readonly />
        </div>
        <div class="form col-md-4">
          <label>Min</label>
          <input [value]="calcAction('min')" type="text" readonly />
        </div>
        <div class="form col-md-4">
          <label>Average</label>
          <input [value]="calcAction('avg')" type="text" readonly />
        </div>
      </div>
      <ng-container formArrayName="lpoVolume">
        <div class="average-check dates">
          <div [class.border-top]="i >= 1" class="form-rows" [formGroup]="$any(lpo)" *ngFor="let lpo of lpoArray.controls; let i = index">
            <app-base-form-template
              labelName="Year"
              bindLabel="viewValue"
              bindValue="value"
              inputType="select"
              inputName="select"
              controlName="year"
              placeholderName="Select a year"
              [options]="years"
              [group]="$any(lpo)"
              [size]="4"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Month"
              bindLabel="viewValue"
              bindValue="value"
              inputType="select"
              inputName="select"
              controlName="month"
              placeholderName="Select a month"
              [options]="months"
              [group]="$any(lpo)"
              [size]="4"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Amount"
              inputType="number"
              inputName="default"
              controlName="amount"
              [group]="$any(lpo)"
              [size]="4"
            >
              <span #append append>KES</span>
            </app-base-form-template>
            <div class="delete lpo-delete">
              <i-feather (click)="deleteRow(lpoArray, i)" name="trash-2"></i-feather>
            </div>
          </div>
          <p class="add-another" (click)="addLpo()">Add another +</p>
        </div>
      </ng-container>
    </div>
    <div class="main-data-section no-border">
      <p class="section-header">Beurre check</p>
      <button (click)="fileUpload.click()" class="upload">Upload</button>
      <input (change)="uploadDoc($event)" #fileUpload hidden type="file" />
      <div class="file-holder">
        <a
          class="file-details"
          (click)="openFile(applicationForm.get('beurreCheck').value)"
          *ngIf="applicationForm.get('beurreCheck').value"
        >
          {{ applicationForm.get('beurreCheck').value?.fileName }}
        </a>
      </div>
    </div>
  </div>
  <div class="needed-document">
    <div class="top-select-doc-wrapper">
      <div>
        <p>Documents needed</p>
      </div>
      <div class="choose-one-wrap">
        <p class="client">Client submitted</p>
        <p>Avenews approval</p>
      </div>
    </div>
    <div *ngFor="let item of neededDocument; let i = index" class="main-need-docs-wrapper">
      <div [formArrayName]="item?.controlName" class="needed-doc">
        <div>
          <p class="doc-type">{{ item.label }}</p>
        </div>
        <div class="checkes-wrapper">
          <div class="checkboxes-wrapper sec-option">
            <input #checker class="form_radio" [value]="item?.value" type="checkbox" [formControlName]="'0'" [id]="item?.id + i" />

            <label [for]="item?.id + i" class="form_radio_label d-inline-flex">
              <span class="form_checkbox_btn"></span>
            </label>
          </div>

          <div class="checkboxes-wrapper">
            <input #checker class="form_radio" [value]="item?.value" type="checkbox" [formControlName]="'1'" [id]="item?.id + i + 1" />

            <label [for]="item?.id + i + 1" class="form_radio_label d-inline-flex">
              <span class="form_checkbox_btn"></span>
            </label>
            <p (click)="checker.click()" class="d-inline-flex click"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end pt-5">
    <button (click)="save()" class="save">Update</button>
  </div>
</div>
