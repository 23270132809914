import { AbstractControl, FormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showAsterisk'
})
export class ShowAsteriskPipe implements PipeTransform {
  constructor() {}
  transform(formInput: FormGroup, name: string) {
    return this.showAsterisk(formInput, name);
  }
  isRequired(formInput: FormGroup, name: string) {
    if (formInput) {
      const validator = formInput.get(name) && formInput.get(name).validator && formInput.get(name).validator({} as AbstractControl);
      return validator && validator['required'] ? true : false;
    }
    return false;
  }

  showAsterisk(formInput: FormGroup, name: string) {
    if (this.isRequired(formInput, name)) {
      return '*';
    }
    return '';
  }
}
