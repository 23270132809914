<div>
  <div id="reset-modal" class="modal">
    <div class="modal-content">
      <div class="modal-box-heading d-flex justify-content-between">
        <h2>Change password</h2>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <div class="modal-body">
        <form [formGroup]="resetPasswordForm">
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Old password"
            [inputType]="showPass ? 'text' : 'password'"
            inputName="default"
            [size]="12"
            controlName="oldPassword"
            [group]="resetPasswordForm"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Password"
            [inputType]="showPass ? 'text' : 'password'"
            inputName="default"
            [size]="12"
            controlName="newPassword"
            [group]="resetPasswordForm"
            [errorMessages]="errorMessages"
          >
          </app-base-form-template>
          <div class="confirm-btns" *ngFor="let item of validations">
            <div>
              <img src="{{ item.wordBoolean ? 'assets/shared/svgs/check-mark.svg' : 'assets/shared/svgs/cross.svg' }}" />
              <span>{{ item.text }}</span>
            </div>
          </div>
          <app-base-form-template
            labelName="Confirm password"
            [inputType]="showPass ? 'text' : 'password'"
            inputName="default"
            [size]="12"
            controlName="confirmPassword"
            [group]="resetPasswordForm"
          >
          </app-base-form-template>
          <div class="pass-wrap">
            <av-checkbox (click)="showPassword()"></av-checkbox>
            <p class="show-pass">Show passwords</p>
          </div>
          <div class="help-block" *ngIf="passwordsNotMatch()">
            <p>Passwords doesn't match</p>
          </div>
        </form>
        <button class="confirm-btn" (click)="confirmChanges()">Confirm</button>
      </div>
    </div>
  </div>
</div>
