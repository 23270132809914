import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilesService, SpinnerToggleService } from '../../services';
let nextUniqueId = 0;

@Component({
  selector: 'av-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModal {
  private uniqueId = `factor-modal-${++nextUniqueId}`;
  passForm: FormGroup;
  errorMessages = {
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'wrongPass', message: 'Wrong password' }
    ]
  };
  @Input() isOpen: boolean;
  @Input() document: any;
  @Input() customClass = this.uniqueId;
  @Output() passwordValue = new EventEmitter<string>();
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: any;
  constructor(private fb: FormBuilder, private filesService: FilesService, private spinner: SpinnerToggleService) {
    this.passForm = this.fb.group({
      password: [undefined, Validators.required]
    });
  }
  close(userClosed?: boolean) {
    this.isOpen = false;
    this.passForm.markAsUntouched();
    this.passForm.reset();
    if (userClosed) {
      this.passwordValue.emit(null);
    }
    this.isOpenModal.emit(this.isOpen);
  }
  async continue() {
    this.passForm.markAllAsTouched();
    if (this.passForm.valid) {
      this.spinner.showSpinner();
      const passValid = await this.filesService.checkPDFPassword(this.document.file, this.passForm.value.password);
      this.spinner.hideSpinner();
      if (passValid) {
        this.passwordValue.emit(this.passForm.value.password);
        this.close();
      } else {
        this.passForm.get('password').setErrors({ wrongPass: true });
      }
    }
  }
}
