import { AgribusinessViewComponent } from './agribusiness-view/agribusiness-view.component';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgribusinessesComponent } from './agribusinesses.component';
import { AgribusinessesResolver } from './resolvers/agribusiness.resolver';
import { UsersResolver } from '../shared/resolvers/users.resolver';
import { AgribusinessByIdResolver } from './resolvers/agribusiness-by-id.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'agribusinesses',
      component: AgribusinessesComponent,
      runGuardsAndResolvers: 'always',
      resolve: { agribusinesses: AgribusinessesResolver, users: UsersResolver },
      data: { pageSize: 10 }
    },
    {
      path: 'agribusinesses/:id',
      component: AgribusinessViewComponent,
      runGuardsAndResolvers: 'always',
      resolve: { agribusiness: AgribusinessByIdResolver }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgribusinessesRoutingModule {}
