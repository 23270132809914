<div class="main-container">
<h1>Please ignore this page</h1>
  <!-- <div class="col-xl-12 col-lg-12 col-sm-12 get-started">
    <div class="cards-wrapper">
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card [chart]="true" iconType="pay" title="Transactions Stats" subtitle="Transactions" route="/pay/transactions">
          <ngx-charts-pie-chart [view]="view" [results]="pieChartData" [legend]="false" [labels]="true" [doughnut]="true">
          </ngx-charts-pie-chart>
        </admin-dashboard-card>
      </div>
    </div>

    <div class="cards-wrapper">
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.transactions"
          iconType="pay"
          title="All transactions"
          subtitle="Transactions"
          route="/pay/transactions"
          [emptyState]="transactionsCardEmptyText"
        ></admin-dashboard-card>
      </div>
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.users"
          iconType="farmers"
          title="Users"
          route="/users"
          subtitle="Users"
          [emptyState]="usersCardEmptyText"
        ></admin-dashboard-card>
      </div>
    </div>
    <div class="cards-wrapper">
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.internalTransactions"
          iconType="pay"
          title="Internal transactions"
          subtitle="Transactions"
          route="/pay/transactions"
          [emptyState]="transactionsCardEmptyText"
        ></admin-dashboard-card>
      </div>
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.topupTransactions"
          iconType="pay"
          title="Topups"
          subtitle="Transactions"
          route="/pay/transactions"
          [emptyState]="transactionsCardEmptyText"
        ></admin-dashboard-card>
      </div>
    </div>
    <div class="cards-wrapper">
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.mobileTransactions"
          iconType="pay"
          title="Mobile Transactions"
          subtitle="Transactions"
          route="/pay/transactions"
          [emptyState]="transactionsCardEmptyText"
        ></admin-dashboard-card>
      </div>
      <div class="dash-card col-lg-6 col-md-12 col-sm-12">
        <admin-dashboard-card
          [number]="counters?.transactions - counters?.mobileTransactions - counters?.topupTransactions"
          iconType="pay"
          title="Bank Transactions"
          subtitle="Transactions"
          route="/pay/transactions"
          [emptyState]="transactionsCardEmptyText"
        ></admin-dashboard-card>
      </div>
    </div>
  </div> -->
</div>
