<div class="modal-box" id="loanDetailsModal">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Please fill up the following details about the agribusiness</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div [formGroup]="detailsForm" class="modal-box-content">
      <div class="d-flex">
        <app-base-form-template
          controlName="clientID"
          [group]="detailsForm"
          inputName="default"
          inputType="text"
          labelName="National Id"
          [size]="12"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair">
        <app-base-form-template
          controlName="has_bank"
          [group]="detailsForm"
          inputName="radio"
          inputType="radio"
          [radios]="yesNo"
          labelName="Does the agribusiness have a bank account?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
        <app-base-form-template
          controlName="has_mpesa"
          [group]="detailsForm"
          inputName="radio"
          inputType="radio"
          [radios]="yesNo"
          labelName="Does the agribusiness have an mpesa?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair">
        <app-base-form-template
          controlName="has_trade_docs"
          [group]="detailsForm"
          inputName="radio"
          inputType="radio"
          [radios]="yesNo"
          labelName="Does the agribusiness have trade docs?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
        <app-base-form-template
          controlName="has_handwritten_trade_docs"
          [group]="detailsForm"
          inputName="radio"
          inputType="radio"
          [radios]="yesNo"
          labelName="Does the agribusiness have hand written trade docs?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair">
        <app-base-form-template
          controlName="loan_amount"
          [group]="detailsForm"
          inputName="default"
          inputType="number"
          labelName="What is the loan amount?"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          controlName="gender"
          [group]="detailsForm"
          inputName="radio"
          inputType="radio"
          [radios]="gender"
          labelName="What is the agribusiness gender?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair">
        <app-base-form-template
          controlName="financing_type"
          bindValue="value"
          bindLabel="viewValue"
          [group]="detailsForm"
          inputName="select"
          inputType="select"
          [options]="financingTypes"
          labelName="What is the financing type?"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          controlName="businessType"
          [group]="detailsForm"
          inputName="new-checkbox"
          inputType="new-checkbox"
          [checkboxes]="businessTypes"
          labelName="What is the type of business?"
          [size]="6"
          [isColumn]="true"
        >
        </app-base-form-template>
      </div>
      <div class="form-pair">
        <app-base-form-template
          controlName="has_facility"
          [group]="detailsForm"
          [radios]="yesNo"
          inputName="radio"
          inputType="radio"
          [options]="financingTypes"
          labelName="Does the agribusiness have a facility?"
          [size]="6"
        >
        </app-base-form-template>
        <app-base-form-template
          [sideText]="'in years'"
          controlName="seniority_in_business"
          [group]="detailsForm"
          inputName="default"
          inputType="number"
          labelName="Seniority in business"
          [size]="6"
        >
        </app-base-form-template>
      </div>
      <div class="d-flex">
        <app-base-form-template
          controlName="education_level"
          bindValue="value"
          bindLabel="label"
          [group]="detailsForm"
          inputName="select"
          inputType="select"
          [options]="educationLevels"
          labelName="What is the agribusiness educational level?"
          [size]="6"
        >
        </app-base-form-template>
      </div>

      <div class="modal-footer">
        <button (click)="autofill()" class="bttn btn-actions">Autofill</button>
        <button class="btn btn-primary" (click)="submit()">{{ false ? 'Update' : 'Add' }}</button>
      </div>
    </div>
  </div>
</div>
