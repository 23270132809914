import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Agt4Deal, Agt4DistributorStatus, DealStatus } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { AuthenticationService } from '@avenews/front-auth';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { EMPTY, catchError } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'change-distributor-status',
  templateUrl: './change-distributor-status.component.html',
  styleUrls: ['./change-distributor-status.component.scss']
})
export class ChangeDistributorStatComponent extends BaseValidationComponent implements OnInit, OnChanges {
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<boolean>();
  @Input() distributor: any;

  statusForm: FormGroup;
  statusSelect = [
    {
      value: Agt4DistributorStatus.NEW,
      viewValue: 'New'
    },
    {
      value: Agt4DistributorStatus.APPROVED,
      viewValue: 'Approve'
    },
    {
      value: Agt4DistributorStatus.DECLINED,
      viewValue: 'Decline'
    },
    {
      value: Agt4DistributorStatus.ON_HOLD,
      viewValue: 'On hold'
    }
  ];
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private authService: AuthenticationService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.statusForm = fb.group({
      status: [undefined, Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  open() {
    $('#changeDistributorStatusWizard').css({ display: 'flex' });
  }
  changeStatus() {
    this.validateForm(this.statusForm);
    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/distributor/user/${this.distributor?._id}/status`, {
          status: this.statusForm.value.status
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            this.close();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: Agt4Deal) => {
          this.alertService.showAlert(`Status Changed to  ${this.statusForm.value.status} successfuly`, 'success');
          this.close();
          this.deleteData();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  deleteData() {
    this.statusForm.reset();
  }
  close() {
    $('#changeDistributorStatusWizard').fadeOut('fast', () => {
      this.deleteData();
    });
  }
}
