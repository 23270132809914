import { animate } from '@angular/animations';
import { Component, ContentChildren, QueryList, AfterContentInit, Input } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
@Component({
  selector: 'av-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input() position: 'start' | 'end' | 'center';
  @Input() size: 'defualt' | 'stretched';
  @Input() index: number;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: any) {
    this.tabs.toArray().forEach((tab, index) => {
      if (tab.active) {
        this.index = index;
      }

      tab.active = false;
    });
    tab.active = true;
    this.tabs.toArray().forEach((tab, index) => {
      if (tab.active) {
        if (index !== this.index) {
          if (index > this.index) {
            tab.counter = -1;
          } else {
            tab.counter = 1;
          }
        }
        this.index = index;
      }
    });
    this.tabs.toArray().forEach((tab, index) => {
      if (index < this.index) {
        tab.counter = -2;
      } else if (index > this.index) {
        tab.counter = 2;
      }
    });
  }
  onClick() {}
}
