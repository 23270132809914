import { BaseAgribusiness } from '@avenews/agt-sdk';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-performance-history',
  templateUrl: './performance-history.component.html',
  styleUrls: ['./performance-history.component.scss']
})
export class PerformanceHistoryComponent implements OnInit {
  @Input() agribusiness: any;

  constructor() {}

  ngOnInit(): void {}
  getFileNames(documents: any): string {
    const names = documents?.map((data) => data?.fileName);
    const types = documents?.map((data) => data?.type);
    const joined = names.map((name, index) => `${name}.${types[index]}`).join('\n');

    return joined.slice(joined.indexOf('\n'));
  }
}
