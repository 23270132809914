export const businessTypes = [
  {
    value: 'aggregator',
    label: 'Aggregator'
  },
  {
    value: 'inputs_provider',
    label: 'Inputs Provider'
  },
  {
    value: 'trader',
    label: 'Trader'
  },
  {
    value: 'processor',
    label: 'Processor'
  },
  {
    value: 'cooperative',
    label: 'Cooperative'
  },
  {
    value: 'other',
    label: 'Other'
  }
];
export const yesNo = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];
export const financingTypes = [
  {
    value: 'buying produce',
    viewValue: 'Buying Produce'
  },
  {
    value: 'buying input',
    viewValue: 'Buying Input'
  },
  {
    value: 'buying combo',
    viewValue: 'Buying Combo'
  }
];

export const gender = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  }
];
export const educationLevels = [
  {
    value: 'primary',
    label: 'Primary'
  },
  {
    value: 'secondary',
    label: 'Secondary'
  },
  {
    value: 'university graduate',
    label: 'University Graduate'
  },
  {
    value: 'university student',
    label: 'University Student'
  },
  {
    value: 'post graduate',
    label: 'Post Graduate'
  },
  {
    value: 'other',
    label: 'Other'
  }
];
export enum ApplicationStatus {
  NEW = 'new',
  DATA_ANALYSIS = 'data_analysis',
  RISK_ASSESSMENT = 'risk_assessment',
  APPROVED = 'approved',
  DECLINED = 'declined',
  PROCESSING = 'processing',
  WAITING_FOR_CLIENT = 'waitingForClient',
  AWAITING_APPROVAL = 'awaitingApproval',
  TENTATIVELY_APPROVED = 'tentativelyApproved',
  INTERNALLY_DECLINED = 'internallyDeclined',
  INTERNALLY_APPROVED = 'internallyApproved',
  CLIENT_DECLINED = 'clientDeclined',
  CLIENT_APPROVED = 'clientApproved',
  ON_HOLD = 'onHold',
  REFERRED_BACK = 'referredBack',
  DUPLICATED = 'duplicated',
  CHURNED = 'churned'
}
