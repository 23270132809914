<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Orders'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-orders-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'STATUS' && col?.name !== 'AMOUNT' && col?.name !== 'SUPPLIER NAME'"
      [ngTemplateOutlet]="td"
    ></ng-container>

    <ng-container *ngIf="col?.name === 'AMOUNT'">
      <td *ngIf="item?.total">{{ item?.total | number: '1.0-2' }}<span class="kes">KES</span></td>
      <td *ngIf="!item?.total">-</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'SUPPLIER NAME'">
      <td>{{ item?.createdBy?.personalInformation?.firstName + ' ' + item?.createdBy?.personalInformation?.lastName }}</td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td>
        <div>
          <p class="status status-{{ item?.status }}">
            {{ statusGrabber(item?.status) || '-' | titlecase }}
          </p>
        </div>
      </td></ng-container
    >
  </ng-template>
</agt-nosdk-list>

<frontend-change-order-status [order]="order"></frontend-change-order-status>
