<nav class="navbar navbar-primary navbar-transparent navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <a class="navbar-brand" href="https://www.avenews-gt.com"
        ><img src="../../../assets/img/logo-20cm-grey.png" width="168" height="34"
      /></a>
    </div>
    <div class="pull-right pt-3">
      <button class="bttn-transparent" routerLink="/login">Sign In</button>
      <button class="bttn-transparent" routerLink="/registration">Sign Up</button>
    </div>
  </div>
</nav>

<div class="container content-container">
  <p>
    <strong
      >Avenews-GT Privacy Policy <br />
      Last Revised: August 20, 2019</strong
    >
  </p>
  <p>
    Avenews-GT Inc. and its affiliates (<strong>“Avenews-GT", "Company" “we” or "us"</strong>) respects the privacy of the users of its
    website platform available at https://agt-platform.com/login and its mobile application (the <strong>"Site"</strong>,
    <strong>"App"</strong> and <strong>“you”</strong> or <strong>"User"</strong> respectively), and is committed to protect the personal
    information that you share with us. We believe that you have a right to know our practices regarding the information we may collect and
    use when you visit and/or use our Site and/or App. The Site and the App shall be referred to collectively as the
    <strong>"Platform"</strong>.
  </p>

  <p>
    Capitalized terms which are not defined herein, shall have the meaning ascribed to them in our Terms of Use at
    <a routerLink="/terms-of-use" target="_blank">www.agt-platform.com/terms-of-use</a>
    (<strong> "Terms of Use"</strong>), into which this Privacy Policy is incorporated thereto by reference. You hereby acknowledge that
    this Privacy Policy governs your use of the Platform. Additionally, the Company maintains a website serving mainly as an informative
    marketing tool, available at: https://www.avenews-gt.com/. This website is governed by its applicable Privacy Policy available at:
    https://www.avenews-gt.com/privacy-policy.
  </p>

  <ol style="list-style-position: inside">
    <li>
      <strong>Your Consent (PLEASE READ CAREFULLY!)</strong><br />
      <p>
        BY ENTERING, CONNECTING TO, ACCESSING OR USING THE PLATFORM, AND/OR BY INSTALLING THE APP ON YOUR MOBILE DEVICE, ENTERING INTO,
        CONNECTING TO, ACCESSING AND/OR USING THE APP OR ENTERING, CONNECTING TO, ACCESSING OR USING THE SITE, YOU AGREE TO THE TERMS AND
        CONDITIONS SET FORTH IN THIS PRIVACY POLICY (THE
        <strong>"PRIVACY POLICY"</strong>), INCLUDING TO THE COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION (AS DEFINED BELOW). IF
        YOU DO NOT AGREE TO ANY TERM PROVIDED HEREIN, PLEASE DO NOT ENTER TO, CONNECT TO, ACCESS OR USE THE PLATFORM IN ANY MANNER, DO NOT
        INSTALL THE APP, ACCESS AND/OR USE THE PLATFORM AND YOU ARE REQUESTED TO PROMPTLY UNINSTALL THE APP FROM YOUR MOBILE DEVICE.
      </p>
    </li>
    <li>
      <strong> Which information we may collect on our Users? </strong><br />
      We may collect two types of data and information from our Users:
      <ol type="a" style="padding-left: 2.5rem">
        <li>
          The first type of information is non-identifiable and anonymous information (“<strong>Non-personal Information</strong>”).
          <strong
            >We are not aware of the identity of the individual or entity from whom we have collected the Non-personal Information </strong
          >. Non-Personal Information is any unconcealed information which does not enable identification of an individual, and which is
          available to us while you enter and/or use the Platform and/or install the App. Non-personal Information which is being gathered
          consists of technical information, behavioral information and aggregated information, and may contain, among other things, your
          activity on the Platform, your type of operating system, type of mobile device and its operating system and version, your
          'click-stream' on the Platform, your type of browser and keyboard language, the hardware specifications of such device (including
          screen resolution), time spent on various screens of the Platform, demographics and biomechanical data, traffic patterns, etc.
        </li>
        <li>
          The second type of information is individually identifiable information (“<strong>Personal Information</strong>”).
          <strong> This information may identify an individual or may be of a private and/or sensitive nature</strong>.
          <ul style="padding-left: 2.5rem">
            <li>
              Users of the Platform may automatically provide their IP address (or Mac Address, as applicable) and/or advertiser identifier
              (when available on a device) mainly for enhancing the User’s experience and for geo-location and security purposes as further
              detailed below.
            </li>
            <li>
              Personal Information is collected from the details the Users provide in the registration form available on the Platform (which
              enables the Users to open an Account), which includes, inter alia, name, email, country and telephone number, identification
              number (ID), job title, company's registered name and number, address, a profile photo name, business name, email address and
              phone number. Additional information may be requested in the future. Personal Information is collected from the details you
              may provide us in respect to your service provider and customers, which includes their email address and telephone number
              (which enables us to open an Account on their behalf).
            </li>
            <li>
              Personal Information includes any data and/or content, including User Generated Content, that you may upload or generate
              through use of the Platform and the Service. Such information may include, for example, information related to orders,
              farmers, ecosystems, invoices, and products, farmer details and documents.
            </li>
          </ul>
          <p>
            For avoidance of doubt, any Non-Personal Information connected or linked to any Personal Information shall be deemed as Personal
            Information as long as such connection or linkage exists.
          </p>
          <p>
            <strong
              >We do not collect any Personal Information from you or related to you without your approval, which is obtained, inter alia,
              through your active acceptance to the Terms of Use and the Privacy Policy.</strong
            >
          </p>
        </li>
      </ol>
    </li>

    <li>
      <strong>How Do We Collect Information on Our Users?</strong><br />
      <p>There are two main methods we use:</p>
      <ol style="padding-left: 2.5rem" type="a">
        <li>
          <strong>We collect information through your entry, connection, installation, access and/or use of Platform.</strong>
          In other words, when you are using the Service we are aware of it and may gather, collect and store the information relating to
          such usage.
        </li>
        <li>
          <strong> We collect information which you provide us voluntarily.</strong>
          For example, we collect Personal Information when you register and open an Account, interact with other Users and upload User
          Generated Content.
        </li>
      </ol>
      <p>
        We may gather, collect and store such information either independently or through the help of our authorized third-party service
        providers as detailed below.
      </p>
    </li>
    <li>
      <strong>What are the Purposes of the Collection of Information?</strong><br />
      <p>We collect Non-personal Information and Personal Information in order to operate the Platform and provide the Service.</p>
      <p><u>Non-personal Information is collected in order to:</u></p>
      <ul>
        <li>Use it for statistical and research purposes and for customization and improvement of our Service.</li>
        <li>Provide and improve the Service for our commercial purposes.</li>
        <li>Improve the quality of the Service by formulating, customizing and enhancing the User's experience.</li>
      </ul>
      <p><u>Non-personal Information is collected in order to:</u></p>
      <ul style="margin-bottom: 1rem">
        <li>Use it for statistical and research purposes and for customization and improvement of our Service.</li>
        <li>Provide and improve the Service for our commercial purposes.</li>
        <li>Improve the quality of the Service by formulating, customizing and enhancing the User's experience.</li>
        <li>
          Allow us to contact Users with proposals and tailored information regarding new products, events, offers, services, features,
          enhancements, special offers, upgrade opportunities, and events of interest etc.
        </li>
        <li>
          Send push notifications to the User's device after the User has agreed to accept push notifications from Avenews-GT. Users can
          deactivate push notification by changing their settings within our Service.
        </li>
        <li>
          Verify the User's identity when he/she signs in to the Service, as well as verifying User's identity for the purpose of dealing
          with inappropriate interactions of User and/or fraudulent use of our Service.
        </li>
        <li>Be able to farmer Users for the purpose of providing them with technical assistance and support.</li>
        <li>
          Determine general geo-location information (i.e., country) from which the User's computer is connected to the Internet in order
          for us to know the general location of our Platform's Users as well as in order to safeguard our Platform.
        </li>
      </ul>
    </li>
    <li>
      <strong>Sharing Information with Third Parties</strong>
      <p>
        Avenews-GT will share Personal Information it collects to the extent that such disclosure is required for operating the Services
        offered via the Platform. Mainly, some of the information collected from you via the Platform will be available, to the extent
        required, to Users that you choose to interact with. In addition, we may share Personal Information with third-parties for the
        purpose of offering you tailored direct marketing via the Platform, as well as providing the Users with Financial Services, if
        applicable
      </p>
      <p>
        Notwithstanding, Avenews-GT may share Personal Information in the following cases: (a) to satisfy any applicable law, regulation,
        legal process, subpoena or governmental request; (b) to enforce this Privacy Policy and/or the Terms of Use, including investigation
        of potential violations thereof; (c) to detect, prevent, or otherwise address fraud, security or technical issues; (d) to respond to
        User's support requests; (e) to respond to claims that any content available on the Platform violates the rights of third-parties;
        (f) to respond to claims that contact information (e.g. name, e-mail address, etc.) of a third-party has been posted or transmitted
        without their consent or as a form of harassment; (g) to protect the rights, property, or personal safety of Avenews-GT, its Users,
        or the general public; (h) when Avenews-GT is undergoing any change in control, including by means of merger, acquisition or
        purchase of all or substantially all of its assets; (i) to collect, hold and/or manage your Personal Information through
        Avenews-GT's authorized third parties service providers (including, as applicable, their affiliates as necessary to provide us with
        the requested services), as reasonable for business purposes, which may be located in a country that does not have the same data
        protection laws as your jurisdiction; (j) to store or process your Personal Information through Avenews-GT's affiliated companies,
        which may be located in a country that does not have the same data protection laws as your jurisdiction. Avenews-GT requires its
        affiliated entities to agree to processing your Personal Information in compliance herewith; (k) cooperate with third parties for
        legitimate business purposes, including without limitation, enhancing the User's experience; and/or (l) pursuant to your explicit
        approval prior to the disclosure.
      </p>
      <p>
        For avoidance of doubt, Avenews-GT has the unrestricted right to use
        <u>Non-personal Information</u> for any purpose and to transfer and disclose <u>Non-personal Information</u> to third parties at its
        own discretion.
      </p>
    </li>
    <li>
      <strong>Social Features</strong>
      <p>
        Our Platform may include social media features (“<strong>Social Features</strong>”). The Social Features are operated or allow for
        social integration with certain third party social networks or third party platforms (“<strong>Social Network</strong>”). These
        Social Networks are created and maintained by third parties who are not affiliated with and/or controlled by Avenews-GT. If you
        enable this integration with such Social Networks, your use of the Social Features is subject to the applicable third party Social
        Network’s terms of use and privacy policies. You should ensure that you read their terms of use and privacy policies to understand
        how they treat your information and in order to understand the methods for changing the privacy or sharing settings on such
        services. If you do not agree to the practices described in such terms you should not allow the Platform to interact with such
        Social Networks, however you may find that you are not able to enjoy all the features that may be made available via the Platform.
        You understand that where you allow interaction with any third party Social Network, we may share data about you with your farmers
        (and vice versa) in order that you may enjoy social elements. This data may include, without limitation, your name and your social
        media profile picture. By using the Social Features you hereby agree and understand that the applicable Social Networks or Platforms
        may collect certain Personal Information, such as your IP address, Non-personal Information and may set cookies and/or other web
        tracking technologies to enable the functionality of the Social Features. The use of such features enables the sharing of
        information with your friends or the public, depending on the settings you establish with the third party that provides the Social
        Features. You hereby understand and agree that when you share content via Social Networks that your content may become public and
        others may re-post-it.
      </p>
    </li>
    <li>
      <strong>Deletion or Modification of Personal Information</strong>
      <p>
        If for any reason you wish to update or modify your Personal Information included in your Account you may do so by clicking the
        "Settings" option available through the App. If for any reason you wish to delete your Account and/or change your e-mail address
        please send us an e-mail with adequate detail of your request to:
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a>, and we will make reasonable efforts to modify or delete any such
        Personal Information pursuant to any applicable privacy laws. Note that unless you instruct us otherwise we may retain and use your
        Personal Information after termination of your Account, for as long as reasonably required for the purposes of which such Personal
        Information was collected, including without limitation, for future analytics and analysis, in order to comply with our legal or
        business requirements or obligations, to resolve disputes, to enforce our Terms and/or to enable the User to reinstate his/her
        Account, all as permitted under any applicable privacy laws.
      </p>
      <p>
        Aggregate and/or anonymous information derived from your Account and/or use of the Platform may remain on Avenews-GT servers
        indefinitely.
      </p>
    </li>
    <li>
      <strong>Minors</strong>
      <p>
        The Platform are intended for Users over the age of sixteen (16). Therefore, Avenews-GT does not intend and does not knowingly
        collect Personal Information from minors under the age of sixteen (16) and does not wish to do so. We reserve the right to request
        proof of age at any stage so that we can verify that minors under the age of sixteen (16) are not using the Platform. If we learn
        that we collected Personal Information from minors under the age of sixteen (16) we will delete that information as quickly as
        possible. Please contact Avenews-GT at
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a> if you have reasons to suspect that Avenews-GT collected Personal
        Information from minors under the age of sixteen (16) and we will delete that information as quickly as possible.
      </p>
    </li>
    <li>
      <strong>Security </strong>
      <p>
        We take reasonable measures to maintain the security and integrity of our Platform and your information and prevent unauthorized
        access to it or use thereof through generally accepted industry standard technologies and internal procedures. Please note, however,
        that there are inherent risks in transmission of information over the Internet or other methods of electronic storage and we cannot
        guarantee that unauthorized access or use of your Personal Information will never occur.
      </p>
    </li>
    <li>
      <strong>Third Party Service Providers</strong>
      <p>
        We may be using third party software or service in order to collect, host, store and/or process the information detailed herein.
        Avenews-GT uses commercially reasonable efforts to engage with third parties that post a privacy policy governing their collection,
        processing and use of non-personal and personal information. Such software includes without limitation; Amazon Web Services (AWS)
        which privacy policy can be found at
        <a href=" https://aws.amazon.com/privacy/?nc1=f_pr" target="_blank">https://aws.amazon.com/privacy/?nc1=f_pr</a>
        , google analytics which privacy policy can be found at
        <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a>
        , and Hotjar which privacy policy can be found at
        <a href="https://www.hotjar.com/legal/policies/privacy" target="https://www.hotjar.com/legal/policies/privacy"
          >https://www.hotjar.com/legal/policies/privacy</a
        >. However, Avenews-GT does not control such third party service provider, please read such third party service providers' terms of
        use and privacy policies to understand their privacy practices.
      </p>
    </li>
    <li>
      <strong>International Data Transfer </strong>
      <p>
        We may transfer information collected about you, including Personal Information, to affiliated entities, or to other third party
        service provides (as provided herein) across borders and from your country or jurisdiction to other countries or jurisdictions
        around the world. Please note that we may transfer such information to a country and jurisdiction that does not have the same data
        protection laws as your jurisdiction, and you consent to such transfer of information.
      </p>
    </li>
    <li>
      <strong>Links to Third Party Websites </strong>
      <p>
        Certain links provided herein permit our Users to leave the Platform and enter non-Avenews-GT sites or services. Those linked sites
        and services are provided solely as a convenience to you. These linked sites and services are not under the control of Avenews-GT
        and it is not responsible for the availability of such external sites or services, and does not endorse and is not responsible or
        liable for any content including but not limited to content advertising, products or other information on or available from such
        linked sites and services or any link contained in linked sites or service. In addition, Avenews-GT is not responsible or liable for
        such linked sites and services' privacy practices and/or any other practices. Your access to, use of and reliance upon any such
        sites, services and content and your dealings with such third parties are at your sole risk and expense. Avenews-GT reserves the
        right to terminate any link at any time. You further acknowledge and agree that the Company shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be caused, by or in connection with use of or reliance on any
        services, content, products or other materials available on or through such linked sites or resource. Most of such linked sites and
        services provide legal documents, including terms of use and privacy policy, governing the use thereof. It is always advisable to
        read such documents carefully before using those sites and services, inter alia, in order to know what kind of information they are
        collecting.
      </p>
    </li>
    <li>
      <strong>Cookies and Other Tracking Technologies</strong>
      <p>
        When you access or use the Platform, Avenews-GT and/or our third party service providers may use industry-wide technologies such as
        "cookies" or other similar technologies, which store certain local information on your device or on the Site
        ("<strong>Cookies</strong>"), which may enable, inter alia, automatic activation of certain features and make the User’s experience
        and usage simpler, more relevant, convenient and effortless. Such information is locally stored on your computer or on your device.
        Avenews-GT and/or our authorized third party service providers may access such information. Avenews-GT and/or our authorized third
        party service providers may use both session cookies (which expire once you close the Platform) and persistent cookies (which stay
        on the User’s device until he/she deletes them). Such Cookies included in the Service may store non-personal information (such as
        the different pages viewed by a User within the Platform) as well as User persistent identifier, which will be collected in
        accordance with the terms specified herein. It is easy to prohibit and/or delete the Cookies, inter alia, via uninstalling the App
        from your mobile device and/or through the Settings option of your device. Most platforms will allow you to erase cookies from your
        computer's hard drive or from you device, block acceptance of cookies, or receive a warning before a cookie is stored. In order to
        erase or disable the Cookies you may use the settings option of your device or according to the specific instructions provided by
        the third party service provider's privacy policy and terms of use. However, if you block or erase cookies, or change the settings
        of your device, your online and/or App experience may be limited.
      </p>
    </li>
    <li>
      <strong>Advertisements </strong>
      <p>
        Users may receive advertisements and commercials (including without limitation coupons, discounts, etc.) from third party
        advertisers and/or our authorized service providers and/or vendors. By clicking the advertisements or commercials you may be
        transferred to a website of an advertiser or receive any other messages, information or offers from the advertiser and/or from
        others. Note that if you click on any of these advertisements, these advertisers may use local storage including without limitation
        cookies and other web-tracking technologies (such as tracking pixel agent or visitor identification technology, etc.) to collect
        data regarding advertisement performance, your interaction with such ads and our Platform and your interests (which may include,
        non-personal and/or personal information (such as, device and network information, unique identifiers, gender, age and location)
        about you) in order to serve you advertisements and for the legitimate business interests of such third parties ad networks. We
        recommend that you review the terms of use and privacy policy of any advertiser with which you are interacting before doing so.
        Their privacy policy, not ours, will apply to any of those interactions. <br />
        All the information contained in such advertisements and commercials belongs solely to the advertisers and the Company makes no
        warranties or representations in respect of such, whether or not the Company has control over such advertisements or commercials and
        these are provided on an "AS IS" basis. In any event, the Company will not be liable for any damage or loss incurred to you as a
        result of or in connection with such advertisements or commercials. The Company, advertisers and/or related third-parties may be
        entitled to certain shares of the earnings for such advertisements and/or commercials. You agree, acknowledge and consent that no
        right, title or interest is or shall be granted to you in any way with respect to any revenue share whatsoever in relation thereto.
      </p>
    </li>
    <li>
      <strong>Direct Marketing</strong>
      <p>
        You hereby agree that we may use your farmer details for the purpose of informing you of products, services and other updates and
        new features which may be of interest to you, as well as for sending you other materials to the e-mail address provided in the
        registration process. You may withdraw your consent via sending a written notice to Avenews-GT by e-mail to the following e-mail
        address:
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a> or alternatively following the instructions for removing yourself
        from the mailing list which are available in the e-mail transmitted to you.
      </p>
    </li>
    <li>
      <strong>Changes to the Privacy Policy</strong>
      <p>
        The terms of this Privacy Policy will govern the use of the Platform and any information collected with respect thereto. Avenews-GT
        reserves the right to change this policy at any time, so please re-visit this page frequently. In case of any material change, we
        will make reasonable efforts to post a clear notice on the Platform and/or we will send you an e-mail (to the extent that you
        provided us with such e-mail address) regarding such change. Such substantial changes will take effect seven (7) days after such
        notice was provided on our Platform or sent by e-mail, whichever is the earlier. Otherwise, all other changes to this Privacy Policy
        are effective as of the stated “Last Revised” date and your continued use of the Platform after the Last Revised date will
        constitute acceptance of, and agreement to be bound by, those changes. In the event that the Terms should be amended to comply with
        any legal requirements, the amendments may take effect immediately, or as required by the law and without any prior notice.
      </p>
    </li>
    <li>
      <strong>Got any Questions?</strong>
      <p>
        If you have any questions (or comments) concerning this Privacy Policy, you are most welcome to send us an email to the following
        address:
        <a href="mailto:office@avenews-gt.com">office@avenews-gt.com</a> and we will make an effort to reply within a reasonable timeframe.
      </p>
    </li>
  </ol>
</div>
