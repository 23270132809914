import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseAgribusiness } from '@avenews/agt-sdk';
import { SideView } from '../../shared/components/base-side-view/base-side-view.component';

@Component({
  selector: 'frontend-agribusiness-view',
  templateUrl: './agribusiness-view.component.html',
  styleUrls: ['./agribusiness-view.component.scss']
})
export class AgribusinessViewComponent implements OnInit {
  topTitle: string;
  response = false;
  item: any;
  showModale = false;
  showDecModal = false;
  application: any;
  agribusiness: BaseAgribusiness;
  sideView: SideView[] = [
    {
      name: 'partner first name',
      dtoName: 'firstName'
    },
    {
      name: 'partner last name',
      dtoName: 'lastName'
    },

    {
      name: 'partner type',
      dtoName: 'partnerType'
    },
    {
      name: 'phone number',
      dtoName: 'adminDetails.phoneNumber'
    },
    {
      name: 'email',
      dtoName: 'adminDetails.email'
    },
    {
      name: 'role',
      dtoName: 'adminDetails.role'
    }
  ];
  sideViewAddDetails: SideView[] = [
    {
      name: 'FINANCED % CAP',
      dtoName: 'loanAmount'
    },
    {
      name: 'PAYMENT TERMS',
      dtoName: 'paymentTerms'
    },

    {
      name: 'STATUS',
      dtoName: 'status'
    }
  ];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ agribusiness }) => {
      this.agribusiness = agribusiness;
      this.topTitle = this.agribusiness.adminDetails?.name;
    });
  }

  statusGrabber(status: string) {
    switch (status) {
      case 'draft':
        return 'Draft';
      case 'approved':
        return 'Approved';
      case 'processing':
        return 'Processing';
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Declined';
      case 'pre-approved':
        return 'Pre Approved';
      default:
        return 'Draft';
    }
  }
  remover(ans: boolean) {
    this.showModale = false;
  }
  openModal(item: any) {
    this.item = item;
    this.showModale = true;
  }
  agriType(agri: any) {
    return agri?.paymentTerms ? 'Buyer' : 'Supplier';
  }
  agriFirstName(agri): string {
    if (agri?.contacts?.length) {
      return agri.contacts[0].firstName;
    }
    return '-';
  }
  agriLastName(agri): string {
    if (agri?.contacts?.length) {
      return agri.contacts[0].lastName;
    }
    return '-';
  }
}
