export const certifications = [
  'Woman Owned - WBENC Certification',
  'WERCSmart Registered',
  'WBE',
  'UTZ',
  'U.S. Grade A',
  'USDA Organic',
  'USDA Free Range',
  'USDA BioPreferred',
  'USDA Antibiotic Free',
  'UL Certification',
  'SVLK Certification',
  'Sugar Alternative',
  'State Certified Minority Owned',
  'SQF Level 3 Certification',
  'SQF Level 2 Certification',
  'Small Disadvantaged Business - Government Certified',
  'Small Business Certified - SBA(8)A Certified',
  'Service-Disabled Veteran Owned - VA Confirmed',
  'Seafood Watch Green Rating',
  'REAL® Seal',
  'RCC Kosher',
  'Rainforest Alliance Certified',
  'Primus Labs',
  'PFOA Free',
  'PETA Cruelty Free and Vegan',
  'PEFC',
  'Paleo Certified',
  'OU Kosher Certified',
  'Oregon Tilth Organic',
  'OMRI',
  'OK Kosher Certified',
  'NZIDT Halal',
  'NSF Non-GMO',
  'NSF GMP',
  'NSF Gluten-Free Certification',
  'NSF BRC',
  'NSF/ANSI 305',
  'NSF 305 Organic Personal Care',
  'NPA Natural Seal: Personal Care',
  'NPA Natural Seal: Home Care',
  'NPA GMP',
  'Non-GMO Project Verified',
  'NAPW',
  'MSC Certified',
  'MBE',
  'Manufacturers Declaration of Compliance for United States FDA',
  'Made Safe',
  'LFGB',
  'Leaping Bunny Approved',
  'KSA Kosher Certified',
  'JPMA Certified',
  'ITA Affiliate',
  'ISO9001:2008',
  'ISO 9001',
  'ISO 22000:2005',
  'ISO 14001:2004',
  'International Featured Standards (IFS)',
  'iGen Certified',
  'IFANCA Halal Certified',
  'ICEA',
  'IC Certification',
  'HASB Halal Certified',
  'Halal Certification',
  'HACCP',
  'GOTS Certified',
  'GOTS',
  'Gluten-Reduced Certified',
  'Gluten- Free Certified',
  'Global Recycle Standard',
  'Global Coffee Platform',
  'Global Certification Service Ltd',
  'GI Symbol',
  'GFSI',
  'GFCP',
  'GFCO',
  'Gay, Lesbian, Bi-Sexual or Transgender Owned - NGLCC certified',
  'Forest Stewardship Council',
  'FDA Antibiotic Free',
  'GFCC Certification',
  'Fair Trade Certified',
  'EWG Verified',
  'EWG Certified',
  'ETL Listed',
  'EPA Safer Choice Certification',
  'Energy Star',
  'Ecogarantie',
  'EcoCert',
  'EarthKosher',
  'Ethnic Minority Owned - NMSDC Certified',
  'DOP - Protected Designation of Origin',
  'Disadvantaged Business Enterprise - Government Certified',
  'Direct Trade',
  'DesignLights Consortium (DLC) Certification',
  'CSE',
  'CRC Kosher',
  'CPSC GCC',
  'COSMOS-standard',
  'Codex GMP',
  'Choose Cruelty Free',
  'Certified Organic by the New Mexico Department of Agriculture',
  'Certified Organic',
  'Certified Humane',
  'Certificate of Compliance CE',
  'CE Certification',
  'CCIC cruelty free',
  'Cage-Free',
  'BRC Global Standard',
  'BPA Free',
  'BFC',
  'BAP Certification',
  'Australian Certified Organic',
  'ASTM 963-11',
  'ASTA Testing Report',
  'Animal Welfare Approved',
  'American Wagyu Association Membership',
  'American Heart Association certified',
  'American Grassfed',
  'US Halal Certification',
  'Vegan Certified',
  'Veteran Owned - NaVOBA'
];
