<div class="main">
  <div class="vertical-progress-bar">
    <div class="progress">
      <div
        class="progress-item circle"
        *ngFor="let step of steps"
        [ngClass]="{ 'progress-item-completed': step?.done, 'in-progress-item': step === currUncompletedStep }"
      >
        <span class="line" *ngIf="step?.done || step === currUncompletedStep">
          <span class="progress-line" [style.height]="step?.done ? 100 + '%' : progressHeight + '%'" ngClass="blue-line"></span>
        </span>
        <span *ngIf="step !== currUncompletedStep && !step?.done" class="line"></span>
        <div class="flex-space-between title">
          <p
            class="small-text medium m-unset letter-space-32"
            [ngClass]="[step?.title === currUncompletedStep?.title ? 'blue-title' : 'grey-title', step?.done ? 'dark' : 'grey']"
          >
            {{ step.title }}
          </p>

          <p *ngIf="step?.subtitle" class="text-secondary font-normal text-xs">{{step?.subtitle}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
