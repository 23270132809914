<ng-template
  [cdkConnectedOverlayPanelClass]="['av-select-pane', customClass]"
  (attach)="open()"
  (detach)="close()"
  [cdkConnectedOverlayOrigin]="element"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
>
  <div class="avenews-select">
    <div class="avenews-select-container">
      <div class="select-search-container">
        <div class="select-search-bar">
          <input
            class="form-control searcher"
            *ngIf="allowSearch"
            type="text"
            placeholder="Search"
            (keyup)="onSearch($event)"
            [(ngModel)]="searchTerm"
          />
          <span class="searching"></span>
        </div>
      </div>
      <div class="items-container">
        <ng-container *ngFor="let item of items">
          <div *ngIf="item" [ngClass]="{ selected: selected?._id === item?._id }" class="item">
            <div class="avenews-select-item" (click)="selectItem(item)">
              <div *ngIf="avatar" class="avenews-select-avatar">
                <span *ngIf="!item?.fake">
                  {{ item?.personalInformation?.firstName | slice: 0:1 }}{{ item?.personalInformation?.lastName | slice: 0:1 }}</span
                >
              </div>
              <div class="avenews-select-item-label">{{ item.viewValue | titlecase }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
