import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { UsersResolver } from '../shared/resolvers/users.resolver';
import { DealsResolver } from './user-view/resolvers/deals.resolver';
import { PeriodsResolver } from './user-view/resolvers/periods.resolver';
import { RepaymentsResolver } from './user-view/resolvers/repayments.resolver';
import { DealsComponent } from './user-view/deals/deals.component';
import { PeriodsComponent } from './user-view/periods/periods.component';
import { RepaymentsComponent } from './user-view/repayments/repayments.component';
import { UserViewComponent } from './user-view/user-view.component';

@NgModule({
  declarations: [
    OrganizationsListComponent,
    CreateOrganizationComponent,
    UserViewComponent,
    DealsComponent,
    PeriodsComponent,
    RepaymentsComponent
  ],
  imports: [
    MatTooltipModule,
    MatMenuModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    AdminSharedModule,
    SharedModule,
    OrganizationsRoutingModule
  ],
  exports: [],
  providers: [DealsResolver, UsersResolver, PeriodsResolver, RepaymentsResolver]
})
export class OrganizationsModule {}
