import Smartlook from 'smartlook-client';
import { User } from '@avenews/agt-sdk';
import { catchError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, AbstractControl, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BreadCrumbs, Question, SdkService, SpinnerToggleService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';
import { TradeDeskService } from '../services/trade-desk.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTabGroup } from '@angular/material/tabs';
import { ApplicationStatus } from '../../shared/helpers';
import * as moment from 'moment';

interface Answer {
  answers: string[];
  notes: string;
  score?: number;
  question: string;
  loanId: string;
  _id?: string;
}
@Component({
  selector: 'frontend-trade-desk-agent',
  templateUrl: './trade-desk-agent.component.html',
  styleUrls: ['./trade-desk-agent.component.scss', './trade-desk-agent-2.scss']
})
export class TradeDeskAgentComponent implements OnInit, AfterViewInit {
  selectContainer: any;
  isLoading = false;
  isEdit: boolean;
  mlScoreData: any;
  question: Question;
  selectedIndex = 0;
  selectOpen = false;
  questionsForm: FormGroup;
  newTD = false;
  environment = environment;
  currentUser: User;
  users: User[];
  mlScore = 50;
  assignee: User;
  loanDetails: any;
  actualMlScore = 0;
  fakeId = 25;
  chosenCategory: any;
  offer: any;
  answers: Answer[] = [];
  kycLink = '';
  originalOffer: any;
  cropForm: FormGroup;
  cropPriceForm: FormGroup;
  wholeSale: FormGroup;
  retail: FormGroup;
  emailSMF: FormGroup;
  phoneSMF: FormGroup;
  businessForm: FormGroup;
  insightForm: FormGroup;
  bankFormArray: FormArray;
  isBNPLUser: boolean;
  reasonRes: (value: string | PromiseLike<any>) => void;
  reasonSubmitted = new Promise<any>((resolve) => {
    this.reasonRes = resolve;
  });
  insightsArray: FormArray;
  bankInsightsArray: FormArray;
  // months is an array of object with the following structure viewValue:name of month, value:number

  months = [];
  statuses = [
    {
      realName: 'New',
      status: ApplicationStatus.NEW,
      color: 'blue'
    },
    {
      realName: 'In progress',
      status: ApplicationStatus.PROCESSING,
      color: 'orange'
    },
    {
      realName: 'Waiting for client',
      status: ApplicationStatus.WAITING_FOR_CLIENT,
      color: 'gray'
    },
    {
      realName: 'On hold',
      status: ApplicationStatus.ON_HOLD,
      color: 'gray'
    },
    {
      realName: 'Duplicated',
      status: ApplicationStatus.DUPLICATED,
      color: 'gray'
    },
    {
      realName: 'Churned',
      status: ApplicationStatus.CHURNED,
      color: 'gray'
    },
    {
      realName: 'Referred back',
      status: ApplicationStatus.REFERRED_BACK,
      color: 'purple'
    },
    {
      realName: 'Awaiting offer approval',
      status: ApplicationStatus.AWAITING_APPROVAL,
      color: 'dark-blue'
    },
    {
      realName: 'Tentatively approved',
      status: ApplicationStatus.TENTATIVELY_APPROVED,
      color: 'dark-blue'
    },
    {
      realName: 'Offer declined intrenally',
      status: ApplicationStatus.INTERNALLY_DECLINED,
      color: 'red'
    },

    {
      realName: 'Offer approved intrenally',
      status: ApplicationStatus.INTERNALLY_APPROVED,
      color: 'green'
    },
    {
      realName: 'Offer declined by client',
      status: ApplicationStatus.CLIENT_DECLINED,
      color: 'red'
    },
    {
      realName: 'Offer approved by client',
      status: ApplicationStatus.CLIENT_APPROVED,
      color: 'green'
    }
  ];
  loan: any;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    },
    {
      name: 'Trade Desk',
      route: 'trade-desk'
    }
  ];
  categories = [];
  editMode: boolean;
  activeEdit = [];
  helperForm: FormGroup;
  miniSpinners;
  cropOptions: any = [];

  bankOptions = [
    { value: 'equity', viewValue: 'Equity' },
    { value: 'co_op', viewValue: 'Co-op' },
    { value: 'kcb', viewValue: 'KCB' },
    { value: 'ncba', viewValue: 'NCBA' },
    { value: 'absa', viewValue: 'Absa' },
    { value: 'dtb', viewValue: 'DTB' },
    { value: 'family_bank', viewValue: 'Family Bank' },
    { value: 'nbk', viewValue: 'NBK' },
    { value: 'stanbic', viewValue: 'Stanbic' },
    { value: 'stanchart', viewValue: 'Stanchart' },
    { value: 'sbm', viewValue: 'SBM' },
    { value: 'boa', viewValue: 'BOA' },
    { value: 'credit_bank', viewValue: 'Credit Bank' },
    { value: 'sidian_bank', viewValue: 'Sidian Bank' },
    { value: 'im_bank', viewValue: 'IM Bank' },
    { value: 'loop', viewValue: 'Loop' },
    { value: 'consolidated', viewValue: 'Consolidated' },
    { value: 'hf_group', viewValue: 'HF Group' },
    { value: 'kopo_kopo', viewValue: 'Kopo Kopo' },
    { value: 'gulf_african', viewValue: 'Gulf African Bank' }
  ];
  cropPriceOptions: any = [];
  errorMessages = {
    username: [
      {
        type: 'required',
        message: 'Email is required'
      },
      {
        type: 'email',
        message: 'Email is not valid'
      }
    ],
    password: [
      {
        type: 'invalidFile',
        message: 'File not supported'
      },
      {
        type: 'required',
        message: 'Password is required'
      },
      {
        type: 'wrongPass',
        message: 'Wrong password'
      }
    ]
  };
  @ViewChild('tabGroup', {
    static: true
  })
  tabGroup: MatTabGroup;
  extraForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private route: ActivatedRoute,
    private alerts: AlertService,
    private clipboard: Clipboard,
    private http: HttpClient,
    private router: Router,
    private sdkService: SdkService,
    public tdService: TradeDeskService
  ) {}
  saveLoanData() {
    if (this.loan?.dealId) {
      this.extraForm.patchValue({
        dealMargin: this.loan.dealMargin || this?.loan?.dealId?.totalSelling - this?.loan?.dealId?.totalBuying,
        dealROI: Math.round(100 * (this.loan.dealROI || this?.loan?.dealId?.totalSelling / this?.loan?.dealId?.totalBuying - 1)) + '%',
        periodROI: Math.round(this.loan?.dealId?.periodRoi * 100) + '%',
        annualROI: Math.round(this.loan?.dealId?.annualizedRoi * 100) + '%',
        periodWeeks: this.loan?.dealId?.periodWeeks?.toFixed(2),
        totalBuying: this.loan?.dealId?.totalBuying,
        totalSelling: this.loan?.dealId?.totalSelling
      });
    }
  }
  ngOnInit(): void {
    this.resetPromise();
    this.months = this.tdService.months;
    this.tdService.createCropOptions().then((data) => {
      this.cropOptions = data;
    });
    this.tdService.createCropPriceOptions().then((data) => {
      this.cropPriceOptions = data;
    });
    this.miniSpinners = this.tdService.miniSpinners;
    this.initForms();
    this.route.data.subscribe(({ categories, loan, scoreData, generalVariables, user, users, logs, loanInfo }) => {
      if (environment.production) {
        try {
          Smartlook.identify(user._id, {
            email: user.personalInformation?.email,
            name: user.personalInformation?.firstName + ' ' + user.personalInformation?.lastName,
            phoneNumber: user.personalInformation?.phoneNumber
          });
        } catch {}
      }
      console.log(loanInfo, 'loanInfo');
      if (loanInfo?.financingDetails?.inputsNetwork) {
        this.isBNPLUser = true;
      }
      this.initForms();
      this.mlScoreData = scoreData;
      console.log(this.mlScoreData);
      this.users = users;
      this.users.forEach((data) => {
        data['avatar'] = true;
      });
      this.users.push({
        personalInformation: { firstName: 'Unassign', lastName: '' },
        _id: undefined,
        fake: true,
        avatar: false
      } as any);
      this.loan = loan;
      this.kycLink = `http://ukyc-frontend.s3-website-us-east-1.amazonaws.com/kyc-check/${this.loan._id}`;
      this.assignee = this.loan?.assignee;
      this.offer = scoreData?.offerCalculator;
      this.originalOffer =
        logs?.entities?.find((log) => {
          return log.action === 'getOfferCalculator';
        })?.value?.offerCalculator || this.offer;
      this.currentUser = user;
      this.mlScore = 100 - categories?.reduce((a: any, b: any) => a + b.score, 0);
      this.actualMlScore = 0;
      this.tdService.checkAll(this.cropForm, this.emailSMF, this.phoneSMF, this.businessForm, this.cropPriceForm, scoreData, loan);
      if (scoreData) {
        const bankStatements = scoreData?.decision?.bankStatement;
        if (bankStatements?.length) {
          this.bankFormArray = this.fb.array([]);
          for (let i = 0; i < bankStatements.length; i++) {
            const bankStatement = bankStatements[i];
            this.addBankStatement(bankStatement);
          }
        }
        const insights = scoreData?.decision?.insights;
        const bankInsights = scoreData?.decision?.bankInsights;
        if (insights?.length) {
          this.insightsArray = this.fb.array([]);
          for (let i = 0; i < insights.length; i++) {
            const insight = insights[i];
            const insightForm = this.generateInsightForm();
            insightForm?.patchValue({
              fileName: insight?.documents?.[0]?.fileName,
              password: insight?.documents?.[0]?.password,
              _id: insight?._id
            });
            this.tdService.prepareInsights(insight, insightForm, loan);
            this.insightsArray.push(insightForm);
          }
        }
        if (bankInsights?.length) {
          this.bankInsightsArray = this.fb.array([]);
          for (let i = 0; i < bankInsights.length; i++) {
            const insight = bankInsights[i];
            const insightForm = this.generateBankInsightForm();
            insightForm?.patchValue({
              fileName: insight?.documents?.[0]?.fileName,
              password: insight?.documents?.[0]?.password,
              _id: insight?._id
            });
            this.tdService.prepareBankInsights(insight, insightForm);
            this.bankInsightsArray.push(insightForm);
          }
        }
        this.newTD = false;
        this.loanDetails = scoreData;
        if (scoreData.score) {
          this.actualMlScore = (1 - scoreData.score) * this.mlScore;
        }
      } else {
        this.newTD = true;
        if (!this.loanDetails) {
          this.loanDetails = {};
        }
        this.loanDetails['threshold'] = {
          approval: generalVariables.approvalThreshold?.value,
          declined: generalVariables.declineThreshold?.value
        };
      }
      let ans = [];
      this.categories = categories;
      this.categories?.forEach((data) => {
        this.categories['answeredQuestions'] = this.answeredQuestions(data);
        data?.questions?.forEach((data) => {
          if (data?.answer) ans.push(data?.answer);
        });
      });
      this.answers = ans;
      this.loan = loan;
      this.saveLoanData();

      for (let i = 0; i < this.categories?.length; i++) {
        const category = this.categories[i];
        for (let j = 0; j < category?.questions.length; j++) {
          const question = category?.questions[j];
          question.multipleChoices = question?.multipleChoices?.map((choice) => {
            return { value: choice.label, label: choice.label, viewValue: choice.label };
          });
          const answer = question?.answer;
          question['answer'] = answer?.answers || undefined;
          question['answerId'] = answer?._id || undefined || undefined;
          question['notes'] = answer?.notes || undefined;
          question['userScore'] = answer?.score || 0;
        }
      }
      if (!this.chosenCategory) {
        this.chooseCategory(0);
      } else {
        let i = this.categories.findIndex((category) => category._id === this.chosenCategory._id);
        const category = this.categories[i];
        this.chosenCategory = category;
        this.questionsForm = this.fb.group({});
        category.questions.forEach((question) => {
          const arr = this.fb.array([]);
          if (question?.multipleChoices?.length) {
            question.multipleChoices = question?.multipleChoices?.map((choice) => {
              if (question?.type === 'checkbox') {
                arr.push(this.fb.control(question?.answer?.find((a) => a === choice.label) || undefined));
              }
              return { value: choice.label, label: choice.label, viewValue: choice.label };
            });
          }
          this.questionsForm.addControl(
            question._id,
            this.fb.group({
              answer: question?.type === 'checkbox' ? arr : question.answer?.[0],
              notes: [question.notes],
              _id: [question.answerId || undefined]
            })
          );
        });
      }
      this.chosenCategory = this.categories.find((category) => category._id === this.chosenCategory?._id);
      this.loan['customStatus'] = this.getStatus();
      this.loan['decisionTreeStatus'] = this.getDecisionTreeStatus();
    });
  }
  edit() {
    if (this.editMode) {
      this.createSmartlookEvent('Submit TD Questions');

      this.submit();
      return;
    }
    this.createSmartlookEvent('Edit TD Questions Started');
    this.editMode = true;
  }

  remove(category: AbstractControl) {}
  resetForm() {}
  get isEditing() {
    return this.activeEdit.some((e) => e);
  }
  chooseCategory(i: number) {
    if (this.categories[i]?._id === this.chosenCategory?._id) return;
    if (!this.editMode) {
      const category = this.categories[i];
      this.chosenCategory = category;
      this.questionsForm = this.fb.group({});
      category.questions.forEach((question) => {
        const arr = this.fb.array([]);
        if (question?.multipleChoices?.length) {
          question.multipleChoices = question?.multipleChoices?.map((choice) => {
            if (question?.type === 'checkbox') {
              arr.push(this.fb.control(question?.answer?.find((a) => a === choice.label) || undefined));
            }
            return { value: choice.label, label: choice.label, viewValue: choice.label };
          });
        }
        this.questionsForm.addControl(
          question._id,
          this.fb.group({
            answer: question?.type === 'checkbox' ? arr : question.answer?.[0],
            notes: [question.notes],
            _id: [question.answerId || undefined]
          })
        );
      });
    } else {
      this.alerts
        .fire({
          iconName: 'warning',
          title: 'Save changes?',
          subtitle: 'You need to save your changes before you can change the category',
          confirmButtonText: 'Save',
          cancelButtonText: 'Discard'
        })
        .then((data) => {
          if (data.confirmed) {
            this.submit();
            this.editMode = false;
            this.chooseCategory(i);
          } else if (data.canceled) {
            this.createSmartlookEvent('Cancelled TD Questions Edit');

            this.editMode = false;
            this.chooseCategory(i);
          }
        });
    }
  }
  expand(div: HTMLDivElement, icon: any) {
    if (!div.clientHeight) {
      icon.elem.nativeElement.classList.add('expanded');
      div.style.height = `${div.scrollHeight}px`;
    } else {
      icon.elem.nativeElement.classList.remove('expanded');
      div.style.height = '0px';
    }
  }
  expandMore(div: HTMLDivElement, bool?: boolean) {
    if (bool) {
      div.style.height = 82 + 'px';
    }
    setTimeout(() => {
      div.style.height = `${div.scrollHeight}px`;
    }, 200);
  }
  get calcTotalQuestionScore() {
    let totalScore = 0;
    this.chosenCategory?.questions?.forEach((question) => {
      if (question.active) totalScore += question.score;
    });
    return totalScore;
  }
  restrict(e: KeyboardEvent) {
    const bannedKeys = ['e', 'E', '+', '-', '*', '/'];
    if (bannedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }
  }
  // get calcAnswersByCat() {
  //   if (!this.chosenCategory) return 0;
  //   return this.calcAnswerScore(this.chosenCategory?.questions);
  // }

  // private calcAnswerScore(questions: any[]) {
  //   return questions?.reduce((acc, question) => {
  //     if (question?.active) {
  //       return acc + question?.userScore;
  //     }
  //     return acc;
  //   }, 0);
  // }
  answeredQuestions(category: any) {
    return category.questions.reduce((acc, question) => {
      if (question.active && question.answer?.length) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }
  calcAnswerPercentage(category: any) {
    if (this.newTD) {
      return 0;
    } else {
      return isNaN(+category?.totalScore) ? 0 : +category?.totalScore * 100;
    }
    // let totalScore = 0;
    // category?.questions?.forEach((question) => {
    //   if (question.active) totalScore += question.score;
    // });
    // if (totalScore === 0) return 0;
    // return (this.calcAnswerScore(category.questions) / totalScore) * (category?.score || 1);
  }
  // questionScore(question: any) {
  //   return question?.reduce((acc, curr) => {
  //     return acc + curr.score;
  //   }, 0);

  // }
  submit() {
    const answers: Answer[] = [];
    const keys = Object.keys(this.questionsForm.controls);
    keys.forEach((key) => {
      const answer = this.questionsForm.controls[key].value;
      let score = Math.round(
        Math.random() *
          this.chosenCategory.questions.find((data) => {
            return data._id === key;
          }).score
      );
      if (!answer?.answer) {
        score = 0;
      }
      answers.push({
        answers:
          typeof answer.answer === 'string' || typeof answer?.answer === 'number'
            ? [answer?.answer]
            : answer.answer?.map?.((data) => {
                if (!!data) return data;
              }),
        notes: answer.notes,
        question: key,
        loanId: this.loan._id,
        _id: answer?._id || undefined
      });
    });
    this.spinner.showSpinner();

    this.createSmartlookEvent('TD Questions Saved');
    this.http
      .post(`${this.environment.new_api_url}/api/trade-desk/answer/many`, answers)
      .pipe(
        catchError((err) => {
          this.editMode = false;
          this.spinner.hideSpinner();
          this.alerts.showAlert(err.error.message, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.editMode = false;
        this.alerts.showAlert('Answers saved', 'success');
        this.router.navigateByUrl(this.router.url);
      });
  }
  get financeScore() {
    let performance = 0;
    this.categories?.forEach((category) => {
      performance += this?.calcAnswerPercentage(category);
    });
    return performance + this.actualMlScore || 0;
  }
  activeLength(questions: any[]) {
    return questions.filter((data) => {
      return data?.active;
    });
  }
  openLoanDetails() {
    this.createSmartlookEvent('ML Model Modal Opened');
    $('#loanDetailsModal').css({ display: 'flex' });
  }
  closed(bool: boolean) {
    if (bool) {
      this.actualMlScore = null;
      this.router.navigateByUrl(this.router.url);
      // this.getMlScore();
      this.createSmartlookEvent('ML Model Updated');
    } else {
      this.createSmartlookEvent('ML Model Closed Without Updates');
    }
    if (this.loanDetails) this.loanDetails = JSON.parse(JSON.stringify(this.loanDetails));
  }
  generateOffer() {
    if (this.getStatus()?.status === 'decline') {
      return;
    }
    if (!this.loanDetails) {
      this.alerts.showAlert('Please fill in the loan details first', 'danger');
      return;
    }
    this.spinner.showSpinner();
    this.createSmartlookEvent('Offer Generated');
    this.http
      .put(`${environment.new_api_url}/api/trade-desk/offer-calculator`, {
        PD: this.loanDetails?.score,
        loanId: this.loan._id
      })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err.message, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        this.offer = data;
        this.router.navigateByUrl(this.router.url);
        $('#loanOfferModal').css({ display: 'flex' });
      });
  }

  arrayNotEmpty(arr: any[]): boolean {
    if (!arr) return false;
    if (arr.length === 0) return false;
    return arr.some((data) => {
      return !!data;
    });
  }
  getStatus(): { status: string; name: string } {
    let res = { status: '', name: '' };
    const score = this.financeScore;
    // const fileTypes = { coi: false, cr12: false, kra: false };
    // Object.keys(fileTypes).forEach((key) => {
    //   fileTypes[key] = this.mlScoreData?.files?.some((file: { fileType: string; status: string }) => {
    //     if (key === file.fileType) {
    //     }
    //     return file?.status === 'approved' && file?.fileType === key;
    //   });
    // });
    const { approval, declined } = this.loanDetails?.threshold;
    if (this.offer?.status === 'declined') {
      res.status = 'decline';
      res.name = 'Offer Declined';
      return res;
    } else if (this.offer?.status === 'approved') {
      res.status = 'approve';
      res.name = 'Offer Approved';
      return res;
    }
    const showNewRec = !(this.mlScoreData?.categories as any[])?.some((category) => category?.categoryId === '634573d637e3644de79432b0');
    console.log(this.mlScoreData?.categories);
    console.log(showNewRec);
    if (showNewRec) {
      const env = environment.production ? 'prod' : 'staging';
      this.http
        .get(`https://nhkl5nsm56.execute-api.us-east-1.amazonaws.com/${env}/td-recommendation/${this.loan?._id}`)
        .subscribe((data: any) => {
          if (data?.status === 'approve') {
            res.status = 'approve';
            res.name = 'Approval is recommended';
          } else if (data?.status === 'decline') {
            res.status = 'decline';
            res.name = 'Decline is recommended';
          } else {
            res.status = 'escalate';
            res.name = 'Please escalate';
          }
        });
    } else {
      if (score >= +approval) {
        res.status = 'approve';
        res.name = 'Approval is recommended';
      } else if (score <= +declined) {
        res.status = 'decline';
        res.name = 'Decline is recommended';
      } else {
        res.status = 'escalate';
        res.name = 'Please escalate';
      }
    }
    return res;
  }
  getDecisionTreeStatus() {
    let res = { status: '', name: '' };
    if (this.mlScoreData?.score && this.actualMlScore) {
      const mlScore = this.actualMlScore;
      if (mlScore < 36) {
        res.status = 'decline';
        res.name = 'Decline is recommended';
      } else if (mlScore >= 36 && mlScore <= 42) {
        res.status = 'escalate';
        res.name = 'Please escalate';
      } else {
        res.status = 'approve';
        res.name = 'Approval is recommended';
      }

      return res;
    }
    res.status = 'decline';
    res.name = 'Decline is recommended';
    return res;
  }

  uploadDoc(event: any, i: number, form?: any) {
    let file: File = event.target.files[0];
    if (!this.validateFile(file)) {
      this.alerts.showAlert(`Maximum file size is 150MB`, 'danger');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File = reader.result as string;
      const doc = {
        file: base64File.split(',')[1],
        fileName: file.name,
        mime: file.type
      };
      if (form) {
        form.patchValue({ ...doc });
        return;
      }
      this.insightsArray.at(i).patchValue({ ...doc });
    };
  }
  initForms() {
    this.extraForm = this.fb.group({
      dealMargin: [0],
      dealROI: [0],
      periodROI: [0],
      periodWeeks: [0],
      annualROI: [0],
      totalBuying: [0],
      totalSelling: [0]
    });

    const mmaForm = () =>
      this.fb.group({
        min: [undefined],
        max: [undefined],
        avg: [undefined]
      });
    const smForm = (phone?: boolean) =>
      this.fb.group({
        username: [undefined, [Validators.required, !phone ? Validators.email : Validators.required]],
        facebook: [{ value: undefined, disabled: true }],
        linkedin: [{ value: undefined, disabled: true }],
        twitter: [{ value: undefined, disabled: true }]
      });

    this.helperForm = this.fb.group({
      cropForm: this.fb.group({
        cropType: [undefined, Validators.required],
        planting: [{ value: undefined, disabled: true }],
        harvesting: [{ value: undefined, disabled: true }],
        peak: [{ value: undefined, disabled: true }]
      }),
      cropPriceForm: this.fb.group({
        cropName: [undefined, Validators.required],
        wholeSale: mmaForm(),
        retail: mmaForm()
      }),
      emailSMF: smForm(),
      phoneSMF: smForm(true),
      business: this.fb.group({
        number: [undefined, Validators.required],
        name: [undefined, Validators.required],
        status: [{ value: undefined, disabled: true }]
      }),
      bankStatement: this.fb.array([this.generateBankForm()]),
      insights: this.fb.array([this.generateInsightForm()]),
      bankInsights: this.fb.array([this.generateBankInsightForm()])
    });

    this.cropForm = this.helperForm.get('cropForm') as FormGroup;
    this.cropPriceForm = this.helperForm.get('cropPriceForm') as FormGroup;
    this.wholeSale = this.cropPriceForm.get('wholeSale') as FormGroup;
    this.retail = this.cropPriceForm.get('retail') as FormGroup;
    this.emailSMF = this.helperForm.get('emailSMF') as FormGroup;
    this.phoneSMF = this.helperForm.get('phoneSMF') as FormGroup;
    this.businessForm = this.helperForm.get('business') as FormGroup;
    this.bankFormArray = this.helperForm.get('bankStatement') as FormArray;
    this.insightsArray = this.helperForm.get('insights') as FormArray;
    this.bankInsightsArray = this.helperForm.get('bankInsights') as FormArray;
  }
  validateFile(file: any) {
    return file.size < 15e7;
  }
  async copyToClipboard(val: string) {
    this.clipboard.copy(val);
    this.alerts.showAlert('Copied to clipboard', 'success');
  }
  viewKyc() {
    $('#kycViewModal').css({ display: 'flex' });
  }
  openAssigneeSelect(element: any) {
    this.selectOpen = !this.selectOpen;
    this.selectContainer = element;
  }
  assignTo(user?: User) {
    this.tdService.assignTo(user, this.loan).then((data) => {
      if (!data) {
      } else {
        this.assignee = user;
      }
      this.selectOpen = false;
    });
  }
  toggleReasonModal() {
    //if reasonModal exists more than once
    let reasonModal;

    if (document.querySelectorAll('#reasonModal').length > 1) {
      reasonModal = $(document.querySelectorAll('#reasonModal')?.[1]);
    }
    reasonModal = reasonModal || $('#reasonModal');
    const display = reasonModal.css('display') === 'flex' ? 'none' : 'flex';
    reasonModal.css({ display });
  }
  async changeStatus(status: any) {
    let declineReason = { definedReason: '', reason: '' };

    if (status.status === ApplicationStatus.INTERNALLY_DECLINED || status.status === ApplicationStatus.CLIENT_DECLINED) {
      this.toggleReasonModal();
      declineReason = await this.reasonSubmitted;
      this.resetPromise();
      if (!declineReason?.definedReason) {
        return;
      }
      this.toggleReasonModal();
    }
    this.createSmartlookEvent('Changed Loan Status', {
      status: status.status,
      declineReason: declineReason?.reason || undefined,
      definedReason: declineReason?.definedReason || undefined
    });

    this.spinner.showSpinner();
    this.http
      .put(`${this.environment.new_api_url}/api/loan-application/status/${this.loan?._id}`, {
        status: status?.status,
        declineReason: declineReason?.reason || undefined,
        definedReason: declineReason?.definedReason || undefined
      })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err.message, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe(() => {
        this.loan.status = status.status;
        this.spinner.hideSpinner();
        this.alerts.showAlert('Status changed', 'success');
      });
  }
  tabChanged(e: any) {
    this.selectedIndex = e.index;
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((data) => {
      const noteId = data?.['noteId'];
      if (noteId) {
        const notesTab = this.tabGroup._tabs.find((data) => {
          return data.textLabel === 'Notes';
        });
        this.tabGroup.selectedIndex = notesTab?.position;
      }
    });
  }
  addBankStatement(data?: any) {
    this.bankFormArray.push(this.generateBankForm(data));
  }
  removeBankStatement(i: number) {
    this.bankFormArray.at(i).reset();
    if (this.bankFormArray.length > 1) {
      this.bankFormArray.removeAt(i);
    }
  }
  generateBankForm = (data?: { income: number; expense: number; month: number }) => {
    return this.fb.group({
      income: [data?.income, Validators.required],
      expense: [data?.expense, Validators.required],
      month: [data?.month, Validators.required]
    });
  };
  generateInsightForm() {
    return this.fb.group({
      file: [undefined, Validators.required],
      fileName: [undefined],
      mime: 'application/pdf',
      password: [undefined, Validators.required],
      income: [undefined],
      expenses: [undefined],
      loan: [undefined],
      bet: [undefined],
      overdraft: [undefined],
      periods: [undefined],
      _id: [undefined],
      periodicTotals: [undefined],
      personalInfo: [undefined],
      extra: [undefined]
    });
  }
  addAnotherInsight() {
    this.insightsArray.push(this.generateInsightForm());
  }
  generateBankInsightForm() {
    return this.fb.group({
      file: [undefined, Validators.required],
      bankName: [undefined, Validators.required],
      fileValid: [undefined],
      fileName: [undefined],
      mime: 'application/pdf',
      password: [undefined],
      data: [undefined],
      _id: [undefined]
    });
  }
  addAnotherBankInsight() {
    this.bankInsightsArray.push(this.generateBankInsightForm());
  }

  resolveReason(value: any) {
    this.reasonRes(value);
  }

  createSmartlookEvent(action: string, obj?: any) {
    if (environment.production) {
      const smartlookObj = {
        Action: action,
        loanId: this.loan?._id,
        agent: this.currentUser?.personalInformation?.firstName + ' ' + this.currentUser?.personalInformation?.lastName,
        date: moment().format('DD/MM/YYYY') + ' ' + moment().format('hh:mm'),
        ...obj
      };
      console.log(smartlookObj);
      Smartlook.track('Trade desk', {
        ...smartlookObj
      });
    }
  }

  private resetPromise() {
    this.reasonRes = null;
    this.reasonSubmitted = new Promise<any>((resolve) => {
      this.reasonRes = resolve;
    });
  }
}
