import { MatInputModule } from '@angular/material/input';
import {
  AccountNumberPipe,
  BeneficiaryNamePipe,
  ColFilterPipe,
  ColumnPipe,
  ContactNamePipe,
  EntityTypePipe,
  FilterPipe,
  FullnamePipe,
  IssuedForPipe,
  MultiCropsPipe,
  MultiInputsPipe,
  MultiProductsPipe,
  PhoneCodePipe,
  PhoneEmailPipe,
  PlusPhonePipe,
  SanitizePipe,
  StatusPipe,
  StatusesPipe,
  TruncatePipe
} from './pipes';
import {
  AvenewsRatingComponent,
  AvenewsSelectComponent,
  BackAlertModal,
  BaseBreadcrumbsTemplateComponent,
  BaseFormTemplateComponent,
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  BaseNewFormTemplateComponent,
  BlockItem,
  CircleProgressComponent,
  CircleInitialsComponent,
  ExportModalComponent,
  FiltersTemplateComponent,
  InfoViewComponent,
  InlineSpinnerComponent,
  ListComponent,
  ListNoSdkComponent,
  NumberedStepsComponent,
  PasswordModal,
  PhoneInputComponent,
  SheetModalComponent,
  SvgComponent,
  TwoFaModalComponent,
  TwoFaService,
  stepsTrackerComponent,
  ModalComponent,
  NumericProgressComponent
} from './components';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DndDirective } from './directives/drag-drop.directive';
import { ErrorMessagesDirective } from './directives/error-messages.directive';
import { SpinnerToggleService } from './services/spinner-toggle.service';
import { ListOptions } from './helpers/types/list.types';
import { SdkService } from './services/sdk.service';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DownloadCloud,
  Trash,
  Trash2,
  Upload,
  X,
  Check,
  ChevronLeft,
  Calendar,
  Edit2,
  Edit,
  Eye,
  Info,
  AtSign,
  MapPin,
  Search,
  Lock,
  Filter,
  ChevronRight
} from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CurrentUserResolver } from './resolvers/current-user.resolver';
import { BeneficiaryDetailsPipe, NotificationDatePipe, TrendIconPipe } from './pipes';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { BackAlertDirective, PassedDataDirective } from './directives';
import { ShowFieldStylePipe } from './pipes/show-field-style/show-field-style.pipe';
import { ShowAsteriskPipe } from './pipes/show-asterisk/show-asterisk.pipe';
import { HideControlPipe } from './pipes/hide-controls/hide-controls.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FilesService, ModalService } from './services';
import { TimeRemainingPipe } from './pipes/time-remaining/time-remaining.pipe';
import { CardTemplateComponent } from './components/card-template/card-template.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FeatureAnnouncementComponent } from './components/feature-announcement/feature-announcement.component';

const icons = {
  DownloadCloud,
  Trash,
  Upload,
  X,
  Check,
  Trash2,
  ChevronLeft,
  ChevronRight,
  Calendar,
  Edit2,
  Edit,
  Eye,
  Info,
  Search,
  AtSign,
  MapPin,
  Filter,
  Lock
};
@NgModule({
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    MatSortModule,
    NgSelectModule,
    MatMenuModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatTabsModule,
    OverlayModule,
    DragDropModule,
    MatNativeDateModule,
    FeatherModule.pick(icons)
  ],
  declarations: [
    ExportModalComponent,
    FiltersTemplateComponent,
    AvenewsSelectComponent,
    ListComponent,
    AvenewsRatingComponent,
    BaseModal,
    BaseModalBody,
    BaseModalFooter,
    BaseModalHeader,
    // PIPES
    AccountNumberPipe,
    NotificationDatePipe,
    BeneficiaryNamePipe,
    ShowAsteriskPipe,
    ShowFieldStylePipe,
    BaseFormTemplateComponent,
    BaseNewFormTemplateComponent,
    ColFilterPipe,
    ColumnPipe,
    ContactNamePipe,
    EntityTypePipe,
    HideControlPipe,
    StatusesPipe,
    FullnamePipe,
    FilterPipe,
    IssuedForPipe,
    MultiCropsPipe,
    TimeRemainingPipe,
    MultiInputsPipe,
    MultiProductsPipe,
    PhoneCodePipe,
    PhoneEmailPipe,
    PlusPhonePipe,
    TrendIconPipe,
    StatusPipe,
    PhoneInputComponent,
    TruncatePipe,
    SvgComponent,
    SanitizePipe,
    BaseBreadcrumbsTemplateComponent,
    ListNoSdkComponent,
    BeneficiaryDetailsPipe,
    ErrorMessagesDirective,
    DndDirective,
    PassedDataDirective,
    BackAlertDirective,
    SheetModalComponent,
    BackAlertModal,
    NumberedStepsComponent,
    CircleProgressComponent,
    CircleInitialsComponent,
    TwoFaModalComponent,
    InfoViewComponent,
    PasswordModal,
    InlineSpinnerComponent,
    BlockItem,
    CardTemplateComponent,
    stepsTrackerComponent,
    ModalComponent,
    NumericProgressComponent,
    FeatureAnnouncementComponent
  ],
  exports: [
    AccountNumberPipe,
    BeneficiaryNamePipe,
    FiltersTemplateComponent,
    BaseModal,
    BaseModalBody,
    BaseModalFooter,
    BaseModalHeader,
    BackAlertModal,
    ShowFieldStylePipe,
    AvenewsSelectComponent,
    ColFilterPipe,
    ColumnPipe,
    ContactNamePipe,
    EntityTypePipe,
    ShowAsteriskPipe,
    FilterPipe,
    IssuedForPipe,
    MultiCropsPipe,
    MultiInputsPipe,
    ListComponent,
    MultiProductsPipe,
    PhoneCodePipe,
    PhoneEmailPipe,
    BaseFormTemplateComponent,
    BaseNewFormTemplateComponent,
    PlusPhonePipe,
    FullnamePipe,
    PhoneInputComponent,
    StatusPipe,
    TruncatePipe,
    FeatherModule,
    SanitizePipe,
    SvgComponent,
    BaseBreadcrumbsTemplateComponent,
    ListNoSdkComponent,
    BeneficiaryDetailsPipe,
    ErrorMessagesDirective,
    TrendIconPipe,
    DndDirective,
    PassedDataDirective,
    BackAlertDirective,
    SheetModalComponent,
    NotificationDatePipe,
    HideControlPipe,
    AvenewsRatingComponent,
    NumberedStepsComponent,
    StatusesPipe,
    TimeRemainingPipe,
    CircleProgressComponent,
    CircleInitialsComponent,
    TwoFaModalComponent,
    InfoViewComponent,
    PasswordModal,
    InlineSpinnerComponent,
    BlockItem,
    CardTemplateComponent,
    stepsTrackerComponent,
    ModalComponent,
    NumericProgressComponent,
    FeatureAnnouncementComponent
  ],
  providers: [
    SdkService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    CurrentUserResolver,
    ListOptions,
    DatePipe,
    DecimalPipe,
    TwoFaService,
    TitleCasePipe,
    FilesService,
    ModalService,
    SpinnerToggleService
  ]
})
export class SharedModule {
  public static forRoot(config: any): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: 'env',
          useValue: config?.env
        }
      ]
    };
  }
}
