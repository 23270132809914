<base-modal>
  <base-modal-header header="Disbursment Prediction"></base-modal-header>
  <base-modal-body>
    <div class="form-pair flex">
      <app-base-form-template
        labelName="Disbursment Amount"
        inputType="number"
        inputName="default"
        controlName="disbursmentAmount"
        [group]="form"
        [size]="6"
      >
      </app-base-form-template>
      <app-base-form-template
        labelName="Repayment Amount"
        inputType="number"
        inputName="default"
        controlName="repaymentAmount"
        [group]="form"
        [size]="6"
      >
      </app-base-form-template>
    </div>
    <div class="form-pair flex">
      <app-base-form-template labelName="Age" inputType="number" inputName="default" controlName="age" [group]="form" [size]="6">
      </app-base-form-template>
      <app-base-form-template
        labelName="Payment Terms"
        inputType="number"
        inputName="default"
        controlName="paymentTerm"
        [group]="form"
        [size]="6"
      >
      </app-base-form-template>
    </div>
    <div class="form-pair flex">
      <app-base-form-template
        inputName="select"
        inputType="select"
        [options]="monthOptions"
        bindValue="value"
        bindLabel="viewValue"
        labelName="Disbursment Month"
        controlName="disbursmentMonth"
        [group]="form"
        [size]="6"
      >
      </app-base-form-template>
      <app-base-form-template
        labelName="Repayment Month"
        inputName="select"
        inputType="select"
        [options]="monthOptions"
        bindValue="value"
        bindLabel="viewValue"
        controlName="repaymentMonth"
        [group]="form"
        [size]="6"
      >
      </app-base-form-template>
    </div>
  </base-modal-body>
  <base-modal-footer>
    <button (click)="close()" class="bttn btn-actions">Close</button>
    <button (click)="submit()" class="bttn btn-primary !text-white">Submit</button>
  </base-modal-footer>
</base-modal>
