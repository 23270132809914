<div class="modal-box" id="{{ exportId }}">
  <div class="modal-box-content-wrapper">
    <div class="modal-box-content">
      <div class="modal-box-heading">
        <h1>
          Export
          <i class="material-icons pull-right" (click)="closeExportModal()" #closeModal>close</i>
        </h1>
      </div>
      <div class="modal-box-center">
        <div class="d-flex flex-column" [formGroup]="exportForm">
          <div class="columns-wrapper">
            <p class="total-records">
              Records ready to export:
              <span class="records">{{ totalCount | number: '1.0-2' }} Records</span>
            </p>
            <p class="choose-cols">Choose which columns you want to export</p>
            <div class="checkboxes-wrapper">
              <div [formGroup]="checkAllForm" class="select-all">
                <input formControlName="yes" type="checkbox" class="form_radio" id="check-all-groups" name="contact" />
                <label for="check-all-groups" class="form_radio_label check-all"> <span class="form_checkbox_btn"></span></label>
                <p>SELECT ALL COLUMNS</p>
              </div>
              <app-base-form-template
                inputType="checkbox"
                [checkboxes]="columnsCheckbox"
                inputName="checkbox"
                [size]="12"
                [group]="exportForm"
              ></app-base-form-template>
            </div>
          </div>
          <div class="export-buttons">
            <div>
              <button (click)="cancel()" class="cancel-button">Cancel</button>
              <button (click)="export()" class="export-button">Export</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="popup-wrapper" id="{{ exportId + 'success' }}">
  <div class="popup-content-wrapper">
    <div class="popup-content">
      <div class="container-1 tab-content p-3">
        <div class="dialog">
          <div class="sa">
            <div class="icon"></div>
            <div class="check"></div>
          </div>
          <div class="title">Records successfully exported</div>
          <div class="sub-title">You will receive the exported file by email shortly.</div>
        </div>
        <div class="modal-delete-footer">
          <button class="btn-success-export" (click)="submitExport()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
