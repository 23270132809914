import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { User } from '@avenews/agt-sdk';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return (this.http.get(`${environment.new_api_url}/api/user/${id}`) as Observable<User>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
