<div>
  <div class="docs-section">
    <div class="uploaded-documents" [ngClass]="{ 'justify-center': !documents?.length }">
      <div class="state empty-state" *ngIf="!documents?.length; else fullSate">
        <img src="../../../assets/images/svgs/Group 77.svg" />
        <h1>No documents added yet</h1>
        <div class="header">
          <input name="file" class="input-file" (change)="uploadDocs($event)" #fileUpload type="file" />
          <label (click)="fileUpload.click()" class="upload">Upload</label>
        </div>
      </div>
      <ng-template #fullSate>
        <div class="header">
          <input name="file" class="input-file" (change)="uploadDocs($event)" #fileUpload type="file" />
          <label (click)="fileUpload.click()" class="upload">Upload</label>
        </div>
        <div *ngFor="let document of documents; let i = index" class="doc-card }}">
          <div class="view">
            <div class="file-image-wrapper">
              <img src="../../../assets/images/svgs/file-1.svg" />
            </div>
            <p #tooltip="matTooltip" matTooltip="{{ document?.fileName }}.{{ document?.type }}" matTooltipPosition="above" class="t-bold">
              {{ document?.fileName }}.{{ document?.type }}
            </p>
          </div>
          <div class="doc-footer">
            <p class="t-light-gray">{{ document.dateCreated | date : 'dd/MM/yyyy' }}</p>
            <div class="action-btns">
              <img src="../../../assets/images/svgs/trash.svg" (click)="removeDoc(document._id, document.fileName)" />
              <a (click)="openFile(document?._id)" target="_blank"> <img src="../../../assets/images/svgs/download.svg" /></a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
