import { PaginationInfo } from '@avenews/agt-sdk';

export const pag: PaginationInfo = {
  pageSize: 10000,
  pageNumber: 1,
  sortBy: 'numericId',
  sortOrder: 'desc'
};
export const pagination: PaginationInfo = {
  pageSize: 10,
  pageNumber: 1,
  sortBy: '_id',
  sortOrder: 'desc'
};
