<ng-template
  [cdkConnectedOverlayPanelClass]="['some-class']"
  cdkConnectedOverlayBackdropClass="some-backdrop"
  cdkConnectedOverlayHasBackdrop
  (attach)="open()"
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
  ><div class="modal-box" id="addPeriodWizard">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-heading">
        <p>{{ isEdit ? 'Edit' : 'New' }} Period</p>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <div class="modal-box-content">
        <p class="content-header">
          {{ user?.personalInformation?.firstName + ' ' + user?.personalInformation?.lastName }} - {{ deal?.name || period?.deal?.name }} -
          {{ period?.['name'] || 'New Period' }}
        </p>
        <div (keyup.enter)="submit()" [formGroup]="periodForm" class="general-form">
          <div class="form-pair">
            <app-base-form-template
              [errorMessages]="errorMessages"
              labelName="Period Duration"
              bindLabel="viewValue"
              bindValue="value"
              inputType="number"
              inputName="default"
              controlName="duration"
              [noSearch]="true"
              [group]="periodForm"
              [size]="6"
              [min]="0"
              [rOnly]="periodForm.get('repaymentType')?.value === 'multiFixed' ? true : false"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Fee multiplier"
              inputType="text"
              inputName="default"
              controlName="feeMultiplier"
              [group]="periodForm"
              [size]="6"
            >
            </app-base-form-template>
          </div>
          <div class="form-pair">
            <app-base-form-template
              [errorMessages]="errorMessages"
              labelName="Min disbursement"
              inputType="number"
              inputName="default"
              controlName="minDisbursement"
              [group]="periodForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>

            <app-base-form-template
              [errorMessages]="errorMessages"
              labelName="Max disbursement"
              inputName="default"
              controlName="maxDisbursement"
              inputType="number"
              [group]="periodForm"
              [size]="6"
            >
              <span #append append>KES</span>
            </app-base-form-template>
          </div>
        </div>
        <div *ngIf="deal.repaymentInfo?.repaymentMode === 'multiFixed'" class="multi-fixed-section">
          <p class="content-header">Repayments</p>
          <div class="multi-repaymnets">
            <div class="flex">
              <app-base-form-template
                labelName="Repayment type"
                bindLabel="viewValue"
                bindValue="value"
                inputType="select"
                inputName="select"
                controlName="repaymentType"
                [options]="repaymentType"
                [noSearch]="true"
                [group]="periodForm"
                [size]="6"
              >
              </app-base-form-template>
              <div *ngIf="periodForm.get('repaymentType')?.value === 'multiFixed'" class="max-repayment">
                <app-base-form-template
                  [errorMessages]="errorMessages"
                  labelName="Max repayment amount"
                  inputType="number"
                  inputName="default"
                  controlName="maxRepaymentAmount"
                  [group]="periodForm"
                  [size]="15"
                  [rOnly]="true"
                >
                  <span append #append>KES</span>
                </app-base-form-template>
              </div>
            </div>
            <div class="multi-repayments" *ngIf="periodForm.get('repaymentType')?.value === 'multiFixed'">
              <div *ngFor="let item of multipleRepaymentsArray.controls; let prodIndex = index" class="row-form">
                <div class="num flex">#{{ prodIndex + 1 }}</div>
                <app-base-form-template
                  labelName="Days from start"
                  inputType="number"
                  inputName="default"
                  controlName="daysFromStart"
                  [group]="$any(item)"
                  [size]="4"
                  [strictMode]="false"
                  [isDisabled]="true"
                  [rOnly]="true"
                >
                  <span append #append>KES</span>
                </app-base-form-template>
                <app-base-form-template
                  labelName="Amount to repay"
                  inputType="number"
                  inputName="default"
                  controlName="amount"
                  [group]="$any(item)"
                  [size]="4"
                  [strictMode]="false"
                  [isDisabled]="true"
                  [rOnly]="true"
                >
                  <span append #append>KES</span>
                </app-base-form-template>
                <app-base-form-template
                  labelName="In percentages"
                  inputType="number"
                  inputName="default"
                  controlName="percentage"
                  [group]="$any(item)"
                  [size]="3"
                  [strictMode]="false"
                  [isDisabled]="true"
                  [isDisabled]="true"
                  [rOnly]="true"
                >
                  <span append #append class="percentage">%</span>
                </app-base-form-template>
                <div class="flex lighten">
                  <img src="../../../assets/images/svgs/red-trash.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="submit()">{{ isEdit ? 'Update' : 'Create' }}</button>
      </div>
    </div>
  </div>
</ng-template>
