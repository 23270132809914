<ng-container *ngIf="user?.policyRole?.name?.includes('agent')">
  <div
    [ngClass]="{ fade: !mlScoreData?.score, click: mlScoreData?.score }"
    (click)="openMlModal()"
    class="category"
    #tooltip="matTooltip"
    [matTooltip]="'Fill in the ML model in order to use this'"
    [matTooltipDisabled]="!!mlScoreData?.score"
  >
    <div class="category-header">
      <div class="vertical-header">
        <p>Disbursment ML</p>
      </div>
      <div *ngIf="mlScoreData?.disbursementMl?.prediction" class="score-holder end-score ml-cat">
        <p>{{ 1 }}/{{ 1 }}</p>
        <ng-template #spinner>
          <img #tooltip="matTooltip" matTooltip="Calculating" class="spinna" src="../../../assets/loading.gif" alt="" />
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="user?.policyRole?.name?.includes('admin')">
  <mat-accordion [ngClass]="{ fade: !mlScoreData?.score }">
    <mat-expansion-panel
      #tooltip="matTooltip"
      [matTooltip]="'Fill in the ML model in order to use this'"
      [matTooltipDisabled]="mlScoreData?.score !== 0"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [disabled]="true"
      hideToggle
      #panel
      class="cursor-default category !p-0 !h-auto"
      id="panel"
    >
      <mat-expansion-panel-header class="pp !mr-0 cursor-default">
        <mat-panel-title class="!mr-0">
          <div class="w-full flex items-center justify-between">
            <div class="big-text">Disbursment ML</div>
            <div class="flex items-center icon-holder">
              <div *ngIf="mlScoreData?.disbursementMl?.prediction" class="mr-3 cursor-pointer" (click)="panel.toggle()">
                <i-feather [@rotate]="panelOpenState.toString()" name="chevron-down"></i-feather>
              </div>
              <div *ngIf="mlScoreData?.score" class="cursor-pointer" (click)="openMlModal()">
                <i-feather name="arrow-up-right"></i-feather>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="disbs flex justify-between" *ngFor="let prediction of mlScoreData?.disbursementMl?.prediction; let i = index">
        <div class="text-primary font-medium">Disbursment {{ i + 1 }}</div>
        <div class="text-avenews">{{ prediction * 100 | number: '1.0-2' }}%</div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
