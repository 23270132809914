import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResetPasswordByTokenDTO } from '@avenews/agt-sdk';
import { SdkService, SpinnerToggleService } from '@avenews/shared-lib';
import { LoginConfig } from '../../../auth-config';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';
import { ErrorCodes } from '@avenews/base-types';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordType = 'password';
  formInput: FormGroup;
  passwordChanged = false;
  resetToken: string;
  showPass = false;
  passwordForm: FormGroup;
  successPath = '/avenews-pay/success-page';
  constructor(
    private sdkService: SdkService,
    private spinner: SpinnerToggleService,
    private alertService: AlertService,
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) public document: Document,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    @Inject('loginConfig') public loginConfig: LoginConfig
  ) {}
  ngOnInit() {
    this.resetToken = this.route.snapshot.params['token'];
    if(this.route.snapshot.data['successPath']){

      this.successPath = this.route.snapshot.data['successPath']
    }
    console.log(this.successPath);
    this.passwordForm = this.formBuilder.group(
      {
        newPassword: [undefined, [Validators.required, Validators.minLength(8)]],
        confirmPassword: [undefined, [Validators.required, Validators.minLength(8)]]
      },
      { validator: this.equalPasswordsValidator }
    );
    this.formInput = this.passwordForm;
  }

  equalPasswordsValidator(group: FormGroup) {
    if (group.value.newPassword !== group.value.confirmPassword) {
      return { passwordsNotMatch: true };
    }
    return null;
  }
  notMin() {
    return this.passwordForm.get('newPassword').errors && this.passwordForm.get('newPassword').errors?.['minlength'];
  }
  showPassword() {
    if (this.showPass === false) {
      this.showPass = true;
    } else {
      this.showPass = false;
    }
  }

  passwordsNotMatch() {
    return (
      this.passwordForm.get('confirmPassword').touched &&
      this.passwordForm.get('newPassword').value !== this.passwordForm.get('confirmPassword').value
    );
  }

  submit() {
    this.onSubmit(this.passwordForm);
    if (this.passwordForm.valid) {
      this.spinner.showSpinner();
      const reset: ResetPasswordByTokenDTO = {
        token: this.resetToken,
        newPwd: this.passwordForm.get('newPassword').value
      };

      this.http
        .post(`${this.loginConfig.environment.new_api_url}/api/user/reset-password/token`, reset)
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            if (err.errorCode === ErrorCodes.EXPIRED_TOKEN || err.errorCode === ErrorCodes.WRONG_TOKEN) {
              this.alertService.showAlert(`Your password reset link has expired`, 'danger');
            } else this.alertService.showAlert(`Something went wrong`, 'danger');
            this.spinner.hideSpinner();
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.router.navigate([this.successPath]);
        });
    }
  }

  isFieldInvalid(field: string, errorType?: string) {
    if (field === 'password' && errorType === 'minlength') {
      return this.formInput.get(field).hasError('minlength') && this.formInput.get(field).touched;
    } else if (field === 'password' && errorType === 'required') {
      return this.formInput.get(field).hasError('required') && this.formInput.get(field).touched;
    } else {
      return this.formInput.get(field).invalid && this.formInput.get(field).touched;
    }
  }

  showFieldStyle(field: string) {
    return {
      'has-error': this.isFieldInvalid(field)
    };
  }

  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }
}
