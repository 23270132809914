import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AdminHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = '';
    if (!request.headers.get('Authorization')) {
      try {
        token = JSON.parse(localStorage.getItem('credentials')).token;
      } catch (err) {
        console.error(err);
        this.router.navigate(['/login']);
      }
      const tokenedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(tokenedRequest);
    } else {
      return next.handle(request);
    }
  }
}
