<div class="router-outlet">
  <router-outlet></router-outlet>
</div>
<div *ngIf="showSpinner" class="avenews-animation">
  <img src="../assets/loading.gif" alt="" />
  <ng-container></ng-container>
  <p class="spinner-title">
    {{ spinnerMessage?.title }}
  </p>
  <p class="spinner-subtitle">{{ spinnerMessage?.subtitle }}</p>
</div>
