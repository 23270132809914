import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiProduct'
})
export class MultiProductsPipe implements PipeTransform {
  transform(products: any[], tooltip?: boolean) {
    if (tooltip) {
      return this.tooltip(products);
    }
    return this.column(products);
  }
  tooltip(products: any[]) {
    let res = '';
    products.forEach((data) => {
      res += `${data.name} - ${data.quantity} ${data.measurement}\n`;
    });
    return res;
  }
  column(products: any[]) {
    const length = products.length;
    if (length) {
      if (length === 1) {
        return products[0].name;
      } else {
        return 'Multiple';
      }
    } else {
      return '-';
    }
  }
}
