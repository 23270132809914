import { MatTooltipModule } from '@angular/material/tooltip';
import { ChangePasswordModal } from './components/change-password/change-password.component';
import { KycViewComponent } from './components/kyc-view/kyc-view.component';
import { KycCardComponent } from './components/kyc-card/kyc-card.component';
import { OrgsResolver } from './resolvers/orgs.resolver';
import { TopdownComponent } from './components/topdown/topdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopupResolver } from './resolvers/topups.resolver';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersResolver } from './resolvers/users.resolver';
import { AccountsByBeneficiaryResolver } from './resolvers/accounts-by-beneficiary.resolver';
import { TransactionByIdResolver } from './resolvers/transaction-by-id.resolver';
import { TopupComponent } from './components/topup/topup.component';
import { SharedModule } from '@avenews/shared-lib';
import { FeatherModule } from 'angular-feather';
import { HttpClientModule } from '@angular/common/http';
import { ActivateComponent } from './components/activate/activate.component';
import { CashoutLimitationComponent } from './components/cashout-limitation/cashout-limitation.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { TransactionStatusComponent } from './components/transaction-status/transaction-status.component';
import { BaseSideViewComponent } from './components/base-side-view/base-side-view.component';
import { ChevronLeft, Search, Percent } from 'angular-feather/icons';
import { UserResolver } from './resolvers/user.resolver';
import { CreateDealComponent } from './components/create-deal/create-deal.component';
import { FadeSidebarDirective } from './directives/fade-sidebar.directive';
import { MatNativeDateModule } from '@angular/material/core';
import { NewPeriodComponent } from './components/new-period/new-period.component';
import { ChangeStatusComponent } from './components/change-status/change-status.component';
import { ChangeInvoiceStatusComponent } from './components/change-invoice-status/change-invoice-status.component';
import { LinkUserModalComponent } from './components/link-user-modal/link-user-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DocumentsResolver } from '../finance/resolvers/documents.resolver';
import { AgriTopupComponent } from './components/agri-topup/agri-topup.component';
import { ChangeApplicationStatusComponent } from './components/change-application-status/change-application-status/change-application-status.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { DeclineModalComponent } from './components/decline-modal/decline-modal.component';
import { ChangeOrderStatusComponent } from './components/change-order-status/change-order-status.component';
import { CashBackPopupComponent } from './components/cashback-popup/cashback-popup.component';
import { UserApproveTopupComponent } from './components/user-approve-topup/user-approve-topup.component';
import { DeclineUserTopupComponent } from './components/decline-user-topup/decline-user-topup.component';
import { RepaymentModal } from './components/repayment-modal/repayment-modal.component';
import { ChangeDealStatusComponent } from './components/change-deal-status/change-deal-status.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ChangePeriodStatusComponent } from './components/change-period-status/change-period-status.component';
import { UserApproveRepaymentComponent } from './components/user-approve-repayment/user-approve-repayment.component';
import { DeclineUserRepaymentComponent } from './components/decline-user-repayment/decline-user-repayment.component';
import { QuestionGeneratorComponent } from './components/question-generator/question-generator.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { LinkUserToDealModalComponent } from './components/link-user-to-deal-modal/link-user-to-deal-modal.component';
import { LoanDetailsComponent } from './components/add-loan-details/add-loan-details.component';
import { LoanOfferComponent } from './components/loan-offer/loan-offer.component';
import { UiLibraryModule } from '@avenews/ui-library';
import { AvenewsUserComponent } from './components/add-avenews-user/add-avenews-user.component';
import { ContenteditableValueAccessor } from './directives/editable-control.directive';
import { ReasonModalComponent } from './components/reason-modal/reason-modal.component';
import { AvenewsAgentComponent } from './components/add-avenews-agent/add-avenews-agent.component';
import { DisbMlModal } from './components/disb-ml-modal/disb-ml-modal';
import { DealNamePipe } from './pipes/deal-name.pipe';
import { ChangeDistributorStatComponent } from './components/change-distributor-status/change-distributor-status.component';
import { BnplStatusComponent } from './components/bnpl-status/bnpl-status.component';
import { DistributorDocumentsStatusComponent } from './components/distributor-documents-status/distributor-documents-status.component';
import { RoundDownNumePipe } from './pipes/round-down-num.pipe';
import { WithdrawModal } from './components/withdraw-modal/withdraw-modal.component';
import { OrgByIdResolver } from './resolvers/org-by-id.resolver';
import { DocumentsListModal } from './components/documents-list-modal/documents-list-modal';
const icons = {
  ChevronLeft,
  Search,
  Percent
};
@NgModule({
  declarations: [
    TopupComponent,
    RepaymentModal,
    UserApproveRepaymentComponent,
    TopdownComponent,
    CreateUserComponent,
    TransactionStatusComponent,
    CashoutLimitationComponent,
    ActivateComponent,
    ChangeDealStatusComponent,
    CreateDealComponent,
    BaseSideViewComponent,
    DeclineUserRepaymentComponent,
    FadeSidebarDirective,
    ChangePasswordModal,
    NewPeriodComponent,
    ChangeStatusComponent,
    LinkUserToDealModalComponent,
    LinkUserModalComponent,
    ChangeInvoiceStatusComponent,
    AgriTopupComponent,
    ChangeApplicationStatusComponent,
    DeleteModalComponent,
    DeclineModalComponent,
    ChangeOrderStatusComponent,
    LoanDetailsComponent,
    LoanOfferComponent,
    ChangePeriodStatusComponent,
    AvenewsUserComponent,
    CashBackPopupComponent,
    ContenteditableValueAccessor,
    UserApproveTopupComponent,
    KycCardComponent,
    KycViewComponent,
    ReasonModalComponent,
    DeclineUserTopupComponent,
    QuestionGeneratorComponent,
    DisbMlModal,
    AvenewsAgentComponent,
    DealNamePipe,
    ChangeDistributorStatComponent,
    BnplStatusComponent,
    DistributorDocumentsStatusComponent,
    RoundDownNumePipe,
    WithdrawModal,
    DocumentsListModal
  ],
  imports: [
    NgxPaginationModule,
    CommonModule,
    UiLibraryModule,
    MatNativeDateModule,
    TextFieldModule,
    SharedModule,
    OverlayModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    FeatherModule.pick(icons)
  ],
  exports: [
    TopupComponent,
    RepaymentModal,
    TopdownComponent,
    ContenteditableValueAccessor,
    ChangeDealStatusComponent,
    CreateUserComponent,
    ChangePeriodStatusComponent,
    CashoutLimitationComponent,
    TransactionStatusComponent,
    ActivateComponent,
    BaseSideViewComponent,
    LinkUserToDealModalComponent,
    CreateDealComponent,
    FadeSidebarDirective,
    NewPeriodComponent,
    ChangeOrderStatusComponent,
    LoanOfferComponent,
    ChangeInvoiceStatusComponent,
    UserApproveRepaymentComponent,
    DeclineUserRepaymentComponent,
    LinkUserModalComponent,
    AgriTopupComponent,
    ChangeStatusComponent,
    DeleteModalComponent,
    LoanDetailsComponent,
    ChangeApplicationStatusComponent,
    DeclineModalComponent,
    CashBackPopupComponent,
    UserApproveTopupComponent,
    ReasonModalComponent,
    KycCardComponent,
    KycViewComponent,
    DeclineUserTopupComponent,
    QuestionGeneratorComponent,
    AvenewsUserComponent,
    AvenewsAgentComponent,
    DealNamePipe,
    ChangeDistributorStatComponent,
    BnplStatusComponent,
    DistributorDocumentsStatusComponent,
    RoundDownNumePipe,
    WithdrawModal,
    DocumentsListModal
  ],
  providers: [
    UsersResolver,
    OrgsResolver,
    OrgByIdResolver,
    UserResolver,
    DocumentsResolver,
    TransactionByIdResolver,
    AccountsByBeneficiaryResolver,
    TopupResolver
  ]
})
export class AdminSharedModule {}
