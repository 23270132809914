import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SdkService } from '../../services/sdk.service';
// tslint:disable: component-selector
import { Action, ListConfig, ListOptions } from '../../helpers/types/list.types';
import { Component, Injector, ContentChild, TemplateRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { defaultValues } from '../../helpers/constants/default_values';
import { Filter, FilterOptions, PaginatedResult, PaginationInfo } from '@avenews/agt-sdk';
import { SpinnerToggleService } from '../../services/spinner-toggle.service';
import { ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { ExportInfo, QueryMode } from '@avenews/base-types';
declare const $: any;
interface OrFilters {
  dtoName: string;
  type?: string;
}
@Component({
  selector: 'agt-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent<T> implements OnChanges {
  @ContentChild('emptyState')
  emptyStateTemplateRef?: TemplateRef<any>;
  @ContentChild('column')
  colTemplateRef?: TemplateRef<any>;
  @ContentChild('columnValue')
  colValueTemplateRef?: TemplateRef<any>;
  @Input() currentPage = 1;
  @Input() itemsPerPage = defaultValues.items_per_page;
  @Input() listConfig!: ListConfig;
  @Input() list!: T[];
  @Input() totalCount!: number;
  @Input() originalTotalCount = 0;
  @Input() listId?: string;
  @Input() exportId?: string;
  @Input() maintitleEmptyState = true;
  @Input() options: PaginationInfo = {
    pageNumber: this.currentPage,
    pageSize: this.itemsPerPage,
    sortBy: '_id',
    sortOrder: 'desc'
  };
  filters: OrFilters[] = [];
  queryMode = undefined;
  recordsOptions = [10, 50, 100];
  @Output() menuActions = new EventEmitter();
  @Output() bulkActions = new EventEmitter();
  @Output() addButton = new EventEmitter();
  @Output() bulkAddButton = new EventEmitter();
  @Output() changeP = new EventEmitter();
  @Output() changeItemsPerPage = new EventEmitter();
  @Output() sort = new EventEmitter();
  @Output() sendFilters = new EventEmitter();
  @Output() paginated = new EventEmitter();
  colFilter: any;
  unsortedList: T[];
  recordsPerPageForm!: FormGroup;
  filterOptions: any[] = [];
  id?: string;
  searchTerm = '';
  optionsArr: any[] = [];
  searchDelay: any;
  isMulti!: boolean;
  stickyFilters: Filter[] = [];
  secondOption: any;
  that!: ListComponent<T>;
  resolverName!: string;
  thirdOption: any;
  customiseForm: FormGroup;
  showAlert?: boolean;
  public spinner!: SpinnerToggleService;
  public sdk: SdkService;
  public financeSdk: SdkService; // will change it once we have a real finance sdk
  public fb: FormBuilder;
  private routee: ActivatedRoute;
  constructor(protected injector: Injector, private listOptions: ListOptions) {
    this.spinner = this.injector.get(SpinnerToggleService);
    this.sdk = injector.get(SdkService);
    this.financeSdk = injector.get(SdkService);
    this.routee = injector.get(ActivatedRoute);
    this.fb = injector.get(FormBuilder);
    this.customiseForm = this.fb.group({});
    this.recordsPerPageForm = this.fb.group({
      recordsPerPage: [10]
    });
  }

  paginateV2(page?: number): Promise<void> {
    return new Promise((res) => {
      if (!this.listOptions.noPagination) {
        this.spinner.showSpinner();
        this.options.pageNumber = page || 1;
        const opts = [];
        if (this.id) {
          opts.push(this.id);
        }
        const filteredFilters = this.filterOptions.slice();
        filteredFilters.push(...this.stickyFilters);
        opts.push({
          pagination: this.options,
          filters: filteredFilters.length ? filteredFilters : undefined,
          queryMode: this.queryMode
        });

        (this.sdk as any)[this.listOptions.name](...opts).then((data: PaginatedResult<T>) => {
          this.getData(data);
          this.currentPage = page!;
          this.spinner.hideSpinner();
          this.paginated.emit();
          res();
        });
      } else {
        this.currentPage = page || 1;
        this.options.pageNumber = this.currentPage;
        this.paginated.emit();
        this.spinner.hideSpinner();
        res();
      }
    });
  }

  init() {
    this.routee.data.subscribe((data) => {
      this.currentPage = 1;
      this.itemsPerPage = defaultValues.items_per_page;
      this.options.pageSize = defaultValues.items_per_page;
      this.getData(data[this.resolverName]);
      this.unsortedList = this.list?.slice();
      this.originalTotalCount = this.totalCount;
      if (data['filters']) {
        this.stickyFilters = data['filters'];
      }
      // this.recordsOptions = [];
      // if (this.totalCount >= 10) {
      //   this.recordsOptions.push(10);
      // } else {
      //   this.recordsOptions = [this.totalCount];
      // }
      // if (this.totalCount >= 50) {
      //   this.recordsOptions.push(50);
      // }
      // if (this.totalCount >= 100) {
      //   this.recordsOptions.push(100);
      // }

      this.recordsPerPageForm.get('recordsPerPage')!.setValue(defaultValues.items_per_page);
    });
  }
  openExportModal() {
    $('#' + this.exportId).css({ display: 'flex' });
  }
  ngOnChanges(changes: any): void {
    if (changes['listConfig'] && this.listConfig) {
      this.customiseForm = this.fb.group({});
      this.listConfig.columns.forEach((col) => {
        this.customiseForm.addControl(
          this.fixString(col.name),
          new FormControl({
            value: col.selected,
            disabled: col.sticky
          })
        );
      });
      this.onCustomiseValueChange();
      this.customiseForm.valueChanges.subscribe(() => {
        this.onCustomiseValueChange();
      });
    }
  }
  changeRecordsPerPage(page?: number) {
    this.changeItemsPerPage.emit(this.recordsPerPageForm.get('recordsPerPage')!.value || 10);
  }
  onCustomiseValueChange() {
    const data = this.customiseForm.getRawValue();

    const keys = Object.keys(data);

    const filtered = keys.filter((key) => {
      return data[key];
    });
    this.colFilter = filtered;
  }
  fixString(str: string) {
    return str.replace(/ /g, '');
  }
  getData(res: any) {
    this.list = res.entities;
    this.totalCount = res.totalCount;
    this.paginated.emit();
  }

  pageChanged(page: number) {
    this.options.pageNumber = page || 1;
    this.currentPage = page || 1;
    this.paginateV2(page);
  }
  changedPage(page: number) {
    this.changeP.emit(page);
  }
  sortEmit(sort: Sort) {
    this.sort.emit(sort);
  }
  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.options.sortOrder = 'desc';
      this.options.sortBy = '_id';
      if (this.listOptions.noPagination) {
        this.list = this.unsortedList.slice();
      } else {
        this.pageChanged(this.currentPage);
      }
      return;
    } else {
      this.options.sortOrder = sort.direction;
      this.options.sortBy = sort.active;
      if (this.listOptions.noPagination) {
        // this.list = this.unsortedList.slice();
        this.list = this.list.sort((a, b) => {
          if (this.getValue(a, sort?.active) < this.getValue(b, sort?.active)) {
            return sort.direction === 'asc' ? -1 : 1;
          }
          if (this.getValue(a, sort?.active) > this.getValue(b, sort?.active)) {
            return sort.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        this.paginateV2(1);
      }
    }
  }
  getValue(col: any, dtoName: string) {
    if (dtoName.includes('.')) {
      const splitted = dtoName.split('.');
      let res: any = col;
      for (let i = 0; i < splitted.length; i++) {
        if (res && res[splitted[i]]) {
          res = res[splitted[i]];
        } else {
          return undefined;
        }
      }
      return (res != null && res) ?? undefined;
    }
    if (col[dtoName] != null) {
      return col[dtoName];
    }
    return undefined;
  }
  search(input: string) {
    this.filterOptions = [];
    if (input && this.filters) {
      this.filters.forEach((data) => {
        if (data.type === 'number' && isNaN(+input)) return;
        this.filterOptions.push({
          column: data.dtoName,
          option: data?.type === 'number' ? FilterOptions.EQUAL : 'contains',
          value: data.type === 'number' ? +input : input
        });
      });
    }
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.pageChanged(1);
    }, 600);
  }
  get listStatus(): 'normal' | 'empty-state' | 'empty-search' {
    if (this.list) {
      if (!this.list.length && this.originalTotalCount) {
        return 'empty-search';
      }
      if (!this.list.length && !this.originalTotalCount) {
        return 'empty-state';
      }
    } else {
      return 'empty-state';
    }
    return 'normal';
  }
  listAction(action: Action | any, entity?: T) {
    this.menuActions.emit({ action, entity });
  }
  bulkAction(action: Action) {
    this.bulkActions.emit({ action });
  }

  disableCol(name: string) {
    if (this.listConfig) {
      return this.listConfig.fixedColumns.includes(name);
    }
    return false;
  }
  filterValues(e: any) {
    this.sendFilters.emit(e);
  }
  export(e: any) {
    const exportInfo: ExportInfo = {
      columns: [],
      ids: e.ids ? e.ids : undefined
    };

    this.listConfig.columns.forEach((data) => {
      if (e[this.fixString(data.name)]) {
        exportInfo.columns.push({ name: data.dtoName, showingName: data.name });
      }
    });
    this.bulkActions.emit({ action: 'export', entity: exportInfo });
  }
  exportAll(e?: any) {
    // export All
    this.spinner.showSpinner();
    (this.sdk as any)
      [this.listOptions.exportMethod](e)
      .then(() => {
        this.spinner.hideSpinner();
        $('#' + this.exportId).fadeOut('fast', () => {
          $('#' + this.exportId + 'success').css({ display: 'flex' });
        });
      })
      .catch((err: any) => {
        this.spinner.hideSpinner();
        // this.alerts.showAlertDanger(err.message);
      });
  }
  noPagFilter(filter: { column: string; option: string; value: any }[]) {
    this.list = this.unsortedList.filter((item) => {
      let res = true;
      filter.forEach((f) => {
        if (!this.getValue(item, f.column)) {
          res = false;
        } else {
          if (f.option === FilterOptions.EQUAL) {
            if ((this.getValue(item, f.column)?.toLowerCase?.() || this.getValue(item, f.column)) !== f.value) {
              res = false;
            }
          } else if (f.option === FilterOptions.CONTAINS) {
            if (this.getValue(item, f.column) && !this.getValue(item, f.column)?.toLowerCase()?.includes(f.value?.toLowerCase())) {
              res = false;
            }
          } else if (f.option === 'not-contains') {
            if (this.getValue(item, f.column) && this.getValue(item, f.column).includes(f.value)) {
              res = false;
            }
          } else if (f.option === FilterOptions.NOT_EQUAL) {
            if (this.getValue(item, f.column) === f.value) {
              res = false;
            }
          } else if (f.option === FilterOptions.GREATER_THAN) {
            if (this.getValue(item, f.column) <= f.value) {
              res = false;
            }
          } else if (f.option === FilterOptions.LOWER_THAN) {
            if (this.getValue(item, f.column) >= f.value) {
              res = false;
            }
          }
        }
      });
      return res;
    });
    this.totalCount = this.list.length;
  }
}
