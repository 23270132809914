import { Component, Input } from '@angular/core';

@Component({
  selector: 'numeric-progress',
  templateUrl: './numeric-progress.component.html',
  styleUrls: ['./numeric-progress.component.scss']
})
export class NumericProgressComponent {
  @Input() steps: string[];
}
