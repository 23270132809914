<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Loan files'"></av-breadcrumbs-template>
<mat-tab-group [disableRipple]="true" id="user-view-tabs">
  <mat-tab color="#152C5B" label="Expires soon">
    <frontend-expire-soon> </frontend-expire-soon>
  </mat-tab>
  <mat-tab color="#152C5B" label="Expired files">
    <frontend-expired-files></frontend-expired-files>
  </mat-tab>
  <mat-tab color="#152C5B" label="Archived files">
    <frontend-archived-files></frontend-archived-files>
  </mat-tab>
</mat-tab-group>
