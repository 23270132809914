import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'av-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() id: string;
  @Input() fileName: string;
  @Input() loanId: string;
  @Input() path: string;
  @Input() url: string;
  @Output() answer = new EventEmitter();
  environment = environment;
  urlCont: string;

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private spinner: SpinnerToggleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.urlCont =
      this.path === 'agribusiness' ? `${this.path}/${this.loanId}/necessary-document/${this.id}` : `${this.path}/file/${this.id}`;
  }

  onHandleDelete(ans: boolean) {
    if (ans) {
      this.spinner.showSpinner();
      this.http
        .delete(`${this.environment.new_api_url}/api/${this.urlCont}`)
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(`Could not delete document`, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Document was deleted successfully`, 'success');
          this.router.navigate([`/${this.url}/`, this.loanId]);
        });
    }
    this.answer.emit(false);
  }
}
