import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import * as $ from 'jquery';
import { PaymentService } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { Transaction } from '@avenews/base-types';
@Component({
  selector: 'app-topdown-component',
  templateUrl: './topdown.component.html',
  styleUrls: ['./topdown.component.scss']
})
export class TopdownComponent extends BaseValidationComponent implements OnInit, OnChanges {
  @Input() accounts: any;
  @Input() user: any;
  beneficiaryAccountId: string;
  beneficiaryId: string;
  topupForm: FormGroup;
  balanceType = [];
  errorMessages = {
    amount: [
      { type: 'required', message: 'This field is required' },
      { type: 'min', message: 'Invalid number' }
    ]
  };
  constructor(fb: FormBuilder, private alertService: AlertService, public spinner: SpinnerToggleService, private http: HttpClient) {
    super();
    this.topupForm = fb.group({
      amount: [undefined, [Validators.required, Validators.min(1)]],
      balanceType: [undefined, Validators.required],
      notes: [undefined]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this?.accounts?.length) {
      const balanceType = [];
      this.accounts.forEach((data) => {
        if (data?.service === PaymentService.AVENEWS_FINANCING) {
          balanceType.push({
            viewValue: 'Financing Balance',
            value: data
          });
        } else if (data?.service === PaymentService.AVENEWS_INCOME) {
          balanceType.push({
            viewValue: 'Income Balance',
            value: data
          });
        }
      });
      this.balanceType = balanceType;
    }
  }
  ngOnInit(): void {}
  close() {
    $('#addTopdownWizard').fadeOut('', () => {
      this.deleteData();
    });
  }

  onTopup() {
    this.validateForm(this.topupForm);

    if (this.topupForm.valid) {
      this.beneficiaryAccountId = this.topupForm.value.balanceType._id;
      this.beneficiaryId = this.topupForm.value.balanceType.beneficiaryId._id;
      this.spinner.showSpinner();
      this.http
        .post(`${environment.new_api_url}/api/transaction/balance/top-up`, {
          amount: -this.topupForm.get('amount').value,
          beneficiaryAccountId: this.beneficiaryAccountId,
          notes: this.topupForm.get('notes').value
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            $('#addTopdownWizard').fadeOut();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: Transaction) => {
          this.alertService.showAlert(`Account has been topped down with ${-data.amount} KES successfuly`, 'success');
          $('#addTopdownWizard').fadeOut();
          this.deleteData();
          this.spinner.hideSpinner();
        });
    }
  }
  deleteData() {
    this.topupForm.reset();
    this.topupForm.patchValue({
      amount: undefined,
      balanceType: undefined,
      notes: undefined
    });
  }
}
