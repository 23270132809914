import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { AgribusinessesRoutingModule } from './agribusinesses-routing.module';
import { AgribusinessesComponent } from './agribusinesses.component';
import { AgribusinessesResolver } from './resolvers/agribusiness.resolver';
import { AgribusinessViewComponent } from './agribusiness-view/agribusiness-view.component';
import { PerformanceHistoryComponent } from './performance-history/performance-history.component';
import { AgriDocumentsComponent } from './agri-documents/agri-documents.component';
import { AgribusinessByIdResolver } from './resolvers/agribusiness-by-id.resolver';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AgribusinessesComponent, AgribusinessViewComponent, PerformanceHistoryComponent, AgriDocumentsComponent],
  imports: [
    MatTooltipModule,
    MatMenuModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AdminSharedModule,
    MatTabsModule,
    AgribusinessesRoutingModule,
    SharedModule
  ],
  exports: [],
  providers: [AgribusinessesResolver, AgribusinessByIdResolver]
})
export class AgribusinessesModule {}
