import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';
import { Router } from '@angular/router';
import { UploadFileDTO } from '@avenews/agt-sdk';
import { TradeDeskService } from '../../../trade-desk/services/trade-desk.service';
import { DistributorDocumentsStatusComponent } from '../../../shared/components/distributor-documents-status/distributor-documents-status.component';
enum FileType {
  CR12 = 'cr12',
  COI = 'coi',
  KRA = 'kra',
  GENERAL = 'general',
  TRADE_DOCS = 'tradeDocs',
  VIDEO_CALL = 'videoCall'
}
interface UploadFileValidatorDTO {
  document: UploadFileDTO;
  type: FileType;
  password?: string;
}

@Component({
  selector: 'td-documents',
  templateUrl: './td-documents.component.html',
  styleUrls: ['./td-documents.component.scss']
})
export class TdDocumentsComponent implements OnChanges, OnDestroy {
  currId: string;
  @Input() dist: any[];
  documents: any[];
  @Input() distributor: any;
  currentDoc: any;
  documentOptionsMap = {
    bankStatement: 'Bank Statement',
    MPesaStatment: 'M-PESA statement',
    cr12: 'CR12 (company ownership document)',
    coi: 'Official certificate of incorporation',
    kra: 'KRA PIN certificate',
    tradeDocs: 'Trade documents',
    general: 'General'
  };
  @Input() loan: any;
  @Output() openModal = new EventEmitter();
  acceptedMimeTypes = ['application/pdf'];
  prettyAcceptedTypes = ['pdf'];
  environment = environment;
  subscription: Subscription;
  approvedSections = {
    cr12: false,
    coi: false,
    kra: false
  };
  deleteMode = false;
  currDocId: string;
  item: any;
  showDecModal: boolean;
  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private spinner: SpinnerToggleService,
    private tdService: TradeDeskService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnChanges() {
    this.subscription = this.route.params.subscribe(async (params) => {
      this.currId = params['id'];
    });
    this.route.data.subscribe(({}) => {
      this.documents = this.distributor?.documents;
      if (!this.documents) {
        this.documents = [];
      }
      const sorter = (a: any, b: any) => {
        if (a?.dateCreated < b?.dateCreated) {
          return 1;
        }
        if (a?.dateCreated > b?.dateCreated) {
          return -1;
        }
        return 0;
      };

      this.documents = [
        {
          title: 'CR12',
          docType: 'cr12',
          data: this.documents?.filter((doc: any) => doc.fileType === 'cr12')?.sort(sorter)
        },
        {
          title: 'CERTIFICATE OF INCORPORATION / REGISTRATION',
          docType: 'coi',
          data: this.documents?.filter((doc: any) => doc.fileType === 'coi')?.sort(sorter)
        },
        {
          title: 'KRA pin / Tax Compliance Certificate',
          docType: 'kra',
          data: this.documents?.filter((doc: any) => doc.fileType === 'kra')?.sort(sorter)
        }
      ];

      this.documents.forEach((document) => {
        document.data.forEach((doc: any) => {
          if ((doc?.valid === false && !doc?.status) || doc.status === 'declined') {
            doc['cssStatus'] = this.checkStatus('declined');
          } else {
            doc['cssStatus'] = this.checkStatus(doc?.status);
          }
        });
      });

      Object.keys(this.approvedSections).forEach((section) => {
        this.approvedSections[section] = this.documents
          .find((doc) => {
            return doc.docType === section;
          })
          ?.data?.some((doc) => {
            return doc?.status === 'approved';
          });
      });
    });
  }
  validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 50e6;
  }

  async uploadDocs(event: any, docType: FileType) {
    let file: File = event.target.files[0];
    const checkTypeFor = [FileType.COI, FileType.CR12, FileType.KRA];
    if (!checkTypeFor.includes(docType)) {
      if (file.size > 50e6) {
        this.alertService.showAlert(`File too big`, 'danger');
        return;
      }
    } else {
      if (!this.validateFile(file)) {
        this.alertService.showAlert(`Maximum file size is 50MB and it should be ${this.prettyAcceptedTypes.join(', ')}`, 'danger');
        return;
      }
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      const base64File = (reader.result as string).split(',')[1];
      const doc: UploadFileValidatorDTO = {
        document: { file: base64File, fileName: file.name, mime: file.type },
        type: docType
        // password: this.loan?.password
      };
      if (docType === FileType.GENERAL) {
        const password = await this.tdService.getPassword();
        if (password) {
          doc['password'] = password;
        }
      }
      this.spinner.showSpinner();
      this.http
        .post(`${this.environment.new_api_url}/api/trade-desk/loan/${this.loan?._id}/file-validator`, { ...doc })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(`Document was not added`, 'danger');
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.spinner.hideSpinner();
          this.router.navigateByUrl(this.router.url);
          this.alertService.showAlert(`Document was added successfully`, 'success');
        });
    };
  }

  checkStatus(type: string) {
    if (type === 'approved') return 'doc-status-approved';
    if (type === 'declined') return 'doc-status-declined';
    if (type === 'awaiting') return 'doc-status-awaiting';
    else return null;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  openFile(file: any) {
    this.spinner.showSpinner();
    this.http
      .post(`https://gc0an3fw5j.execute-api.us-east-1.amazonaws.com/prod`, {
        bucket: file?.bucket,
        file: file?.key,
        region_name: file?.bucket === 'agt-new-files-local-dev-staging' ? 'us-east-1' : 'us-east-2'
      })
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Something went wrong`, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        window.open(data['url']);
      });
  }
  openchangeStatusModal(item: any) {
    this.modalService.open(DistributorDocumentsStatusComponent, {
      currentDoc: item,
      distributor: this.distributor
    });
    this.item = item;
  }
}
