import { Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { catchError, EMPTY } from 'rxjs';
import { PaymentService } from '@avenews/agt-sdk';

@Component({
  selector: 'withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.scss']
})
export class WithdrawModal extends BaseValidationComponent implements OnInit, OnChanges {
  deal: any;
  organization: any;
  options: any;
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  beneficiaryAccountId: string;
  beneficiaryId: string;
  withdrawForm: FormGroup;
  environment = environment;
  balance: number;

  errorMessages = {
    amountWithdrawn: [
      { type: 'required', message: 'This field is required' },
      { type: 'min', message: 'Invalid number' },
      { type: 'max', message: 'Can’t be bigger than the remaining amount' }
    ]
  };
  modalService: ModalService;
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.withdrawForm = fb.group({
      amountWithdrawn: [undefined, [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    if (this.options.deal) {
      this.getFunds();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}
  async getFunds() {
    this.http.get(`${environment?.new_api_url}/api/balance/user/${this.options.organization?.orgUser._id}`).subscribe((data) => {
      this.balance = data[this.options.deal._id].total;
      this.withdrawForm
        .get('amountWithdrawn')
        .setValidators([Validators.max(Number(this.roundDownTwoDecimals(data[this.options.deal._id].total)))]);
    });
  }

  roundDownTwoDecimals(num: number): string {
    const roundedNumber = Math.floor(num * 100) / 100;
    return roundedNumber.toFixed(2);
  }

  deleteData() {
    this.withdrawForm.reset();
  }

  submit() {
    this.validateForm(this.withdrawForm);
    if (this.withdrawForm.valid) {
      this.spinner.showSpinner();
      this.getAccountsByBeneficiary();
    }
  }

  getAccountsByBeneficiary() {
    this.http
      .get(environment.new_api_url + '/api/account/beneficiary/' + this.options.organization.orgUser._id)
      .pipe(
        catchError((err) => {
          console.log('error returning accounts');
          console.log(err);
          return EMPTY.pipe();
        })
      )
      .subscribe((accounts: any) => {
        const finance = accounts.find((data) => data.service === PaymentService.AVENEWS_FINANCING);
        this.http
          .post(`${this.environment?.new_api_url}/api/transaction/balance/withdraw`, {
            amount: this.withdrawForm.get('amountWithdrawn').value,
            beneficiaryAccountId: finance._id,
            dealId: this.options.deal._id
          })
          .pipe(
            catchError((err: any) => {
              this.spinner.hideSpinner();
              this.alertService.showAlert(err?.error?.message, 'danger');
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.modalService.close();
            this.router.navigateByUrl(this.router.url);
            this.spinner.hideSpinner();
            this.alertService.showAlert(`Withdraw was set successfully`, 'success');
          });
      });
  }
}
