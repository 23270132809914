<ng-select
  class="phone-input-select"
  *ngIf="select"
  [ngClass]="{ 'has-error': form.get(controlName).invalid && form.get(controlName).touched }"
  #select
  (open)="onOpen()"
  (close)="onClose()"
  [clearable]="false"
  [disabled]="isDisabled"
  [readonly]="rOnly"
  [(ngModel)]="regionCode"
  [items]="countries"
  [searchable]="false"
  autocomplete="nothing"
  [searchFn]="search"
  bindLabel="viewValue"
  bindValue="value"
  [ngModelOptions]="{ standalone: true }"
  (change)="changeRegion($event)"
>
  <ng-template ng-label-tmp let-item="item">
    <img *ngIf="!svg" height="19" width="28" src="assets/images/flags/{{ item?.value }}.png" />
    <svg *ngIf="svg" viewBox="0 0 640 480">
      <use xlink:href="assets/images/flags/KE.svg#ke-flag"></use>
    </svg>
    <p class="phone-number-code" *ngIf="phoneNumberCode">+254</p>
    <div *ngIf="showFlagArrow && !rOnly && !isDisabled" class="flag-arrow"></div>
  </ng-template>
  <ng-template let-index="index" let-search="searchTerm" ng-option-tmp let-item="item">
    <img height="19" width="28" src="assets/images/flags/{{ item?.value }}.png" />
    <span *ngIf="!flagOnly">
      {{ item.viewValue }}
    </span>
  </ng-template>
  <ng-template ng-header-tmp>
    <input
      #searchRef
      autocomplete="walaishi"
      placeholder="Type country here..."
      class="search-input"
      type="search"
      (input)="filter(select, $any($event).target.value)"
    />
  </ng-template>
</ng-select>
<div class="fixed-select" *ngIf="!select">
  <div>
    <ng-container *ngIf="!flagOnly">
      <img *ngIf="!svg" height="19" width="28" src="assets/images/flags/{{ regionCode }}.png" />
      <svg *ngIf="svg" viewBox="0 0 640 480">
        <use xlink:href="assets/images/flags/KE.svg#ke-flag"></use>
      </svg>
      +{{ phoneCode }}
    </ng-container>
    <ng-container *ngIf="flagOnly">
      <img *ngIf="!svg" height="19" width="28" src="assets/images/flags/{{ regionCode }}.png" />
      <svg *ngIf="svg" viewBox="0 0 640 480">
        <use xlink:href="assets/images/flags/KE.svg#ke-flag"></use>
      </svg>
    </ng-container>
  </div>
</div>
