<ng-template
  [cdkConnectedOverlayPanelClass]="['some-class']"
  cdkConnectedOverlayBackdropClass="some-backdrop"
  cdkConnectedOverlayHasBackdrop
  (attach)="open()"
  (detach)="close()"
  [cdkConnectedOverlayOpen]="isOpen"
  cdkConnectedOverlay
  [cdkConnectedOverlayDisableClose]="true"
>
  <div class="modal-box" id="changePeriodStatusWizard">
    <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
      <div class="modal-box-heading">
        <p>Change Period Status</p>
        <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
      </div>
      <div class="modal-box-content">
        <form [formGroup]="statusForm">
          <app-base-form-template
            labelName="Status"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="status"
            placeholderName="Change status"
            [options]="statusSelect"
            [group]="statusForm"
            [size]="6"
          >
          </app-base-form-template>
        </form>
      </div>
      <div class="topup-modal-footer">
        <button class="btn btn-primary" (click)="changeStatus()">Update Status</button>
      </div>
    </div>
  </div>
</ng-template>
