import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Agt4Deal, Status } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { EMPTY, catchError } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-distributor-documents-status',
  templateUrl: './distributor-documents-status.component.html',
  styleUrls: ['./distributor-documents-status.component.scss']
})
export class DistributorDocumentsStatusComponent extends BaseValidationComponent implements OnInit {
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<boolean>();
  @Input() currentDoc: any;
  @Input() distributor: any;

  statusForm: FormGroup;
  statusSelect = [
    {
      value: Status.APPROVED,
      viewValue: 'Approve'
    },
    {
      value: Status.DECLINED,
      viewValue: 'Decline'
    }
  ];
  environment: any;
  options: any;
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router,
    private modalService: ModalService
  ) {
    super();
    this.statusForm = fb.group({
      status: [undefined, Validators.required]
    });
  }
  ngOnInit(): void {
    this.currentDoc = this.options.currentDoc;
    this.distributor = this.options.distributor;
  }
  close() {
    this.modalService.close();
  }
  changeStatus() {
    this.validateForm(this.statusForm);
    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/distributor/${this.distributor?._id}/documents/${this.currentDoc._id}/status`, {
          status: this.statusForm.value.status
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            this.close();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: Agt4Deal) => {
          this.alertService.showAlert(`Status Changed to  ${this.statusForm.value.status} successfuly`, 'success');
          this.close();
          this.spinner.hideSpinner();
          this.deleteData();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  deleteData() {
    this.statusForm.reset();
  }
}
