import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiCrops'
})
export class MultiCropsPipe implements PipeTransform {
  transform(crops: any[], tooltip?: boolean) {
    if (tooltip) {
      return this.tooltip(crops);
    }
    return this.column(crops);
  }
  tooltip(crops: any[]) {
    let res = '';
    crops.forEach((data) => {
      res += `${data.crop.name} - ${data.quantityRequested} Acre `;
    });
    return res;
  }
  column(crops: any[]) {
    const length = crops.length;
    if (length) {
      if (length === 1) {
        return crops[0].quantityRequested + crops[0].crop.name;
      } else {
        return 'Multiple';
      }
    } else {
      return '-';
    }
  }
}
