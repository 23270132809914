import { LoginConfig } from './../../auth-config';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import { ResetPasswordDTO } from '@avenews/agt-sdk';
import { countries, SdkService, SpinnerToggleService } from '@avenews/shared-lib';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetLinkStatus = 0;
  formInput: FormGroup;
  username: string;
  forgotPasswordForm: FormGroup;
  countries = countries;
  phoneUtil: any;
  regionCode: string;
  canReset = false;
  isResetPassword = false;
  phoneCode: string;
  methodName: string;
  partialPhoneNumber: string;

  constructor(
    private formBuilder: FormBuilder,
    private sdkService: SdkService,
    private route: ActivatedRoute,
    private spinner: SpinnerToggleService,
    @Inject('loginConfig') public loginConfig: LoginConfig
  ) {}

  ngOnInit() {
    this.formInput = this.forgotPasswordForm;
    this.phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    this.regionCode = this.loginConfig.environment.variables.regionCode;
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['']
    });

    this.forgotPasswordForm.get('email').valueChanges.subscribe((email) => {
      if (email) {
        this.forgotPasswordForm.get('phone').setValue('', { emitEvent: false });
        this.forgotPasswordForm.get('phone').setValidators([]);
        this.forgotPasswordForm.get('email').setValidators([Validators.required, Validators.email]);
      }
    });

    this.forgotPasswordForm.get('phone').valueChanges.subscribe((phone) => {
      if (phone) {
        this.forgotPasswordForm.get('email').setValue('', { emitEvent: false });
        this.forgotPasswordForm.get('email').setValidators([]);
        this.forgotPasswordForm.get('phone').setValidators([Validators.required]);
      }
    });
  }

  changeRegionCode() {
    this.phoneCode = this.phoneUtil.getCountryCodeForRegion(this.regionCode);
    const PNF = libphonenumber.PhoneNumberFormat;
    let phone;
    try {
      phone = this.phoneUtil.parse(this.partialPhoneNumber, this.regionCode);
    } catch (error) {
      phone = undefined;
      return;
    }
    this.forgotPasswordForm.patchValue({
      phone: this.phoneUtil.format(phone, PNF.E164)
    });
  }

  recoverPassword() {
    this.onSubmit(this.forgotPasswordForm);
    if (this.forgotPasswordForm.valid) {
      if (!this.canReset) {
        this.isResetPassword = true;
        this.username = this.forgotPasswordForm.value.email;
        const resetPasswordDto: ResetPasswordDTO = { email: this.username };
        this.sdkService.sendResetPasswordLink(resetPasswordDto).then((data) => {
          this.resetLinkStatus = 1;
        });
      } else {
      }
    }
  }

  generateLink(code: any, country: any) {
    return `<img src='assets/img/flags/${code}.png' height='19' height='27'><span>\xa0\xa0${country}</span>`;
  }

  checkMethod() {
    if (this.forgotPasswordForm.value.email) {
      this.methodName = 'email';
    } else {
      this.methodName = 'phone';
    }
  }
  captchaSubmit(e: any) {
    if (e) {
      this.canReset = e;
    }
  }
  isFieldInvalid(field: string, errorType?: string) {
    if (field === 'password' && errorType === 'minlength') {
      return this.formInput.get(field).hasError('minlength') && this.formInput.get(field).touched;
    } else if (field === 'password' && errorType === 'required') {
      return this.formInput.get(field).hasError('required') && this.formInput.get(field).touched;
    } else {
      return this.formInput.get(field).invalid && this.formInput.get(field).touched;
    }
  }

  showFieldStyle(field: string) {
    return {
      'has-error': this.isFieldInvalid(field)
    };
  }

  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }
}
