import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { AlertService } from '@avenews/alerts';
import { SpinnerToggleService } from '@avenews/shared-lib';
declare const $: any;
@Component({
  selector: 'frontend-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnChanges {
  @Input() accounts: any;
  @Input() user: any;
  activation: string;
  activationEp: string;
  constructor(
    private http: HttpClient,
    private spinner: SpinnerToggleService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  ngOnChanges() {
    this.activation = this.accounts?.[0]?.validated ? 'Active' : 'Inactive';
    this.activationEp = this.accounts?.[0]?.validated ? 'Disable' : 'Activate';
  }
  close() {
    $('#activation').fadeOut();
  }
  activateOrDisable() {
    if (this.activationEp === 'Disable') {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/account/avenews/${this.accounts[0]._id}`, {
          validated: false
        })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.alertService.showAlert(`Account has been disabled successfuly`, 'success');
          this.spinner.hideSpinner();
          this.router.navigateByUrl(this.router.url);
          $('#activation').fadeOut();
        });
    }
    if (this.activationEp === 'Activate') {
      this.spinner.showSpinner();
      this.http

        .put(`${environment.new_api_url}/api/account/avenews/${this.accounts[0]._id}`, {
          validated: true
        })
        .pipe(
          catchError((err: any) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert(`Account has been activated successfuly`, 'success');
          $('#activation').fadeOut();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
}
