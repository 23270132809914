import { catchError, EMPTY, from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { PaginatedResult } from '@avenews/agt-sdk';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Transaction } from '@avenews/base-types';

@Injectable()
export class TopupResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve() {
    return (
      this.http.get(
        `${environment.new_api_url}/api/transaction/10/1/_id/desc?filters=[{%22value%22:%22TOPUP%22,%22column%22:%22transactionId%22,%22option%22:%22contains%22}]`
      ) as Observable<PaginatedResult<Transaction>>
    ).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
