import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';

@Component({
  selector: 'frontend-decline-user-topup',
  templateUrl: './decline-user-topup.component.html',
  styleUrls: ['./decline-user-topup.component.scss']
})
export class DeclineUserTopupComponent implements OnInit {
  @Input() topup: any;
  alertService: any;

  constructor(private spinner: SpinnerToggleService, private http: HttpClient, private router: Router) {}

  ngOnInit() {}
  close() {
    $('#declineTopupWizard').fadeOut();
  }
  decline() {
    //endpoint to approve topup
    // if (this.topup && this.topup.amount) {
    //   this.spinner.showSpinner();
    //   this.http
    //     .put(`${environment.new_api_url}/api/loan-application/status/${this.topup._id}`, {
    //       status: 'declined'
    //     })
    //     .pipe(
    //       catchError((err) => {
    //         this.alertService.showAlert(err?.error?.message, 'danger');
    //         this.spinner.hideSpinner();
    //         $('#approveUserTopupWizard').fadeOut();
    //         return EMPTY.pipe();
    //       })
    //     )
    //     .subscribe((data) => {
    //       this.alertService.showAlert(`Top up declined successfuly`, 'success');
    //       $('#changeAppStatusWizard').fadeOut();
    //       this.router.navigateByUrl(this.router.url);
    //       this.spinner.hideSpinner();
    //     });
    // }
  }
}
