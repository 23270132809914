<div *ngIf="loading" class="activity-container d-flex justify-content-center">
  <img width="200" src="../../../assets/loading.gif" alt="" />
</div>
<ng-container *ngIf="!loading && activityLog?.length">
  <div class="refresher">
    <i-feather (click)="handleLog()" name="refresh-cw"></i-feather>
  </div>
  <div *ngFor="let activity of activityLog" class="activity-container">
    <div [style.background]="activity.background" class="user-avatar">
      {{ activity?.createdBy?.personalInformation?.firstName[0] }}{{ activity?.createdBy?.personalInformation?.lastName[0] }}
    </div>
    <div class="details">
      <div class="message">
        <span>{{ activity?.createdBy?.personalInformation?.firstName }} {{ activity?.createdBy?.personalInformation?.lastName }}</span>
        {{ activity?.message }}
      </div>
      <div class="date">
        {{ activity?.dateCreated | notificationDate }}
        {{ (activity.dateCreated | notificationDate) === 'Today' ? (activity?.dateCreated | date: 'HH:mm') : '' }}
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading && !activityLog?.length"> No logs yet </ng-container>
