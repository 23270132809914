import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {
  transform(expirationDate: Date): string {
    console.log(expirationDate);
    const currentDate = new Date();
    const remainingTime = expirationDate.getTime() - currentDate.getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerMonth = 30 * millisecondsPerDay;
    const millisecondsPerYear = 365 * millisecondsPerDay;

    if (remainingTime <= 0) {
      return 'Expired';
    } else if (remainingTime <= millisecondsPerDay) {
      return 'Less than 1 day';
    } else if (remainingTime <= millisecondsPerMonth) {
      const days = Math.floor(remainingTime / millisecondsPerDay);
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (remainingTime <= millisecondsPerYear) {
      const months = Math.floor(remainingTime / millisecondsPerMonth);
      return `${months} month${months > 1 ? 's' : ''}`;
    } else {
      const years = Math.floor(remainingTime / millisecondsPerYear);
      return `${years} year${years > 1 ? 's' : ''}`;
    }
  }
}
