export const measureUnits = [
  { value: 'GR', viewValue: 'Grams' },
  { value: 'KG', viewValue: 'Kilograms' },
  { value: 'MT', viewValue: 'MT' },
  { value: 'LB', viewValue: 'LB' },
  { value: 'ML', viewValue: 'Milliliters' },
  { value: 'LI', viewValue: 'Liters' },
  { value: 'OZ', viewValue: 'OZ' }
];
export const measurement = [
  { value: 'KG', viewValue: 'Kilograms' },
  { value: 'LI', viewValue: 'Liters' },
  { value: 'Unit', viewValue: 'Unit' }
];
