import { NgModule } from '@angular/core';
import { ButtonComponent } from './button/button.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { OptionComponent } from './option/option.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TabGroupComponent } from './tabs/tab-group.component';
import { TabsComponent } from './tabs/tabs-group/tabs.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    SlideToggleComponent,
    RadioComponent,
    SelectComponent,
    OptionComponent,
    TabsComponent,
    TabComponent,
    TabGroupComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FeatherModule.pick(allIcons)],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    OptionComponent,
    TabsComponent,
    TabComponent,
    TabGroupComponent,
    SlideToggleComponent
  ]
})
export class UiLibraryModule {}
