<div class="container" (click)="moveToDetails()" [ngClass]="item?.status">
  <div class="file-title">
    <img src="assets/shared/svgs/file-item-icon.svg" alt="file-item-icon" />
    <h6>{{ item?.fileName }}</h6>
  </div>
  <ng-container *ngIf="!item?.editMode; else edit">
    <img src="assets/shared/svgs/item-arrow-left.svg" alt="arrow-left" />
  </ng-container>
  <ng-template #edit>
    <div class="circle">
      <img src="assets/shared/svgs/edit.svg" />
    </div>
  </ng-template>
</div>
