import { Router } from '@angular/router';
import { AlertService } from '@avenews/alerts';
import { User } from '@avenews/agt-sdk';
import { DocumentPasswordComponent } from './../tools/document-password/document-password.component';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { TitleCasePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { catchError, EMPTY, of, lastValueFrom, Observable, firstValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { SpinnerToggleService } from '@avenews/shared-lib';
interface SocialMediaRes {
  status: { facebook: boolean; twitter: boolean; linkedin: boolean };
}
@Injectable()
export class TradeDeskService {
  miniSpinners = {
    email: false,
    crop: false,
    business: false,
    phone: false,
    insights: [false],
    cropPrices: false,
    bankStatement: false,
    bankInsights: [false]
  };
  months = [
    {
      viewValue: 'January',
      value: 1
    },
    {
      viewValue: 'February',
      value: 2
    },
    {
      viewValue: 'March',
      value: 3
    },
    {
      viewValue: 'April',
      value: 4
    },
    {
      viewValue: 'May',
      value: 5
    },
    {
      viewValue: 'June',
      value: 6
    },
    {
      viewValue: 'July',
      value: 7
    },
    {
      viewValue: 'August',
      value: 8
    },
    {
      viewValue: 'September',
      value: 9
    },
    {
      viewValue: 'October',
      value: 10
    },
    {
      viewValue: 'November',
      value: 11
    },
    {
      viewValue: 'December',
      value: 12
    }
  ];
  constructor(
    private http: HttpClient,
    private titleCaser: TitleCasePipe,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private spinner: SpinnerToggleService,
    private alerts: AlertService,
    private decimalPipe: DecimalPipe,
    private router: Router
  ) {}
  async getAllCrops(): Promise<{ names: string[] }> {
    return lastValueFrom(
      <any>this.http.get(`https://k7uwfegyb6.execute-api.us-east-1.amazonaws.com/prod/crop-calendar`).pipe(
        catchError((err) => {
          return of([]);
        })
      )
    );
  }
  checkAll(
    cropForm: FormGroup,
    emailSMF: FormGroup,
    phoneSMF: FormGroup,
    businessForm: FormGroup,
    cropPricesForm: FormGroup,
    data: any,
    loan: any
  ) {
    if (data) {
      const crop = data?.decision?.crop;
      const business = data?.decision?.businessValidator;
      const phoneNumber = data?.decision?.socialMedia?.phoneNumber;
      const email = data?.decision?.socialMedia?.email;
      const cropPrices = data?.decision?.cropPrices;
      if (crop) {
        cropForm.patchValue({
          cropType: crop?.crop_type,
          planting: crop?.decision?.planting ? 'Yes' : 'No',
          harvesting: crop?.decision?.harvesting ? 'Yes' : 'No',
          peak: crop?.decision?.peak ? 'Yes' : 'No'
        });
      }
      if (business) {
        businessForm.patchValue({
          name: business?.businessName,
          number: business?.businessNumber,
          status: business.decision?.status
        });
      } else {
        businessForm.patchValue({
          name: loan?.businessDetails?.name,
          number: loan?.businessDetails?.registrationNumber
        });
      }
      if (phoneNumber) {
        phoneSMF.patchValue({
          username: phoneNumber?.username,
          facebook:
            phoneNumber?.decision?.status?.facebook === true
              ? 'Yes'
              : phoneNumber?.decision?.status?.facebook === false
              ? 'No'
              : 'Check Manually',
          twitter:
            phoneNumber?.decision?.status?.twitter === true
              ? 'Yes'
              : phoneNumber?.decision?.status?.twitter === false
              ? 'No'
              : 'Check Manually',
          linkedin:
            phoneNumber?.decision?.status?.linkedin === true
              ? 'Yes'
              : phoneNumber?.decision?.status?.linkedin === false
              ? 'No'
              : 'Check Manually'
        });
      }
      if (email) {
        emailSMF.patchValue({
          username: email?.username,
          facebook:
            email?.decision?.status?.facebook === true ? 'Yes' : email?.decision?.status?.facebook === false ? 'No' : 'Check Manually',
          twitter: email?.decision?.status?.twitter === true ? 'Yes' : email?.decision?.status?.twitter === false ? 'No' : 'Check Manually',
          linkedin:
            email?.decision?.status?.linkedin === true ? 'Yes' : email?.decision?.status?.linkedin === false ? 'No' : 'Check Manually'
        });
      }
      if (cropPrices) {
        cropPricesForm.patchValue({
          cropName: cropPrices?.product,
          wholeSale: cropPrices?.decision?.wholesale,
          retail: cropPrices?.decision?.retail
        });
      }
    } else {
      businessForm.patchValue({
        name: loan?.businessDetails?.name,
        number: loan?.businessDetails?.registrationNumber
      });
    }
  }

  async createCropOptions(): Promise<any[]> {
    let crops;
    try {
      crops = await this.getAllCrops();
    } catch {
      crops = {
        names: [
          'rice',
          'Irish potatoes',
          'potatoes',
          'hass avocado',
          'avocado',
          'mango',
          'sorghum',
          'onion',
          'peppers',
          'tomato',
          'white cabbage',
          'watermelon',
          'kale/borecole',
          'red cabbage',
          'carrots',
          'french beans',
          'cow peas',
          'cow peas leafy (kunde)',
          'soy beans',
          'sura beans',
          'sugarcane',
          'groundnut/peanut',
          'passion fruits ',
          'black nightshade (Managu/Mnavu)',
          'chilli fresh',
          'chilli dry',
          'yellow bananas',
          'millet',
          'barley',
          'oats',
          'wheat',
          'maize'
        ]
      };
    }
    return crops.names.map((crop) => {
      return {
        viewValue: this.titleCaser.transform(crop.split('_').join(' ')),
        value: crop
      };
    });
  }
  async createCropPriceOptions(): Promise<any[]> {
    let crops = [];
    try {
      return await lastValueFrom(
        this.http.get('https://tgf5n6wlfi.execute-api.us-east-1.amazonaws.com/prod').pipe(
          catchError((err) => {
            throw err;
          })
        ) as Observable<any>
      );
    } catch (err) {
      return crops;
    }
  }
  checkCrop(cropForm: FormGroup, id: string) {
    cropForm.markAllAsTouched();
    if (cropForm.valid) {
      this.miniSpinners['crop'] = true;
      // this.http.post('https://avenews-business-validation.herokuapp.com/business', { businessNumber: 'BN-DBCL6LD2' }).subscribe((data) => {
      // });
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/loan/${id}/crops`, {
          crop_type: cropForm?.get('cropType')?.value
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners['crop'] = false;
            return of(undefined);
          })
        )
        .subscribe((cropState: any) => {
          this.miniSpinners.crop = false;
          cropForm.patchValue({
            planting: cropState?.planting ? 'Yes' : 'No',
            harvesting: cropState?.harvesting ? 'Yes' : 'No',
            peak: cropState?.peak ? 'Yes' : 'No'
          });
        });
    }
  }
  checkSocialMedia(form: FormGroup, spinner: string, id: string) {
    form.markAllAsTouched();
    if (form.valid) {
      this.miniSpinners[spinner] = true;
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/loan/${id}/social-media/${spinner === 'email' ? 'email' : 'phone-number'}`, {
          username: form.get('username')?.value
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners[spinner] = false;
            return of(undefined);
          })
        )
        .subscribe((data: SocialMediaRes) => {
          this.miniSpinners[spinner] = false;
          form.patchValue({
            facebook: data?.status?.facebook === true ? 'Yes' : data?.status?.facebook === false ? 'No' : 'Check Manually',
            twitter: data?.status?.twitter === true ? 'Yes' : data?.status?.twitter === false ? 'No' : 'Check Manually',
            linkedin: data?.status?.linkedin === true ? 'Yes' : data?.status?.linkedin === false ? 'No' : 'Check Manually'
          });
        });
    }
  }
  checkBusiness(form: FormGroup, spinner: string, id: string) {
    form.markAllAsTouched();
    if (form.valid) {
      this.miniSpinners[spinner] = true;
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/loan/${id}/business-validator`, {
          number: form.get('number')?.value?.trim(),
          name: form.get('name')?.value?.trim?.()
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners[spinner] = false;
            return of({ status: 'Error' });
          })
        )
        .subscribe((data: any) => {
          this.miniSpinners[spinner] = false;
          if (data) {
            form.patchValue({
              status: data?.status || 'Error'
            });
          }
        });
    }
  }
  checkInsights(form: FormGroup, loan: any, i: number) {
    const id = loan?._id;
    form.markAllAsTouched();
    if (form.valid) {
      this.miniSpinners.insights[i] = true;
      this.http
        .post(`${environment.new_api_url}/api/trade-desk/insights/loan/${id}`, {
          document: {
            file: form.get('file')?.value,
            fileName: form.get('fileName')?.value,
            mime: form.get('mime')?.value
          },
          password: form.get('password')?.value,
          insightId: form.get('_id')?.value
        })
        .pipe(
          catchError((err) => {
            if (err.error?.message === 'INSIGHT_PASSWORD_NOT_VALID') {
              form.get('password')?.setErrors({ wrongPass: true });
            }
            this.miniSpinners.insights[i] = false;
            return of(undefined);
          })
        )
        .subscribe((data: any) => {
          this.miniSpinners.insights[i] = false;
          if (data) {
            this.prepareInsights(data, form, loan);
          }
        });
    }
  }
  prepareInsights(insight: any, form: FormGroup, loan: any) {
    if (insight?.periods?.file_valid === false) {
      form.patchValue({
        income: '',
        expenses: '',
        loan: '',
        bet: '',
        overdraft: '',
        periods: 'invalid',
        _id: insight?._id
      });
      form.get('file')?.markAsTouched();

      form.get('file')?.setErrors({ wrongFile: true });
      form.get('fileName')?.markAsTouched();
      form.get('password')?.setValue(insight?.documents?.[0]?.password);
      setTimeout(() => {
        form.get('password')?.setErrors({ invalidFile: true });
      }, 100);
      return;
    }
    if (insight?.periods && !insight?.periods?.file_valid) {
      const keys = Object.keys(insight.periods);
      let fromTo = '';
      const data = [0, 0, 0, 0] as any;
      const expenses = ['loanRatio', 'betRatio', 'overdraftRatio', 'other'];
      let incomeAmount = 0;
      let expenseAmount = 0;
      const periods = [];
      const personalInfo = insight?.periods?.personalInfo;
      let statementStatus;
      let insightDate: Date;
      if (personalInfo?.statementPeriod) {
        statementStatus = this.getStatementStatus(insight, loan);
      }
      const periodicTotals = insight?.periods?.periodicTotals;
      for (let i = 0; i < keys?.length; i++) {
        if (!isNaN(+keys[i].slice(0, 4))) {
          const period = {
            ...insight.periods[keys[i]]
          };
          Object.keys(period).forEach((key) => {
            try {
              if (key?.includes('ratio')) {
                period[key] = this.decimalPipe.transform(period[key] * 100, '1.0-2');
              } else {
                period[key] = this.decimalPipe.transform(period[key], '1.0-2');
              }
            } catch {}
          });
          periods.push({
            date: keys[i],
            ...period,
            netIncome: insight.periods[keys[i]].netIncome ? this.decimalPipe.transform(insight.periods[keys[i]].netIncome) : ('none' as any)
          });

          const key = Object.keys(insight.periods)[i];
          const monthInNumber = +key.split('-')[1];
          const year = +key.split('-')[0];
          // get the month's first 3 letters based on a number from 1 to 12
          const month = new Date(year, monthInNumber - 1, 1).toLocaleString('en-us', { month: 'short' });
          if (i === 0) {
            fromTo += month;
          } else if (i === keys.length - 1) {
            fromTo += ' - ' + month;
          }
          incomeAmount += insight.periods[key].income;
          expenseAmount += insight.periods[key].withdrawn;
          expenses.forEach((expense, i) => {
            // expenseAmount += insight.periods[key][expense];
            data[i] += insight.periods[key][expense] || 0;
          });

          for (let i = 0; i < data.length; i++) {
            data[i] = Math.round((data[i] / 4) * 100) + '%';
          }
          form.patchValue({
            income: incomeAmount,
            expenses: expenseAmount,
            loan: data[0],
            bet: data[1],
            overdraft: data[2],
            periods,
            _id: insight._id,
            periodicTotals,
            personalInfo,
            extra: {
              statementStatus
            }
          });
        }
      }
    }
  }
  checkCropPrices(cropForm: FormGroup, id: string) {
    cropForm.markAllAsTouched();
    if (cropForm.valid) {
      this.miniSpinners['cropPrices'] = true;
      // this.http.post('https://avenews-business-validation.herokuapp.com/business', { businessNumber: 'BN-DBCL6LD2' }).subscribe((data) => {
      // });
      this.http
        .put(`${environment.new_api_url}/api/trade-desk/loan/${id}/crop-prices`, {
          product: cropForm?.get('cropName')?.value
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners['cropPrices'] = false;
            return of(undefined);
          })
        )
        .subscribe((cropState: any) => {
          this.miniSpinners['cropPrices'] = false;
          cropForm.get('wholeSale').patchValue(cropState?.wholesale);
          cropForm.get('retail').patchValue(cropState?.retail);
        });
    }
  }
  async getPassword(): Promise<string> {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(DocumentPasswordComponent).create(this.injector);
    componentRef.instance.isOpen = true;
    this.appRef.attachView(componentRef.hostView);
    return firstValueFrom(componentRef.instance.passwordValue.asObservable());
  }

  addNote(noteControl: FormControl, id: string, mentionedUsers: string[]) {
    this.spinner.showSpinner();
    const noteDto = { note: noteControl?.value, mentionedUsers };
    if (noteDto.mentionedUsers?.length === 0) {
      delete noteDto.mentionedUsers;
    }
    this.http
      .post(`${environment.new_api_url}/api/loan-application/${id}/general-note`, noteDto)
      .pipe(
        catchError((err) => {
          this.alerts.showAlert(err.error.message, 'danger');
          this.spinner.hideSpinner();
          return EMPTY.pipe();
        })
      )
      .subscribe(() => {
        this.spinner.hideSpinner();
        noteControl.reset();
        this.router.navigateByUrl(this.router.url);
        this.alerts.showAlert(`Note added successfully`, 'success');
      });
  }
  assignTo(user: User, loan: any): Promise<boolean> {
    return new Promise((res) => {
      this.spinner.showSpinner();
      let apiUrl = `${environment.new_api_url}/api/loan-application/`;
      if (user?._id) {
        apiUrl = apiUrl.concat(`${loan?._id}/assign`);
      } else {
        apiUrl = apiUrl.concat(`${loan?._id}/unassign`);
      }
      this.http
        .put(apiUrl, {
          assigneeId: user?._id
        })
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alerts.showAlert(err.error.message, 'danger');
            res(false);
            return EMPTY.pipe();
          })
        )
        .subscribe(() => {
          this.spinner.hideSpinner();
          const alertMessage = user?._id
            ? `Loan assigned to 
          ${user?.personalInformation?.firstName} ${user?.personalInformation?.lastName}`
            : `Loan unassigned`;
          this.alerts.showAlert(`${alertMessage} successfully`, 'success');
          loan.assignee = user;
          res(true);
        });
    });
  }
  deleteNote(noteId: string) {
    return new Promise((res, rej) => {
      this.spinner.showSpinner();
      this.http
        .delete(`${environment.new_api_url}/api/loan-application/general-note/${noteId}`)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alerts.showAlert(err.error.message, 'danger');
            rej();
            return EMPTY.pipe();
          })
        )
        .subscribe(() => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(`Note deleted successfully`, 'success');
          res(noteId);
        });
    });
  }
  checkBankStatements(bankForm: FormArray, loanId: string) {
    bankForm.markAllAsTouched();
    if (bankForm.valid) {
      this.miniSpinners['bankStatement'] = true;
      this.http
        .post(`${environment.new_api_url}/api/trade-desk/bank-statements/loan/${loanId}`, {
          bankStatements: bankForm?.getRawValue()
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners['bankStatement'] = false;
            return of(undefined);
          })
        )
        .subscribe((bankStatement: any) => {
          this.miniSpinners['bankStatement'] = false;
          this.alerts.showAlert(`Bank statements checked successfully`, 'success');
        });
    }
  }
  prepareBankInsights(insights: any, form: FormGroup) {
    const periods = [];
    Object.keys(insights.data.months).forEach((month) => {
      const period = {
        date: month,
        paidIn: this.decimalPipe.transform(insights.data.months[month]['paidIn'], '1.0-2'),
        withdrawn: this.decimalPipe.transform(insights.data.months[month].withdrawn, '1.0-2')
      };
      periods.push(period);
    });
    const totals = {
      totalPaidIn: this.decimalPipe.transform(insights.data.total.totalPaidIn, '1.0-2'),
      totalWithdrawn: this.decimalPipe.transform(insights.data.total.totalWithdrawn, '1.0-2'),
      avgPaidIn: this.decimalPipe.transform(insights.data.total.avgPaidIn, '1.0-2'),
      avgWithdrawn: this.decimalPipe.transform(insights.data.total.avgWithdrawn, '1.0-2')
    };
    form.patchValue({
      data: {
        total: totals,
        months: periods
      },
      fileValid: insights.fileValid ? 'Yes' : 'No',
      bankName: insights.bankName,
      _id: insights._id
    });
  }

  checkBankInsight(form: FormGroup, id: string, i: number) {
    form.markAllAsTouched();
    if (form.valid) {
      this.miniSpinners.bankInsights[i] = true;
      this.http
        .post(`${environment.new_api_url}/api/trade-desk/bank-insights/loan/${id}`, {
          document: {
            file: form.get('file')?.value,
            fileName: form.get('fileName')?.value,
            mime: form.get('mime')?.value
          },
          bankName: form?.value?.bankName,
          password: form?.value?.password,
          insightId: form.get('_id')?.value
        })
        .pipe(
          catchError((err) => {
            this.miniSpinners.bankInsights[i] = false;
            return of(undefined);
          })
        )

        .subscribe((data: any) => {
          this.miniSpinners.bankInsights[i] = false;
          if (data && data?.fileValid) {
            this.prepareBankInsights(data, form);
          } else {
            if (data?.fileValid === false) {
              this.alerts.showAlert(`This file is invalid, it has been modified`, 'danger');
            } else {
              this.alerts.showAlert(`Something went wrong`, 'danger');
            }
            form.get('data').reset();
          }
        });
    }
  }
  getStatementStatus(insight, loan) {
    const businessName = loan?.businessDetails?.name?.toLowerCase();
    const personalName =
      loan?.createdBy?.personalInformation?.firstName?.toLowerCase() + ' ' + loan?.createdBy?.personalInformation?.lastName?.toLowerCase();
    const phoneNumber = loan?.personalDetails?.primaryPhoneNumber;
    let phoneZero = phoneNumber;
    if (phoneNumber?.[4] === '0') {
      phoneZero = phoneNumber.slice(4);
    } else {
      phoneZero = '0' + phoneNumber.slice(4);
    }
    console.log(phoneZero);
    console.log(phoneNumber);
    let statementStatus = {
      name: null,
      phoneNumber: null,
      email: null,
      date: null
    };
    try {
      const personalInfo = insight?.periods?.personalInfo;
      let insightDate: Date;
      console.log(personalInfo);
      console.log(insight);
      insightDate = new Date(insight?.dateCreated);
      const statementSplittedDate = personalInfo?.statementPeriod?.split(' - ');
      const startDate = new Date(statementSplittedDate[0]);
      const endDate = new Date(statementSplittedDate[1]);
      // get month difference in numbers
      const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
      const monthDiffInsight = (insightDate.getFullYear() - startDate.getFullYear()) * 12 + (insightDate.getMonth() - startDate.getMonth());
      console.log(monthDiff);
      console.log(monthDiffInsight);
      if (monthDiff < 6) {
        statementStatus['date'] = false;
      } else if (monthDiffInsight < 6) {
        statementStatus['date'] = false;
      } else {
        statementStatus['date'] = true;
      }

      if (personalInfo?.customerName) {
        const customerName = personalInfo?.customerName?.toLowerCase();
        if (customerName === personalName) {
          statementStatus['name'] = true;
        } else {
          statementStatus['name'] = false;
        }
      } else if (personalInfo?.organisationName) {
        const organisationName = personalInfo?.organisationName?.toLowerCase();
        if (organisationName === businessName) {
          statementStatus['name'] = true;
        } else {
          statementStatus['name'] = false;
        }
      }
      if (personalInfo?.mobileNumber) {
        const mobileNumber = personalInfo?.mobileNumber;
        if (mobileNumber === phoneNumber || mobileNumber === phoneZero) {
          statementStatus['phoneNumber'] = true;
        } else {
          statementStatus['phoneNumber'] = false;
        }
      }

      if (personalInfo?.emailAddress) {
        const email = personalInfo?.emailAddress;
        if (email?.toLowerCase() === loan?.personalDetails?.email?.toLowerCase()) {
          statementStatus['email'] = true;
        } else {
          statementStatus['email'] = false;
        }
      }
      console.log(monthDiff);
      return statementStatus;
    } catch {
      return {};
    }
  }
}
