import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'av-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() type: 'secondary' | 'primary' | 'tertiary' | 'quiet' | 'icon';

  @Input() iconType: 'secondary' | 'primary' | 'tertiary' | 'quiet' | 'darkquiet';

  @Input() disabled: boolean;

  @Input() customClass: string;

  @Input() toggle: string;

  @Input() expanded: string;

  @Input() label: string;

  @Input() id: string;

  @Input() icon: string;

  @Input() rotateIcon: boolean;

  @Input() size: 'default' | 'medium' | 'small' | 'stretched';

  @Input() iconSize: 'default' | 'medium' | 'small' | 'stretched';

  constructor() {}

  ngOnInit() {}
}
