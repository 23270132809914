import { Directive, Input, AfterViewInit, ElementRef } from '@angular/core';
import * as $ from 'jquery';
@Directive({
  selector: '[fadeSidebar]'
})
export class FadeSidebarDirective implements AfterViewInit {
  @Input() modalId: string;
  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    try {
      const modal: HTMLElement = this.el.nativeElement.querySelector('#' + this.modalId);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const element = mutation.target as HTMLElement;
          const display = element.style.display;
          if (display === 'flex') {
            this.changeZindex(0);
          } else {
            this.changeZindex(1000);
          }
        });
      });

      observer.observe(modal, {
        attributes: true
      });
    } catch (err) {}
  }
  changeZindex(num: number) {
    $('.mat-tab-header').css('z-index', num);
    $('.header-wrapper').css({ 'z-index': num });
    $('.sidebar-menu').css({ 'z-index': num });
  }
}
