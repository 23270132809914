import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ViewChildren, QueryList, Input, ViewChild, OnInit, ContentChild, Output, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { ErrorMessages } from '../../helpers';
let nextUniqueId = 0;
import * as libphonenumber from 'google-libphonenumber';
@Component({
  selector: 'app-base-new-form-template',
  templateUrl: './base-new-form-template.component.html',
  styleUrls: ['./base-new-form-template.component.scss']
})
export class BaseNewFormTemplateComponent implements OnInit {
  phoneCode: string;
  partialPhoneNumber: string;
  regionCode = 'KE';
  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  phoneDisabled: boolean;
  @Input() controlName!: any;
  @Input() wrap: boolean;
  @Input() bigger: boolean;
  @Input() newDesign: boolean;
  @Input() darker: boolean;
  @Input() flagSvg?: boolean;
  @Input() appendIt = true;
  @Input() strictMode = true;
  @Input() defaultValidators = false;
  @Input() labelName?: string;
  @Input() underLabel?: string;
  @Input() underInput?: string;
  @Input() size?: number;
  @Input() placeholderName?: string;
  @Input() isDisabled?: boolean;
  @Input() disablePhone?: boolean;
  @Input() disableSpaces?: boolean;
  @Input() rOnlyPhone?: boolean;
  @Input() flagOnly?: boolean;
  @Input() isBackground?: boolean;
  @Input() stepNumber?: number;
  @Input() phoneNumber?: boolean;
  @Input() isColumn?: boolean;
  @Input() sideText?: string;
  @Input() group!: FormGroup;
  @Input() radios?: any[];
  @Input() checkboxes?: any[];
  @Input() options?: any[];
  @Input() bindLabel?: string;
  @Input() borderd?: boolean = true;
  @Input() searchPlaceholder?: string;
  @Input() bindValue?: string;
  @Input() inline?: boolean;
  @Input() rOnly?: boolean;
  @Input() inputType = 'text';
  @Input() inputName = 'default';
  @Input() max?: any;
  @Input() value?: any;
  @Input() minlength?: number;
  @Input() noSearch = true;
  @Input() min?: any;
  @Input() errorMessages: ErrorMessages;
  @Input() addErrorMessages: any[];
  @Input() dates?: Date[];
  @Output() openSelect = new EventEmitter();
  @Output() onPaste = new EventEmitter();
  @ContentChild('append', { static: true }) append?: any;
  invalidPhoneNumber?: boolean;
  formInput: any;
  @ViewChildren('submitButton') submitButtons?: QueryList<any>;
  @ViewChild('select') select: any;
  public uniqueId = `${++nextUniqueId}`;
  ngAfterViewInit(): void {
    this.checkboxChecker();
  }
  ngOnInit(): void {
    this.formInput = this.group;
    if (this.isRequired(this.controlName)) {
      if (!this.errorMessages || !this.errorMessages?.[this.controlName]?.length) {
        this.errorMessages = {
          [this.controlName]: [
            {
              type: 'required',
              message: 'This field is required'
            }
          ]
        };
        if (this.defaultValidators) {
          const defaultValidators = [
            {
              type: 'wrongNumber',
              message: `Please enter a valid Kenyan phone number`
            },
            {
              type: 'email',
              message: `Please enter a valid email address`
            }
          ];
          if (this.addErrorMessages) {
            defaultValidators.push(...this.addErrorMessages);
          }
          this.errorMessages[this.controlName].push(...defaultValidators);
          console.log(this.errorMessages);
        }
      }
    }
  }
  disableSubmitButton(status: boolean) {
    this.submitButtons?.forEach((button) => {
      button.nativeElement.disabled = status;
    });
  }

  isRequired(name: string) {
    if (this.group && !this.formInput) {
      this.formInput = this.group;
    }
    if (this.formInput) {
      const validator =
        this.formInput.get(name) && this.formInput.get(name).validator && this.formInput.get(name).validator({} as AbstractControl);
      return validator && validator.required ? true : false;
    }
    return false;
  }

  showAsterisk(name: string) {
    if (this.group && !this.formInput) {
      this.formInput = this.group;
    }
    if (this.isRequired(name)) {
      return '*';
    }
    return '';
  }

  isFieldInvalid(field: string, errorType?: string) {
    if (this.formInput) {
      if (field === 'password' && errorType === 'minlength') {
        return this.formInput.get(field).hasError('minlength') && this.formInput.get(field).touched;
      } else if (field === 'password' && errorType === 'required') {
        return this.formInput.get(field).hasError('required') && this.formInput.get(field).touched;
      } else {
        return this.formInput.get(field)?.invalid && this.formInput.get(field).touched;
      }
    }
    return false;
  }
  showFieldStyle(field: string) {
    return {
      'has-error': this.isFieldInvalid(field)
    };
  }
  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }
  validateForm(form: FormGroup, skip?: string) {
    const arrayCheck = (fArray: FormArray) => {
      fArray.controls.map((current: any) => {
        if (current instanceof FormControl) {
          current.markAsTouched({ onlySelf: true });
        } else {
          Object.keys(current.controls).forEach((subFieldControl) => {
            const currentControl = current.get(subFieldControl);
            currentControl?.updateValueAndValidity();
            currentControl?.markAsTouched({ onlySelf: true });
          });
        }
      });
    };
    Object.keys(form.controls).forEach((field) => {
      if (field !== skip) {
        const control = form.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }

        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach((subField) => {
            const subControl = control.get(subField);
            subControl?.markAsTouched({ onlySelf: true });

            if (subControl instanceof FormArray) {
              arrayCheck(subControl);
            }
          });
        }
        if (control instanceof FormArray) {
          arrayCheck(control);
        }
      }
    });
  }
  isFieldInvalidNested(parent_field: string, sub_field: string) {
    return this.formInput.get(parent_field).get(sub_field).invalid && this.formInput.get(parent_field).get(sub_field).touched;
  }
  showFieldStyleNested(parent_field: string, sub_field: string) {
    return {
      'has-error': this.isFieldInvalidNested(parent_field, sub_field)
    };
  }
  filter(e: any, search: any) {
    e.filter(search.value);
  }
  search(a: string, b: { viewValue: string }) {
    return b.viewValue.toLowerCase().includes(a.toLowerCase()) ? b : null;
  }
  onOpen(e: any) {
    if (this.rOnly) {
      e.close();
    }
    this.openSelect.emit(false);
    this.resizeSelect();
    // $('.select-template ng-select input.search-input').focus();
  }
  resizeSelect() {
    // const width = $('app-add-agribusiness .select-template ng-select').width();
    // $('app-add-agribusiness .select-template ng-dropdown-panel').css({
    //   width: `${width}px`
    // });
  }
  changeRegion(code: string) {
    this.regionCode = code;
    this.phoneCode = '+' + this.phoneUtil.getCountryCodeForRegion(this.regionCode);
    this.phoneDisabled = false;
    this.group.get('countryPhoneCode').setValue(this.phoneCode);
  }
  onClose() {
    this.openSelect.emit(true);
  }
  onCheckboxChange(event: any, i: number) {
    const selectedValues = this.formInput?.controls?.[this.controlName] as FormArray;
    if (event.target.checked) {
      selectedValues.at(i).setValue(event.target.value);
    } else {
      selectedValues.at(i).setValue(undefined);
    }
  }

  checkboxChecker() {
    if (this.inputType !== 'new-checkbox') return;
    let counter = 0;
    const val = `checkbox-container-${this.uniqueId}`;
    const options = this.group.get(this.controlName).value;
    const checkboxChildren = document.querySelector(`#${val}`)?.children;
    for (let i = 0; i < checkboxChildren.length; i++) {
      //remove check from all children
      const checkbox = checkboxChildren[i].querySelector('input[type="checkbox"]') as any;
      if (checkbox && !options.includes(checkbox?.value)) {
        checkbox.checked = false;
        counter++;
      }
    }
    if (counter === checkboxChildren.length) {
      (this.group.get(this.controlName) as FormArray).controls.forEach((control) => {
        control.setValue(undefined);
      });
      console.log(this.group.get(this.controlName));
    }
    setTimeout(() => {
      this.group.get(this.controlName).markAsUntouched();
    });
  }
  preventNonNumeric(event: any) {
    // if its not a number but dont user keyCode its deprecated
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
    const truncatedValue = numericValue.slice(0, 15); // truncate to 15 characters
    event.target.value = truncatedValue;
    this.group.get(this.controlName).setValue(truncatedValue);
  }
  pasted(event: ClipboardEvent) {
    const pasteEvent = {
      isImage: false,
      val: '',
      event,
      label: this.labelName,
      controlName: this.controlName,
      previousValue: this.group.get(this.controlName).value
    };
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type === 'image/png' || items[i].type === 'image/jpeg') {
        pasteEvent.isImage = true;
        this.onPaste.emit(pasteEvent);
        // uncomment when we care about file paste
        // const file = items[i].getAsFile();

        // if (file) {
        //   const reader = new FileReader();
        //   reader.onload = () => {
        //     const imageDataUrl = reader.result as string;
        //     console.log('Image data URL:', imageDataUrl);
        //     this.onPaste.emit(imageDataUrl)
        //   };
        //   reader.readAsDataURL(file);
        // }
      } else {
        items[i]?.getAsString((e) => {
          pasteEvent.val = e;
          this.onPaste.emit(pasteEvent);
        });
      }
    }
  }
  onInput(e: KeyboardEvent) {
    // disable spaces
    if (e.key === ' ' && this.disableSpaces) {
      e.preventDefault();
    }
  }
}
