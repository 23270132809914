import { PaginationInfo } from '@avenews/agt-sdk';
import { ShellConfig } from '@avenews/shell';
import { environment } from '../../../environments/environment';
export const env = environment;
export const shellConfig: ShellConfig = {
  shared: {
    env
  },
  sidebar: [
    {
      path: '/home',
      title: 'Home',
      type: 'link',
      icontype: 'home',
      neededPermission: ['avenews-admin']
    },
    {
      path: '/organizations',
      title: 'Organizations',
      type: 'link',
      icontype: 'users',
      neededPermission: ['avenews-admin']
    },
    {
      path: '/users',
      title: 'Users',
      type: 'link',
      icontype: 'users',
      neededPermission: ['avenews-admin']
    },
    {
      path: '/distributors',
      mainPath: 'distributors',
      title: 'Distributors',
      type: 'link',
      icontype: 'users',
      neededPermission: ['avenews-admin'],
      active: false,
      open: false,
      dropdown: [
        {
          path: 'distributors/all',
          title: 'Distributors',
          type: 'link'
        },
        {
          path: 'distributors/transactions/all',
          title: 'Transactions',
          type: 'link'
        }
      ]
    },
    {
      path: '/agents',
      mainPath: 'agents',
      title: 'Agents',
      type: 'link',
      icontype: 'users',
      neededPermission: ['avenews-admin'],
      active: false,
      open: false
    },
    {
      path: '/agribusinesses',
      title: 'Agribusinesses',
      type: 'link',
      icontype: 'agribusiness',
      neededPermission: ['avenews-admin']
    },
    // {
    //   path: '/invoice',
    //   title: 'Invoices',
    //   type: 'link',
    //   icontype: 'forms',
    //   neededPermission: ['avenews-admin']
    // },
    {
      path: '/transactions',
      mainPath: 'transactions',
      title: 'Transactions',
      type: 'link',
      icontype: 'pay',
      neededPermission: ['avenews-admin'],
      active: false,
      open: false,
      dropdown: [
        {
          path: 'transactions/all',
          title: 'All',
          type: 'link'
        },
        {
          path: 'transactions/internal',
          title: 'Internal',
          type: 'link'
        },
        {
          path: 'transactions/pending',
          title: 'Bank pending approval',
          type: 'link'
        },
        {
          path: 'transactions/bank',
          title: 'Bank',
          type: 'link'
        },
        {
          path: 'transactions/mobile',
          title: 'Mobile',
          type: 'link'
        },
        {
          path: 'transactions/topup',
          title: 'Topups',
          type: 'link'
        },
        //Will use in future
        // {
        //   path: 'transactions/user-topup',
        //   title: 'User topups',
        //   type: 'link'
        // },
        {
          path: 'transactions/users-repayments',
          title: 'Users repaymnets',
          type: 'link'
        }
      ]
    },
    // {
    //   path: '/finance',
    //   mainPath: 'finance',
    //   title: 'Finance',
    //   type: 'link',
    //   icontype: 'finance',
    //   neededPermission: ['avenews-admin'],
    //   active: false,
    //   open: false,
    //   dropdown: [
    //     {
    //       path: 'finance/new-applications',
    //       title: 'New',
    //       type: 'link'
    //     },
    //     {
    //       path: 'finance/data-analysis',
    //       title: 'Data',
    //       type: 'link'
    //     },
    //     {
    //       path: 'finance/risk-assessment',
    //       title: 'Risk',
    //       type: 'link'
    //     },
    //     {
    //       path: 'finance/approved',
    //       title: 'Approved',
    //       type: 'link'
    //     },
    //     {
    //       path: 'finance/declined',
    //       title: 'Declined',
    //       type: 'link'
    //     }
    //   ]
    // },
    {
      path: '/orders',
      title: 'Orders',
      type: 'link',
      icontype: 'orders',
      neededPermission: ['avenews-admin']
    },
    {
      path: '/trade-desk',
      title: 'Trade desk',
      type: 'link',
      icontype: 'tradeDesk',
      neededPermission: ['avenews-agent'],
      dropdown: [
        {
          path: '/trade-desk',
          title: 'Trade desk',
          type: 'link'
        },
        {
          path: '/loan-files',
          title: 'Loan files',
          type: 'link'
        }
      ]
    },
    {
      path: '/trade-desk',
      mainPath: 'trade-desk',
      title: 'Trade desk',
      type: 'link',
      icontype: 'tradeDesk',
      active: false,
      open: false,
      neededPermission: ['avenews-admin', 'avenews-financial'],
      dropdown: [
        {
          path: '/trade-desk',
          title: 'Trade desk',
          type: 'link'
        },
        // {
        //   path: '/portfolio-monitor',
        //   title: 'Portfolio monitor',
        //   type: 'link'
        // },
        {
          path: '/loan-files',
          title: 'Loan files',
          type: 'link'
        }
      ]
    },
    {
      path: '/settings/change-password',
      title: 'Change password',
      type: 'link',
      icontype: 'settings',
      neededPermission: ['avenews-admin', 'avenews-agent']
    }
  ],
  header: [
    {
      icon: 'log-out',
      logout: true,
      title: 'Logout'
    }
  ]
};
export const authConfig = {
  environment,
  login: {
    hideSignUp: true,
    isAdmin: true,
    hidePhoneLogin: true,
    hideForgotPassword: true,
    environment,
    title: 'Avenews Admin Panel',
    socialLogin: false,
    logoIcon: 'assets/images/avenews-logos/avenews-icon-logo.svg',
    logo: 'assets/images/avenews-logos/avenews-logo.svg',
    icon: 'assets/images/avenews-logos/expired.svg'
  },
  registartion: {
    environment,
    title: 'Everything you need to manage your',
    logo: 'assets/images/avenews-logos/avenews-logo.svg',
    withBlue: true,
    blueTitle: 'Agribusiness',
    socialLogin: false,
    infoCard: [
      {
        label: 'Digitize your supply chain.',
        infoContent: 'Onboard your network of farmers to avenews, and create a secure and transparent ecosystem for your business.'
      },
      {
        label: 'Boost trade.',
        infoContent: 'Create your own trade documents in seconds. No more paper. No more handshakes. No more verbal agreements.'
      },
      {
        label: 'Expand operations.',
        infoContent:
          'Invite your field agents, agronomists, extenstion officers, and any other team member to work with you on avenews in real-time.'
      }
    ]
  }
};
export const defaultPagination = {
  options: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '_id',
    sortOrder: 'desc'
  } as PaginationInfo
};
