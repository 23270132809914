<div class="text-slate-900 rounded-lg bg-white p-4 mt-2">
  <ng-container *ngIf="!loading">
    <div class="w-full mt-4">
      <ng-container *ngIf="kyc; else noKyc">
        <div class="details-container w-full">
          <div class="status-holder">
            <div class="d-flex justify-content-end">
              <span
                [ngClass]="{ 'no-click': currentUser?.policyRole?.name === 'avenews-agent' }"
                [matMenuTriggerFor]="menu"
                class="touch click status status-{{ kycStatus | statuses : statuses : true }}"
                >{{ kycStatus | statuses : statuses }}</span
              >
            </div>
            <mat-menu #menu="matMenu">
              <button *ngFor="let status of statuses | slice : 1" mat-menu-item>
                <span (click)="changeStatus(status?.status)" class="status status-{{ status?.color }}">{{
                  status?.status | statuses : statuses
                }}</span>
              </button>
            </mat-menu>
          </div>
          <div class="my-5">
            <av-info-view [list]="list"></av-info-view>
          </div>
          <div class="personal-images">
            <h2 class="title text-slate-800 font-medium text-xl">Pictures</h2>
            <div class="card border-gray-300">
              <div class="title mb-3 max-w-lg">
                <p class="text-slate-800 text-2xl">ID Front</p>
                <div class="image-holder">
                  <div class="iconner hidden">
                    <div (click)="openOverlay(kyc?.images?.front)" class="icon-holder">
                      <i-feather name="maximize-2"></i-feather>
                    </div>
                  </div>
                  <img [src]="kyc?.images?.front" alt="" />
                </div>
              </div>
              <div class="title mb-3 max-w-lg">
                <p class="text-slate-800 text-2xl">ID Back</p>
                <div class="image-holder">
                  <div class="iconner hidden">
                    <div (click)="openOverlay(kyc?.images?.back)" class="icon-holder">
                      <i-feather name="maximize-2"></i-feather>
                    </div>
                  </div>
                  <img [src]="kyc?.images?.back" alt="" />
                </div>
              </div>
              <div class="title mb-3 max-w-lg">
                <p class="text-slate-800 text-2xl">Live Face</p>
                <div class="image-holder">
                  <div class="iconner hidden">
                    <div (click)="openOverlay(kyc?.images?.face)" class="icon-holder">
                      <i-feather name="maximize-2"></i-feather>
                    </div>
                  </div>
                  <img [src]="kyc?.images?.face" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noKyc>
        <div class="text-center">
          <img width="350" height="350" class="mx-auto" src="../../../assets/images/empty-states/kyc-empty.svg" />
          <p class="text-2xl font-medium">No KYC for this client</p>
          <p class="text-xl text-gray-500">This client hasn't done the KYC check</p>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="w-full flex justify-center">
      <img width="150px" height="100px" src="../../../assets/loading.gif" alt="" />
    </div>
  </ng-container>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="isOpen">
  <div
    (click)="checkClose($event, img)"
    class="overlay-container backdrop-brightness-0 fixed back w-screen h-screen flex justify-center items-center"
  >
    <div #img class="relative">
      <div (click)="isOpen = false" class="icon rounded-b-sm cursor-pointer bg-slate-900 absolute hidden top-0 right-0 p-2">
        <i-feather class="text-white" name="x"></i-feather>
      </div>
      <img class="w-full h-full select-none" [src]="passedImage" alt="" />
    </div>
  </div>
</ng-template>
