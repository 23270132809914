<div class="empty-state" *ngIf="!agribusiness?.performances?.length">
  <div>
    <img src="../../../assets/images/svgs/Group 77.svg" alt="" />
    <p class="empty-title">No performance history</p>
  </div>
</div>
<div *ngIf="agribusiness?.performances?.length > 0" class="default-wrapper">
  <div class="wrapper">
    <div class="table-wrapper">
      <p class="main-title">Performance history</p>
      <table class="table-blue">
        <thead>
          <tr class="blue-tr">
            <th class="id">ID *</th>
            <th class="desc">DESCRIPTION *</th>
            <th>AVG AMOUNT *</th>
            <th>DOCUMENTATION *</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-body-tr" *ngFor="let perform of agribusiness?.performances; let i = index">
            <td>
              <div class="form col-md-4">
                <input class="input" value="#{{ i + 1 }}" type="text" readonly />
              </div>
            </td>
            <td class="desc-td">
              <textarea readonly class="input" value="{{ perform?.description }}" type="text"></textarea>
            </td>
            <td class="td-amount">
              <div class="form-append">
                <input readonly class="supplier-input form-group col-md-12" type="number" [value]="perform?.avgAmount" />
                <div class="appended">KES</div>
              </div>
            </td>
            <td>
              <div class="form col-md-12">
                <input
                  #inputTooltip="matTooltip"
                  matTooltip="{{ perform?.documents[0]?.fileName }}.{{ perform?.documents[0]?.type }}"
                  matTooltipClass="agri-files-tooltip"
                  matTooltipPosition="above"
                  class="input file-input"
                  value="{{ perform?.documents[0]?.fileName }}.{{ perform?.documents[0]?.type }}"
                  type="text"
                  readonly
                />
                <span
                  #tooltip="matTooltip"
                  [matTooltip]="getFileNames(perform?.documents)"
                  matTooltipClass="agri-files-tooltip"
                  matTooltipPosition="above"
                  class="more"
                  *ngIf="perform.documents.length > 1"
                  >+{{ perform.documents.length - 1 }}</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
