<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'deal-list'"
  [totalCount]="totalCount"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (changeP)="pageChanged($event)"
  (sort)="manualSort($event)"
  (sendFilters)="manualFilter($event)"
>
  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'USER NAME' && col?.name !== 'PERIOD NAME' && col?.name !== 'REPAYMENT DATE'"
      [ngTemplateOutlet]="td"
    ></ng-container>

    <ng-container *ngIf="col?.name === 'USER NAME'">
      <td>{{ item?.owner?.personalInformation?.firstName }} {{ item?.owner?.personalInformation?.lastName }}</td></ng-container
    >
    <ng-container *ngIf="col?.name === 'REPAYMENT DATE'">
      <td>
        {{ item.period?.repaymentOption?.details?.[item.period?.repaymentOption?.details?.length - 1]?.date | date : 'dd/MM/YYYY' }}
      </td></ng-container
    >

    <ng-container *ngIf="col?.name === 'PERIOD NAME'">
      <td>{{ periodName(item) || '-' }}</td></ng-container
    >
  </ng-template>
</agt-nosdk-list>
<!-- <repayments-modal fadeSidebar modalId="repaymentModal" [period]="period"></repayments-modal> -->
