import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'showFieldStyle'
})
export class ShowFieldStylePipe implements PipeTransform {
  constructor() {}
  transform(formInput: AbstractControl, field?: string) {
    return this.isFieldInvalid(field, formInput) ? 'has-error' : '';
  }
  isFieldInvalid(field: string, formInput: AbstractControl) {
    if (formInput) {
      if (formInput instanceof FormControl) {
        return formInput.invalid && formInput.touched;
      } else {
        return formInput.get(field)?.invalid && formInput.get(field).touched;
      }
    }

    return false;
  }
}
