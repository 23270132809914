import { User } from '@avenews/agt-sdk';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService, SpinnerToggleService } from '@avenews/shared-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatExpansionPanel } from '@angular/material/expansion';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EMPTY, catchError } from 'rxjs';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'td-signoff',
  templateUrl: './td-signoff.component.html',
  styleUrls: ['./td-signoff.component.scss'],
  animations: [
    trigger('rotate', [
      state('true', style({ transform: 'rotate(180deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('true => false', animate('300ms ease-out')),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class TDSignoffComponent implements OnInit {
  @Input() loan: any;
  @Input() user: User;
  @Input() mlScoreData: any;
  @Input() originalOffer: any;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  biro: any;
  kyc: any;
  panelOpenState = false;
  signoffs = [
    {
      action: 'risk',
      data: {},
      name: 'Risk'
    },
    {
      action: 'data',
      data: {},
      name: 'Data'
    },
    {
      action: 'tdQuestions',
      data: {},
      name: 'TD Questions'
    },
    {
      action: 'mlQuestions',
      data: {},
      name: 'ML Questions'
    },
    {
      action: 'decisionTree',
      data: {},
      name: 'Decision Tree'
    }
  ];
  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private alerts: AlertService,
    private router: Router,
    private spinner: SpinnerToggleService
  ) {}
  ngOnInit(): void {
    this.route.data.subscribe(({ loan }) => {
      this.loan = loan;
      this.prepareSignoffs();
    });
  }
  prepareSignoffs() {
    const signoffs = this.loan?.signOff;
    // signoffs is an object with keys as action names and values as data
    if (signoffs) {
      Object.keys(signoffs).forEach((action) => {
        this.signoffs.find((signoff) => signoff.action === action).data = signoffs[action];
      });
      console.log(this.signoffs);
    }
  }

  signOff(action: string) {
    this.spinner.showSpinner();
    this.http
      .put(`${environment.new_api_url}/api/loan-application/${this.loan?._id}/signoff`, {
        action
      })
      .pipe(
        catchError((err) => {
          this.alerts.showAlert(err?.error?.message, 'danger');
          this.spinner.hideSpinner();
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        this.alerts.showAlert('Successfully signed off', 'success');
        this.loan = data;
        this.prepareSignoffs();
        // this.router.navigateByUrl(this.router.url);
      });
  }
}
