<div class="modal-box" id="addOrganizationWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>New organization</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div [formGroup]="organizationForm" class="modal-box-content">
      <p class="org-details">Organization details</p>
      <div>
        <div class="form-pair">
          <app-base-form-template
            labelName="Business name"
            inputType="text"
            inputName="default"
            controlName="businessName"
            [group]="organizationForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Registration number"
            inputType="text"
            inputName="default"
            controlName="registrationNumber"
            [group]="organizationForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <app-base-form-template
            labelName="Partner"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="partner"
            [options]="partner"
            [group]="organizationForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Type"
            bindLabel="viewValue"
            bindValue="value"
            inputType="select"
            inputName="select"
            controlName="type"
            placeholderName="Select type"
            [options]="type"
            [group]="organizationForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>

        <div class="form-pair">
          <app-base-form-template
            labelName="Business address"
            inputType="text"
            inputName="default"
            controlName="businessAddress"
            [group]="organizationForm"
            [size]="6"
          >
          </app-base-form-template>

          <div class="form-group">
            <label for="valueChain">Value chain</label>
            <div class="value-chain">
              <input [formControl]="valueChainControl" type="text" />
              <button (click)="newProduct()" class="small-add">Add</button>
            </div>
          </div>
        </div>
        <div class="form-pair">
          <div class="docs-wrap">
            <label class="label-docs" for="documents">Documents</label>
            <div class="file-uploader">
              <input
                name="file"
                class="input-file"
                (change)="uploadDocs($event)"
                multiple
                accept="{{ acceptedMimeTypes?.join(',') }}"
                #fileUpload
                type="file"
              />

              <button class="upload-btn flex justify-center items-center" (click)="fileUpload.click()">
                <i-feather name="upload"></i-feather> Upload
              </button>
            </div>
            <div class="full-state">
              <div *ngFor="let file of filesArray?.controls; let i = index">
                <div class="uploaded-documents">
                  <p>{{ file?.value?.fileName }}</p>
                  <i-feather class="cursor-pointer" (click)="removeFile(i)" name="trash2"></i-feather>
                </div>
              </div>
            </div>
            <div class="error-messages" *ngIf="emptyDocsError && !this.organizationForm.get('documents').value.length">
              <p>Proof of delivery is required</p>
            </div>
          </div>
          <div class="chains-wrapper">
            <div *ngFor="let chain of valueChains?.controls; let i = index" class="values">
              <p>{{ chain.value }}</p>
              <i-feather (click)="removeProduct(i)" name="x"></i-feather>
            </div>
          </div>
        </div>
        <hr />
        <p class="link-user">Agent app user?</p>
        <app-base-form-template
          labelName=""
          inputType="radio"
          [radios]="yesNoOptions"
          [strictMode]="false"
          [isColumn]="true"
          inputName="radio"
          controlName="isAgent"
          [size]="12"
          [group]="organizationForm"
        ></app-base-form-template>
        <div>
          <div class="form-pair">
            <app-base-form-template
              labelName="First name"
              inputType="text"
              inputName="default"
              controlName="firstName"
              [group]="organizationForm"
              [size]="6"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Last name"
              inputType="text"
              inputName="default"
              controlName="lastName"
              [group]="organizationForm"
              [size]="6"
            >
            </app-base-form-template>
          </div>
          <div class="form-pair">
            <app-base-form-template
              labelName="Phone number"
              inputType="tel"
              inputName="phoneInput"
              controlName="phoneNumber"
              [group]="organizationForm"
            >
            </app-base-form-template>
            <app-base-form-template
              labelName="Email"
              inputType="text"
              inputName="default"
              controlName="email"
              [group]="organizationForm"
              [size]="6"
            >
            </app-base-form-template>
          </div>
        </div>
        <!-- <div *ngIf="!isNewUser && isExistUser" class="form-group">
          <label class="user-acc">User account</label>
          <div *ngIf="!user" (click)="openModal()" class="select-user">
            <p>Select user</p>
            <i-feather name="chevron-down"></i-feather>
          </div>
          <div (click)="openModal()" *ngIf="user" class="select-user">
            <p class="selected-user">{{ user?.personalInformation | beneficiaryName | titlecase }}</p>
            <i-feather name="chevron-down"></i-feather>
          </div>
        </div> -->

        <div class="topup-modal-footer">
          <button (click)="create()" class="btn btn-primary">Create</button>
        </div>
      </div>
    </div>
  </div>
</div>
<link-user-to-deal-modal [isNotOrganization]="false" (getUser)="getUser($event)" [users]="users"></link-user-to-deal-modal>
