<div class="top-header">
  <div>
    <img class="avenews-logo" src="{{ loginConfig?.logo }}" alt="Avenwes-GT" />
  </div>
  <div class="flex-text-btn">
    <p class="gray">New to {{ loginConfig?.title }}?</p>
    <av-button routerLink="/registration" label="Sign up" type="secondary" size="small"></av-button>
  </div>
</div>
<div class="flex-wrapper">
  <div class="container">
    <div class="panel">
      <div class="top-head">
        <a routerLink="/login"><i-feather name="chevron-left"></i-feather> Return to sign in</a>
        <img class="logo-icon" src="{{ loginConfig?.logoIcon }}" alt="avenews" />
      </div>
      <div class="forgot-password-form" *ngIf="!isResetPassword">
        <form [formGroup]="forgotPasswordForm">
          <div class="">
            <p class="forgot-title">Forgot your password?</p>
            <p class="email-enter">
              Enter the email address with which you signed up to Avenews and we will send you an email with a link to reset your password.
            </p>
            <app-base-form-template
              labelName="Email"
              inputType="text"
              inputName="default"
              [size]="12"
              controlName="email"
              [group]="forgotPasswordForm"
            >
            </app-base-form-template>
          </div>
        </form>
        <av-button type="primary" size="stretched" label="Submit" (click)="recoverPassword()"> </av-button>
      </div>

      <div *ngIf="isResetPassword" class="a-panel-body">
        <div class="reset-pass">
          <div class="sa">
            <div class="icon"></div>
            <div class="check"></div>
          </div>
        </div>
        <div>
          <p class="title-center">Reset Password</p>
          <p class="reset">
            If you have an account with us, an email will be sent to you with a link to reset your password. If you did not find the email
            in the main box, it may have reached spam or another box.
          </p>
        </div>
      </div>
    </div>
    <div class="footer">
      <a href="../../assets/pdfs/kenya-Terms-of-use.docx.pdf" target="_blank">Terms of Use</a>
      <a class="policy" href="../../assets/pdfs/kenya-privacy-policy.pdf" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
