import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Transaction, FilterOptions, Status, Action } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';
import { ListNoSdkComponent, BreadCrumbs } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import * as $ from 'jquery';

@Component({
  selector: 'frontend-user-topups-list',
  templateUrl: './user-topups-list.component.html',
  styleUrls: ['./user-topups-list.component.scss']
})
export class UserTopupsListComponent extends ListNoSdkComponent<Transaction> implements OnInit {
  isOpen = true;
  topup: any;
  contactGroupTotal = 0;
  groupName: string;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alertService: AlertService, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'topups';
    this.exportId = 'main-farmer-export';
  }
  ngOnInit(): void {
    super.init();
    this.listConfig = {
      emptyState: {
        title: 'No internal transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        { label: 'Approve', type: 'default', action: 'approve' },
        { label: 'Decline', type: 'default', action: 'decline' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['NAME', 'ACTIONS'],
      mainTitle: 'Topups',

      columns: [
        {
          name: 'ISSUER',
          dtoName: 'beneficiary.name',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'RECEIVER',
          dtoName: 'creditor.name',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'CASHBACK',
          dtoName: 'cashback',
          type: 'text',
          pipe: 'text',
          selected: true
        },
        {
          name: 'Date Created',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },

        {
          name: 'ACTIONS',
          actions: [{ label: 'Status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'ISSUER',
          dtoName: 'creditor.creditor.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'RECEIVER',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          type: 'text'
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number'
        },
        {
          name: 'Date Created',
          dtoName: 'dateCreated',
          type: 'date'
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text'
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          type: 'text'
        }
      ]
    };
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  approveTopup(e: any) {
    this.topup = e;
    $('#approveUserTopupWizard').css('display', 'flex');
  }
  declineTopup(e: any) {
    this.topup = e;
    $('#declineTopupWizard').css('display', 'flex');
  }

  openStatus(transaction: Transaction) {}
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'approve':
        this.approveTopup(e.entity);
        break;
      case 'decline':
        this.declineTopup(e.entity);
        break;
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
}
