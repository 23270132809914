<div class="modal-box" id="repaymentModal">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Set repayment</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="repaymnet-header">
        Top up for {{ period?.owner | fullname | titlecase }} - {{ period?.deal.name }} - {{ period?.['name'] }}
      </p>
      <p class="repaymnet-amount">
        Remaining repayment: <span>{{ period?.repaymentOption?.repaymentAmount | roundDownNum }}KES</span>
      </p>
      <form [formGroup]="repaymentForm">
        <div class="form-pair">
          <app-base-form-template
            labelName="Amount repaid"
            bindLabel="viewValue"
            bindValue="value"
            inputType="number"
            inputName="default"
            controlName="amountRepaid"
            [group]="repaymentForm"
            [errorMessages]="errorMessages"
            [size]="6"
          >
            <span #append append>KES</span>
          </app-base-form-template>
          <app-base-form-template
            [errorMessages]="errorMessages"
            labelName="Repayment date"
            inputType="date"
            inputName="date"
            controlName="repaymentDate"
            [group]="repaymentForm"
            [size]="6"
            placeholderName="DD/MM/YYYY"
          >
          </app-base-form-template>
        </div>
        <app-base-form-template
          labelName="Method"
          bindLabel="viewValue"
          bindValue="value"
          inputType="select"
          [noSearch]="true"
          inputName="select"
          controlName="paymentMethod"
          [placeholderName]="'Choose method'"
          [options]="options"
          [group]="repaymentForm"
          [size]="6"
        >
        </app-base-form-template>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="submit()">Save</button>
    </div>
  </div>
</div>
