import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult, User } from '@avenews/agt-sdk';

@Injectable()
export class AgentCommissionsResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve(activeRoute: ActivatedRouteSnapshot) {
    const id = activeRoute.params['id'];
    let url = `/api/commissions/agent/${id}/10/1/_id/desc`;

    return (this.http.get(`${environment.new_api_url}${url}`) as Observable<PaginatedResult<any>>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
