import { catchError, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AlertService } from '@avenews/alerts';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'td-kyc-checklist',
  templateUrl: './td-kyc-checklist.component.html',
  styleUrls: ['./td-kyc-checklist.component.scss']
})
export class TdKycCheckListComponent implements OnInit, OnChanges {
  checklistSelection = new SelectionModel<any>(true /* multiple */);
  loading = true;
  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      completed: node.completed,
      node
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  @Input() loan: any;
  @Input() user: any;
  @Input() mlScoreData: any;
  checklist: any;
  environment = environment;
  url: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private alerts: AlertService
  ) {
    // this.dataSource.data = TREE_DATA;
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    const baseUrl = 'https://ir6sfiae54.execute-api.us-east-1.amazonaws.com';
    if (this.environment.new_api_url.includes('qa')) {
      this.url = `${baseUrl}/qa`;
    } else if (this.environment.new_api_url.includes('staging')) {
      this.url = `${baseUrl}/staging`;
    } else if (this.environment.production) {
      this.url = `${baseUrl}/prod`;
    }
    console.log(this.loan);
    const tree_data = [];
    this.http.get(`${this.url}/${this.loan?._id}`).subscribe((data: { files: any[] }) => {
      this.loading = false;
      this.checklist = data;
      console.log(data);
      data?.files?.forEach((data) => {
        data['completed'] = true;
        const children = data?.tasks?.map?.((task) => {
          const node = {
            name: task?.name,
            expandable: false,
            completed: task?.completed
          };
          return node;
        });

        const file = {
          name: data.name,
          children
        };
        tree_data.push(file);
      });
      this.dataSource.data = tree_data;
      this.dataSource.data.forEach((data) => {
        data?.children?.forEach((node) => {
          const note = {
            expandable: false,
            level: 1,
            name: node.name,
            completed: node.completed
          };
          this.todoLeafItemSelectionToggle(note);
        });
      });
      console.log(this.dataSource);
    });
  }
  hasChild = (_: number, node: any) => node.expandable;
  getLevel = (node: any) => node.level;

  descendantsAllSelected(node: any): boolean {
    try {
      const descendants = this.treeControl?.getDescendants(node);
      const descAllSelected =
        descendants.length > 0 &&
        descendants.every((child) => {
          return child.completed;
        });
      return descAllSelected;
      // const descAllSelected =
      //   descendants.length > 0 &&
      //   descendants.every((child) => {
      //     return this.checklistSelection.isSelected(child);
      //   });
      // return descAllSelected;
    } catch (err) {
      return false;
    }
  }

  descendantsPartiallySelected(node: any): boolean {
    try {
      const descendants = this.treeControl.getDescendants?.(node);
      const result = descendants.some((child) => child?.completed);
      return result && !this.descendantsAllSelected(node);
    } catch (err) {
      return false;
    }
  }

  todoItemSelectionToggle(node: any): void {
    const allSelected = this.descendantsAllSelected(node);
    if (allSelected) {
      this.checklistSelection.deselect(node);
    } else {
      this.checklistSelection.select(node);
    }

    // this.checklistSelection.toggle(node);
    const val = !allSelected;
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => (child.completed = val));
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: any): void {
    let parent: any | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }
  checkRootNodeSelection(node: any): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      node.completed = false;
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      node.completed = true;
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  todoLeafItemSelectionToggle(node: any): void {
    node.completed = !node.completed;
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
  checkAll() {
    this.spinner.showSpinner();
    const roots = this.treeControl.dataNodes.filter((data) => {
      return data?.level === 0;
    });
    const newArr = [];
    const data = this.dataSource.data.slice();
    roots.forEach((node) => {
      const obj = {
        name: node.name,
        tasks: []
      };
      const descendants = this.treeControl.getDescendants(node);

      obj['tasks'] = descendants.map((child) => {
        return {
          name: child.name,
          completed: child?.completed
        };
      });
      newArr.push(obj);
    });
    this.checklist.files = newArr;
    this.http
      .put(`${this.url}/${this.loan?._id}`, this.checklist)
      .pipe(
        catchError((err) => {
          this.spinner.hideSpinner();
          this.alerts.showAlert(err.error.message, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((data) => {
        this.spinner.hideSpinner();
        this.alerts.showAlert('Checklist updated successfully', 'success');
      });
  }
}
