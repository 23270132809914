import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';

@Component({
  selector: 'frontend-decline-user-repayment',
  templateUrl: './decline-user-repayment.component.html',
  styleUrls: ['./decline-user-repayment.component.scss']
})
export class DeclineUserRepaymentComponent implements OnInit {
  @Input() repayment: any;
  alertService: any;
  environment = environment;
  constructor(private spinner: SpinnerToggleService, private http: HttpClient, private router: Router) {}

  ngOnInit() {}
  close() {
    $('#declineUserRepaymentWizard').fadeOut();
  }
  decline(ans: boolean) {
    if (ans) {
      this.spinner.showSpinner();
      this.http
        .put(
          `${this.environment.new_api_url}/api/deal/${this.repayment.deal._id}/period/${this.repayment.period._id}/repayment/${this.repayment._id}/status`,
          {
            status: 'declined'
          }
        )
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.router.navigate(['/transactions/users-repayments']);
            $('#declineUserRepaymentWizard').fadeOut();
            return EMPTY.pipe();
          })
        )
        .subscribe((data: any) => {
          $('#declineUserRepaymentWizard').fadeOut();
          this.router.navigate(['/transactions/users-repayments']);
          this.spinner.hideSpinner();
        });
    }
    $('#declineUserRepaymentWizard').fadeOut();
  }
}
