<base-modal>
  <base-modal-header header="Change Documets Status"></base-modal-header>
  <base-modal-body>
    <form [formGroup]="statusForm">
      <app-base-form-template
        labelName="Status"
        bindLabel="viewValue"
        bindValue="value"
        inputType="select"
        inputName="select"
        controlName="status"
        placeholderName="Change status"
        [options]="statusSelect"
        [group]="statusForm"
        [size]="6"
      >
      </app-base-form-template>
    </form>

    <div class="topup-modal-footer">
      <button class="btn btn-primary" (click)="changeStatus()">Update Status</button>
    </div>
  </base-modal-body>
</base-modal>
