<div class="modal-box" id="approveUserRepaymentWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>Approve repayment</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="modal-title">
        {{ repayment?.owner?.personalInformation | beneficiaryName | titlecase }} - {{ repayment?.deal?.name }} - #{{
          repayment?.period?.numericId
        }}
      </p>
      <p class="modal-amount">
        Remaining repayment amount: <span>{{ repayment?.reportedAmount | number: '1.0-2' }}KES</span>
      </p>
      <p class="modal-extra-info">
        You are about to approve a repayment for {{ repayment?.owner?.personalInformation | beneficiaryName | titlecase }} of an amount of:
      </p>
      <div (keyup.enter)="submit()" [formGroup]="repaymentForm">
        <app-base-form-template
          labelName="Amount"
          [strictMode]="false"
          inputType="number"
          inputName="default"
          controlName="amount"
          [errorMessages]="errorMessages"
          [group]="repaymentForm"
          [size]="6"
        >
          <span #append append>KES</span>
        </app-base-form-template>
      </div>
      <p class="check-amount">*Check that the amount you approve matches the amount entered by the user</p>
    </div>

    <div class="bttn-wrapper">
      <button (click)="submit()" class="approve">Approve</button>
    </div>
  </div>
</div>
