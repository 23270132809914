<av-breadcrumbs-template [breadCrumbs]="breadCrumbs" [moduleName]="'Trade desk'"></av-breadcrumbs-template>
<agt-nosdk-list
  [list]="list"
  [listConfig]="listConfig"
  [itemsPerPage]="itemsPerPage"
  [currentPage]="currentPage"
  [exportId]="exportId"
  [listId]="'base-approved-list'"
  [totalCount]="totalCount"
  (addButton)="goToBuilder()"
  [originalTotalCount]="originalTotalCount"
  (changeItemsPerPage)="changePageSize($event)"
  (menuActions)="actions($event)"
  (sendFilters)="filter($event)"
  (sort)="sortData($event)"
  (changeP)="pageChanged($event)"
>
  <!-- (sendFilters)="manualFilter($event)"
  (sort)="manualSort($event)" -->
  <ng-container header>
    <div class="custom-search">
      <input
        placeholder="Search here..."
        class="search-bar"
        type="search"
        [ngModel]="searchTerm"
        (ngModelChange)="search($event)"
        name=""
        id=""
      />
      <span></span>
    </div>
  </ng-container>

  <ng-template #column let-col="col" let-item="item" let-td="template">
    <ng-container
      *ngIf="col?.name !== 'STATUS' && col?.name !== 'SOURCE' && col?.name !== 'USER REQUESTED AMOUNT' && col?.name !== 'REQUESTED AMOUNT'"
      [ngTemplateOutlet]="td"
    ></ng-container>
    <ng-container *ngIf="col?.name === 'STATUS'">
      <td>
        <p class="status status-{{ item?.status | statuses : statuses : true }}">
          {{ item?.status | statuses : statuses }}
        </p>
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'SOURCE'">
      <td>
        <p [ngClass]="{ 'status status-purple': item?.source === 'Inputs Network' }">{{ item?.source }}</p>
      </td></ng-container
    >
    <ng-container *ngIf="col?.name === 'USER REQUESTED AMOUNT'">
      <td>
        <ng-container *ngIf="item?.userRequestedAmount != null">
          {{ item?.userRequestedAmount }}
        </ng-container>
        <ng-container *ngIf="item?.userRequestedAmount == null">
          <div class="loader-gif">
            <img src="../../../assets/loading.gif" alt="" />
          </div>
        </ng-container>
      </td>
    </ng-container>
    <ng-container *ngIf="col?.name === 'REQUESTED AMOUNT'">
      <td>
        <ng-container *ngIf="item?.loanAmount != null">
          {{ item?.loanAmount }}
        </ng-container>
        <ng-container *ngIf="item?.loanAmount == null">
          <div class="loader-gif">
            <img src="../../../assets/loading.gif" alt="" />
          </div>
        </ng-container>
      </td>
    </ng-container>
  </ng-template>
</agt-nosdk-list>
<frontend-change-application-status [application]="application"></frontend-change-application-status>
<frontend-reason-modal [title]="reasonTitle" (submitted)="redFlag($event)"></frontend-reason-modal>
