export interface Question {
  score: number;
  label: string;
  type: InputTypes;
  value?: string;
  placeholder: string;
  size?: number;
  disabled?: boolean;
  validators?: Validator[];
  errorMessages?: ErrorMessages;
  multipleChoices?: MultipleChoices[];
  active?: boolean;
  _id?: string;
}

export type InputTypes = 'text' | 'number' | 'date' | 'radio' | 'checkbox' | 'select';
export interface ErrorMessages {
  [key: string]: {
    type: string;
    message: string;
    messageKey?: string;
  }[];
}
export interface MultipleChoices {
  value: string;
  label?: string;
}
export interface Validator {
  name: string;
  value?: any; // if a validator is max or min then this is the value
}
