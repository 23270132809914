import { OrdersComponent } from './orders/orders.component';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersResolver } from './resolvers/orders.resolver';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderResolver } from './resolvers/order.resolver';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'orders',
      component: OrdersComponent,
      resolve: { orders: OrdersResolver },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'orders/:id',
      component: OrderViewComponent,
      resolve: { order: OrderResolver },
      runGuardsAndResolvers: 'always'
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule {}
