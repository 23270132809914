<div class="side-view-container">
  <div class="side-view-header">
    <p>{{ topTitle | titlecase }}</p>
  </div>
  <div class="side-content-container">
    <div class="side-view-content-header">
      <p>{{ basicDetails }}</p>
    </div>
    <div class="side-content">
      <div class="details-wrapper" *ngFor="let item of sideView">
        <p class="detail-head">{{ item.name }}</p>
        <ng-template #p>
          <p class="detials-content">{{ entity | column: item }}</p>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="fieldValue || p"
          [ngTemplateOutletContext]="{
            item: item,
            entity: entity,
            template: p
          }"
        ></ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="addDetails" class="side-content-container additional">
    <div class="side-view-content-header">
      <p>{{ additionalDetails }}</p>
    </div>
    <div class="side-content">
      <div class="details-wrapper" *ngFor="let item of sideViewAddDetails">
        <p class="detail-head">{{ item.name }}</p>
        <ng-template #p>
          <p class="detials-content">{{ entity | column: item }}</p>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="fieldValue || p"
          [ngTemplateOutletContext]="{
            item: item,
            entity: entity,
            template: p
          }"
        ></ng-container>
      </div>
    </div>
  </div>
</div>
