import { Observable, filter, mapTo } from 'rxjs';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, Input, Output, EventEmitter, ViewChild, HostListener, OnInit } from '@angular/core';
import * as $ from 'jquery';
let nextUniqueId = 0;

@Component({
  selector: 'av-sheet-modal',
  templateUrl: './sheet-modal.component.html',
  styleUrls: ['./sheet-modal.component.scss']
})
export class SheetModalComponent implements OnInit {
  private uniqueId = `sheet-pane-${++nextUniqueId}`;
  escPressed$: Observable<KeyboardEvent>;
  @Input() initialBreakpoint = 0.5;
  @Input() breakpoints = [0, 0.5, 0.9];
  @Input() isOpen: boolean;
  @Input() customClass = this.uniqueId;
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<boolean>();
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: any;
  @HostListener('document:click', ['$event.target'])
  onClick(e: any) {
    if (this.isOpen && e?.classList?.contains(this.customClass)) {
      this.animateClose();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.height = window.innerHeight;
  }
  sheetModal: HTMLDivElement;
  timeoutDuration = 400;
  height = window.innerHeight;
  constructor() {}
  ngOnInit(): void {}
  close() {
    this.isOpen = false;
    this.isOpenModal.emit(this.isOpen);
  }

  open() {
    this.sheetModal = this.cdkConnectedOverlay.overlayRef._pane.querySelector('#sheet-modal');
    this.moveModal(-100 + this.initialBreakpoint * 100, true);
    this.dim(this.initialBreakpoint);
  }

  drag(event: CdkDragMove) {
    const y = event.pointerPosition.y + document.body.scrollTop;
    if (y < this.height + 20 && y > 24) {
      const bottom = ((-y + 16) / this.height) * 100;
      this.moveModal(bottom);
    }
  }
  dragEnd() {
    const topPercentage = (this.height - this.sheetModal.offsetTop) / this.height;
    const closestBreakpoint = this.breakpoints.reduce((prev, curr) => {
      return Math.abs(curr - topPercentage) < Math.abs(prev - topPercentage) ? curr : prev;
    }, 0);
    this.moveModal(-(1 - closestBreakpoint) * 100, true);
    if (closestBreakpoint === 0) {
      this.animateClose();
    }
  }
  animateClose(close?: boolean) {
    this.dismiss.emit(close ?? true);
    this.moveModal(-100, true);
    setTimeout(() => {
      this.close();
    }, 400);
  }

  moveModal(num: number, anime?: boolean) {
    if (anime) {
      $(this.sheetModal).animate(
        {
          bottom: num + '%'
        },

        this.timeoutDuration
      );
    } else {
      this.sheetModal.style.bottom = num + '%';
    }
    this.dim(num);
  }
  dim(dimAmount?: number) {
    const topPercentage = ((this.height - this.sheetModal.offsetTop) / this.height) * 0.4 || dimAmount * 0.4; // to limit it to 0.4;
    $(document.querySelector('.sheet-backdrop')).css({
      backgroundColor: 'rgba(0,0,0,' + topPercentage + ')'
    });
  }
}
