import { catchError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BreadCrumbs, MinControlValidator, Question, SpinnerToggleService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';

@Component({
  selector: 'frontend-trade-desk-builder',
  templateUrl: './trade-desk-builder.component.html',
  styleUrls: ['./trade-desk-builder.component.scss']
})
export class TradeDeskBuilderComponent implements OnInit {
  categoryForm: FormGroup;
  categoryFormBackup: FormGroup;
  isLoading = false;
  isEdit: boolean;
  question: Question;
  threshForm = new FormGroup({});
  threshBackup: FormGroup;
  scoreBackup = new FormControl();
  environment = environment;
  stamForm: FormGroup;
  mlScore = 50;
  fakeId = 25;
  chosenCategory: any;
  scoreControl = new FormControl();
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    },
    {
      name: 'Trade Desk',
      route: 'trade-desk'
    }
  ];
  categories = [];
  editMode: boolean;
  activeEdit = [];
  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    private route: ActivatedRoute,
    private alerts: AlertService,
    private http: HttpClient,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.threshForm = this.fb.group({
      approval: [0, [Validators.required, MinControlValidator('decline', true), Validators.min(0), Validators.max(100)]],
      decline: [0, [Validators.required, MinControlValidator('approval', false), Validators.min(0), Validators.max(100)]]
    });
    this.scoreControl.valueChanges.subscribe((value) => {
      this.mlScore = value;
    });
    this.route.data.subscribe(({ categories, generalVariables }) => {
      this.threshForm.patchValue({
        approval: generalVariables.approvalThreshold?.value || 0,
        decline: generalVariables.declineThreshold?.value || 0
      });
      this.scoreControl.setValidators([Validators.min(0), Validators.max(100), Validators.required]);
      this.scoreControl.setValue(+(generalVariables?.mlScore?.value || 0));
      this.stamForm = this.fb.group({ stam: [undefined] });
      this.categoryFormBackup = this.fb.group({
        categories: this.fb.array([])
      });
      this.categoryForm = this.fb.group({
        categories: this.fb.array([])
      });
      // this.categories = categories?.filter((data) => {
      //   return !data?.category?.deleted;
      // });
      // this.categories?.forEach((data) => {
      //   data.category.questions = data?.category?.questions.filter((question) => {
      //     return !question.deleted;
      //   });
      // });
      this.categories = categories;
      this.categories?.forEach((category) => {
        this.categoryFormArray.push(this.createCategory(category));
      });
      if (!this.chosenCategory) {
        this.chooseCategory(0);
      }
      this.chosenCategory = this.categories?.find((category) => category._id === this.chosenCategory._id);
    });
  }
  createCategory(category?: any) {
    return this.fb.group({
      name: category?.name || undefined,
      active: category?.active ?? true,
      _id: category?._id || ++this.fakeId,
      score: [category?.score || 0, [Validators.required]],
      questions: [category?.questions || [], [Validators.required]],
      deleted: [category?.deleted || false],
      new: [category?._id ? undefined : true]
    });
  }
  get categoryFormArray() {
    return this.categoryForm.get('categories') as FormArray;
  }
  addEmptyCategory() {
    this.categoryFormArray.push(this.createCategory());
    setTimeout(() => {
      this.openFooters();
    }, 1);
  }
  edit() {
    // if (this.isEditing) {
    //   this.categoryFormArray.at(i).patchValue({
    //     tempName: this.categoryFormArray.at(i).value.name,
    //     tempActive: this.categoryFormArray.at(i).value.active
    //   });
    //   return;
    // }
    // this.activeEdit[i] = true;
    // this.categoryFormArray.at(i).enable();
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.copyGroup();
    }
    this.openFooters(!this.editMode);
  }
  copyGroup() {
    this.categoryFormBackup = this.fb.group({
      categories: this.fb.array([])
    });
    this.scoreBackup = new FormControl(this.scoreControl.value);
    this.threshBackup = this.fb.group({
      approval: this.threshForm.get('approval').value,
      decline: this.threshForm.get('decline').value
    });
    this.categoryFormArray.controls.forEach((control) => {
      (<FormArray>this.categoryFormBackup.get('categories')).push(this.createCategory(control.value));
    });
  }
  cancelEdit() {
    this.categoryForm = this.fb.group({
      categories: this.fb.array([])
    });
    this.scoreControl.setValue(this.scoreBackup.value);
    this.threshForm.patchValue({
      approval: this.threshBackup.get('approval').value,
      decline: this.threshBackup.get('decline').value
    });
    this.threshForm.get('approval').updateValueAndValidity();
    const catArray = <FormArray>this.categoryFormBackup.get('categories');
    catArray.controls.forEach((control) => {
      this.categoryFormArray.push(this.createCategory(control.value));
      this.categoryFormArray.at(this.categoryFormArray.length - 1).disable();
    });
    this.edit();
  }

  openFooters(close?: boolean) {
    if (close) {
      document.querySelectorAll('.category-footer-wrapper').forEach((data: HTMLDivElement) => {
        data.style.height = '0px';
      });
    } else {
      document.querySelectorAll('.category-footer-wrapper').forEach((data: HTMLDivElement) => {
        data.style.height = data.scrollHeight + 'px';
      });
    }
  }

  remove(category: AbstractControl) {
    if (this.editMode) {
      const cat = this.categoryFormArray.at(this.categoryFormArray.controls.indexOf(category));
      if (cat.value.new) {
        this.categoryFormArray.removeAt(this.categoryFormArray.controls.indexOf(category));
      } else {
        cat.setValue({ ...cat.value, deleted: true });
      }
    }
  }
  resetForm(i: number) {
    this.categoryFormArray.at(i).patchValue({
      name: this.categoryFormArray.at(i).value.tempName,
      active: this.categoryFormArray.at(i).value.tempActive
    });
  }
  get isEditing() {
    return this.activeEdit.some((e) => e);
  }
  chooseCategory(i: number) {
    if (!this.editMode && this.categoryFormArray?.at(i)?.value) {
      const category = this.categoryFormArray.at(i).value;
      this.chosenCategory = category;
      this.chosenCategory['_id'] = category._id;
    }
  }
  expand(div: HTMLDivElement, icon: any) {
    if (!div.clientHeight) {
      icon.elem.nativeElement.classList.add('expanded');
      div.style.height = `${div.scrollHeight}px`;
    } else {
      icon.elem.nativeElement.classList.remove('expanded');
      div.style.height = '0px';
    }
  }
  expandMore(div: HTMLDivElement, bool?: boolean) {
    if (bool) {
      div.style.height = 82 + 'px';
    }
    setTimeout(() => {
      div.style.height = `${div.scrollHeight}px`;
    }, 200);
  }
  calcTotalScore() {
    let totalScore = 0;
    this.categoryFormArray.controls.forEach((control) => {
      if (control.value.active && !control.value.deleted) {
        totalScore += control.value.score;
      }
    });
    return totalScore;
  }
  get calcTotalQuestionScore() {
    let totalScore = 0;
    this.chosenCategory?.questions?.forEach((question) => {
      if (question.active) totalScore += question.score;
    });
    return totalScore;
  }
  restrict(e: KeyboardEvent) {
    const bannedKeys = ['e', 'E', '+', '-', '*', '/'];
    if (bannedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }
  }
  saveCategories() {
    if (!this.scoreControl?.valid || this.calcTotalScore() + this.scoreControl?.value !== 100) {
      this.categoryForm.setErrors({ weights: true });
      return;
    }
    this.isLoading = true;
    this.edit();
    const categories = this.categoryFormArray.controls.map((control) => {
      return {
        deleted: control.value.deleted,
        name: control.value.name,
        active: control.value.active,
        score: control.value.score,
        _id: typeof control.value._id === 'number' ? undefined : control.value._id.toString()
      };
    });
    this.http
      .post(`${this.environment.new_api_url}/api/trade-desk/category/many`, categories)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return EMPTY.pipe(err);
        })
      )
      .subscribe((data) => {
        setTimeout(() => {
          this.isLoading = false;
          this.router.navigateByUrl(this.router.url);
          this.alerts.showAlert('Categories saved successfully');
        }, 300);
      });
    const vars = ['m-l-score', 'approval-threshold', 'decline-threshold'];
    for (let i = 0; i < vars.length; i++) {
      this.http
        .put(`${this.environment.new_api_url}/api/general-env/name/${vars[i]}`, {
          value: i === 0 ? this.scoreControl.value : i === 1 ? this.threshForm.get('approval').value : this.threshForm.get('decline').value
        })
        .pipe(
          catchError((err) => {
            if (i !== 0) {
              this.alerts.showAlert('Error saving thresholds', 'danger');
            } else {
              this.alerts.showAlert('Error saving score', 'danger');
            }
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
        });
    }
  }
  trackByFn(i: number, item: any) {
    return item?._id;
  }
  addQuestion() {
    if (this.editMode) {
      this.alerts.showAlert('Please finish editing the categories', 'warning');
      return;
    }
    $('#addQuestionModal').css({ display: 'flex' });
    this.question = null;
    this.isEdit = false;
  }
  activeQuestion(question: any) {
    this.http.put(`${this.environment.new_api_url}/api/trade-desk/question/${question?._id}`, question).subscribe((data) => {});
  }
  editQuestion(question: any) {
    if (this.editMode) {
      this.alerts.showAlert('please finish editing', 'warning');
      return;
    }
    $('#addQuestionModal').css({ display: 'flex' });
    this.question = question;
    this.isEdit = true;
  }
  deleteQuestion(question: any) {
    this.alerts
      .fire({
        iconName: 'warning',
        title: 'Are you sure?',
        subtitle: 'Are you sure you want to delete this question?',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      })
      .then((data) => {
        if (data.confirmed) {
          this.http
            .put(`${this.environment.new_api_url}/api/trade-desk/question/${question?._id}`, { ...question, deleted: true })
            .subscribe((data) => {
              this.router.navigateByUrl(this.router.url);
              this.alerts.showAlert('Question deleted successfully');
            });
        }
      });
  }

  clearQuestionData() {
    this.question = null;
    this.isEdit = false;
  }
}
