<div class="modal-box" id="addAgentWizard">
  <div class="modal-box-content-wrapper modal-box-content-wrapper-sm">
    <div class="modal-box-heading">
      <p>New Agent</p>
      <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    </div>
    <div class="modal-box-content">
      <p class="top-up-header">Create agent user</p>
      <div [formGroup]="userForm">
        <div class="form-pair">
          <app-base-form-template
            labelName="First Name"
            inputType="text"
            inputName="default"
            controlName="firstName"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Last Name"
            inputType="text"
            inputName="default"
            controlName="lastName"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
        <div class="form-pair">
          <app-base-form-template
            labelName="Phone number"
            inputType="text"
            inputName="default"
            placeholderName="+254123456789"
            controlName="phoneNumber"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
          <app-base-form-template
            labelName="Email"
            inputType="text"
            inputName="default"
            [errorMessages]="errorMessages"
            controlName="email"
            [group]="userForm"
            [size]="6"
          >
          </app-base-form-template>
        </div>
      </div>
      <div class="topup-modal-footer">
        <button class="btn btn-primary" (click)="createUser()">Create</button>
      </div>
    </div>
  </div>
</div>
