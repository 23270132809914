import { environment } from '@env/environment';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { catchError, EMPTY, of, map, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Bureau } from './biro';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'td-bureau',
  templateUrl: './td-bureau.component.html',
  styleUrls: ['./td-bureau.component.scss']
})
export class TdBureauComponent implements OnInit {
  @Input() loan: any;
  @Input() user: any;
  @Input() mlScoreData: any;
  environment = environment;
  loading: boolean;
  chartOptions: any = {
    elements: {
      point: {
        radius: 7
      },
      line: {
        tension: 0.5
      }
    },
    scales: {
      y: {
        position: 'left',
        beginAtZero: true
      }
    },
    plugins: {
      legend: { display: false }
    }
  };

  bureaus: Bureau[] = [];
  nationalIds: string[] = [];
  openDebtorContracts: any[] = [];
  closedDebtorContracts: any[] = [];
  contracts: any[] = [];
  nationalIdControl = new FormControl(undefined, [Validators.pattern(/^[0-9]*$/), Validators.required]);
  bureauForm = new FormGroup({
    nationalId: this.nationalIdControl
  });
  errorMessages = {
    nationalId: [
      { type: 'required', message: 'National ID is required' },
      { type: 'pattern', message: 'National ID must be a number' }
    ]
  };
  openDebtorSums = {
    total: 0,
    balance: 0,
    pastDueAmount: 0,
    currency: ''
  };
  closedDebtorSums = {
    total: 0,
    balance: 0,
    pastDueAmount: 0,
    currency: ''
  };
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private alerts: AlertService,
    private spinner: SpinnerToggleService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(async ({ scoreData, loan }) => {
      this.loan = loan;
      this.loading = true;
      const otherIds = scoreData?.decision?.bureau?.nationalIds || [];
      this.nationalIds = [scoreData?.clientID, ...otherIds];
      //remove dups
      this.nationalIds = [...new Set(this.nationalIds)];
      this.bureaus = [];
      for (let i = 0; i < this.nationalIds.length; i++) {
        this.bureaus.push(await this.getBureauByNationalId(this.nationalIds[i]));
      }
      this.bureaus = this.bureaus.filter((data) => !!data);
      this.bureaus.forEach((data) => {
        this.getBureauContracts(data);
        this.getScoring(data);
        const grade = data?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record?.[0]?.Grade;
        if (grade?.match(/a/i)) {
          data['gradeColor'] = 'text-green-400';
        } else if (grade?.match(/b/i)) {
          data['gradeColor'] = 'text-green-500';
        } else if (grade?.match(/c/i)) {
          data['gradeColor'] = 'text-yellow-400';
        } else if (grade?.match(/d/i)) {
          data['gradeColor'] = 'text-orange-600';
        } else if (grade?.match(/e/i)) {
          data['gradeColor'] = 'text-red-600';
        } else {
          data['gradeColor'] = 'text-gray-600';
        }
      });
      this.loading = false;
      // if(this.bureaus.length > 0) {
      //   this.nationalIdControl.setValue(this.bureaus[0].nationalId);
      // }
    });
  }
  getBureauContracts(bureau: Bureau) {
    const contracts = bureau?.bureauCheck?.GetCustomReportResult?.Contracts?.ContractList?.Contract;
    contracts?.forEach((data) => {
      // from camel case to sentence case
      data['AccountProductType'] = data['AccountProductType'].replace(/([A-Z])/g, ' $1').trim();
      data['lastUpdate'] = this.getLastUpdated(data);
      data['total'] =
        data['OriginalAmount']?.Value && data['OriginalAmount']?.Value !== '0.00'
          ? data['OriginalAmount']?.Value + ' ' + data['OriginalAmount']?.Currency
          : undefined;
      data['balance'] =
        data['OutstandingAmount']?.Value && data['OutstandingAmount']?.Value !== '0.00'
          ? data['OutstandingAmount']?.Value + ' ' + data['OutstandingAmount']?.Currency
          : undefined;
      data['PastDueAmount'] =
        data['OverdueBalance']?.Value && data['OverdueBalance']?.Value !== '0.00'
          ? data['OverdueBalance']?.Value + ' ' + data['OverdueBalance']?.Currency
          : undefined;
    });
    bureau['contracts'] = contracts;
    const openDebtorContracts = contracts.filter((data) => data.RoleOfClient.match(/debtor/i) && data.PhaseOfContract === 'Open');
    const closedDebtorContracts = contracts.filter((data) => data.RoleOfClient.match(/debtor/i) && data.PhaseOfContract === 'Closed');
    openDebtorContracts.forEach((data) => {
      this.openDebtorSums.total += data['OriginalAmount']?.Value ? +data['OriginalAmount']?.Value : 0;
      this.openDebtorSums.balance += data['OutstandingAmount']?.Value ? +data['OutstandingAmount']?.Value : 0;
      this.openDebtorSums.pastDueAmount += data['OverdueBalance']?.Value ? +data['OverdueBalance']?.Value : 0;
      this.openDebtorSums.currency = data['OriginalAmount']?.Currency;
    });
    closedDebtorContracts.forEach((data) => {
      this.closedDebtorSums.total += data['OriginalAmount']?.Value ? +data['OriginalAmount']?.Value : 0;
      this.closedDebtorSums.balance += data['OutstandingAmount']?.Value ? +data['OutstandingAmount']?.Value : 0;
      this.closedDebtorSums.pastDueAmount += data['OverdueBalance']?.Value ? +data['OverdueBalance']?.Value : 0;
      this.closedDebtorSums.currency = data['OriginalAmount']?.Currency;
    });
    bureau['openDebtorContracts'] = openDebtorContracts;
    bureau['closedDebtorContracts'] = closedDebtorContracts;
  }
  getLastUpdated(contract: any) {
    if (contract?.PaymentCalendarList?.CalendarItem?.length) {
      const lastUpdated = contract.PaymentCalendarList?.CalendarItem.find((item) => item.DelinquencyStatus === 'Ok');
      return lastUpdated?.Date;
    }
  }
  async getBureauByNationalId(nationalId: string): Promise<Bureau> {
    return firstValueFrom(
      this.http
        .post(`https://zibindddbcf2kk56bvtolszpna0cvart.lambda-url.us-east-1.on.aws`, {
          clientID: nationalId
        })
        .pipe(
          catchError((err) => {
            return of(undefined);
          }),
          map((data) => {
            if (!data) {
              return undefined;
            } else return data;
          })
        ) as any
    );
  }
  addBureau() {
    if (this.nationalIds.includes(this.nationalIdControl.value)) {
      this.alerts.showAlert('National Id already exists', 'danger');
      return;
    }
    if (!this.nationalIdControl.valid) {
      this.nationalIdControl.markAsTouched();
      return;
    }
    this.spinner.showSpinner();
    this.http
      .post(`https://zibindddbcf2kk56bvtolszpna0cvart.lambda-url.us-east-1.on.aws`, {
        clientID: this.bureauForm.get('nationalId').value
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          this.spinner.hideSpinner();
          this.alerts.showAlert(`Couldn't find the national id`, 'danger');
          return EMPTY.pipe();
        })
      )
      .subscribe((bureau: any) => {
        if (!bureau) {
          this.spinner.hideSpinner();
          this.alerts.showAlert(`Couldn't find the national id`, 'danger');
          return;
        }

        this.http
          .post(`${this.environment.new_api_url}/api/trade-desk/bureau/loan/${this.loan?._id}`, {
            nationalId: this.bureauForm.get('nationalId').value
          })
          .pipe(
            catchError((err) => {
              this.spinner.hideSpinner();
              this.alerts.showAlert(err.error?.message, 'danger');
              return EMPTY.pipe();
            })
          )
          .subscribe(() => {
            this.spinner.hideSpinner();
            this.alerts.showAlert('Bureau added successfully', 'success');
            this.getBureauContracts(bureau);
            this.getScoring(bureau);
            this.bureaus.push(bureau);
            this.bureauForm.reset();
          });
      });
  }
  getScoring(bureau: Bureau) {
    const records = bureau?.bureauCheck?.GetCustomReportResult?.CIP?.RecordList?.Record;
    let datasets = [{}];
    const datas = [];
    let labels = [];
    for (let i = records.length - 1; i >= 0; i--) {
      const record = records[i];
      const date = this.datePipe.transform(new Date(record.Date as any), 'MMM YY');
      const data = isNaN(+record.Score) ? 0 : +record.Score;
      datas.push(data);
      labels.push(date);
    }
    datasets[0] = {
      data: datas,
      borderColor: '#37c1ce',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#37c1ce',
      pointBorderWidth: 3
    };
    datasets[0]['label'] = 'Score';

    bureau['scoring'] = {
      datasets,
      labels
    };
  }
}
