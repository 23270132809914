import { Router } from '@angular/router';
import { AuthenticationService } from '@avenews/front-auth';
import { BaseValidationComponent } from '../base-validation/base-validation.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, EMPTY } from 'rxjs';
import * as $ from 'jquery';
import { AlertService } from '@avenews/alerts';
import { PeriodStatus, Agt4Period } from '@avenews/agt-sdk';
@Component({
  selector: 'app-change-period-status',
  templateUrl: './change-period-status.component.html',
  styleUrls: ['./change-period-status.component.scss']
})
export class ChangePeriodStatusComponent extends BaseValidationComponent implements OnInit, OnChanges {
  @Input() period: Agt4Period;
  @Input() isOpen: boolean;
  @Output('isOpenChange') isOpenModal = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<boolean>();
  statusForm: FormGroup;
  statusSelect = [
    {
      value: PeriodStatus.NEW,
      viewValue: 'New'
    },
    {
      value: PeriodStatus.ACTIVE,
      viewValue: 'Active'
    },
    {
      value: PeriodStatus.OVERDUE,
      viewValue: 'Overdue'
    },
    {
      value: PeriodStatus.PARTIALLY_PAID,
      viewValue: 'Partially Paid'
    },
    {
      value: PeriodStatus.SETTLED,
      viewValue: 'Settled'
    },
    {
      value: PeriodStatus.CANCELLED,
      viewValue: 'Cancel'
    }
  ];
  constructor(
    fb: FormBuilder,
    private alertService: AlertService,
    private authService: AuthenticationService,
    public spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.statusForm = fb.group({
      status: [undefined, Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  // close() {
  //   $('#changePeriodStatusWizard').fadeOut('', () => {
  //     this.deleteData();
  //   });
  // }
  open() {
    $('#changePeriodStatusWizard').css({ display: 'flex' });
  }
  changeStatus() {
    if (this.statusForm.value.status === PeriodStatus.OVERDUE && new Date()?.getTime() < new Date(this.period?.endDate)?.getTime()) {
      this.overdueAlert();
    } else {
      this.submit();
    }
  }
  overdueAlert() {
    this.alertService
      .fire({
        title: 'Are you sure?',
        subtitle:
          'Changing the status to overdue while the End Date is in the future will result in the period showing a day in negative for the client in avenews pay',
        iconName: 'warning',
        cancelButton: true,
        confirmButtonText: 'Change it anyways'
      })
      .then((data) => {
        if (data.confirmed) {
          this.submit();
        }
      });
  }
  submit() {
    this.validateForm(this.statusForm);

    if (this.statusForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${environment.new_api_url}/api/deal/${this.period?.deal?._id}/period/${this.period?._id}/status`, {
          status: this.statusForm.value.status
        })
        .pipe(
          catchError((err) => {
            this.alertService.showAlert(err?.error?.message, 'danger');
            this.spinner.hideSpinner();
            this.close();
            this.deleteData();
            return EMPTY.pipe();
          })
        )
        .subscribe(() => {
          this.alertService.showAlert(`Status Changed to  ${this.statusForm.value.status} successfuly`, 'success');
          this.close();
          this.deleteData();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  deleteData() {
    this.statusForm.reset();
    this.statusForm.patchValue({});
  }
  close() {
    $('#changePeriodStatusWizard').fadeOut('fast', () => {
      this.isOpen = false;
      this.isOpenModal.emit(this.isOpen);
    });
  }
}
