import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult } from '@avenews/agt-sdk';

@Injectable()
export class ArchivedFilesResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router) {}

  resolve() {
    return (
      this.http.get(`${environment.new_api_url}/api/file/10/1/expiresAt/asc?type=archived&filters=[{%22column%22:%22status%22,%22option%22:%22contains%22,%22value%22:%22approved%22}]&queryMode=and`) as Observable<PaginatedResult<any>>
    ).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
