import { SharedModule } from '@avenews/shared-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardCardComponent } from '../dashboard-card/dashboard-card.component';
import { DashboardCounterResolver } from './dashboard-counter.resolver';
import { ArrowRight, ChevronRight, Upload, ChevronDown, ChevronUp, Circle, Check, Plus } from 'angular-feather/icons';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FeatherModule } from 'angular-feather';
const icons = {
  ArrowRight,
  Upload,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Circle,
  Check,
  Plus
};

@NgModule({
  declarations: [DashboardComponent, DashboardCardComponent],
  imports: [CommonModule, NgxChartsModule, NgChartsModule, FeatherModule.pick(icons), SharedModule, DashboardRoutingModule],
  providers: [DashboardCounterResolver]
})
export class DashboardModule {}
