import { Component, OnInit, Input } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-email-sent-modal',
  templateUrl: './email-sent-modal.component.html',
  styleUrls: ['./email-sent-modal.component.scss']
})
export class EmailSentModalComponent implements OnInit {
  user: any;
  @Input() userEmail: string;

  constructor() {}

  ngOnInit() {}
  close() {
    $('#resetModalWizard').fadeOut();
  }
}
