import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealName'
})
export class DealNamePipe implements PipeTransform {
  transform(itemTitle?: string): string {
    if (/[A-Z]/.test(itemTitle)) {
      var separatedString = '';
      for (var i = 0; i < itemTitle.length; i++) {
        if (itemTitle[i] === itemTitle[i].toUpperCase()) {
          separatedString += ' ' + itemTitle[i];
        } else {
          separatedString += itemTitle[i];
        }
      }
      return separatedString.trim();
    } else {
      return itemTitle;
    }
  }
}
