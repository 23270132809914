<base-modal>
  <base-modal-body>
    <i class="material-icons pull-right" (click)="close()" #closeModal>close</i>
    <div class="decline-content">
      <div class="alert-icon-holder">
        <div class="sa sa-primary">
          <div class="icon">
            <img src="assets/shared/svgs/warning.svg" class="success-icon" name="alert-circle" />
          </div>
        </div>
      </div>
      <div class="header my-4">
        <p class="main-title">{{ 'Are you sure?' }}</p>
        <p class="sub-title text-primary font-medium !text-base">
          {{ alertOptions.subtitle }}
        </p>
      </div>
      <div class="flex items-center flex-col w-100">
        <button (click)="this.handleButtons('cancel')" class="w-full bg-avenews font-medium text-base text-white rounded-lg h-14">
          Go back
        </button>
        <a (click)="this.handleButtons('confirm')" class="mt-6 text-avenews font-medium text-base cursor-pointer">Continue anyway</a>
      </div>
    </div>
  </base-modal-body>
</base-modal>
