import { AbstractControl, FormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trendIcon'
})
export class TrendIconPipe implements PipeTransform {
  constructor() {}
  transform(trend: string, color?: boolean) {
    if (!color) {
      return this.trendToIcon(trend);
    } else {
      return this.trendToIconColor(trend);
    }
  }
  trendToIcon(trend: string) {
    switch (trend) {
      case 'Up':
        return 'arrow-up';
      case 'Down':
        return 'arrow-down';
      case 'noChange':
        return 'minus';
      default:
        return 'minus';
    }
  }
  trendToIconColor(trend: string) {
    switch (trend) {
      case 'Up':
        return 'text-green-600';
      case 'Down':
        return 'text-red-600';
      case 'noChange':
        return 'text-white';
      default:
        return 'text-white';
    }
  }
}
