import { BankTransactionListComponent } from './bank-transaction-list/bank-transaction-list.component';
import { MobileTransactionListComponent } from './mobile-transaction-list/mobile-transaction-list.component';
import { AllTransactionListResolver } from './resolvers/all-transactions.resolver';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TopupListComponent } from './topup-list/topup-list.component';
import { InternalTransactionListComponent } from './internal-transaction-list/intrenal-transaction-list.component';
import { TopupResolver } from './../shared/resolvers/topups.resolver';
import { Shell } from '@avenews/shell';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilterOptions, Status } from '@avenews/agt-sdk';
import { defaultPagination } from '../core/constants';
import { UserTopupsListComponent } from './user-topups-list/user-topups-list.component';
import { UsersRepaymentsListComponent } from './users-repayments-list/users-repayments-list.component';
import { AllPaymentsListResolver } from './resolvers/all-repayments.resolver';
import { PendingTransactionListComponent } from './pending-transaction-list/pending-transaction-list.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/internal',
      component: InternalTransactionListComponent,
      resolve: { transactions: AllTransactionListResolver },
      data: {
        filters: [
          {
            value: 'true',
            column: 'internal',
            option: FilterOptions.EQUAL
          },
          {
            value: Status.DECLINED,
            column: 'status',
            option: FilterOptions.NOT_EQUAL
          },
          {
            value: Status.APPROVED,
            column: 'status',
            option: FilterOptions.NOT_EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/pending',
      component: PendingTransactionListComponent,
      resolve: { transactions: AllTransactionListResolver },
      data: {
        queryMode: 'and',
        options: {
          pageNumber: 1,
          pageSize: 10000,
          sortBy: '_id',
          sortOrder: 'desc'
        },
        filters: [
          {
            value: Status.PREAPPROVED,
            column: 'status',
            option: FilterOptions.EQUAL
          }
        ] as any
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/topup',
      component: TopupListComponent,
      resolve: { topups: AllTransactionListResolver },
      data: {
        filters: [
          {
            value: 'TOPUP',
            column: 'transactionId',
            option: FilterOptions.CONTAINS
          }
        ]
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/all',
      component: TransactionListComponent,
      resolve: { allTransactions: AllTransactionListResolver }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/bank',
      component: BankTransactionListComponent,
      resolve: { allTransactions: AllTransactionListResolver },
      data: {
        pageSize: 10000
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/mobile',
      component: MobileTransactionListComponent,
      resolve: { mobileTransactions: AllTransactionListResolver },
      data: {
        pageSize: 10000
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/user-topup',
      component: UserTopupsListComponent,
      resolve: { topups: AllTransactionListResolver },
      data: {
        filters: [
          {
            value: 'TOPUP',
            column: 'transactionId',
            option: FilterOptions.CONTAINS
          }
        ],
        sortBy: 'status'
      }
    },
    {
      runGuardsAndResolvers: 'always',
      path: 'transactions/users-repayments',
      component: UsersRepaymentsListComponent,
      resolve: { repayments: AllPaymentsListResolver }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayRoutingModule {}
