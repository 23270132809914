<div [ngClass]="{ 'collapsed-menu': collapsed }" class="sidebar-menu">
  <div (click)="collapse()" class="floating-back">
    <i-feather [ngClass]="showText ? 'icon-left' : 'icon-right'" name="chevron-left"></i-feather>
  </div>
  <div class="sidebar-header">
    <div class="brand-wrapper d-flex" routerLink="/{{menuItems[0]?.path}}">
      <img src="assets/shared/sidebar/Avenews-icon-logo.svg" alt="logo" class="brand-img" />
      <img *ngIf="showText" src="assets/shared/sidebar/avenews-text-logo.png" alt="logo" class="brand-text" />
    </div>
  </div>
  <div class="sidebar-content">
    <ng-container *ngFor="let menuitem of menuItems; let i = index">
      <ng-container *ngIf="userHasAccess(menuitem)">
        <div *ngIf="!menuitem?.dropdown" class="sidebar-item-wrapper">
          <div *ngIf="!menuitem?.dropdown" [routerLink]="menuitem.path" routerLinkActive="active" class="sidebar-item">
            <av-svg class="svg-icon {{ menuitem?.icontype }}-icon" iconType="{{ menuitem?.icontype }}"></av-svg>
            <p *ngIf="showText">{{ menuitem?.title }}</p>
          </div>
        </div>
        <div *ngIf="menuitem?.dropdown" class="sidebar-item-wrapper">
          <div
            (mouseenter)="mouseEnter(menuTrigger)"
            (mouseleave)="mouseLeave(menuTrigger, $event)"
            [ngClass]="{ active: isActive(menuitem?.mainPath) }"
            (click)="menuitem.open = !menuitem.open"
            *ngIf="menuitem?.dropdown"
            class="sidebar-item dropdowner "
          >
            <div style="visibility: hidden" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"></div>
            <av-svg class="svg-icon {{ menuitem?.icontype }}-icon" iconType="{{ menuitem?.icontype }}"></av-svg>
            <p *ngIf="showText">{{ menuitem?.title }}</p>

            <i-feather
              *ngIf="showText"
              class="dropdown-handler"
              [ngClass]="menuitem?.open ? 'close-dropdown' : 'open-dropdown'"
              name="chevron-down"
            ></i-feather>
            <mat-menu backdropClass="menu-backdrop" class="sidebar-mat-menu" xPosition="after" #menu="matMenu" [hasBackdrop]="true">
              <div (mouseleave)="mouseLeave(menuTrigger)" class="menu-wrapper">
                <div class="menu-title">{{ menuitem?.title }}</div>
                <div mat-menu-item *ngFor="let subItem of menuitem?.dropdown; let i = index" class="sidebar-sub-item">
                  <p [routerLink]="subItem.path" routerLinkActive="active">{{ subItem?.title }}</p>
                </div>
              </div>
            </mat-menu>
          </div>
          <div
            *ngIf="menuitem?.open && !collapsed && showText"
            [@openClose]="{ params: { len: menuitem?.dropdown?.length * 30 } }"
            class="subitem-wrapper"
          >
            <div *ngFor="let subItem of menuitem?.dropdown; let i = index" class="sidebar-sub-item">
              <p [routerLink]="subItem.path" routerLinkActive="active">{{ subItem?.title }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="sidebar-footer">
    <i-feather class="life-buoy" name="life-buoy"></i-feather>
    <p class="support-link" *ngIf="!collapsed">Help</p>
  </div>
</div>
