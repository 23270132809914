<div [class.none]="noDropFilter === true" class="drop-filter">
  <div class="main-swiper-wepper">
    <div class="swiper">
      <div class="slider__wrap swiper-wrapper">
        <div class="swiper-slide" *ngFor="let slide of slides; let i = index">
          <img [src]="slide?.imgSrc" [alt]="slide?.imgAlt" />
          <p class="title">{{ slide?.title }}</p>
          <p class="description">{{ slide?.description }}</p>
        </div>
      </div>
      <div class="footer">
        <div class="slider__controls">
          <div class="slider__pagination"></div>
        </div>
        <button class="next-btn" (click)="swipeNext()">
          {{ swip.activeIndex !== slides.length - 1 ? 'Next' : 'Let’s schedule a call!' }}
        </button>
      </div>
    </div>
  </div>
</div>
