import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { combineLatest, EMPTY, of, zip } from 'rxjs';

@Injectable()
export class GeneralVariablesResolver implements Resolve<any> {
  environment = environment;
  constructor(private http: HttpClient) {}
  resolve(route: ActivatedRouteSnapshot) {
    return combineLatest([
      this.getVariable('m-l-score'),
      this.getVariable('approval-threshold'),
      this.getVariable('decline-threshold')
    ]).pipe(
      map(([mlScore, approvalThreshold, declineThreshold]) => {
        return { mlScore, approvalThreshold, declineThreshold };
      })
    );
  }
  getVariable(variable: string) {
    return this.http.get(this.environment.new_api_url + `/api/general-env/name/${variable}`).pipe(
      catchError((err) => {
        return of(undefined);
      })
    );
  }
}
