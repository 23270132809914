import { Component, OnInit } from '@angular/core';
import { BreadCrumbs } from '@avenews/shared-lib';

@Component({
  selector: 'frontend-loan-files',
  templateUrl: './loan-files.component.html',
  styleUrls: ['./loan-files.component.scss']
})
export class LoanFilesComponent implements OnInit {
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
