import { yesNo } from './../../shared/helpers/index';
import { catchError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { User } from '@avenews/agt-sdk';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PhoneNumberValidator, BaseFormTemplateComponent, SpinnerToggleService } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';

enum OrganizationType {
  AGGREGATOR = 'aggregator',
  INPUT_PROVIDER = 'input-provider',
  TRADER = 'trader',
  PROCESSOR = 'processor'
}
enum OrganizationPartner {
  SUPPLIER = 'supplier',
  BUYER = 'buyer'
}
@Component({
  selector: 'frontend-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent extends BaseFormTemplateComponent implements OnInit {
  organizationForm: FormGroup;
  newUserForm: FormGroup;
  emptyDocsError = false;
  isNewUser: boolean;
  environment = environment;
  user: any;
  isExistUser: boolean;
  valueChains: FormArray;
  valueChainControl: FormControl = new FormControl();
  userType = [{ label: 'New', value: 'new' }];
  yesNoOptions = yesNo;
  users: User[];
  type = [
    {
      viewValue: 'Aggregator',
      value: OrganizationType.AGGREGATOR
    },
    {
      viewValue: 'Input provider',
      value: OrganizationType.INPUT_PROVIDER
    },
    {
      viewValue: 'Trader',
      value: OrganizationType.TRADER
    },
    {
      viewValue: 'Processor',
      value: OrganizationType.PROCESSOR
    }
  ];
  partner = [
    {
      viewValue: 'Supplier',
      value: OrganizationPartner.SUPPLIER
    },
    {
      viewValue: 'Buyer',
      value: OrganizationPartner.BUYER
    },
    {
      viewValue: 'No',
      value: 'no'
    }
  ];

  prettyAcceptedTypes = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'heif', 'heic'];
  acceptedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'image/heif',
    'image/heif-sequence',
    'image/heic',
    'image/heic-sequence'
  ];
  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerToggleService,
    protected router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private http: HttpClient
  ) {
    super();
    this.organizationForm = this.fb.group({
      businessName: [undefined, Validators.required],
      registrationNumber: [undefined, Validators.required],
      partner: [undefined, Validators.required],
      type: [undefined, Validators.required],
      businessAddress: [undefined, Validators.required],
      user: ['new'],
      owner: [undefined, Validators.required],
      valueChain: this.fb.array([]),
      documents: this.fb.array([]),
      isAgent: [null, Validators.required]
    });
    this.isNewUser = true;
    this.isExistUser = false;

    this.organizationForm.addControl('firstName', new FormControl(undefined, [Validators.required]), { emitEvent: false });
    this.organizationForm.addControl('lastName', new FormControl(undefined, [Validators.required]), { emitEvent: false });
    this.organizationForm.addControl('email', new FormControl(undefined, [Validators.required]), { emitEvent: false });
    // this.organizationForm.addControl('countryPhoneCode', new FormControl('+254', [Validators.required]), { emitEvent: false });
    this.organizationForm.addControl(
      'phoneNumber',
      new FormControl(undefined, [Validators.required, PhoneNumberValidator(this.regionCode)]),
      { emitEvent: false }
    );
    (this.user = undefined), { emitEvent: false };
    this.formInput = this.organizationForm;
    this.errorMessages = {
      email: [
        { type: 'required', message: 'This field is required' },
        { type: 'email', message: 'Invalid email address' }
      ],
      paymentTerms: [
        {
          type: 'required',
          message: 'This field is required'
        },
        {
          type: 'min',
          message: 'Payment terms must be at least 1 day'
        }
      ],
      phoneNumber: [
        {
          type: 'required',
          message: 'Phone number is required'
        },
        {
          type: 'wrongNumber',
          message: 'Phone number is not valid'
        }
      ]
    };
    this.organizationForm.valueChanges.subscribe((data) => {
      // if (data.user === 'new') {
      //   this.isNewUser = true;
      //   this.isExistUser = false;
      //   this.organizationForm.addControl('firstName', new FormControl(undefined, [Validators.required]), { emitEvent: false });
      //   this.organizationForm.addControl('lastName', new FormControl(undefined, [Validators.required]), { emitEvent: false });
      //   this.organizationForm.addControl('email', new FormControl(undefined, [Validators.required]), { emitEvent: false });
      //   this.organizationForm.addControl('countryPhoneCode', new FormControl('+254', [Validators.required]), { emitEvent: false });
      //   this.organizationForm.addControl(
      //     'phoneNumber',
      //     new FormControl(undefined, [Validators.required, PhoneNumberValidator(this.regionCode)]),
      //     { emitEvent: false }
      //   );
      //   (this.user = undefined), { emitEvent: false };
      // }
      //  else if (data.user === 'exist') {
      //   this.isNewUser = false;
      //   this.isExistUser = true;
      //   this.deleteNewUserData(), { emitEvent: false };
      //   this.organizationForm.removeControl('firstName', { emitEvent: false });
      //   this.organizationForm.removeControl('lastName', { emitEvent: false });
      //   this.organizationForm.removeControl('email', { emitEvent: false });
      //   this.organizationForm.removeControl('countryPhoneCode', { emitEvent: false });
      //   this.organizationForm.removeControl('phoneNumber', { emitEvent: false });
      // }
    });
  }
  override ngOnInit(): void {
    this.valueChains = this.organizationForm.get('valueChain') as FormArray;
    this.route.data.subscribe(({ users }) => {
      this.users = users;
      this.users = this?.users?.filter?.((data) => {
        return !data?.['orgUser'];
      });
    });
  }
  deleteNewUserData() {
    // this.organizationForm.removeControl('firstName', { emitEvent: false });
    // this.organizationForm.removeControl('lastName', { emitEvent: false });
    // this.organizationForm.removeControl('email', { emitEvent: false });
    // this.organizationForm.removeControl('countryPhoneCode', { emitEvent: false });
    // this.organizationForm.removeControl('phoneNumber', { emitEvent: false });
  }
  uploadDocs(event: any) {
    let file: File = event.target.files[0];
    if (!this.validateFile(file)) {
      this.alertService.showAlert(`Maximum file size is 15MB and it should be ${this.prettyAcceptedTypes.join(', ')}`, 'danger');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File = (reader.result as string).split(',')[1];
      const doc = {
        file: base64File,
        fileName: file.name,
        mime: file.type
      };
      this.addFileToArray(doc);
    };
  }
  validateFile(file: any) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 15e6;
  }
  addFileToArray(file: any) {
    (this.organizationForm.get('documents') as FormArray).push(this.createGroup(file));
  }
  close() {
    $('#addOrganizationWizard').fadeOut('slow', () => {
      this.deleteData();
    });
  }
  createGroup(file: any) {
    return this.fb.group({
      ...file
    });
  }
  get filesArray() {
    return this.organizationForm.get('documents') as FormArray;
  }
  removeFile(index: number) {
    this.filesArray.removeAt(index);
  }
  deleteData() {
    this.organizationForm.reset();
    this.organizationForm.markAsUntouched();
    this.organizationForm.get('documents').reset;
    this.organizationForm.get('valueChain').reset;
    this.deleteNewUserData();
    this.user = undefined;
    this.organizationForm.get('user').setValue('new');
    this.valueChains.clear();
    this.filesArray.clear();
    // this.isExistUser = false;
    // this.isNewUser = false;
  }
  newProduct() {
    const name = this.valueChainControl.value;
    if (this.valueChainControl.value) {
      this.valueChains.push(new FormControl(name));
      this.valueChainControl.setValue('');
    }
  }
  removeProduct(i: number) {
    this.valueChains.removeAt(i);
  }
  openModal() {
    $('#linkUserToDealAccount').css({ display: 'flex' });
  }
  getUser(e: any) {
    this.user = e.user;
    this.organizationForm.get('owner').setValue(this.user._id);
  }
  create() {
    this.validateForm(this.organizationForm);
    let newUser = {};
    if (this.organizationForm.get('user').value === 'new') {
      newUser = {
        firstName: this.organizationForm.get('firstName').value,
        lastName: this.organizationForm.get('lastName').value,
        email: this.organizationForm.get('email').value,
        countryPhoneCode: '+254',
        phoneNumber: '+254' + this.organizationForm.get('phoneNumber').value
      };
      this.organizationForm.get('owner').setValue(newUser);
    }
    if (this.organizationForm.valid) {
      const organization = {
        ...this.organizationForm.value,
        partner: this.organizationForm.value?.partner === 'no' ? undefined : this.organizationForm.value?.partner
      };

      let endpoint = 'api/organization';
      if (organization?.isAgent) {
        endpoint = 'api/organization/agent?source=agent';
        newUser['password'] = this.generatePassword();
      }
      if (this.organizationForm.get('user').value === 'new') {
        organization.owner = { newUser };
      } else {
        organization.owner = { userId: this.user._id };
      }

      delete organization?.firstName;
      delete organization?.lastName;
      delete organization?.countryPhoneCode;
      delete organization?.phoneNumber;
      delete organization?.email;
      delete organization?.user;
      delete organization.owner?.newUser?.countryPhoneCode;
      delete organization?.isAgent;
      this.spinner.showSpinner();
      this.http
        .post(`${environment?.new_api_url}/${endpoint}`, organization)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            this.close();
            return EMPTY.pipe();
          })
        )
        .subscribe((data) => {
          this.spinner.hideSpinner();
          this.alertService.showAlert('Organization created successfully', 'success');
          this.close();
          this.router.navigateByUrl(this.router.url);
        });
    }
  }
  generatePassword() {
    // atleast 5 letters min 8 characters
    let password = '';
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    for (let i = 0; i < 5; i++) {
      password += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    for (let i = 0; i < 3; i++) {
      password += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    //shuffle
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');
    return password;
  }
}
