import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

export class RadioConfig {
  label: string;
  value: string;
}
export interface SideView {
  name: string;
  dtoName: string;
  type?: string;
  pipe?: string;
  radio?: RadioConfig[];
}

@Component({
  selector: 'frontend-base-side-view',
  templateUrl: './base-side-view.component.html',
  styleUrls: ['./base-side-view.component.scss']
})
export class BaseSideViewComponent implements OnInit {
  @Input() topTitle: string;
  @Input() action = true;
  @Input() addDetails = false;
  @Input() additionalDetails: string;
  @Input() basicDetails = 'Basic details';
  @Input() sideView: SideView[];
  @Input() sideViewAddDetails: SideView[];
  @Input() entity: any;
  @ContentChild('fieldValue')
  fieldValue: TemplateRef<any>;
  @Output() sideAction = new EventEmitter();
  constructor() {}

  ngOnInit() {}
  listAction(action: any, entity: any) {
    this.sideAction.emit({ action, entity });
  }
}
