import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, from, Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { environment } from '@env/environment';
import { PaginatedResult, User } from '@avenews/agt-sdk';
import { AgentsService } from '../services/agents.service';
import { getFiltersAndQueryMode, pagination } from '@avenews/shared-lib';

@Injectable()
export class IncomingAgentCommissionsResolver implements Resolve<any> {
  constructor(private http: HttpClient, private router: Router, private agentsService: AgentsService) {}

  resolve(activeRoute: ActivatedRouteSnapshot) {
    const id = activeRoute.params['id'];
    let url = `/api/commissions/agent/${id}`;
    const filters = this.agentsService.getIncomingDatesFilter();
    const { filter, queryMode } = getFiltersAndQueryMode({ pagination, filters });
    url = `${url}/${pagination.pageSize}/${pagination.pageNumber}/${pagination.sortBy}/${pagination.sortOrder}?${filter}${queryMode}`;
    return (this.http.get(`${environment.new_api_url}${url}`) as Observable<PaginatedResult<any>>).pipe(
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/login']);
        return EMPTY.pipe();
      })
    );
  }
}
