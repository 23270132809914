<div id="myModal" class="modal">
  <div class="modal-content">
    <span class="close" (click)="close()">&times;</span>
    <div class="content">
      <h6>{{ content.title }}</h6>
      <p>{{ content.sub }}</p>
      <h6>How to get this file?</h6>
      <div *ngIf="content.showBtns" class="btns">
        <mat-tab-group [disableRipple]="true" id="application-view-tabs">
          <mat-tab color="#152C5B" label="Digital file">
            <p class="mt-4">
              It is an electronic version of the file that you can access on your device. <span>(Easier to process).</span>
            </p>
            <numeric-progress [steps]="content.digitalSteps"></numeric-progress>
          </mat-tab>
          <mat-tab color="#152C5B" label="Physical file">
            <p class="mt-4">It is printout or paper version of the file that can be sent by email.</p>
            <numeric-progress [steps]="content.physicalSteps"></numeric-progress>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div *ngIf="!content.showBtns">
        <numeric-progress [steps]="content.steps"></numeric-progress>
      </div>
    </div>
  </div>
</div>
