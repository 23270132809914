import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Transaction } from '@avenews/base-types';
import { Action, BreadCrumbs, ListNoSdkComponent } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import * as $ from 'jquery';
import { catchError, EMPTY } from 'rxjs';
import { FilterOptions } from '@avenews/agt-sdk';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'frontend-internal-transaction-list',
  templateUrl: './internal-transaction-list.component.html',
  styleUrls: ['./internal-transaction-list.component.scss']
})
export class InternalTransactionListComponent extends ListNoSdkComponent<Transaction> implements OnInit {
  isOpen = true;
  contactGroupTotal = 0;
  groupName: string;
  transaction: Transaction;
  @ViewChild(ListNoSdkComponent) agtList: ListNoSdkComponent<Transaction>;
  groupDesc: string;
  isEdit: boolean = undefined;
  breadCrumbs: BreadCrumbs[] = [
    {
      name: 'Home',
      route: 'home'
    }
  ];
  constructor(injector: Injector, private alerts: AlertService, private route: ActivatedRoute, protected router: Router) {
    super(injector, {
      name: 'getAllUsers',
      exportMethod: 'bulkExportContacts',
      apiUrl: `${environment.new_api_url}/api/transaction`
    });
    this.isMulti = true;
    this.optionsArr = [undefined];
    this.resolverName = 'transactions';
    this.exportId = 'main-farmer-export';
    this.queryMode = 'or';
  }
  ngOnInit(): void {
    this.paginated.subscribe((data) => {
      this.postPagination();
    });

    this.listConfig = {
      emptyState: {
        title: 'No internal transactions here',
        img: 'assets/images/empty-states/no-farmers.png'
      },
      actions: [
        { label: 'Status', type: 'default', action: 'status' }
        // { label: 'Edit', type: 'default', action: 'edit' },
        // { label: 'Delete', type: 'delete', action: 'delete' }
      ],
      bulkAddButton: undefined,
      addButton: undefined,
      fixedColumns: ['BENEFICIARY', 'ACTIONS'],
      mainTitle: 'Internal transactions',
      // bulkActions: [
      //   { label: 'Export All', type: 'default', action: 'export' },
      //   { label: 'Delete All', type: 'delete', action: 'delete' }
      // ],
      columns: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'transactionId',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACCOUNT NAME',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true
        },
        {
          name: 'BENEFICIARY BANK NAME',
          dtoName: 'beneficiary.details.bankName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY BRANCH CODE',
          dtoName: 'beneficiary.details.branchCode',
          type: 'number',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NUMBER',
          dtoName: 'beneficiary.details.accountNumber',
          type: 'number',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC ID',
          dtoName: 'beneficiary._id',
          type: 'number',
          selected: true
        },
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          type: 'text',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'creditor.highRisk',
          type: 'text',
          pipe: 'titleCase',
          selected: true
        },
        {
          name: 'CREDITOR NAME',
          dtoName: 'creditor.creditor.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'CREDITOR ACC ID',
          dtoName: 'creditor._id',
          type: 'number',
          selected: true
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text',
          selected: true
        },
        {
          name: 'SERVICE',
          dtoName: 'service',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          sideText: 'KES',
          pipe: 'number',
          selected: true
        },
        {
          name: 'TRANSACTION DATE',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACCOUNT TYPE',
          dtoName: 'accountTypes.to',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          type: 'text',
          selected: true
        },
        {
          name: 'ACTIONS',
          actions: [{ label: 'Status' }],
          selected: true,
          sticky: true,
          dtoName: undefined
        }
      ],
      filters: [
        {
          name: 'BENEFICIARY',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true,
          sticky: true,
          dtoNames: [
            'beneficiaryusers.personalInformation.firstName',
            'beneficiaryusers.personalInformation.lastName',
            'beneficiarycontacts.firstName',
            'beneficiarycontacts.lastName'
          ]
        },
        {
          name: 'TRANSACTION TYPE',
          dtoName: 'transactionId',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY ACCOUNT NAME',
          dtoName: 'beneficiary.beneficiary.personalInformation.firstName',
          type: 'text',
          selected: true
        },
        {
          name: 'DEAL NAME',
          dtoName: 'deal.name',
          type: 'text'
        },
        {
          name: 'BENEFICIARY BANK NAME',
          dtoName: 'beneficiary.details.bankName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'BENEFICIARY BRANCH CODE',
          dtoName: 'beneficiary.details.branchCode',
          type: 'number',
          selected: true
        },
        {
          name: 'BENEFICIARY ACC NUMBER',
          dtoName: 'beneficiary.details.accountNumber',
          type: 'number',
          selected: true
        },

        {
          name: 'BENEFICIARY ACC ID',
          dtoName: 'beneficiary._id',
          type: 'text',
          selected: true
        },
        {
          name: 'CREDITOR NAME',
          dtoName: 'creditor.creditor.personalInformation.firstName',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'CREDITOR ACC ID',
          dtoName: 'creditor._id',
          type: 'text',
          selected: true
        },
        {
          name: 'HIGH RISK',
          dtoName: 'transactionId',
          type: 'radio',
          radio: [
            {
              label: 'High risk',
              value: 'HR',
              filterOption: FilterOptions.CONTAINS
            }
          ]
        },
        {
          name: 'TRANSACTION ID',
          dtoName: 'transactionId',
          type: 'text',
          selected: true
        },
        {
          name: 'SERVICE',
          dtoName: 'service',
          type: 'text',
          selected: true
        },
        {
          name: 'AMOUNT',
          dtoName: 'amount',
          type: 'number',
          pipe: 'number',
          selected: true
        },
        {
          name: 'DATE CREATED',
          dtoName: 'dateCreated',
          type: 'date',
          pipe: 'date',
          selected: true
        },
        {
          name: 'STATUS',
          dtoName: 'status',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'ACCOUNT TYPE',
          dtoName: 'accountTypes.to',
          pipe: 'titleCase',
          type: 'text',
          selected: true
        },
        {
          name: 'NOTES',
          dtoName: 'notes',
          type: 'text',
          selected: true
        }
      ] as any

      // bulkActionLabel: 'bulk actions'
    };
    super.init();
  }

  onClose() {
    this.isOpen = false;
  }

  switchTabs(tabs: any) {
    tabs.selectedIndex = 0;
    $('#addGroupWizard').fadeOut();
  }
  deleteContact() {}
  exportContact() {}

  contactGroupTotalCount(e: any) {
    this.contactGroupTotal = e;
  }

  openStatus(transaction: Transaction) {
    this.transaction = transaction;
    $('#changeStatusWizard').css({
      display: 'flex'
    });
  }
  actions(e: { action: Action; entity?: Transaction }) {
    switch (e.action.action) {
      case 'status':
        this.openStatus(e.entity);
        break;
      case 'external' as string:
        this.alerts
          .fire({
            title: 'Are you sure?',
            subtitle: 'You are about to send this transaction to the External Queue',
            iconName: 'warning'
          })
          .then((data) => {
            if (data.confirmed) {
              this.spinner.showSpinner();
              this.http
                .put(`${environment?.new_api_url}/api/transaction/internal/${e?.entity?._id}/external`, {})
                .pipe(
                  catchError((err) => {
                    this.spinner.hideSpinner();
                    this.alerts.showAlert(err.error.message, 'danger');
                    return EMPTY.pipe();
                  })
                )
                .subscribe(() => {
                  this.alerts.showAlert('Transaction moved to external queue successfully', 'success');
                  this.router.navigateByUrl(this.router.url);
                });
            }
          });
        break;
      case 'delete':
        break;
      case 'view':
        break;
      case 'export':
        break;
    }
  }
  actionsBulk(e: any) {
    switch (e.action) {
      case 'export':
        this.exportAll({ ...e.entity, module: 'Farmers' });
    }
  }

  filter(e: any) {
    this.filterOptions = e;
    this.paginateV2(1);
  }
  changePageSize(data: any) {
    this.options.pageSize = data;
    this.currentPage = 1;
    this.itemsPerPage = data;
    this.paginateV2(1);
  }
  ngAfterViewInit(): void {
    this.that = this.agtList;
  }
  postPagination() {
    const list = [];
    for (let item of this.list) {
      const actions = [];
      if (item.internal === true) {
        actions.push({ label: 'Move to external queue', type: 'default', action: 'external' });
      }
      actions.push({ label: 'Status', type: 'default', action: 'status' });
      item['actions'] = actions;
      list.push(item);
    }
    this.list = list.slice();
  }

  transactionType(transactionId: any) {
    switch (true) {
      case transactionId.includes('CASHBACK'):
        return 'Cashback';
      case transactionId.includes('TOPUP'):
        return 'Topup';
      case transactionId.includes('KES') || transactionId.includes('BXC'):
        return 'Mobile';
      case transactionId.includes('RTGS') || transactionId.includes('EFT'):
        return 'Bank';
      default:
        return 'Unknown';
    }
  }
}
