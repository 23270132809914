import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Credentials, User } from '@avenews/agt-sdk';
import { EMPTY } from 'rxjs';
import { SpinnerToggleService } from '@avenews/shared-lib';
import { environment } from '@env/environment';
import { AuthenticationService } from '@avenews/front-auth';
import { AlertService } from '@avenews/alerts';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordModal implements OnInit {
  user: User;
  environment = environment;
  showPass = false;
  urlCont: string;
  atLeastEight = false;
  atLeastOneUpper = false;
  atLeastOneLower = false;
  atLeastOneNumber = false;
  atLeastOneSpecialCharacter = false;
  resetPasswordForm: FormGroup;
  regularExpression = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\].{7,}$/;
  validations = [
    { text: 'At least one number', wordBoolean: this.atLeastOneNumber },
    { text: 'At least one upper case letter', wordBoolean: this.atLeastOneUpper },
    { text: 'At least one lower case letter', wordBoolean: this.atLeastOneLower },
    { text: 'Minimum 8 characters', wordBoolean: this.atLeastEight },
    { text: 'At least one symbol', wordBoolean: this.atLeastOneSpecialCharacter }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private http: HttpClient,
    private spinner: SpinnerToggleService,
    private router: Router,
    private alerts: AlertService
  ) {}
  errorMessages = {
    newPassword: [
      { type: 'pattern', message: 'Password is too weak' },
      { type: 'required', message: 'This field is required' }
    ],
    oldPassword: [
      { type: 'required', message: 'This field is required' },
      { type: 'wrongPass', message: 'Wrong password' }
    ]
  };

  ngOnInit(): void {
    this.user = this.authService.credentials?.user;
    this.resetPasswordForm = this.formBuilder.group(
      {
        oldPassword: [undefined, [Validators.required, Validators.minLength(8)]],
        newPassword: [undefined, [Validators.required, Validators.pattern(this.regularExpression), Validators.min(10)]],
        confirmPassword: [undefined, Validators.required]
      },
      { validator: [this.equalPasswordsValidator] }
    );
    this.resetPasswordForm.valueChanges.subscribe((data) => {
      if (data.newPassword) {
        data.newPassword.match('^(?=.*[0-9])') ? (this.validations[0].wordBoolean = true) : (this.validations[0].wordBoolean = false);
        data.newPassword.match('(?=[^!@?\n]*[@!#%^&*()?])')
          ? (this.validations[4].wordBoolean = true)
          : (this.validations[4].wordBoolean = false);
        data.newPassword.match('(?=.*[A-Z])') ? (this.validations[1].wordBoolean = true) : (this.validations[1].wordBoolean = false);
        data.newPassword.match('(?=.*[a-z])') ? (this.validations[2].wordBoolean = true) : (this.validations[2].wordBoolean = false);
        data.newPassword.match(/.{8,32}/) ? (this.validations[3].wordBoolean = true) : (this.validations[3].wordBoolean = false);
      } else {
        this.validations.forEach((data) => {
          data.wordBoolean = false;
        });
      }
    });
  }
  equalPasswordsValidator(group: FormGroup) {
    if (group.value.confirmPassword !== group.value.newPassword) {
      return { passwordsNotMatch: true };
    }
    return null;
  }
  passwordsNotMatch() {
    return (
      this.resetPasswordForm.get('confirmPassword').touched &&
      this.resetPasswordForm.get('newPassword').value !== this.resetPasswordForm.get('confirmPassword').value
    );
  }
  showPassword() {
    this.showPass = !this.showPass;
  }

  onSubmit(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.onSubmit(control);
      }
    });
  }
  confirmChanges() {
    this.onSubmit(this.resetPasswordForm);
    if (this.resetPasswordForm.valid) {
      this.spinner.showSpinner();
      this.http
        .put(`${this.environment.new_api_url}/api/user/change-password`, {
          oldPassword: this.resetPasswordForm.value.oldPassword,
          newPassword: this.resetPasswordForm.value.newPassword
        })
        .pipe(
          catchError((err) => {
            if (err?.error?.message === 'INVALID_CREDENTIALS') {
              this.resetPasswordForm.get('oldPassword').setErrors({ wrongPass: true });
            } else {
              this.alerts.showAlert(err?.error?.message, 'danger');
            }
            this.spinner.hideSpinner();
            return EMPTY.pipe();
          })
        )
        .subscribe(() => {
          this.spinner.hideSpinner();
          this.alerts.showAlert('Password changed successfully');
          this.close();
        });
    }
  }
  close() {
    if (this.user.policyRole?.name === 'avenews-agent') {
      this.router.navigate(['/trade-desk']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
