import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Question, BaseFormTemplateComponent, SpinnerToggleService, MultipleChoices } from '@avenews/shared-lib';
import { AlertService } from '@avenews/alerts';
import { environment } from '@env/environment';

@Component({
  selector: 'av-create-question',
  templateUrl: './question-generator.component.html',
  styleUrls: ['./question-generator.component.scss']
})
export class QuestionGeneratorComponent extends BaseFormTemplateComponent implements OnChanges {
  @Input() isEdit: boolean;
  @Input() question: Question;
  @Input() category: any;
  @Output() closed = new EventEmitter();
  fakeForm = this.fb.group({ fake: [] });
  environment = environment;
  answerTypes = [
    {
      viewValue: 'Free text',
      value: 'textarea'
    },
    {
      viewValue: 'Number input',
      value: 'number'
    },
    {
      viewValue: 'Radio buttons',
      value: 'radio'
    },
    {
      viewValue: 'Checkboxes',
      value: 'checkbox'
    },
    {
      viewValue: 'Dropdown',
      value: 'select'
    }
  ];
  scoreOptions = [
    {
      label: 'Including score calculation',
      value: true
    }
  ];
  questionForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private spinner: SpinnerToggleService,
    private http: HttpClient,
    private router: Router
  ) {
    super();
    this.questionForm = this.fb.group({
      placeholder: [''],
      label: [undefined, Validators.required],
      type: [undefined, Validators.required],
      scorable: [undefined],
      multipleChoices: this.fb.array([])
    });
    this.formInput = this.questionForm;
    this.questionForm.get('type').valueChanges.subscribe((value) => {
      if (value === 'checkbox' || value === 'radio' || value === 'select') {
        if (!this.multipleChoicesArray?.length) this.multipleChoicesArray.push(this.multipleChoiceGroup());
      } else {
        this.multipleChoicesArray.clear();
      }
    });
  }
  close() {
    $('#addQuestionModal').fadeOut('fast', () => {
      this.deleteData();
      this.closed.emit();
    });
  }
  deleteData() {
    this.questionForm.reset();
    this.questionForm.markAsUntouched();
  }
  get multipleChoicesArray() {
    return this.questionForm.get('multipleChoices') as FormArray;
  }
  multipleChoiceGroup() {
    return this.fb.group({
      label: [undefined, Validators.required],
      value: [undefined],
      score: [undefined, [Validators.required, Validators.min(0)]]
    });
  }
  addGroup(multipleChoice?: MultipleChoices) {
    if (multipleChoice) {
      this.multipleChoicesArray.push(
        this.fb.group({
          label: [multipleChoice.label, Validators.required],
          value: [multipleChoice.label || undefined],
          score: [multipleChoice['score'] ?? undefined, [Validators.required, Validators.min(0)]]
        })
      );
    } else {
      this.multipleChoicesArray.push(this.multipleChoiceGroup());
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['question'] && changes['question']?.currentValue) {
      this.questionForm.patchValue(this.question);
      if (this.question.type === 'radio' || this.question.type === 'checkbox' || this.question.type === 'select') {
        this.multipleChoicesArray.clear();
        this.question.multipleChoices.forEach((multipleChoice) => {
          this.addGroup(multipleChoice);
        });
      }
    }
  }
  submit() {
    this.validateForm(this.questionForm);
    if (this.questionForm.valid) {
      const dto = this.questionForm.value;
      dto.scorable = dto.scorable ? true : false;
      dto?.multipleChoices?.forEach?.((data) => {
        data.value = data.label;
      });
      this.spinner.showSpinner();
      const putOrPost = this.isEdit ? 'put' : 'post';
      const subUrl = this.isEdit
        ? `/api/trade-desk/question/${this.question?._id}`
        : '/api/trade-desk/question/category/' + this.category._id;
      this.http[putOrPost](this.environment.new_api_url + subUrl, dto)
        .pipe(
          catchError((err) => {
            this.spinner.hideSpinner();
            this.alertService.showAlert(err.error.message, 'danger');
            return EMPTY.pipe();
          })
        )
        .subscribe(() => {
          this.alertService.showAlert('Question saved successfully');
          this.spinner.hideSpinner();
          this.router.navigateByUrl(this.router.url);
          this.close();
        });
    }
  }
  remove(i: number) {
    if (this.multipleChoicesArray.length > 1) {
      this.multipleChoicesArray.removeAt(i);
    }
  }
  reset() {
    this.multipleChoicesArray.clear();
    this.multipleChoicesArray.push(this.multipleChoiceGroup());
  }
}
